import { QueryOpenInvitation } from '../graphql/invitation';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import LoadingState from '../custom-prebuilt/preloader/LoadingState.component';
import { QueryOrg, ConnectPersonToOrgUnit } from '../graphql/orgs';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { successToastMid, errorToastMid } from '../lib/toast';
import LoginForm from '../components/login/LoginForm.component';
import { useAuth } from '../lib/authContext';
import { QueryPersonWorksAt, CreateUserRecord, QueryPersonBasics } from '../graphql/people';
import { SpinnerCircular } from 'spinners-react';
import { CheckIcon } from '@heroicons/react/outline';

const HandleInvitation = () => {
  const { userEmail, authenticated, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const invitationUid = location.pathname.split('/').at(-1);
  const [invitation, setInvitation] = useState();
  const [org, setOrg] = useState();
  const [password, setPassword] = useState('');
  const [userRecordLoading, setUserRecordLoading] = useState(false); // Loading state to check if user's record exists in db. If not, create one
  const [connectUserLoading, setConnectUserLoading] = useState(false); // Loading state to connect user's record to org
  const [finalizeLoading, setFinalizeLoading] = useState(false); // Decide whether should re-render password form
  const [alreadyWorksAt, setAlreadyWorksAt] = useState(null);

  const queryOpenInvitation = useQuery(QueryOpenInvitation, {
    variables: { where: { uid: invitationUid } },
  });
  const [queryOrg] = useLazyQuery(QueryOrg);
  const [queryPersonWorksAt] = useLazyQuery(QueryPersonWorksAt);
  const [queryPersonBasics] = useLazyQuery(QueryPersonBasics);
  const [createUserRecord] = useMutation(CreateUserRecord);
  const [connectPersonToOrgUnit] = useMutation(ConnectPersonToOrgUnit);

  useEffect(() => {
    userEmail &&
      org?.uid &&
      queryPersonWorksAt({
        variables: {
          where: { email: userEmail },
          isAdminConnectionWhere2: {
            node: {
              uid: org?.uid,
            },
          },
          orgUnitsConnectionWhere2: {
            node: {
              organization: {
                uid: org?.uid,
              },
            },
          },
        },
      }).then((res) => {
        res.data?.people[0]?.orgUnitsConnection?.totalCount !== 0 &&
          setAlreadyWorksAt({
            worksAt: true,
            isAdmin: res.data?.people[0]?.isAdminConnection?.totalCount !== 0,
          });
      });
  }, [userEmail, org, queryPersonWorksAt]);

  useEffect(() => {
    queryOpenInvitation.data?.invitations &&
      setInvitation(queryOpenInvitation.data?.invitations[0]);
  }, [queryOpenInvitation]);

  useEffect(() => {
    invitation?.org &&
      queryOrg({ variables: { where: { uid: invitation.org } } }).then((res) => {
        setOrg(res.data?.organizations[0]);
      });
  }, [invitation, queryOrg]);

  const acceptInvitation = () => {
    connectPersonToOrgUnit({
      variables: {
        connect: {
          worksAt: [
            {
              where: {
                node: {
                  uid: org.uid,
                },
              },
              edge: {
                isAdmin: false,
              },
            },
          ],
        },
        where: {
          email: userEmail,
        },
      },
    }).then(() => {
      setConnectUserLoading(false);
      successToastMid(`Joined ${org?.name}`);
      setTimeout(() => {
        navigate('/');
        window.location.reload();
      }, 2000);
    });
  };

  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (password === invitation.password) {
      setUserRecordLoading(true);
      setConnectUserLoading(true);
      setFinalizeLoading(true);
      queryPersonBasics({ variables: { where: { email: userEmail } } }).then((res) => {
        if (!res?.data?.people?.[0]) {
          //User record not in db
          createUserRecord({
            variables: {
              input: [
                {
                  email: userEmail,
                  name: userEmail.split('.').at(0),
                  active: true,
                },
              ],
            },
          }).then(() => {
            setUserRecordLoading(false);
            acceptInvitation();
          });
        } else {
          //User record already exists
          setUserRecordLoading(false);
          acceptInvitation();
        }
      });
    } else {
      errorToastMid('Wrong password');
    }
  };

  console.log(invitation, org);

  return (
    <div className="fixed z-[100] h-screen w-screen bg-white overflow-auto">
      <main className="relative h-full">
        <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
          <img
            src="https://images.unsplash.com/photo-1571740073230-9cf01981ca52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt="TODO"
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="h-full">
          <div className="h-full mx-auto max-w-2xl py-8 px-4 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
            <div className="lg:col-start-2 space-y-8 lg:flex lg:flex-col">
              {!authenticated ? (
                <div className="lg:my-auto">
                  <LoginForm noRedirect />
                </div>
              ) : (
                <>
                  {invitation && org ? (
                    <>
                      <div className="flex flex-row">
                        <img src={org.photoURL} alt="" className="h-10 w-10 mr-2" />
                        <p className="mt-2 text-xl font-bold tracking-tight text-gray-900">
                          Invitation from {org.name}
                        </p>
                      </div>

                      {!alreadyWorksAt ? (
                        !userRecordLoading &&
                        !connectUserLoading &&
                        !finalizeLoading && (
                          <form
                            className="flex flex-row w-full space-x-4"
                            onSubmit={(e) => {
                              handleSubmitForm(e);
                            }}
                          >
                            <div
                              className="relative rounded-md border border-gray-300 px-3 py-3 shadow-sm focus-within:border-signature
                          focus-within:ring-1 focus-within:ring-signature flex-1"
                            >
                              <label
                                htmlFor="name"
                                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                              >
                                Invitation password
                              </label>
                              <input
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                                type="password"
                                name="password"
                                id="password"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                required
                                value={password}
                              />
                            </div>
                            <button
                              type="submit"
                              className="px-4 py-3 shadow bg-gray-900 hover:bg-gray-700 transition duration-300 text-white rounded-md"
                            >
                              <ArrowRightIcon className="h-4 w-4" />
                            </button>
                          </form>
                        )
                      ) : (
                        <div
                          className={`w-fit flex flex-row items-center space-x-2 cursor-pointer`}
                        >
                          <p
                            className="text-sm"
                            onClick={() => {
                              alreadyWorksAt.isAdmin ? navigate('/admin/people') : navigate('/');
                            }}
                          >
                            {alreadyWorksAt.isAdmin
                              ? 'Manage this invitation in admin panel'
                              : 'Already member of this organization'}
                          </p>
                          <ArrowRightIcon className="h-4 w-4 cursor-pointer" />
                        </div>
                      )}

                      <div className="space-y-4">
                        {(userRecordLoading || connectUserLoading || finalizeLoading) && (
                          <>
                            <div className="w-full flex flex-row items-center">
                              {userRecordLoading ? (
                                <SpinnerCircular
                                  size={20}
                                  thickness={100}
                                  speed={50}
                                  color="black"
                                  className="mr-2"
                                />
                              ) : (
                                <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                              )}
                              <span className="text-xs text-gray-600">Creating records</span>
                            </div>
                            <div className="w-full flex flex-row items-center">
                              {connectUserLoading ? (
                                <SpinnerCircular
                                  size={20}
                                  thickness={100}
                                  speed={50}
                                  color="black"
                                  className="mr-2"
                                />
                              ) : (
                                <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                              )}
                              <span className="text-xs text-gray-600">Accepting invitation</span>
                            </div>
                          </>
                        )}
                      </div>

                      <div>
                        <button
                          className="text-white text-sm bg-gray-900 hover:bg-gray-700 transition duration-300 px-3 py-2 rounded-md w-full"
                          onClick={logout}
                        >
                          Sign out
                        </button>
                      </div>
                    </>
                  ) : (
                    <LoadingState />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HandleInvitation;
