import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type AddKnowledgeState = {
  open: boolean;
  layerNum: number;
};

const initState: AddKnowledgeState = {
  open: false,
  layerNum: 0,
};

export const addKnowledgeSlice = createSlice({
  name: 'add-knowledge',
  initialState: initState,
  reducers: {
    toggleAddKnowledge: (state, action: PayloadAction<Partial<AddKnowledgeState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleAddKnowledge } = addKnowledgeSlice.actions;

export default addKnowledgeSlice.reducer;
