import { useMutation } from '@apollo/client';
import { CreateUserRequest } from '../graphql/requests';
import { Button, Label, Input } from './common.component';
import { useState } from 'react';
import { capitalize } from 'lodash';
import { toast } from 'react-hot-toast';
import { useAuth } from '../lib/authContext';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const RequestItem = ({ requestType, closeModal }) => {
  const { cognitoID } = useAuth();
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [createUserRequest] = useMutation(CreateUserRequest, {
    refetchQueries: ['QueryRequestedSkills'],
  });

  const handleCreateRequest = () => {
    if (!name) {
      setError(true);
      return;
    }

    const promise = createUserRequest({
      variables: {
        input: [
          {
            name: name,
            id: uuidv4(),
            type: requestType,
            requestedBy: {
              connect: [
                {
                  edge: {
                    dateRequested: moment().format('MM/DD/YYYY'),
                  },
                  where: {
                    node: {
                      cognitoID: cognitoID,
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    });
    toast.promise(promise, {
      success: 'Successfully requested skill!',
      error: 'An error occurred!',
    });
    closeModal();
  };

  return (
    <div className="w-full flex flex-col items-center -mt-4">
      <Label className="w-2/3 flex flex-row items-center justify-center">
        {`${capitalize(requestType)}`}
        <Input
          className="ml-2"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setError(false);
          }}
        />
      </Label>
      <span className={`w-2/3 text-xs text-red-600 ${error ? 'block' : 'hidden'}`}>
        Required field
      </span>
      <div className="w-2/3 flex flex-row justify-evenly items-center mt-2">
        <Button onClick={() => handleCreateRequest()}>Confirm</Button>
        <Button onClick={() => closeModal()}>Cancel</Button>
      </div>
    </div>
  );
};

export default RequestItem;
