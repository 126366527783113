import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProfileGeneratorInfo } from '../state/ProfileGeneratorContext';
import Modal from '../../../custom-prebuilt/Modal.component';
import { mergeClasses } from '../../../lib/classNames';
import ImageInput from '../../../custom-prebuilt/ImageInput';
import { updateState } from '../state/profileGeneratorSlice';
import { InputClasses } from './ProfileGeneratorProfileInput';

const ImageUpload = ({ path, className }) => {
  // TODO: replace with real type when doing LR108-533
  const profileData = useSelector((state) => state.profileGenerator.profileData);
  const [cropperOpen, setCropperOpen] = useState(false);
  const { getValue } = useProfileGeneratorInfo();
  const image = useMemo(() => getValue(path), [profileData]);
  const dispatch = useDispatch();

  const updateImage = (newImage) => {
    dispatch(updateState({ path, newValue: newImage }));
  };

  const openCropper = () => setCropperOpen(true);
  const closeCropper = () => setCropperOpen(false);

  const cropper = useMemo(() => {
    return (
      <ImageInput
        image={image}
        setImage={updateImage}
        id={path}
        changeButtonText={'Choose Headshot'}
        fieldLabel={<h1 className="text-xs text-font-dark font-bold">Headshot</h1>}
        contentWrapperClasses={'flex my-4 items-center gap-6'}
        displayImageClasses={'max-h-[84px] max-w-[84px] p-1 rounded-md bg-white box-content'}
        enableCropper
        cropAspect={1}
        useImageAspectRatio
      />
    );
  }, []);

  return (
    <>
      <Modal
        open={cropperOpen}
        content={cropper}
        onDestructiveButtonClick={closeCropper}
        destructiveButtonLabel={'close'}
      />
      <img
        className={mergeClasses(InputClasses, className)}
        onClick={openCropper}
        src={image}
        alt="input"
      />
    </>
  );
};

export default ImageUpload;
