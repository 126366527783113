import PageContainer from '../custom-prebuilt/PageContainer';
import { useQuery } from '@apollo/client';
import { QueryAssessment } from '../graphql/assessment';
import { useSelector } from 'react-redux';
import AssessmentList from '../components/assessment/AssessmentList.component';
import LoadingState from '../custom-prebuilt/preloader/LoadingState.component';
import AssessmentSelectWork from '../components/assessment/companies/jg/project-assessment/utils/AssessmentSelectWork.component';
import { useNavigate } from 'react-router';

const Assessment = () => {
  const navigate = useNavigate();
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const assessment = useQuery(QueryAssessment, {
    variables: {
      where: {
        org_SOME: {
          uid: defaultOrgUid,
        },
      },
    },
  });

  return (
    <PageContainer
      title="Assessment"
      action={() => {
        navigate('/assessment/completions');
      }}
      actionName="View Completed Assessments"
    >
      <AssessmentSelectWork />
      {assessment?.data?.assessments ? (
        <AssessmentList assessments={assessment?.data?.assessments} />
      ) : (
        <LoadingState />
      )}
    </PageContainer>
  );
};

export default Assessment;
