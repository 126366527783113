import { NodeProps } from '../../../../lib/taxonomyHelpers';
import { Handle, Position } from 'reactflow';
import { useState } from 'react';
import SlideOver from '../../../../custom-prebuilt/SlideOver.component';
import AttachNewChild from '../utils/AttachNewChild';

const TaxonomyNode = ({ data }: { data: NodeProps }) => {
  const [open, setOpen] = useState(false);
  const handleCloseSlideover = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={`max-w-[120px] max-h-[66px] text-xs border border-green-900 rounded-lg p-2 bg-green-400 ${
          data.isAdmin ? 'hover:bg-[#64E392] cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.isAdmin) setOpen(true);
        }}
      >
        {data.name}
        <Handle type="target" position={Position.Bottom} className="w-1/3 !bg-green-800" />
      </div>

      <SlideOver
        show={open}
        onClose={handleCloseSlideover}
        onClickButtonClose={handleCloseSlideover}
        title="Skill Taxonomy Node Edit"
        content={
          <div className="w-full h-full flex flex-col bg-bg-light">
            <div className="w-full flex items-center space-x-2">
              <h3 className="text-lg font-medium">Taxonomy</h3>
              <div className="rounded-md bg-green-300 text-xs p-1 border border-black">
                Taxonomy
              </div>
            </div>
            <AttachNewChild id={data.id} closeSlideover={handleCloseSlideover} />
          </div>
        }
      />
    </>
  );
};

export default TaxonomyNode;
