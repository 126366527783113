import { Auth } from 'aws-amplify';
import { Button } from '../../../custom-prebuilt/common.component';
import Card from '../../shared/Card.component';
import { useEffect, useState } from 'react';
import { api_putConnector } from '../people/utils/fetchResp';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import ConnectorDetails from './ConnectorDetails';
import { toast } from 'react-hot-toast';

const Connectors = (props) => {
  const { data, setData, title, type } = props;
  // const [connectors, setConnectors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data[type])
      setLoading(false);
  }, [data])

  const updateConnectors = (newConnector, index) => {
    Auth.currentSession().then((session) => {
      const sessionToken = session?.getAccessToken().getJwtToken();
      const promise = api_putConnector(sessionToken, newConnector).then(() => {
        const updatedConnectors = [...data[type]];
        updatedConnectors[index] = newConnector;
        setData({
          ...data,
          [type]: updatedConnectors
        });
      });
      toast.promise(promise, {
        success: 'Updated connector',
        loading: 'Updating...',
        error: 'An error occurred!',
      });
    });
  };

  return (
    <>
      <div className="w-full h-full flex flex-col ml-4">
        <h3 className="text-sm font-medium text-gray-900">Manage {title}s</h3>
        <Card className="mt-4 w-fit pt-5">
          <div className="flex flex-row items-center">
            <div className="flex flex-col mr-5 space-y-2">
              <h3 className="text-xs font-medium text-gray-900">Add New {title}</h3>
              <p className="text-xs font-normal">Link your management system to Connect</p>
            </div>
            <Button>Create</Button>
          </div>
        </Card>
        <h3 className="text-sm font-medium text-gray-900 mt-8">Current {title}s</h3>
        {loading ? (
          <LoadingState />
        ) : !data[type].length ? (
          <div className="text-sm mt-2">No {title}s</div>
        ) : (
          data[type].map((con, i) => (
            <ConnectorDetails
              key={i}
              connectorData={con}
              updateConnector={(newCon) => updateConnectors(newCon, i)}
            />
          ))
        )}
      </div>
    </>
  );
};
export default Connectors;
