import { useState, useEffect } from "react"
import SingleChoice from "./SingleChoice.component"
import { useDispatch, useSelector } from "react-redux"
import { toggleAssessment } from "../../../../../redux/assessmentSlice"

const MultipleChoiceBlock = ({ options, stage, title }) => {

  const [selected, setSelected] = useState(null)
  const teamAnswers = useSelector(state => state.assessment.teamAnswers)
  const dispatch = useDispatch()

  const handleSelectOption = (number) => {
    setSelected(number)
    if (options.name === "size" || options.name === "budget") {
      dispatch(toggleAssessment({ teamAnswers: [...teamAnswers.filter(a => a.name !== options.name), { name: options.name, value: number }] }))
    }
  }

  useEffect(() => {
    stage == 1 && setSelected(null)
  }, [stage])

  return (
    <>
      {
        options.stage == stage &&
        <div className="px-5 py-6 bg-white w-full shadow rounded-lg">
          {
            title &&
            <p className="text-xs font-medium mb-5">{title}</p>
          }
          <label htmlFor="multiple-choice" className="block text-xs font-medium text-gray-700">
            {options.question}
          </label>
          <p className="text-xs text-gray-500 mt-2 leading-relaxed">{options.description}</p>
          <div className={`space-y-3 mt-4 ${options.align === "row" && "grid grid-cols-3 gap-3 space-y-0"}`}>
            {
              options.options.map((option, index) => (
                <div key={index} onClick={() => { handleSelectOption(index) }}>
                  <SingleChoice
                    option={option}
                    index={index}
                    selected={selected}
                    align={options.align} />
                </div>
              ))
            }
          </div>
          {/* <button className="mt-6 px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 
            transition duration-300"
            onClick={handleClearForm}>Clear</button> */}
        </div>
      }
    </>
  )
}

export default MultipleChoiceBlock