import { Button } from './common.component';
import Modal from './Modal.component';
import { useState } from 'react';
import { capitalize } from 'lodash';
import RequestItem from './RequestItem';

/**
 *
 * @param {*} searchInput inputData for searchbar
 * @returns
 */
export type NoResultsProps = {
  searchInput: string;
  showRequestInput?: boolean;
  requestType?: string;
};
const NoResults = ({ searchInput, showRequestInput = false, requestType = '' }: NoResultsProps) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => setOpen(false);

  return (
    <>
      <div className="h-full w-full flex flex-col items-center py-12 px-4">
        <p className="text-sm font-medium text-gray-500 w-fit mb-2">
          No results found for{' '}
          <span className="text-gray-700 font-semibold">&quot;{searchInput}&quot;</span>
        </p>
        {!showRequestInput ? (
          <p className="text-xs font-medium text-gray-400 w-fit max-w-sm text-center">
            Double check your spelling or contact your administrator if you expect to see results
          </p>
        ) : (
          <div className="w-full flex flex-col text-xs font-medium text-gray-400 max-w-sm items-center text-center">
            Not seeing what you are looking for? Make a request for it to be added to Connect
            <Button className="w-40 mt-2" onClick={() => setOpen(true)}>
              Request
            </Button>
          </div>
        )}
      </div>
      <Modal
        open={open}
        onSecondaryButtonClick={() => setOpen(false)}
        title={<div className="text-sm font-medium">{`Request a ${capitalize(requestType)}`}</div>}
        content={<RequestItem closeModal={closeModal} requestType={requestType} />}
      />
    </>
  );
};

export default NoResults;
