import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GetAllCategories, GetAllCertifications, GetAllSkills } from './graphql/requirements';
import { Input, Label, Select } from '../../../custom-prebuilt/common.component';

const reqTypes = ['Skill', 'Skill Category', 'Certification', 'Prompt'];

const AddRequirementModal = ({ closeModal, addRequirement }) => {
  const [reqType, setReqType] = useState('');
  const [skillReq, setSkillReq] = useState({
    name: '',
    id: '',
    minRating: 1,
    minProjects: 0,
  });
  const [categoryReq, setCategoryReq] = useState({
    name: '',
    id: '',
    minSkills: 1,
    minRating: 1,
    minProjects: 0,
  });
  const [certReq, setCertReq] = useState({
    name: '',
    id: '',
  });
  const [promptReq, setPromptReq] = useState({
    name: '',
    type: '',
    choices: [],
    correctAnswer: '',
  });
  const [answerInput, setAnswerInput] = useState('');

  const valid =
    (reqType == 'Skill' && skillReq.minProjects >= 0 && skillReq.minRating >= 1 && skillReq.name) ||
    (reqType == 'Skill Category' &&
      categoryReq.minProjects >= 0 &&
      categoryReq.minRating >= 1 &&
      categoryReq.minSkills >= 1 &&
      categoryReq.name) ||
    (reqType == 'Certification' && certReq.name) ||
    (reqType == 'Prompt' &&
      promptReq.name &&
      (promptReq.type == 'short answer' ||
        (promptReq.type == 'multiple choice' &&
          promptReq.choices.length &&
          promptReq.choices.includes(promptReq.correctAnswer)) ||
        (promptReq.type == 'checklist' &&
          promptReq.choices.length &&
          Number(promptReq.correctAnswer) >= 1)));

  const { data: skillData, loading: skillLoading, error: skillError } = useQuery(GetAllSkills);
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(GetAllCategories);
  const { data: certData, loading: certLoading, error: certError } = useQuery(GetAllCertifications);

  if (skillError || categoryError || certError) {
    console.error(skillError || categoryError || certError);
  }

  const handleAddRequirement = () => {
    const reqDict = {
      Skill: skillReq,
      'Skill Category': categoryReq,
      Certification: certReq,
      Prompt: promptReq,
    };

    addRequirement(reqType, reqDict[reqType]);
    closeModal();
  };

  return (
    <div className="flex flex-col">
      <h3 className="font-medium text-sm"></h3>
      <Label>
        Requirement Type
        <Select
          value={reqType}
          onChange={(e) => {
            setReqType(e.target.value);
          }}
        >
          <option value="" />
          {reqTypes.map((type, i) => {
            return (
              <option key={i} value={type}>
                {type}
              </option>
            );
          })}
        </Select>
      </Label>
      {reqType === 'Skill' && !skillLoading && (
        <>
          <Label>
            Required Skill
            <Select
              value={skillReq.id}
              onChange={(e) => {
                const skill = skillData.skills.find((s) => s.id === e.target.value);
                setSkillReq((s) => ({
                  ...s,
                  id: e.target.value,
                  name: skill?.name ?? '',
                }));
              }}
            >
              <option value="" />
              {skillData.skills.map((skill, i) => {
                return (
                  <option key={i} value={skill.id}>
                    {skill.name}
                  </option>
                );
              })}
            </Select>
          </Label>
          <Label>
            Minimum Rating
            <Input
              type="number"
              min={1}
              max={3}
              value={skillReq.minRating}
              onChange={(e) =>
                setSkillReq((s) => ({
                  ...s,
                  minRating: parseInt(e.target.value, 10),
                }))
              }
            />
          </Label>
          <Label>
            Minimum Projects
            <Input
              type="number"
              min={0}
              value={skillReq.minProjects}
              onChange={(e) =>
                setSkillReq((s) => ({
                  ...s,
                  minProjects: parseInt(e.target.value, 10),
                }))
              }
            />
          </Label>
        </>
      )}
      {reqType === 'Skill Category' && !categoryLoading && (
        <>
          <Label>
            Required Skill Category
            <Select
              value={categoryReq.id}
              onChange={(e) => {
                const category = categoryData.skillCategories.find((c) => c.id === e.target.value);
                setCategoryReq((c) => ({
                  ...c,
                  id: e.target.value,
                  name: category?.name ?? '',
                }));
              }}
            >
              <option value="" />
              {categoryData.skillCategories.map((category, i) => {
                return (
                  <option key={i} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </Select>
          </Label>
          <Label>
            Minimum Skills
            <Input
              type="number"
              min={1}
              value={categoryReq.minSkills}
              onChange={(e) =>
                setCategoryReq((c) => ({
                  ...c,
                  minSkills: parseInt(e.target.value, 10),
                }))
              }
            />
          </Label>
          <Label>
            Minimum Rating
            <Input
              type="number"
              min={1}
              max={3}
              value={categoryReq.minRating}
              onChange={(e) =>
                setCategoryReq((c) => ({
                  ...c,
                  minRating: parseInt(e.target.value, 10),
                }))
              }
            />
          </Label>
          <Label>
            Minimum Projects
            <Input
              type="number"
              min={0}
              value={categoryReq.minProjects}
              onChange={(e) =>
                setCategoryReq((c) => ({
                  ...c,
                  minProjects: parseInt(e.target.value, 10),
                }))
              }
              className="w-1/2 mt-1 text-base font-normal p-1"
            />
          </Label>
        </>
      )}
      {reqType === 'Certification' && !certLoading && (
        <>
          <Label>
            Required Certification
            <Select
              value={certReq.id}
              onChange={(e) => {
                const cert = certData.certifications.find((c) => c.id === e.target.value);
                setCertReq((c) => ({
                  ...c,
                  id: cert.id,
                  name: cert?.name,
                }));
              }}
            >
              <option value="" />
              {certData.certifications.map((cert, i) => {
                return (
                  <option key={i} value={cert.id}>
                    {cert.name}
                  </option>
                );
              })}
            </Select>
          </Label>
        </>
      )}
      {reqType === 'Prompt' && (
        <>
          <Label>
            Prompt
            <Input
              type="text"
              value={promptReq.name}
              onChange={(e) =>
                setPromptReq((p) => ({
                  ...p,
                  name: e.target.value,
                }))
              }
              className="w-2/3 mt-1 text-base font-normal p-1"
            />
          </Label>
          <Label>
            Prompt Type
            <Select
              value={promptReq.type}
              onChange={(e) => {
                setPromptReq((p) => ({
                  ...p,
                  type: e.target.value,
                  choices: [],
                  correctAnswer: e.target.value === 'checklist' ? '0' : '',
                }));
                setAnswerInput('');
              }}
            >
              <option />
              <option value={'multiple choice'}>Multiple Choice</option>
              <option value={'checklist'}>Checklist</option>
              <option value={'short answer'}>Short Answer</option>
            </Select>
          </Label>
          {promptReq.type === 'multiple choice' && (
            <Label>
              Answers
              <div className="flex flex-col items-center rounded-md border border-black p-2">
                {promptReq.choices.map((a, i) => {
                  return (
                    <div className="w-full flex items-center text-base p-1" key={i}>
                      <Input
                        type="radio"
                        name="correct"
                        className="mr-2"
                        checked={promptReq.correctAnswer === a}
                        onChange={() => setPromptReq((p) => ({ ...p, correctAnswer: a }))}
                      />
                      {a}
                      <span
                        onClick={() => {
                          let newChoices = [...promptReq.choices];
                          newChoices.splice(i, 1);
                          setPromptReq((p) => ({
                            ...p,
                            choices: newChoices,
                          }));
                        }}
                        className="ml-2 text-red-600 cursor-pointer rounded-md hover:bg-gray-200"
                      >
                        X
                      </span>
                    </div>
                  );
                })}
                <div className="w-full flex items-center">
                  <Input value={answerInput} onChange={(e) => setAnswerInput(e.target.value)} />
                  <button
                    onClick={() => {
                      setAnswerInput('');
                      setPromptReq((p) => ({
                        ...p,
                        choices: [...p.choices, answerInput],
                      }));
                    }}
                    className="text-sm text-white bg-slate-700 rounded-md p-1 ml-2 hover:bg-slate-600"
                  >
                    Add Answer
                  </button>
                </div>
              </div>
            </Label>
          )}
          {promptReq.type === 'checklist' && (
            <>
              <label className="flex flex-col font-medium text-sm mt-2">
                Answers
                <div className="flex flex-col items-center rounded-md border border-black p-2">
                  {promptReq.choices.map((a, i) => {
                    return (
                      <div key={i} className="w-full flex items-center text-base p-1">
                        {a}
                        <span
                          onClick={() => {
                            let newChoices = [...promptReq.choices];
                            newChoices.splice(i, 1);
                            setPromptReq((p) => ({
                              ...p,
                              choices: newChoices,
                            }));
                          }}
                          className="ml-2 text-red-600 cursor-pointer rounded-md hover:bg-gray-200"
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                  <div className="w-full flex items-center">
                    <Input
                      type="text"
                      value={answerInput}
                      onChange={(e) => setAnswerInput(e.target.value)}
                    />
                    <button
                      onClick={() => {
                        setAnswerInput('');
                        setPromptReq((p) => ({
                          ...p,
                          choices: [...p.choices, answerInput],
                        }));
                      }}
                      className="text-sm text-white bg-slate-700 rounded-md p-1 ml-2 hover:bg-slate-600"
                    >
                      Add Answer
                    </button>
                  </div>
                </div>
              </label>
              <Label>
                Required Answers
                <Input
                  type="number"
                  value={promptReq.correctAnswer}
                  onChange={(e) =>
                    setPromptReq((p) => ({
                      ...p,
                      correctAnswer: e.target.value,
                    }))
                  }
                  min={0}
                />
              </Label>
            </>
          )}
        </>
      )}

      <div className="flex justify-end gap-4">
        <div className="mt-2">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3
            bg-white text-xs font-medium text-black hover:bg-gray-200 transition duration-150"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
        </div>
        <div className="mt-2">
          <button
            type="button"
            className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3
            bg-bg-dark text-xs font-medium text-white ${
              valid ? 'hover:bg-gray-700' : ''
            } transition duration-150 disabled:opacity-75`}
            onClick={() => handleAddRequirement()}
            disabled={!valid}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRequirementModal;
