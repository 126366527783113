import Modal from '../../../custom-prebuilt/Modal.component';
import { useState, useMemo, useCallback } from 'react';
import { errorToastMid } from '../../../lib/toast';
import { Auth } from 'aws-amplify';
import { useMutation } from '@apollo/client';
import { AcceptInvitation } from '../../../graphql/invitation';

const DetailsChange = ({ show, setShowModal, user }) => {
  const [acceptInvitation] = useMutation(AcceptInvitation);

  const [detailsInput, setDetailsInput] = useState({
    newPassword: '',
    confirmNewPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: 0,
  });

  const handleInputChange = useCallback(
    (event) => {
      setDetailsInput({
        ...detailsInput,
        [event.target.name]: event.target.value,
      });
    },
    [detailsInput],
  );

  const handleSubmitForm = useCallback(
    (e) => {
      e.preventDefault();
      if (detailsInput.newPassword !== detailsInput.confirmNewPassword) {
        errorToastMid("Passwords don't match");
      } else {
        // Change details
        Auth.completeNewPassword(user, detailsInput.newPassword)
          .then((user) => {
            const email = user.signInUserSession.idToken.payload.email;
            acceptInvitation({
              variables: {
                disconnect: { invited: [{ where: { node: { email: email } } }] },
              },
            }).then(() => window.location.reload());
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [detailsInput?.confirmNewPassword, detailsInput?.newPassword, user],
  );

  const DetailsChangeComponent = useMemo(() => {
    return (
      <form
        onSubmit={(e) => {
          handleSubmitForm(e);
        }}
        className="space-y-2"
      >
        <div>
          <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">
            New password
          </label>
          <div className="mt-1">
            <input
              id="new-password"
              name="newPassword"
              type="password"
              required
              value={detailsInput.newPassword}
              onChange={handleInputChange}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm"
            />
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="confirm-new-password" className="block text-sm font-medium text-gray-700">
            Confirm new password
          </label>
          <div className="mt-1">
            <input
              id="confirm-new-password"
              name="confirmNewPassword"
              type="password"
              required
              value={detailsInput.confirmNewPassword}
              onChange={handleInputChange}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm"
            />
          </div>
        </div>

        <div className="!mt-6">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-signature py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-signature focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2"
          >
            Confirm
          </button>
        </div>
      </form>
    );
  }, [detailsInput, handleInputChange, handleSubmitForm]);

  return (
    <div>
      <Modal
        open={show}
        title="Change account details"
        content={DetailsChangeComponent}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => setShowModal(false)}
      />
    </div>
  );
};

export default DetailsChange;
