export type AvailabilitySliderProps = {
  availability: number;
  setAvailability: React.Dispatch<React.SetStateAction<number>>;
};
/**
 *
 * @param {*} availability number of availability hours
 * @param {*} setAvailability setState function for availability
 * @returns
 */
const AvailabilitySlider = (props: AvailabilitySliderProps) => {
  const { availability, setAvailability } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailability(parseInt(event.target.value));
  };

  return (
    <div>
      {/* <label htmlFor="default-availability-slider" className="text-xs text-gray-700">
        Filter results by availability: {availability} hours
      </label> */}
      <input
        id="default-availability-slider"
        type="range"
        min="0"
        max="40"
        step="20"
        value={availability}
        onChange={handleChange}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
      />
      <div className="w-full flex justify-between text-xs px-2">
        <span>0h</span>
        <span>20h</span>
        <span>40h</span>
      </div>
    </div>
  );
};

export default AvailabilitySlider;
