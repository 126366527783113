import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';

const ArrayInput = ({ array, updateArray, className }) => {
  const [input, setInput] = useState('');

  return (
    <div className={`flex flex-col space-y-1 ${className ?? ''}`}>
      {array.map((item, i) => (
        <div key={i} className="text-xs flex flex-row items-center">
          {item.toString()}
          <div
            onClick={() => {
              const newArray = [...array];
              newArray.splice(i, 1);
              updateArray(newArray);
            }}
            className="ml-1 w-6 h-6 flex items-center justify-center rounded-md cursor-pointer border border-borders bg-white hover:bg-gray-50"
          >
            <XIcon className="w-4 h-4" />
          </div>
        </div>
      ))}
      <div className="flex flex-row items-center space-x-1">
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="rounded-md text-xs border border-borders p-1"
        />
        {!!input && (
          <>
            <div
              onClick={() => {
                updateArray([...array, input]);
                setInput('');
              }}
              className="w-6 h-6 flex items-center justify-center rounded-md cursor-pointer border border-borders bg-white hover:bg-gray-50"
            >
              <CheckIcon className="w-5 h-5" />
            </div>
            <div
              onClick={() => setInput('')}
              className="w-6 h-6 flex items-center justify-center rounded-md cursor-pointer border border-borders bg-white hover:bg-gray-50"
            >
              <XIcon className="w-5 h-5" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ArrayInput;
