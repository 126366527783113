import { useSelector, useDispatch } from 'react-redux';
import { toggleAssessment } from '../../../../../../redux/assessmentSlice';
import { MinusCircleIcon } from '@heroicons/react/solid';
import { toggleModal } from '../../../../../../redux/modalSlice';
import { useState } from 'react';
import Modal from '../../../../../../custom-prebuilt/Modal.component';

const AssessmentAdjustConfirmation = ({ skill, type }) => {
  const [skillRef, setSkillRef] = useState(skill);
  const show = useSelector((state) => state.modal.show2);
  const selectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const dispatch = useDispatch();

  const handleRemoveSkill = () => {
    dispatch(toggleModal({ show: false }));
    dispatch(
      toggleAssessment({
        selectedSkills: [...selectedSkills].filter((s) => s.node.name !== skillRef.node.name),
      }),
    );
  };

  const handleChangeSkillLevel = (skill, rating) => {
    setSkillRef({ ...skillRef, rating: rating });
    dispatch(
      toggleAssessment({
        selectedSkills: [...selectedSkills].map((s) =>
          s.node.name === skill.node.name ? { node: s.node, rating: rating } : s,
        ),
      }),
    );
  };

  const handleCloseModal = () => {
    dispatch(toggleModal({ show2: false }));
  };

  return (
    <>
      {skillRef ? (
        <div className="flex flex-row justify-between items-center mb-2">
          <div
            className="flex flex-col lg:flex-row w-fit border border-gray-900 divide-y lg:divide-y-0 lg:divide-x 
    divide-gray-900"
          >
            <button
              className={`px-3 py-2 hover:text-white hover:bg-gray-900 transition duration-200 text-xs
      ${skillRef.rating === 1 && 'bg-gray-900 text-white'}`}
              onClick={() => {
                handleChangeSkillLevel(skill, 1);
              }}
            >
              {type === 'project' ? 'Primary' : 'Knowledgable'}
            </button>
            <button
              className={`px-3 py-2 hover:text-white hover:bg-gray-900 transition duration-200 text-xs
      ${skillRef.rating === 2 && 'bg-gray-900 text-white'}`}
              onClick={() => {
                handleChangeSkillLevel(skill, 2);
              }}
            >
              {type === 'project' ? 'Secondary' : 'Proficient'}
            </button>
            <button
              className={`px-3 py-2 hover:text-white hover:bg-gray-900 transition duration-200 text-xs 
      ${skillRef.rating === 3 && 'bg-gray-900 text-white'}`}
              onClick={() => {
                handleChangeSkillLevel(skill, 3);
              }}
            >
              {type === 'project' ? 'Passive' : 'Lead/Teach'}
            </button>
          </div>
          <MinusCircleIcon
            className="h-5 w-5 text-red-500 cursor-pointer"
            onClick={() => {
              dispatch(toggleModal({ show2: true }));
            }}
          />
        </div>
      ) : null}

      <Modal
        open={show}
        title="Remove skill"
        content={<p className="text-xs mb-2">This action can&apos;t be undone. Are you sure?</p>}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={handleCloseModal}
        destructiveButtonLabel="Remove"
        onDestructiveButtonClick={handleRemoveSkill}
      />
    </>
  );
};

export default AssessmentAdjustConfirmation;
