import { useEffect, useState } from 'react';
import SearchAndSelectInput from '../../../custom-prebuilt/SearchAndSelectInput';
import { CheckboxInput, Input } from '../../../custom-prebuilt/common.component';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { QueryPeopleTablePagination } from '../../../graphql/people';

type AddProjectMemberInput = {
  members: [] | undefined;
  showInputErrors: boolean;
  onChange: Function;
  editValue?: any;
};

export default function AddProjectMember(props: AddProjectMemberInput) {
  const [member, setMember] = useState(undefined);
  const [memberName, setMemberName] = useState(''); // search
  const [memberRole, setMemberRole] = useState('');
  const [memberStartDate, setMemberStartDate] = useState('');
  const [memberEndDate, setMemberEndDate] = useState('');
  const [hoursPerWeek, setHoursPerWeek] = useState('');
  const [totalHours, setTotalHours] = useState('');
  const [memberIsActive, setMemberIsActive] = useState(true);

  // reset when showInputErrors is changed to "false"
  useEffect(() => {
    if (!props.showInputErrors) {
      setMember(undefined);
      setMemberName('');
      setMemberRole('');
      setMemberStartDate('');
      setMemberEndDate('');
      setHoursPerWeek('');
      setTotalHours('');
      setMemberIsActive(true);
    }
  }, [props.showInputErrors]);

  // reset an editValue comes in.
  useEffect(() => {
    if (props.editValue) {
      setMember(props.editValue.member);
      setMemberName(props.editValue.memberName);
      setMemberRole(props.editValue.memberRole);
      setMemberStartDate(props.editValue.memberStartDate);
      setMemberEndDate(props.editValue.memberEndDate);
      setHoursPerWeek(props.editValue.hoursPerWeek);
      setTotalHours(props.editValue.totalHours);
      setMemberIsActive(props.editValue.active);
    }
  }, [props.editValue]);

  useEffect(() => {
    if (
      !member ||
      !memberRole ||
      !memberStartDate ||
      (hoursPerWeek && !parseFloat(hoursPerWeek)) ||
      (totalHours && !parseFloat(totalHours))
    ) {
      props?.onChange?.();
      return;
    }

    props?.onChange?.({
      member: member,
      active: memberIsActive,
      role: memberRole,
      startDate: memberStartDate,
      endDate: memberEndDate,
      totalHours: totalHours,
      hoursPerWeek: hoursPerWeek,
    });
  }, [
    member,
    memberRole,
    memberStartDate,
    memberEndDate,
    hoursPerWeek,
    totalHours,
    memberIsActive,
  ]);

  // get Potential Members
  const defaultOrgUid = useSelector<any>((state) => state.org.defaultOrgUid);

  const [allData, setAllData] = useState([]);
  const { data } = useQuery(QueryPeopleTablePagination, {
    variables: {
      // options: { sort: sortOptions },
      where: { uid: defaultOrgUid },
      membersWhere2: { active: true },
      orgUnitsConnectionWhere2: {
        node: { organization: { uid: defaultOrgUid } },
      },
    },
  });

  useEffect(() => {
    if (data?.organizations[0]) {
      setAllData(data.organizations[0].orgUnit.map((u: any) => u.members).flat());
    }
  }, [data]);

  return (
    <>
      <div className="flex flex-col space-y-1">
        <label className="text-xs">Name: *</label>
        <SearchAndSelectInput
          data={allData.filter(
            (m: any) => !props.members?.map((n: any) => n?.member?.name).includes(m.name),
          )}
          searchField="name"
          search={memberName}
          placeholder="Search for existing member..."
          setSearch={setMemberName}
          setSelected={(val) => {
            const newMember = allData.find((m: any) => m.name === val);
            setMember(newMember);
          }}
          // eslint-disable-next-line
          classes={`my-2 ${
            props.showInputErrors && !member ? 'border rounded-md border-red-500' : ''
          }`}
        />
      </div>
      <div className="flex flex-col space-y-1">
        <label className="text-xs">Role: *</label>
        <Input
          // eslint-disable-next-line
          className={`border px-2 ${props.showInputErrors && !memberRole ? 'border-red-500' : ''}`}
          name="memberRole"
          value={memberRole}
          onChange={(e) => setMemberRole(e.target.value)}
        />
      </div>
      <div className="flex justify-between space-x-4">
        <div className="flex flex-col space-y-1 basis-1/2">
          <label className="text-xs">Start Date *</label>
          <Input
            // eslint-disable-next-line
            className={`border px-2 ${
              props.showInputErrors && !memberStartDate ? 'border-red-500' : ''
            }`}
            type="date"
            value={memberStartDate}
            onChange={(e) => setMemberStartDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1 basis-1/2">
          <label className="text-xs">End Date</label>
          <Input
            type="date"
            value={memberEndDate}
            onChange={(e) => setMemberEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-between space-x-4">
        <div className="flex flex-col space-y-1 basis-1/2">
          <label className="text-xs">Hours Per Week:</label>
          <Input
            className={`border px-2 ${
              props.showInputErrors && hoursPerWeek && !parseFloat(hoursPerWeek)
                ? 'border-red-500'
                : ''
            }`}
            value={hoursPerWeek}
            onChange={(e) => setHoursPerWeek(e.target.value)}
          />
          <span
            className={`text-xs text-red-500 ${
              props.showInputErrors && hoursPerWeek && !parseFloat(hoursPerWeek)
                ? 'block'
                : 'hidden'
            }`}
          >
            Must be a decimal
          </span>
        </div>
        <div className="flex flex-col space-y-1 basis-1/2">
          <label className="text-xs">Total Hours:</label>
          <Input
            className={`border px-2 ${
              props.showInputErrors && totalHours && !parseFloat(totalHours) ? 'border-red-500' : ''
            }`}
            value={totalHours}
            onChange={(e) => setTotalHours(e.target.value)}
          />
          <span
            className={`text-xs text-red-500 ${
              props.showInputErrors && totalHours && !parseFloat(totalHours) ? 'block' : 'hidden'
            }`}
          >
            Must be a decimal
          </span>
        </div>
      </div>
      <CheckboxInput
        checked={memberIsActive}
        onChange={() => setMemberIsActive((c) => !c)}
        name="memberIsActive"
      >
        Project member is currently active
      </CheckboxInput>
    </>
  );
}
