import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { QueryAssessment } from '../../graphql/assessment';
import SevenCTOsAssessment from './companies/7ctos/7CTOsAssessment.component';
import JGSkillAssessment from './companies/jg/skill-assessment/JGSkillAssessment.component';
import JGProjectAssessment from './companies/jg/project-assessment/JGProjectAssessment.component';

const TakeAssessment = () => {
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const location = useLocation();
  const assessmentId = location.pathname.split('/').at(2);
  const assessmentStage = location.search.slice(1);

  const assessment = useQuery(QueryAssessment, {
    variables: {
      where: {
        assessmentId,
      },
    },
  });

  return (
    <>
      {defaultOrg?.name === '7CTOs' && (
        <SevenCTOsAssessment assessment={assessment} stage={assessmentStage} />
      )}{' '}
      {/*7CTOs*/}
      {defaultOrg?.uid === '710bf6dd-1604-4a85-a8d3-bfa33904666e' &&
        assessmentId === '3e68d66c-b91f-4519-a772-7a4880ae95f3' && (
          <JGSkillAssessment assessment={assessment} />
        )}
      {defaultOrg?.uid === '710bf6dd-1604-4a85-a8d3-bfa33904666e' &&
        assessmentId === '1ad1fccc-d279-46a0-8980-1d91afd6ba67' && <JGProjectAssessment />}
    </>
  );
};

export default TakeAssessment;
