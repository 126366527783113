import React from 'react';
import { useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

const ContentDiv = ({ content }: { content?: JSX.Element }) => {
  const [collectionRef] = useAutoAnimate<HTMLDivElement>({ duration: 150 });

  return (
    <div ref={collectionRef} className="w-full h-fit overflow-hidden">
      {content}
    </div>
  );
};

export type AccordionProps = {
  title?: JSX.Element | string;
  content?: JSX.Element;
  className?: string;
  boxStyle?: boolean;
  expandAll?: boolean;
};

const Accordion = ({ title, content, className, boxStyle, expandAll }: AccordionProps) => {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const [iconRef] = useAutoAnimate<HTMLDivElement>();
  const [show, setShow] = useState(false);

  const reveal = () => setShow(!show);

  useEffect(() => {
    setShow(!!expandAll);
  }, [expandAll]);

  return (
    <div className={`px-4 py-2 ${className} rounded-md`}>
      <div ref={parentRef} className={`${!boxStyle && 'rounded-lg shadow px-6 py-4'} w-full`}>
        <div ref={iconRef} className="flex flex-row items-cente w-full">
          <div className="text-xs font-medium w-full flex items-center">{title}</div>
          {!show ? (
            <ChevronDownIcon
              className="h-7 w-7 p-2 rounded-full shadow-lg ml-auto self-start cursor-pointer hover:bg-gray-800 hover:text-white transition duration-150"
              onClick={() => {
                reveal();
              }}
            />
          ) : (
            <ChevronUpIcon
              className="h-7 w-7 p-2 rounded-full shadow-lg ml-auto self-start cursor-pointer hover:bg-gray-800 hover:text-white transition duration-150"
              onClick={() => {
                reveal();
              }}
            />
          )}
        </div>
        {show && <ContentDiv content={content} />}
      </div>
    </div>
  );
};

export default Accordion;
