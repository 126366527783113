import { useLazyQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { toggleActionInput } from '../../redux/actionInputSlice';
import { QueryPersonBasics } from '../../graphql/people';
import { errorToastMid } from '../../lib/toast';
import Card from '../../components/shared/Card.component';
import { SaveButton } from '../../components/shared/Button.component';
import { RootState } from '../../redux/store';

export type InputActionType = {
  title: string;
  prompt: string;
  action: () => void;
  type: string;
  placeholder: string;
};
/**
 *
 * @param { title, prompt, action, type, placeholder, actionName }
 * @returns
 */
const InputAction = ({ title, prompt, action, type, placeholder }: InputActionType) => {
  const dispatch = useDispatch();
  const [queryPersonBasics] = useLazyQuery(QueryPersonBasics);
  const inputValue = useSelector((state: RootState) => state.actionInput.inputValue);

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    queryPersonBasics({ variables: { where: { email: inputValue } } }).then((res) => {
      res.data?.people?.length ? action() : errorToastMid('Could not find person');
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleActionInput({ inputValue: e.target.value }));
  };

  return (
    <Card title={title} _titleClassNameOverride="p-5 pb-0">
      <>
        <div className="mt-1 max-w-xl text-xs text-gray-500">
          <p>{prompt}</p>
        </div>
        <form
          className="input-action-form mt-3 sm:flex sm:items-center w-full"
          onSubmit={(e) => {
            handleOnSubmit(e);
          }}
        >
          <div className="flex-1">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type={type || 'email'}
              name={type || 'email'}
              id={type || 'email'}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-800 focus:ring-gray-800 text-xs"
              placeholder={placeholder || 'you@example.com'}
              onChange={(e) => {
                handleInputChange(e);
              }}
              required
            />
          </div>
          <SaveButton onClick={() => {}}></SaveButton>
        </form>
      </>
    </Card>
  );
};

export default InputAction;
