import { gql } from '@apollo/client';

export const QueryEntitiesCount = gql`
  query QueryEntitiesCount(
    $where: ProjectWhere
    $peopleAggregateWhere2: PersonWhere
    $skillsAggregateWhere2: SkillWhere
  ) {
    projectsAggregate(where: $where) {
      count
    }
    peopleAggregate(where: $peopleAggregateWhere2) {
      count
    }
    skillsAggregate(where: $skillsAggregateWhere2) {
      count
    }
    certificationsAggregate {
      count
    }
  }
`;
