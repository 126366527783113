import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import ReactFlow, { Background, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/base.css';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { GetSkillPath } from '../../../graphql/taxonomy';
import { getEdges, getNodes } from '../../../lib/taxonomyHelpers';
import SkillCategoryNode from './nodes/SkillCategoryNode';
import SkillNode from './nodes/SkillNode';
import TaxonomyNode from './nodes/TaxonomyNode';
import { GetTaxonomySubtree } from '../../../graphql/taxonomy';

//readonly single-skill version of the Taxonomy view
const SingleSkillView = (props) => {
  const nodeTypes = useMemo(
    () => ({ Taxonomy: TaxonomyNode, SkillCategory: SkillCategoryNode, Skill: SkillNode }),
    [],
  );

  const [getSkillPath, { loading }] = useLazyQuery(GetSkillPath, {
    fetchPolicy: 'no-cache',
  });
  const [getCategorySubtree] = useLazyQuery(GetTaxonomySubtree, {
    fetchPolicy: 'no-cache',
  });
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const loadSkillPath = (skillId) => {
    getSkillPath({
      variables: {
        skillId,
      },
    })
      .then(({ data }) => {
        const nodeArray = data?.getSkillPath;
        if (!nodeArray?.length) return;
        const formattedNodes = getNodes(nodeArray);
        const formattedEdges = getEdges(nodeArray);
        setNodes(formattedNodes);
        setEdges(formattedEdges);
        return getCategorySubtree({
          variables: {
            rootId: nodeArray[nodeArray.length - 1]?.parents[0],
          },
        }).then((results) => ({ ...results, nodeArray }));
      })
      .then(({ data, nodeArray }) => {
        const subTree =
          data?.getTaxonomySubtree?.filter((n) => !nodeArray.find((n1) => n1.id === n.id)) || [];
        const fullNodeArray = [...subTree, ...nodeArray];
        if (!fullNodeArray?.length) return;
        const formattedNodes = getNodes(fullNodeArray);
        const formattedEdges = getEdges(fullNodeArray);
        setNodes(formattedNodes);
        setEdges(formattedEdges);
      });
  };

  const skillId = props?.skill?.id;
  useEffect(() => {
    loadSkillPath(skillId);
  }, [skillId]);

  return (
    <div className="w-full h-96">
      {loading ? (
        <LoadingState />
      ) : nodes.length ? (
        <div className="w-full h-full bg-white rounded-lg shadow">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            fitView
            zoomOnScroll={false}
          >
            <Background />
          </ReactFlow>
        </div>
      ) : null}
    </div>
  );
};

export default SingleSkillView;
