import { useMemo, useState } from 'react';
import { FilterIcon as FilterEmpty } from '@heroicons/react/outline';
import { FilterIcon as FilterFilled, XIcon } from '@heroicons/react/solid';
import SkillSelection from '../../../custom-prebuilt/SkillSelection';
import { mergeClasses } from '../../../lib/classNames';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { addToArray } from '../state/profileGeneratorSlice';
import { useProfileGeneratorInfo } from '../state/ProfileGeneratorContext';
import Search from './ProfileGeneratorSearch';
import { useHorizontalTabs } from '../../../lib/customHook';
import Toggle from './ProfileGeneratorToggle';
import { FilterItem, AdjustableFilterItem } from './ProfileGeneratorFilterItem';
import { Skill } from './ProfileGeneratorSkill';
import PopOut from '../../../custom-prebuilt/PopOut';

const skillRatingFilters = [
  {
    dataTip: 'Skill Level: Unrated',
    type: 'normal',
    colorOn: 'bg-stone-400',
    colorOff: 'bg-stone-200',
  },
  {
    dataTip: 'Skill Level: Knowledgeable',
    type: 'normal',
    colorOn: 'bg-yellow-400',
    colorOff: 'bg-yellow-200',
  },
  {
    dataTip: 'Skill Level: Proficient',
    type: 'normal',
    colorOn: 'bg-orange-400',
    colorOff: 'bg-orange-200',
  },
  {
    dataTip: 'Skill Level: Lead/Tech',
    type: 'normal',
    colorOn: 'bg-rose-400',
    colorOff: 'bg-rose-200',
  },
];

const projectRatingFilters = [
  {
    dataTip: 'Project Use: No Experience',
    type: 'normal',
    colorOn: 'bg-slate-400',
    colorOff: 'bg-slate-200',
  },
  {
    dataTip: 'Project Use: Passive',
    type: 'scale',
    colorOn: 'bg-green-400',
    colorOff: 'bg-green-200',
  },
  {
    dataTip: 'Project Use: Secondary',
    type: 'scale',
    colorOn: 'bg-emerald-400',
    colorOff: 'bg-emerald-200',
  },
  {
    dataTip: 'Project Use: Primary',
    type: 'scale',
    colorOn: 'bg-teal-400',
    colorOff: 'bg-teal-200',
  },
];

const getDefaultFilters = (filterObject) => {
  return filterObject.map((filter) => {
    if (filter.type === 'scale') return [true, 1];
    else return true;
  });
};

const getNumFilters = (filters) => {
  return filters.reduce((p, c) => {
    let cVal = 0;
    if (Array.isArray(c)) cVal = (c[0] ? 0 : 1) | (c[1] === 1 ? 0 : 1);
    else cVal = c ? 0 : 1;
    return p + cVal;
  }, 0);
};

const tabs = ['By Category', 'By Skill'];

const SkillDisplay = ({
  skills,
  selectSkill,
  categoryClasses,
  className,
  search,
  filterVisible = true,
  type = ['skill'],
  filterPosition = ['bottom', 'left'],
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [skillFilters, setSkillFilters] = useState(getDefaultFilters(skillRatingFilters));
  const [projectFilters, setProjectFilters] = useState(getDefaultFilters(projectRatingFilters));
  const numSkillFilters = useMemo(() => getNumFilters(skillFilters), [skillFilters]);
  const numProjectFilters = useMemo(() => getNumFilters(projectFilters), [projectFilters]);
  // eslint-disable-next-line no-unused-vars
  const [tabIndex, _setTabIndex, tabsComponent] = useHorizontalTabs(
    tabs,
    false,
    'bg-signature text-white shadow',
    'bg-transparent hover:text-signature',
    'w-max',
  );

  const showFilters = useMemo(
    () => ({
      showSkillFilter: type.includes('skill'),
      showProjectValueFilter: type.includes('projectValue'),
      showProjectFilter: type.includes('project'),
    }),
    [type],
  );

  const flipFilter = (index, list, setList) => {
    const newFilters = [...list];
    const filter = newFilters[index];
    newFilters[index] = !filter;
    setList(newFilters);
  };

  const adjustProjectFilter = (list, setList, index, flip, newValue) => {
    const newFilters = [...list];
    if (typeof newValue !== 'undefined') {
      newFilters[index][1] = newValue;
    }
    if (flip) {
      newFilters[index][0] = !newFilters[index][0];
    }
    setList(newFilters);
  };

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchMatchesSkill = (skill) => {
    if (searchTerm === '') return true;
    return skill.name.toLowerCase().includes(searchTerm.toLowerCase());
  };

  const onWipeSearch = () => {
    setSearchTerm('');
  };

  const toggle = () => setIsToggled(!isToggled);

  const resetSkillFilters = () => {
    setSkillFilters(getDefaultFilters(skillRatingFilters));
  };

  const resetProjectFilters = () => {
    setProjectFilters(getDefaultFilters(projectRatingFilters));
  };

  const filterRating = (skill) => {
    const knowledgeableFilter = skillFilters[1] && skill.rating === 1;
    const proficientFilter = skillFilters[2] && skill.rating === 2;
    const leadTechFilter = skillFilters[3] && skill.rating === 3;
    const unratedFilter = skillFilters[0] && skill.rating == null;
    return knowledgeableFilter || proficientFilter || leadTechFilter || unratedFilter;
  };

  const filterProjectSkills = (skill) => {
    let noExperienceFilter = projectFilters[0];
    let passiveFilter = projectFilters[1][0];
    let secondaryFilter = projectFilters[2][0];
    let primaryFilter = projectFilters[3][0];

    if (showFilters.showProjectValueFilter) {
      const counts = [0, 0, 0];
      const allRatings = skill.projectRatings ?? [];
      allRatings.forEach((val) => {
        if (val !== 0) counts[val - 1] += 1;
      });
      const total = counts.reduce((p, c) => p + c);
      noExperienceFilter = noExperienceFilter && total === 0;
      passiveFilter = passiveFilter && counts[2] >= projectFilters[1][1];
      secondaryFilter = secondaryFilter && counts[1] >= projectFilters[2][1];
      primaryFilter = primaryFilter && counts[0] >= projectFilters[3][1];
    } else {
      noExperienceFilter = noExperienceFilter && skill.rating === null;
      passiveFilter = passiveFilter && skill.rating === 3;
      secondaryFilter = secondaryFilter && skill.rating === 2;
      primaryFilter = primaryFilter && skill.rating === 1;
    }

    return noExperienceFilter || passiveFilter || secondaryFilter || primaryFilter;
  };

  const filterSkills = (skill, skillFilters, projectFilters) => {
    if (
      showFilters.showSkillFilter &&
      (showFilters.showProjectFilter || showFilters.showProjectValueFilter)
    )
      return filterRating(skill, skillFilters) && filterProjectSkills(skill, projectFilters);
    if (showFilters.showSkillFilter) return filterRating(skill, skillFilters);
    if (showFilters.showProjectFilter || showFilters.showProjectValueFilter)
      return filterProjectSkills(skill, projectFilters);

    return false;
  };

  const filterComponents = useMemo(() => {
    const createFilters = (filterObject, filters, setFilters) => {
      return filterObject
        .map((filter, index) => {
          if (typeof filter === 'undefined' || typeof filter !== 'object') return null;
          if (filter.type === 'scale') {
            if (typeof filter.overrideType !== 'undefined' && filter.overrideType === 'normal') {
              return (
                <FilterItem
                  key={index}
                  dataTip={filter.dataTip}
                  onClick={() =>
                    adjustProjectFilter(projectFilters, setProjectFilters, index, true)
                  }
                  isActive={filters[index][0]}
                  colorOn={filter.colorOn}
                  colorOff={filter.colorOff}
                />
              );
            }
            return (
              <AdjustableFilterItem
                key={index}
                isActive={projectFilters[index][0]}
                filterDataTip={filter.dataTip}
                filterOnClick={() =>
                  adjustProjectFilter(projectFilters, setProjectFilters, index, true)
                }
                rangeValue={projectFilters[index][1]}
                onAdjust={(value) =>
                  adjustProjectFilter(projectFilters, setProjectFilters, index, false, value)
                }
                colorOn={filter.colorOn}
                colorOff={filter.colorOff}
              />
            );
          } else if (filter.type === 'normal') {
            return (
              <FilterItem
                key={index}
                dataTip={filter.dataTip}
                onClick={() => flipFilter(index, filters, setFilters)}
                isActive={filters[index]}
                colorOn={filter.colorOn}
                colorOff={filter.colorOff}
              />
            );
          } else return null;
        })
        .filter(Boolean);
    };

    return (
      <div>
        {showFilters.showSkillFilter ? (
          <>
            <div className="flex items-center pb-1 gap-1">
              <p className="text-xs font-bold text-font-dark">Rating Filters</p>
              {numSkillFilters > 0 ? (
                <XIcon
                  data-tip="Reset Rating Filters"
                  data-for="filterInfo"
                  className="h-4 text-red-400 cursor-pointer"
                  onClick={resetSkillFilters}
                />
              ) : null}
            </div>
            <div className="flex flex-wrap gap-2 w-max pb-2">
              {createFilters(skillRatingFilters, skillFilters, setSkillFilters)}
            </div>
          </>
        ) : null}

        {showFilters.showProjectFilter || showFilters.showProjectValueFilter ? (
          <>
            <div className="flex items-center pb-1 gap-1">
              <p className="text-xs font-bold text-font-dark">Project Filters</p>
              {numProjectFilters > 0 ? (
                <XIcon
                  data-tip="Reset Project Filters"
                  data-for="filterInfo"
                  className="h-4 text-red-400 cursor-pointer"
                  onClick={resetProjectFilters}
                />
              ) : null}
            </div>
            <div className={`flex ${showFilters.showProjectValueFilter ? 'flex-col' : ''} gap-2`}>
              {showFilters.showProjectValueFilter
                ? createFilters(projectRatingFilters, projectFilters, setProjectFilters)
                : createFilters(
                    projectRatingFilters.map((pRF, index) => {
                      if (index !== 0) return { ...pRF, overrideType: 'normal' };
                    }),
                    projectFilters,
                    setProjectFilters,
                  )}
            </div>
          </>
        ) : null}
      </div>
    );
  }, [projectFilters, skillFilters]);

  return (
    <>
      <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="filterInfo" />
      <div className={mergeClasses('flex flex-col', className)}>
        {search ? (
          <Search value={searchTerm} onChange={onSearchChange} wipeSearch={onWipeSearch} />
        ) : null}
        <div className="flex justify-between pb-2">
          {tabsComponent}
          <div className="flex items-center">
            {filterVisible ? (
              <PopOut
                isOpen={isToggled}
                setIsOpen={setIsToggled}
                content={filterComponents}
                className="p-2 flex"
                position={filterPosition}
              >
                <Toggle
                  isToggled={numSkillFilters > 0 || numProjectFilters > 0}
                  onClick={toggle}
                  toggle1={{
                    icon: <FilterFilled className="h-6 text-color-bg-signature" />,
                    title: 'Filters',
                    subtitle: `${numProjectFilters + numSkillFilters} applied`,
                  }}
                  toggle2={{
                    icon: <FilterEmpty className="h-6 text-color-bg-signature" />,
                    title: 'Filters',
                    subtitle: 'None',
                  }}
                />
              </PopOut>
            ) : null}
          </div>
        </div>
        <SkillSelection
          skills={skills.filter(searchMatchesSkill).filter(filterSkills)}
          SkillComponent={Skill}
          handleSelectSkill={selectSkill}
          disableClickAndHold
          skillListClasses={'gap-3'}
          categoryClasses={categoryClasses}
          displayType={tabIndex === 0 ? 'category' : 'skills'}
        />
      </div>
    </>
  );
};

const SkillSelector = ({ path }) => {
  const allSkills = useSelector((state) => state.profileGenerator.allSkills);
  const { getValue } = useProfileGeneratorInfo();
  const dispatch = useDispatch();
  const defaultStyle = useMemo(() => getValue(`${path}DefaultStyles`), [path]);
  const selectedSkills = getValue(path) ?? [];

  const selectSkill = (skill) => {
    ReactTooltip.hide();
    dispatch(
      addToArray({
        pathOfArray: path,
        templateItem: {
          skillName: skill.name,
          rating: skill.rating,
          imageLink: skill.imageLink,
          projectRatings: skill.projectRatings,
          skillNameStyles: defaultStyle,
        },
      }),
    );
  };

  const isNotASelectedSkill = (skill) => {
    return !selectedSkills.some((selectedSkill) => selectedSkill.skillName === skill.name);
  };

  return (
    <div className="grid gap-3 py-4 min-w-full lg:grid-rows-[1fr_auto] xl:grid-cols-[1fr_min-content]">
      <SkillDisplay
        className="flex flex-col p-4 rounded-lg shadow bg-white w-full order-2 xl:order-1 gap-3"
        categoryClasses="xl:justify-center 3xl:justify-start"
        selectSkill={selectSkill}
        skills={allSkills.filter(isNotASelectedSkill)}
        type={['skill', 'projectValue']}
        search
      />
    </div>
  );
};

export { SkillDisplay, SkillSelector };
