import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { classNames } from '../../lib/classNames';

interface CloseIndicatorProps {
  className?: string;
}

const CloseIndicator = ({ className }: CloseIndicatorProps) => {
  return (
    <div
      className={classNames(
        `h-fit w-fit p-2 rounded-full bg-white shadow-xl absolute -right-2 -top-2`,
        className,
      )}
    >
      <XIcon className="h-4 w-4 text-gray-600" />
    </div>
  );
};

export default CloseIndicator;
