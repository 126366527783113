
/*eslint-disable*/

import { classNames } from "../../../lib/classNames"
import PersonCard from "../../shared/PersonCard.component"
import PersonSkillRadar from "../../../custom-prebuilt/PersonSkillRadar.component"
import { useHorizontalTabs } from "../../../lib/customHook"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { QueryPersonDetails } from "../../../graphql/people"
import { useQuery } from "@apollo/client"
import UserCert from "../../profile/skill-cert/UserCert.component"
import UserSkillList from "../../profile/skill-cert/UserSkillList.component"
import { useDispatch, useSelector } from "react-redux"
import { toggleSlideOver } from "../../../redux/slideOverSlice"
import CloseIndicator from "../../shared/CloseIndicator.component"
import { useState } from "react"
import { toggleExplore } from "../../../redux/exploreSlice"

const tabs = [
  "Overview",
  "Skills",
  "Certifications",
]

/**
*
A component for displaying the basic details of a person in a compare card.
If the revealAll prop is true, additional tabs will be shown that display the person's
skills, certifications, and other details.
 * @param {object} personBasics - An object containing basic details of the person. Must at least contain name, email, and avatar.
 * @param {boolean} revealAll - Determines whether or not to display additional tabs and details.
 * @param {string} className - Additional classes to apply to the root element of the component.
 * @returns {JSX.Element} - The PersonCompareCard component.
*/

const PersonCompareCard = (props) => {
  // eslint-disable-next-line
  const { personBasics, revealAll, className, canvasIdx } = props
  const [current, setCurrent, tabsComponent] = useHorizontalTabs(tabs)
  const [tabsRef] = useAutoAnimate({ duration: 300 })
  const [cardRef] = useAutoAnimate({ duration: 150 })

  // console.log(personBasics)
  const queryDetails = useQuery(QueryPersonDetails, {
    variables: {
      where: {
        cognitoID: personBasics?.cognitoID
      }
    }
  })
  const dispatch = useDispatch()
  const [isHovering, setIsHovering] = useState(false)
  const [containerRef] = useAutoAnimate({ duration: 100 })
  const canvasItems = useSelector(state => state.explore.canvasItems)

  const handleSelectPerson = () => {
    dispatch(toggleSlideOver({ show1: true, profileTarget: personBasics }))
  }

  const handleMouseEnter = () => {
    setTimeout(() => {
      setIsHovering(true)
    })
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const handleRemovePerson = () => {
    const updatedCanvas = [canvasItems[canvasIdx][0]]
    const updatedArray = [...canvasItems][canvasIdx][1].filter(
      p => p.cognitoID !== personBasics.cognitoID
    )
    updatedCanvas.push(updatedArray)
    const temp = [...canvasItems]
    temp.splice(canvasIdx, 1, updatedCanvas)
    dispatch(toggleExplore({
      canvasItems: temp
    }))
  }

  return (
    <div
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(className, `bg-white shadow-lg rounded-md relative`)}
    >
      {
        isHovering &&
        <div onClick={handleRemovePerson}>
          <CloseIndicator className="z-50 cursor-pointer" />
        </div>
      }
      <div ref={cardRef} className="flex flex-col space-y-3">
        <PersonCard
          personBasics={personBasics}
          onSelect={handleSelectPerson}
          className="shadow-none bg-gray-100 rounded-b-none !cursor-pointer select-none"
          disableHover />
        {
          revealAll &&
          <div className="mx-3">
            {tabsComponent}
            <div ref={tabsRef} className="w-full aspect-1 bg-white rounded-md overflow-y-auto overflow-x-hidden">
              {
                current === 0 &&
                <PersonSkillRadar
                  className="shadow-none"
                  skillDetails={queryDetails.data?.people[0]?.skillsConnection?.edges} />
              }
              {
                current === 1 &&
                <UserSkillList
                  userDetails={queryDetails.data?.people[0]}
                  className="shadow-none -mx-3 -mt-4"
                  redirectSkill />
              }
              {
                current === 2 &&
                <UserCert
                  userDetails={queryDetails.data?.people[0]}
                  className="shadow-none -mx-3 -mt-4" />
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PersonCompareCard