import { useEffect, useState } from "react"
import { QueryAllOpenInvitations, DeleteOpenInvitation } from "../../../graphql/invitation"
import { useQuery, useMutation } from "@apollo/client"
import LoadingState from "../../../custom-prebuilt/preloader/LoadingState.component"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import { api_adminDeleteUser } from "./utils/fetchResp"
import HeaderText from "../../shared/HeaderText.component"
import { Auth } from "aws-amplify"

const PastInvitations = ({ orgUnits }) => {
  const defaultOrgUid = useSelector(state => state.org.defaultOrgUid)
  const queryAllOpenInvitations = useQuery(QueryAllOpenInvitations, {
    variables: {
      where: {
        edge: {
          orgUnitName_IN: orgUnits,
        },
      },
    },
  });
  const [pastInvitations, setPastInvitations] = useState([])

  const [deleteOpenInvitation] = useMutation(DeleteOpenInvitation, {
    refetchQueries: () => [{
      query: QueryAllOpenInvitations,
      variables: {
        where: {
          org: defaultOrgUid,
        }
      }
    }]
  })

  useEffect(() => {
    queryAllOpenInvitations.data?.invitations
      ? setPastInvitations([
        ...queryAllOpenInvitations.data?.invitations[0].invitedConnection.edges || []
      ])
      : setPastInvitations(null)
  }, [queryAllOpenInvitations])

  const handleCancelInvitation = (email, orgUnitName) => {
    toast.promise(
      handleDeleteOpenInvitation(email, orgUnitName),
      {
        loading: "Deleting...",
        success: "Deleted",
        error: "Error"
      }, {
      position: "top-center"
    }
    )
  }

  const handleDeleteOpenInvitation = async (email, orgUnitName) => {
    // Delete in databse
    await deleteOpenInvitation({ variables: { input: { email, orgUnitName } } })

    // Delete in Cognito
    await Auth.currentAuthenticatedUser().then(user => {
      user.getSession((err, session) => {
        if (err) {
          throw new Error(err);
        }
        const sessionToken = session.getAccessToken().jwtToken;
        api_adminDeleteUser(sessionToken, email)
      })
    })
  }

  return (
    <div className="col-span-1 max-h-[400px] overflow-y-auto">
      <HeaderText text="Past invitations" />
      {
        pastInvitations ?
          pastInvitations?.length ?
            <div className="mt-4 flex flex-col max-h-[400px] overflow-y-auto">
              <div>
                <div className="inline-block min-w-full align-middle">
                  <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                            Email
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 hidden 2xl:table-cell">
                            Org Unit Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
                            Date Invited
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Profile</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {pastInvitations.map((invitation, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">{invitation.node.email}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 hidden 2xl:table-cell">{invitation.orgUnitName}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 max-w-[10rem] truncate">{invitation.dateInvited}</td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6 text-red-600 cursor-pointer">
                              <p onClick={() => handleCancelInvitation(invitation.node.email, invitation.orgUnitName)}>
                                Cancel invitation</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            : <p className="text-xs text-font-light font-medium mt-4">There isn&apos;t any invitations yet</p>
          : <LoadingState />
      }
    </div>
  )
}

export default PastInvitations
