import { useEffect, useMemo, useState } from 'react';
import { SkillDetailsDynamicType } from './SkillDetails.component';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { QueryProjectsFromSkill } from '../../../graphql/projects';
import SearchLogic from '../../SearchLogic.component';
import ProjectsBySkillTableView from '../../../components/projects/ProjectsBySkillTableView.component';

export type SkillProjectsProps = {
  skillDetails: SkillDetailsDynamicType;
};

const SkillProjects = (props: SkillProjectsProps) => {
  const defaultOrgUid = useSelector((state: any) => state.org.defaultOrgUid);

  const [searchInput, setSearchInput] = useState('');

  // Sort Selector for People
  const sortProjectsSelector = useSelector((state: any) => state.sortTable.projects);
  const sortOptions = [];
  if (sortProjectsSelector.column !== '') {
    sortOptions.push({
      [sortProjectsSelector.column]: sortProjectsSelector.sortValue,
    });
  }

  const [statesData, setStatesData] = useState([]);
  const { data, loading } = useQuery(QueryProjectsFromSkill, {
    variables: {
      options: {
        sort: sortOptions,
      },
      where: {
        AND: [
          {
            assessments_SOME: {
              skills_SOME: {
                name: props.skillDetails.name,
              },
            },
          },
          {
            // active: true,
            OR: [
              {
                name_NOT_STARTS_WITH: 'JG',
              },
              {
                name_IN: ['JG - Connect Development', 'JG - Tense'],
              },
            ],
            organizationAggregate: {
              count_GT: 0,
            },
          },
        ],
      },
      assessmentsWhere: {
        skillsConnection_SOME: {
          node: {
            name: props.skillDetails.name,
          },
        },
      },
      skillsConnectionWhere: {
        node: {
          name: props.skillDetails.name,
        },
      },
      orgUnitsConnectionWhere: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (data && data?.projects) {
      const tmp = [...data.projects];

      setStatesData(
        tmp.filter((project) =>
          project.name.toLowerCase().includes(searchInput.toLowerCase()),
        ) as any,
      );
    }
  }, [data, searchInput]);

  const ProjectsTableComponent = useMemo(() => {
    return (
      <ProjectsBySkillTableView
        customList={statesData}
        showPaginationNav={false}
        actionName="View project"
        search={{
          isSearching: searchInput ? true : false,
          searchFor: 'projects',
          filteredData: data
            ? [...data.projects].filter((project) =>
                project.name.toLowerCase().includes(searchInput.toLowerCase()),
              )
            : [],
        }}
      />
    );
  }, [data, searchInput, statesData]);

  return (
    <div className="!p-0 bg-white px-4 pt-2 pb-2">
      <div className="rounded-lg shadow bg-bg_light">
        <div className="">
          <dl className="space-y-6 sm:space-y-0 py-6 sm:py-0 overflow-hidden">
            <div className="m-3 mb-4">
              <input
                type="text"
                placeholder="Find project"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs py-2"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>

            <SearchLogic
              className={'h-80 m-3'}
              dataCheck={statesData.length > 0}
              loading={loading}
              searchInput={searchInput}
              dataComponent={ProjectsTableComponent}
            />
          </dl>
        </div>
      </div>
    </div>
  );
};

export default SkillProjects;
