import { ReactNode } from 'react';

export type PageHeaderProps = {
  title?: ReactNode | undefined;
  action?: (event?: React.MouseEvent<HTMLElement>) => void;
  actionName?: ReactNode | undefined;
  action2?: (event?: React.MouseEvent<HTMLElement>) => void;
  actionName2?: ReactNode | undefined;
  setTopDistance?: boolean;
};

const PageHeader = ({
  title,
  action,
  actionName,
  action2,
  actionName2,
  setTopDistance,
}: PageHeaderProps) => {
  return (
    <div
      className={`flex items-center justify-between shadow rounded-md flex-grow h-fit mb-5 bg-gray-800 text-white
        sticky z-20 grow-0 ${!setTopDistance && 'top-16'}`}
    >
      <div className="h-fit min-w-0 py-5 md:py-7 px-4 md:px-8">
        <p className="text-sm font-semibold leading-7 lg:text-base sm:truncate">{title}</p>
      </div>
      <div className="flex md:pb-0 md:mt-0 md:ml-4 px-8">
        {actionName && (
          <button
            onClick={action}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium 
                    text-black bg-white hover:bg-gray-200 transition duration-150"
          >
            {actionName}
          </button>
        )}
        {actionName2 && (
          <button
            onClick={action2}
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium 
                    text-black bg-white hover:bg-gray-200 transition duration-150"
          >
            {actionName2}
          </button>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
