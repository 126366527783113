import { useMutation, useQuery } from '@apollo/client';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button, Label } from '../../../../custom-prebuilt/common.component';
import LoadingState from '../../../../custom-prebuilt/preloader/LoadingState.component';
import SearchAndSelectInput from '../../../../custom-prebuilt/SearchAndSelectInput';
import { GetUnattachedSkills, ReplaceSkill } from '../../../../graphql/taxonomy';

type ReplaceSkillNodeProps = {
  id: string;
  closeSlideover: () => void;
};

export type UnattachedSkill = {
  name: string;
  id: string;
};

const ReplaceSkillNode = ({ id, closeSlideover }: ReplaceSkillNodeProps) => {
  const { data, loading } = useQuery(GetUnattachedSkills);
  const [replaceSkill] = useMutation(ReplaceSkill, {
    refetchQueries: ['GetOrgTaxonomy', 'GetUnattachedSkills'],
  });
  const [error, setError] = useState(false);
  const [skill, setSkill] = useState('');
  const [search, setSearch] = useState('');
  const [collapse, setCollapse] = useState(false);
  const [parentRef] = useAutoAnimate<HTMLDivElement>({ duration: 150 });

  const validateForm = () => {
    if (!skill) {
      setError(true);
      return false;
    }
    return true;
  };

  const handleReplaceSkill = () => {
    if (validateForm()) {
      const skillID = data.getUnattachedSkills.find(
        (node: UnattachedSkill) => node.name === skill,
      ).id;
      const promise = replaceSkill({
        variables: {
          prevSkillId: id,
          newSkillId: skillID,
        },
      });
      toast.promise(promise, {
        loading: 'Replacing Skill...',
        success: 'Successfully replaced Skill!',
        error: 'An error occurred!',
      });
      closeSlideover();
    }
  };

  return (
    <>
      <div
        ref={parentRef}
        className="w-full flex flex-col items-center bg-white border border-gray-200 rounded-md shadow-sm p-2 mt-6"
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 22.318 22.318">
              <g id="code-compare-duotone" transform="translate(0 0)">
                <path
                  id="Path_66"
                  data-name="Path 66"
                  d="M18.831,6.974a3.487,3.487,0,1,1,3.487-3.487A3.486,3.486,0,0,1,18.831,6.974Zm1.046-3.487a1.046,1.046,0,1,0-1.046,1.046A1.045,1.045,0,0,0,19.877,3.487ZM3.487,15.343A3.487,3.487,0,1,1,0,18.831,3.486,3.486,0,0,1,3.487,15.343ZM2.441,18.831a1.046,1.046,0,1,0,1.046-1.046A1.045,1.045,0,0,0,2.441,18.831Z"
                  transform="translate(0 0)"
                  fill="#1f2937"
                />
                <path
                  id="Path_67"
                  data-name="Path 67"
                  d="M56.025.268l3.487,3.138a1.048,1.048,0,0,1,0,1.554L56.025,8.1a1.046,1.046,0,0,1-1.748-.776V5.579h-.7a2.792,2.792,0,0,0-2.79,2.79v7.266a3.477,3.477,0,0,0-2.79,0V8.369a5.579,5.579,0,0,1,5.579-5.579h.7V1.046A1.047,1.047,0,0,1,56.025.268Zm3.832,21a1.044,1.044,0,0,1-.619.955,1.032,1.032,0,0,1-1.129-.179l-3.487-3.138a1.046,1.046,0,0,1,0-1.552l3.487-3.138a1.032,1.032,0,0,1,1.129-.179,1.044,1.044,0,0,1,.619.955v1.744h.7a2.792,2.792,0,0,0,2.79-2.79V6.682a3.477,3.477,0,0,0,2.79,0v7.266a5.579,5.579,0,0,1-5.579,5.579h-.7Z"
                  transform="translate(-45.908 0)"
                  opacity="0.4"
                />
              </g>
            </svg>
            <h3 className="text-black font-medium text-sm">Replace Skill</h3>
          </div>
          <button
            className="rounded-full bg-bg-light p-1 shadow-md hover:bg-gray-100"
            onClick={() => setCollapse(!collapse)}
          >
            {!collapse ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </button>
        </div>
        {!collapse && (
          <div className="w-full px-4">
            {loading ? (
              <LoadingState />
            ) : (
              <div className="w-full flex flex-col">
                <Label className="max-w-[66%] text-xs">
                  Skill Name:
                  <SearchAndSelectInput
                    data={data?.getUnattachedSkills}
                    searchField="name"
                    search={search}
                    placeholder="Search for existing skill..."
                    setSearch={setSearch}
                    setSelected={(val) => {
                      setError(false);
                      setSkill(val);
                    }}
                    classes="my-2"
                  />
                </Label>
                <span className={`text-red-600 text-xs ${error ? 'block' : 'hidden'}`}>
                  Skill is required!
                </span>
                <Button
                  className="w-fit mt-2 px-3 py-2 rounded-md shadow-sm border border-gray-300 font-medium bg-bg-dark text-xs text-white hover:bg-gray-700"
                  onClick={() => handleReplaceSkill()}
                >
                  Replace Skill
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ReplaceSkillNode;
