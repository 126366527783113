import { gql } from '@apollo/client';

export const QueryUserEducation = gql`
  query QueryUserEducation($where: PersonWhere) {
    people(where: $where) {
      attendedConnection {
        edges {
          id
          degreeName
          majorName
          startDate
          endDate
          node {
            educationName
          }
        }
      }
    }
  }
`;

export const ConnectUserToEducation = gql`
  mutation ConnectUserToEducation($input: EducationInput!) {
    createEducation(input: $input) {
      __typename
    }
  }
`;

export const DisconnectUserFromEducation = gql`
  mutation DisconnectUserFromEducation($id: String!) {
    deleteEducation(id: $id) {
      __typename
    }
  }
`;

export const UpdateUserEducationConnection = gql`
  mutation UpdateUserEducationConnection($input: EducationInput!) {
    updateEducation(input: $input) {
      __typename
    }
  }
`;
