import { useAutoAnimate } from '@formkit/auto-animate/react';
import { classNames } from '../../lib/classNames';

export type SimpleSectionProps = {
  children: JSX.Element;
  className?: string;
};

const SimpleSection = ({ children, className }: SimpleSectionProps) => {
  // useAutoAnimate<T extends Element>(options?: Partial<AutoAnimateOptions> | AutoAnimationPlugin)
  const [parent] = useAutoAnimate<HTMLDivElement>({ duration: 100 });
  return (
    <div
      ref={parent}
      className={classNames(`px-5 py-5 shadow bg-white rounded-md w-full`, className)}
    >
      {children}
    </div>
  );
};

export default SimpleSection;
