import React, { useContext } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { QueryPersonBasics } from '../../../graphql/people';
import {
  DeleteProfile,
  QueryProfile,
  DownloadProfile,
  GenerateProfile,
  GetProfile,
  GetProfileDownloadLink,
  UpdateProfile,
} from '../../../graphql/profile';
import { QueryAllTemplates } from '../../../graphql/template';
import { useAuth } from '../../../lib/authContext';
import { useSelector, useDispatch } from 'react-redux';
import { QueryPersonProfileGenerator } from '../../../graphql/people';
import {
  cleanConnectData,
  copy,
  getConnectData,
  pathToArray,
  connectDataToDisplayData,
  makeCopyOfObject,
} from './ProfileGenerationContextHelpers';
import Profile from '../profile/ProfileGeneratorProfile';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import ProfileGeneratorDataDisplay from '../connect-data/ProfileGeneratorDataDisplay';
import {
  setSelectedPerson,
  completePoll,
  initializeProfileData,
  setProfileGeneratorState,
  triggerPolling,
} from './profileGeneratorSlice';
import { Amplify, Storage } from 'aws-amplify';
import { updateState } from './profileGeneratorSlice';
import { SkillSelector } from '../connect-data/ProfileGeneratorSkillDisplay';
import Modal from '../../../custom-prebuilt/Modal.component';
import OnGenerateModal from '../ProfileGeneratorOnGenerateModal';

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

const generationSuccess = 'Generated!';
const generationLoading = 'Generating';
const generationError =
  'There has been an error generating the profile. Please reach out to an admininstrator.';

const ProfileGeneratorContext = React.createContext();

const useProfileGeneratorInfo = () => {
  return useContext(ProfileGeneratorContext);
};

export const defaultGenerateChangeMessage = 'The profile was generated.';
export const defaultReGenerationMessage = 'The profile was regenerated.';

const verifyFileName = (fileName) => {
  // only contains words, spaces, and is not the empty string and doesn't contain only white space
  return !/[^\w ]/.test(fileName) && !!fileName.trim() && fileName !== '';
};

const fileNameError =
  'A file name may only contain letters and spaces. It cannot contain special characters, be empty or only contain spaces.';

const ProfileGeneratorProvider = ({ children }) => {
  const { userEmail, cognitoID } = useAuth();
  const dispatch = useDispatch();
  const defaultOrgUnit = useSelector((state) => state.org.defaultOrgUnit);
  const selectedPerson = useSelector((state) => state.profileGenerator.selectedPerson);
  const selectedField = useSelector((state) => state.profileGenerator.selectedField);
  const templates = useSelector((state) => state.profileGenerator.templates);
  const selectedTemplate = useSelector((state) => state.profileGenerator.selectedTemplate);
  const connectData = useSelector((state) => state.profileGenerator.connectData);
  const profileData = useSelector((state) => state.profileGenerator.profileData);
  const profileID = useSelector((state) => state.profileGenerator.profileID);
  const shouldPoll = useSelector((state) => state.profileGenerator.shouldPoll);
  const userProfiles = useSelector((state) => state.profileGenerator.userProfiles);
  const userProfilesLength = useSelector((state) => state.profileGenerator.userProfilesLength);
  const profileStatus = useSelector((state) => state.profileGenerator.profileStatus);
  const fetchNumber = useSelector((state) => state.profileGenerator.fetchNumber);
  const selectedProfile = useSelector((state) => state.profileGenerator.selectedProfile);
  const profileDataOriginal = useSelector((state) => state.profileGenerator.profileDataOriginal);
  const slideOverField = useSelector((state) => state.profileGenerator.slideOverField);
  const queryTree = useSelector((state) => state.profileGenerator.queryTree);
  const assets = useSelector((state) => state.profileGenerator.assets);
  const fileName = useSelector((state) => state.profileGenerator.fileName);
  const currentUser = useSelector((state) => state.profileGenerator.currentUser);
  const changeMessage = useSelector((state) => state.profileGenerator.changeMessage);
  const selectedPersonLoading = typeof selectedPerson === 'undefined';
  const [toastId, setToastId] = useState();
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isReGenerateModalOpen, setIsReGenerateModalOpen] = useState(false);

  const createGenerationPromise = async () => {
    const generationToastID = toast.loading(generationLoading);
    setToastId(generationToastID);
  };

  const cleanUpGenerationPromise = () => {
    toast.dismiss();
    setToastId();
  };

  const handleProfileServiceError = () => {
    toast.error(
      'There has been an error generating the profile. Please reach out to an admininstrator.',
    );
  };

  const handleGenerationError = () => {
    dispatch(setProfileGeneratorState({ shouldPoll: false, profileStatus: 'Error' }));
    if (typeof toastId !== 'undefined') {
      toast.error(generationError, { id: toastId });
    }
  };

  const getArrayItem = (path, index) => {
    const newProfileData = { ...profileData };
    let currentProfileData = newProfileData;
    const pathArray = pathToArray(path);
    const updateKey = pathArray.pop();
    for (let pathItem of pathArray) {
      currentProfileData = currentProfileData[pathItem];
    }
    return currentProfileData[index][updateKey];
  };

  const getValue = (path, index, styleName) => {
    const isArray = typeof index !== 'undefined' && index !== -1;
    if (typeof styleName !== 'undefined') {
      return getStyle(path, index)[styleName];
    }
    if (isArray) return getArrayItem(path, index, styleName);
    let currentProfileData = profileData;
    const pathArray = pathToArray(path);
    for (let pathItem of pathArray) {
      currentProfileData = currentProfileData[pathItem];
    }

    return currentProfileData;
  };

  const getStyle = (path, index) => {
    const styleValue = getValue(`${path}Styles`, index);
    if (typeof styleValue === 'undefined') return {};
    const styleObject = {};
    styleValue
      .split(';')
      .filter((style) => style !== '')
      .forEach((style) => {
        const styleMatch = style.match(/(?<styleName>.*):(?<styleValue>.*)/).groups;
        const reactStyleName = styleMatch.styleName.replace(/-(\w)/g, (match, letter) =>
          letter.toUpperCase(),
        );
        styleObject[reactStyleName] = styleMatch.styleValue;
      });
    return styleObject;
  };

  const onProfilesFetched = () => {
    if (typeof usersProfileData !== 'undefined' && typeof templates !== 'undefined') {
      const selectedProfileID = selectedProfile?.profileID;
      const profiles = usersProfileData.people[0].profiles.map((profile) => {
        if (typeof selectedProfileID !== 'undefined' && selectedProfileID === profile.profileID) {
          dispatch(setProfileGeneratorState({ changes: profile.changes }));
        }
        const template = templates.find((template) => template.templateID === profile.templateID);
        return {
          ...profile,
          template,
        };
      });
      dispatch(
        setProfileGeneratorState({ userProfiles: profiles, userProfilesLength: profiles.length }),
      );
    }
  };

  useQuery(QueryPersonBasics, {
    variables: { where: { email: userEmail } },
    skip: !userEmail,
    onError: handleProfileServiceError,
    onCompleted: (data) => {
      const dataString = JSON.stringify(data.people[0]);
      dispatch(setSelectedPerson(JSON.parse(dataString)));
      dispatch(setProfileGeneratorState({ currentUser: JSON.parse(dataString) }));
    },
  });

  const {
    data: usersProfileData,
    loading: profileLoading,
    refetch: refetchProfiles,
  } = useQuery(QueryProfile, {
    skip: selectedPersonLoading,
    variables: { where: { email: selectedPerson?.email } },
    onError: handleProfileServiceError,
    fetchPolicy: 'network-only',
    onCompleted: onProfilesFetched,
  });

  const [deleteProfileMutation, { loading: deleteLoading }] = useMutation(DeleteProfile, {
    onError: handleProfileServiceError,
    refetchQueries: [QueryProfile],
  });

  const { loading: templatesLoading } = useQuery(QueryAllTemplates, {
    onError: handleProfileServiceError,
    onCompleted: (data) => {
      const newTemplates = data.templates;
      dispatch(setProfileGeneratorState({ templates: newTemplates }));
      dispatch(setProfileGeneratorState({ selectedTemplate: newTemplates.at(0) }));
    },
  });

  const mergeDisplaySkills = (skill1, skill2) => {
    return {
      ...skill1,
      projectRatings: [...skill1.projectRatings, ...skill2.projectRatings],
    };
  };

  useQuery(QueryPersonProfileGenerator, {
    skip:
      typeof selectedPerson === 'undefined' ||
      defaultOrgUnit == null ||
      defaultOrgUnit?.orgUnit_id == null,
    variables: {
      where: { email: selectedPerson?.email },
      orgUnitsConnectionWhere2: {
        node: {
          orgUnit_id: defaultOrgUnit?.orgUnit_id,
        },
      },
      experienceConnectionWhere2: {
        node: {
          _on: {
            Project: {
              OR: [
                {
                  name_NOT_STARTS_WITH: 'JG',
                },
                {
                  name_IN: ['JG - Connect Development', 'JG - Tense'],
                },
              ],
            },
          },
        },
      },
      assessmentsWhere2: {
        personCompleted_SOME: {
          cognitoID: selectedPerson?.cognitoID,
        },
      },
    },
    onError: handleProfileServiceError,
    onCompleted: (result) => {
      const dataOutput = result.people[0];

      // remove all bad values from connect data (__typname, node, edges, etc.)
      const newConnectData = cleanConnectData(dataOutput);

      const skillPaths = [
        '.skillsConnection',
        ['.experienceConnection', 'assessments', 'skillsConnection'],
      ];
      // get all of a users skills, this is used to create the skills display
      let allSkillsCurrent = new Map();
      for (const skillPath of skillPaths) {
        let skillArray;
        if (Array.isArray(skillPath)) {
          const skillParent = getConnectData(skillPath[0], newConnectData);
          skillArray = skillParent
            .map((project) => {
              return project[skillPath[1]].map((assessment) => {
                return assessment[skillPath[2]].map((skill) => {
                  const newSkill = makeCopyOfObject(skill);
                  delete newSkill.rating;
                  newSkill.projectRatings = [skill.rating];
                  return newSkill;
                });
              });
            })
            .flat(Infinity)
            .filter(Boolean);
        } else {
          skillArray = getConnectData(skillPath, newConnectData).map((skill) => {
            const newSkill = makeCopyOfObject(skill);
            newSkill.projectRatings = [0];
            return newSkill;
          });
        }
        skillArray.forEach((skill) => {
          let skillToSet = skill;
          if (allSkillsCurrent.has(skill.name)) {
            const skillObj = allSkillsCurrent.get(skill.name);
            skillToSet = mergeDisplaySkills(skillObj, skill);
          }
          allSkillsCurrent.set(skill.name, skillToSet);
        });
      }
      const allSkills = Array.from(allSkillsCurrent.values());
      newConnectData.skillsConnection = allSkills;
      dispatch(
        setProfileGeneratorState({
          allSkills: allSkills,
          queryTree: connectDataToDisplayData(newConnectData),
          connectData: newConnectData,
        }),
      );
    },
  });

  const [createProfile] = useMutation(GenerateProfile, {
    onCompleted: (data) => {
      dispatch(triggerPolling(data.createProfile));
    },
    onError: handleGenerationError,
    refetchQueries: [QueryProfile],
  });

  const [updateProfile] = useMutation(UpdateProfile, {
    onCompleted: (data) => dispatch(triggerPolling(data.updateProfile)),
    onError: handleGenerationError,
    refetchQueries: [QueryProfile],
  });

  const [pollProfile] = useLazyQuery(GetProfile, {
    onCompleted: (data) => {
      dispatch(completePoll(data.profile));
    },
    onError: handleProfileServiceError,
    fetchPolicy: 'network-only',
  });

  const [getDownloadLink] = useLazyQuery(GetProfileDownloadLink, {
    onCompleted: (data) => {
      // copy the download link the clipboard
      copy(data.getProfileDownloadLink.downloadURL);
    },
    onError: handleProfileServiceError,
  });

  const [downloadProfilePDF] = useLazyQuery(DownloadProfile, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // downloads the profile
      const pdfData = new Uint8Array(
        atob(data.downloadProfile.profile)
          .split('')
          .map((char) => char.charCodeAt(0)),
      );
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = `${fileName}.pdf`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onError: handleProfileServiceError,
  });

  const closeRegenerateModal = () => setIsReGenerateModalOpen(false);
  const closeGenerateModal = () => setIsGenerateModalOpen(false);

  const openRegenerateModal = (disabled) => {
    if (disabled) return;
    setIsReGenerateModalOpen(true);
  };
  const openGenerateModal = (disabled) => {
    if (disabled) return;
    setIsGenerateModalOpen(true);
  };

  /**
   * Uploads input assets to S3
   * TODO: this eventually needs to download private assets
   */
  const uploadAssetsToS3 = async () => {
    dispatch(setProfileGeneratorState({ profileStatus: 'Uploading Assets' }));
    const newProfileData = JSON.parse(JSON.stringify(profileData));
    for (const assetName of Object.keys(assets)) {
      const assetFile = assets[assetName];
      const bucketPath = `users/${cognitoID}/pdf-asset-${assetFile.name}`;
      await Storage.put(`users/${cognitoID}/pdf-asset-${assetFile.name}`, assetFile, {
        level: 'public',
        bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
      });
      newProfileData.assets[
        assetName
      ] = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/${bucketPath}`;
    }

    return newProfileData;
  };

  const generateProfile = async () => {
    if (!verifyFileName(fileName)) {
      toast.error(fileNameError);
      return;
    }
    closeGenerateModal();
    try {
      const newProfileData = await uploadAssetsToS3();
      const variables = {
        input: {
          ownerID: selectedPerson.cognitoID,
          fileName,
          profileData: newProfileData,
          templateID: selectedTemplate.templateID,
          change: {
            message: changeMessage,
            authorName: currentUser.name,
            authorID: cognitoID,
            time: new Date().getTime().toString(),
          },
        },
      };
      dispatch(
        setProfileGeneratorState({
          profileData: newProfileData,
          shouldPoll: true,
          assets: [],
          profileStatus: 'Generating',
        }),
      );
      createGenerationPromise();
      await createProfile({ variables });
    } catch (error) {
      dispatch(setProfileGeneratorState({ profileStatus: 'Error' }));
    }
  };

  const duplicateProfile = async (profileData, templateID, fileName) => {
    const variables = {
      input: {
        ownerID: selectedPerson.cognitoID,
        fileName,
        profileData,
        templateID,
        change: {
          message: 'The profile was duplicated.',
          authorName: currentUser.name,
          authorID: cognitoID,
          time: new Date().getTime().toString(),
        },
      },
    };
    dispatch(
      setProfileGeneratorState({
        shouldPoll: true,
        profileStatus: 'Generating',
      }),
    );
    createGenerationPromise();
    await createProfile({ variables });
  };

  const reGenerateProfile = async () => {
    if (!verifyFileName(fileName)) {
      toast.error(fileNameError);
      return;
    }
    closeRegenerateModal();
    try {
      const newProfileData = await uploadAssetsToS3();
      const variables = {
        where: {
          ownerID: selectedPerson.cognitoID,
          profileID,
        },
        input: {
          ownerID: selectedPerson.cognitoID,
          fileName,
          profileData: newProfileData,
          templateID: selectedTemplate.templateID,
          change: {
            message: changeMessage,
            authorName: currentUser.name,
            authorID: cognitoID,
            time: new Date().getTime().toString(),
          },
        },
      };
      dispatch(
        setProfileGeneratorState({
          profileData: newProfileData,
          shouldPoll: true,
          assets: [],
          profileStatus: 'Generating',
        }),
      );
      createGenerationPromise();
      await updateProfile({ variables });
    } catch (error) {
      dispatch(setProfileGeneratorState({ profileStatus: 'Error' }));
    }
  };

  const createDownloadLink = async (disabled) => {
    if (disabled) return;
    const variables = {
      where: {
        ownerID: selectedPerson.cognitoID,
        profileID,
      },
    };
    await getDownloadLink({ variables });
  };

  const downloadProfile = async (ownerID, profileID) => {
    const variables = {
      where: {
        ownerID,
        profileID,
      },
    };
    const promise = downloadProfilePDF({ variables });
    await toast.promise(promise, {
      loading: 'Starting download...',
      success: 'Started download!',
    });
  };

  const downloadSelectedProfile = async (disabled) => {
    if (disabled) return;
    await downloadProfile(selectedPerson.cognitoID, profileID);
  };

  const setSelectedPersonContext = async (newPerson) => {
    // setting the profiles to the empty array creates a nice transition
    if (newPerson.email !== selectedPerson.email) {
      dispatch(setSelectedPerson(newPerson));
    } else await refetchProfiles();
  };

  const deleteProfile = async (ownerID, profileID) => {
    const variables = { where: { ownerID, profileID } };
    setProfileGeneratorState({ userProfiles: undefined });
    await deleteProfileMutation({ variables });
  };

  const onChangeField = (event, path) => {
    dispatch(updateState({ path, newValue: event.target.value }));
  };

  const currentProfile = useMemo(
    () => (
      <Profile
        profileData={profileData}
        template={selectedTemplate?.template}
        templateID={selectedTemplate?.templateID}
        onChangeField={onChangeField}
      />
    ),
    [profileData],
  );

  const openConnectData = (component) => {
    dispatch(toggleSlideOver({ show2: true }));
    dispatch(setProfileGeneratorState({ slideOverField: component }));
  };

  useEffect(() => {
    // if a users profiles data have been fetched map the correct template values to them
    onProfilesFetched();
  }, [usersProfileData, templates]);

  // sets up the profile data only after it has the necessary data
  useEffect(() => {
    if (
      typeof selectedTemplate !== 'undefined' &&
      typeof connectData !== 'undefined' &&
      typeof selectedPerson !== 'undefined' &&
      typeof profileID === 'undefined'
    ) {
      dispatch(initializeProfileData());
    }
  }, [selectedTemplate, connectData, selectedPerson]);

  useEffect(() => {
    if (profileStatus === 'Generated' || profileStatus === 'Regeneration Available') {
      const selectedProfileString = JSON.stringify(
        selectedProfile?.profileData ?? profileDataOriginal,
      );
      const currentProfileString = JSON.stringify(profileData);
      if (selectedProfileString === currentProfileString) {
        dispatch(setProfileGeneratorState({ profileStatus: 'Generated' }));
      } else dispatch(setProfileGeneratorState({ profileStatus: 'Regeneration Available' }));
    }
  }, [profileData]);

  /**
   * Polls the profile service every three seconds to see if the profile has generated
   */
  useEffect(() => {
    let timeout;
    if (
      typeof profileID !== 'undefined' &&
      profileID !== null &&
      shouldPoll &&
      profileStatus === 'Generating'
    ) {
      // poll every three seconds since when the profile generator is warm
      // executions take about this long
      timeout = setTimeout(async () => {
        const variables = { where: { profileID, ownerID: selectedPerson.cognitoID } };
        await pollProfile({ variables });
      }, 3000);
    } else if (profileStatus === 'Generated') {
      // make a true copy of the profile data for change comparison
      const trueCopy = JSON.stringify(profileData);
      dispatch(
        setProfileGeneratorState({ profileDataCopy: JSON.parse(trueCopy), shouldPoll: false }),
      );
      if (typeof toastId !== 'undefined') toast.success(generationSuccess, { id: toastId });
      setToastId();
      refetchProfiles();
    } else if (profileStatus === 'Error') {
      if (typeof toastId !== 'undefined') toast.error(generationError, { id: toastId });
      refetchProfiles();
    }
    return () => clearTimeout(timeout);
  }, [profileID, profileStatus, fetchNumber]);

  useEffect(() => {
    if (profileStatus === 'Error' && typeof toastId !== 'undefined')
      toast.error(generationError, { id: toastId });
  }, [toastId, profileStatus]);

  useEffect(() => {
    return cleanUpGenerationPromise;
  }, []);

  const dataDisplay = (
    <ProfileGeneratorDataDisplay
      displayData={queryTree}
      connectData={connectData}
      relevantFields={selectedField.relevantFields ?? []}
    >
      {slideOverField}
    </ProfileGeneratorDataDisplay>
  );

  const skillDisplay = useMemo(() => {
    if (selectedField.type === 'skill') {
      let path = selectedField.path;
      if (path.includes('.skillName')) path = path.replace('.skillName', '');
      return <SkillSelector path={path} />;
    }
    return null;
  }, [selectedField]);

  return (
    <ProfileGeneratorContext.Provider
      value={{
        setSelectedPerson: setSelectedPersonContext,
        deleteProfile,
        profileComponentsLoading:
          profileLoading || deleteLoading || userProfilesLength !== (userProfiles?.length ?? 0),
        profileGenerationStepsLoading:
          typeof selectedTemplate === 'undefined' ||
          typeof profileData === 'undefined' ||
          typeof connectData === 'undefined' ||
          typeof queryTree === 'undefined',
        generateProfile,
        duplicateProfile,
        reGenerateProfile,
        createDownloadLink,
        downloadSelectedProfile,
        openConnectData,
        downloadProfile,
        currentProfile,
        getValue,
        getStyle,
        dataDisplay,
        skillDisplay,
        openRegenerateModal,
        openGenerateModal,
      }}
    >
      <Modal
        destructiveButtonLabel="Cancel"
        onDestructiveButtonClick={closeGenerateModal}
        content={<OnGenerateModal />}
        open={isGenerateModalOpen}
        primaryButtonLabel="Confirm"
        onPrimaryButtonClick={generateProfile}
      />
      <Modal
        destructiveButtonLabel="Cancel"
        onDestructiveButtonClick={closeRegenerateModal}
        open={isReGenerateModalOpen}
        content={<OnGenerateModal />}
        primaryButtonLabel="Confirm"
        onPrimaryButtonClick={reGenerateProfile}
      />
      {templatesLoading ||
      typeof profileData === 'undefined' ||
      typeof currentUser === 'undefined' ? (
        <LoadingState />
      ) : (
        children
      )}
    </ProfileGeneratorContext.Provider>
  );
};

export { ProfileGeneratorProvider, useProfileGeneratorInfo };
