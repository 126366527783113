// Might be re-factor to re-use with projects-admin-view/skills-admin-view but for this implementation,
// separated components are used to boost customization

import {
  UserGroupIcon,
  UsersIcon,
  SearchIcon,
  UserAddIcon,
  IdentificationIcon,
} from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import { QueryAllPeople } from '../../../graphql/people';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getSections } from '../../../lib/getNameSections';
import SnapshotProfile from '../../../custom-prebuilt/snapshot/SnapshotProfile.component';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { useSelector, useDispatch } from 'react-redux';
import PeopleTools from './PeopleTools.component';
import { useAuth } from '../../../lib/authContext';
import ManageAdmins from './ManageAdmins.component';
import { useNestedNav } from '../../../lib/customHook';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import OrgUnitTools from './OrgUnitTools';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import { toggleModal } from '../../../redux/modalSlice';
import SearchLogic from '../../../custom-prebuilt/SearchLogic.component';
import { useApplyFilter } from '../../../lib/customHook';

const nestedNav = [
  {
    name: 'Directory',
    icon: UsersIcon,
  },
  {
    name: 'Member Invitations',
    icon: UserAddIcon,
  },
  {
    name: 'Manage Admins',
    icon: IdentificationIcon,
  },
  {
    name: 'Manage Org Units',
    icon: UserGroupIcon,
  },
];

const PeopleDirectoryAdminView = () => {
  const { userEmail } = useAuth();
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [peopleDirectory, setPeopleDirectory] = useState([]);
  const [initAllPeopleData, setInitAllPeopleData] = useState(null);
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const [allPeople] = useLazyQuery(QueryAllPeople);
  const [current, nestedNavComponent] = useNestedNav(nestedNav);
  const [parent] = useAutoAnimate({ duration: 150 });
  const dispatch = useDispatch();
  const show = useSelector((state) => state.slideOver.show);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const [searchInput, setSearchInput] = useState('');
  const [applyFilter, setApplyFilter, activeFilter, inactiveFilter, ModalComponent] =
    useApplyFilter();

  useEffect(() => {
    if (applyFilter) {
      setPeopleDirectory([]);
      setInitAllPeopleData(null);
      setSelectedPerson(null);
      allPeople({
        variables: {
          where: {
            uid: defaultOrgUid,
          },
          orgUnitsConnectionWhere2: {
            node: {
              organization: {
                uid: defaultOrgUid,
              },
            },
          },
          [!((!activeFilter && !inactiveFilter) || (activeFilter && inactiveFilter)) &&
          'membersWhere2']: {
            [(!activeFilter || !inactiveFilter) && 'active']: activeFilter,
          },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'standby', //prevent repetition when re-querying
      }).then((allPeopleData) => {
        const organizations = allPeopleData.data?.organizations;

        if (organizations) {
          setPeopleDirectory([
            ...getSections(
              [...organizations[0].orgUnit.map((o) => o.members).flat()].sort((a, b) =>
                a.name < b.name ? -1 : 1,
              ),
            ),
          ]); //Sort people alphabetically
          setInitAllPeopleData(allPeopleData);
        }

        setSelectedPerson(
          [
            ...getSections(
              [...(organizations[0]?.orgUnit.map((o) => o?.members).flat() || [])].sort((a, b) =>
                a.name < b.name ? -1 : 1,
              ),
            ),
          ][0]?.data[0],
        );
        setApplyFilter(false);
      });
    }
  }, [
    userEmail,
    defaultOrg,
    allPeople,
    defaultOrgUid,
    applyFilter,
    activeFilter,
    inactiveFilter,
    setApplyFilter,
  ]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);

    const oganizations = initAllPeopleData.data?.organizations;

    if (oganizations) {
      e.target.value === '' &&
        setPeopleDirectory([
          ...getSections(
            [...(oganizations[0]?.orgUnit.map((o) => o.members).flat() || [])].sort((a, b) =>
              a.name < b.name ? -1 : 1,
            ),
          ),
        ]);

      e.target.value !== '' &&
        setPeopleDirectory([
          ...getSections(
            oganizations[0]?.orgUnit
              .map((o) => o.members)
              .flat()
              .filter(
                (person) =>
                  person.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                  person.email.toLowerCase().includes(e.target.value.toLowerCase()),
              ),
          ),
        ]);
    }
  };

  const handleSelectPerson = (person) => {
    setSelectedPerson(person);
    dispatch(toggleSlideOver({ show: true, profileTarget: person }));
  };

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false, profileTarget: null }));
  };

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full gap-y-4">
      {nestedNavComponent}
      <div ref={parent} className="flex flex-grow">
        {current === 0 && (
          <>
            <aside className="lg:max-w-[20rem] 2xl:max-w-sm min-w-[20rem] 2xl:min-w-[24rem] flex-1 flex-grow border-r border-gray-200 xl:order-first lg:flex lg:flex-col">
              <div className="lg:px-6 pt-6 pb-4">
                <h2 className="text-sm font-medium text-gray-900">People Directory</h2>
                <p className="mt-1 text-xs text-gray-600">Select a person to view their profile</p>
                <div className="mt-2 flex space-x-4">
                  <div className="min-w-0 flex-1">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-signature focus:ring-signature
                                        text-xs"
                        placeholder="Search"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      dispatch(toggleModal({ show: true }));
                    }}
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-gray-800 px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-700
                        focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2 transition duration-300"
                  >
                    <FilterIcon className="h-4 w-4 text-white" aria-hidden="true" />
                  </button>

                  {ModalComponent}
                </div>
              </div>
              {/* Directory list */}
              <SearchLogic
                dataCheck={peopleDirectory.length}
                dataComponent={
                  <nav className="min-h-0 flex-1 overflow-y-auto flex-grow" aria-label="Directory">
                    {peopleDirectory?.map((el, index) => {
                      return (
                        <div key={index} className="relative">
                          <div className="sticky top-0 z-10 border-t border-b border-gray-200 px-6 py-1 text-sm font-medium text-gray-500 bg-gray-200">
                            <h3>{el.letter}</h3>
                          </div>
                          <ul className="relative z-0 divide-y divide-gray-200">
                            {peopleDirectory[index].data.map((person, index) => {
                              return (
                                <li key={index}>
                                  <div
                                    className={`relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-200 transition duration-300
                                              ${
                                                selectedPerson === person &&
                                                'ring-inset ring-signature bg-gray-200 ring-2'
                                              }`}
                                  >
                                    <div className="flex-shrink-0">
                                      <img
                                        className="h-10 w-10 rounded-full"
                                        referrerPolicy="no-referrer"
                                        src={
                                          person.userIconUrl !== null &&
                                          person.userIconUrl !== 'null'
                                            ? person.userIconUrl
                                            : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png'
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div className="focus:outline-none cursor-pointer">
                                        {/* Extend touch target to entire panel */}
                                        <span
                                          className="absolute inset-0"
                                          aria-hidden="true"
                                          onClick={() => {
                                            handleSelectPerson(person);
                                          }}
                                        />
                                        <p className="text-xs font-medium text-gray-900">
                                          {person.name}
                                        </p>
                                        <p className="truncate text-xs text-gray-500">
                                          {person?.orgUnitsConnection?.edges?.[0]?.position}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </nav>
                }
                loading={!peopleDirectory.length}
                searchInput={searchInput}
                reloadCondition={applyFilter}
              />
            </aside>
            {selectedPerson ? (
              <>
                <aside className="border-r border-gray-200 xl:order-first xl:flex xl:flex-col hidden flex-grow">
                  {selectedPerson ? (
                    <SnapshotProfile target={selectedPerson} coverPic={true} editable />
                  ) : null}
                </aside>
              </>
            ) : (
              <LoadingState />
            )}
          </>
        )}
        {current === 1 && <PeopleTools />}
        {current === 2 && <ManageAdmins />}
        {current === 3 && <OrgUnitTools />}
      </div>

      <SlideOver
        show={show}
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        className="xl:hidden"
        largeSize
        content={<SnapshotProfile target={target} coverPic={false} editable />}
      />
    </div>
  );
};

export default PeopleDirectoryAdminView;
