import React from 'react';
import { Navigate } from 'react-router';
import { useAuth } from '../lib/authContext';
import { useLocation } from 'react-router';

export default function ProtectedRoute({ children }) {
    const location = useLocation()
    const { authenticated } = useAuth();
    return( authenticated ? children : location.pathname.startsWith("/invitation") ? children : <Navigate to='/'/> )
}
