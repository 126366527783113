import { gql } from '@apollo/client';

export const CreateInvitation = gql`
  mutation CreateInvitation($input: InvitationCreateInput!, $dateInvited: String!) {
    createUserInvitation(input: $input, dateInvited: $dateInvited) {
      invitedAggregate {
        count
      }
    }
  }
`;

export const QueryAllOpenInvitations = gql`
  query QueryAllOpenInvitations($where: InvitationInvitedConnectionWhere) {
    invitations {
      invitedConnection(where: $where) {
        edges {
          dateInvited
          expired
          orgUnitName
          node {
            email
          }
        }
      }
    }
  }
`;
/*
{
  "where": {
    "edge": {
      "orgUnitName_IN": []
    }
  }
}
*/

export const AcceptInvitation = gql`
  mutation AcceptInvitation($disconnect: InvitationDisconnectInput) {
    updateInvitations(disconnect: $disconnect) {
      info {
        relationshipsDeleted
      }
    }
  }
`;

export const DeleteOpenInvitation = gql`
  mutation DeleteOpenInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      invitedAggregate {
        count
      }
    }
  }
`;

export const QueryOpenInvitation = gql`
  query QueryOpenInvitation($where: InvitationWhere) {
    invitations(where: $where) {
      invitedConnection {
        edges {
          dateInvited
          expired
          orgUnitName
          node {
            email
          }
        }
      }
    }
  }
`;
