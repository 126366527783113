import {
  UserGroupIcon,
  CollectionIcon,
  ClipboardListIcon,
  StatusOnlineIcon,
} from '@heroicons/react/outline';

const adminNavigation = [
  { name: 'People', href: 'people', icon: UserGroupIcon },
  { name: 'Projects', href: 'projects', icon: CollectionIcon },
  { name: 'Skills', href: 'skills', icon: ClipboardListIcon },
  { name: 'Communication', href: 'communication', icon: StatusOnlineIcon },
  {
    name: 'Connectors',
    href: 'connectors',
    icon: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        stroke="none"
        fill="currentColor"
        viewBox="0 0 17.641 13.23"
        {...props}
      >
        <path
          id="merge-sharp-solid"
          d="M5.513,64h.531l.331.413,4.079,5.1H13.23V67.308h1.1l3.308,3.308-3.308,3.308h-1.1V71.718H10.453l-4.079,5.1-.331.413H0V75.025H4.982l3.528-4.41-3.528-4.41H0V64H5.513Z"
          transform="translate(0 -64)"
        />
      </svg>
    ),
  },
  {
    name: 'Competency',
    href: 'competency',
    icon: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        stroke="none"
        fill="currentColor"
        viewBox="0 0 576 512"
        {...props}
      >
        {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
        <path d="M144 80v96H48V80h96zM48 32H0V80v96 48H48h96 10l70 93.3V336v96 48h48 96 48V432 336 288H368 272 262l-70-93.3V176 152H384v24 48h48 96 48V176 80 32H528 432 384V80v24H192V80 32H144 48zM368 336v96H272V336h96zM432 80h96v96H432V80z" />
      </svg>
    ),
  },
];

export default adminNavigation;
