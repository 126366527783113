const SkillInfo = ({ skill }) => {
  return (
    <>
      {skill && (
        <div data-cy="skill-info" className="space-y-4">
          <div className="px-4 py-4 shadow-md bg-white rounded-md">
            <p className="text-xs font-semibold mb-4">Basics</p>
            <div className="flex flex-row space-x-3">
              <img
                className="h-24 w-24"
                src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`}
                alt=""
              />
              <div className="flex flex-col space-y-2 flex-1">
                <p className="text-xs text-medium">{skill.name}</p>
                <p className="text-xs text-gray-500">{skill.description}</p>
              </div>
            </div>
          </div>

          <div className="px-4 py-4 shadow-md bg-white rounded-md">
            <p className="text-xs font-semibold mb-4">Category</p>
            <div className="space-y-1">
              <p className="text-xs">Job role: {skill.category[0].value}</p>
              <p className="text-xs">Category: {skill.category[1].value}</p>
            </div>
          </div>

          <div className="px-4 py-4 shadow-md bg-white rounded-md">
            <p className="text-xs font-semibold mb-4">
              People with this skill
              <span className="text-gray-500 font-normal ml-2">{skill.peopleWithSkill.length}</span>
            </p>
            <div className="flex flex-col space-y-4">
              {skill.peopleWithSkill.map((p, index) => (
                <div key={index} className="flex flex-row">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={p.userIconUrl}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                  <div className="flex flex-col ml-3">
                    <p className="text-xs text-medium">{p.name}</p>
                    <p className="text-xs text-gray-500">{p.email}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SkillInfo;
