import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AssessmentState = {
  generalAnswers: any[];
  teamAnswers: any[];
  assessmentAnswers: any[];
  selectedRole: any[];
  selectedSkills: any[];
  loading: boolean;
  projectDescription: string;
  project: any[];
};

const initialState: AssessmentState = {
  generalAnswers: [],
  teamAnswers: [],
  assessmentAnswers: [],
  selectedRole: [],
  selectedSkills: [],
  loading: true,
  projectDescription: '',
  project: [],
};

export const assessmentSlice = createSlice({
  name: 'action-input',
  initialState,
  reducers: {
    toggleAssessment: (state, action: PayloadAction<Partial<AssessmentState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleAssessment } = assessmentSlice.actions;

export default assessmentSlice.reducer;
