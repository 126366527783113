import { useEffect } from 'react';
import { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import ClickNHoldSkill from './ClickNHoldSkill.component';
import { classNames } from '../lib/classNames';
import ReactTooltip from 'react-tooltip';

const allCategoriesName = [
  { name: 'AWS', icon: '/SkillTagIcons/aws_icon.svg' },
  { name: 'Azure', icon: '/SkillTagIcons/azure_icon.svg' },
  { name: 'Back End', icon: '/SkillTagIcons/backend_icon.svg' },
  { name: 'Data', icon: '/SkillTagIcons/data_icon.svg' },
  { name: 'Design', icon: '/SkillTagIcons/design_icon.svg' },
  { name: 'DevOps', icon: '/SkillTagIcons/devops_icon.svg' },
  { name: 'Front End', icon: '/SkillTagIcons/frontend_icon.svg' },
  { name: 'Google Cloud', icon: '/SkillTagIcons/googlecloud_icon.svg' },
  { name: 'Languages', icon: '/SkillTagIcons/language_icon.svg' },
  { name: 'Mobile', icon: '/SkillTagIcons/mobile_icon.svg' },
  { name: 'Techniques', icon: '/SkillTagIcons/techniques_icon.svg' },
  { name: 'Testing', icon: '/SkillTagIcons/testing_icon.svg' },
  { name: 'Web Platforms', icon: '/SkillTagIcons/webplatforms_icon.svg' },
];

/**
 * @param skills the skills to organize by category
 * @param {(skill) => void} handleSelectedSkill (optional) the function called when a skill is selected
 * @param selectedSkills (optional) if the list of selected skills is provided then
 * selected skills are highlighted green
 * @param disableClickAndHold (optional) if included the click and hold functionality is disabled
 * @param {(skill) => Boolean} filter (optional) a function that is used to filter skills in a category
 * @param filterComponents (optional) components that will appear when a category is selected
 * @param resetFilters (optional) a function called when a new category is selcted to reset current filters
 * @param SkillComponent (optional) a react component that displays a single skill. If this prop is not included the default is used
 * @param categoryClasses (optional) allows a developer to apply custom styles to the list of categories displayed
 * @param skillListClasses (optional) allows a developer to apply custom styles to the list of skills displayed
 * @param displayType (optional) 'category' | 'skill'
 */
const SkillSelection = ({
  skills,
  handleSelectSkill,
  selectedSkills,
  disableClickAndHold,
  filter,
  filterComponents,
  resetFilters,
  SkillComponent,
  categoryClasses,
  skillListClasses,
  displayType = 'category',
}) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (typeof resetFilters !== 'undefined' && selectedCategory === '') resetFilters();
  }, [selectedCategory]);

  useEffect(() => {
    if (typeof skills !== 'undefined') {
      setAllCategories(
        allCategoriesName.map((c) => ({
          name: c.name,
          icon: c.icon,
          value: skills.filter((s) => s.category.find((ct) => ct.value === c.name)),
        })),
      );
    }
  }, [skills]);

  const SkillView = ({ skills }) => {
    return (
      <div className={classNames('flex flex-row flex-wrap', skillListClasses)}>
        {[...skills].filter(filter ?? (() => true)).map((skill, index) => (
          <div
            key={index}
            onClick={() => {
              handleSelectSkill(skill);
            }}
          >
            {SkillComponent ? (
              <SkillComponent
                preSelected={selectedSkills}
                skill={skill}
                disable={disableClickAndHold}
              />
            ) : (
              <div data-tip={skill.name} data-for="skill" key={index} className="w-14">
                <ClickNHoldSkill
                  preSelected={selectedSkills}
                  skill={skill}
                  disable={disableClickAndHold}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const CategoryView = () => {
    if (!selectedCategory)
      return (
        <div className={classNames('flex flex-wrap', categoryClasses)}>
          {allCategories
            .filter((category) => category.value.length > 0)
            .map((c, index) => (
              <div
                key={index}
                className="bg-white shadow rounded-md px-4 py-5 h-fit mr-3 mb-3 cursor-pointer grid grid-cols-[2rem_max-content]
              space-x-2 items-center hover:bg-gray-200 transition duration-300 
              "
                onClick={() => {
                  setSelectedCategory(c.name);
                }}
              >
                <img
                  src={require(`/src/assets${c.icon}`)}
                  alt=""
                  className="max-w-[2rem] max-h-[2rem] flex-1"
                />
                <div className="flex-1 space-y-1 box-border">
                  <p className="text-xs text-medium">{c.name}</p>
                  <p className="text-xs text-gray-500">{c.value.length} skills</p>
                </div>
              </div>
            ))}
        </div>
      );

    return (
      <div className="w-full">
        <div className="flex mb-3">
          <button className="text-xs text-gray-500 font-normal shadow rounded-md p-2 flex flex-row items-center cursor-default">
            {selectedCategory}
            <XIcon
              className="h-3 w-3 ml-1 text-gray-400 cursor-pointer"
              onClick={() => {
                setSelectedCategory('');
              }}
            />
          </button>
          {filterComponents}
        </div>
        <SkillView
          skills={[...allCategories].filter((c) => c.name === selectedCategory)[0].value}
        />
      </div>
    );
  };

  return (
    <div>
      <ReactTooltip multiline place="top" effect="solid" backgroundColor="#1F2937" id="skill" />
      {displayType === 'category' ? (
        <CategoryView />
      ) : (
        <div className="w-full">
          {typeof filterComponents === 'undefined' ? null : (
            <div className="flex py-2 mb-3">{filterComponents}</div>
          )}
          <SkillView
            skills={allCategories
              .map((c) => c.value)
              .flat()
              .sort((a, b) => b.rating - a.rating)}
          />
        </div>
      )}
    </div>
  );
};

export default SkillSelection;
