import ProfileNav from '../ProfileNav.component';
import UserLocation from './utils/UserLocation.component';
import UserUnitGroup from './utils/UserUnitGroup.component';
import { useSelector, useDispatch } from 'react-redux';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ProfileLayout from '../ProfileLayout.component';
import UserSkills from '../skill-cert/UserSkills.component';
import UserCert from '../skill-cert/UserCert.component';
import Modal from '../../../custom-prebuilt/Modal.component';
import { toggleModal } from '../../../redux/modalSlice';
import UserSkillCategory from '../skill-cert/UserSkillCategory.component';
import ProjectExperience from '../experience/ProjectExperience.component';
import { EXPERIENCE, OVERVIEW, SKILLS } from '../../../redux/profileNavSlice';

const UserDetails = ({ personDetails }) => {
  const current = useSelector((state) => state.profileNav.current);
  const skillCategoryList = useSelector((state) => state.modal.skills);
  const skillCategoryOpen = useSelector((state) => state.modal.show);
  const dispatch = useDispatch();
  const [parent] = useAutoAnimate();

  return (
    <div className="flex flex-col space-y-6 h-full overflow-x-hidden">
      <ProfileNav />
      <div
        ref={parent}
        className={
          current != EXPERIENCE
            ? 'xl:grid xl:grid-cols-2 w-full xl:gap-5 flex flex-col space-y-5 xl:space-y-0'
            : ''
        }
      >
        {current === OVERVIEW && (
          <ProfileLayout
            com1={<UserUnitGroup />}
            // com2={<UserGroups />}
            com3={<UserLocation userDetails={personDetails} />}
          />
        )}

        {current === SKILLS && (
          <ProfileLayout
            com1={<UserSkills userDetails={personDetails} />}
            com3={<UserCert userDetails={personDetails} header />}
          />
        )}
        {current === EXPERIENCE && <ProjectExperience userDetails={personDetails} />}
      </div>
      <Modal
        open={skillCategoryOpen}
        content={<UserSkillCategory skillCategoryList={skillCategoryList} />}
        onSecondaryButtonClick={() => {
          dispatch(toggleModal({ show: false, skills: skillCategoryList }));
          setTimeout(() => {
            dispatch(toggleModal({ show: false, skills: null }));
          }, 150);
        }}
        secondaryButtonLabel="Back"
      />
    </div>
  );
};

export default UserDetails;
