import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SlideOverState = {
  show: boolean;
  show1: boolean;
  show2: boolean;
  profileTarget: any;
  skill: any;
  editObject: any;
};

const initialState: SlideOverState = {
  show: false,
  show1: false, //In case there are more than 2 slide overs in one component
  show2: false, //In case there are more than 3 slide overs in one component
  profileTarget: null,
  skill: null,
  editObject: null,
};

export const slideOverSlice = createSlice({
  name: 'slide-over',
  initialState,
  reducers: {
    toggleSlideOver: (state, action: PayloadAction<Partial<SlideOverState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleSlideOver } = slideOverSlice.actions;

export default slideOverSlice.reducer;
