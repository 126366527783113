import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { mergeClasses } from '../../../lib/classNames';

export type PaginatorInput = {
  rowsPerPage: number;
  currentPage: number;
  totalRecords: number;
  maxPagesToDisplay?: number;
  onPageChange: (page: number) => void;
};

const Paginator = ({
  rowsPerPage,
  currentPage,
  totalRecords,
  maxPagesToDisplay = 9,
  onPageChange,
}: PaginatorInput) => {
  // must have 5 or more pages for ellipses to function correctly
  maxPagesToDisplay = Math.max(maxPagesToDisplay, 5);

  const activeClassname =
    'cursor-pointer relative z-10 inline-flex justify-center items-center border border-indigo-500 bg-indigo-50 px-4 py-3 text-xs font-medium text-indigo-600 focus:z-20 w-12';
  const ellipsesClassname =
    'cursor-pointer relative inline-flex justify-center items-center border border-gray-300 bg-white px-4 py-3 text-xs font-medium text-gray-500 w-12';
  const inactiveClassname = ellipsesClassname + ' focus:z-20 hover:bg-gray-50';

  const ellipsis = '...';

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / rowsPerPage));
  }, [totalRecords]);

  useEffect(() => {}, [currentPage]);

  function navigateToPreviousPage() {
    if (currentPage == 1) {
      return;
    }
    navigateToPage(currentPage - 1);
  }

  function navigateToNextPage() {
    if (currentPage === totalPages) {
      return;
    }
    navigateToPage(currentPage + 1);
  }

  function navigateToPage(page: number) {
    onPageChange(page);
  }

  function calculateVisibleRecordsLowerBound() {
    return (currentPage - 1) * rowsPerPage + 1;
  }

  function calculateVisibleRecordsUpperBound() {
    return currentPage * rowsPerPage > totalRecords ? totalRecords : currentPage * rowsPerPage;
  }

  const getPagesToDisplay = (): string[] => {
    let pages: string[];

    if (totalPages > maxPagesToDisplay) {
      const lowerEllipsisNeeded = currentPage > maxPagesToDisplay / 2;
      const upperEllipsisNeeded = totalPages - currentPage > maxPagesToDisplay / 2;

      pages = [];
      pages.push('1');

      let lowerLimit: number;

      if (lowerEllipsisNeeded) {
        pages.push(ellipsis);
        lowerLimit = currentPage - Math.floor(maxPagesToDisplay / 2) + 2;
      } else {
        lowerLimit = 2;
      }

      if (!upperEllipsisNeeded) {
        lowerLimit = totalPages - maxPagesToDisplay + 3;
      }

      for (let i = lowerLimit; pages.length < maxPagesToDisplay - 2; i++) {
        pages.push(`${i}`);
      }

      if (upperEllipsisNeeded) {
        pages.push(ellipsis);
      } else {
        pages.push(`${totalPages - 1}`);
      }

      pages.push(`${totalPages}`);
    } else {
      pages = Array.from(Array(totalPages).keys()).map((p) => `${p + 1}`);
    }

    return pages;
  };

  const RenderNumbers = (): JSX.Element => {
    if (totalRecords > 0 && totalPages > 0) {
      return (
        <div className="flex flex-nowrap">
          {
            //Array.from(Array(getPagesToDisplay).keys()).map((item, index) => {
            getPagesToDisplay().map((item, index) => {
              if (item !== ellipsis) {
                const pageNumber = +item;
                return (
                  <div
                    key={pageNumber}
                    onClick={() => navigateToPage(pageNumber)}
                    aria-current="page"
                    className={currentPage === pageNumber ? activeClassname : inactiveClassname}
                  >
                    {pageNumber}
                  </div>
                );
              } else {
                return (
                  <div key={`${index + 1}`} aria-current="page" className={ellipsesClassname}>
                    {item}
                  </div>
                );
              }
            })
          }
        </div>
      );
    } else {
      return <></>;
    }
  };

  type RecordCounterInput = { className?: string };

  const RenderRecordCounter = ({ className }: RecordCounterInput): JSX.Element => {
    // md:hidden
    const classes = mergeClasses('text-xs text-gray-700', className);
    return (
      <div className={classes}>
        <p>
          Showing&nbsp;<span className="font-medium">{calculateVisibleRecordsLowerBound()}</span>
          &nbsp;to&nbsp;
          <span className="font-medium">{calculateVisibleRecordsUpperBound()}</span>&nbsp;of&nbsp;
          <span className="font-medium">{totalRecords}</span>&nbsp;results
        </p>
      </div>
    );
  };

  const RenderSmallScreenNavBar = (): JSX.Element => {
    return (
      <>
        <div
          onClick={navigateToPreviousPage}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2
                  text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </div>
        <div
          onClick={navigateToNextPage}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2
                  text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </div>
      </>
    );
  };

  const RenderBigScreenNavBar = (): JSX.Element => {
    return (
      <>
        <RenderRecordCounter className={'hidden lg:flex pl-6'}></RenderRecordCounter>

        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <div
              onClick={navigateToPreviousPage}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2
                text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 cursor-pointer"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>

            <RenderNumbers></RenderNumbers>

            <div
              onClick={navigateToNextPage}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2
                text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 cursor-pointer"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={'space-y-2'}>
        <RenderRecordCounter className={'flex lg:hidden'}></RenderRecordCounter>

        <div className={'flex'}>
          <div className="flex flex-1 justify-between sm:hidden">
            <RenderSmallScreenNavBar></RenderSmallScreenNavBar>
          </div>
          <div
            className="hidden shrink items-center border-t border-gray-200 bg-white shadow rounded-md
                sm:flex sm:justify-start
                lg:flex-1 lg:justify-between"
          >
            <RenderBigScreenNavBar></RenderBigScreenNavBar>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paginator;
