import { PencilAltIcon, TrashIcon, DownloadIcon, DuplicateIcon } from '@heroicons/react/outline';
import { useMemo } from 'react';
import ProfileWrapper from './ProfileGeneratorWrapper';
import Handlebars from 'handlebars/dist/handlebars.min.js';
import { toTitle } from './state/ProfileGenerationContextHelpers';

const timeFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: 'numeric',
  hour: 'numeric',
  hourCycle: 'h12',
});

const UserProfile = ({ profile, onEdit, onDelete, onDownload, onDuplicate }) => {
  const { fileName, profileData, profileStatus, template, changes } = profile;
  const compileTemplate = Handlebars.compile(template.template);
  const lastChange = useMemo(() => {
    const lastChangeItem = { ...changes.at(-1) };
    lastChangeItem.time = timeFormat.format(new Date(parseInt(lastChangeItem.time)));
    return lastChangeItem;
  }, [changes]);

  const profileHTML = useMemo(
    () =>
      compileTemplate({
        ...(profileData ?? {}),
        remoteAssetDirectory: `https://${process.env.REACT_APP_S3_PUBLIC}.s3.amazonaws.com/templates/${template.templateID}/assets`,
      }),
    [profileData],
  );

  return (
    <ProfileWrapper html={profileHTML} wrapperClassNames={'bg-white'}>
      <div>
        <div className="pt-2 pb-2 flex flex-col gap-1 pl-4">
          <p className="text-font-dark truncate">
            <b>File Name:</b> {fileName}.pdf
          </p>
          <p className="text-font-dark">
            <b>Status:</b> {toTitle(profileStatus)}
          </p>
          <p className="text-font-dark">
            <b>Last Changed:</b> {lastChange.time}
          </p>
        </div>
        <div className="flex divide-x-[1px] border-t-[1px] text-sm">
          <button
            onClick={onDownload}
            className="flex basis-1/4 justify-center items-center text-font-dark p-2 hover:bg-gray-200 rounded-bl-md group"
          >
            <DownloadIcon className="h-6 w-6 text-font-light group-hover:text-font-dark" />
          </button>
          <button
            onClick={onEdit}
            className="flex basis-1/4 justify-center items-center text-font-dark p-2 hover:bg-gray-200 group"
          >
            <PencilAltIcon className="h-6 w-6 text-font-light  group-hover:text-font-dark" />
          </button>
          <button
            onClick={onDelete}
            className="flex basis-1/4 justify-center items-center text-font-dark p-2 hover:bg-gray-200 rounded-br-md group"
          >
            <TrashIcon className="h-6 w-6 text-font-light group-hover:text-font-dark" />
          </button>
          <button
            onClick={onDuplicate}
            className="flex basis-1/4 justify-center items-center text-font-dark p-2 hover:bg-gray-200 rounded-br-md group"
          >
            <DuplicateIcon className="h-6 w-6 text-font-light group-hover:text-font-dark" />
          </button>
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default UserProfile;
