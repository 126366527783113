import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import ClickNHoldSkill from '../../../../../../custom-prebuilt/ClickNHoldSkill.component';
import Modal from '../../../../../../custom-prebuilt/Modal.component';
import { toggleModal } from '../../../../../../redux/modalSlice';
import AssessmentAdjustConfirmation from './AssessmentAdjustConfirmation.component';
import AssessmentOverview from './AssessmentOverview.component';
import {
  CreateUserSkillRatings,
  DeleteUserSkills,
  AddUserSkillAssessment,
} from '../../../../../../graphql/assessment';
import { useMutation } from '@apollo/client';
import { QueryPersonSkills } from '../../../../../../graphql/people';
import { QueryAllSkills } from '../../../../../../graphql/skills';
import { useAuth } from '../../../../../../lib/authContext';
import toast from 'react-hot-toast';
import moment from 'moment';

const AssessmentConfirmation = () => {
  const { userEmail } = useAuth();
  const selectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const show = useSelector((state) => state.modal.show);
  const show1 = useSelector((state) => state.modal.show1);
  const skill = useSelector((state) => state.modal.skill);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const knowledgableSkills = [...selectedSkills].filter((s) => s.rating === 1);
  const proficientSkills = [...selectedSkills].filter((s) => s.rating === 2);
  const leadTeachSkills = [...selectedSkills].filter((s) => s.rating === 3);

  const [createUserSkillRatings] = useMutation(CreateUserSkillRatings, {
    refetchQueries: () => [
      {
        query: QueryPersonSkills,
        variables: {
          where: {
            email: userEmail || '',
          },
        },
      },
      {
        query: QueryAllSkills,
        variables: {
          where: {
            node: {
              email: userEmail || '',
            },
          },
        },
      },
    ],
  });

  const [deleteUserSkills] = useMutation(DeleteUserSkills, {
    refetchQueries: () => [
      {
        query: QueryPersonSkills,
        variables: {
          where: {
            email: userEmail || '',
          },
        },
      },
      {
        query: QueryAllSkills,
        variables: {
          where: {
            node: {
              email: userEmail || '',
            },
          },
        },
      },
    ],
  });

  const [addUserSkillAssessment] = useMutation(AddUserSkillAssessment, {
    refetchQueries: ['GetLastAssessment'],
  });

  const handleSelectSkill = (skill) => {
    dispatch(toggleModal({ show: true, skill: skill }));
  };

  const handleCloseModal = () => {
    dispatch(toggleModal({ show: false, skill: null }));
  };

  const handleCloseModalConfirmation = () => {
    dispatch(toggleModal({ show1: false }));
    navigate(`${getLocationPrevLevel(location)}/introduction`);
  };

  const handleSubmitAssessment = () => {
    const input = selectedSkills.map((skill) => {
      return { skillName: skill.node.name, ratingValue: skill.rating };
    });

    const skillUpdate = input.length
      ? createUserSkillRatings({
          variables: { email: userEmail || '', input },
        })
      : deleteUserSkills({
          variables: { email: userEmail || '' },
        });

    toast.promise(
      skillUpdate.then(() => {
        addUserSkillAssessment({
          variables: {
            email: userEmail || '',
            dateCompleted: moment().format('L'),
          },
        }).then(() => {
          dispatch(toggleModal({ show1: true }));
        });
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
    );
  };

  return (
    <div className="h-full w-full xl:pl-4">
      <Modal
        open={show}
        title={`${skill?.node.name || 'Skill'} level`}
        content={<AssessmentAdjustConfirmation skill={skill} />}
        primaryButtonLabel="Confirm"
        onPrimaryButtonClick={handleCloseModal}
        onSecondaryButtonClick={handleCloseModal}
      />

      <Modal
        open={show1}
        primaryButtonLabel="OK"
        onPrimaryButtonClick={handleCloseModalConfirmation}
        onSecondaryButtonClick={handleCloseModalConfirmation}
        content={<AssessmentOverview />}
      />

      <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-5 h-full">
        <div className="flex flex-col space-y-5 flex-1">
          <div className="px-4 py-5 shadow bg-white rounded-md w-full">
            <div className="flex flex-row justify-between">
              <p className="text-xs mb-4 font-semibold">Confirm skills</p>
            </div>
            <p className="text-xs mb-2">
              Please confirm the skills below match your current skillset
            </p>
            <p className="text-xs mb-2">
              Tip: You can click on a skill to adjust its rating or remove it
            </p>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer mr-2"
              onClick={() => {
                navigate(`${getLocationPrevLevel(location)}/rate`);
              }}
            >
              Back
            </button>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer"
              onClick={() => {
                handleSubmitAssessment();
              }}
            >
              Submit
            </button>
          </div>

          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="px-4 py-5 shadow bg-white rounded-md col-span-1">
              <p className="text-xs font-semibold mb-4">Knowledgable rated skills</p>
              <div className="flex flex-row flex-wrap">
                {knowledgableSkills.map((skill, index) => (
                  <div
                    key={index}
                    className="w-14"
                    onClick={() => {
                      handleSelectSkill(skill);
                    }}
                  >
                    <ClickNHoldSkill skill={skill.node} disable />
                  </div>
                ))}
              </div>
            </div>

            <div className="px-4 py-5 shadow bg-white rounded-md col-span-1">
              <p className="text-xs font-semibold mb-4">Proficient rated skills</p>
              <div className="flex flex-row flex-wrap">
                {proficientSkills.map((skill, index) => (
                  <div
                    key={index}
                    className="w-14"
                    onClick={() => {
                      handleSelectSkill(skill);
                    }}
                  >
                    <ClickNHoldSkill skill={skill.node} disable />
                  </div>
                ))}
              </div>
            </div>

            <div className="px-4 py-5 shadow bg-white rounded-md col-span-1">
              <p className="text-xs font-semibold mb-4">Lead/Teach rated skills</p>
              <div className="flex flex-row flex-wrap">
                {leadTeachSkills.map((skill, index) => (
                  <div
                    key={index}
                    className="w-14"
                    onClick={() => {
                      handleSelectSkill(skill);
                    }}
                  >
                    <ClickNHoldSkill skill={skill.node} disable />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentConfirmation;
