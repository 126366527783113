import { Fragment, useState } from 'react';
import { SearchIcon, SupportIcon, ExclamationIcon } from '@heroicons/react/solid';
import {
  UsersIcon,
  HomeIcon,
  CollectionIcon,
  ClipboardListIcon,
  ChartSquareBarIcon,
} from '@heroicons/react/outline';
import { Combobox, Dialog, Transition, Switch } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSearch } from '../redux/searchSlice';
import { classNames } from '../lib/classNames';
import { useQuery } from '@apollo/client';
import { QueryAllPeople } from '../graphql/people';
import { QueryAllSkills } from '../graphql/skills';
import { useNavigate } from 'react-router';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { toggleBugReport } from '../redux/bugReportSlice';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { skills_split, projects_split } from '../split.io/sdk.config';
import { RootState } from '../redux/store';
import Modal from './Modal.component';
import RequestItem from './RequestItem';

// import defaultImg from '../assets/default-user.svg';
const defaultImg: string = require('../assets/default-user.svg').default;

const projects = [{ id: 1, name: 'Org name / Proj name', category: 'Projects', url: '#' }];

const sites = [
  {
    __typename: 'Site',
    name: 'Home',
    icon: HomeIcon,
    pathname: '/',
  },
  {
    __typename: 'Site',
    name: 'People',
    icon: UsersIcon,
    pathname: '/people',
  },
  {
    __typename: 'Site',
    name: 'Projects',
    icon: CollectionIcon,
    pathname: '/projects',
  },
  {
    __typename: 'Site',
    name: 'Skills',
    icon: ClipboardListIcon,
    pathname: '/skills',
  },
  {
    __typename: 'Site',
    name: 'Assessments',
    icon: ChartSquareBarIcon,
    pathname: '/assessment',
  },
];

const SearchComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = useSelector((state: RootState) => state.search.show);
  const defaultOrgUid = useSelector((state: RootState) => state.org.defaultOrgUid);
  const [showQuickLink, setShowQuickLink] = useState(true);
  const [sitesParent] = useAutoAnimate<HTMLDivElement>({ duration: 250 });
  const [resParent] = useAutoAnimate<HTMLDivElement>({ duration: 100 });
  const [bugParent] = useAutoAnimate<HTMLDivElement>({ duration: 100 });
  const [open, setOpen] = useState(false);

  const closeModal = () => setOpen(false);

  const queryAllPeople = useQuery(QueryAllPeople, {
    variables: {
      where: {
        uid: defaultOrgUid,
      },
      membersWhere2: {
        active: true,
      },
    },
    skip: !show,
  });

  const queryAllSkills = useQuery(QueryAllSkills, { skip: !show });

  const users = queryAllPeople?.data?.organizations[0]?.orgUnit.map((o: any) => o.members).flat();
  const skills = queryAllSkills?.data?.skills;

  const [rawQuery, setRawQuery] = useState('');

  const query = rawQuery.toLowerCase().replace(/^[#/@/>]/, '');

  const projectsShortcut = '#';
  const isProjectsQuery = rawQuery.startsWith(projectsShortcut);
  const skillsShortcut = '>';
  const isSkillsQuery = rawQuery.startsWith(skillsShortcut);
  const usersShortcut = '@';
  const isUsersQuery = rawQuery.startsWith(usersShortcut);

  const getFilteredFunction = (objects: any[]) => {
    return objects?.filter((object) => object.name.toLowerCase().includes(query));
  };

  let filteredSkills: any[] = [];
  let filteredUsers: any[] = [];
  let filteredProjects: any[] = [];

  if (isSkillsQuery) {
    filteredSkills = getFilteredFunction(skills);
  } else if (isUsersQuery) {
    filteredUsers = getFilteredFunction(users);
  } else if (isProjectsQuery) {
    filteredProjects = getFilteredFunction(projects);
  } else if (query !== '') {
    filteredProjects = getFilteredFunction(projects);
    filteredSkills = getFilteredFunction(skills);
    filteredUsers = getFilteredFunction(users);
  }

  const handleItemRedirect = (item: any) => {
    if (item?.__typename === 'Person') {
      navigate(`/${item.email.split('.').at(0)}`);
    } else if (item?.__typename === 'Site') {
      navigate(`${item.pathname}`);
    } else if (item === 'bug') {
      dispatch(toggleBugReport({ show: true }));
    } else if (item?.__typename === 'Skill') {
      navigate(`/skills/${item.slug}`);
    }
    dispatch(toggleSearch({ show: false }));
  };

  return (
    <>
      <Transition.Root
        show={show}
        as={Fragment}
        afterLeave={() => {
          setRawQuery('');
          setShowQuickLink(true);
        }}
        appear
      >
        <Dialog
          as="div"
          className="relative z-[1000]"
          onClose={() => {
            dispatch(toggleSearch({ show: false }));
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5
              transition-all"
              >
                <Combobox
                  onChange={(item) => {
                    handleItemRedirect(item);
                  }}
                >
                  <div className="relative">
                    <SearchIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 text-xs"
                      placeholder="Go to..."
                      onChange={(event) => {
                        setRawQuery(event.target.value);
                        event.target.value === ''
                          ? setShowQuickLink(true)
                          : setShowQuickLink(false);
                      }}
                    />
                    <div className="flex flex-row px-4 mb-2 justify-between items-center">
                      <div className="flex flex-row">
                        <Switch
                          checked={showQuickLink}
                          onChange={setShowQuickLink}
                          className={classNames(
                            showQuickLink ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              showQuickLink ? 'translate-x-4' : 'translate-x-0',
                              'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          />
                        </Switch>
                        <p className="ml-2 text-xs text-gray-500 font-medium">Show quick links</p>
                      </div>
                      <div className="flex flex-row space-x-2">
                        <button
                          className="px-3 py-2 rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50 text-xs font-medium text-gray-500"
                          onClick={() => {
                            dispatch(toggleSearch({ show: false }));
                            setOpen(true);
                          }}
                        >
                          Request a skill
                        </button>
                        <button
                          className="px-3 py-2 rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50 text-xs font-medium text-gray-500"
                          onClick={() => {
                            dispatch(toggleSearch({ show: false }));
                            dispatch(toggleBugReport({ show: true }));
                          }}
                        >
                          Report a bug
                        </button>
                      </div>
                    </div>
                  </div>

                  <div ref={sitesParent}>
                    {showQuickLink &&
                      sites.map((site, index) => (
                        <SplitTreatments key={index + 10} names={[skills_split, projects_split]}>
                          {({ treatments, isReady }) => {
                            return (isReady &&
                              treatments[skills_split].treatment === 'off' &&
                              site.name === 'Skills') ||
                              (treatments[projects_split].treatment === 'off' &&
                                site.name === 'Projects') ? null : (
                              <Combobox.Option
                                key={site.name}
                                value={site}
                                className={({ active }) =>
                                  classNames(
                                    'flex select-none items-center px-4 py-3 text-gray-600 cursor-pointer transition duration-150',
                                    active ? 'bg-gray-900 !text-white' : '',
                                  )
                                }
                              >
                                <site.icon className="h-4 w-4" aria-hidden="true" />
                                <span className="ml-2 flex-auto truncate text-xs">{site.name}</span>
                                <span className="flex-none text-xs ml-auto">{site.pathname}</span>
                              </Combobox.Option>
                            );
                          }}
                        </SplitTreatments>
                      ))}
                  </div>

                  <Combobox.Options static>
                    <div ref={bugParent}>
                      {query !== '' && 'bug'.toLowerCase().includes(query) && (
                        <Combobox.Option
                          value="bug"
                          className={({ active }) =>
                            classNames(
                              'flex select-none items-center px-4 py-3 text-gray-600 cursor-pointer transition duration-150',
                              active ? 'bg-gray-900 !text-white' : '',
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            className="h-4 w-4"
                            aria-hidden="true"
                            stroke="currentColor"
                            fill="currentColor"
                          >
                            <path d="M24 42q-3.25 0-6.05-1.55T13.8 36H8v-3h4.6q-.35-1.3-.35-2.625V27.7h-4.3v-3h4.3q0-1.45.025-2.875T12.7 19H8v-3h6q.7-1.4 1.85-2.45Q17 12.5 18.4 11.8L14.55 8l2-2 4.7 4.7q1.4-.5 2.825-.5 1.425 0 2.825.5L31.6 6l2 2-3.8 3.8q1.4.7 2.475 1.775Q33.35 14.65 34.15 16h5.9v3H35.3q.45 1.4.425 2.825Q35.7 23.25 35.7 24.7h4.35v3H35.7q0 1.35.025 2.675Q35.75 31.7 35.4 33h4.65v3h-5.8q-1.3 2.95-4.125 4.475Q27.3 42 24 42Zm0-3q3.6 0 6.15-2.525 2.55-2.525 2.55-6.125V22q0-3.6-2.55-6.125T24 13.35q-3.6 0-6.15 2.525Q15.3 18.4 15.3 22v8.35q0 3.6 2.55 6.125T24 39Zm-4-7h8v-3h-8Zm0-8.65h8v-3h-8Zm4 2.85h.025H24h.025H24h.025H24h.025H24Z" />
                          </svg>
                          <span className="ml-2 flex-auto truncate text-xs">Report a bug</span>
                        </Combobox.Option>
                      )}
                    </div>

                    <div
                      ref={resParent}
                      className="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto px-4"
                    >
                      {(filteredProjects?.length > 0 ||
                        filteredUsers?.length > 0 ||
                        filteredSkills?.length > 0) && (
                        <>
                          <SplitTreatments names={[projects_split]}>
                            {({ treatments, isReady }) => {
                              return isReady && treatments[projects_split].treatment === 'on' ? (
                                <div>
                                  {filteredProjects?.length > 0 && (
                                    <li>
                                      <h2 className="text-xs font-semibold text-gray-900">
                                        Projects
                                      </h2>
                                      <ul className="-mx-4 mt-2 text-xs text-gray-700">
                                        {filteredProjects.map((project) => (
                                          <Combobox.Option
                                            key={project.id}
                                            value={project}
                                            className={({ active }) =>
                                              classNames(
                                                'flex select-none items-center px-4 py-2 cursor-pointer transition duration-150',
                                                active ? 'bg-gray-900 text-white' : '',
                                              )
                                            }
                                          >
                                            {({ active }) => (
                                              <>
                                                <CollectionIcon
                                                  className={classNames(
                                                    'h-4 w-4 flex-none',
                                                    active ? 'text-white' : 'text-gray-400',
                                                  )}
                                                  aria-hidden="true"
                                                />
                                                <span className="ml-2 flex-auto truncate">
                                                  {project.name}
                                                </span>
                                              </>
                                            )}
                                          </Combobox.Option>
                                        ))}
                                      </ul>
                                    </li>
                                  )}
                                </div>
                              ) : null;
                            }}
                          </SplitTreatments>

                          <SplitTreatments names={[skills_split]}>
                            {({ treatments, isReady }) => {
                              return isReady && treatments[skills_split].treatment === 'on' ? (
                                <div>
                                  {filteredSkills?.length > 0 && (
                                    <li>
                                      <h2 className="text-xs font-semibold text-gray-900">Skill</h2>
                                      <ul className="-mx-4 mt-2 text-xs text-gray-700">
                                        {filteredSkills.map((skill, index) => (
                                          <Combobox.Option
                                            key={index}
                                            value={skill}
                                            className={({ active }) =>
                                              classNames(
                                                'flex select-none items-center px-4 py-2 cursor-pointer transition duration-150',
                                                active ? 'bg-gray-900 text-white' : '',
                                              )
                                            }
                                          >
                                            {/* Params: { active } */}
                                            {() => (
                                              <>
                                                <img
                                                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`}
                                                  alt=""
                                                  className="h-4 w-4 flex-none"
                                                />
                                                <span className="ml-2 flex-auto truncate">
                                                  {skill.name}
                                                </span>
                                              </>
                                            )}
                                          </Combobox.Option>
                                        ))}
                                      </ul>
                                    </li>
                                  )}
                                </div>
                              ) : null;
                            }}
                          </SplitTreatments>

                          {filteredUsers?.length > 0 && (
                            <li>
                              <h2 className="text-xs font-semibold text-gray-900">Users</h2>
                              <ul className="-mx-4 mt-2 text-xs text-gray-700">
                                {filteredUsers.map((user) => (
                                  <Combobox.Option
                                    key={user.email}
                                    value={user}
                                    className={({ active }) =>
                                      classNames(
                                        'flex cursor-pointer select-none items-center px-4 py-2 transition duration-150',
                                        active ? 'bg-gray-900 text-white' : '',
                                      )
                                    }
                                  >
                                    <img
                                      src={user?.userIconUrl ?? defaultImg}
                                      alt=""
                                      className="h-4 w-4 flex-none rounded-full"
                                      referrerPolicy="no-referrer"
                                    />
                                    <span className="ml-2 flex-auto truncate">{user.name}</span>
                                  </Combobox.Option>
                                ))}
                              </ul>
                            </li>
                          )}
                        </>
                      )}
                    </div>
                  </Combobox.Options>
                  {rawQuery === '?' && (
                    <div className="py-14 px-6 text-center text-xs sm:px-14">
                      <SupportIcon className="mx-auto h-4 w-4 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">Help with searching</p>
                      <p className="mt-2 text-gray-500">
                        Use this tool to quickly search for users and projects across our entire
                        platform. You can also use the search modifiers found in the footer below to
                        limit the results to just users or projects.
                      </p>
                    </div>
                  )}

                  <SplitTreatments names={[skills_split, projects_split]}>
                    {({ treatments, isReady }) => {
                      return isReady &&
                        query !== '' &&
                        rawQuery !== '?' &&
                        !filteredUsers?.length &&
                        (treatments[skills_split].treatment === 'off' ||
                          treatments[projects_split].treatment === 'off') ? (
                        <div className="py-14 px-6 text-center text-xs sm:px-14">
                          <ExclamationIcon
                            className="mx-auto h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                          <p className="mt-4 font-semibold text-gray-900">No results found</p>
                          <p className="mt-2 text-gray-500">
                            We couldn’t find anything with that term. Please try again.
                          </p>
                        </div>
                      ) : (
                        <div>
                          {query !== '' &&
                            rawQuery !== '?' &&
                            !filteredProjects?.length &&
                            !filteredSkills?.length &&
                            !filteredUsers?.length && (
                              <div className="py-14 px-6 text-center text-xs sm:px-14">
                                <ExclamationIcon
                                  className="mx-auto h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p className="mt-4 font-semibold text-gray-900">No results found</p>
                                <p className="mt-2 text-gray-500">
                                  We couldn’t find anything with that term. Please try again.
                                </p>
                              </div>
                            )}
                        </div>
                      );
                    }}
                  </SplitTreatments>

                  <div className="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
                    Type{' '}
                    <SplitTreatments names={[projects_split]}>
                      {({ treatments, isReady }) => {
                        return isReady && treatments[projects_split].treatment === 'on' ? (
                          <span>
                            <kbd
                              className={classNames(
                                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 sm:inline-flex',
                                isProjectsQuery
                                  ? 'border-gray-900 text-gray-900'
                                  : 'border-gray-400 text-gray-900',
                              )}
                            >
                              {projectsShortcut}
                            </kbd>{' '}
                            <span className="hidden sm:inline">for projects,</span>
                          </span>
                        ) : null;
                      }}
                    </SplitTreatments>
                    <SplitTreatments names={[skills_split]}>
                      {({ treatments, isReady }) => {
                        return isReady && treatments[skills_split].treatment === 'on' ? (
                          <span>
                            <kbd
                              className={classNames(
                                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 sm:inline-flex',
                                rawQuery === '?'
                                  ? 'border-gray-900 text-gray-900'
                                  : 'border-gray-400 text-gray-900',
                              )}
                            >
                              {skillsShortcut}
                            </kbd>{' '}
                            <span className="hidden sm:inline">for skills,</span>
                          </span>
                        ) : null;
                      }}
                    </SplitTreatments>
                    <kbd
                      className={classNames(
                        'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                        isUsersQuery
                          ? 'border-gray-900 text-gray-900'
                          : 'border-gray-400 text-gray-900',
                      )}
                    >
                      {usersShortcut}
                    </kbd>{' '}
                    for users, and{' '}
                    <kbd
                      className={classNames(
                        'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                        rawQuery === '?'
                          ? 'border-gray-900 text-gray-900'
                          : 'border-gray-400 text-gray-900',
                      )}
                    >
                      ?
                    </kbd>{' '}
                    for help.
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal
        open={open}
        onSecondaryButtonClick={closeModal}
        title="Request a Skill"
        content={<RequestItem closeModal={closeModal} requestType={'skill'} />}
      />
    </>
  );
};

export default SearchComponent;
