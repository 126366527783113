import { XIcon, PlusIcon, MenuIcon } from '@heroicons/react/outline';
import { useProfileGeneratorInfo } from '../state/ProfileGeneratorContext';
import { pathToArray, camelCaseToTitle } from '../state/ProfileGenerationContextHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToArray,
  removeFromArray,
  selectField,
  rearrangeArray,
} from '../state/profileGeneratorSlice';
import { createElement, useMemo, useState, useEffect, useRef } from 'react';
import { mergeClasses } from '../../../lib/classNames';
import Input, { InputClasses, InputFocusClasses } from './ProfileGeneratorProfileInput';
import ReactTooltip from 'react-tooltip';

const DragOver = ({ children, isDragging, onDrop, onDragStart }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onDragOver = (event) => {
    event.preventDefault();
    setIsHovered(true);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsHovered(false);
  };

  useEffect(() => {
    setIsHovered(false);
  }, [isDragging]);

  return (
    <div
      draggable
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragStart={onDragStart}
      className={isHovered && isDragging ? 'bg-[rgba(194,199,208,1)] rounded-sm' : 'bg-transparent'}
    >
      {children}
    </div>
  );
};

const ArrayWrapper = ({ title, toolbar, isEmpty = false, isFocused, children, selectField }) => {
  return (
    <div className="relative">
      {isFocused && (
        <>
          <div className="absolute w-max flex z-[51] items-center bg-white shadow-md rounded-md bottom-[calc(100%_+_8px)] -left-1">
            {toolbar}
          </div>
          <div className="absolute bottom-[calc(100%_+_8px)] -right-1 z-[51] bg-white shadow-md rounded-md flex gap-3">
            <p className="text-base font-medium text-[#1F2938] p-2 font-['Libre_Franklin']">
              {title}
            </p>
          </div>
        </>
      )}
      {isEmpty && (
        <div
          onClick={selectField}
          style={{ clipPath: 'circle()' }}
          className="absolute right-[calc(100%_+_8px)] z-[52] w-3 h-3 bg-red-400"
        />
      )}
      {children}
    </div>
  );
};

const ArrayInputs = ({
  path,
  listWrapper,
  onChange,
  relevantFields,
  listItem,
  title,
  type,
  templateItem,
}) => {
  const dispatch = useDispatch();
  const arrayWrapperRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const selectedField = useSelector((state) => state.profileGenerator.selectedField);
  const profileData = useSelector((state) => state.profileGenerator.profileData);
  const isThisFocused = useMemo(
    () => selectedField.path.includes(path) || path.includes(selectedField.path),
    [path, selectedField],
  );
  const pathOfArray = useMemo(() => `.${pathToArray(path).slice(0, -1).join('.')}`, [path]);
  const { getValue } = useProfileGeneratorInfo();
  const listValue = useMemo(() => getValue(pathOfArray), [pathOfArray, profileData]);
  const defaultStyle = useMemo(() => getValue(`${pathOfArray}DefaultStyles`), [path]);

  const listItemOverrides = listItem.match(
    /{"type":"\$item","props":{"className":"(?<className>.*)"}}/,
  ).groups.className;
  // const listItemOverrides = useMemo(() => listItem.match(/,?{"type":"\$item","props":{"className":"(.*)"}}/)?.[0] ?? "", [listItem])
  const editAttribute = pathToArray(path).pop();
  const editAttributeTitle = camelCaseToTitle(editAttribute);

  const deleteArrayItem = (index) => {
    dispatch(removeFromArray({ pathOfArray, index }));
    const newIndex = index - 1;
    let newPath = path;
    if (newIndex === -1) newPath = pathOfArray;
    dispatch(selectField({ path: newPath, type, index: newIndex, relevantFields }));
  };

  const selectArray = () => {
    if (!isThisFocused)
      dispatch(selectField({ path, type, index: listValue.length - 1, relevantFields }));
  };

  const addItem = () => {
    let actualTemplateItem = { [editAttribute]: '', [`${editAttribute}Styles`]: defaultStyle };
    if (templateItem)
      actualTemplateItem = { ...templateItem, [`${editAttribute}Styles`]: defaultStyle };
    dispatch(addToArray({ pathOfArray, templateItem: actualTemplateItem }));
    dispatch(selectField({ path, type, index: listValue.length, relevantFields }));
  };

  const startDragSkill = (event, index) => {
    ReactTooltip.hide();
    setIsDragging(true);
    event.dataTransfer.setData('drag', index);
  };

  const endDragSkill = (event, destination) => {
    event.preventDefault();
    let index = parseInt(event.dataTransfer.getData('drag'));
    if (destination !== -1 && index !== -1) {
      setIsDragging(false);
      dispatch(
        rearrangeArray({
          pathToArrayString: pathOfArray,
          index,
          destination: destination,
        }),
      );
      dispatch(selectField({ path, type, index: destination, relevantFields }));
    }
    event.dataTransfer.setData('drag', -1);
  };

  const arrayItems = useMemo(() => {
    const list = listValue.map((_item, index) => {
      const isThisItemFocused = isThisFocused && selectedField.index === index;
      const inputComp = (
        <DragOver
          isDragging={isDragging}
          onDrop={(event) => endDragSkill(event, index)}
          onDragStart={(event) => startDragSkill(event, index)}
        >
          <Input
            key={`${pathOfArray}.${index}`}
            type={type}
            path={path}
            onChange={onChange}
            hideTitle
            className={mergeClasses(InputClasses, listItemOverrides)}
            relevantFields={relevantFields}
            index={index}
            title={`${editAttributeTitle} ${index + 1}`}
            autoSize
          />
          {isThisItemFocused && (
            <div className="absolute p-2 flex gap-2 z-[51] top-full -right-1  bg-white shadow-md rounded-md">
              <MenuIcon className="h-5 w-5 text-[#1F2938] cursor-pointer" />
              <XIcon
                // onDrop={(event) => endDragSkill(event, index)}
                // onDragStart={(event) => startDragSkill(event, index)}
                className="h-5 w-5 text-red-400 cursor-pointer"
                onClick={() => deleteArrayItem(index)}
              />
            </div>
          )}
        </DragOver>
      );
      const listItemString = listItem.replace(
        /,?{"type":"\$item","props":{"className":".*"}},?/,
        '',
      );
      const listItemJSON = JSON.parse(listItemString);
      listItemJSON.children = listItemJSON.children.map((item) =>
        createElement(item.type, item.props, item.children),
      );
      // this creates a bug where the input comp is always last
      listItemJSON.children.push(inputComp);
      return createElement(listItemJSON.type, listItemJSON.props, listItemJSON.children);
    });

    const listWrapperJSON = JSON.parse(listWrapper);
    listWrapperJSON.props.className = `${listWrapperJSON.props.className} ${
      isThisFocused ? 'overflow-y-scroll' : ''
    }`;
    listWrapperJSON.props.ref = arrayWrapperRef;
    listWrapperJSON.children = list;
    return createElement(listWrapperJSON.type, listWrapperJSON.props, listWrapperJSON.children);
  }, [listValue, isThisFocused, selectedField, isDragging]);

  useEffect(() => {
    if (
      typeof arrayWrapperRef !== 'undefined' &&
      typeof arrayWrapperRef.current !== 'undefined' &&
      !isThisFocused
    ) {
      arrayWrapperRef.current.scrollTop = 0;
    }
  }, [selectedField]);

  const arrayToolBar = useMemo(() => {
    return (
      <div className="flex gap-2">
        <div className="flex p-2 justify-center items-center cursor-pointer" onClick={addItem}>
          <h1 className="text-base font-medium pr-1 font-['Libre_Franklin']">Add Item</h1>
          <PlusIcon className=" h-4 " />
        </div>
      </div>
    );
  }, [selectedField]);

  return (
    <>
      <ArrayWrapper
        title={title}
        toolbar={arrayToolBar}
        isFocused={isThisFocused}
        isEmpty={listValue.length === 0}
        selectField={selectArray}
      >
        <div className={isThisFocused ? InputFocusClasses : ''} onClick={selectArray}>
          {arrayItems}
        </div>
      </ArrayWrapper>
    </>
  );
};

export default ArrayInputs;
