import { useSelector } from 'react-redux';
import { HandIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../../redux/modalSlice';

const AssessmentListCard = ({ assessment }) => {
  const navigate = useNavigate();
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const dispatch = useDispatch();

  const handleNavigateAssessment = () => {
    if (defaultOrg.name === '7CTOs') {
      navigate(`${assessment.link}?1`);
    } else if (defaultOrg.name === 'Jahnel Group') {
      if (assessment.assessmentId === '3e68d66c-b91f-4519-a772-7a4880ae95f3') {
        navigate(`${assessment.link}/introduction`);
      } else if (assessment.assessmentId === '1ad1fccc-d279-46a0-8980-1d91afd6ba67') {
        dispatch(toggleModal({ show: true }));
      }
    }
  };

  return (
    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="flex w-full items-center justify-between space-x-4 p-6">
        <img
          className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
          src={defaultOrg?.photoURL}
          alt=""
          referrerPolicy="no-referrer"
        />
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-gray-900">{assessment.name}</h3>
          </div>
        </div>
      </div>
      <div className="px-6 py-3">
        <p className="text-xs font-semibold mb-1">Description:</p>
        <div className="flex flex-row items-start">
          <div className="flex flex-col">
            <p className="text-xs text-gray-600 font-normal line-clamp-1">
              {assessment?.description}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <p
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border
                        border-transparent py-3 text-xs font-medium text-gray-700 cursor-pointer
                        hover:bg-gray-800 transition duration-300 hover:text-white group"
            >
              <InformationCircleIcon
                className="h-4 w-4 text-gray-400 group-hover:text-white transition duration-300"
                aria-hidden="true"
              />
              <span className="ml-2">Info</span>
            </p>
          </div>
          <div className="-ml-px flex w-0 flex-1" onClick={handleNavigateAssessment}>
            <p
              className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border
                        border-transparent py-3 text-xs font-medium text-gray-700 cursor-pointer
                        hover:bg-gray-800 transition duration-300 hover:text-white group"
            >
              <HandIcon
                className="h-4 w-4 text-gray-400 group-hover:text-white transition duration-300"
                aria-hidden="true"
              />
              <span className="ml-2">Take</span>
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AssessmentListCard;
