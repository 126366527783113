import { useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { groupBy } from 'lodash';
import TableView from '../custom/TableView.component';

import defaultImg from '../../assets/default-user.svg';
import { AvatarGroup } from '../../custom-prebuilt/common.component';

const transformSkill = (skill) => ({
  imageLink: skill.node?.imageLink,
  link: `/skills/${skill.node.slug}`,
  name: skill.node?.name,
});

const ProjectAssessmentsTableView = (props) => {
  const { customList, actionName, search } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const tableHeadItems = [
    {
      table: 'assessmentCompletions',
      name: 'name',
      title: 'Name',
    },
    {
      table: 'assessmentCompletions',
      name: 'hoursWorked',
      title: 'Hours Worked',
    },
    {
      table: 'assessmentCompletions',
      name: 'primarySkills',
      title: 'Primary Skills',
    },
    {
      table: 'assessmentCompletions',
      name: 'secondarySkills',
      title: 'Secondary Skills',
    },
    {
      table: 'assessmentCompletions',
      name: 'passiveSkills',
      title: 'Passive Skills',
    },
    {
      table: 'assessmentCompletions',
      name: 'description',
      title: 'Description',
    },
  ];

  const RenderBody = useMemo(() => {
    const BodyMemo = () => {
      return (
        <>
          {customList?.length &&
            customList.map((assessment, index) => {
              const personData = assessment.personCompleted[0];
              const skills = groupBy(assessment.skillsConnection?.edges, 'rating');

              return (
                <tr key={index} className="hover:bg-gray-200 transition duration-300">
                  <td className="py-4 pl-4 pr-3 text-xs sm:pl-6">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={personData?.userIconUrl ?? defaultImg}
                          alt=""
                          referrerPolicy="no-referrer"
                        />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{personData?.name}</div>
                        <div className="text-gray-500">{personData?.email}</div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900">
                      {personData.experienceConnection?.edges?.[0]?.totalHours}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900 min-w-[10rem]">
                      <AvatarGroup
                        label=""
                        labelTip="The skill was used for a majority of your time spent on the project."
                        avatars={skills?.[1]?.map(transformSkill) || []}
                        limit="4"
                        className="px-0"
                      />
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900 min-w-[10rem]">
                      <AvatarGroup
                        label=""
                        labelTip="The skill was used for part of your time spent on the project."
                        avatars={skills?.[2]?.map(transformSkill) || []}
                        limit="4"
                        className="px-0"
                      />
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900 min-w-[10rem]">
                      <AvatarGroup
                        label=""
                        labelTip="The skill was seldomly used."
                        avatars={skills?.[3]?.map(transformSkill) || []}
                        limit="4"
                        className="px-0"
                      />
                    </div>
                  </td>
                  <td className="px-4 py-3 text-xs text-gray-500 sm:pl-6 hidden lg:table-cell">
                    <div className="text-gray-900">{assessment.description}</div>
                  </td>
                </tr>
              );
            })}
        </>
      );
    };

    return BodyMemo;
  }, [customList]);

  return (
    <>
      <TableView
        data={customList}
        tableHeadItems={tableHeadItems}
        RenderBody={RenderBody}
        search={search}
        renderActionCol={actionName}
      />
    </>
  );
};

export default ProjectAssessmentsTableView;
