import { mergeClasses } from '../../../lib/classNames';

type ToggleOption = {
  title: string;
  subtitle: string;
  icon: JSX.Element;
};

interface ToggleProps {
  onClick: () => void;
  isToggled: boolean;
  toggle1: ToggleOption;
  toggle2: ToggleOption;
  className: string;
}

const Toggle = ({ onClick, isToggled, toggle1, toggle2, className }: ToggleProps) => {
  const selectedToggle = isToggled ? toggle1 : toggle2;
  return (
    <button
      onClick={onClick}
      className={mergeClasses('flex gap-2 items-center p-2 rounded-md bg-white shadow', className)}
    >
      {selectedToggle.icon}
      <div className="flex flex-col items-start">
        <p className="text-xs text-font-dark font-bold">{selectedToggle.title}</p>
        <p className="text-xs text-font-light">{selectedToggle.subtitle}</p>
      </div>
    </button>
  );
};

export default Toggle;
