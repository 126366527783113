import PageErrorComponent from './PageError.component';
import React from 'react';

const PageNotFound = () => {
  return (
    <PageErrorComponent
      errorCode={404}
      title={'Page Not Found'}
      text={'The page you are looking for does not exist. Please contact an admin for help.'}
    />
  );
};

export default PageNotFound;
