import { useRef, useMemo } from 'react';
import { mergeClasses } from '../lib/classNames';
import { useClickOutside } from '../lib/customHook';

interface PopOutProps {
  isOpen: Boolean;
  setIsOpen: (newValue: boolean) => void;
  content: JSX.Element | JSX.Element[];
  className: string;
  children: JSX.Element | JSX.Element[];
  position: ['top' | 'bottom', 'left' | 'right'];
}

const PopOut = ({
  isOpen,
  setIsOpen,
  content,
  className,
  children,
  position = ['bottom', 'right'],
}: PopOutProps) => {
  const popoutRef = useRef<HTMLDivElement>(null);
  useClickOutside(popoutRef, () => setIsOpen(false));
  const xPos = position[0] === 'top' ? ' bottom-[100%]' : '';
  // this is switched intentionally
  const yPos = position[1] === 'right' ? ' left-0 ' : ' right-0 ';
  const classes = useMemo(
    () => mergeClasses(`absolute${xPos}${yPos}bg-white shadow rounded-md`, className),
    [className],
  );

  return (
    <div ref={popoutRef} className="relative z-50">
      {children}
      <div style={{ display: isOpen ? 'unset' : 'none' }} className={classes}>
        {content}
      </div>
    </div>
  );
};

export default PopOut;
