import { useMutation, useQuery } from '@apollo/client';
import { AttachExistingNode, GetUnattachedSkills } from '../../../../graphql/taxonomy';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button, Label } from '../../../../custom-prebuilt/common.component';
import SearchAndSelectInput from '../../../../custom-prebuilt/SearchAndSelectInput';
import LoadingState from '../../../../custom-prebuilt/preloader/LoadingState.component';
import { UnattachedSkill } from './ReplaceSkillNode';

type AttachSkillProps = {
  id: string;
  closeSlideover: () => void;
};

const AttachSkill = ({ id, closeSlideover }: AttachSkillProps) => {
  const [search, setSearch] = useState('');
  const [skill, setSkill] = useState('');
  const [error, setError] = useState(false);
  const { data, loading } = useQuery(GetUnattachedSkills);
  const [attachExistingSkill] = useMutation(AttachExistingNode, {
    refetchQueries: ['GetOrgTaxonomy', 'GetUnattachedSkills'],
  });

  const validateForm = () => {
    if (!skill) {
      setError(true);
      return false;
    }
    return true;
  };

  const handleAttachSkill = () => {
    if (validateForm()) {
      const skillID = data.getUnattachedSkills.find(
        (node: UnattachedSkill) => node.name === skill,
      ).id;
      const promise = attachExistingSkill({
        variables: {
          parentId: id,
          childId: skillID,
        },
      });
      toast.promise(promise, {
        loading: 'Attaching Skill...',
        success: 'Successfully attached Skill!',
        error: 'An error occurred!',
      });
      closeSlideover();
    }
  };

  return (
    <>
      {loading ? (
        <LoadingState />
      ) : (
        <div className="w-full flex flex-col">
          <Label className="max-w-[66%] text-xs">
            Skill Name:
            <SearchAndSelectInput
              data={data?.getUnattachedSkills}
              placeholder="Search for existing skill..."
              searchField="name"
              search={search}
              setSearch={setSearch}
              setSelected={(val) => {
                setError(false);
                setSkill(val);
              }}
              classes="my-2"
            />
          </Label>
          <span className={`text-red-600 text-xs ${error ? 'block' : 'hidden'}`}>
            Skill is required!
          </span>
          <Button
            className="w-fit mt-2 px-3 py-2 rounded-md shadow-sm border border-gray-300 font-medium bg-bg-dark text-xs text-white hover:bg-gray-700"
            onClick={() => handleAttachSkill()}
          >
            Attach Skill
          </Button>
        </div>
      )}
    </>
  );
};

export default AttachSkill;
