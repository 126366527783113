import { useNestedNav } from '../../../lib/customHook';
import Connectors from './Connectors';
import { Auth } from 'aws-amplify';
import { api_scanConnectors } from '../people/utils/fetchResp';
import { useEffect, useState } from 'react';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';

const nestedNav = [
  {
    name: 'People Connectors',
    icon: (props) => (
      <svg
        stroke="none"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.807 17.481"
        {...props}
      >
        <g
          id="People_Connector_Icon"
          data-name="People Connector Icon"
          transform="translate(-17834.816 -1550.47)"
        >
          <path
            id="merge-sharp-solid"
            d="M6.19,64h.6l.371.464,4.58,5.725h3.118V67.714h1.238l3.714,3.714-3.714,3.714H14.855V72.665H11.737L7.157,78.39l-.371.464H0V76.379H5.594l3.961-4.952L5.594,66.476H0V64H6.19Z"
            transform="translate(17834.816 1486.47)"
          />
          <g id="Group_285" data-name="Group 285" transform="translate(17836.998 1559.139)">
            <g
              id="Ellipse_11"
              data-name="Ellipse 11"
              transform="translate(0 0)"
              fill="#fff"
              stroke="currentColor"
              strokeWidth="1"
            >
              <ellipse cx="4.254" cy="4.406" rx="4.254" ry="4.406" stroke="none" />
              <ellipse cx="4.254" cy="4.406" rx="3.754" ry="3.906" fill="none" />
            </g>
            <path
              id="Icon_material-person"
              data-name="Icon material-person"
              d="M8.246,8.2A1.1,1.1,0,1,0,7.123,7.1,1.112,1.112,0,0,0,8.246,8.2Zm0,.551C7.5,8.755,6,9.124,6,9.857v.551h4.493V9.857C10.493,9.124,9,8.755,8.246,8.755Z"
              transform="translate(-4.067 -3.772)"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    name: 'Project Connectors',
    icon: (props) => (
      <svg
        stroke="none"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.807 17.481"
        {...props}
      >
        <g
          id="Project_Connector_Icon"
          data-name="Project Connector Icon"
          transform="translate(-17834.816 -1550.47)"
        >
          <path
            id="merge-sharp-solid"
            d="M6.19,64h.6l.371.464,4.58,5.725h3.118V67.714h1.238l3.714,3.714-3.714,3.714H14.855V72.665H11.737L7.157,78.39l-.371.464H0V76.379H5.594l3.961-4.952L5.594,66.476H0V64H6.19Z"
            transform="translate(17834.816 1486.47)"
          />
          <g id="Group_285" data-name="Group 285" transform="translate(17836.998 1559.139)">
            <g
              id="Ellipse_11"
              data-name="Ellipse 11"
              transform="translate(0 0)"
              fill="#fff"
              stroke="currentColor"
              strokeWidth="1"
            >
              <ellipse cx="4.254" cy="4.406" rx="4.254" ry="4.406" stroke="none" />
              <ellipse cx="4.254" cy="4.406" rx="3.754" ry="3.906" fill="none" />
            </g>
            <path
              id="svgviewer-output"
              d="M6.757,4.878H3.47m3.287,0a.47.47,0,0,1,.47.47V6.757a.47.47,0,0,1-.47.47H3.47A.47.47,0,0,1,3,6.757V5.348a.47.47,0,0,1,.47-.47m3.287,0v-.47a.47.47,0,0,0-.47-.47M3.47,4.878v-.47a.47.47,0,0,1,.47-.47m0,0V3.47A.47.47,0,0,1,4.409,3H5.818a.47.47,0,0,1,.47.47v.47m-2.348,0H6.287"
              transform="translate(-0.859 -0.707)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    name: 'Skill Connectors',
    icon: (props) => (
      <svg
        stroke="none"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.807 17.481"
        {...props}
      >
        <g
          id="Skill_Connector_Icon"
          data-name="Skill Connector Icon"
          transform="translate(-17834.816 -1550.47)"
        >
          <path
            id="merge-sharp-solid"
            d="M6.19,64h.6l.371.464,4.58,5.725h3.118V67.714h1.238l3.714,3.714-3.714,3.714H14.855V72.665H11.737L7.157,78.39l-.371.464H0V76.379H5.594l3.961-4.952L5.594,66.476H0V64H6.19Z"
            transform="translate(17834.816 1486.47)"
          />
          <g id="Group_285" data-name="Group 285" transform="translate(17836.998 1559.139)">
            <g
              id="Ellipse_11"
              data-name="Ellipse 11"
              transform="translate(0 0)"
              fill="#fff"
              stroke="currentColor"
              strokeWidth="1"
            >
              <ellipse cx="4.254" cy="4.406" rx="4.254" ry="4.406" stroke="none" />
              <ellipse cx="4.254" cy="4.406" rx="3.754" ry="3.906" fill="none" />
            </g>
            <path
              id="svgviewer-output_1_"
              data-name="svgviewer-output (1)"
              d="M6.054,3.527H5.527A.527.527,0,0,0,5,4.054V7.214a.527.527,0,0,0,.527.527H8.161a.527.527,0,0,0,.527-.527V4.054a.527.527,0,0,0-.527-.527H7.634m-1.58,0a.527.527,0,0,0,.527.527h.527a.527.527,0,0,0,.527-.527m-1.58,0A.527.527,0,0,1,6.58,3h.527a.527.527,0,0,1,.527.527m-.79,1.844h.79m-.79,1.054h.79M6.054,5.371h0m0,1.054h0"
              transform="translate(-2.59 -0.965)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </svg>
    ),
  },
];

const ConnectorsAdminMenu = () => {
  const [current, nestedNavComponent] = useNestedNav(nestedNav);
  const [loading, setLoading] = useState(true);
  const [connectors, setConnectors] = useState({
    people: [],
    projects: [],
    skills: [],
  });

  useEffect(() => {
    Auth.currentSession().then(async (session) => {
      const sessionToken = session?.getAccessToken().getJwtToken();
      const res = await api_scanConnectors(sessionToken);
      let tempConnectors = {
        people: [],
        projects: [],
        skills: [],
      }
      /**
       * Review this when add new connectors
       */
      for (const connector of res) { 
        if (connector.connector === "tense")
          tempConnectors.projects.push(connector);
        else if (connector.connector === "google")
          tempConnectors.people.push(connector);
        else
          tempConnectors.skills.push(connector);
      }
      setConnectors(tempConnectors);
      setLoading(false);
    });
  }, []);

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full gap-y-4">
      {nestedNavComponent}
      {
        loading ? <LoadingState />
          : <>
            {current === 0 &&
              <Connectors
                data={connectors}
                setData={setConnectors}
                type={"people"}
                title={"People Connector"}
              />
            }
            {current === 1 &&
              <Connectors
                data={connectors}
                setData={setConnectors}
                type={"projects"}
                title={"Project Connector"}
              />}
            {current === 2 && <Connectors
              data={connectors}
              setData={setConnectors}
              type={"skills"}
              title={"Skill Connector"}
            />}
          </>
      }
    </div>
  );
};

export default ConnectorsAdminMenu;
