import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Button, Input, Select, TextArea } from '../../../custom-prebuilt/common.component';
import { useHorizontalTabs } from '../../../lib/customHook';
import { Toggle } from '../../../custom-prebuilt/common.component';
import { useState } from 'react';
import moment from 'moment';
import defaultImg from '../../../assets/default-user.svg';
import { Amplify } from 'aws-amplify';
import { industries } from '../../settings/AddWorkExperience.component';
import { errorToastMid } from '../../../lib/toast';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import PersonStack from '../../shared/PersonStack.component';
import { v4 as uuidv4 } from 'uuid';
import { AddProjectMembers, CreateProject } from '../../../graphql/projects';
import { toast } from 'react-hot-toast';
import AddProjectMember from './AddProjectMember';
import ArrayInput from '../../../custom-prebuilt/ArrayInput';
import { formatArray } from '../edit/EditInfoContent.component';

const tabs = ['Step 1', 'Step 2', 'Step 3'];

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

export default function AddNewProject() {
  // UI
  const [current, setCurrent, tabsComponent] = useHorizontalTabs(tabs, true);
  const [parent] = useAutoAnimate();

  // Project information
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [company, setCompany] = useState('');
  const [location, setLocation] = useState('');
  const [active, setActive] = useState(true);
  const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState('');
  const [industry, setIndustry] = useState('');
  const [sector, setSector] = useState('');
  const [hyperlinks, setHyperlinks] = useState([]);
  const [members, setMembers] = useState([]);

  const [member, setMember] = useState(null);

  const [showInputErrors, setShowInputErrors] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState();

  // get Potential Members
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  const [createProject] = useMutation(CreateProject, {
    refetchQueries: ['QueryAllProjects'],
  });

  const [addProjectMembers] = useMutation(AddProjectMembers);

  const handleAddMember = () => {
    setShowInputErrors(true);
    if (!member) {
      return;
    }

    setMembers((m) => m.concat(member));
    setEditMode(false);
    setEditValue();
    setMember(null);
    setTimeout(() => setShowInputErrors(false));
  };

  const handleCreateProject = () => {
    const projectId = uuidv4();
    const createPromise = createProject({
      variables: {
        input: [
          {
            name: name,
            id: projectId,
            company: company,
            description: description,
            startDate: startDate,
            endDate: endDate,
            active: active,
            hyperlinks: hyperlinks,
            industry: industry,
            location: location,
            sector: sector,
            organization: {
              connect: [
                {
                  where: {
                    node: {
                      uid: defaultOrgUid,
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    })
      .then(() => {
        const membersInput = members.map((m) => ({
          userId: m.member.cognitoID,
          active: m.active,
          role: m.role,
          startDate: m.startDate,
          endDate: m.endDate,
          totalHours: parseFloat(m.totalHours),
          hoursPerWeek: parseFloat(m.hoursPerWeek),
        }));

        addProjectMembers({
          variables: {
            projectId: projectId,
            projectMembers: membersInput,
          },
        });
      })
      .then(() => {
        setCurrent(0);
        setName('');
        setDescription('');
        setActive(true);
        setCompany('');
        setIndustry('');
        setLocation('');
        setSector('');
        setHyperlinks([]);
        setMembers([]);
        setStartDate('');
        setEndDate('');
      });

    toast.promise(createPromise, {
      loading: 'Creating project...',
      success: 'Successfully created project!',
      error: 'An error occurred!',
    });
  };

  const handleRemoveMember = (mem) => {
    setMembers((mms) => mms.filter((m) => m != mem));
  };

  const handleEdit = (mem) => {
    setEditValue({
      member: mem.member,
      memberName: mem.member.name,
      memberRole: mem.role,
      memberStartDate: mem.startDate,
      memberEndDate: mem.endDate,
      hoursPerWeek: mem.hoursPerWeek,
      totalHours: mem.totalHours,
      active: mem.active,
    });
    setEditMode(true);
    handleRemoveMember(mem);
  };

  const handleNextStage = () => {
    setShowInputErrors(false);
    switch (current) {
      case 0:
        if (!name || !description || !startDate || !company) {
          setShowInputErrors(true);
          errorToastMid('Missing input(s). Please check again');
        } else {
          setCurrent((current) => current + 1);
        }
        break;
      case 1:
        setCurrent((current) => current + 1);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex-grow lg:px-5 mt-5 lg:mt-0">
      <h2 className="text-sm font-medium text-gray-900 mb-2 ml-1">Add New Project</h2>
      <div ref={parent} className="w-full p-6 bg-white shadow rounded-md">
        {tabsComponent}
        {current === 0 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">Add basic project info</p>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Name *</label>
              <Input
                // eslint-disable-next-line
                className={`border px-2 ${showInputErrors && !name ? 'border-red-500' : ''}`}
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Description *</label>
              <TextArea
                // eslint-disable-next-line
                className={`border px-2 resize-y h-24 ${
                  showInputErrors && !description ? 'border-red-500' : ''
                }`}
                value={description}
                name="description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Company *</label>
              <Input
                // eslint-disable-next-line
                className={`border px-2 ${showInputErrors && !company ? 'border-red-500' : ''}`}
                name="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Location</label>
              <Input
                className="border px-2"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Industry</label>
              <Select value={industry} onChange={(e) => setIndustry(e.target.value)}>
                <option value={''} />
                {industries.map((entry, i) => (
                  <option value={entry} key={i}>
                    {entry}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col space-y-1">
                <label className="text-xs">Sector</label>
                <Select value={sector} onChange={(e) => setSector(e.target.value)}>
                  <option value={'private'}>Private</option>
                  <option value={'public'}>Public</option>
                </Select>
              </div>
              <div className="flex flex-col space-y-1">
                <label className="text-xs">Start Date *</label>
                <Input
                  // eslint-disable-next-line
                  className={`border px-2 ${showInputErrors && !startDate ? 'border-red-500' : ''}`}
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="text-xs">End Date</label>
                <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Hyperlinks</label>
              <ArrayInput className="w-1/2" array={hyperlinks} updateArray={setHyperlinks} />
            </div>
            <div className="flex flex-col space-y-1 mb-2">
              <label className="text-xs">Active</label>
              <div className="flex flex-row">
                <Toggle boolean={active} checked={active} onChange={() => setActive((a) => !a)} />
                <p className="text-xs ml-2">{active ? 'true' : 'false'}</p>
              </div>
            </div>
          </div>
        )}

        {current === 1 && (
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col space-y-4">
              <p className="text-xs text-font-light font-semibold">Add Project Members</p>
              <AddProjectMember
                showInputErrors={showInputErrors}
                members={members}
                onChange={setMember}
                editValue={editMode ? editValue : undefined}
              />
              <div className="flex justify-end">
                {editMode ? (
                  <Button className="basis-1/2" onClick={handleAddMember}>
                    Save Project Member
                  </Button>
                ) : (
                  <Button className="basis-1/2" onClick={handleAddMember}>
                    Add Project Member
                  </Button>
                )}
              </div>
            </div>
            <div className="grow">
              <p className="text-xs text-font-light font-semibold">Current Project Members</p>
              <div className="flex flex-col border rounded-md p-4">
                <p className="text-xs text-medium text-font-light mb-4">{members.length} members</p>
                <div className="flex flex-col space-y-4 mb-6">
                  {members.map((m, index) => (
                    <div key={index} className="flex flex-row items-center">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={m.member.userIconUrl ?? defaultImg}
                        alt=""
                        referrerPolicy="no-referrer"
                      />
                      <div className="flex flex-col ml-3 grow">
                        <p className="text-xs font-medium">{m.member.name}</p>
                        <p className="text-xs font-light text-gray-600">{m.role}</p>
                      </div>
                      <div className="space-x-2">
                        <button
                          className="shadow bg-gray-100 px-3 py-2 ml-auto hover:bg-gray-200 transition duration-300
                          text-xs"
                          onClick={() => handleEdit(m)}
                        >
                          Edit
                        </button>
                        <button
                          className="shadow bg-gray-100 px-3 py-2 ml-auto hover:bg-gray-200 transition duration-300
                          text-xs text-red-800"
                          onClick={() => handleRemoveMember(m)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {current === 2 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">Confirm details</p>
            <div className="flex flex-col space-y-1">
              <p className="text-xs font-medium text-black">
                Name: <span className="text-gray-500">{name}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Description: <span className="text-gray-500">{description}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Company: <span className="text-gray-500">{company}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Location: <span className="text-gray-500">{location || '—'}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Active: <span className="text-gray-500">{active ? 'Yes' : 'No'}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Start Date: <span className="text-gray-500">{startDate}</span>
              </p>
              <p className="text-xs font-medium text-black">
                End Date: <span className="text-gray-500">{endDate || '—'}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Industry: <span className="text-gray-500">{industry || '—'}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Sector: <span className="text-gray-500">{sector || '—'}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Hyperlinks: <span className="text-gray-500">{formatArray(hyperlinks, '—')}</span>
              </p>
              <p className="text-xs font-medium text-black">Members:</p>
              <PersonStack peopleArray={members.map((m) => m.member)} stackNum={10} />
            </div>
          </div>
        )}

        <div className="w-full justify-end flex flex-row space-x-1 mt-4">
          {current !== 0 && (
            <Button
              onClick={() => {
                setCurrent((current) => current - 1);
              }}
            >
              Back
            </Button>
          )}
          {current !== 2 && <Button onClick={handleNextStage}>Next</Button>}
          {current === 2 && <Button onClick={handleCreateProject}>Create</Button>}
        </div>
      </div>
    </div>
  );
}
