import { useSelector, useDispatch } from 'react-redux';
import EditInfo from '../../../components/admin/edit/EditInfo.component';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import { RootState } from '../../../redux/store';

enum Fields {
  name = 'name',
  category = 'category',
  level = 'level',
  cost = 'cost',
  expires_after = 'expires_after',
  description = 'description',
  websiteLink = 'websiteLink',
  bounty = 'bounty',
  logo = 'logo',
}

export type CertDetailsDynamicType = {
  [key in Fields]: any;
};

export type CertDetailsProps = {
  certDetails: CertDetailsDynamicType;
  editable: boolean;
};

const showFields: { field: Fields; label: string; type?: string }[] = [
  { field: Fields.name, label: 'Name', type: 'string' },
  { field: Fields.category, label: 'Category', type: 'string' },
  { field: Fields.level, label: 'Level', type: 'string' },
  { field: Fields.cost, label: 'Cost', type: 'string' },
  { field: Fields.expires_after, label: 'Expires After', type: 'string' },
  { field: Fields.description, label: 'Description', type: 'string' },
  { field: Fields.websiteLink, label: 'Website', type: 'string' },
  { field: Fields.bounty, label: 'Bounty', type: 'string' },
];

const CertDetails = ({ certDetails, editable }: CertDetailsProps) => {
  const isCurrentAdmin = useSelector((state: RootState) => state.org.isCurrentAdmin);
  const dispatch = useDispatch();

  const handleOpenEditInfo = (target: string, field: string, oldValue: any, id: any, type: any) => {
    const editObject = { target, field, oldValue, id, type };
    dispatch(toggleSlideOver({ show1: true, editObject }));
  };

  return (
    <div className="px-4 pt-2 pb-2">
      <div className="rounded-lg shadow bg-bg_light">
        <div className="">
          <dl className="space-y-6 sm:space-y-0 py-6 sm:py-0 overflow-hidden">
            {showFields.map(({ field, label, type }, index) => {
              let value =
                field === 'bounty' ? (
                  <p>{certDetails.bounty[0]?.reward || 'null'}</p>
                ) : (
                  certDetails[field]?.toString()
                );

              if (field === 'expires_after' && certDetails.expires_after) {
                value = `${certDetails.expires_after} months`;
              }

              return (
                <div key={index} className="flex flex-row items-center px-6 sm:py-5">
                  <div className="sm:flex">
                    <dt className="text-xs font-medium text-gray-700 sm:w-40 sm:flex-shrink-0 lg:w-24 3xl:w-40">
                      {label}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-500 sm:col-span-2 sm:mt-0 sm:ml-6 max-w-[200px] xs:max-w-sm break-words">
                      {value}
                    </dd>
                  </div>

                  {isCurrentAdmin && editable && (
                    <p
                      className="text-xs text-bg-dark ml-auto pl-4 font-semibold cursor-pointer"
                      onClick={() => {
                        handleOpenEditInfo(
                          'cert',
                          field,
                          certDetails[field],
                          certDetails?.name,
                          type,
                        );
                      }}
                    >
                      Edit
                    </p>
                  )}
                </div>
              );
            })}
          </dl>
        </div>
      </div>

      {isCurrentAdmin && editable && <EditInfo />}
    </div>
  );
};

export default CertDetails;
