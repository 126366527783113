import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { QueryOrgAdmins } from '../../../graphql/orgs';
import SnapshotProfile from '../../../custom-prebuilt/snapshot/SnapshotProfile.component';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import HeaderText from '../../shared/HeaderText.component';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useUpdateAdmin } from './utils/action';
import { toggleActionInput } from '../../../redux/actionInputSlice';
import { SearchIcon } from '@heroicons/react/outline';

const CurrentAdmin = () => {
  const removeAdmin = useUpdateAdmin(false);
  const [triggerRemoveAdmin, setTriggerRemoveAdmin] = useState(false);

  const [searchInput, setSearchInput] = useState('');

  const show = useSelector((state) => state.slideOver.show2);
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const [parent] = useAutoAnimate();
  const dispatch = useDispatch();
  const [orgAdmins, setOrgAdmins] = useState([]);
  const orgAdminsData = useQuery(QueryOrgAdmins, {
    variables: {
      where: {
        uid: defaultOrgUid,
      },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  useEffect(() => {
    orgAdminsData.data?.organizations &&
      setOrgAdmins([...orgAdminsData.data.organizations[0].hasAdmin]);
  }, [orgAdminsData.data, defaultOrgUid]);

  const revokeAdmin = (email) => {
    // check existance of email, set it to state somehow, and then:
    dispatch(toggleActionInput({ inputValue: email }));
    setTriggerRemoveAdmin(true);
  };

  useEffect(() => {
    if (triggerRemoveAdmin) {
      removeAdmin();
      setTriggerRemoveAdmin(false);
    }
  }, [triggerRemoveAdmin, removeAdmin]);

  return (
    <div ref={parent}>
      <div className="mt-1 mb-4">
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full sm:max-w-sm rounded-md pl-10 border-gray-300 shadow-sm focus:border-signature focus:ring-signature
					py-2 text-xs"
            placeholder="Search Current Admins"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>
      <HeaderText text="Current Admin" />
      {orgAdmins.length ? (
        <div className="mt-4 flex flex-col max-h-[400px] overflow-y-auto rounded-md shadow">
          <div>
            <div className="inline-block min-w-full align-middle">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 hidden 2xl:table-cell"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className=" px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Profile</span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {orgAdmins
                      .filter((person) =>
                        person.name.toLowerCase().includes(searchInput.toLowerCase()),
                      )
                      .map((person, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                            {person.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 hidden 2xl:table-cell">
                            {person?.orgUnitsConnection?.edges?.[0]?.position}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 max-w-[10rem] truncate">
                            {person.email}
                          </td>
                          <td className="hidden sm:table-cell relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                            <p
                              className="cursor-pointer"
                              onClick={() => {
                                dispatch(toggleSlideOver({ show2: true, profileTarget: person }));
                              }}
                            >
                              Profile
                            </p>
                          </td>
                          <td>
                            <button
                              onClick={() => revokeAdmin(person.email)}
                              className="rounded-md border text-xs px-4 py-2 bg-red-600 text-white font-medium shadow-sm hover:bg-red-800"
                            >
                              <span className="sm:hidden">X</span>
                              <span className="hidden sm:inline">Revoke</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingState />
      )}
      <SlideOver
        show={show}
        title="User Profile"
        onClose={() => {
          dispatch(toggleSlideOver({ show2: false }));
          setTimeout(() => {
            dispatch(toggleSlideOver({ profileTarget: null }));
          }, 500);
        }}
        onClickButtonClose={() => {
          dispatch(toggleSlideOver({ show2: false }));
          setTimeout(() => {
            dispatch(toggleSlideOver({ profileTarget: null }));
          }, 500);
        }}
        largeSize={true}
        content={<SnapshotProfile target={target} coverPic={true} />}
      />{' '}
      {/*Could tear down Snapshotprofle and rebuilt using smaller components for better customization*/}
    </div>
  );
};

export default CurrentAdmin;
