import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  Button,
  H2,
  Input,
  InputErrorCheck,
  Label,
  Select,
  TextArea,
} from '../../custom-prebuilt/common.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { CreateWorkExperience, QueryUserWorkExperience } from '../../graphql/work_experience';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../lib/authContext';

export const industries = [
  'Agriculture & Mining',
  'Business Services',
  'Computers & Electronics',
  'Consumer Services',
  'Education',
  'Energy & Utilities',
  'Financial Services',
  'Government',
  'Healthcare, Pharmaceuticals, & Biotech',
  'Manufacturing',
  'Media & Entertainment',
  'Non-Profit',
  'Real Estate & Construction',
  'Retail',
  'Software & Internet',
  'Telecommunications',
  'Transportation & Storage',
  'Travel, Recreation, and Leisure',
  'Wholesale & Distribution',
  'Other',
];

/**Section for adding a user's work experience
 * @returns a form for inputing new work experience
 */
const AddWorkExperience = () => {
  const { userEmail } = useAuth();
  const [inputError, setError] = useState();

  const [createExperience, { loading: createLoading, error: createError }] =
    useMutation(CreateWorkExperience);

  //input state variables
  const [company, setCompany] = useState('');
  const [location, setLocation] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [industry, setIndustry] = useState('');

  const onCreate = async () => {
    if (company === '' || location === '' || title === '' || startDate === '' || endDate === '') {
      setError(true);
      return;
    }
    await createExperience({
      variables: {
        create: {
          experience: [
            {
              edge: {
                active: false,
                startDate: startDate,
                endDate: endDate,
                role: title,
              },
              node: {
                Project: {
                  active: true,
                  name: company,
                  company: company,
                  id: uuidv4(),
                  industry: industry,
                  location: location,
                  sector: '',
                  startDate: startDate,
                  endDate: endDate,
                  description: description,
                  hyperlinks: [],
                },
              },
            },
          ],
        },
        where: {
          email: userEmail,
        },
      },
      refetchQueries: [QueryUserWorkExperience],
    });
    setCompany('');
    setLocation('');
    setDescription('');
    setTitle('');
    setEndDate('');
    setStartDate('');
    setIndustry('');
    setError();
  };

  if (createError) {
    console.log(createError);
  }

  return (
    <div className="w-full h-full">
      {createLoading ? (
        <LoadingState />
      ) : (
        <>
          <H2 className="mb-2">Add New Work Experience</H2>

          <Label>
            Enter the name of the company you worked for
            <Input
              type="text"
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              maxLength="50"
            />
          </Label>
          <InputErrorCheck value={company} error={inputError}>
            This is a required field
          </InputErrorCheck>

          <Label>
            Enter the industry you worked in (optional)
            <Select value={industry} onChange={(e) => setIndustry(e.target.value)}>
              <option value={''} />
              {industries.map((entry, i) => {
                return (
                  <option value={entry} key={i}>
                    {entry}
                  </option>
                );
              })}
            </Select>
          </Label>

          <Label>
            Enter the office location
            <Input
              type="text"
              value={location}
              onChange={(event) => setLocation(event.target.value)}
            />
          </Label>
          <InputErrorCheck value={location} error={inputError}>
            This is a required field
          </InputErrorCheck>

          <Label>
            Enter your title or position
            <Input
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              maxLength="50"
            />
          </Label>
          <InputErrorCheck value={title} error={inputError}>
            This is a required field
          </InputErrorCheck>

          <Label>
            Describe the work you completed, during your employment
            <TextArea
              rows={5}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              maxLength="2000"
            />
          </Label>

          <div className="flex flex-row justify-between">
            <Label>
              What year did you start employment?
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </Label>
            <Label>
              What year did your employment end?
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </Label>
          </div>
          <InputErrorCheck value={startDate} error={inputError}>
            This is a required field
          </InputErrorCheck>
          <InputErrorCheck value={endDate} error={inputError && startDate !== ''}>
            This is a required field
          </InputErrorCheck>

          <div className="flex flex-row justify-end">
            <Button className="mt-14" onClick={onCreate}>
              Add Work Experience
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default AddWorkExperience;
