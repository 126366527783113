import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import React from 'react';

export type ModalProps = {
  open: boolean;
  title?: JSX.Element | string;

  onClose?: () => void;
  size?: 'large' | 'medium';
  content?: JSX.Element;

  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  destructiveButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onDestructiveButtonClick?: () => void;
  dataTestidPrimary?: string;
  dataTestidSecondary?: string;
  dataTestidDestructive?: string;
};

// /**
//  * A modal that will be displayed upon all other contents
//  * @param show Open/close modal
//  * @param title Modal title
//  * @param content Modal content
//  * @param buttonPrompt Button prompt
//  * @param onClose Execute when user inputs ESC
//  * @param onClickButtonClose Execute when user clicks the button
//  * @param onClickDivClose Execute when user clicks outside modal
//  * @param extend Provide to achieve large size modal
//  */
const Modal = ({
  open,
  title,
  size = 'medium',
  content,
  primaryButtonLabel,
  secondaryButtonLabel,
  destructiveButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onDestructiveButtonClick,
  dataTestidPrimary,
  dataTestidSecondary,
  dataTestidDestructive,
}: ModalProps) => {
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[500]" onClose={() => onSecondaryButtonClick?.()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="absolute h-full w-full bg-transparent top-0 left-0"></div>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-150"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`z-10 relative bg-bg_lightgray rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
                                    transform transition-all sm:my-8 w-full max-w-md ${
                                      size == 'large' ? 'sm:max-w-7xl' : 'sm:max-w-lg'
                                    }
                                    sm:p-6 self-center justify-self-center`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-sm leading-6 font-medium text-gray-900 mb-4"
                  >
                    {title}
                  </Dialog.Title>
                  <div
                    className={`${size == 'large' ? 'h-[70vh]' : 'max-h-[500px]'} overflow-y-auto`}
                  >
                    {content}
                  </div>
                  <div className="flex justify-end gap-4">
                    {secondaryButtonLabel && (
                      <div className="mt-2">
                        <button
                          type="button"
                          data-testid={dataTestidSecondary}
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3
                          bg-white text-xs font-medium text-black hover:bg-gray-200 transition duration-150"
                          onClick={() => onSecondaryButtonClick?.()}
                          disabled={!onSecondaryButtonClick}
                        >
                          {secondaryButtonLabel}
                        </button>
                      </div>
                    )}
                    {primaryButtonLabel && (
                      <div className="mt-2">
                        <button
                          type="button"
                          // className=" shadow-sm
                          data-testid={dataTestidPrimary}
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3
                          bg-bg-dark text-xs font-medium text-white hover:bg-gray-700 transition duration-150 disabled:opacity-75"
                          onClick={() => onPrimaryButtonClick?.()}
                          disabled={!onPrimaryButtonClick}
                        >
                          {primaryButtonLabel}
                        </button>
                      </div>
                    )}
                    {destructiveButtonLabel && (
                      <div className="mt-2">
                        <button
                          type="button"
                          data-testid={dataTestidDestructive}
                          className="inline-flex justify-center w-full rounded-md border border-red-300 shadow-sm px-4 py-3
                          bg-red-600 text-xs font-medium text-white hover:bg-red-500 transition duration-150"
                          onClick={() => onDestructiveButtonClick?.()}
                          disabled={!onDestructiveButtonClick}
                        >
                          {destructiveButtonLabel}
                        </button>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Modal;
