import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NavbarState = {
  expand: boolean;
};

const initialState: NavbarState = {
  expand: true,
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    updateNavbar: (state, action: PayloadAction<Partial<NavbarState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateNavbar } = navbarSlice.actions;

export default navbarSlice.reducer;
