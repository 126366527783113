import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../lib/authContext';
import { makeCopyOfObject } from './state/ProfileGenerationContextHelpers';

const timeFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  weekday: 'long',
  minute: 'numeric',
  hour: 'numeric',
  hourCycle: 'h12',
});

const HistoryItem = ({ change, currentUserID }) => {
  const { message, authorName, authorID, time } = change;
  const styles = useMemo(() => {
    const isError = authorName === 'Profile Generator' || authorName === 'Profile Service';
    if (currentUserID === authorID) return `self-end ${isError ? 'bg-red-400' : 'bg-white'}`;
    else return `${isError ? 'bg-red-400' : 'bg-neutral-100'}`;
  }, [currentUserID, authorID]);

  const readableTime = timeFormat.format(new Date(parseInt(time)));

  return (
    <div className={`rounded-md w-fit shadow-md flex p-4 gap-2 flex-col justify-center ${styles}`}>
      <div className="flex gap-2">
        <p className="font-medium text-font-dark font-xs">{authorName}:</p>
        <div className="grid grid-cols-[.3fr_1fr] grid-rows-2">
          <p className="text-font-dark row-span-1 col-span-2">{message}</p>
          <p className="text-font-dark self-end italic col-start-2">{readableTime}</p>
        </div>
      </div>
    </div>
  );
};
const History = () => {
  const changes = useSelector((state) => state.profileGenerator.changes);
  const { cognitoID } = useAuth();

  const changeList = useMemo(() => {
    const reversedArray = makeCopyOfObject(changes);
    reversedArray.reverse();
    return reversedArray.map((change) => {
      return <HistoryItem key={change.time} change={change} currentUserID={cognitoID} />;
    });
  }, [changes, cognitoID]);
  return <div className="flex flex-col w-full h-full gap-y-4">{changeList}</div>;
};

export default History;
