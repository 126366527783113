import React from 'react';

export type PageErrorComponentProps = {
  errorCode: number;
  title: string;
  text: string;
};

const PageErrorComponent = ({ errorCode, title, text }: PageErrorComponentProps) => {
  return (
    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-xl font-bold tracking-tight text-gray-800">{errorCode}</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-sm font-bold tracking-tight text-gray-900">{title}</h1>
              <p className="mt-1 text-xs text-gray-500">{text}</p>
            </div>
            {/* <div className="mt-4 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <button
                                href="#"
                                className="inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium
                                    text-white shadow-sm hover:bg-gray-700 transition duration-300"
                            >
                                Go back home
                            </button>
                            <button
                                href="#"
                                className="inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium
                                    text-white shadow-sm hover:bg-gray-700 transition duration-300"
                            >
                                Contact admin
                            </button>
                        </div> */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageErrorComponent;
