import { useSelector } from 'react-redux';
import SimpleActionCard from '../../../custom-prebuilt/actions/SimpleActionCard.component';
import Modal from '../../../custom-prebuilt/Modal.component';
import PastInvitations from './PastInvitations.component';
import { useState, useMemo, useCallback } from 'react';
import { errorToastMid, successToastMid } from '../../../lib/toast';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CreateUserRecord, QueryPersonBasics } from '../../../graphql/people';
import { CheckConnectionPersonToOrg, ConnectPersonToOrgUnit } from '../../../graphql/orgs';
import { Auth } from 'aws-amplify';
import { api_adminCreateUser } from './utils/fetchResp';
import HeaderText from '../../shared/HeaderText.component';
import { CreateInvitation, QueryAllOpenInvitations } from '../../../graphql/invitation';
import { v4 as uuid } from 'uuid';
import { Button, Input, Select } from '../../../custom-prebuilt/common.component';
import { QueryAllOrgUnits } from '../../../graphql/orgs';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';

const PeopleTools = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  // Only for invitation modal (See ticket LR108-53)
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [invitationInput, setInvitationInput] = useState({
    email: '',
    orgName: '',
  });
  const [fetchUser] = useLazyQuery(QueryPersonBasics);
  const [createUserRecord] = useMutation(CreateUserRecord);
  const [connectPersonToOrgUnit] = useMutation(ConnectPersonToOrgUnit);
  const [checkConnectionPersonToOrg] = useLazyQuery(CheckConnectionPersonToOrg);
  const [createInvitation] = useMutation(CreateInvitation);
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const orgUnits = useQuery(QueryAllOrgUnits, {
    variables: {
      where: {
        uid: defaultOrgUid,
      },
    },
  }).data?.organizations?.[0]?.orgUnit;

  const handleInvitationInputChange = useCallback(
    (e) => {
      setInvitationInput({
        ...invitationInput,
        [e.target.name]: e.target.value,
      });
    },
    [invitationInput],
  );

  const handleCloseInvitationModal = () => {
    setShowInvitationModal(false);
    setInvitationInput({
      email: '',
      orgName: '',
    });
  };

  const handleSubmitAdminInviteUser = useCallback(
    async (e) => {
      e.preventDefault();
      if (!invitationInput?.email || !invitationInput?.orgName) return;

      setSendingInvitation(true);
      // 1. Validate/Create user in database
      const email = invitationInput.email;
      const orgName = invitationInput.orgName;
      const cognitoID = `Connect_${uuid()}`;
      const invitedUserEmail = await fetchUser({
        variables: { where: { email } },
        fetchPolicy: 'network-only',
      });
      const invitedUserCognitoID = await fetchUser({
        variables: { where: { cognitoID } },
        fetchPolicy: 'network-only',
      });
      // Check if this email is already in the organization or not
      if (invitedUserEmail.data.people.length > 0) {
        const connections = await checkConnectionPersonToOrg({
          variables: {
            personWhere: { email },
            // orgUnitsConnectionWhere: {
            //   node: { name: orgName }
            // }
          },
          fetchPolicy: 'network-only',
        });

        if (connections.data.peopleConnection.edges.length > 0) {
          errorToastMid('The account is already invited to this organization.');
          setSendingInvitation(false);
          return;
        }
      }
      // checks to make sure no user already has the generated unique id
      if (invitedUserCognitoID.data.people.length > 0) {
        errorToastMid(
          'There has been an issue creating the invitation. Please try again in a few moments.',
        );
        setSendingInvitation(false);
        return;
      }

      try {
        // 1. adminCreateUser in Cognito user pool
        Auth.currentAuthenticatedUser().then((user) => {
          user.getSession((err, session) => {
            if (err) {
              throw new Error(err);
            }
            const sessionToken = session.getAccessToken().jwtToken;
            // API Gateway
            api_adminCreateUser(sessionToken, email, cognitoID)
              .then(async (data) => {
                if (
                  data?.statusCode === 400 ||
                  data?.statusCode === 403 ||
                  data.message === 'Internal Server Error' ||
                  data.message === 'Forbidden'
                ) {
                  errorToastMid(data.message + '\nPlease contact admin for more information.');
                  setSendingInvitation(false);
                } else {
                  // 2. Create user if not have account
                  if (invitedUserEmail.data.people.length === 0) {
                    await createUserRecord({
                      variables: {
                        input: [
                          {
                            email,
                            name: email.split('.').at(0),
                            active: true,
                            cognitoID,
                          },
                        ],
                      },
                    });
                  }

                  // 3. Connect Person node to OrgUnit (NOTE: OrgUnit is not Organization node)
                  try {
                    await connectPersonToOrgUnit({
                      variables: {
                        where: { email: email },
                        connect: {
                          orgUnits: [
                            {
                              where: { node: { name: orgName } },
                            },
                          ],
                        },
                      },
                    });

                    // Successful invitation
                    invitedUserEmail.data.people.length === 0
                      ? successToastMid(
                          'Please check your email address for the temporary password.',
                        )
                      : successToastMid('The user has been invited to organization unit.');

                    // Create inivitaton node
                    let today = new Date();
                    const dd = String(today.getDate()).padStart(2, '0');
                    const mm = String(today.getMonth() + 1).padStart(2, '0');
                    const yyyy = today.getFullYear();
                    today = mm + '/' + dd + '/' + yyyy;
                    await createInvitation({
                      variables: {
                        input: {
                          expired: false,
                          orgUnitName: orgName,
                          email: email,
                        },
                        dateInvited: today,
                      },
                      refetchQueries: () => [
                        {
                          query: QueryAllOpenInvitations,
                          variables: { where: { org: defaultOrgUid } },
                        },
                      ],
                    });
                  } catch (error) {
                    errorToastMid('Unexpected Error!');
                  }
                  setSendingInvitation(false);
                  handleCloseInvitationModal();
                }
              })
              .catch((err) => errorToastMid(err.message));
          });
        });
      } catch (error) {
        errorToastMid(`Unexpected Error`);
      }
    },
    [
      checkConnectionPersonToOrg,
      connectPersonToOrgUnit,
      createUserRecord,
      fetchUser,
      createInvitation,
      sendingInvitation,
      invitationInput?.email,
      invitationInput?.orgName,
    ],
  );

  const AdminInviteUserComponent = useMemo(
    () => (
      <div>
        <div className="text-center mt-2">
          <div className="mt-2 h-fit">
            <div className="text-sm text-gray-500">
              <div className="h-full w-full flex flex-col items-center">
                <form
                  onSubmit={handleSubmitAdminInviteUser}
                  className="isolate w-full rounded-md shadow-sm space-y-4"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-xs font-medium text-gray-900 text-left"
                    >
                      Email
                    </label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={invitationInput.email}
                      onChange={handleInvitationInputChange}
                      disabled={sendingInvitation === true}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="orgName"
                      className="block text-xs font-medium text-gray-900 text-left"
                    >
                      Organization Unit (Company)
                    </label>
                    <Select
                      value={invitationInput.orgName}
                      onChange={handleInvitationInputChange}
                      name="orgName"
                      id="orgName"
                      disabled={sendingInvitation === true}
                      required
                    >
                      <option value={''} />
                      {orgUnits?.map((orgUnit, i) => {
                        return (
                          <option value={orgUnit?.name} key={i}>
                            {orgUnit?.name}
                          </option>
                        );
                      })}
                    </Select>

                    <p className="text-xs text-font-light font-medium text-left">
                      If you don&apos;t see the member&apos;s organization unit above, {'  '}
                      <span className="underline cursor-pointer">add a new org unit here</span>
                    </p>
                  </div>

                  <Button type="submit" className="w-full">
                    {!sendingInvitation ? 'Submit' : 'Sending invitation...'}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [
      handleSubmitAdminInviteUser,
      handleInvitationInputChange,
      invitationInput?.orgName,
      invitationInput?.email,
      orgUnits,
    ],
  );

  return (
    <div className="flex flex-1 h-full">
      <aside className="pt-6 lg:pt-0 pb-4 w-full">
        <h2 className="text-sm font-medium text-gray-900 mb-2 ml-4">Member Invitations</h2>
        <div className="lg:px-4 space-y-8 max-h-full">
          <div>
            <HeaderText text="Actions" />
            <div className="mt-4 w-full grid grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 gap-5">
              <SimpleActionCard
                title="Invite User to Organization"
                description="Invite User to Organization Unit with email"
                btnPrompt="Invite"
                action={() => {
                  setShowInvitationModal(true);
                }}
              />
            </div>
          </div>

          {!orgUnits ? (
            <LoadingState />
          ) : (
            <PastInvitations orgUnits={orgUnits.map((org) => org?.name)} />
          )}
        </div>
      </aside>

      <Modal
        open={showInvitationModal}
        onSecondaryButtonClick={handleCloseInvitationModal}
        content={AdminInviteUserComponent}
        title="Admin invite user"
      />
    </div>
  );
};

export default PeopleTools;
