import { useNavigate } from 'react-router';
import HelpInformation from '../../../custom-prebuilt/HelpInformation.component';
import PersonCard from '../../shared/PersonCard.component';
import Template from '../ProfileGeneratorTemplate';
import { setProfileGeneratorState } from '../state/profileGeneratorSlice';
import { useDispatch, useSelector } from 'react-redux';

const ProfileGeneratorChooseTemplate = () => {
  const templates = useSelector((state) => state.profileGenerator.templates);
  const selectedTemplate = useSelector((state) => state.profileGenerator.selectedTemplate);
  const selectedPerson = useSelector((state) => state.profileGenerator.selectedPerson);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectTemplate = (isSelected, template) => {
    dispatch(setProfileGeneratorState({ selectedTemplate: template }));
  };

  const allTemplates = templates.map((template, index) => {
    const isSelected =
      typeof selectedTemplate !== 'undefined' &&
      template.templateID === selectedTemplate.templateID;
    return (
      <Template
        key={`select-template-${index}`}
        {...template}
        selectTemplate={() => selectTemplate(isSelected, template)}
        isSelected={isSelected}
      />
    );
  });

  const helpText = [
    'Please select the template you would like to use to generate a profile. By default a template is selected automatically.',
    "When you are ready to add the selected person's information to the profile please click the button below.",
  ];

  const enterProfileInformation = () => {
    navigate('/profiles/generate');
  };

  return (
    <div>
      <HelpInformation title={'Select Template'} className="mb-2">
        {helpText.map((help, index) => (
          <p key={`choose-template-help-text-${index}`} className="text-xs">
            {help}
          </p>
        ))}
      </HelpInformation>
      <button
        className="flex h-min gap-4 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm
          font-medium text-gray-700 shadow-sm hover:bg-gray-50 cursor-pointer"
        onClick={enterProfileInformation}
      >
        {`Enter Profile Information for `}
        <PersonCard personBasics={selectedPerson} disableHover className="w-auto" />
      </button>

      <div className="overflow-y-scroll flex flex-wrap content-baseline h-full gap-6 pt-4">
        {allTemplates}
      </div>
    </div>
  );
};

export default ProfileGeneratorChooseTemplate;
