import { gql } from '@apollo/client';

export const CreateUserRequest = gql`
  mutation CreateUserRequest($input: [UserRequestCreateInput!]!) {
    createUserRequests(input: $input) {
      info {
        nodesCreated
      }
    }
  }
`;
/*
{
  "input": [
    {
      "name": null,
      "id": null,
      "type": null,
      "requestedBy": {
        "connect": [
          {
            "where": {
              "node": {
                "cognitoID": null
              }
            }
          }
        ]
      }
    }
  ]
}
*/
