import { classNames } from '../../../lib/classNames';

const FilterItem = ({ colorOn, colorOff, dataTip, onClick, isActive }) => {
  return (
    <button
      data-tip={dataTip}
      data-for="filterInfo"
      onClick={onClick}
      className={classNames('p-4 h-min rounded-md shadow', isActive ? colorOn : colorOff)}
    />
  );
};

const AdjustableFilterItem = ({
  colorOn,
  colorOff,
  filterDataTip,
  filterOnClick,
  onAdjust,
  rangeValue,
  isActive,
}) => {
  const onChange = (event) => {
    onAdjust(parseInt(event.target.value));
  };

  const onBlur = () => {
    if (isNaN(rangeValue)) onAdjust(1);
  };

  const getSize = () => {
    if (isNaN(rangeValue)) return '.45rem';
    let width = rangeValue.toString().length * 0.45;
    return `${width}rem`;
  };

  return (
    <div className="flex gap-1 items-center" key={colorOn}>
      <FilterItem
        dataTip={filterDataTip}
        onClick={filterOnClick}
        isActive={isActive}
        colorOn={colorOn}
        colorOff={colorOff}
      />
      <div className="flex justify-center items-center gap-1">
        <input
          onBlur={onBlur}
          style={{ width: getSize() }}
          className="p-1 box-content rounded-md max-w-[1.5rem] h-auto text-font-dark font-normal border-borders border focus:border-signature focus:ring-signature text-xs disabled:opacity-75"
          type="number"
          min="1"
          disabled={!isActive}
          value={rangeValue.toString()}
          onChange={onChange}
        />
        <p className="text-xs text-font-light">&ge; projects</p>
      </div>
    </div>
  );
};

export { FilterItem, AdjustableFilterItem };
