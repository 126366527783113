import { gql } from '@apollo/client';

export const QueryAllTemplates = gql`
  query Templates {
    templates {
      template
      templateID
      templateName
      templateConfig
      exampleTemplate
    }
  }
`;
