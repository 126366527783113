import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SkillsTableView from '../components/skills/SkillsTableView.component';
import { QuerySkillsTablePagination } from '../graphql/skills';
import { useQuery } from '@apollo/client';
import PageContainer from '../custom-prebuilt/PageContainer';
import { useNavigate } from 'react-router';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { skills_split } from '../split.io/sdk.config';
import RestrictedAuth from '../custom-prebuilt/RestrictedAuth.component';
import SearchLogic from '../custom-prebuilt/SearchLogic.component';

const Skills = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');

  // Get query string from URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  // Sort Selector for People
  const sortSkillsSelector = useSelector((state) => state.sortTable.skills);
  let sortOptions = [];
  if (sortSkillsSelector.column !== '' && sortSkillsSelector.column !== 'usersRated') {
    sortOptions.push({
      [sortSkillsSelector.column]: sortSkillsSelector.sortValue,
    });
  }

  // Limit items in 1 page
  const paginationSize = 15;
  const [statesData, setStatesData] = useState([]);
  const { data, loading } = useQuery(QuerySkillsTablePagination, {
    variables: {
      options: {
        sort: sortOptions,
      },
    },
  });

  useEffect(() => {
    if (data?.skills) {
      const page = params.page ? params.page : 1;

      let tmp = [...data.skills];
      if (sortSkillsSelector.column === 'usersRated' && sortSkillsSelector.sortValue === '') {
        tmp = [...data.skills];
      } else if (
        sortSkillsSelector.column === 'usersRated' &&
        sortSkillsSelector.sortValue === 'ASC'
      ) {
        tmp.sort((a, b) => a.peopleWithSkillAggregate.count - b.peopleWithSkillAggregate.count);
      } else if (
        sortSkillsSelector.column === 'usersRated' &&
        sortSkillsSelector.sortValue === 'DESC'
      ) {
        tmp.sort((a, b) => b.peopleWithSkillAggregate.count - a.peopleWithSkillAggregate.count);
      } else {
        tmp = [...data.skills];
      }
      !searchInput
        ? setStatesData(tmp.slice((page - 1) * paginationSize, page * paginationSize))
        : setStatesData(
            tmp
              .filter((skill) => skill.name.toLowerCase().includes(searchInput.toLowerCase()))
              .slice((page - 1) * paginationSize, page * paginationSize),
          );
    }
  }, [data, params.page, sortSkillsSelector.sortValue, sortSkillsSelector.column, searchInput]);

  const SkillsTableComponent = useMemo(() => {
    return (
      <SkillsTableView
        customList={statesData}
        paginationSize={paginationSize}
        search={{
          isSearching: searchInput ? true : false,
          searchFor: 'skills',
          filteredData: data
            ? [...data.skills].filter((skill) =>
                skill.name.toLowerCase().includes(searchInput.toLowerCase()),
              )
            : [],
        }}
      />
    );
  }, [data, statesData, searchInput]);

  return (
    <SplitTreatments names={[skills_split]}>
      {({ treatments, isReady }) => {
        return isReady && treatments[skills_split].treatment === 'on' ? (
          <PageContainer
            title="Skills Directory"
            action={() => {
              navigate('/skills/certifications');
            }}
            actionName="View certifications"
            action2={() => navigate('/skills/taxonomy')}
            actionName2="View Skill Taxonomy"
          >
            <div className="mt-1 mb-4">
              <input
                type="text"
                placeholder="Find skill"
                className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs py-2"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>

            {/* Display all skills  */}
            <SearchLogic
              dataCheck={statesData.length > 0}
              loading={loading}
              searchInput={searchInput}
              dataComponent={SkillsTableComponent}
              showRequestInput
              requestType="skill"
            />
          </PageContainer>
        ) : (
          <RestrictedAuth />
        );
      }}
    </SplitTreatments>
  );
};

export default Skills;
