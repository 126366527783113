import { gql } from '@apollo/client';

export const QueryProfile = gql`
  query Profiles($where: PersonWhere) {
    people(where: $where) {
      profiles {
        ownerID
        fileName
        profileData
        templateID
        profileID
        profileStatus
        changes {
          message
          authorName
          authorID
          time
        }
      }
    }
  }
`;

export const DeleteProfile = gql`
  mutation DeleteProfile($where: ProfileMutationWhere) {
    deleteProfile(where: $where)
  }
`;
export const UpdateProfile = gql`
  mutation UpdateProfile($where: ProfileMutationWhere, $input: ProfileUpsertInput) {
    updateProfile(where: $where, input: $input) {
      profileStatus
      profileID
    }
  }
`;
export const GetProfile = gql`
  query GetProfile($where: ProfileWhere) {
    profile(where: $where) {
      profileError
      profileStatus
      changes {
        message
        authorName
        authorID
        time
      }
    }
  }
`;
export const GetProfileDownloadLink = gql`
  query ProfileDownloadLink($where: ProfileWhere) {
    getProfileDownloadLink(where: $where) {
      downloadURL
    }
  }
`;
export const DownloadProfile = gql`
  query DownloadProfile($where: ProfileWhere) {
    downloadProfile(where: $where) {
      profile
    }
  }
`;

export const GenerateProfile = gql`
  mutation GenerateProfile($input: ProfileUpsertInput) {
    createProfile(input: $input) {
      profileStatus
      profileID
    }
  }
`;
