import { MinusCircleIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAssessment } from '../../../../../../redux/assessmentSlice';
import { useNavigate, useLocation } from 'react-router';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import { toggleModal } from '../../../../../../redux/modalSlice';
import Modal from '../../../../../../custom-prebuilt/Modal.component';
import SearchLogic from '../../../../../../custom-prebuilt/SearchLogic.component';
import ExpandableText from '../../../../../../custom-prebuilt/ExpandableText';
import { CloseOutlined } from '@ant-design/icons';

const AssessmentRateSkills = () => {
  const selectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const show = useSelector((state) => state.modal.show2);
  const removeSkill = useSelector((state) => state.modal.skill);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [filteredSkills, setFilteredSkills] = useState([]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  useEffect(() => {
    setFilteredSkills(
      selectedSkills?.filter(
        (s) =>
          s?.node?.name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
          s?.node?.slug?.toLowerCase().includes(searchInput?.toLowerCase()),
      ),
    );
  }, [searchInput, selectedSkills]);

  const handleChangeSkillLevel = (skill, rating) => {
    dispatch(
      toggleAssessment({
        selectedSkills: [...selectedSkills].map((s) =>
          s.node.name === skill.node.name ? { node: s.node, rating: rating } : s,
        ),
      }),
    );
  };

  const handleRemoveSkill = () => {
    dispatch(
      toggleAssessment({
        selectedSkills: [...selectedSkills].filter((s) => s.node.name !== removeSkill.node.name),
      }),
    );
    dispatch(toggleModal({ show2: false }));
  };

  const handleCloseModal = () => {
    dispatch(toggleModal({ show2: false, skill: null }));
  };

  return (
    <div className="h-full w-full xl:pl-4">
      <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-5 h-full">
        <div className="flex flex-col space-y-5 flex-1">
          <div className="px-4 py-5 shadow bg-white rounded-md w-full">
            <div className="flex flex-row justify-between">
              <p className="text-xs mb-4 font-semibold">Rate skills</p>
            </div>
            <p className="text-xs mb-2">Rate your skills based on the criteria below:</p>
            <ul className="flex flex-col space-y-2 text-xs ">
              <li>
                • <span className="font-semibold">Knowledgeable</span>: Skills you have some
                knowledge in
              </li>
              <li>
                • <span className="font-semibold">Proficient</span>: Skills you have real world
                experience with. You could be put on a project for these skills
              </li>
              <li>
                • <span className="font-semibold">Lead/Teach</span>: Skills you could lead or teach.
                You should have deep knowledge in these skills
              </li>
            </ul>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer mr-2"
              onClick={() => {
                navigate(`${getLocationPrevLevel(location)}/select`);
              }}
            >
              Back
            </button>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer"
              onClick={() => {
                navigate(`${getLocationPrevLevel(location)}/confirmation`);
              }}
            >
              Confirmation
            </button>
          </div>

          <div className="px-4 pb-5 md:px-0 md:pb-0 shadow overflow-auto bg-white rounded-md">
            <div className="flex m-4 items-center gap-2">
              <input
                type="text"
                name="skill-search"
                id="skill-search"
                value={searchInput}
                className="block rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs
                    transition duration-100 w-44"
                placeholder="Search for a skill"
                onChange={handleInputChange}
              />
              <CloseOutlined
                className="text-gray-500 cursor-pointer hover:text-gray-900"
                onClick={handleClearInput}
              />
            </div>
            <SearchLogic
              loading={!filteredSkills.length}
              dataCheck={filteredSkills.length}
              searchInput={searchInput}
              dataComponent={
                <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                        >
                          Skill
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:table-cell"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                        >
                          Rating
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {filteredSkills.map((skill, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 w-fit">
                            <div className="flex flex-row space-x-2 lg:space-x-3 items-center">
                              <img
                                className="h-6 w-6 lg:h-8 lg:w-8"
                                src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.node.imageLink}`}
                                alt=""
                              />
                              <p className="text-xs">{skill.node.name}</p>
                            </div>
                          </td>
                          <td
                            className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell truncate
                        max-w-[10rem] lg:max-w-xs"
                          >
                            <ExpandableText text={skill.node.description} />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 z-10">
                            <div
                              className="flex flex-col lg:flex-row w-fit border border-gray-900 divide-y lg:divide-y-0 lg:divide-x
                          divide-gray-900"
                            >
                              <button
                                className={`px-3 py-2 hover:text-white hover:bg-gray-900 transition duration-200
                            ${skill.rating === 1 && 'bg-gray-900 text-white'}`}
                                onClick={() => {
                                  handleChangeSkillLevel(skill, 1);
                                }}
                              >
                                Knowledgeable
                              </button>
                              <button
                                className={`px-3 py-2 hover:text-white hover:bg-gray-900 transition duration-200
                            ${skill.rating === 2 && 'bg-gray-900 text-white'}`}
                                onClick={() => {
                                  handleChangeSkillLevel(skill, 2);
                                }}
                              >
                                Proficient
                              </button>
                              <button
                                className={`px-3 py-2 hover:text-white hover:bg-gray-900 transition duration-200
                            ${skill.rating === 3 && 'bg-gray-900 text-white'}`}
                                onClick={() => {
                                  handleChangeSkillLevel(skill, 3);
                                }}
                              >
                                Lead/Teach
                              </button>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3">
                            <MinusCircleIcon
                              className="h-5 w-5 text-red-500 cursor-pointer"
                              onClick={() => {
                                dispatch(toggleModal({ show2: true, skill: skill }));
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <Modal
        open={show}
        title="Remove skill"
        content={<p className="text-xs mb-2">This action can&apos;t be undone. Are you sure?</p>}
        destructiveButtonLabel="Remove"
        onDestructiveButtonClick={handleRemoveSkill}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={handleCloseModal}
      />
    </div>
  );
};

export default AssessmentRateSkills;
