import { NodeProps, formatNodeName } from '../../../../lib/taxonomyHelpers';
import { Handle, Position } from 'reactflow';
import { useState } from 'react';
import SlideOver from '../../../../custom-prebuilt/SlideOver.component';
import ReplaceSkillNode from '../utils/ReplaceSkillNode';
import DetachSkillNode from '../utils/DetachSkillNode';
import DetachEdge from '../utils/DetachEdge';

const SkillNode = ({ data }: { data: NodeProps }) => {
  const [open, setOpen] = useState(false);

  const handleCloseSlideover = () => {
    setOpen(false);
  };

  return (
    <>
      <Handle type="source" position={Position.Top} className="w-1/3 !bg-black" />
      <div
        className="max-w-[120px] max-h-[66px] text-xs flex items-center border border-black rounded-lg p-2 bg-white hover:bg-gray-50 cursor-pointer"
        onClick={() => {
          if (data.isAdmin) setOpen(true);
        }}
      >
        <img
          src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${data.icon}`}
          className="w-[24px] h-[24px] mr-2"
        />
        {formatNodeName(data.name, 30)}
      </div>
      <SlideOver
        show={open}
        onClose={handleCloseSlideover}
        onClickButtonClose={handleCloseSlideover}
        title="Skill Taxonomy Node Edit"
        content={
          <div className="w-full h-full flex flex-col bg-bg-light">
            <div className="w-full flex items-center space-x-2">
              <h3 className="text-lg font-medium">{data.name}</h3>
              <div className="rounded-md bg-white text-xs p-1 border border-black">Skill</div>
            </div>
            <ReplaceSkillNode id={data.id} closeSlideover={handleCloseSlideover} />
            <DetachEdge id={data.id} closeSlideover={handleCloseSlideover} />
            <DetachSkillNode id={data.id} closeSlideover={handleCloseSlideover} />
          </div>
        }
      />
    </>
  );
};

export default SkillNode;
