import { useLocation } from 'react-router';
import ProfileGeneratorChooseTemplate from '../components/profile-generator/subpages/ProfileGeneratorChooseTemplate';
import ProfileGeneratorSelect from '../components/profile-generator/subpages/ProfileGeneratorSelect';
import PageContainer from '../custom-prebuilt/PageContainer';
import { ProfileGeneratorProvider } from '../components/profile-generator/state/ProfileGeneratorContext';
import ProfileGeneratorEditor from '../components/profile-generator/subpages/ProfileGeneratorEditor';

const ProfileGenerator = () => {
  const location = useLocation();
  const pageTitle = 'Profile Generator';
  const routeName = location.pathname.split('/').at(-1);
  const isSelectPerson = routeName === 'select' || routeName === 'profiles';
  const isChooseTemplate = routeName === 'template';
  const isEditor = routeName === 'generate';

  return (
    <ProfileGeneratorProvider>
      {isSelectPerson ? (
        <PageContainer title={pageTitle}>
          <ProfileGeneratorSelect />
        </PageContainer>
      ) : null}
      {isChooseTemplate ? (
        <PageContainer title={pageTitle}>
          <ProfileGeneratorChooseTemplate />
        </PageContainer>
      ) : null}
      {isEditor ? (
        <PageContainer
          title={pageTitle}
          className="pb-0 min-h-[unset] h-[100vh] grid grid-rows-[min-content_1fr]"
        >
          <ProfileGeneratorEditor />
        </PageContainer>
      ) : null}
    </ProfileGeneratorProvider>
  );
};

export default ProfileGenerator;
