import {
  BellIcon,
  UsersIcon,
  MenuIcon,
  HomeIcon,
  XIcon,
  ClipboardListIcon,
  CollectionIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  UserCircleIcon,
  LogoutIcon,
  CogIcon,
  ChartSquareBarIcon,
  CheckIcon,
  DocumentIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/outline';

import { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { toggleSearch } from '../redux/searchSlice';
import { useLocation } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { useMutation, useQuery } from '@apollo/client';
import gsap from 'gsap';
import '../styles/Header.styles.scss';
import { updateNavbar } from '../redux/navbarSlice';
import { useAuth } from '../lib/authContext';
import { getModifierKey } from '../lib/modifierKey';
import { UpdatePersonDefaultOrgUid, QueryCurrentOrg, QueryPersonBasics } from '../graphql/people';
import toast from 'react-hot-toast';
import defaultImg from '../assets/default-user.svg';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { skills_split, projects_split } from '../split.io/sdk.config';
import { getDefaultExpanding } from '../lib/manageLocalStorage';
import { getWindowWidth } from '../lib/getWindowSize';
import { toggleBugReport } from '../redux/bugReportSlice';

const BIG_SCREEN_SIZE = 2600;

const Header = () => {
  //Rebuild ReactTooltip
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const navigate = useNavigate();
  const { authenticated, logout, userEmail } = useAuth();
  const { data } = useQuery(QueryPersonBasics, {
    variables: {
      where: {
        email: userEmail,
      },
    },
  });
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expandNavbar, setExpandNavbar] = useState(
    getDefaultExpanding() !== null ? getDefaultExpanding() : true,
  );
  const location = useLocation();
  const pathname = location.pathname;
  const navbarRef = useRef();
  const expandIcon = useRef();
  const collapseIcon = useRef();
  const tooltipRef = useRef();
  const dispatch = useDispatch();
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const worksAt = useSelector((state) => state.org.worksAt);
  const isCurrentAdmin = useSelector((state) => state.org.isCurrentAdmin);

  const kbarShow = useSelector((state) => state.search.show);

  useEffect(() => {
    !kbarShow && setMobileMenuOpen(false);
  }, [kbarShow]);

  const windowWidth = getWindowWidth();

  const [updatePersonDefaultOrgUid] = useMutation(UpdatePersonDefaultOrgUid, {
    refetchQueries: () => [
      {
        query: QueryCurrentOrg,
        variables: { where: { email: userEmail } },
      },
    ],
  });

  const sidebarNavigation = [
    {
      name: 'Home',
      href: '/',
      icon: HomeIcon,
      current: () => {
        return pathname === '/';
      },
    },
    {
      name: 'People',
      href: '/people',
      icon: UsersIcon,
      current: () => {
        return pathname.startsWith('/people');
      },
    },
    {
      name: 'Projects',
      href: '/projects',
      icon: CollectionIcon,
      current: () => {
        return pathname.startsWith('/projects');
      },
    },
    {
      name: 'Skills',
      href: '/skills',
      icon: ClipboardListIcon,
      current: () => {
        return pathname.startsWith('/skills');
      },
    },
    {
      name: 'Assessment',
      href: '/assessment',
      icon: ChartSquareBarIcon,
      current: () => {
        return pathname.startsWith('/assessment');
      },
    },
    {
      name: 'Profiles',
      href: '/profiles',
      icon: DocumentIcon,
      current: () => {
        return pathname.startsWith('/profiles');
      },
    },
    {
      name: 'Explore',
      href: '/explore',
      icon: PaperAirplaneIcon,
      current: () => {
        return pathname.startsWith('/explore');
      },
    },
  ];

  const userNavigation = [
    {
      name: 'Profile',
      key: 'profile',
      icon: <UserCircleIcon className="h-4 w-4" />,
    },
    // { name: 'Plan', key: "plan", icon: <CreditCardIcon className='h-4 w-4' /> },
    {
      name: 'User Settings',
      key: 'settings',
      icon: <CogIcon className="h-4 w-4" />,
    },
    {
      name: 'Sign out',
      key: 'signout',
      icon: <LogoutIcon className="h-4 w-4" />,
    },
  ];

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    if (expandNavbar) {
      gsap.to(navbarRef.current, {
        width: '12rem',
        duration: 0.7,
        ease: 'power4.inOut',
      });
      gsap.to(expandIcon.current, { opacity: 0, duration: 0.2 });
      gsap.to(expandIcon.current, { display: 'none', delay: 0.2 });
      gsap.to(collapseIcon.current, { display: 'inline-flex', delay: 0.2 });
      gsap.to(collapseIcon.current, { opacity: 1, duration: 0.2, delay: 0.2 });
      gsap.to(tooltipRef.current, { display: 'none' });

      setTimeout(() => {
        document.querySelectorAll('.nav-item').forEach((item) => item.classList.add('appear'));
      }, 300);

      windowWidth <= BIG_SCREEN_SIZE && dispatch(updateNavbar({ expand: true }));
    } else {
      gsap.to(navbarRef.current, {
        width: '4rem',
        duration: 0.7,
        ease: 'power4.inOut',
      });
      gsap.to(collapseIcon.current, { opacity: 0, duration: 0.2 });
      gsap.to(collapseIcon.current, { display: 'none', delay: 0.2 });
      gsap.to(expandIcon.current, { display: 'inline-flex', delay: 0.2 });
      gsap.to(expandIcon.current, { opacity: 1, duration: 0.2, delay: 0.2 });
      gsap.to(tooltipRef.current, { display: 'block' });

      setTimeout(() => {
        document.querySelectorAll('.nav-item').forEach((item) => item.classList.remove('appear'));
      }, 0);

      windowWidth <= BIG_SCREEN_SIZE && dispatch(updateNavbar({ expand: false }));
    }
  }, [expandNavbar, dispatch]);

  const handleToggleSearch = (e) => {
    e.preventDefault();
    dispatch(toggleSearch({ show: true }));
  };

  const handleNavigateProfile = () => {
    navigate(`/${userEmail.split('.')[0]}`);
  };

  const handleSelectDefaultOrg = (org) => {
    toast
      .promise(
        updatePersonDefaultOrgUid({
          variables: {
            where: { email: userEmail },
            update: { defaultOrgUid: org.uid },
          },
        }),
        {
          loading: 'Switching...',
          success: `Selected ${org.name}`,
          error: 'Could not switch',
        },
        {
          position: 'top-center',
        },
      )
      .then(() => {
        setTimeout(() => {
          navigate('/');
          window.location.reload();
        }, 500);
      });
  };

  const handleChangeNavbar = (bool) => {
    setExpandNavbar(bool);
    localStorage.setItem('navbar-expand', bool);
  };

  return (
    <>
      {authenticated ? (
        <>
          <div ref={tooltipRef}>
            <ReactTooltip
              place="right"
              effect="solid"
              backgroundColor="#1F2937"
              offset={{ right: 10 }}
            />
          </div>
          <div className="flex h-full w-full fixed top-0 left-0 z-40 pointer-events-none flex-row">
            {/* Bottom section */}
            <div
              ref={navbarRef}
              className={`hidden md:flex overflow-hidden pointer-events-none h-full md:bg-gray-800 relative md:pointer-events-auto
                  ${!defaultOrg && 'hover:cursor-not-allowed'}
                  ${expandNavbar ? 'w-48' : 'w-16'}`}
            >
              {/* Narrow sidebar*/}
              <nav
                aria-label="Sidebar"
                className={`hidden md:block md:flex-shrink-0 md:overflow-y-auto w-full overflow-x-hidden ${
                  !defaultOrg && 'pointer-events-none'
                }`}
              >
                <div className="relative flex w-full flex-col space-y-3 py-3 px-2 h-full">
                  {/* Logo */}
                  {defaultOrg && (
                    <div className="w-full mb-2 ml-1">
                      <Menu as="div" className="relative inline-block text-left">
                        <Menu.Button
                          data-cy="org-selector"
                          className={`flex flex-row items-center ${
                            expandNavbar && 'hover:bg-gray-700 transition duration-150'
                          }
                          rounded-md`}
                        >
                          <p
                            onClick={() => {}}
                            className={`cursor-pointer flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg
                            text-gray-400 ${
                              !expandNavbar && 'hover:bg-gray-700 transition duration-150'
                            }`}
                          >
                            <img
                              className={`md:child:fill-white child:fill-gray-900 h-7 w-7 cursor-pointer`}
                              src={defaultOrg?.photoURL}
                              alt=""
                            />
                          </p>
                          <div className="nav-item flex flex-row items-center cursor-pointer py-2 rounded-md pr-2 opacity-0">
                            <p className="text-xs text-gray-200 truncate max-w-[6rem]">
                              {defaultOrg?.name}
                            </p>
                            <ChevronDownIcon className="h-3 w-3 text-white ml-1" />
                          </div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className="fixed left-4 mt-2 w-56 origin-top-right rounded-md bg-white
                            shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-30 flex flex-col"
                          >
                            <div
                              className="flex flex-row bg-gray-100 px-4 py-1 text-xs text-gray-700 rounded-t-md
                                items-center border-b border-gray-200"
                            >
                              <p className="text-[0.65rem] font-normal text-gray-600">
                                {userEmail}
                              </p>
                            </div>
                            {worksAt?.map((org, index) => {
                              return (
                                <Menu.Item key={index}>
                                  <div
                                    className={`flex flex-row cursor-pointer px-4 py-2 text-xs text-gray-700
                                    ${
                                      !isCurrentAdmin &&
                                      index === worksAt.length - 1 &&
                                      'rounded-b-md'
                                    }
                                    hover:bg-gray-300 transition duration-300 items-center border-b border-gray-200
                                    ${org.uid === defaultOrg?.uid ? 'bg-gray-300' : 'bg-gray-100'}`}
                                    onClick={() => {
                                      handleSelectDefaultOrg(org);
                                    }}
                                  >
                                    <img
                                      className="h-7 w-7 rounded-full bg-gray-900"
                                      src={org?.photoURL}
                                      alt=""
                                    />
                                    <p className="ml-2 text-xs font-medium">{org?.name}</p>
                                    {org.uid === defaultOrg?.uid && (
                                      <CheckIcon className="ml-auto h-4 w-4" />
                                    )}
                                  </div>
                                </Menu.Item>
                              );
                            })}
                            {isCurrentAdmin && (
                              <Menu.Item>
                                <div
                                  className="flex flex-row cursor-pointer bg-gray-100 px-4 py-2 text-xs text-gray-700
                            hover:bg-gray-300 transition duration-300 rounded-b-md items-center border-b border-gray-200"
                                  onClick={() => {
                                    navigate('/admin/people');
                                  }}
                                >
                                  <CogIcon className="h-4 w-4" />
                                  <p className="ml-2 text-xs font-medium text-gray-600">
                                    Organization Settings
                                  </p>
                                </div>
                              </Menu.Item>
                            )}
                            {/* {
                              isCurrentAdmin && userEmail?.includes("@jahnelgroup.com") &&
                              <Menu.Item>
                                <div className='flex flex-row cursor-pointer bg-gray-100 px-4 py-2 text-xs text-gray-700
                            hover:bg-gray-300 transition duration-300 rounded-b-md items-center border-b border-gray-200'
                                  onClick={() => { navigate('/power-admin') }}>
                                  <CogIcon className='h-4 w-4' />
                                  <p className='ml-2 text-xs font-medium text-gray-600'>Organization Settings</p>
                                </div>
                              </Menu.Item>
                            } */}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}

                  {sidebarNavigation.map((item, index) => (
                    <SplitTreatments key={index + 10} names={[skills_split, projects_split]}>
                      {({ treatments, isReady }) => {
                        return (isReady &&
                          treatments[skills_split].treatment === 'off' &&
                          item.name === 'Skills') ||
                          (treatments[projects_split].treatment === 'off' &&
                            item.name === 'Projects') ? (
                          ''
                        ) : (
                          <p
                            data-tip={item.name}
                            key={index}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            className={`cursor-pointer ${classNames(
                              item.current()
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-400 hover:bg-gray-700 transition duration-150',
                              'flex-shrink-0 flex flex-row items-center h-10 w-full px-[0.875rem] rounded-lg relative',
                            )}`}
                          >
                            <item.icon
                              className="h-5 w-5 transition duration-200"
                              aria-hidden="true"
                            />
                            <span className="nav-item left-11 absolute opacity-0 transition duration-200 text-xs">
                              {item.name}
                            </span>
                          </p>
                        );
                      }}
                    </SplitTreatments>
                  ))}
                  <p
                    ref={expandIcon}
                    onClick={() => {
                      handleChangeNavbar(true);
                    }}
                    className="cursor-pointer flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg
                                                text-gray-400 hover:bg-gray-700 transition duration-150 !mt-auto"
                  >
                    <span className="sr-only">Expand</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </p>
                </div>
              </nav>
              <p
                ref={collapseIcon}
                onClick={() => {
                  handleChangeNavbar(false);
                }}
                className="hidden cursor-pointer flex-shrink-0 items-center justify-center h-10 w-10 rounded-lg
                  text-gray-400 hover:bg-gray-700 transition duration-150 opacity-0 absolute bottom-3 right-3"
              >
                <span className="sr-only">Expand</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </p>
            </div>

            {/* Top nav*/}
            <header className="relative flex h-16 items-center bg-bg_lightgray pointer-events-auto flex-1 -ml-44 md:mx-auto">
              <div className="w-full ml-1 md:hidden pl-48"></div>
              {/* Menu button area */}
              <div className="absolute inset-y-0 left-48 flex items-center pr-4 sm:pr-6 md:hidden">
                {/* Mobile menu button */}
                <button
                  type="button"
                  className="-ml-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Desktop nav area */}
              <div className={`hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-end`}>
                <button
                  onClick={(e) => {
                    handleToggleSearch(e);
                  }}
                  className="mr-auto ml-8 px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md
                                            hover:bg-gray-700 transition duration-300 cursor-pointer"
                >
                  Go to...{' '}
                  <span className="text-gray-500 ml-3 tracking-tighter">
                    {getModifierKey()} + k
                  </span>
                </button>

                {/* <div className="w-fit">
                                        <div className="relative w-fit text-gray-400 focus-within:text-gray-500">
                                            <button onClick={(e) => { handleToggleSearch(e) }}
                                                className='ml-4 px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md
                                                    hover:bg-gray-700 transition duration-300'>Search</button>
                                        </div>
                                    </div> */}
                <div className="ml-5 flex flex-shrink-0 items-center space-x-10 pr-4">
                  <div className="flex items-center space-x-8 pr-5">
                    <Menu as="div" className="relative inline-block text-left ">
                      <Menu.Button className="flex rounded-full bg-white text-sm">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          referrerPolicy="no-referrer"
                          src={data?.people?.[0]?.userIconUrl ?? defaultImg}
                          alt=""
                        />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white
                                                        shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <div className="">
                            {userNavigation.map((item, index) => {
                              return (
                                <Menu.Item key={index}>
                                  <div
                                    className="flex flex-row cursor-pointer bg-gray-100 px-4 py-2 text-xs text-gray-700
                                    hover:bg-gray-200 transition duration-300 items-center"
                                    onClick={() => {
                                      if (item.key === 'profile') {
                                        handleNavigateProfile();
                                      } else if (item.key === 'signout') {
                                        localStorage.removeItem('toastedSignedIn');
                                        logout();
                                      } else if (item.key === 'settings') {
                                        navigate('/settings/personal');
                                      } else if (item.key === 'organization') {
                                        navigate('/organization');
                                      } else if (item.key === 'plan') {
                                        navigate('/plan');
                                      }
                                    }}
                                  >
                                    {item.icon}
                                    <div className="ml-2">
                                      {item.name === 'Profile' ? (
                                        <div className="flex flex-col space-y-1">
                                          <span className="font-semibold">
                                            {data?.people?.[0]?.name ?? 'Name'}
                                          </span>
                                          <span className="text-gray-400">
                                            {defaultOrg?.name ?? 'Org'}
                                          </span>
                                        </div>
                                      ) : (
                                        item.name
                                      )}
                                    </div>
                                  </div>
                                </Menu.Item>
                              );
                            })}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
              <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={setMobileMenuOpen}>
                  {/* <Transition.Child
                                            as={Fragment}
                                            enter="transition-opacity ease-linear duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity ease-linear duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75" />
                                        </Transition.Child> */}

                  <div className={`fixed inset-0 z-40 bg-black_rgba`}>
                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                      enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                      enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
                      leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                      leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                      leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
                    >
                      <Dialog.Panel
                        className="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
                        aria-label="Global"
                      >
                        <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                          <img
                            className="md:child:fill-white child:fill-gray-900 h-7 w-7 cursor-pointer"
                            src={defaultOrg?.photoURL}
                            alt=""
                          />
                          <button
                            type="button"
                            className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100
                                                            hover:text-gray-500 ml-auto"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            <span className="sr-only">Close main menu</span>
                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4 flex flex-col">
                          <Menu
                            as="div"
                            className="relative inline-block text-left bg-gray-900 rounded-md w-fit -mt-16 mb-4 ml-2"
                          >
                            <Menu.Button className={`flex flex-row items-center rounded-md`}>
                              <p
                                onClick={() => {}}
                                className={`cursor-pointer flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg
                            text-gray-400 ${
                              !expandNavbar && 'hover:bg-gray-700 transition duration-150'
                            }`}
                              >
                                <img
                                  className={`md:child:fill-white child:fill-gray-900 h-7 w-7 cursor-pointer`}
                                  src={defaultOrg?.photoURL}
                                  alt=""
                                />
                              </p>
                              <div className="nav-item flex flex-row items-center cursor-pointer py-2 rounded-md pr-2 opacity-1">
                                <p className="text-xs text-gray-200 truncate max-w-[6rem]">
                                  {defaultOrg?.name}
                                </p>
                                <ChevronDownIcon className="h-3 w-3 text-white ml-1" />
                              </div>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                className="fixed left-4 mt-2 w-56 origin-top-right rounded-md bg-white
                            shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-30 flex flex-col"
                              >
                                <div
                                  className="flex flex-row bg-gray-100 px-4 py-1 text-xs text-gray-700 rounded-t-md
                                items-center border-b border-gray-200"
                                >
                                  <p className="text-[0.65rem] font-normal text-gray-600">
                                    {userEmail}
                                  </p>
                                </div>
                                {worksAt?.map((org, index) => {
                                  return (
                                    <Menu.Item key={index}>
                                      <div
                                        className={`flex flex-row cursor-pointer px-4 py-2 text-xs text-gray-700
                                    ${
                                      !isCurrentAdmin &&
                                      index === worksAt.length - 1 &&
                                      'rounded-b-md'
                                    }
                                    hover:bg-gray-300 transition duration-300 items-center border-b border-gray-200
                                    ${org.uid === defaultOrg?.uid ? 'bg-gray-300' : 'bg-gray-100'}`}
                                        onClick={() => {
                                          handleSelectDefaultOrg(org);
                                        }}
                                      >
                                        <img
                                          className="h-7 w-7 rounded-full bg-gray-900"
                                          src={org?.photoURL}
                                          alt=""
                                        />
                                        <p className="ml-2 text-xs font-medium">{org?.name}</p>
                                        {org.uid === defaultOrg?.uid && (
                                          <CheckIcon className="ml-auto h-4 w-4" />
                                        )}
                                      </div>
                                    </Menu.Item>
                                  );
                                })}
                                {isCurrentAdmin && (
                                  <Menu.Item>
                                    <div
                                      className="flex flex-row cursor-pointer bg-gray-100 px-4 py-2 text-xs text-gray-700
                            hover:bg-gray-300 transition duration-300 rounded-b-md items-center border-b border-gray-200"
                                      onClick={() => {
                                        navigate('/admin/people');
                                        setMobileMenuOpen(false);
                                      }}
                                    >
                                      <CogIcon className="h-4 w-4" />
                                      <p className="ml-2 text-xs font-medium text-gray-600">
                                        Organization Settings
                                      </p>
                                    </div>
                                  </Menu.Item>
                                )}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          <button
                            onClick={(e) => {
                              handleToggleSearch(e);
                            }}
                            className="mr-auto px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md
                            hover:bg-gray-700 transition duration-300 cursor-pointer md:hidden ml-2 mb-1"
                          >
                            Go to...{' '}
                            <span className="text-gray-500 ml-3 tracking-tighter">
                              {getModifierKey()} + k
                            </span>
                          </button>
                          {sidebarNavigation.map((item, index) => (
                            <SplitTreatments
                              key={index + 10}
                              names={[skills_split, projects_split]}
                            >
                              {/* `SplitTreatments` passes down to a child function a `treatments` prop, which contains split evaluations.
                               * It also passes down the SDK status (`isReady`, `isTimedout`, `lastUpdate`) as props. You can use `isReady`
                               * to conditionally render your component, for example, by showing a Loading label until the SDK is ready.
                               * While the SDK is not ready, treatments values are `control`. */}
                              {({ treatments, isReady }) => {
                                //console.log(treatments[skills_split]);
                                return (isReady &&
                                  treatments[skills_split].treatment === 'off' &&
                                  item.name === 'Skills') ||
                                  (treatments[projects_split].treatment === 'off' &&
                                    item.name === 'Projects') ? (
                                  ''
                                ) : (
                                  <Fragment key={item.name}>
                                    <p
                                      className="block rounded-md py-2 px-3 text-sm font-medium text-gray-900 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => {
                                        setMobileMenuOpen(false);
                                        navigate(item.href);
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                  </Fragment>
                                );
                              }}
                            </SplitTreatments>
                          ))}
                        </div>
                        <div className="border-t border-gray-200 pt-4 pb-3">
                          <div className="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                            <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                referrerPolicy="no-referrer"
                                src={data?.people?.[0]?.userIconUrl ?? defaultImg}
                                alt=""
                              />
                            </div>
                            <div className="ml-3 min-w-0 flex-1">
                              <div className="truncate text-base font-medium text-gray-800">
                                {data?.people?.[0]?.name ?? 'Name'}
                              </div>
                              <div className="truncate text-sm font-medium text-gray-500">
                                {userEmail}
                              </div>
                            </div>
                            <a
                              href="#"
                              className="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">View notifications</span>
                              <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </a>
                          </div>
                          <div className="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                            {userNavigation.map((item) => (
                              <div
                                key={item.name}
                                href={item.href}
                                className="block rounded-md py-1 px-3 text-sm font-medium text-gray-900 hover:bg-gray-50 cursor-pointer"
                                onClick={() => {
                                  setMobileMenuOpen(false);
                                  if (item.key === 'profile') {
                                    handleNavigateProfile();
                                  } else if (item.key === 'signout') {
                                    localStorage.removeItem('toastedSignedIn');
                                    logout();
                                  } else if (item.key === 'settings') {
                                    navigate('/settings/personal');
                                  } else if (item.key === 'organization') {
                                    navigate('/organization');
                                  } else if (item.key === 'plan') {
                                    navigate('/plan');
                                  }
                                }}
                              >
                                <div className="">
                                  {item.name === 'Profile' ? (
                                    <div className="flex flex-row space-x-1">
                                      <span className="font-semibold">
                                        {data?.people?.[0]?.name ?? 'Name'}
                                      </span>
                                      <span className="font-bold">·</span>
                                      <span className="text-gray-400">
                                        {defaultOrg?.name ?? 'Org'}
                                      </span>
                                    </div>
                                  ) : (
                                    item.name
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="border-t border-gray-200 pt-4 pb-3 flex justify-center">
                            <button
                              className="px-3 py-2 rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50 text-xs font-medium text-gray-500"
                              onClick={() => {
                                setMobileMenuOpen(false);
                                dispatch(toggleSearch({ show: false }));
                                dispatch(toggleBugReport({ show: true }));
                              }}
                            >
                              Report a bug
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition.Root>
            </header>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
