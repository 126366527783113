import { classNames } from '../../lib/classNames';

const ProfileWrapper = ({ wrapperClassNames, iframeClassNames, children, html }) => {
  return (
    <div className={classNames('rounded-md shadow w-[275px]', wrapperClassNames)}>
      <div className="overflow-hidden aspect-[.89] rounded-t-md">
        {/* A4 Paper Size */}
        <iframe
          className={classNames(
            'scale-[.35] w-[794px] h-[882px] origin-top-left',
            iframeClassNames,
          )}
          scrolling="no"
          title={'Test'}
          srcDoc={html}
        />
      </div>
      {children}
    </div>
  );
};

export default ProfileWrapper;
