import {
  AcademicCapIcon,
  BriefcaseIcon,
  IdentificationIcon,
  OfficeBuildingIcon,
  UserIcon,
  ClipboardListIcon
} from '@heroicons/react/outline';

const settingsNavigation = [
  { name: 'Personal Information', href: 'personal', icon: UserIcon },
  { name: 'Company Information', href: 'company', icon: OfficeBuildingIcon },
  { name: 'Contact Information', href: 'contact', icon: IdentificationIcon },
  { name: 'Education', href: 'education', icon: AcademicCapIcon },
  { name: 'Work Experience', href: 'work', icon: BriefcaseIcon },
	{ name: 'Certifications', href: 'certifications', icon: ClipboardListIcon}
];

export default settingsNavigation;
