import { gql } from '@apollo/client';

export const QueryCertificationTablePagination = gql`
  query QueryCertificationTablePagination($options: CertificationOptions) {
    certifications(options: $options) {
      name
      logo
      totalPeopleAcquired
      level
      category
      websiteLink
      description
      peopleWithCertConnection(where: { node: { active: true } }) {
        edges {
          node {
            name
            email
            userIconUrl
          }
          acquiredDate
        }
      }
      activePeopleWithCert: peopleWithCert(where: { active: true }) {
        name
      }
    }
  }
`;

export const AddUserWithCertConnection = gql`
  mutation AddUserWithCertConnection(
    $updateCertificationsWhere2: CertificationWhere
    $update: CertificationUpdateInput
  ) {
    updateCertifications(where: $updateCertificationsWhere2, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

export const QueryAllCert = gql`
  query QueryAllCert {
    certifications {
      logo
      name
      level
      category
      expires_after
      description
      cost
      websiteLink
      bounty {
        reward
      }
    }
  }
`;

export const UpdateCert = gql`
  mutation UpdateCert($where: CertificationWhere, $update: CertificationUpdateInput) {
    updateCertifications(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

export const CreateCert = gql`
  mutation CreateCert($input: [CertificationCreateInput!]!) {
    createCertifications(input: $input) {
      info {
        bookmark
      }
    }
  }
`;

export const QueryCerts = gql`
  query QueryCerts($where: CertificationWhere) {
    certifications(where: $where) {
      logo
      name
    }
  }
`;

export const QueryBounties = gql`
  query QueryBounties($where: BountyWhere) {
    bounties(where: $where) {
      reward
    }
  }
`;
