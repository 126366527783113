import { classNames } from '../../lib/classNames';

type SkillType = {
  imageLink: string;
  name: string;
};
export type SkillCardProps = {
  skill: SkillType;
  className?: string;
  highlight?: boolean;
  rating: number;
};

/**
 * A basic display of a person
 * @param {*} skill info of a skill. Must have at least its name, slug, and iconUrl
 * @param {*} rating skill rating
 */
const SkillCard = ({ skill, className, highlight, rating }: SkillCardProps) => {
  const imgURL = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`;

  return (
    <div
      className={classNames(
        className,
        `px-4 py-3 flex flex-row h-fit items-center justify-between bg-white
    cursor-pointer hover:bg-gray-900 hover:text-white transition duration-150 ${
      highlight && 'text-white bg-gray-900'
    }`,
      )}
    >
      <div className="flex flex-row h-fit items-center">
        <img className="h-5 w-5 select-none mr-3" src={imgURL} alt="" />
        <div className="flex flex-col space-y-1">
          <p className="text-xs font-normal">{skill.name}</p>
          {rating && (
            <p className="text-xs text-font-light">
              <span>{rating === 1 && 'Knowledgable'}</span>
              <span>{rating === 2 && 'Proficient'}</span>
              <span>{rating === 3 && 'Lead/Teach'}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
