import PageContainer from './PageContainer';
import { classNames } from '../lib/classNames';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Accordion from './Accordion.component';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { skills_split, projects_split } from '../split.io/sdk.config';
import { ComponentProps, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import gsap from 'gsap';

/**
 * A Navigation entry for a NavBar
 * @typedef {Object} NavigationEntry
 * @property {string=} name - The display name of the link
 * @property {string=} href - The [relative] path it should link to
 * @property {JSX.Element=} icon - The Icon that represents it
 */
type NavigationEntry = {
  name: string;
  href: string;
  icon: FunctionComponent;
};

/**
 * Props for a NestedVerticalNav
 * @typedef {Object} NestedVerticalNavProps
 * @property {NavigationEntry[]} navigation - The list of entrys in the nav.
 * @property {string} title - The title of the page.
 */
export type NestedVerticalNavProps = {
  title: string;
  menuTitle?: string | undefined;
  navigation: NavigationEntry[];
};

/**
 * An inner vertical navigation.
 * @param {NestedVerticalNavProps} props
 * @returns {JSX.Element}
 */
const NestedVerticalNav = (props: NestedVerticalNavProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [parent] = useAutoAnimate<HTMLDivElement>({ duration: 150 });
  const [expand, setExpand] = useState(true);
  const expandIcon = useRef<HTMLParagraphElement>(null);
  const collapseIcon = useRef<HTMLParagraphElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expand) {
      gsap.to(navbarRef.current, {
        width: '14rem',
        duration: 0.7,
        ease: 'power4.inOut',
      });
      gsap.to(expandIcon.current, { opacity: 0, duration: 0.2 });
      gsap.to(expandIcon.current, { display: 'none', delay: 0.2 });
      gsap.to(collapseIcon.current, { display: 'inline-flex', delay: 0.2 });
      gsap.to(collapseIcon.current, { opacity: 1, duration: 0.2, delay: 0.2 });
      gsap.to(tooltipRef.current, { display: 'none' });

      setTimeout(() => {
        document
          .querySelectorAll('.nested-nav-item')
          .forEach((item) => item.classList.remove('opacity-0'));
      }, 300);
    } else {
      gsap.to(navbarRef.current, {
        width: '3.5rem',
        duration: 0.7,
        ease: 'power4.inOut',
      });
      gsap.to(collapseIcon.current, { opacity: 0, duration: 0.2 });
      gsap.to(collapseIcon.current, { display: 'none', delay: 0.2 });
      gsap.to(expandIcon.current, { display: 'inline-flex', delay: 0.2 });
      gsap.to(expandIcon.current, { opacity: 1, duration: 0.2, delay: 0.2 });
      gsap.to(tooltipRef.current, { display: 'block' });
      setTimeout(() => {
        document
          .querySelectorAll('.nested-nav-item')
          .forEach((item) => item.classList.add('opacity-0'));
      }, 0);
    }
  }, [expand]);

  // redirects user to the first navigation item if it is on an invalid route
  useEffect(() => {
    // check if the current route is a valid NavigationEntry
    const pathArray = location.pathname.split('/');
    const containsRoute = props.navigation.some((navItem) => {
      let { href } = navItem;
      if (href?.includes('/')) {
        href = href.split('/').at(-1) || '';
      }
      return href?.includes(pathArray.at(-1) || '');
    });
    if (!containsRoute) {
      navigate(props.navigation[0].href || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Render icon component from props
   * @param IconComponent
   * @param props
   * @returns
   */
  const renderIcon = (IconComponent: FunctionComponent, props: ComponentProps<'svg'>) => {
    return <IconComponent {...props} />;
  };

  return (
    <PageContainer className="page-container-no-padding-bottom" title={props.title}>
      <div ref={tooltipRef} className={expand ? 'hidden' : 'block'}>
        <ReactTooltip
          id="component"
          place="right"
          effect="solid"
          backgroundColor="#1F2937"
          offset={{ right: 10 }}
        />
      </div>
      <div className="h-fit lg:h-full-init w-full">
        <div className="flex h-full w-full">
          {/* Static sidebar for desktop */}
          <div className="hidden lg:flex lg:flex-shrink-0">
            <div ref={navbarRef} className={`flex flex-col ${expand ? 'w-56' : 'w-14'}`}>
              {/* Sidebar component */}
              <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200">
                <div className="flex flex-1 flex-col pt-5">
                  <nav className="flex-1" aria-label="Sidebar">
                    <div className="space-y-1 pr-4">
                      {props.navigation.map((item, index) => (
                        <SplitTreatments key={index + 10} names={[skills_split, projects_split]}>
                          {/* `SplitTreatments` passes down to a child function a `treatments` prop, which contains split evaluations.
                           * It also passes down the SDK status (`isReady`, `isTimedout`, `lastUpdate`) as props. You can use `isReady`
                           * to conditionally render your component, for example, by showing a Loading label until the SDK is ready.
                           * While the SDK is not ready, treatments values are `control`. */}
                          {({ treatments, isReady }: ISplitTreatmentsChildProps) => {
                            //console.log(treatments[skills_split]);
                            return (isReady &&
                              treatments[skills_split].treatment === 'off' &&
                              item.name === 'Skills') ||
                              (treatments[projects_split].treatment === 'off' &&
                                item.name === 'Projects') ? null : (
                              <NavLink
                                data-for="component"
                                data-tip={item.name}
                                to={item.href}
                                key={item.name}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? 'bg-gray-200 text-gray-900'
                                      : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                                    `group flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-300 cursor-pointer`,
                                  )
                                }
                              >
                                {({ isActive }) => (
                                  <>
                                    {renderIcon(item.icon, {
                                      className: classNames(
                                        isActive
                                          ? 'text-gray-500'
                                          : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-[6px] flex-shrink-0 h-5 w-5 transition duration-300',
                                      ),
                                    })}
                                    <p className="nested-nav-item opacity-1 transition duration-200 whitespace-nowrap">
                                      {item.name}
                                    </p>
                                  </>
                                )}
                              </NavLink>
                            );
                          }}
                        </SplitTreatments>
                      ))}
                    </div>
                  </nav>
                  {expand ? (
                    <p
                      ref={collapseIcon}
                      onClick={() => setExpand(false)}
                      className="inline-flex h-10 w-10 rounded-lg cursor-pointer self-end mr-1 items-center justify-center hover:bg-gray-200"
                    >
                      <ChevronLeftIcon className="h-5 w-5" />
                    </p>
                  ) : (
                    <p
                      ref={expandIcon}
                      onClick={() => setExpand(true)}
                      className="inline-flex h-10 w-10 rounded-lg cursor-pointer self-end mr-1 items-center justify-center hover:bg-gray-200"
                    >
                      <ChevronRightIcon className="h-5 w-5" />
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex min-w-0 flex-1 flex-col h-full overflow-hidden">
            <div className="lg:hidden">
              <Accordion
                className="px-0 py-0 bg-white"
                title={
                  <div className="flex flex-row">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900
              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-signature"
                    >
                      <span className="sr-only">Open sidebar</span>
                    </button>
                    <p className="text-xs ml-1">{props.menuTitle}</p>
                  </div>
                }
                content={
                  <div className="space-y-1 mt-4">
                    {props.navigation.map((item, index) => (
                      <SplitTreatments key={index + 10} names={[skills_split, projects_split]}>
                        {/* `SplitTreatments` passes down to a child function a `treatments` prop, which contains split evaluations.
                         * It also passes down the SDK status (`isReady`, `isTimedout`, `lastUpdate`) as props. You can use `isReady`
                         * to conditionally render your component, for example, by showing a Loading label until the SDK is ready.
                         * While the SDK is not ready, treatments values are `control`. */}
                        {({ treatments, isReady }: ISplitTreatmentsChildProps) => {
                          //console.log(treatments[skills_split]);
                          return (isReady &&
                            treatments[skills_split].treatment === 'off' &&
                            item.name === 'Skills') ||
                            (treatments[projects_split].treatment === 'off' &&
                              item.name === 'Projects') ? null : (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                                  'group flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-300',
                                )
                              }
                            >
                              {({ isActive }) => (
                                <>
                                  {renderIcon(item.icon, {
                                    className: classNames(
                                      isActive
                                        ? 'text-gray-500'
                                        : 'text-gray-400 group-hover:text-gray-500',
                                      'mr-2 h-6 w-6',
                                    ),
                                    'aria-hidden': 'true',
                                  })}
                                  {item.name}
                                </>
                              )}
                            </NavLink>
                          );
                        }}
                      </SplitTreatments>
                    ))}
                  </div>
                }
              />
            </div>
            <div ref={parent} className="pt-5 h-full overflow-y-auto overflow-x-hidden">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default NestedVerticalNav;
