import { useSelector, useDispatch } from 'react-redux';
import EditInfo from '../../../components/admin/edit/EditInfo.component';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import { classNames } from '../../../lib/classNames';
import { formatDate } from '../../../lib/formatDate';
import { RootState } from '../../../redux/store';

enum SkillFields {
  name = 'name',
  dateAdded = 'dateAdded',
  active = 'active',
  description = 'description',
  websiteLink = 'websiteLink',
  slug = 'slug',
  category = 'category',
}

export type PeopleWithSkillConnectionEdge = {
  node: any;
  rating: number;
};

export type PeopleWithSkillConnectionType = {
  edges: PeopleWithSkillConnectionEdge[];
};

export type SkillDetailsDynamicType = {
  [key in SkillFields]: any;
} & {
  peopleWithSkillConnection: PeopleWithSkillConnectionType;
};

type ShowFieldsItem = {
  field: SkillFields;
  display: string;
};

const showFields: ShowFieldsItem[] = [
  {
    field: SkillFields.name,
    display: 'Name',
  },
  {
    field: SkillFields.dateAdded,
    display: 'Date Added',
  },
  {
    field: SkillFields.active,
    display: 'Active',
  },
  {
    field: SkillFields.description,
    display: 'Description',
  },
  {
    field: SkillFields.websiteLink,
    display: 'Website Link',
  },
  {
    field: SkillFields.slug,
    display: 'Slug',
  },
  {
    field: SkillFields.category,
    display: 'Category',
  },
];

export type SkillDetailsProps = {
  skillDetails: SkillDetailsDynamicType;
  editable: boolean;
  className?: string;
};

const SkillDetails = ({ skillDetails, editable, className }: SkillDetailsProps) => {
  const isCurrentAdmin = useSelector((state: RootState) => state.org.isCurrentAdmin);
  const dispatch = useDispatch();

  const handleOpenEditInfo = (target: string, field: string, oldValue: any, id: any) => {
    const editObject = { target, field, oldValue, id };
    dispatch(toggleSlideOver({ show1: true, editObject }));
  };

  return (
    <div className={classNames(className, 'px-4 pt-2 pb-2')}>
      <div className="rounded-lg shadow bg-bg_light">
        <div className="">
          <dl className="space-y-6 sm:space-y-0 py-6 sm:py-0 overflow-hidden">
            {showFields.map((f, index) => {
              const field = f.field;
              return (
                <div key={index} className="flex flex-row items-center px-6 sm:py-5">
                  <div className="sm:flex">
                    <dt className="text-xs font-medium text-gray-700 sm:w-40 sm:flex-shrink-0 lg:w-24 3xl:w-40">
                      {f.display}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-500 sm:col-span-2 sm:mt-0 sm:ml-6 max-w-[200px] xs:max-w-sm break-words">
                      {skillDetails[field] === undefined || skillDetails[field]?.length === 0 ? (
                        'N/A'
                      ) : field === 'dateAdded' ? (
                        formatDate(skillDetails[field], 'N/A')
                      ) : field !== 'category' ? (
                        skillDetails[field].toString()
                      ) : (
                        <div className="flex flex-row flex-wrap">
                          {skillDetails[field]?.map((e: any, index: number) => (
                            <p key={index} className="px-2 py-1 bg-gray-200 mr-1 mb-1">
                              {e.value}
                            </p>
                          ))}
                        </div>
                      )}
                    </dd>
                  </div>

                  {isCurrentAdmin && editable && (
                    <p
                      className="text-xs text-bg-dark ml-auto pl-4 font-semibold cursor-pointer"
                      onClick={() => {
                        handleOpenEditInfo('skill', field, skillDetails[field], skillDetails?.slug);
                      }}
                    >
                      Edit
                    </p>
                  )}
                </div>
              );
            })}
          </dl>
        </div>
      </div>

      {isCurrentAdmin && editable && <EditInfo />}
    </div>
  );
};

export default SkillDetails;
