import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import {
  QueryPersonDetails,
  UpdatePersonActiveStatus,
  DeleteUserRecord,
} from '../../graphql/people';
import { useNavigate } from 'react-router';
import autoAnimate from '@formkit/auto-animate';
import LoadingState from '../preloader/LoadingState.component';
import UserDetails from './utils/UserDetails.component';
import { toggleSlideOver } from '../../redux/slideOverSlice';
import Modal from '../Modal.component';
import {
  api_adminDisableUser,
  api_adminEnableUser,
  api_adminDeleteUser,
} from '../../components/admin/people/utils/fetchResp';
import { Auth } from 'aws-amplify';
import { RootState } from '../../redux/store';
const defaultImg: string = require('../../assets/default-user.svg').default;

type DynamicDataType = {
  [key: string]: any;
};

export type SnapshotProfileProps = {
  target: DynamicDataType;
  coverPic: boolean;
  editable: boolean;
};

const SnapshotProfile = ({ target, coverPic, editable }: SnapshotProfileProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultOrgUid = useSelector((state: RootState) => state.org.defaultOrgUid);
  const isCurrentAdmin = useSelector((state: RootState) => state.org.isCurrentAdmin);

  const email = target?.email;
  const { data, error } = useQuery(QueryPersonDetails, {
    variables: {
      where: { email },
      orgUnitsConnectionWhere2: {
        node: { organization: { uid: defaultOrgUid } },
      },
      categoryConnectionWhere2: { node: { type: 'Category' } },
    },
  });
  if (error) console.error(error);
  const isActive = data?.people?.[0]?.active === true;

  const [updateActive] = useMutation(UpdatePersonActiveStatus, {
    variables: { where: { email }, update: { active: isActive } },
    refetchQueries: [QueryPersonDetails],
  });

  const [deleteUser] = useMutation(DeleteUserRecord, {
    variables: { where: { email } },
  });

  const parentRef = useRef();

  useEffect(() => {
    parentRef.current && autoAnimate(parentRef.current);
  }, [parentRef]);

  const [showDeactivatePersonModal, setShowDeactivatePersonModal] = useState(false);

  const handleCloseDeactivatePersonModal = () => {
    setShowDeactivatePersonModal(false);
  };

  const changePersonStatus = async () => {
    const currentSession = await Auth.currentSession();
    const sessionToken = currentSession.getAccessToken().payload['jwtToken'];

    const isActive = data?.people?.[0]?.active === true;
    if (isActive) {
      await api_adminDisableUser(sessionToken, email);
    } else {
      await api_adminEnableUser(sessionToken, email);
    }
    await updateActive({ variables: { update: { active: !isActive }, where: { email } } });

    handleCloseDeactivatePersonModal();
    window.location.reload();
  };

  const DeactivatePersonModal = () => {
    return (
      <div>
        <div>
          <div className="h-fit">
            <div className="text-sm text-gray-500">
              <div className="h-full w-full flex flex-col items-center">
                <div className="w-full">
                  {isActive
                    ? 'Deactivating will remove access from the application but they can be re-activated to restore access'
                    : 'Reactivating will grant access to the application'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [showDeletePersonModal, setShowDeletePersonModal] = useState(false);

  const handleCloseDeletePersonModal = () => {
    setShowDeletePersonModal(false);
  };

  const handleDeleteUser = async () => {
    const currentSession = await Auth.currentSession();
    const sessionToken = currentSession.getAccessToken().payload['jwtToken'];
    await api_adminDeleteUser(sessionToken, email);
    await deleteUser();
    handleCloseDeletePersonModal();
    window.location.reload();
  };

  const DeletePersonModal = () => {
    return (
      <div>
        <div>
          <div className="h-fit">
            <div className="text-sm text-gray-500">
              <div className="h-full w-full flex flex-col items-center">
                <div className="w-full">
                  Doing so will remove all data related to this user from Connect and they will need
                  to be re-invited to access the system.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex h-full rounded-md flex-col overflow-y-scroll bg-bg_lightgray">
        {!data ? (
          <LoadingState />
        ) : (
          <div className="divide-y divide-gray-200">
            <div className="pb-4 bg-bg_light px-4">
              {coverPic && (
                <img
                  className=""
                  src={data?.people?.[0]?.userBannerUrl ?? 'https://i.imgur.com/ZZRnqAK.jpg'}
                  alt=""
                />
              )}
              <div className="flow-root px-4 sm:flex sm:items-end sm:px-6 bg-bg_light pb-8 rounded-lg pt-6">
                <div>
                  <div className="-m-1 flex">
                    <div className="inline-flex overflow-hidden rounded-lg border-2 p-1 border-gray-800 mb-4 sm:mb-0">
                      <img
                        className="h-20 w-20 flex-shrink-0 sm:h-24 sm:w-24 lg:h-32 lg:w-32"
                        src={data?.people?.[0]?.userIconUrl ?? defaultImg}
                        alt=""
                        referrerPolicy="no-referrer"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:ml-6 sm:flex-1">
                  <div>
                    <div className="flex items-center">
                      <h3 className="text-base font-bold text-gray-900">
                        {data?.people?.[0]?.name ?? 'Name'}
                      </h3>
                    </div>
                    <p className="text-sm text-gray-500 mb-2">{email ?? 'Email'}</p>
                    <p className="text-sm font-medium text-gray-900">
                      {data?.people?.[0]?.orgUnitsConnection?.edges?.[0]?.position ?? 'Position'}
                    </p>
                  </div>
                  <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                    <button
                      onClick={() => {
                        navigate(`/${target.email.split('.')[0]}`);
                        dispatch(toggleSlideOver({ show: false }));
                      }}
                      type="button"
                      className="inline-flex w-full max-w-2xl flex-shrink-0 items-center justify-center rounded-md
                                                                    border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium text-white
                                                                    shadow-sm hover:bg-gray-700 sm:flex-1 transition duration-150"
                    >
                      Full profile
                    </button>
                    {isCurrentAdmin && (
                      <button
                        onClick={() => setShowDeactivatePersonModal(true)}
                        className="shadow bg-gray-100 px-3 py-2 ml-auto hover:bg-gray-200 transition duration-300
                        text-xs"
                      >
                        {isActive ? 'Deactivate' : 'Reactivate'}
                      </button>
                    )}

                    {isCurrentAdmin && !isActive && (
                      <button
                        onClick={() => setShowDeletePersonModal(true)}
                        className="shadow bg-gray-100 px-3 py-2 ml-auto hover:bg-gray-200 transition duration-300
                        text-xs font-bold text-red-700 hover:text-red-500"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <UserDetails userDetails={data?.people?.[0]} editable={editable} />
          </div>
        )}

        <Modal
          open={showDeactivatePersonModal}
          title={
            data?.people?.[0]?.active === true
              ? 'Are you sure you want to deactivate this user?'
              : 'Are you sure you want to reactivate this user?'
          }
          content={DeactivatePersonModal()}
          onSecondaryButtonClick={handleCloseDeactivatePersonModal}
          secondaryButtonLabel="Cancel"
          primaryButtonLabel={isActive ? undefined : 'Reactivate'}
          destructiveButtonLabel={isActive ? 'Deactivate' : undefined}
          onDestructiveButtonClick={changePersonStatus}
          onPrimaryButtonClick={changePersonStatus}
        />

        <Modal
          open={showDeletePersonModal}
          content={DeletePersonModal()}
          title={
            <span>
              Are you sure you want to <em className="text-red-800 font-bold">permanently</em>{' '}
              delete this user?
            </span>
          }
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCloseDeletePersonModal}
          destructiveButtonLabel="Delete"
          onClose={handleDeleteUser}
        />
      </div>
    </>
  );
};

export default SnapshotProfile;
