import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import React, { useEffect, useMemo, useState } from 'react';
import { get, orderBy } from 'lodash';
import Paginator from './Paginator.component';
import SearchBar from './SearchBar.component';
import SortIndicator, { SortDirection, TableSortValue } from './SortIndicator.component';
import NoResults from '../../../custom-prebuilt/NoResults.component';
import { mergeClasses } from '../../../lib/classNames';

/**
 *
 * @param {string} title - The label to be displayed for the column
 * @param {string} field - The name of the property holding the value of the column.
 *
 * @param {boolean} sortable - Determines if the table should be able to sort by the column
 * @param {SortDirection} initialSortDir - Used to determine the initial sort direction for the table
 * @param {boolean} searchable - Determines if the column should be included in the search results
 *
 * @param {string} headerCellClassName - additional classes to be added to the <td> element in the header
 * @param {string} dataCellClassName - additional classes to be added to the <td> element in the body
 *
 * @param {(data: any, index: number) => JSX.Element} RenderHeaderCellFn - Functional component to override the default <td> component in the header
 * @param {(data: any, index: number) => JSX.Element} RenderDataCellFn - Functional component to override the default <td> component in the body
 *
 * @constructor
 */
export type Column = {
  title: string;
  field: string;

  sortable?: boolean;
  initialSortDir?: SortDirection;
  searchable?: boolean;

  headerCellClassName?: string;
  dataCellClassName?: string;

  RenderHeaderCellFn?: (data: any, index: number) => JSX.Element;
  RenderDataCellFn?: (data: any, index: number) => JSX.Element;
};

const ColumnDefault = {
  sortable: false,
  initialSortDir: SortDirection.OFF,
  searchable: false,
} as Partial<Column>;

export function Column(props: Column): Column {
  return { ...ColumnDefault, ...props };
}

type RenderRowInput<T> = {
  rowDataObj: T;
  rowIndex: number;
};
/**
 *
 * @param {string} tableId - ID of the table element in DOM
 * @param {T[]} data - Array of objects to be represented by the table.
 * @param {Column[]} columns - Array of Column objects representing the columns in the table
 * @param {number} totalRecords - The total number of records in the dataset

 * @param {number} rowsPerPage - The number of rows to be displayed on each page
 * @param {string} emptyMessage - The message to be displayed when there are no results in the table
 * @param {boolean} topPaginator - Whether the top paginator should be displayed or not
 * @param {boolean} bottomPaginator - Whether the bottom paginator should be displayed or not
 * @param {boolean} displaySearchBar - Whether the search bar should be displayed or not
 * @param {string} searchBarPlaceholder - The text to be displayed in the search bar before the user has started typing
 *
 * @param {(rowData: T) => void} onRowClick - Callback for when a row is clicked
 *
 * @param {string} tableClassName - additional classes to be added to the <table> element
 * @param {string} headerClassName - additional classes to be added to the <thead> element
 * @param {string} bodyClassName - additional classes to be added to the <tbody> element
 * @param {string} dataRowClassName - additional classes to be added to the <tr> element in the table body
 *
 * @param {() => JSX.Element} RenderHeaderFn - Functional component to override the default header component
 * @param {({ rowDataObj, rowIndex }: RenderRowInput<T>) => JSX.Element} RenderDataRowFn - Functional component to override the data row component
 *
 * @returns {JSX.Element}
 */
export type DataTableInput<T> = {
  tableId: string;
  data: T[];
  columns: Column[];

  //Optional Table Props
  totalRecords?: number;
  rowsPerPage?: number;
  emptyMessage?: string;
  topPaginator?: boolean;
  bottomPaginator?: boolean;

  displaySearchBar?: boolean;
  searchBarPlaceholder?: string;

  onRowClick?: (rowData: T) => void;

  tableClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  dataRowClassName?: string;

  // Render override props
  RenderHeaderFn?: () => JSX.Element;
  RenderDataRowFn?: ({ rowDataObj, rowIndex }: RenderRowInput<T>) => JSX.Element;
};

const DataTable = <T,>({
  tableId,
  data,
  totalRecords,
  columns,

  rowsPerPage = 15,
  emptyMessage = 'No Results Found',
  topPaginator = false,
  bottomPaginator = false,

  displaySearchBar = false,
  searchBarPlaceholder = 'Search',

  onRowClick,

  tableClassName,
  headerClassName,
  bodyClassName,
  dataRowClassName,

  RenderHeaderFn,
  RenderDataRowFn,
}: DataTableInput<T>) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentData, setCurrentData] = useState<T[]>(data ? [...data] : []);
  const [dataSubset, setDataSubset] = useState<T[]>(data ? [...data] : []);
  const [dataSubsetTotal, setDataSubsetTotal] = useState<number>(totalRecords ?? data?.length ?? 0);
  const [currentSort, setCurrentSort] = useState<TableSortValue>(getDefaultSortState());
  const [currentSearch, setCurrentSearch] = useState<string>();

  function getDefaultSortState(): TableSortValue {
    const defaultSortColumn = columns.find((col) => col.sortable && col.initialSortDir);

    if (defaultSortColumn) {
      return {
        field: defaultSortColumn.field,
        direction: defaultSortColumn.initialSortDir,
      } as TableSortValue;
    } else {
      return {
        field: '',
        direction: SortDirection.OFF,
      } as TableSortValue;
    }
  }

  function handleSortClicked(column: Column): void {
    if (!column.sortable) {
      return;
    }

    let newSort;

    if (currentSort && currentSort.field === column.field) {
      // current sort was clicked again
      newSort = {
        field: currentSort.field,
        direction: (currentSort.direction + 1) % 3,
      };
    } else {
      // new sort field was clicked
      newSort = {
        field: column.field,
        direction: SortDirection.ASC,
      };
    }

    if (newSort.direction == SortDirection.OFF) {
      newSort = getDefaultSortState();
    }

    setCurrentSort(newSort);
  }

  function setCurrentDataAndTotalRecords(): void {
    calculateCurrentData();
    calculateTotalRecords();
  }

  function calculateCurrentData(): void {
    if (!dataSubset) {
      setCurrentData([]);
    } else if (dataSubset?.length <= rowsPerPage) {
      setCurrentData(dataSubset);
    } else {
      setCurrentData(dataSubset.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage));
    }
  }

  function calculateTotalRecords(): void {
    setDataSubsetTotal(dataSubset?.length ?? 0);
  }

  useEffect(() => {
    setCurrentDataAndTotalRecords();
  }, [currentPage, dataSubset]);

  // When data, currentSearch, or currentSort changes, reapply the filter and sort to the original data set
  // and then go to the first page
  useEffect(() => {
    filterAndSortData();
    setCurrentPage(1);
  }, [data, currentSearch, currentSort]);

  function filterAndSortData(): void {
    const filteredData = getFilteredData();
    setDataSubset(sortData(filteredData));
  }

  // Sorts data using the currentSort
  function sortData(unsortedData: T[]): T[] {
    const sortCol = columns.find((col) => col.field === currentSort?.field);
    if (!sortCol) {
      return unsortedData;
    }

    const order = currentSort.direction === SortDirection.ASC ? 'asc' : 'desc';

    return orderBy(
      unsortedData,
      (d) => {
        const dataValue = get(d, sortCol.field);
        return typeof dataValue === 'string' ? dataValue.toLowerCase() : dataValue;
      },
      order,
    );
  }

  // Returns an array that is a subset of the data array after applying the current search parameter
  function getFilteredData(): T[] {
    if (!currentSearch) {
      return data;
    }

    return data.filter((d) => {
      for (const col of columns) {
        if (
          col.searchable &&
          get(d, col.field)?.toLowerCase().includes(currentSearch?.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
  }

  function makeKey(section: string, row: number, col: number) {
    return JSON.stringify({ section, row, col });
  }

  const RenderHeader = useMemo(
    () =>
      RenderHeaderFn ??
      ((): JSX.Element => {
        return (
          <tr>
            {columns.map((column, columnIndex) => {
              const headerClasses = mergeClasses(
                'cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900',
                column.headerCellClassName,
              );

              return column.RenderHeaderCellFn ? (
                column.RenderHeaderCellFn(column, columnIndex)
              ) : (
                <th
                  key={makeKey('header', 0, columnIndex)}
                  scope="col"
                  className={headerClasses}
                  onClick={() => handleSortClicked(column)}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span>{column.title}</span>
                    {column.sortable && (
                      <SortIndicator
                        key={makeKey('sortIndicator', 0, columnIndex)}
                        tableSortState={currentSort}
                        columnField={column.field}
                        initialSortValue={column.initialSortDir}
                      />
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        );
      }),
    [currentSort],
  );

  type RenderCellInput = {
    rowDataObj: T;
    column: Column;
    columnIndex: number;
  };

  const RenderCell = ({ rowDataObj, column, columnIndex }: RenderCellInput): JSX.Element => {
    return column.RenderDataCellFn ? (
      column.RenderDataCellFn(rowDataObj, columnIndex)
    ) : (
      <>
        <div className="text-xs text-gray-900"> {get(rowDataObj, column.field)} </div>
      </>
    );
  };

  const defaultCellClasses = 'whitespace-nowrap px-3 py-4 text-xs text-gray-900';

  const RenderRow =
    RenderDataRowFn ??
    (({ rowDataObj, rowIndex }: RenderRowInput<T>): JSX.Element => {
      return (
        <>
          {columns.map((column, columnIndex) => {
            const cellClasses = mergeClasses(defaultCellClasses, column.dataCellClassName);

            return (
              <td className={cellClasses} key={makeKey('dataRow', rowIndex, columnIndex)}>
                <RenderCell rowDataObj={rowDataObj} column={column} columnIndex={columnIndex} />
              </td>
            );
          })}
        </>
      );
    });

  const paginatorMemo = useMemo(
    () => (
      <Paginator
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        totalRecords={dataSubsetTotal ?? 0}
        onPageChange={setCurrentPage}
      />
    ),
    [dataSubsetTotal, rowsPerPage, currentPage],
  );

  const rowClasses = mergeClasses(
    'hover:bg-gray-200 transition duration-300 cursor-pointer',
    dataRowClassName,
  );

  const RenderEmptyMessage = () => {
    const emptyMessageClasses = mergeClasses('text-center', rowClasses);

    return (
      <tr className={emptyMessageClasses}>
        <td className={defaultCellClasses} colSpan={columns.length}>
          {' '}
          {emptyMessage}{' '}
        </td>
      </tr>
    );
  };

  function handleRowClick(rowData: T) {
    if (onRowClick) {
      onRowClick(rowData);
    }
  }

  const RenderBody = useMemo(
    // eslint-disable-next-line react/display-name
    () => (): JSX.Element => {
      return (
        <>
          {currentData.map((rowData: T, rowIndex: number) => {
            return (
              <tr
                className={rowClasses}
                key={makeKey('row', rowIndex, 0)}
                onClick={() => handleRowClick(rowData)}
              >
                <RenderRow rowDataObj={rowData} rowIndex={rowIndex} />
              </tr>
            );
          })}
        </>
      );
    },
    [currentData],
  );

  const RenderTable = () => {
    const tableClasses = mergeClasses('min-w-full divide-y divide-gray-300', tableClassName);
    const headerClasses = mergeClasses('bg-gray-50', headerClassName);
    const bodyClasses = mergeClasses('divide-y divide-gray-200 bg-white', bodyClassName);

    return (
      <table className={tableClasses}>
        <thead className={headerClasses}>
          <RenderHeader />
        </thead>
        <tbody className={bodyClasses}>
          {data?.length === 0 ? <RenderEmptyMessage /> : <RenderBody />}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {!data ? (
        <LoadingState />
      ) : (
        <div className="space-y-4">
          {displaySearchBar && (
            <SearchBar
              tableId={tableId}
              placeholder={searchBarPlaceholder}
              onInputChange={setCurrentSearch}
            />
          )}

          {data.length > 0 && currentData.length === 0 && currentSearch ? (
            <NoResults searchInput={currentSearch} />
          ) : (
            <>
              {topPaginator && paginatorMemo}

              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <RenderTable />
                    </div>
                  </div>
                </div>
              </div>

              {bottomPaginator && paginatorMemo}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DataTable;
