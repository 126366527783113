import toast from 'react-hot-toast';

export const successToast = (msg: string) => {
  toast.success(msg);
};

export const errorToast = (msg: string) => {
  toast.error(msg);
};

export const iconToast = (msg: string) => {
  toast(msg, {
    icon: '👏',
  });
};

export const successToastMid = (msg: string) => {
  toast.success(msg, {
    position: 'top-center',
  });
};

export const iconToastMid = (msg: string) => {
  toast(msg, {
    icon: '👏',
    position: 'top-center',
  });
};

export const errorToastMid = (msg: string) => {
  toast.error(msg, {
    position: 'top-center',
  });
};
