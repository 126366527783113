import LoadingState from './preloader/LoadingState.component';
import { ResponsiveRadar } from '@nivo/radar';
import { useMemo } from 'react';
import { classNames } from '../lib/classNames';

export type PersonSkillRadarType = {
  skillDetails: any[];
  className?: string;
};

const PersonSkillRadar = ({ skillDetails, className }: PersonSkillRadarType) => {
  const frontendSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Front End',
  );
  const backendSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Back End',
  );
  const dataSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Data',
  );
  const cloudSkills = skillDetails?.filter(
    (s) =>
      s?.node?.categoryConnection?.edges[1]?.node.value === 'AWS' ||
      s?.node?.categoryConnection?.edges[1]?.node.value === 'Azure' ||
      s?.node?.categoryConnection?.edges[1]?.node.value === 'Google Cloud',
  );
  const designSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Design',
  );
  const languagesSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Languages',
  );
  const mobileSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Mobile',
  );
  const webSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Web Platforms',
  );
  const techniquesSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Techniques',
  );
  const testingSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'Testing',
  );
  const devopsSkills = skillDetails?.filter(
    (s) => s?.node?.categoryConnection?.edges[1]?.node.value === 'DevOps',
  );

  const frontendScore = frontendSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const backendScore = backendSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const dataScpre = dataSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const cloudScore = cloudSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const designScore = designSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const languagesScore = languagesSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const mobileScore = mobileSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const webScore = webSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const techniquesScore = techniquesSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const testingScore = testingSkills?.reduce((acc, curr) => acc + curr.rating, 0);
  const devopsScore = devopsSkills?.reduce((acc, curr) => acc + curr.rating, 0);

  const data = [
    {
      category: 'Frontend',
      efficiency: frontendScore,
    },
    {
      category: 'Backend',
      efficiency: backendScore,
    },
    {
      category: 'Data',
      efficiency: dataScpre,
    },
    {
      category: 'Cloud',
      efficiency: cloudScore,
    },
    {
      category: 'Design',
      efficiency: designScore,
    },
    {
      category: 'Languages',
      efficiency: languagesScore,
    },
    {
      category: 'Mobile',
      efficiency: mobileScore,
    },
    {
      category: 'Web',
      efficiency: webScore,
    },
    {
      category: 'Techniques',
      efficiency: techniquesScore,
    },
    {
      category: 'Testing',
      efficiency: testingScore,
    },
    {
      category: 'Devops',
      efficiency: devopsScore,
    },
  ];

  const render = useMemo(() => {
    return (
      <ResponsiveRadar
        data={data}
        keys={['efficiency']}
        indexBy="category"
        valueFormat=">-.2f"
        margin={{ top: 30, right: 45, bottom: 30, left: 45 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={12}
        dotSize={5}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        colors={{ scheme: 'nivo' }}
        blendMode="multiply"
        motionConfig="wobbly"
      />
    );
  }, [data]);

  return (
    <div className={classNames(`w-full h-full min-w-0`, className)}>
      {skillDetails ? <>{render}</> : <LoadingState />}
    </div>
  );
};

export default PersonSkillRadar;
