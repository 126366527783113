import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState, useMemo, RefObject } from 'react';
import gsap from 'gsap';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { classNames, mergeClasses } from './classNames';
import { toggleSlideOver } from '../redux/slideOverSlice';
import { toggleModal } from '../redux/modalSlice';
import { updateNavbar } from '../redux/navbarSlice';
import Modal from '../custom-prebuilt/Modal.component';
import { CheckboxInput } from '../custom-prebuilt/common.component';
import { getDefaultExpanding } from './manageLocalStorage';
import { getWindowWidth } from './getWindowSize';
import { useAuth } from './authContext';
import React from 'react';
import { RootState } from '../redux/store';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { togglePopOver } from '../redux/popOverSlice';

export const useNavbar = () => {
  // DO NOT CHANGE
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const expand = useSelector((state: RootState) => state.navbar.expand);
  const dispatch = useDispatch();
  const SMALL_SCREEN_SIZE = 768;
  const BIG_SCREEN_SIZE = 2600;

  const handleWindowResize = useCallback(() => {
    setWindowWidth(getWindowWidth());
  }, []);

  const performTransition = (bool: boolean, duration: number) => {
    if (!bool) {
      gsap.to(pageContainerRef.current, {
        paddingLeft: '6rem',
        duration: duration,
        ease: 'power4.inOut',
      });
    } else {
      gsap.to(pageContainerRef.current, {
        paddingLeft: '14rem',
        duration: duration,
        ease: 'power4.inOut',
      });
    }
  };

  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    window.addEventListener('resize', () => {
      handleWindowResize();
    });

    return () => {
      window.removeEventListener('resize', () => {
        handleWindowResize();
      });
    };
  }, []); // DO NOT CHANGE

  const expandLogic = useCallback(
    (duration: number) => {
      if (windowWidth < SMALL_SCREEN_SIZE) {
        gsap.to(pageContainerRef.current, {
          paddingLeft: '1.25rem',
          duration: 0,
          ease: 'power4.inOut',
        });
      } else {
        performTransition(expand, duration);
      }
    },
    [windowWidth, expand],
  );

  useEffect(() => {
    if (windowWidth >= SMALL_SCREEN_SIZE) {
      const defaultExpand = getDefaultExpanding() !== null ? getDefaultExpanding() : true;
      dispatch(updateNavbar({ expand: windowWidth <= BIG_SCREEN_SIZE ? defaultExpand : false }));
      performTransition(windowWidth <= BIG_SCREEN_SIZE ? defaultExpand : false, 0.01);
    }
  }, [dispatch, windowWidth]);

  useEffect(() => {
    expandLogic(0.7);
  }, [expand, expandLogic]);

  useEffect(() => {
    if (windowWidth < SMALL_SCREEN_SIZE) {
      gsap.to(pageContainerRef.current, { paddingLeft: '1.25rem', duration: 0 });
    } else if (windowWidth >= SMALL_SCREEN_SIZE) {
      !expand
        ? gsap.to(pageContainerRef.current, { paddingLeft: '6rem', duration: 0 })
        : gsap.to(pageContainerRef.current, { paddingLeft: '14rem', duration: 0 });
    }
  }, [windowWidth]); // DO NOT CHANGE

  return pageContainerRef;
};

export type NestedNavEntry = {
  name: string;
  icon: (props: any) => JSX.Element;
};

/**
 *
 * @param {*} nestedNav Array of navbar [{name, icon}, ...]
 * @returns current nav item's index and a JSX component
 */
export const useNestedNav = (nestedNav: NestedNavEntry[]) => {
  const [current, setCurrent] = useState(0);
  const [expand, setExpand] = useState(true);
  const expandIcon = useRef<HTMLParagraphElement>(null);
  const collapseIcon = useRef<HTMLParagraphElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expand) {
      gsap.to(navbarRef.current, {
        width: '15rem',
        duration: 0.7,
        ease: 'power4.inOut',
      });
      gsap.to(expandIcon.current, { opacity: 0, duration: 0.2 });
      gsap.to(expandIcon.current, { display: 'none', delay: 0.2 });
      gsap.to(collapseIcon.current, { display: 'inline-flex', delay: 0.2 });
      gsap.to(collapseIcon.current, { opacity: 1, duration: 0.2, delay: 0.2 });
      gsap.to(tooltipRef.current, { display: 'none' });

      setTimeout(() => {
        document
          .querySelectorAll('.hook-nav-item')
          .forEach((item) => item.classList.remove('opacity-0'));
      }, 300);
    } else {
      gsap.to(navbarRef.current, {
        width: '3.5rem',
        duration: 0.7,
        ease: 'power4.inOut',
      });
      gsap.to(collapseIcon.current, { opacity: 0, duration: 0.2 });
      gsap.to(collapseIcon.current, { display: 'none', delay: 0.2 });
      gsap.to(expandIcon.current, { display: 'inline-flex', delay: 0.2 });
      gsap.to(expandIcon.current, { opacity: 1, duration: 0.2, delay: 0.2 });
      gsap.to(tooltipRef.current, { display: 'block' });

      setTimeout(() => {
        document
          .querySelectorAll('.hook-nav-item')
          .forEach((item) => item.classList.add('opacity-0'));
      }, 0);
    }
  }, [expand]);

  const nestedNavComponent = (
    <>
      <div ref={tooltipRef} className={expand ? 'hidden' : 'block'}>
        <ReactTooltip
          id="custom-hook"
          place="right"
          effect="solid"
          backgroundColor="#1F2937"
          offset={{ right: 10 }}
        />
      </div>
      <aside
        ref={navbarRef}
        className={`flex flex-col h-full flex-shrink-0 justify-between order-first lg:border-r lg:border-gray-200 ${
          expand ? 'lg:w-60' : 'lg:w-14'
        }`}
      >
        <div className="flex flex-shrink-0">
          <div className="flex w-full flex-col">
            <div className="flex min-h-0 flex-1 flex-col">
              <div className="flex flex-1 flex-col">
                <nav className="flex-1" aria-label="Sidebar">
                  <div className="space-y-1 px-2">
                    {nestedNav.map((item, index) => (
                      <p
                        data-tip={item.name}
                        data-for="custom-hook"
                        onClick={() => {
                          setCurrent(index);
                        }}
                        key={item.name}
                        className={classNames(
                          current === index
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                          `group flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-300 cursor-pointer whitespace-nowrap`,
                        )}
                      >
                        <item.icon
                          className={classNames(
                            current === index
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-[6px] flex-shrink-0 h-5 w-5 transition duration-300',
                          )}
                        />
                        <span className="hook-nav-item opacity-1 transition duration-200 whitespace-nowrap">
                          {item.name}
                        </span>
                      </p>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {expand ? (
          <p
            ref={collapseIcon}
            className="hidden self-end h-10 w-10 rounded-lg cursor-pointer mr-1 items-center justify-center hover:bg-gray-200 lg:inline-flex"
            onClick={() => setExpand(false)}
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </p>
        ) : (
          <p
            ref={expandIcon}
            className="hidden self-end h-10 w-10 rounded-lg cursor-pointer mr-1 items-center justify-center hover:bg-gray-200 lg:inline-flex"
            onClick={() => setExpand(true)}
          >
            <ChevronRightIcon className="h-5 w-5" />
          </p>
        )}
      </aside>
    </>
  );

  return [current, nestedNavComponent];
};

/**
 *
 * @param {*} tabs Array(<String>) Array of tab items
 * @param {*} disableClick <boolean> true to disable directly click on tabs. False to enable this feature
 * @returns current tab index and a JSX component for horizontal tabs
 */
export const useHorizontalTabs = (
  tabs: string[],
  disableClick: boolean,
  selectedTabClass: string,
  unselectedTabClass: string,
  className: string,
) => {
  const [current, setCurrent] = useState(0);

  const tabComponent = (
    <div className={mergeClasses('w-full mb-4', className)}>
      <div className="flex flex-row items-center">
        <nav className="flex" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <p
              key={index}
              className={classNames(
                index === current
                  ? twMerge('bg-gray-300 text-black', selectedTabClass)
                  : twMerge('text-gray-500 hover:text-gray-700', unselectedTabClass),
                'px-3 py-2 font-medium text-xs rounded-md cursor-pointer transition duration-150',
              )}
              onClick={() => {
                !disableClick && setCurrent(index);
              }}
            >
              {tab}
            </p>
          ))}
        </nav>
      </div>
    </div>
  );

  return [current, setCurrent, tabComponent];
};

export const useCleanUpLayover = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(toggleModal({ show: false, show1: false }));
    dispatch(toggleSlideOver({ show: false, show1: false, show2: false }));
    /* eslint-disable-next-line */
    dispatch(togglePopOver({ show: false, targetType: '', data: null, targetHeight: 0, popOverHover: false }));
  }, [location.pathname, dispatch]);
};

export const useApplyFilter = () => {
  const dispatch = useDispatch();
  const modalShow = useSelector((state: RootState) => state.modal.show);
  const [activeFilter, setActiveFilter] = useState(true);
  const [inactiveFilter, setInactiveFilter] = useState(false);
  const [applyFilter, setApplyFilter] = useState(true);

  const handleCloseModal = useCallback(() => {
    dispatch(toggleModal({ show: false }));
  }, [dispatch]);

  const handleFilter = useCallback(
    (e: any) => {
      if (e.target.name === 'active') {
        setActiveFilter(!activeFilter);
      } else if (e.target.name === 'inactive') {
        setInactiveFilter(!inactiveFilter);
      }
    },
    [activeFilter, inactiveFilter],
  );

  const ModalComponent = useMemo(() => {
    return (
      <Modal
        open={modalShow}
        primaryButtonLabel="Apply"
        onPrimaryButtonClick={() => {
          handleCloseModal();
          setApplyFilter(true);
        }}
        onSecondaryButtonClick={handleCloseModal}
        title="Select either one or both to apply filter(s)"
        content={
          <div className="flex flex-col space-y-2 mb-2">
            <CheckboxInput checked={activeFilter} onChange={handleFilter} name="active">
              Active
            </CheckboxInput>
            <CheckboxInput checked={inactiveFilter} onChange={handleFilter} name="inactive">
              Inactive
            </CheckboxInput>
          </div>
        }
      />
    );
  }, [activeFilter, handleCloseModal, handleFilter, inactiveFilter, modalShow]);

  return [applyFilter, setApplyFilter, activeFilter, inactiveFilter, ModalComponent];
};

export const useFilteredSkills = (project: any) => {
  const { cognitoID } = useAuth();

  const userAssessment = project?.assessments?.find(
    (a: any) => a?.personCompleted?.[0]?.cognitoID === cognitoID,
  );
  return !userAssessment ? [] : userAssessment?.skillsConnection?.edges;
};

// adapted from https://usehooks.com/useOnClickOutside/
export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const callBack = (event: MouseEvent) => {
      if (typeof ref !== 'undefined' && ref !== null && ref.current !== null) {
        if (ref.current.contains(event.target as HTMLElement)) {
          return;
        }
        handler(event);
      }
    };
    document.addEventListener('mousedown', callBack);
    document.addEventListener('touchstart', callBack);
    return () => {
      document.removeEventListener('mousedown', callBack);
      document.removeEventListener('touchstart', callBack);
    };
  }, [ref, handler]);
};

interface Position {
  x: number;
  y: number;
}

export const usePopOver = () => {
  const dispatch = useDispatch();
  const popOverHover = useSelector((state: RootState) => state.popOver.popOverHover);

  const timeoutRef = useRef<NodeJS.Timeout>();
  const timeoutRef2 = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (popOverHover) {
      clearTimeout(timeoutRef2.current);
    }
  }, [popOverHover]);

  /* eslint-disable-next-line */
  const handleGetPopOverData = (targetType: string, data: object, position: Position, targetHeight: number) => {
    timeoutRef.current = setTimeout(() => {
      dispatch(togglePopOver({ show: true, targetType, data, position, targetHeight }));
    }, 350);
  };

  const handleClearPopOverData = () => {
    // @ts-ignore
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef2.current = setTimeout(() => {
      /* eslint-disable-next-line */
      dispatch(togglePopOver({ show: false, targetType: '', data: null, targetHeight: 0, popOverHover: false }));
    }, 100);
  };

  return { handleGetPopOverData, handleClearPopOverData };
};

type UpdatePosition = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => Position;

export const useCursorPosition = (): UpdatePosition => {
  const updatePosition = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    return { x: left, y: top };
  }, []);

  return updatePosition;
};
