import PageContainer from '../custom-prebuilt/PageContainer';
import Card from '../components/shared/Card.component';
import GridTitle from '../components/shared/GridTitle.component';
import { useNavigate } from 'react-router';

const categories = [
  {
    id: 'jg-skill-explore',
    name: 'Explore',
    description: 'Explore people who know skills & tech stacks',
  },
];

const Explore = () => {
  const navigate = useNavigate();

  const redirectSkillExplore = () => {
    navigate('jg-skill-explore');
  };

  return (
    <PageContainer title="Explore">
      <div className="space-y-6">
        <div>
          <GridTitle>Available categories</GridTitle>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
            {categories.map((c, index) => (
              <Card title={c.name} hoverEnabled onClick={redirectSkillExplore} key={index}>
                <p className="text-xs">{c.description}</p>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Explore;
