import { useMutation } from "@apollo/client";
import {
  CheckCircleIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import { H2, Input, InputErrorCheck, Select } from "../../custom-prebuilt/common.component";
import LoadingState from "../../custom-prebuilt/preloader/LoadingState.component";
import {
  ConnectUserToEducation,
  DisconnectUserFromEducation,
  QueryUserEducation,
  UpdateUserEducationConnection,
} from "../../graphql/educations";
import { degreeData } from "./AddEducation.component";
import educationList from "../../assets/education.json"
import SearchAndSelectInput from "../../custom-prebuilt/SearchAndSelectInput";
import { useAuth } from "../../lib/authContext";

/** Component for advertising users education
 * @param {JSON} attendedData initial data on a user's education
 * @param {boolean} editable flag controlling whether this component is editable or not
 * @returns a card advertising a users education
 */
const EditableEducationCard = ({ attendedData }) => {
  const { userEmail } = useAuth();

  // component state variables
  const [editing, setEditing] = useState(false);
  const [inputError, setError] = useState(false);
  const [deletionConfirmationFlag,setDeletionFlag] = useState()

  // queries and mutations
  const [connectEducation, { loading: connectLoading, error: connectError}] = 
    useMutation(ConnectUserToEducation,{
      refetchQueries: [QueryUserEducation]
    });
  const [updateEducation, { loading: updateLoading, error: updateError}] = 
    useMutation(UpdateUserEducationConnection,{
      refetchQueries: [QueryUserEducation]
    });
  const [removeEducation, { loading: deleteLoading, error: deleteError }] =
    useMutation(DisconnectUserFromEducation, {
      refetchQueries: [QueryUserEducation]
  });
  
  // input state variables
  const[university,setUniversity] = useState(attendedData?.node.educationName)
  const[search, setSearch] = useState(attendedData?.node.educationName)
  const[degree,setDegree] = useState(attendedData?.degreeName)
  const[major,setMajor] = useState(attendedData?.majorName)
  const[startDate,setStartDate] = useState(attendedData?.startDate)
  const[endDate,setEndDate] = useState(attendedData?.endDate)

  const onCancel = () => {
    setEditing(false)
    setUniversity(attendedData?.node.educationName)
    setSearch(attendedData?.node.educationName)
    setDegree(attendedData?.degreeName)
    setMajor(attendedData?.majorName)
    setStartDate(attendedData?.startDate)
    setEndDate(attendedData?.endDate)
  }

  const onDelete = async () => {
    await removeEducation({
      variables: { 
        id: attendedData.id,
      },
      refetchQueries:[QueryUserEducation]
  });
    setEditing(false);
  };

  const onUpdate = async () => {
    if(university==="" || degree==="" || startDate==="" || endDate===""){
      setError(true)
      return
    }

    if(attendedData.node.educationName === university){
      await updateEducation({
          variables: {
              input: {
                  id: attendedData.id,
                  email: userEmail,
                  educationName: university,
                  degreeName: degree,
                  majorName: major,
                  startDate: startDate,
                  endDate: endDate,
              }
          }
      })
    }
    else{
      // university changed. delete old connection, connect to new
      await removeEducation({
          variables: { 
              id: attendedData.id,
          }
      })
      await connectEducation({
          variables: {
              input: {
                  email: userEmail,
                  educationName: university,
                  degreeName: degree,
                  majorName: major,
                  startDate: startDate,
                  endDate: endDate,
              }
          }
      })
    }
    setEditing()
    setError()
  }
  
  if (deleteError || updateError || connectError)
    console.error(deleteError,updateError,connectError);

  return (
    <div className="border border-borders rounded-md relative bg-white p-4 space-y-1">
      {(updateLoading||deleteLoading||connectLoading)?(<LoadingState />) :(
        editing ? (
          <>
            <div className=" space-y-1 absolute top-1 right-1">
              <CheckCircleIcon className="w-6 stroke-bg-dark" onClick={onUpdate} />
              <XCircleIcon className="w-6 stroke-bg-dark" onClick={onCancel}/>
            </div>
            {deletionConfirmationFlag?(
              <div className="absolute w-full h-full bg-white bg-opacity-90 top-0 left-0 rounded py-2">
                <p className="text-center my-4">Are you sure you would like to delete your work experience for <span className="italic font-bold">{attendedData?.node.educationName}</span>?</p>
                <div className="flex w-3/5 mx-auto">
                    <button 
                        className="rounded bg-red-400 hover:bg-red-500 hover:scale-105 transform transition-all text-white p-2 mx-auto"
                        onClick={onDelete}
                    >Confirm</button>
                    <button 
                        className="rounded bg-gray-800 hover:bg-black hover:scale-105 transform transition-all mx-auto text-white p-2"
                        onClick={() => setDeletionFlag(false)}
                    >Cancel</button>
                </div>
              </div>
            ):(
              <TrashIcon className="absolute bottom-1 right-1 w-6 stroke-bg-dark" onClick={()=>setDeletionFlag(true)}/>
            )}
            <SearchAndSelectInput classes="w-4/5" data={educationList} search={search} searchField={"educationName"} setSearch={(val) => {setSearch(val)}} setSelected={(val) => {setUniversity(val)}}/>
            <InputErrorCheck value={university} error={inputError} >This is a required field</InputErrorCheck>
            <Select
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              className="w-4/5"
            >
              <option value={""} />
              {degreeData.map((degree, index) => {
                return (
                  <option key={index} value={degree.degree}>
                    {degree.degree}
                  </option>
                );
              })}
            </Select>
            <InputErrorCheck value={degree} error={inputError} >This is a required field</InputErrorCheck>
            <Input
              type="text"
              value={major}
              className="w-4/5"
              onChange={(e) => setMajor(e.target.value)}
            />
            <div className="flex justify-between w-4/5">
				<Input
					type="text"
					maxLength="4"
					minLength="4"
					inputMode="numeric"
					pattern="[0-9]{4}"
					value={startDate}
					onChange={(e) => {
						if (Number(e.target.value) || e.target.value==="") setStartDate(e.target.value)
					}}
				/>
               <Input
					type="text"
					maxLength="4"
					minLength="4"
					inputMode="numeric"
					pattern="[0-9]{4}"
					value={endDate}
					onChange={(e) => {
						if (Number(e.target.value) || e.target.value==="") setEndDate(e.target.value)
					}}
				/>
            </div>
            <InputErrorCheck value={startDate} error={inputError} >This is a required field</InputErrorCheck>
            <InputErrorCheck value={endDate} error={inputError&&startDate!==""} >This is a required field</InputErrorCheck>
          </>
        ) : (
          <>
            <PencilIcon className="w-5 absolute top-1 right-2" onClick={() => {setEditing(true)}}/>
            <H2>{attendedData?.node.educationName}</H2>
            <p className="text-xs text-font-dark">{`${attendedData?.degreeName} ${attendedData?.majorName!==""?"in":""} ${attendedData?.majorName}`}</p>
            <p className="text-xs text-font-dark my-1">
                <span>{attendedData?.startDate || "N/A"} - {attendedData?.endDate || "N/A"}</span>
            </p>
          </>
        ))}
    </div>
  );
};
export default EditableEducationCard;
