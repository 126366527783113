/**
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

import { Area } from 'react-easy-crop';

export const createBlob = async (imageSrc: string, imageType: string) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    console.log('Could not retrieve canvas context');
    return;
  }
  ctx.drawImage(image, 0, 0);

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((file) => {
        resolve(file);
      }, imageType);
    } catch (error) {
      reject(error);
    }
  });
};

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  imageType?: string,
  rotation = 0,
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('Could not retrieve canvas context');

  const maxSideLength = Math.max(image.width, image.height);
  const canvasSideLength = 2 * ((maxSideLength / 2) * Math.sqrt(2));

  const canvasArea = canvasSideLength * canvasSideLength;
  const IPHONE_MAX_CANVAS_AREA = 16777216;
  if (canvasArea > IPHONE_MAX_CANVAS_AREA)
    throw new Error('Image is too large to be rotated safely');

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = canvasSideLength;
  canvas.height = canvasSideLength;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(canvasSideLength / 2, canvasSideLength / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-canvasSideLength / 2, -canvasSideLength / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    canvasSideLength / 2 - image.width * 0.5,
    canvasSideLength / 2 - image.height * 0.5,
  );

  const data = ctx.getImageData(0, 0, canvasSideLength, canvasSideLength);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - canvasSideLength / 2 + image.width * 0.5 - pixelCrop.x,
    0 - canvasSideLength / 2 + image.height * 0.5 - pixelCrop.y,
  );

  // As a blob (Required for S3 pre transform)
  // Promise((resolve, reject) => {})
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, imageType);
  });

  // As Base64 string
  //return canvas.toDataURL('image/jpeg');
  // return canvas;
}

//Need more information on what this function is supposed to do
/*export const generateDownload = async (imageSrc: string, crop: Area) => {
  if (!crop || !imageSrc) {
    return;
  }

  const canvas = await getCroppedImg(imageSrc, crop);

  if (!canvas) {
    console.error('An error occurred when cropping');
    return;
  }

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = 'image.jpeg';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    'image/jpeg',
    0.66,
  );
};*/
