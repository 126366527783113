import { classNames } from '../../lib/classNames';
import ProfileWrapper from './ProfileGeneratorWrapper';

const Template = ({ templateName, exampleTemplate, selectTemplate, isSelected }) => {
  const hoverColor = 'bg-gray-200';
  const selectedColor = 'bg-gray-800';
  return (
    <ProfileWrapper
      wrapperClassNames={isSelected ? selectedColor : 'bg-white'}
      html={exampleTemplate}
    >
      <div className="pt-2">
        <p
          className={classNames(
            'pl-4 pr-4 pb-2 text-sm truncate',
            isSelected ? 'text-font-light' : 'text-font-dark',
          )}
        >
          <b>Template name:</b> {templateName}
        </p>
        {!isSelected ? (
          <div className="flex divide-x-[1px] border-t-[1px] text-sm">
            <button
              onClick={selectTemplate}
              className={classNames(
                'flex basis-full justify-center items-center  p-2 rounded-b-md group',
                `hover:${hoverColor}`,
              )}
            >
              <p className={'text-font-dark'}>Use this Template</p>
            </button>
          </div>
        ) : null}
      </div>
    </ProfileWrapper>
  );
};

export default Template;
