import { stratify, tree } from 'd3-hierarchy';
import { Edge, MarkerType, Node } from 'reactflow';

export interface NodeProps extends SkillLike {
  isAdmin: Boolean;
}

export interface SkillLike {
  name: string;
  id: string;
  type: string;
  depth: number;
  parents: string[];
  icon?: string;
}

export const getNodes = (
  nodeArray: SkillLike[],
  admin = false,
  width = 120,
  height = 66,
  xSeparation = 10,
  ySeparation = 45,
): Node[] => {
  const root = stratify<SkillLike>().parentId((d) =>
    d.parents.reduce((acc, val) => {
      if (!acc) return val;
      const curParent = nodeArray.find((n) => n.id === acc) as SkillLike;
      const nextParent = nodeArray.find((n) => n.id === val) as SkillLike;
      return curParent.depth < nextParent.depth ? nextParent.id : curParent.id;
    }, null),
  )(nodeArray);
  const nodeTree = tree<SkillLike>()
    .nodeSize([width + xSeparation, height + ySeparation])
    .separation((a, b) => {
      return a.parent == b.parent ? 1 : 1.25;
    })(root);
  return nodeTree.descendants().map(({ data, x, y }) => {
    return {
      id: data.id,
      data: {
        ...data,
        isAdmin: admin,
      },
      position: { x, y },
      type: data.type,
    };
  });
};

export const getEdges = (nodeArray: SkillLike[]): Edge[] => {
  const ret: Edge[] = [];
  nodeArray.forEach((node) => {
    node.parents.forEach((parent) => {
      ret.push({
        id: `${node.id}_${parent}`,
        source: node.id,
        target: parent,
        style: { stroke: 'black' },
        markerEnd: { type: MarkerType.Arrow, color: 'black', width: 20, height: 20 },
      });
    });
  });
  return ret;
};

//truncate node name to stay within a character limit
export const formatNodeName = (name: string, charLimit: number): string => {
  return name.length > charLimit ? `${name.slice(0, charLimit - 3)}...` : name;
};
