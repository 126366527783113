import { useLazyQuery } from '@apollo/client';
import { omit } from 'lodash';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { ExportTaxonomy } from '../../../../graphql/taxonomy';
import { RootState } from '../../../../redux/store';

type EdgeJSON = {
  start: string;
  end: string;
  __typename?: string;
};

type CategoryJSON = {
  name: string;
  __typename?: string;
};

type SkillJSON = {
  name: string;
  slug: string;
  description: string;
  websiteLink: string;
  __typename?: string;
};

export type TaxonomyJSON = {
  skills: SkillJSON[];
  categories: CategoryJSON[];
  edges: EdgeJSON[];
  __typename?: string;
};

//remove __typename from query result
const stripTypename = (taxonomy: TaxonomyJSON): TaxonomyJSON => {
  const skills = taxonomy.skills.map((skill) => omit(skill, '__typename'));
  const categories = taxonomy.categories.map((category) => omit(category, '__typename'));
  const edges = taxonomy.edges.map((edge) => omit(edge, '__typename'));
  return { skills, categories, edges };
};

const ExportSkillTaxonomy = () => {
  const defaultOrgUid = useSelector((state: RootState) => state.org.defaultOrgUid);
  const [queryExportedTaxonomy] = useLazyQuery(ExportTaxonomy);

  const handleExportTaxonomy = () => {
    const promise = queryExportedTaxonomy({
      fetchPolicy: 'network-only',
      variables: {
        orgUid: defaultOrgUid,
      },
    }).then(({ data }) => {
      const json = JSON.stringify(stripTypename(data.exportTaxonomy), null, 2);
      const blob = new Blob([json], { type: 'text/json;' });
      const url = URL.createObjectURL(blob);
      const dl = document.createElement('a');
      dl.href = url;
      dl.setAttribute('download', `taxonomy_${new Date().toISOString()}.json`);
      dl.click();
      dl.remove();
    });
    toast.promise(promise, {
      success: 'Successfully exported!',
      loading: 'Exporting...',
      error: 'An error occurred!',
    });
  };

  return (
    <div
      className="flex items-center p-1 text-sm bg-white border rounded-sm shadow-sm border-gray-200 cursor-pointer hover:bg-gray-50"
      onClick={() => handleExportTaxonomy()}
    >
      Export
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5 ml-2 fill-none stroke-black stroke-[1.5]"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
        />
      </svg>
    </div>
  );
};

export default ExportSkillTaxonomy;
