import { gql } from '@apollo/client';

export const GetOrgTaxonomy = gql`
  query GetOrgTaxonomy($orgUid: String!) {
    getOrgTaxonomy(orgUid: $orgUid) {
      id
      name
      depth
      parents
      type
      icon
    }
  }
`;
/*
{
  "orgUid": null
}
*/

export const AttachNewSkill = gql`
  mutation AttachNewSkill(
    $parentId: String!
    $name: String!
    $slug: String!
    $active: Boolean!
    $imageLink: String!
    $websiteLink: String!
    $description: String!
    $dateAdded: String!
  ) {
    attachNewSkill(
      parentID: $parentId
      name: $name
      slug: $slug
      active: $active
      imageLink: $imageLink
      websiteLink: $websiteLink
      description: $description
      dateAdded: $dateAdded
    ) {
      id
      name
    }
  }
`;
/*
{
  "parentId": null,
  "name": null,
  "slug": null,
  "active": null,
  "imageLink": null,
  "websiteLink": null,
  "description": null,
  "dateAdded": null
}
*/

export const AttachNewSkillCategory = gql`
  mutation AttachNewSkillCategory($parentId: String!, $name: String!) {
    attachNewSkillCategory(parentID: $parentId, name: $name) {
      id
      name
    }
  }
`;
/*
{
  "parentId": null,
  "name": null
}
*/

export const DeleteSkillCategory = gql`
  mutation DeleteSkillCategory($nodeId: String!) {
    deleteSkillCategory(nodeID: $nodeId)
  }
`;
/*
{
  "nodeId": null
}
*/

export const GetUnattachedSkills = gql`
  query GetUnattachedSkills {
    getUnattachedSkills {
      name
      id
    }
  }
`;

export const AttachExistingNode = gql`
  mutation AttachExistingNode($parentId: String!, $childId: String!) {
    attachExistingNode(parentID: $parentId, childID: $childId)
  }
`;
/*
{
  "parentId": null,
  "childId": null
}
*/

export const DetachSkill = gql`
  mutation DetachSkill($nodeId: String!) {
    detachSkill(nodeID: $nodeId) {
      id
      name
    }
  }
`;

export const GetParentNodes = gql`
  query GetParentNodes($id: String!) {
    getParentNodes(childID: $id) {
      name
      id
    }
  }
`;

export const DetachEdgeMutation = gql`
  mutation DetachEdge($childID: String!, $parentID: String!) {
    removeRelationship(childID: $childID, parentID: $parentID)
  }
`;
/*
{
  "nodeId": null
}
*/

export const ReplaceSkill = gql`
  mutation ReplaceSkill($prevSkillId: String!, $newSkillId: String!) {
    replaceSkill(prevSkillID: $prevSkillId, newSkillID: $newSkillId) {
      id
      name
    }
  }
`;
/*
{
  "prevSkillId": null,
  "newSkillId": null
}
*/

export const ExportTaxonomy = gql`
  query ExportTaxonomy($orgUid: String!) {
    exportTaxonomy(orgUid: $orgUid) {
      skills {
        name
        slug
        description
        websiteLink
      }
      categories {
        name
      }
      edges {
        start
        end
      }
    }
  }
`;

export const GetCreatedSkills = gql`
  query GetCreatedSkills($skills: [ImportedSkill!]!) {
    getCreatedSkills(skills: $skills)
  }
`;
/*
{
  skills: null
}
*/

export const ImportTaxonomy = gql`
  mutation ImportTaxonomy(
    $orgUid: String!
    $skills: [ImportedSkill!]!
    $categories: [ImportedCategory!]!
    $edges: [ImportedEdge!]!
  ) {
    importTaxonomy(orgUid: $orgUid, skills: $skills, categories: $categories, edges: $edges) {
      name
    }
  }
`;

export const GetTaxonomySubtree = gql`
  query GetTaxonomySubtree($rootId: String!) {
    getTaxonomySubtree(rootID: $rootId) {
      id
      name
      parents
      type
      icon
      depth
    }
  }
`;
/*
{
  "rootId": null
}
*/

export const GetSkillPath = gql`
  query GetSkillPath($skillId: String!) {
    getSkillPath(skillID: $skillId) {
      id
      name
      parents
      type
      icon
      depth
    }
  }
`;
/*
{
  "skillId": null
}
*/
