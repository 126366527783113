import useTableViewHooks from '../custom/useTableViewHooks';
import TableView from '../custom/TableView.component';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SlideOver from '../../custom-prebuilt/SlideOver.component';
import { useDispatch } from 'react-redux';
import { toggleSlideOver } from '../../redux/slideOverSlice';
import SnapshotCertifications from '../../custom-prebuilt/snapshot/SnapshotCertifications';

const CertificationsTableView = (props) => {
  const { customList, paginationSize, search } = props;

  const dispatch = useDispatch();
  const show = useSelector((state) => state.slideOver.show);
  const target = useSelector((state) => state.slideOver.profileTarget);

  // Sorting initial states
  const initialColumns = {
    name: 0,
    level: 0,
    totalPeopleAcquired: 0,
  };

  const [handleSortColumns] = useTableViewHooks('certifications', initialColumns);

  const tableHeadItems = [
    {
      table: 'certifications',
      name: 'logo',
      title: 'Logo',
      sortable: false,
    },
    {
      table: 'certifications',
      name: 'name',
      title: 'Certification Name',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName:
        'cursor-pointer py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6',
    },
    {
      table: 'certifications',
      name: 'level',
      title: 'Level',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName:
        'cursor-pointer py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6',
    },
    {
      table: 'certifications',
      name: 'catergory',
      title: 'Category',
      sortable: false,
      customClassName: 'cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900',
    },
    {
      table: 'certifications',
      name: 'totalPeopleAcquired',
      title: 'Certification Holders',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName: 'cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900',
    },
  ];

  const RenderBody = useMemo(() => {
    const BodyMemo = () => {
      return (
        <>
          {customList &&
            customList.map((certification, index) => {
              return (
                <tr
                  key={index}
                  className="hover:bg-gray-200 transition duration-300 cursor-pointer"
                  onClick={() => {
                    dispatch(toggleSlideOver({ show: true, profileTarget: certification }));
                  }}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${certification.logo}`}
                          alt=""
                          referrerPolicy="no-referrer"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900">{certification.name}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900">{certification.level}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div
                      className={`${
                        certification.category !== null
                          ? 'bg-green-100 p-1 m-1 text-[10px] w-fit text-green-700 border-solid border-2 border-green-400 uppercase'
                          : 'hidden'
                      }`}
                    >
                      {certification.category}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {certification.activePeopleWithCert.length}
                  </td>
                </tr>
              );
            })}
          {}
        </>
      );
    };
    return BodyMemo;
  }, [customList, dispatch]);

  const closeCertHoldersSlideOver = () => {
    dispatch(toggleSlideOver({ show: false }));
    setTimeout(() => {
      dispatch(toggleSlideOver());
    }, 500);
  };

  return (
    <>
      <TableView
        data={customList}
        tableHeadItems={tableHeadItems}
        paginationSize={paginationSize}
        showPaginationNav={true}
        RenderBody={RenderBody}
        search={search}
        page="Certifications"
      />
      <SlideOver
        show={show}
        title="Certification Information"
        onClose={closeCertHoldersSlideOver}
        onClickButtonClose={closeCertHoldersSlideOver}
        largeSize={true}
        bgWhite={false}
        content={<SnapshotCertifications target={target} bgWhite={true} customList={customList} />}
      />
    </>
  );
};

export default CertificationsTableView;
