import React from 'react';

/**
 * 404 error component
 * @param {*} msg Custom message to display with 404 error code. Default error prompt will be served if not provided
 * @returns
 */

const NotFound = ({ msg }: { msg?: string }) => {
  return (
    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-xl font-bold tracking-tight text-gray-800">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-sm font-bold tracking-tight text-gray-900">Not Found</h1>
              <p className="mt-1 text-xs text-gray-500">
                {msg || 'The requested URL was not found on this server'}
              </p>
            </div>
            {/* <div className="mt-4 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <button
                                href="#"
                                className="inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium
                                    text-white shadow-sm hover:bg-gray-700 transition duration-300"
                            >
                                Go back home
                            </button>
                            <button
                                href="#"
                                className="inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium
                                    text-white shadow-sm hover:bg-gray-700 transition duration-300"
                            >
                                Contact admin
                            </button>
                        </div> */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotFound;
