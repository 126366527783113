import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import AutoSizeInput from '../../custom-prebuilt/AutoSizeInput';
import { mergeClasses } from '../../lib/classNames';
import { InputClasses } from './profile/ProfileGeneratorProfileInput';
import { setProfileGeneratorState } from './state/profileGeneratorSlice';

const onGenerateInputClasses =
  'border-borders border focus:border-signature focus:ring-signature text-xs rounded-md disabled:opacity-75';

const OnGenerateModal = () => {
  const fileNameRef = useRef();
  const changeMessageRef = useRef();
  const dispatch = useDispatch();
  const onChangeFileName = (event) => {
    dispatch(setProfileGeneratorState({ fileName: event.target.value }));
  };
  const onChangeChangeMessage = (event) => {
    dispatch(setProfileGeneratorState({ changeMessage: event.target.value }));
  };
  const fileName = useSelector((state) => state.profileGenerator.fileName);
  const changeMessage = useSelector((state) => state.profileGenerator.changeMessage);

  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-sm font-bold font-dark cursor-default self-center pb-1">
        Additonal Profile Information
      </h1>
      <div className="">
        <h1 className="text-xs text-font-dark font-bold cursor-default ">File Name</h1>
        <AutoSizeInput
          ref={fileNameRef}
          value={fileName}
          onChange={onChangeFileName}
          className={mergeClasses(InputClasses, onGenerateInputClasses, 'w-1/2')}
          dependencyArray={[fileName]}
        />
      </div>
      <div className="">
        <h1 className="text-xs text-font-dark font-bold cursor-default">Generation Message</h1>
        <AutoSizeInput
          ref={changeMessageRef}
          value={changeMessage}
          onChange={onChangeChangeMessage}
          className={mergeClasses(InputClasses, onGenerateInputClasses, 'w-[75%]')}
          dependencyArray={[changeMessage]}
        />
      </div>
    </div>
  );
};

export default OnGenerateModal;
