import { Input } from '../../../custom-prebuilt/common.component';
import { XIcon } from '@heroicons/react/outline';

const Search = ({ value, inputRef, onChange, wipeSearch }) => {
  return (
    <div>
      <h1 className="text-xs text-font-dark font-bold">Search</h1>
      <div className="flex gap-1 min-w-[200px] w-2/3 max-w-[400px] items-center">
        <Input
          ref={inputRef}
          value={value}
          onChange={onChange}
          placeholder="Search"
          className="text-font-dark font-normal pl-2 !border-borders border"
        />
        <XIcon className="h-5 text-red-400 cursor-pointer" onClick={wipeSearch} />
      </div>
    </div>
  );
};

export default Search;
