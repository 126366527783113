import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../../redux/modalSlice';
import { toggleSlideOver } from '../../redux/slideOverSlice';
// import defaultImg from "../../assets/default-user.svg"
const defaultImg: string = require('../../assets/default-user.svg').default;
import { usePopOver, useCursorPosition } from '../../lib/customHook';

interface Position {
  x: number;
  y: number;
}
type PersonBasicsType = {
  userIconUrl?: string;
  name?: string;
  email?: string;
};
type PersonNodeType = {
  node: PersonBasicsType;
};
export type PersonStackProps = {
  peopleArray: PersonNodeType[];
  stackNum: number;
  slideOverShow?: string;
  modalShow?: string;
};

/**
 * Stack of person
 * @param {*} peopleArray The people array
 * @param {*} stackNum Number of shown avatar
 * @param {*} slideOverShow att to show slideOver (eg: show1)
 * @param {*} stackNum att to show slideOver (eg: show1)
 */
const PersonStack = ({ peopleArray, stackNum, slideOverShow, modalShow }: PersonStackProps) => {
  const dispatch = useDispatch();
  const { handleGetPopOverData, handleClearPopOverData } = usePopOver();
  const updatePosition = useCursorPosition();

  return (
    <div className="-space-x-3 flex flex-row">
      {peopleArray?.map((e, index) => {
        const person = e.node || e;
        if (index < stackNum) {
          return (
            <img
              className="h-10 w-10 rounded-full ring-2 ring-white hover:ring-signature
                              transition duration-300 cursor-pointer"
              src={person.userIconUrl ?? defaultImg}
              alt=""
              // data-tip={person.name}
              // data-for="members"
              referrerPolicy="no-referrer"
              key={index}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                dispatch(
                  toggleSlideOver({ [slideOverShow || 'show2']: true, profileTarget: person }),
                );
              }}
              onMouseEnter={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                event.stopPropagation();
                const position: Position = updatePosition(event);
                // @ts-ignore
                handleGetPopOverData('person', person, position, event.target.clientHeight);
              }}
              onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                event.stopPropagation();
                handleClearPopOverData();
              }}
            />
          );
        } else if (index === stackNum) {
          return (
            <div key={index}>
              <div
                className="w-10 h-10 rounded-full flex justify-center items-center bg-gray-300 text-white
                                  cursor-pointer"
                onClick={() => {
                  dispatch(toggleModal({ [modalShow || 'show1']: true, members: peopleArray }));
                }}
              >
                +{peopleArray?.length - stackNum}
              </div>
            </div>
          );
        } else {
          return <Fragment key={index}></Fragment>;
        }
      })}
    </div>
  );
};

export default PersonStack;
