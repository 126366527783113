// Your SDK settings for browser.
// Replace authorizationKey with your api browser key.

export default {
  core: {
    authorizationKey: '7r3ic2je0u9pefjehe86lt7e98dt5ap9q1s9',
    key: 'ClientId', // UserId, OrganizationId etc.
    //key: 'DSFSDFSDFSDFSDFSDF', //7CTOs
    //key: '710bf6dd-1604-4a85-a8d3-bfa33904666e', //JahnelGroup
  },
};

// Replace the following with the name of your splits
export const skills_split = 'skills';
export const projects_split = 'projects';
