import React from 'react';
import { classNames } from '../../lib/classNames';

export interface CounterTextInput {
  counter: string | number | null | undefined;
  displayForNull?: boolean;
  displayForZero?: boolean;
  className?: string;
}
const CounterText = (props: CounterTextInput) => {
  const classes = classNames('text-font-light font-normal', props.className);
  const numCounter = Number(props.counter);

  return (
    <>
      {(!!numCounter ||
        !!(numCounter ?? props.displayForNull) ||
        !!(numCounter || props.displayForZero)) && (
        <span className={classes}>{numCounter || '0'}</span>
      )}
    </>
  );
};
CounterText.defaultProps = { displayForNull: true, displayForZero: true };

export default CounterText;
