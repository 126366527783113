import { useMutation } from '@apollo/client';
import { QueryOrgAdmins, UpdateAdmin } from '../../../../graphql/orgs';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Auth } from 'aws-amplify';
import { api_editAdmin } from './fetchResp';
import { successToastMid } from '../../../../lib/toast';

/**
 * A custom hook to update admin
 * @param {*} add True if adding admin, false otherwise
 * @returns The update function
 */
export const useUpdateAdmin = (add) => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const inputValue = useSelector((state) => state.actionInput.inputValue);
  const [updateAdmin] = useMutation(UpdateAdmin, {
    refetchQueries: () => {
      // Update Cognito admin group
      Auth.currentAuthenticatedUser().then((user) => {
        user.getSession((err, session) => {
          if (err) {
            throw new Error(err);
          }

          const sessionToken = session.getAccessToken().jwtToken;
          api_editAdmin(sessionToken, inputValue, add ? 'add' : 'remove')
            .then((data) => {
              data?.message && successToastMid(data.message);
            })
            .catch((err) => console.log(err));
        });
      });
      return [
        {
          query: QueryOrgAdmins,
          variables: {
            where: {
              uid: defaultOrgUid,
            },
            orgUnitsConnectionWhere2: {
              node: {
                organization: {
                  uid: defaultOrgUid,
                },
              },
            },
          },
        },
      ];
    },
    variables: {
      where: {
        email: inputValue,
      },
      update: {
        isAdmin: [
          {
            [add ? 'connect' : 'disconnect']: [
              {
                where: {
                  node: {
                    uid: defaultOrgUid,
                  },
                },
              },
            ],
          },
        ],
      },
    },
  });

  return () => {
    toast.promise(
      updateAdmin().then(() => {
        Array.from(document.getElementsByClassName('input-action-form')).forEach((form) =>
          form.reset(),
        );
      }),
      {
        loading: 'Updating',
        success: 'Updated',
        error: 'Error',
      },
      {
        position: 'top-center',
      },
    );
  };
};
