/* eslint-disable */

import { useQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Panel,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/base.css';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { AttachExistingNode, GetOrgTaxonomy } from '../../../graphql/taxonomy';
import { getEdges, getNodes } from '../../../lib/taxonomyHelpers';
import SkillCategoryNode from './nodes/SkillCategoryNode';
import SkillNode from './nodes/SkillNode';
import TaxonomyNode from './nodes/TaxonomyNode';
import { toast } from 'react-hot-toast';
import ExportSkillTaxonomy from './utils/ExportTaxonomy';
import ImportSkillTaxonomy from './utils/ImportTaxonomy';

const SkillTaxonomyAdminView = () => {
  const nodeTypes = useMemo(
    () => ({ Taxonomy: TaxonomyNode, SkillCategory: SkillCategoryNode, Skill: SkillNode }),
    [],
  );

  const [attachExistingSkill] = useMutation(AttachExistingNode, {
    refetchQueries: ['GetOrgTaxonomy', 'GetUnattachedSkills', 'GetParentNodes'],
  });

  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const { data } = useQuery(GetOrgTaxonomy, {
    variables: {
      orgUid: defaultOrgUid,
    },
  });
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    if (!data) return;
    const nodeArray = data?.getOrgTaxonomy;
    const formattedNodes = getNodes(nodeArray, true);
    const formattedEdges = getEdges(nodeArray);
    setNodes(formattedNodes);
    setEdges(formattedEdges);
  }, [data]);

  const onConnect = useCallback(({ source, target }) => {

	// Skill or SkillCategory and ends at a SkillCategory or Taxonomy;
	// if this is true, then the connection is created in the DB and if not then
	// an error toast is displayed.
	// Said another way; only one side can be a Skill (because there is only)
	// one Taxonomy.  I'm not sure this can even happen from the UI.
	let sourceNode = nodes.find(n => n.id == source)
	let targetNode = nodes.find(n => n.id == target)

	// if the edge already exists do nothing.
	const sortedEdges = [sourceNode.id, targetNode.id].sort()
	const existingEdge = edges.find(e => {
		let sortedEdge = [e.source, e.target].sort()
		return sortedEdge[0] == sortedEdges[0] && sortedEdge[1] == sortedEdges[1]
	})
	if (existingEdge) return

	// Probably unnecessary but
	// if both ends are Skills, or if one is skill and the other Taxonomy
	// warn and return.
	if (
		[sourceNode.type, targetNode.type].filter(t => t === 'Skill').length > 1 ||
		[sourceNode.type, targetNode.type].sort().join('') === 'SkillTaxonomy'
	) {
		toast('Skills may not link directly to other Skills or to Taxonomy.')
		return;
	}


	const promise = attachExistingSkill({
		variables: {
			parentId: targetNode.id,
			childId: sourceNode.id,
		},
	});
	toast.promise(promise, {
		loading: 'Linking...',
		error: 'An error occurred!',
	});

  }, [nodes, edges])

  return (
    <div className="w-full h-full">
      {!data ? (
        <LoadingState />
      ) : (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
		  onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <Panel
            position="top-left"
            className="flex flex-col items-center space-y-2 bg-transparent"
          >
            <ImportSkillTaxonomy />
            <ExportSkillTaxonomy />
          </Panel>
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      )}
    </div>
  );
};

export default SkillTaxonomyAdminView;
