import { MinusCircleIcon } from '@heroicons/react/solid';
import { classNames } from '../../../lib/classNames';

const projectOrder = ['Primary', 'Secondary', 'Passive'];

const getSkillInfo = (skill) => {
  let obj;
  let toolTip;

  if (skill.isProject) {
    if (skill.rating === 1) obj = { color: 'border-green-400', name: 'was primarily used' };
    else if (skill.rating === 2)
      obj = { color: 'border-emerald-400', name: 'was a secondary skill used' };
    else if (skill.rating === 3) obj = { color: 'border-teal-400', name: 'was passively used' };
    else obj = { color: 'border-slate-400', name: 'was used in an unknown way' };
    toolTip = `${skill.name} ${obj.name}`;
  } else {
    if (skill.rating === 1) obj = { color: 'border-yellow-400', name: 'Knowledgeable' };
    else if (skill.rating === 2) obj = { color: 'border-orange-400', name: 'Proficient' };
    else if (skill.rating === 3) obj = { color: 'border-rose-400', name: 'Lead/Tech' };
    else obj = { color: 'border-slate-400', name: 'Unrated' };
    toolTip = `${obj.name} of/in ${skill.name}`;
  }

  if (Array.isArray(skill.projectRatings)) {
    const counts = [0, 0, 0];
    const allRatings = skill.projectRatings ?? [];
    allRatings.forEach((val) => {
      if (val !== 0) counts[val - 1] += 1;
    });
    const total = counts.reduce((p, c) => p + c);
    if (total === 0) {
      toolTip += '<br/>No Project Experience';
    } else {
      counts.forEach((count, index) => {
        if (count > 0) toolTip += `<br/>${projectOrder[index]} Skill on ${count} projects`;
      });
    }
  }
  obj.tooltip = toolTip;
  return obj;
};

const SkillWrapper = ({ tooltip, tooltipClasses, children, onClick }) => {
  const onClickFunc = (event) => {
    if (typeof onClick !== 'undefined') onClick(event);
  };

  return (
    <div className="w-11" onClick={onClickFunc}>
      <div
        data-tip={tooltip}
        data-for="skill"
        className={classNames(
          'rounded-lg shadow h-11 w-11 p-2 transition duration-150 relative border-2',
          tooltipClasses,
        )}
      >
        {children}
      </div>
    </div>
  );
};

const CustomSkill = ({ skill, decorator, edit }) => {
  // if right clicked
  const editHandler = (event) => {
    if (event.detail === 2) edit();
  };

  return (
    <SkillWrapper tooltip={skill.skillName} onClick={editHandler}>
      {decorator}
      <img
        src={require(`/src/assets${skill.imageLink}`)}
        alt=""
        draggable={false}
        className="rounded-full select-none pointer-events-none h-full w-full"
      />
    </SkillWrapper>
  );
};

const Skill = ({ skill, disablePointer, decorator }) => {
  const { color: borderColor, tooltip } = getSkillInfo(skill);
  return (
    <SkillWrapper
      borderColor={borderColor}
      tooltip={tooltip}
      tooltipClasses={`${disablePointer ? 'cursor-default' : 'cursor-pointer'} ${borderColor}`}
    >
      {decorator}
      <img
        src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`}
        alt=""
        draggable={false}
        className="rounded-full select-none pointer-events-none h-full w-full"
      />
    </SkillWrapper>
  );
};

const SkillDisplaySkill = ({ skill, remove, edit }) => {
  const decorator = (
    <MinusCircleIcon
      onClick={remove}
      className="absolute text-red-400 -top-3 -right-2 h-6 cursor-pointer"
    />
  );
  if (skill.rating === -1)
    return <CustomSkill edit={edit} skill={skill} disablePointer decorator={decorator} />;

  return <Skill skill={skill} disablePointer decorator={decorator} />;
};

export { SkillDisplaySkill, Skill, CustomSkill, SkillWrapper };
