import { Auth } from 'aws-amplify';
import { useAuth } from '../../lib/authContext';
import { useNavigate, useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import { errorToastMid } from '../../lib/toast';
import DetailsChange from './utils/DetailsChange.component';
import ReactTooltip from 'react-tooltip';
import ForgotPassword from './utils/ForgotPassword.component';

const LoginForm = () => {
  const { cognitoUser, authenticated, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Modal FORCE_CHANGE_PASSWORD for first time login
  const [showModal, setShowModal] = useState(false);

  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
  });

  // STATES to handle FORCE_CHANGE_PASSWORD status
  const [forcedChangePWUser, setForceChangePWUser] = useState({});

  // Handle input change
  const handleInputChange = (e) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    location.pathname.startsWith('/invitation')
      ? sessionStorage.setItem('invitation_url', location.pathname)
      : sessionStorage.removeItem('invitation_url');
  }, [location.pathname]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  // Submit sign-in info
  const handleSubmit = async (e) => {
    e.preventDefault();
    const EMAIL = userInput.email;
    const PASSWORD = userInput.password;

    try {
      const user = await Auth.signIn(EMAIL, PASSWORD);
      // Challenge NEW_PASSWORD_REQUIRED
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('FORCE_CHANGE_PASSWORD', user);
        setForceChangePWUser(user);
        // dispatch(toggleModal({ show: true }))
        setShowModal(true);
      } else if (user.signInUserSession.accessToken.jwtToken) {
        navigate('/');
        window.location.reload();
      }
    } catch (error) {
      // console.log('sigin in error ', error.message);
      errorToastMid('Username or password was incorrect.')
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('toastedSignedIn')
    logout()
  }


  // Modal ForgotPassword
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const handleOpenForgotPassword = () => {
    setShowForgotPassword(true)
  }

  return (
    <div className='mx-auto w-full max-w-sm lg:w-96'>
      <ReactTooltip
        place='top'
        effect='solid'
        backgroundColor='#1F2937'
        offset={{ right: 10 }}
        id='logo'
      />

      <div className='w-full'>
        <div className='flex flex-row items-center'>
          <p className='w-fit text-3xl font-bold text-gray-900 tracking-tight'>
            Connect
          </p>
        </div>
        <h2 className='mt-4 text-xl font-semibold tracking-tight text-gray-900'>
          {!cognitoUser ? 'Sign in to your account' : 'Already signed in'}
        </h2>
      </div>

      {!authenticated ? (
        <div className='mt-8'>
          <div>
            <div>
              <p className='text-sm font-medium text-gray-700'>Sign in with</p>

              <div className='mt-1 grid grid-cols-3 gap-3'>
                {/* Google icon */}
                <div>
                  <button
                    onClick={() => {
                      Auth.federatedSignIn({ provider: 'Google' });
                    }}
                    className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm
                                                    transition duration-300 hover:bg-blue-500 group'
                  >
                    <span className='sr-only'>Sign in with Google</span>
                    <svg
                      className='h-5 w-5 group-hover:child:fill-white child:transition child:duration-300'
                      fill='currentColor'
                      viewBox='0 0 16 16'
                    >
                      <path d='M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z' />
                    </svg>
                  </button>
                </div>

                {/* Facebook icon */}
                <div>
                  <button
                    data-tip='Coming soon'
                    data-for='logo'
                    className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm
                                                    transition duration-300 hover:bg-gray-400 group cursor-not-allowed'
                  >
                    <span className='sr-only'>Sign in with Microsoft</span>

					<svg xmlns="http://www.w3.org/2000/svg"
						className='h-5 w-5 group-hover:child:fill-white child:transition child:duration-300'
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z"/>
					</svg>
                  </button>
                </div>

                {/* Github icon */}
                <div>
                  <button
                    data-tip='Coming soon'
                    data-for='logo'
                    className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm
                                                transition duration-300 hover:bg-gray-400 group cursor-not-allowed'
                  >
                    <span className='sr-only'>Sign in with LinkedIn</span>

					<svg xmlns="http://www.w3.org/2000/svg"
						className='h-5 w-5 group-hover:child:fill-white child:transition child:duration-300'
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
					</svg>
                  </button>
                </div>
              </div>
            </div>

            <div className='relative mt-6'>
              <div
                className='absolute inset-0 flex items-center'
                aria-hidden='true'
              >
                <div className='w-full border-t border-gray-300' />
              </div>
              <div className='relative flex justify-center text-sm'>
                <span className='px-2 text-gray-500 bg-white'>Or continue with</span>
              </div>
            </div>
          </div>

          <div className='mt-6'>
            <form onSubmit={handleSubmit} className='space-y-6'>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  Email address
                </label>
                <div className='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    value={userInput.email}
                    onChange={handleInputChange}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm'
                  />
                </div>
              </div>

              <div className='space-y-1'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'
                >
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    value={userInput.password}
                    onChange={handleInputChange}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm'
                  />
                </div>
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-signature focus:ring-signature'
                  />
                  <label
                    htmlFor='remember-me'
                    className='ml-2 block text-sm text-gray-900'
                  >
                    Remember me
                  </label>
                </div>

                <div className='text-sm' onClick={handleOpenForgotPassword}>
                  <p className='font-medium cursor-pointer hover:text-signature transition duration-150'>
                    Forgot your password?
                  </p>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-signature py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-signature focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2'
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className='mt-8'>
          <button
            onClick={() => {
              navigate('/');
            }}
            className='inline-flex w-full justify-center rounded-md border-0 bg-signature py-2 px-4 text-sm font-medium text-white shadow-sm
                                                transition duration-300 hover:bg-signature_light'
          >
            Go to dashboard
          </button>
          <button
            onClick={handleLogout}
            className='mt-2 inline-flex w-full justify-center rounded-md border-0 bg-gray-900 py-2 px-4 text-sm font-medium text-white shadow-sm
                                            transition duration-300 hover:bg-gray-800'
          >
            Sign out
          </button>
        </div>
      )}
      <DetailsChange
        show={showModal}
        setShowModal={setShowModal}
        user={forcedChangePWUser}
      />
      <ForgotPassword
        show={showForgotPassword}
        setShowModal={setShowForgotPassword}
      />
    </div>
  );
};

export default LoginForm;
