export const teamOptions = [
  {
    name: "budget",
    question: "What is your estimated team budget? Only include head count",
    stage: 2,
    description: "This excludes infrastructure cost, 3rd party services and fees",
    options: ["$100k", "$250k", "$500k", "$1.25M", "$3M", "$6M", "$8M", "$12M", "$15M", "$30M", "$50M+"],
    align: "row",
  },
  {
    name: "size",
    stage: 2,
    question: "How large is your team? Include head count for both full time, part time and consultants",
    description: "Direct and indirect reports excluding yourself. Round up to the closest number",
    options: ["1", "2", "3", "10", "20", "40", "$60", "100", "120", "200", "400+"],
    align: "row",
  }
]

export const levelOptions = [
  {
    name: "level",
    question: "Based on what you told us your company is at a level",
    stage: 2,
    description1: "Are you ready to take a few minutes to answer",
    description2: "multiple choice questions? This will help you see if you're operating at that level?",
    options: ["Yes", "No"],
  },
]

export const assessmentOptions = {
  104: {
    name: "Fractional",
    stage: 2,
    question: "Do you have fractional engagements",
    options: [
      "Zero time. I am not considering this at all", 
      "Not yet. I wish I had a way to build a funnel",
      "Yes. I regularly land short contracts to help other companies out",
      "Other"],
  },
  103: {
    name: "Category",
    stage: 2,
    question: "Are you a category leader?",
    options: [
      "Monopolies are bad. There's plenty of room for everyone", 
      "No. We are in a race to the bottom IMHO",
      "Working towards it. We want to own the category of products/services in our industry",
      "Other"],
  },
  102: {
    name: "Succession",
    stage: 2,
    question: "Could someone take over your role in the next 3 months?",
    options: [
      "No. My role is too niche at this point - but soon though", 
      "Almost. Working out the finer details but they are well on their way",
      "Yes. We could easily hire someone into my role",
      "Other"],
  },
  101: {
    name: "Boards",
    stage: 2,
    question: "How many Boards of Directors are you on?",
    options: [
      "Still looking", 
      "None",
      "At least one",
      "Other"],
  },
  94: {
    name: "Coaching",
    stage: 2,
    question: "Are you seen as a coach",
    options: [
      "Yes. I partner with people to explore possibilities for their lives", 
      "I don't know? I find that people take orders from me mostly but don't seek my counsel",
      "I solve problems. People come to me when they need specific answers",
      "Other"],
  },
  93: {
    name: "Investments",
    stage: 2,
    question: "What investments are you making",
    description: "This could be internal to the company, or external",
    options: [
      "I'm considering some right now. I am currently doing the legwork to see if we should invest in something", 
      "Not my department. I prefer to give this to my CEO or strategic advisors",
      "All sorts. I am good with not seeing the immdediate ROI because I believe in investing in the future",
      "Other"],
  },
  92: {
    name: "Management",
    stage: 2,
    question: "How do you feel about your management skills?",
    options: [
      "They suck. I wish I had an MBA or something", 
      "Pretty good. I work hard at being a great manager and other people would confirm that",
      "Suspicious. I am always looking for ways to improve",
      "Other"],
  },
}