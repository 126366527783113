import { formatDate } from '../../../lib/formatDate';
import Accordion from '../../Accordion.component';
import { DetailRow } from './UserDetails.component';
const defaultImg: string = require('../../../assets/default-user.svg').default;

type DynamicDataType = {
  [key: string]: any;
};

type ProjectMemberDetailsProps = {
  title: string;
  memberList: DynamicDataType[];
  editable: boolean;
  showButton: boolean;
  setAddModal: any;
  onEdit: any;
  setConfirmModal: any;
};

const userFields = [
  { field: 'role', label: 'Role', type: 'string' },
  { field: 'startDate', label: 'Start Date', type: 'date' },
  { field: 'endDate', label: 'End Date', type: 'date' },
  { field: 'hoursPerWeek', label: 'Hours Per Week', type: 'number' },
  { field: 'totalHours', label: 'Total Hours', type: 'number' },
  // { field: 'active', label: 'Active', type: 'boolean' },
];

const ProjectMemberDetails = ({
  title,
  memberList,
  editable,
  showButton,
  setAddModal,
  onEdit,
  setConfirmModal,
}: ProjectMemberDetailsProps) => {
  return (
    <>
      <Accordion
        expandAll={true}
        title={
          <span>
            {title}
            <span className="ml-1 font-light text-gray-500">{memberList.length}</span>
          </span>
        }
        content={
          <div className="w-full h-fit py-4">
            {editable && showButton && (
              <button
                className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md
              border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium text-white
              shadow-sm hover:bg-gray-700 sm:flex-1 transition duration-150"
                onClick={() => setAddModal(true)}
              >
                Add Project Member
              </button>
            )}
            {memberList.length ? (
              <div className="space-y-2">
                {memberList.map((obj: any, index: number) => {
                  const { node } = obj;
                  return (
                    <Accordion
                      expandAll={false}
                      title={
                        <div className="w-full flex flex-row justify-start items-center">
                          <img
                            className="rounded-full h-8 w-8 xl:h-8 xl:w-8"
                            src={node?.userIconUrl ?? defaultImg}
                            alt=""
                            referrerPolicy="no-referrer"
                          />
                          <div className="flex flex-col flex-1 ml-3">
                            <p className="text-xs text-gray-600 font-medium">{node.name}</p>
                            <p className="text-xs text-gray-500">{node.email}</p>
                          </div>
                        </div>
                      }
                      content={
                        <>
                          {userFields.map(({ field, label, type }, index) => (
                            <DetailRow
                              key={index}
                              label={label}
                              field={
                                obj[field] == null
                                  ? 'N/A' // eslint-disable-next-line
                                  : type === 'date'
                                  ? formatDate(obj[field], 'N/A')
                                  : obj[field].toString()
                              }
                              editable={editable}
                              onEdit={() => {
                                onEdit(obj, field, type);
                              }}
                            />
                          ))}
                          <div className="flex justify-end">
                            {obj.active && editable ? (
                              <button
                                className="shadow bg-gray-100 px-3 py-2 ml-4
                                hover:bg-gray-200 transition duration-300 text-xs"
                                onClick={() => {
                                  setConfirmModal(obj, 'deactivate', false);
                                }}
                              >
                                Deactivate
                              </button>
                            ) : (
                              !obj.active &&
                              editable && (
                                <>
                                  <button
                                    className="shadow bg-gray-100 px-3 py-2 ml-4 hover:bg-gray-200 transition duration-300 text-xs"
                                    onClick={() => {
                                      setConfirmModal(obj, 'reactivate', true);
                                    }}
                                  >
                                    Reactivate
                                  </button>
                                  <button
                                    className="shadow bg-gray-100 px-3 py-2 ml-4 hover:bg-gray-200 transition duration-300 text-xs font-bold text-red-700 hover:text-red-500"
                                    onClick={() => {
                                      setConfirmModal(obj, 'delete', null);
                                    }}
                                  >
                                    Remove
                                  </button>
                                </>
                              )
                            )}
                          </div>
                        </>
                      }
                      key={index}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="text-sm text-gray-500">No information</p>
            )}
          </div>
        }
      />
    </>
  );
};

export default ProjectMemberDetails;
