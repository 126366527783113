import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Button, Input, TextArea } from '../../../custom-prebuilt/common.component';
import { useHorizontalTabs } from '../../../lib/customHook';
import { useState, useEffect } from 'react';
import {
  QueryBounties,
  CreateCert, // QueryAllSkillsBasics,
  QueryCerts,
} from '../../../graphql/certifications';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Amplify, Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import { errorToastMid } from '../../../lib/toast';
import ImageInput from '../../../custom-prebuilt/ImageInput';

const tabs = ['Step 1', 'Step 2', 'Step 3'];

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

export const getSlug = (name) => {
	var slug = name.replace(/\W+/g, '-');
	slug = slug.replaceAll('_', '-'); //Handles previous rejex with underscore problem
	slug = slug.replaceAll('+', '-plus');
	if (slug[slug.length - 1] === '-') slug = slug.slice(0, -1); //Removes extra dash if user puts space at end

	return slug.toLowerCase();
};

const AddNewCert = (props) => {
  const [current, setCurrent, tabsComponent] = useHorizontalTabs(tabs, true);
  const [parent] = useAutoAnimate();

  const [bounties, setBounties] = useState([]);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [level, setLevel] = useState('');
  const [cost, setCost] = useState('');
  const [expiresAfter, setExpiresAfter] = useState('');

  const [website, setWebsite] = useState('');
  const [selectedBounties, setSelectedBounties] = useState([]);
  const [queryCerts] = useLazyQuery(QueryCerts);
  const [createCert] = useMutation(CreateCert);

  const queryBounties = useQuery(QueryBounties);

  useEffect(() => {
    queryBounties?.data?.bounties && setBounties(queryBounties?.data?.bounties);
  }, [queryBounties?.data]);

  const handleSelectBounty = (bounty) => {
    selectedBounties.find((st) => st.reward === bounty.reward)
      ? setSelectedBounties((selectedBounties) =>
          selectedBounties.filter((st) => st.reward !== bounty.reward),
        )
      : setSelectedBounties((selectedBounties) => [...selectedBounties, bounty]);
  };

  const getBountyInput = () => {
    return selectedBounties.map((s) => {
      return {
        where: {
          node: {
            reward: s.reward,
          },
        },
      };
    });
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'website':
        setWebsite(value);
        break;
      case 'category':
        setCategory(value);
        break;
      case 'level':
        setLevel(value);
        break;
      case 'cost':
        setCost(value);
        break;
      case 'expiresAfter':
        setExpiresAfter(value);
        break;
      default:
        break;
    }
  };

  const handleNextStage = () => {
    switch (current) {
      case 0:
        if (!name || !description || !website || !image) {
          errorToastMid('Missing input(s). Please check again');
        } else {
          setCurrent((current) => current + 1);
        }
        break;
      case 1:
        if (!selectedBounties.length) {
          errorToastMid('Please select at least 1 Bounty tag');
        } else {
          setCurrent((current) => current + 1);
        }
        break;
      default:
        break;
    }
  };

  const resizeFile = (file, maxWidth, maxHeight, compressFormat, quality) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file, // Is the file of the image which will resized.
        maxWidth, // Is the maxWidth of the resized new image.
        maxHeight, // Is the maxHeight of the resized new image.
        compressFormat, // Is the compressFormat of the resized new image.
        quality, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (uri) => {
          // Is the callBack function of the resized new image URI.
          resolve(uri);
        },
        'file', // Is the output type of the resized new image. Can be either base64, blob or file.(Default type is base64)
        0, // Is the minWidth of the resized new image.
        0, // Is the minHeight of the resized new image.
      );
    });

  const handleCreateCert = async () => {
    const resizedImage = await resizeFile(image, 200, 200, 'png', 100);
    const slug = getSlug(name);

    const { data } = await queryCerts({
      variables: { where: { name } },
      fetchPolicy: 'network-only',
    });

    if (data?.certs?.length) {
      errorToastMid('Certification already exists');
      return;
    }

    const createPromise = Storage.put(
      `CertificationLogos/${slug}_${resizedImage.name}`,
      resizedImage,
      {
        level: 'public',
        bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
      },
    )
      .then(() => {
        return createCert({
          variables: {
            input: [
              {
                bounty: {
                  connect: getBountyInput(),
                },
                category,
                cost,
                description,
                expires_after: expiresAfter,
                level,
                logo: `/CertificationLogos/${slug}_${resizedImage.name}`,
                name,
                websiteLink: website,
              },
            ],
          },
        });
      })
      .then(() => {
        setCurrent(0);
        setImage(null);
        setName('');
        setDescription('');
        setWebsite('');
        setSelectedBounties([]);
        setCost('');
        setLevel('');
        setCategory('');
        setExpiresAfter('');
        props?.reload?.();
      });
    toast.promise(createPromise, {
      success: 'Successfully created certification!',
      error: 'An error occurred!',
    });
  };

  return (
    <div className="flex-grow lg:px-5 mt-5 lg:mt-0">
      <h2 className="text-sm font-medium text-gray-900 mb-2 ml-1">Add New Certification</h2>
      <div ref={parent} className="w-full lg:max-w-lg p-6 bg-white shadow rounded-lg">
        {tabsComponent}
        {current === 0 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">Add basic certification info</p>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Name</label>
              <Input
                className="border px-2"
                name="name"
                value={name}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Description</label>
              <TextArea
                className="border px-2 resize-y h-24"
                value={description}
                name="description"
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Website Link</label>
              <Input
                className="border px-2"
                name="website"
                value={website}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex justify-between w-full pt-2">
              <ImageInput
                image={image}
                setImage={setImage}
                enableCropper
                cropShowGrid
                cropShape={'round'}
                cropAspect={1}
                contentWrapperClasses="flex items-center space-x-4 pt-1"
                displayImageClasses="h-[84px] w-[84px] rounded-full bg-white border-2 p-1 border-gray-800"
                changeButtonText="Upload Image"
              />
            </div>
          </div>
        )}

        {current === 1 && (
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Category</label>
              <Input
                className="border px-2"
                name="category"
                value={category}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Level</label>
              <Input
                className="border px-2"
                name="level"
                value={level}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Cost</label>
              <Input
                className="border px-2"
                name="cost"
                value={cost}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Expires after</label>
              <Input
                className="border px-2"
                name="expiresAfter"
                value={expiresAfter}
                onChange={handleInputChange}
              />
            </div>

            <p className="text-xs text-font-light font-semibold">Select Bounty tags</p>
            <div className="flex flex-row flex-wrap align-center">
              {bounties.map((b, index) => (
                <div key={index}>
                  <p
                    className={`px-2 py-1 mr-1 mb-1 text-xs transition duration-300 cursor-pointer
                            ${
                              selectedBounties.find((s) => s.reward === b.reward)
                                ? 'bg-gray-900 text-white'
                                : 'bg-gray-200 text-gray-600'
                            }`}
                    onClick={() => handleSelectBounty(b)}
                  >
                    {b.reward}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {current === 2 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">Confirm details</p>
            <div className="flex flex-col space-y-1">
              <p className="text-xs font-medium text-black">
                Name: <span className="text-gray-500">{name}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Category: <span className="text-gray-500">{category}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Level: <span className="text-gray-500">{level}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Cost: <span className="text-gray-500">{cost}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Expires after: <span className="text-gray-500">{expiresAfter}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Description: <span className="text-gray-500">{description}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Website link: <span className="text-gray-500">{website}</span>
              </p>
              <div className="text-xs font-medium text-black flex flex-row flex-wrap">
                <p className="mr-2">Bounties:</p>
                {selectedBounties.map((st, index) => (
                  <p
                    key={index}
                    className="px-2 py-1 mr-1 mb-1 text-xs transition duration-300 cursor-pointer bg-gray-200 text-gray-600"
                  >
                    {st.reward}
                  </p>
                ))}
              </div>
              <div className="flex flex-col">
                <span className="text-xs font-medium text-black">Image:</span>
                <img
                  className="mt-2 ml-4 h-[84px] w-[84px] rounded-full bg-white border-2 p-1 border-gray-800"
                  src={URL.createObjectURL(image)}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full justify-end flex flex-row space-x-1 mt-4">
          {current !== 0 && (
            <Button onClick={() => setCurrent((current) => current - 1)}>Back</Button>
          )}
          {current !== 2 && <Button onClick={handleNextStage}>Next</Button>}
          {current === 2 && <Button onClick={handleCreateCert}>Create</Button>}
        </div>
      </div>
    </div>
  );
};

export default AddNewCert;
