import React from 'react';
import { classNames } from '../../lib/classNames';
import { CheckCircleIcon, PencilIcon, TrashIcon, XCircleIcon } from '@heroicons/react/outline';

export enum IconButtons {
  CheckCircle = 'CheckCircle',
  XCircle = 'XCircle',
  Trash = 'Trash',
  Pencil = 'Pencil',
}

export interface IconButtonInput {
  icon: IconButtons;
  onClick?: () => void;
  className?: string;
}

const IconButton = (props: IconButtonInput) => {
  const classes = classNames('w-6 stroke-bg-dark cursor-pointer', props.className);

  return switchIcon();

  function switchIcon() {
    switch (props.icon) {
      case IconButtons.CheckCircle:
        return <CheckCircleIcon className={classes} onClick={props.onClick} />;
      case IconButtons.XCircle:
        return <XCircleIcon className={classes} onClick={props.onClick} />;
      case IconButtons.Trash:
        return <TrashIcon className={classes} onClick={props.onClick} />;
      case IconButtons.Pencil:
        return <PencilIcon className={classes} onClick={props.onClick} />;
      default:
        return <></>;
    }
  }
};

export default IconButton;
