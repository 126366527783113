import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button, Input, Label } from '../../../../custom-prebuilt/common.component';
import { AttachNewSkillCategory } from '../../../../graphql/taxonomy';

type AttachSkillCategoryProps = {
  id: string;
  closeSlideover: () => void;
};

const AttachSkillCategory = ({ id, closeSlideover }: AttachSkillCategoryProps) => {
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [attachNewSkillCategory] = useMutation(AttachNewSkillCategory, {
    refetchQueries: ['GetOrgTaxonomy'],
  });

  const validateForm = () => {
    if (!name) {
      setError(true);
      return false;
    }
    return true;
  };

  const handleCreateSkillCategory = () => {
    if (validateForm()) {
      const promise = attachNewSkillCategory({
        variables: {
          parentId: id,
          name: name,
        },
      });
      toast.promise(promise, {
        loading: 'Creating Skill Category...',
        success: 'Successfully created Skill Category!',
        error: 'An error occurred!',
      });
      closeSlideover();
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Label className="max-w-[66%] text-xs">
        New Skill Category Name:
        <Input
          placeholder="Skill Category name"
          value={name}
          onChange={(e) => {
            setError(false);
            setName(e.target.value);
          }}
        />
      </Label>
      <span className={`text-red-600 text-xs ${error ? 'block' : 'hidden'}`}>
        Name is required!
      </span>
      <Button
        className="w-fit mt-2 px-3 py-2 rounded-md shadow-sm border border-gray-300 font-medium bg-bg-dark text-xs text-white hover:bg-gray-700"
        onClick={() => handleCreateSkillCategory()}
      >
        Create New Skill Category
      </Button>
    </div>
  );
};

export default AttachSkillCategory;
