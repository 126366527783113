import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type UserKnowledgeState = {
  skills: any[] | null;
  projectSkills: any[] | null;
  certs: any[] | null;
};

const initState: UserKnowledgeState = {
  skills: null,
  projectSkills: null,
  certs: null,
};

export const userKnowledgeSlice = createSlice({
  name: 'user-knowledge',
  initialState: initState,
  reducers: {
    toggleUserKnowledge: (state, action: PayloadAction<Partial<UserKnowledgeState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleUserKnowledge } = userKnowledgeSlice.actions;

export default userKnowledgeSlice.reducer;
