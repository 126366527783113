import { useNavigate } from 'react-router';
import TableView from '../../components/custom/TableView.component';
import { useMemo } from 'react';
import { toggleSlideOver } from '../../redux/slideOverSlice';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../lib/formatDate';
// import defaultImg from "../../assets/default-user.svg";
const defaultImg: string = require('../../assets/default-user.svg').default;

export type SnapshotCertificationsProps = {
  target: any;
  bgWhite: boolean;
  customList?: any[];
};

/**
 * @props target: GraphQL type. Taken from Redux: state.slideOver.profileTarget
 * @props bgWhite: boolean
 * @returns
 */
const SnapshotCertifications = ({ target, bgWhite }: SnapshotCertificationsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const certHolders = target.peopleWithCertConnection.edges;
  const holderList = certHolders.map((connection: any, index: number) => {
    return { ...target.peopleWithCertConnection.edges[index], key: connection.node.name };
  });

  const tableHeadItems = [
    {
      table: 'certificationHolders',
      name: 'peopleWithCertConnection.edges.name',
      title: 'Certification Holder',
    },
    {
      table: 'certificationHolders',
      name: 'peopleWithCertConnection',
      title: 'Date Acquired',
    },
  ];

  const RenderBody = useMemo(() => {
    const BodyMemo = () => {
      return (
        <>
          {holderList &&
            holderList.map((person: any) => {
              return (
                <tr
                  key={person.key}
                  className="hover:bg-gray-200 transition duration-300"
                  onClick={() => {
                    navigate(`/${person?.node.email.split('.').at(0)}`);
                    dispatch(toggleSlideOver({ show: false }));
                  }}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={person.node.userIconUrl ?? defaultImg}
                          alt=""
                          referrerPolicy="no-referrer"
                        />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{person.key}</div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div className="text-gray-900">
                      {person.acquiredDate ? formatDate(person.acquiredDate) : 'N/A'}
                    </div>
                  </td>
                </tr>
              );
            })}
        </>
      );
    };
    return BodyMemo;
  }, [holderList, dispatch, navigate]);

  return (
    <div className={`flex h-fit rounded-md flex-col ${!bgWhite ? 'bg-bg_lightgray' : 'bg-white'}`}>
      <div className="divide-y divide-gray-200">
        <div className="pb-4 bg-bg_light px-4 mb-4">
          <div className="flow-root px-4 sm:flex sm:items-end sm:px-6 bg-bg_light pb-8 rounded-lg pt-6">
            <div>
              <div className="-m-1 flex">
                <div className="inline-flex overflow-hidden rounded-lg mb-4 sm:mb-0">
                  <img
                    className="h-20 w-20 flex-shrink-0 sm:h-24 sm:w-24"
                    src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${target?.logo}`}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                </div>
              </div>
            </div>
            <div className="sm:ml-6 sm:flex-1">
              <div>
                <div className="flex items-center">
                  <h3 className="text-base font-bold text-gray-900 mt-3 sm:mt-0">
                    {target && target.name}
                  </h3>
                </div>
              </div>
              <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                <a
                  href={
                    target &&
                    target.websiteLink &&
                    (target?.websiteLink?.includes('//') ? '' : '//') + target?.websiteLink
                  }
                  target="empty"
                  className="inline-flex w-full"
                >
                  <button
                    type="button"
                    className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-gray-700 sm:flex-1 transition duration-150"
                  >
                    Certification Details
                  </button>
                </a>
              </div>
            </div>
          </div>
          <p className="text-sm font-normal text-gray-700">{target && target.description}</p>
        </div>
        {holderList.length ? (
          <TableView
            data={holderList}
            RenderBody={RenderBody}
            tableHeadItems={tableHeadItems}
            showPaginationNav={false}
            page="certification holders"
          />
        ) : (
          <p className="px-4 py-4 text-xs font-medium text-font-light">
            No user has rated this certification yet
          </p>
        )}
      </div>
    </div>
  );
};

export default SnapshotCertifications;
