import { useLazyQuery } from '@apollo/client';
import { SearchIcon } from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { QueryCurrentOrg } from '../../../graphql/people';
import { QueryAllProjects } from '../../../graphql/projects';
import { getSections } from '../../../lib/getNameSections';
import SnapshotProject from '../../../custom-prebuilt/snapshot/SnapshotProject.component';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../lib/authContext';
import SearchLogic from '../../../custom-prebuilt/SearchLogic.component';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { projects_split } from '../../../split.io/sdk.config';
import RestrictedAuth from '../../../custom-prebuilt/RestrictedAuth.component';
import { classNames } from '../../../lib/classNames';
import { toggleModal } from '../../../redux/modalSlice';
import { useApplyFilter } from '../../../lib/customHook';
import { orgCheck } from '../../../lib/orgCheck';

const ProjectsDirectoryAdminView = () => {
  const { cognitoID } = useAuth();
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsDirectory, setProjectsDirectory] = useState([]);
  const [initAllProjectsData, setInitAllProjectsData] = useState(null);
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const [searchInput, setSearchInput] = useState('');

  const [applyFilter, setApplyFilter, activeFilter, inactiveFilter, ModalComponent] =
    useApplyFilter();

  const dispatch = useDispatch();

  const [currentOrg] = useLazyQuery(QueryCurrentOrg);
  const [allProjects] = useLazyQuery(QueryAllProjects);

  useEffect(() => {
    if (applyFilter) {
      setProjectsDirectory([]);
      setInitAllProjectsData(null);
      setSelectedProject(null);

      currentOrg({
        variables: { where: { cognitoID: cognitoID || '' } },
      }).then((res) => {
        let where = {
          OR: [
            {
              name_NOT_STARTS_WITH: 'JG',
            },
            {
              name_IN: ['JG - Connect Development', 'JG - Tense'],
            },
          ],
        };
        if (!activeFilter || !inactiveFilter) {
          where.active = activeFilter;
        }
        orgCheck(res, defaultOrg) &&
          allProjects({
            // Need to implement "WORKS_AT" for Projects
            variables: { where },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'standby', // prevent repetition when re-querying
          }).then((allProjectsData) => {
            if (allProjectsData.data?.projects) {
              const sortedProjects = [
                ...getSections(
                  [...allProjectsData.data.projects].sort((a, b) => a.name?.localeCompare(b.name)),
                ),
              ];
              setProjectsDirectory(sortedProjects);
              setInitAllProjectsData(allProjectsData);
              setSelectedProject(sortedProjects[0]?.data?.[0]);
            }
            setApplyFilter(false);
          });
      });
    }
  }, [
    cognitoID,
    defaultOrg,
    allProjects,
    currentOrg,
    applyFilter,
    activeFilter,
    inactiveFilter,
    setApplyFilter,
  ]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    initAllProjectsData.data?.projects &&
      e.target.value === '' &&
      setProjectsDirectory([
        ...getSections(
          [...initAllProjectsData.data.projects].sort((a, b) => a.name?.localeCompare(b.name)),
        ),
      ]);
    initAllProjectsData.data?.projects &&
      e.target.value !== '' &&
      setProjectsDirectory([
        ...getSections(
          initAllProjectsData.data.projects.filter((project) =>
            project.name.toLowerCase().includes(e.target.value.toLowerCase()),
          ),
        ),
      ]);
  };

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  return (
    <SplitTreatments names={[projects_split]}>
      {({ treatments, isReady }) => {
        return isReady && treatments[projects_split].treatment === 'on' ? (
          <div className="relative z-0 flex flex-1 flex-col-reverse lg:flex-row overflow-auto h-full">
            <aside className="max-w-[20rem] 2xl:max-w-sm min-w-[20rem] 2xl:min-w-[24rem] flex-1 flex-grow border-r border-gray-200 xl:order-first lg:flex lg:flex-col">
              <div className="lg:px-6 pt-6 pb-4">
                <h2 className="text-sm font-medium text-gray-900">Projects directory</h2>
                <p className="mt-1 text-xs text-gray-600">Select a project to view information</p>
                <div className="mt-2 flex space-x-4">
                  <div className="min-w-0 flex-1">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-signature focus:ring-signature
                            text-xs"
                        placeholder="Search"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      dispatch(toggleModal({ show: true }));
                    }}
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-gray-800 px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-700
                  focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2 transition duration-300"
                  >
                    <FilterIcon className="h-4 w-4 text-white" aria-hidden="true" />
                    <span className="sr-only">Search</span>
                  </button>
                  {ModalComponent}
                </div>
              </div>
              {/* Directory list */}
              <SearchLogic
                loading={!projectsDirectory.length}
                dataCheck={projectsDirectory.length}
                dataComponent={
                  <nav className="min-h-0 flex-1 overflow-y-auto flex-grow" aria-label="Directory">
                    {projectsDirectory?.map((el, index) => {
                      return (
                        <div key={index} className="relative">
                          <div className="sticky top-0 z-10 border-t border-b border-gray-200 px-6 py-1 text-sm font-medium text-gray-500 bg-gray-200">
                            <h3>{el.letter}</h3>
                          </div>
                          <ul className="relative z-0 divide-y divide-gray-200">
                            {projectsDirectory[index].data.map((project) => {
                              return (
                                <li key={project.id}>
                                  <div
                                    className={classNames(
                                      'relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-200 transition duration-300',
                                      selectedProject === project &&
                                        'ring-inset ring-signature bg-gray-200 ring-2',
                                    )}
                                  >
                                    <div className="flex-shrink-0">
                                      {/* <img className="h-8 w-8 xl:h-8 xl:w-8 select-none mr-2 sm:mr-3"
                            src={require(`/src/assets${project.imageLink}`)} alt="" />
                                      */}
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div className="focus:outline-none cursor-pointer">
                                        {/* Extend touch target to entire panel */}
                                        <span
                                          className="absolute inset-0"
                                          aria-hidden="true"
                                          onClick={() => {
                                            handleSelectProject(project);
                                          }}
                                        />
                                        <p className="text-xs font-medium text-gray-900">
                                          {project.name}
                                        </p>
                                        <p className="truncate text-xs text-gray-500">
                                          {project.startDate || 'No information'}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </nav>
                }
                searchInput={searchInput}
                reloadCondition={applyFilter}
              />
            </aside>
            <aside
              //className='border-r border-gray-200 xl:order-first xl:flex xl:flex-col'
              className="border-r border-gray-200 xl:order-first xl:flex xl:flex-col hidden flex-grow"
            >
              {selectedProject ? <SnapshotProject target={selectedProject} editable /> : null}
            </aside>
          </div>
        ) : (
          <RestrictedAuth />
        );
      }}
    </SplitTreatments>
  );
};

export default ProjectsDirectoryAdminView;
