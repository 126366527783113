import { gql } from '@apollo/client';

export const QuerySkillsTablePagination = gql`
  query QuerySkillsTablePagination($options: SkillOptions) {
    skills(options: $options, where: { active: true }) {
      name
      slug
      description
      imageLink
      dateAdded
      peopleWithSkillAggregate(where: { active: true }) {
        count
      }
      category {
        type
        value
      }
    }
  }
`;

export const QueryAllSkills = gql`
  query QueryAllSkills($where: SkillPeopleWithSkillConnectionWhere, $skillsWhere2: SkillWhere) {
    skills(where: $skillsWhere2) {
      name
      slug
      imageLink
      description
      dateAdded
      category {
        type
        value
        icon
      }
      peopleWithSkill {
        email
        name
        userIconUrl
      }
      peopleWithSkillConnection(where: $where) {
        edges {
          rating
        }
      }
    }
  }
`;

export const QueryAllSkillsBasics = gql`
  query QueryAllSkillsBasics($where: SkillWhere) {
    skills(where: $where) {
      name
      slug
      imageLink
    }
  }
`;

export const QueryPopularSkills = gql`
  query QueryPopularSkills(
    $where: SkillWhere
    $peopleWithSkillConnectionWhere2: SkillPeopleWithSkillConnectionWhere
    $options: SkillOptions
  ) {
    skills(where: $where, options: $options) {
      name
      slug
      imageLink
      description
      dateAdded
      category {
        type
        value
        icon
      }
      peopleWithSkill {
        email
        name
        userIconUrl
      }
      peopleWithSkillConnection(where: $peopleWithSkillConnectionWhere2) {
        edges {
          rating
        }
      }
    }
  }
`;

export const QuerySkillDetails = gql`
  query QuerySkillDetails(
    $where: SkillWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    skills(where: $where) {
	  id
      name
      slug
      imageLink
      description
      dateAdded
      active
      websiteLink
      peopleWithSkillConnection(where: { node: { active: true } }) {
        edges {
          node {
            name
            email
            userIconUrl
            orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
              edges {
                position
                startDate
                node {
                  name
                }
              }
            }
            google_workLocation
            locationState
            slackUsername
          }
          rating
        }
        totalCount
      }
      category {
        value
        type
      }
    }
  }
`;

export const UpdateSkillInfo = gql`
  mutation UpdateSkillInfo(
    $where: SkillWhere
    $disconnect: SkillDisconnectInput
    $connect: SkillConnectInput
    $update: SkillUpdateInput
  ) {
    updateSkills(where: $where, disconnect: $disconnect, connect: $connect, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

export const QuerySkillTags = gql`
  query QuerySkillTags {
    skillTags {
      type
      value
    }
  }
`;

export const CreateSkill = gql`
  mutation CreateSkill($input: [SkillCreateInput!]!) {
    createSkills(input: $input) {
      info {
        bookmark
      }
    }
  }
`;

export const QuerySkill = gql`
  query QuerySkill($where: SkillWhere) {
    skills(where: $where) {
      name
      slug
    }
  }
`;

export const DeleteSkill = gql`
  mutation DeleteSkill($where: SkillWhere) {
    deleteSkills(where: $where) {
      bookmark
    }
  }
`;

export const DeleteCert = gql`
  mutation DeleteCert($where: CertificationWhere) {
    deleteCertifications(where: $where) {
      bookmark
    }
  }
`;

export const QueryRequestedSkills = gql`
  query QueryRequestedSkills($where: UserRequestWhere) {
    userRequests(where: $where) {
      name
      requestedBy {
        name
      }
      requestedByConnection {
        edges {
          dateRequested
        }
      }
    }
  }
`;

export const DeleteRequestedSkills = gql`
  mutation DeleteRequestedSkills($where: UserRequestWhere) {
    deleteUserRequests(where: $where) {
      nodesDeleted
    }
  }
`;
