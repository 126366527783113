import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { QueryCompany } from '../../../../graphql/orgs';
import LoadingState from '../../../../custom-prebuilt/preloader/LoadingState.component';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useNavigate } from 'react-router';
import SnapshotProfile from '../../../../custom-prebuilt/snapshot/SnapshotProfile.component';
import SlideOver from '../../../../custom-prebuilt/SlideOver.component';
import { toggleSlideOver } from '../../../../redux/slideOverSlice';
import { useLocation } from 'react-router';
import Card from '../../../shared/Card.component';
import defaultImg from '../../../../assets/default-user.svg';

/**
 *
 * @param email
 * @param slim whether to display slim version of Company card
 * @returns
 */
const UserUnitGroup = ({ email, slim = false }) => {
  const show = useSelector((state) => state.slideOver.show);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const [parent] = useAutoAnimate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const profileEmail = email || location.pathname.split('/').at(1) + '.com';
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  const queryCompany = useQuery(QueryCompany, {
    variables: {
      where: {
        members_SOME: {
          email: profileEmail || '',
        },
        organization: {
          uid: defaultOrgUid,
        },
      },
      membersConnectionWhere2: {
        node: {
          active: true,
        },
      },
      first: 3,
      sort: [
        {
          node: {
            name: 'ASC',
          },
        },
      ],
    },
  });

  const orgUnit = queryCompany.data?.orgUnits[0];

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false, profileTarget: null }));
  };

  const handleOpenSlideOver = (member) => {
    dispatch(
      toggleSlideOver({
        show: true,
        profileTarget: { email: member?.email },
      }),
    );
  };

  return (
    <Card title="Company" className={slim && 'pb-4'}>
      <div ref={parent} className="flex flex-col w-full space-y-6">
        {!queryCompany.data?.orgUnits[0] ? (
          <LoadingState />
        ) : (
          <>
            <div className="flex flex-row">
              <div className="bg-gray-900 rounded-full h-20 w-20 flex-shrink-0">
                <img
                  className="h-full w-full rounded-full"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${orgUnit?.logo}`}
                  alt=""
                />
              </div>
              <div className="flex flex-col ml-5 h-full">
                <p className="text-sm font-semibold mb-2">{orgUnit?.name || 'Company Name'}</p>
                <div className="flex flex-col space-y-1">
                  <p className="text-xs font-normal text-gray-600">{orgUnit?.motto || 'Motto'}</p>
                  <p className="text-xs font-medium">
                    Location:{' '}
                    {!orgUnit?.locationCity || !orgUnit?.locationState
                      ? 'N/A'
                      : `${orgUnit?.locationCity}, ${orgUnit?.locationState}`}
                  </p>
                  <a
                    className="text-xs font-medium"
                    href={orgUnit?.website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Website: {orgUnit?.website || 'N/A'}
                  </a>
                </div>
              </div>
            </div>

            {!slim && (
              <div className="flex flex-col">
                <p className="text-xs text-medium text-font-light mb-4">
                  {orgUnit?.memberCount} members
                </p>
                <div className="flex flex-col space-y-4 mb-6">
                  {orgUnit?.membersConnection?.edges.map((member, index) => (
                    <div key={index} className="flex flex-row items-center">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={member?.node.userIconUrl ?? defaultImg}
                        alt=""
                        referrerPolicy="no-referrer"
                      />
                      <div className="flex flex-col ml-3">
                        <p className="text-xs font-medium">{member?.node.name}</p>
                        <p className="text-xs font-light text-gray-600">{member.position}</p>
                      </div>
                      <button
                        onClick={() => handleOpenSlideOver(member?.node)}
                        className="shadow bg-gray-100 px-3 py-2 ml-auto hover:bg-gray-200 transition duration-300
                        text-xs"
                      >
                        View
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => {
                    navigate('/people');
                  }}
                  className="w-full py-2 text-xs shadow bg-white border border-gray-200 rounded-md hover:bg-gray-100 transition
                  duration-300"
                >
                  View all company members
                </button>
              </div>
            )}
          </>
        )}
      </div>

      <SlideOver
        show={show}
        title="User Profile"
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        largeSize={true}
        content={<SnapshotProfile target={target} coverPic={true} />}
      />
    </Card>
  );
};

export default UserUnitGroup;
