import { useState } from 'react';

export type DynamicDataType = {
  [key: string]: string;
};

export type SearchAndSelectInputProps = {
  data: DynamicDataType[];
  searchField: string;
  setSelected: (params: string) => void;
  search: string;
  setSearch: (params: string) => void;
  classes?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
};

/** An input box that displays a list of selectable entries as a user types. Accepts
 * a list of JSON objects, a search field, and a reference to a state mutator to preserve
 * the currently selected item from the given list.
 *
 * @param {JSON} data a list of JSON Objects with the searchField key in their root
 *      data = {
 *          searchKey:value_to_search_by,
 *          data: [...],
 *          ...
 *      }
 * @param {string} searchField value to be listed and searched by in each JSON object
 * @param {string} intitialValue on optional initial value for the list
 * @param {useState mutator} setSelected a state mutator passed in to track the currently selected item
 *
 * @returns  a search and select input container
 */
const SearchAndSelectInput = ({
  data,
  disabled,
  searchField,
  setSelected,
  search,
  setSearch,
  classes = '',
  placeholder = '',
  error,
}: SearchAndSelectInputProps) => {
  const [view, setView] = useState(false);

  return (
    <div className={`h-min ${classes}`}>
      <input
        type="text"
        className={`rounded-md border-borders w-full h-8 text-xs font-normal focus:border-signature focus:ring-signature ${
          error ? 'border-red-500' : ''
        }`}
        placeholder={placeholder}
        value={search}
        disabled={disabled}
        onChange={(event) => {
          if (event.target.value === '') setSelected('');
          setSearch(event.target.value);
        }}
        onFocus={() => {
          setView(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setView(false);
          }, 150);
        }}
      />
      <div className={`relative transition-all ${view ? '' : ' opacity-0 h-0'}`}>
        <div className="absolute max-h-32 overflow-y-scroll w-full shadow-lg bg-white">
          {data
            ?.filter((item) => {
              return item[searchField]?.toLowerCase()?.includes(search?.toLowerCase());
            })
            .map((entry, index) => {
              return (
                <p
                  key={entry[searchField] + '-' + index}
                  className={`cursor-pointer p-2 hover:bg-gray-100 font-normal text-xs transition-all w-full h-full rounded ${
                    view && search ? ' block' : ' hidden'
                  }`}
                  onClick={() => {
                    setSelected(entry[searchField]);
                    setSearch(entry[searchField]);
                  }}
                >
                  {entry[searchField]}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default SearchAndSelectInput;
