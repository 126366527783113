import CurrentAdmin from './CurrentAdmin.component';
import InputAction from '../../../custom-prebuilt/actions/InputAction.component';
import { useUpdateAdmin } from './utils/action';
import HeaderText from '../../shared/HeaderText.component';

const ManageAdmins = () => {
  const addAdmin = useUpdateAdmin(true);

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row overflow-auto h-full">
      <aside className="pt-6 lg:pt-0 pb-4 w-full flex flex-1 h-full">
        <div className="lg:px-4 space-y-8 w-full">
          <h2 className="text-sm font-medium text-gray-900 -mb-6">Manage Admins</h2>
          <div>
            <HeaderText text="Actions" />
            <div className="mt-4 w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-5">
              <InputAction
                title="Add admin"
                prompt="Email of person to grant admin authorization"
                action={addAdmin}
              />
              {/* <InputAction
                title="Remove admin"
                prompt="Email of person to revoke admin authorization"
                action={removeAdmin} /> */}
            </div>
          </div>
          <CurrentAdmin />
        </div>
      </aside>
    </div>
  );
};

export default ManageAdmins;
