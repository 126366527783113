import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Position {
  x: number;
  y: number;
}

export type PopOverState = {
  show: boolean;
  targetType: String;
  data: any;
  position: Position;
  popOverHover: Boolean;
  targetHeight: number;
};

const initialState: PopOverState = {
  show: false,
  targetType: '',
  data: null,
  position: { x: 0, y: 0 },
  popOverHover: false,
  targetHeight: 0,
};

export const popOverSlice = createSlice({
  name: 'popover',
  initialState,
  reducers: {
    togglePopOver: (state, action: PayloadAction<Partial<PopOverState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { togglePopOver } = popOverSlice.actions;

export default popOverSlice.reducer;
