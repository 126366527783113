import { TrashIcon } from '@heroicons/react/outline';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../../redux/modalSlice';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import autoAnimate from '@formkit/auto-animate';
import Accordion from '../Accordion.component';
import LoadingState from '../preloader/LoadingState.component';
import {
  DeleteSkill,
  QuerySkillDetails,
  QueryAllSkillsBasics,
  UpdateSkillInfo,
} from '../../graphql/skills';
import SkillDetails, { SkillDetailsDynamicType } from './utils/SkillDetails.component';
import Modal from '../Modal.component';
import toast from 'react-hot-toast';
import { errorToastMid } from '../../lib/toast';
import { Amplify, Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import ImageInput from '../ImageInput';
import { RootState } from '../../redux/store';
const defaultImg: string = require('../../assets/default-user.svg').default;

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

type DynamicDataType = {
  [key: string]: any;
};
export type SnapshotSkillProps = {
  target: DynamicDataType;
  editable: boolean;
};

export const resizeFile = (
  file: Blob,
  maxWidth: number,
  maxHeight: number,
  compressFormat: string,
  quality: number,
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      maxWidth, // Is the maxWidth of the resized new image.
      maxHeight, // Is the maxHeight of the resized new image.
      compressFormat, // Is the compressFormat of the resized new image.
      quality, // Is the quality of the resized new image.
      0, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        // Is the callBack function of the resized new image URI.
        resolve(uri);
      },
      'file', // Is the output type of the resized new image. Can be either base64, blob or file.(Default type is base64)
      0, // Is the minWidth of the resized new image.
      0, // Is the minHeight of the resized new image.
    );
  });

const SnapshotSkill = ({ target, editable }: SnapshotSkillProps) => {
  const [skillImage, setSkillImage] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const querySkillDetails = useQuery(QuerySkillDetails, {
    variables: {
      where: { slug: target?.slug },
    },
    skip: !target,
    fetchPolicy: 'network-only',
    onCompleted: (data) => setSkillImage(data?.skills?.[0]?.imageLink),
  });
  const [skillDetails, setSkillDetails] = useState<SkillDetailsDynamicType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const parentRef = useRef();
  const show = useSelector((state: RootState) => state.modal.show1);
  const deleteSkillName = useSelector((state: RootState) => state.modal.skill);
  const [updateSkill] = useMutation(UpdateSkillInfo, {
    refetchQueries: [
      {
        query: QueryAllSkillsBasics,
      },
    ],
  });
  const [deleteSkill] = useMutation(DeleteSkill, {
    refetchQueries: () => [
      {
        query: QueryAllSkillsBasics,
      },
    ],
  });

  useEffect(() => {
    setIsLoading(true);
    if (target && querySkillDetails.data?.skills) {
      querySkillDetails.data.skills[0]?.peopleWithSkillConnection?.edges
        ? setSkillDetails({
            ...querySkillDetails.data.skills[0],
            peopleWithSkillConnection: {
              edges: [
                ...(querySkillDetails.data?.skills[0]?.peopleWithSkillConnection.edges || []),
              ].sort((a, b) => a.rating - b.rating),
            },
          })
        : setSkillDetails(querySkillDetails.data.skills[0]);

      setTimeout(() => {
        setIsLoading(false);
      });
    }
  }, [target, querySkillDetails.data]);

  useEffect(() => {
    parentRef.current && autoAnimate(parentRef.current);
  }, [parentRef]);

  const handleCloseModal = () => {
    dispatch(toggleModal({ show1: false, skill: null }));
  };

  const handleDeleteSkill = () => {
    deleteSkillName
      ? toast.promise(
          deleteSkill({
            variables: {
              where: {
                name: deleteSkillName,
              },
            },
          }).then(() => {
            dispatch(toggleModal({ show1: false, skill: null }));
          }),
          {
            loading: `Deleting ${deleteSkillName}`,
            success: `Deleted ${deleteSkillName}`,
            error: `Could not delete ${deleteSkillName}`,
          },
        )
      : errorToastMid('Unexpected error. Please try again');
  };

  const onUpdateImage = async (croppedImg: Blob) => {
    try {
      const resizedImage: any = await resizeFile(croppedImg, 200, 200, 'png', 100);

      const uploadPromise = Storage.put(
        `SkillLogos/${skillDetails?.slug}_${resizedImage?.name}`,
        resizedImage,
        {
          level: 'public',
          bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
        },
      ).then(() => {
        return updateSkill({
          variables: {
            where: {
              slug: skillDetails?.slug,
            },
            update: {
              imageLink: `/SkillLogos/${skillDetails?.slug}_${resizedImage?.name}`,
            },
          },
        });
      });
      toast.promise(uploadPromise, {
        success: 'Image has been updated!',
        error: 'An error occurred!',
        loading: 'Loading...',
      });
    } catch (e) {
      console.log('Error uploading file: ', e);
      errorToastMid('An error occurred when uploading the file');
    }
  };

  return (
    <>
      <Modal
        open={show}
        title={`Delete ${deleteSkillName}`}
        content={
          <div>
            <p className="text-xs text-medium">This action cannot be undone. Are you sure?</p>
          </div>
        }
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={handleCloseModal}
        destructiveButtonLabel="Delete"
        onDestructiveButtonClick={handleDeleteSkill}
      />
      <div className="flex h-full rounded-md flex-col overflow-y-scroll bg-bg_lightgray">
        {/* Main */}
        {isLoading ? (
          <LoadingState />
        ) : (
          skillDetails && (
            <div className="divide-y divide-gray-200">
              <div className="pb-4 bg-bg_light px-4">
                <div className="flow-root px-4 sm:flex sm:items-end sm:px-6 bg-bg_light pb-8 rounded-lg pt-6">
                  <ImageInput
                    image={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skillImage}`}
                    id={skillImage}
                    setImage={setSkillImage}
                    enableCropper
                    cropShowGrid
                    afterCrop={onUpdateImage}
                    cropShape={'round'}
                    cropAspect={1}
                    changeButtonText="Change Photo"
                    displayImageClasses="h-[84px] w-[84px] rounded-full bg-white border-2 p-1 border-gray-800"
                    contentWrapperClasses="flex flex-col items-center space-y-4"
                  />
                  <div className="sm:ml-6 sm:flex-1">
                    {/* ) : null} */}
                    <div>
                      <div className="flex items-center">
                        <h3 className="text-base font-bold text-gray-900 mt-3 sm:mt-0">
                          {skillDetails?.name}
                        </h3>
                      </div>
                    </div>
                    <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                      <button
                        type="button"
                        className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md 
                      border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium text-white 
                      shadow-sm hover:bg-gray-700 sm:flex-1 transition duration-150"
                        onClick={() => {
                          navigate(`/skills/${skillDetails?.slug}`);
                        }}
                      >
                        Skill Profile
                      </button>
                      <a href={skillDetails?.websiteLink} target="_blank" rel="noreferrer">
                        <button
                          className="inline-flex w-full flex-1 items-center justify-center rounded-md border
                      border-gray-300 bg-bg_light px-4 py-2 text-xs font-medium text-gray-700 shadow-sm 
                      hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150"
                        >
                          Website
                        </button>
                      </a>
                      <button
                        onClick={() => {
                          dispatch(
                            toggleModal({
                              show1: true,
                              skill: skillDetails?.name,
                            }),
                          );
                        }}
                        className="inline-flex items-center justify-center rounded-md border ml-3 sm:ml-0
                      border-red-300 bg-red-600 px-4 py-2 text-xs font-medium text-white shadow-sm 
                      hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-150"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <SkillDetails skillDetails={skillDetails} editable={editable} />
              <Accordion
                title={
                  <span>
                    People{' '}
                    <span className="ml-1 font-light text-gray-500">
                      {skillDetails?.peopleWithSkillConnection?.edges.length}
                    </span>
                  </span>
                }
                content={
                  <div className="w-full h-fit py-4">
                    {skillDetails?.peopleWithSkillConnection?.edges.length ? (
                      <div>
                        <div className="space-y-2">
                          {skillDetails?.peopleWithSkillConnection?.edges?.map((obj, index) => {
                            const { node, rating } = obj;
                            return (
                              <div
                                key={index}
                                className="w-full flex flex-col xs:flex-row xs:justify-start xs:items-center shadow px-4 py-5 rounded-md"
                              >
                                <div className="flex flex-row">
                                  <img
                                    className="rounded-full h-8 w-8 xl:h-8 xl:w-8"
                                    src={node?.userIconUrl ?? defaultImg}
                                    alt=""
                                    referrerPolicy="no-referrer"
                                  />
                                  <div className="flex flex-col flex-1 ml-3">
                                    <p className="text-xs text-gray-600 font-medium">{node.name}</p>
                                    <p className="text-xs text-gray-500">{node.email}</p>
                                  </div>
                                </div>
                                <span className="xs:ml-aut w-fit mt-4 xs:mt-0 inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                                  {rating === 1
                                    ? 'Knowledgable'
                                    : rating === 2
                                    ? 'Proficient'
                                    : rating === 3
                                    ? 'Lead/Teach'
                                    : 'null'}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500">null</p>
                    )}
                  </div>
                }
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default SnapshotSkill;
