import { SpinnerCircular } from 'spinners-react';

export type LoadingState = {
  color?: string;
  secondaryColor?: string;
};

const LoadingState = ({
  color = 'black',
  secondaryColor = 'rgba(0, 0, 0, 0.44)',
}: LoadingState) => {
  return (
    <div className="h-full w-full flex justify-center items-center z-[500] p-20">
      <SpinnerCircular
        size={35}
        thickness={100}
        speed={100}
        color={color}
        secondaryColor={secondaryColor}
      />
    </div>
  );
};

export default LoadingState;
