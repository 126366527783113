import { SortTableStateEntry } from '../redux/sortTableSlice';

type ItemType = {
  table: string;
  name: string;
  title: string;
  sortable?: boolean;
  sortingHandler?: (column: string) => void;
  customClassName?: string;
};

type SortObjectType = {
  [key: string | 'people' | 'skills' | 'projects' | 'certifications']: SortTableStateEntry;
};

export type SortIndicatorProps = {
  item: ItemType;
  sortObject: SortObjectType;
};

const SortIndicator = ({ item, sortObject }: SortIndicatorProps) => {
  return (
    <>
      {item.sortable ? (
        sortObject[item.table]?.column === item.name ? (
          sortObject[item.table]?.sortValue === 'ASC' ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
              <path d="M24 3l-12 18-12-18z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
              <path d="M0 21l12-18 12 18z" />
            </svg>
          )
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
            <path d="M12 0l8 10h-16l8-10zm8 14h-16l8 10 8-10z" />
          </svg>
        )
      ) : null}
    </>
  );
};

export default SortIndicator;
