import { useMemo } from "react";
import { formatDate } from "../../lib/formatDate";
import TableView from "../custom/TableView.component";
import useTableViewHooks from "../custom/useTableViewHooks";
import { useNavigate } from "react-router";

const SkillsTableView = (props) => {

  const { customList, paginationSize, actionName, search } = props
  const navigate = useNavigate()

  // Sorting initial states
  const initialColumns = {
    name: 0,
    usersRated: 0,
    dateAdded: 0
  }

  const [handleSortColumns] = useTableViewHooks("skills", initialColumns)

  const tableHeadItems = [
    {
      table: "skills",
      name: "name",
      title: "Name",
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName: "cursor-pointer py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6",
    },
    {
      table: "skills",
      name: "description",
      title: "Description",
      customClassName: "py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 hidden lg:table-cell",
    },
    {
      table: "skills",
      name: "tags",
      title: "Tags",
      customClassName: "py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
    },
    {
      table: "skills",
      name: "usersRated",
      title: "Numbers of Users Rated",
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName: "cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900 min-w-[12rem]"
    },
    {
      table: "skills",
      name: "dateAdded",
      title: "Date Added",
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName: "cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900 min-w-[9rem]"
    },
  ]

  const RenderBody = useMemo(() => {
    const BodyMemo = () => {
      return (
        <>
          {customList && customList.map((skill, index) => {
            const imgURL = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`

            return (
              <tr key={index} className="hover:bg-gray-200 transition duration-300 cursor-pointer"
                onClick={() => {
                    navigate(`/skills/${encodeURIComponent(skill?.slug)}`)
                }}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={imgURL} alt="" />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">{skill.name}</div>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-3 text-xs text-gray-500 sm:pl-6 hidden lg:table-cell">
                  <div className="text-gray-900">{skill.description}</div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                  {skill.category.map((tag, index) =>
                    tag.type === "Job Role"
                      ? <div key={index} className="bg-green-100 p-1 m-1 text-[10px] w-fit text-green-700 border-solid border-2 border-green-400">
                        {tag.value.toUpperCase()}
                      </div>
                      : <div key={index} className="bg-blue-100 p-1 m-1 text-[10px] w-fit text-blue-700 border-solid border-2 border-blue-400">
                        {tag.value.toUpperCase()}
                      </div>
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                  <div className="text-gray-900">{skill.peopleWithSkillAggregate.count}</div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                  <div className="text-gray-900">{formatDate(skill?.dateAdded)}</div>
                </td>
              </tr>
            )
          })}
          { }
        </>
      )
    }

    return BodyMemo
  }, [customList, navigate])

  return (
    <TableView
      data={customList}
      tableHeadItems={tableHeadItems}
      paginationSize={paginationSize}
      RenderBody={RenderBody}
      showPaginationNav={true}
      page="Skills"
      renderActionCol={actionName}
      search={search}
    />
  )
}

export default SkillsTableView