const devSkills = [
  { name: 'HTML', imageLink: '/html_200.png' },
  { name: 'Python', imageLink: '/python_200.png' },
  { name: 'Javascript', imageLink: '/javascript_200.png' },
  { name: 'C++', imageLink: '/c++_200.png' },
];

const QASkills = [
  { name: 'Selenium', imageLink: '/selenium_200.png' },
  { name: 'Load Impact', imageLink: '/load-impact_200.png' },
  { name: 'Cypress.io', imageLink: '/cypress_200.png' },
  { name: 'Locust', imageLink: '/locust_200.png' },
];

const designerSkills = [
  { name: 'Adobe Illustrator', imageLink: '/adobe-illustrator_200.png' },
  { name: 'Invision', imageLink: '/invision_200.png' },
  { name: 'Adobe XD', imageLink: '/adobe-xd_200.png' },
  { name: 'Adobe Photoshop', imageLink: '/adobe-photoshop_200.png' },
];

const PMBASkills = [
  { name: 'Google Analytics', imageLink: '/google-analytics_200.png' },
  { name: 'LucidCharts', imageLink: '/lucidchart_200.png' },
];

const AssessmentInfo = () => {
  return (
    <div className="divide-y divide-solid divide-gray-300">
      <div className="py-4">
        <p className="text-xs font-semibold mb-4">
          In the next steps, we will group together skills that are relevant to your job roles
          selected here first
        </p>
        <div className="space-y-1">
          <p className="text-xs font-normal">
            • The goal is to save you from having to sift through skills that are not applicable to
            you
          </p>
          <p className="text-xs font-normal">
            • Select as many job roles that you believe are applicable to you. (You will have the
            option to select from all skills, as well)
          </p>
        </div>
      </div>

      <div className="py-4">
        <p className="text-xs font-semibold mb-4">Developer</p>
        <div className="space-y-1">
          <p className="text-xs font-normal leading-normal">
            Researching, designing, implementing, and managing software programs. Testing and
            evaluating new programs. Identifying areas for modification in existing programs and
            subsequently developing these modifications.
          </p>
          <p className="text-xs font-normal italic !mb-4">Example skills:</p>
          <div className="flex flex-row space-x-5">
            {devSkills.map((skill) => (
              <div key={skill.name} className="flex flex-col w-fit items-center space-y-3">
                <img
                  className="h-9 w-9"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/SkillLogos${skill.imageLink}`}
                  alt=""
                />
                <p className="text-xs text-gray-600 font-medium">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-4">
        <p className="text-xs font-semibold mb-4">QA</p>
        <div className="space-y-1">
          <p className="text-xs font-normal leading-normal">
            A quality assurance personnel is responsible for ensuring that products and services
            meet the established standards set by the company. Duties include maintaining strong
            overall quality control of products made by the company adhering to reliability,
            performance and customer expectation. Inspection of products is part of the job with the
            obligation to report and document findings.
          </p>
          <p className="text-xs font-normal italic !mb-4">Example skills:</p>
          <div className="flex flex-row space-x-5">
            {QASkills.map((skill) => (
              <div key={skill.name} className="flex flex-col w-fit items-center space-y-3">
                <img
                  className="h-9 w-9"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/SkillLogos${skill.imageLink}`}
                  alt=""
                />
                <p className="text-xs text-gray-600 font-medium">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-4">
        <p className="text-xs font-semibold mb-4">Designer</p>
        <div className="space-y-1">
          <p className="text-xs font-normal leading-normal">
            Graphic designers create visual communications to convey messages in an effective and
            aesthetically pleasing manner. This incorporates several tasks and responsibilities.
            Design web pages, brochures, logos, signs, books, magazine covers, annual reports,
            advertisements, and other communication materials.
          </p>
          <p className="text-xs font-normal italic !mb-4">Example skills:</p>
          <div className="flex flex-row space-x-5">
            {designerSkills.map((skill) => (
              <div key={skill.name} className="flex flex-col w-fit items-center space-y-3">
                <img
                  className="h-9 w-9"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/SkillLogos${skill.imageLink}`}
                  alt=""
                />
                <p className="text-xs text-gray-600 font-medium">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-4">
        <p className="text-xs font-semibold mb-4">PM / BA</p>
        <div className="space-y-1">
          <p className="text-xs font-normal leading-normal">
            Project management responsibilities include the coordination and completion of projects
            on time within budget and within scope. Oversee all aspects of projects. Set deadlines,
            assign responsibilities and monitor and summarize progress of project.
          </p>
          <p className="text-xs font-normal italic !mb-4">Example skills:</p>
          <div className="flex flex-row space-x-5">
            {PMBASkills.map((skill) => (
              <div key={skill.name} className="flex flex-col w-fit items-center space-y-3">
                <img
                  className="h-9 w-9"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/SkillLogos${skill.imageLink}`}
                  alt=""
                />
                <p className="text-xs text-gray-600 font-medium">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentInfo;
