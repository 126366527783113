type DynamicObjectType = {
  [key: string]: any;
};

export default class TreeNode {
  value: string;
  logical: string | null;
  children: TreeNode[];
  queryObject: DynamicObjectType;

  constructor(value: string = '') {
    this.value = value;
    this.logical = null;
    this.children = [];
    this.queryObject = {};
  }

  /**
   * Idea: the same as printTree()
   *
   * Treat skill and skill-with-level the same way. Filter them at the end.
   */
  createQuery() {
    const node = this;
    if (node !== null) {
      if (!node.value) {
        const logical = node.logical === '|' ? 'OR' : 'AND';
        node.queryObject[logical] = [];
      } else {
        const value = node.value;
        if (!value.includes('^')) {
          return {
            skillsConnection_SOME: {
              node: {
                slug: node.value,
              },
            },
          };
        } else {
          try {
            const [skill, level] = value.split('^');
            return {
              skillsConnection_SOME: {
                node: {
                  slug: skill,
                },
                edge: {
                  rating_GTE: parseInt(level),
                },
              },
            };
          } catch (error) {
            console.log(error); // For debug
            throw new Error('Please check skill/level again.');
          }
        }
      }
      for (const child of node.children) {
        const logical = node.logical === '|' ? 'OR' : 'AND';
        node.queryObject[logical].push(child.createQuery());
      }
    }

    return node.queryObject;
  }

  printTree(level = 0) {
    const node = this;
    if (node !== null) {
      if (!node.value) {
        console.log(node.logical);
      } else {
        console.log(level, node.value);
      }
      level++;
      for (const child of node.children) {
        child.printTree(level);
      }
    }
  }
}
