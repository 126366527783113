import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import AttachSkill from './AttachSkill';
import AttachSkillCategory from './AttachSkillCategory';

type AttachNewChildProps = {
  id: string;
  closeSlideover: () => void;
};

const AttachNewChild = ({ id, closeSlideover }: AttachNewChildProps) => {
  const [type, setType] = useState('skill');
  const [collapse, setCollapse] = useState(false);
  const [parentRef] = useAutoAnimate<HTMLDivElement>({ duration: 150 });

  return (
    <div
      ref={parentRef}
      className="w-full flex flex-col items-center bg-white border border-gray-200 rounded-md shadow-sm p-2 mt-6"
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 21.565 18.869">
            <g id="diagram-subtask-duotone" transform="translate(0 -32)">
              <path
                id="Path_60"
                data-name="Path 60"
                d="M18.869,32a2.7,2.7,0,0,1,2.7,2.7v2.7a2.7,2.7,0,0,1-2.7,2.7H2.7a2.7,2.7,0,0,1-2.7-2.7V34.7A2.7,2.7,0,0,1,2.7,32Zm0,10.782a2.7,2.7,0,0,1,2.7,2.7v2.7a2.7,2.7,0,0,1-2.7,2.7H12.13a2.7,2.7,0,0,1-2.7-2.7v-2.7a2.7,2.7,0,0,1,2.7-2.7Z"
              />
              <path
                id="Path_61"
                data-name="Path 61"
                d="M96,228.043V224h2.7v4.043a1.346,1.346,0,0,0,1.348,1.348h1.348v2.7h-1.348A4.02,4.02,0,0,1,96,228.043Z"
                transform="translate(-91.957 -183.913)"
                opacity="0.4"
              />
            </g>
          </svg>
          <h3 className="text-black font-medium text-sm">Attach Child Node</h3>
        </div>
        <button
          className="rounded-full bg-bg-light p-1 shadow-md hover:bg-gray-100"
          onClick={() => setCollapse(!collapse)}
        >
          {!collapse ? (
            <ChevronUpIcon className="w-4 h-4" />
          ) : (
            <ChevronDownIcon className="w-4 h-4" />
          )}
        </button>
      </div>
      {!collapse && (
        <div className="w-full px-4 flex flex-col">
          <div className="flex flex-col w-full mt-4 space-y-2">
            <div className="flex items-center text-sm">
              <label
                htmlFor="skill_radio"
                className={`w-4 h-4 box-border rounded-full border bg-white border-gray-500 mr-2 ${
                  type === 'skill' ? 'border-[6px] border-bg-dark' : ''
                }`}
              >
                <input
                  value="skill"
                  checked={type === 'skill'}
                  onChange={(e) => setType(e.target.value)}
                  id="skill_radio"
                  type="radio"
                  name="child_type"
                  className="hidden"
                />
              </label>
              Attach Existing Skill
            </div>

            <div className="flex items-center text-sm">
              <label
                htmlFor="category_radio"
                className={`w-4 h-4 box-border rounded-full border bg-white border-gray-500 mr-2 ${
                  type === 'category' ? 'border-[6px] border-bg-dark' : ''
                }`}
              >
                <input
                  value="category"
                  checked={type === 'category'}
                  onChange={(e) => setType(e.target.value)}
                  id="category_radio"
                  type="radio"
                  name="child_type"
                  className="hidden"
                />
              </label>
              Create New Skill Category
            </div>
          </div>
          <div className="w-full mt-2">
            {type === 'skill' && <AttachSkill id={id} closeSlideover={closeSlideover} />}
            {type === 'category' && <AttachSkillCategory id={id} closeSlideover={closeSlideover} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttachNewChild;
