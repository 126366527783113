import { useDispatch, useSelector } from 'react-redux';
import EditInfo from '../../../components/admin/edit/EditInfo.component';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import Accordion from '../../Accordion.component';
import { MouseEventHandler, useMemo } from 'react';
import LoadingState from '../../preloader/LoadingState.component';
import { useCallback } from 'react';
import { formatDate } from '../../../lib/formatDate';
import { RootState } from '../../../redux/store';

type DetailRowProps = {
  label: string;
  field: string;
  editable: boolean;
  onEdit: MouseEventHandler<HTMLParagraphElement>;
};

export const DetailRow = function (props: DetailRowProps): JSX.Element {
  return (
    <div className="flex flex-row items-center px-0 md:px-5 sm:py-5 mt-3 sm:mt-0">
      <div className="flex">
        <dt className="text-xs font-medium text-gray-700 sm:w-40 sm:flex-shrink-0 lg:w-24 3xl:w-40">
          {props.label}
        </dt>
        <dd className="ml-2 text-xs text-gray-500 sm:col-span-2 sm:mt-0 sm:ml-6 max-w-sm line-clamp-6">
          {props.field}
        </dd>
      </div>
      {props.editable && (
        <p
          className="text-xs text-bg-dark ml-auto pl-4 font-semibold cursor-pointer"
          onClick={props.onEdit}
        >
          Edit
        </p>
      )}
    </div>
  );
};

const showFields = [
  { field: 'name', label: 'Name', type: 'string' },
  { field: 'biography', label: 'Biography', type: 'string' },
  { field: 'birthdate', label: 'Birthdate', type: 'date' },
];
const showContactFields = [
  { field: 'phone', label: 'Phone', type: 'string' },
  { field: 'slackUsername', label: 'Slack', type: 'string' },
  { field: 'linkedIn', label: 'LinkedIn', type: 'string' },
];
const showCompanyFields = [
  { field: 'department', label: 'Department', type: 'string' },
  { field: 'position', label: 'Position', type: 'string' },
  { field: 'startDate', label: 'Start Date', type: 'date' },
];

type DynamicDataType = {
  [key: string]: any;
};

export type UserDetailsProps = {
  userDetails: DynamicDataType;
  editable: boolean;
};

const UserDetails = ({ userDetails, editable }: UserDetailsProps) => {
  const isCurrentAdmin = useSelector((state: RootState) => state.org.isCurrentAdmin);
  const dispatch = useDispatch();
  const companyDetails = userDetails?.orgUnitsConnection?.edges[0];

  const handleOpenEditInfo = useCallback(
    (target: string, field: string, oldValue: any, id: any, type: any) => {
      const editObject = { target, field, oldValue, id, type };
      dispatch(toggleSlideOver({ show1: true, editObject }));
    },
    [dispatch],
  );

  const PersonalInfo = useMemo(() => {
    return (
      <div className="">
        {showFields.map(({ field, label, type }, index) => (
          <DetailRow
            key={index}
            label={label}
            field={
              !userDetails[field]
                ? 'N/A'
                : type === 'date'
                ? formatDate(userDetails[field], 'N/A')
                : userDetails[field].toString()
            }
            editable={isCurrentAdmin && editable}
            onEdit={() =>
              handleOpenEditInfo('person', field, userDetails[field], userDetails?.email, type)
            }
          />
        ))}
      </div>
    );
  }, [userDetails, editable, handleOpenEditInfo, isCurrentAdmin]);

  const ContactInfo = useMemo(() => {
    return (
      <div>
        {showContactFields.map(({ field, label, type }, index) => (
          <DetailRow
            key={index}
            label={label}
            field={!userDetails[field] ? 'N/A' : userDetails[field].toString()}
            editable={isCurrentAdmin && editable}
            onEdit={() =>
              handleOpenEditInfo('person', field, userDetails[field], userDetails?.email, type)
            }
          />
        ))}
      </div>
    );
  }, [userDetails, editable, handleOpenEditInfo, isCurrentAdmin]);

  const CompanyInfo = useMemo(() => {
    return (
      <div>
        {showCompanyFields.map(({ field, label, type }, index) => (
          <DetailRow
            key={index}
            label={label}
            field={
              !companyDetails[field]
                ? 'N/A'
                : type === 'date'
                ? formatDate(companyDetails[field], 'N/A')
                : companyDetails[field].toString()
            }
            editable={isCurrentAdmin && editable}
            onEdit={() => {
              handleOpenEditInfo(
                'person-company',
                field,
                companyDetails[field],
                userDetails?.email,
                type,
              );
            }}
          />
        ))}
      </div>
    );
  }, [companyDetails, editable, handleOpenEditInfo, isCurrentAdmin, userDetails?.email]);

  return (
    <div>
      <div className="rounded-lg bg-bg_light">
        <div className="">
          {userDetails ? (
            <dl className="space-y-6 sm:space-y-0 py-6 sm:py-0 overflow-hidden">
              <Accordion title="Personal Info" expandAll content={PersonalInfo} />
              <Accordion title="Contact" expandAll content={ContactInfo} />
              <Accordion
                title={`Company: ${companyDetails?.node?.name}`}
                expandAll
                content={CompanyInfo}
              />
            </dl>
          ) : (
            <LoadingState />
          )}
        </div>
      </div>

      {isCurrentAdmin && editable && <EditInfo />}
    </div>
  );
};

export default UserDetails;
