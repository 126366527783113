import { gql } from '@apollo/client';

export const QueryAllAnnouncements = gql`
  query QueryAllAnnouncements($where: AnnouncementWhere) {
    announcements(where: $where) {
      active
      announcementId
      content
      description
      expiration
      title
      draft
      belongsToConnection {
        edges {
          datePosted
          node {
            email
            name
            userIconUrl
          }
        }
      }
    }
  }
`;

export const CreateAnnouncement = gql`
  mutation CreateAnnouncement($input: [AnnouncementCreateInput!]!) {
    createAnnouncements(input: $input) {
      info {
        bookmark
      }
    }
  }
`;

export const UpdateAnnouncement = gql`
  mutation UpdateAnnouncement($where: AnnouncementWhere, $update: AnnouncementUpdateInput) {
    updateAnnouncements(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

export const DeleteAnnouncement = gql`
  mutation DeleteAnnouncement($where: AnnouncementWhere) {
    deleteAnnouncements(where: $where) {
      bookmark
    }
  }
`;
