import { useMutation, useQuery } from '@apollo/client';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Modal from '../../../../custom-prebuilt/Modal.component';
import LoadingState from '../../../../custom-prebuilt/preloader/LoadingState.component';
import { DetachEdgeMutation, GetParentNodes } from '../../../../graphql/taxonomy';

type DetachEdgeProps = {
  id: string;
  closeSlideover: () => void;
};

const DetachEdge = ({ id, closeSlideover }: DetachEdgeProps) => {
  const [open, setOpen] = useState<String | null>(null);
  const [collapse, setCollapse] = useState(false);
  const [parentRef] = useAutoAnimate<HTMLDivElement>({ duration: 150 });
  const [detachEdge] = useMutation(DetachEdgeMutation, {
    refetchQueries: ['GetOrgTaxonomy', 'GetUnattachedSkills', 'GetParentNodes'],
  });

  const { data, loading } = useQuery(GetParentNodes, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const parentNodes = data?.getParentNodes;

  const handleDetachEdge = (parentId: String | null) => {
    if (parentNodes.length < 2) return;
    const promise = detachEdge({
      variables: {
        nodeId: id,
        childID: id,
        parentID: parentId,
      },
    });

    toast.promise(promise, {
      loading: 'Detaching connection...',
      success: 'Successfully disconnected!',
      error: 'An error occurred!',
    });
    setOpen(null);
    closeSlideover();
  };

  return (
    <>
      <div
        ref={parentRef}
        className="w-full flex flex-col items-center bg-white border border-gray-200 rounded-md shadow-sm p-2 mt-6"
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-2">
            <svg
              id="merge-duotone"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              viewBox="0 0 25.147 18.869"
            >
              <path
                id="Path_62"
                data-name="Path 62"
                d="M7.105,64a3.147,3.147,0,0,1,2.457,1.18l5.346,6.682h3.961V69.5a1.178,1.178,0,0,1,.727-1.091,1.1,1.1,0,0,1,1.243.256L24.771,72.6a1.067,1.067,0,0,1,0,1.626L20.84,78.157a1.05,1.05,0,0,1-1.243.3,1.178,1.178,0,0,1-.727-1.091V75.007H14.909a3.049,3.049,0,0,1-2.457-1.224L7.105,67.145H1.572a1.572,1.572,0,1,1,0-3.145Z"
                transform="translate(0 -64)"
                fill="#880d0d"
              />
              <path
                id="Path_63"
                data-name="Path 63"
                d="M12.452,256.349a3.049,3.049,0,0,0,2.457,1.224l-5.346,6.639a3.049,3.049,0,0,1-2.457,1.224H1.572a1.572,1.572,0,0,1,0-3.145H7.105L12.137,256Z"
                transform="translate(0 -246.565)"
                opacity="0.4"
              />
            </svg>
            <h3 className="text-black font-medium text-sm">Remove Parent Connections</h3>
          </div>
          <button
            className="rounded-full bg-bg-light p-1 shadow-md hover:bg-gray-100"
            onClick={() => setCollapse(!collapse)}
          >
            {!collapse ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </button>
        </div>
        {!collapse && (
          <div className="w-full px-4 flex flex-col">
            <div className="flex mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 flex-shrink-0 fill-bg-dark"
                viewBox="0 96 960 960"
              >
                <path d="M453 776h60V536h-60v240Zm26.982-314q14.018 0 23.518-9.2T513 430q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447 430q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.316q-54 54.316-127 86Q563 976 480.266 976Z" />
              </svg>
              <div className="text-xs ml-2 font-normal text-gray-500">
                Important: You can only remove parent connections if there is more than one parent
                connection present. To remove just one parent connection, you will have to delete
                the node.
              </div>
            </div>
            {loading ? (
              <LoadingState />
            ) : (
              <div className="flex flex-col mt-4 space-y-2">
                {parentNodes?.map((p: any) => {
                  return (
                    <button
                      key={p.id}
                      onClick={() => setOpen(p.id)}
                      className="w-fit text-sm text-red-800 font-medium rounded-md shadow-sm border border-borders px-2.5 py-1 flex items-center hover:bg-gray-50 disabled:bg-gray-50 disabled:text-gray-500"
                      disabled={parentNodes.length < 2}
                    >
                      <TrashIcon className="h-4 w-4 mr-1" />
                      {p.name}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        title={
          <div className="flex text-base items-center justify-center w-full">
            Are You Sure You Want to Detach this Connection?
          </div>
        }
        open={!!open}
        onSecondaryButtonClick={() => setOpen(null)}
        content={
          <div className="flex justify-evenly w-full h-full">
            <button
              className="px-3 py-2 rounded-md shadow-sm border border-gray-300 font-medium bg-bg-dark text-xs text-white hover:bg-gray-700"
              onClick={() => handleDetachEdge(open)}
            >
              Confirm
            </button>
            <button
              className="px-3 py-2 mr-2 rounded-md shadow-sm bg-white border border-gray-300 text-xs font-medium text-font-dark hover:bg-gray-200"
              onClick={() => setOpen(null)}
            >
              Cancel
            </button>
          </div>
        }
      />
    </>
  );
};

export default DetachEdge;
