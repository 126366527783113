import {
  SpeakerphoneIcon,
} from '@heroicons/react/outline';
import { useNestedNav } from '../../../lib/customHook';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import CurrentAnnouncements from './CurrentAnnouncements.component';

const nestedNav = [
  {
    name: 'Announcements',
    icon: SpeakerphoneIcon,
  },
];

const AnnouncementsAdminView = () => {

  const [parent] = useAutoAnimate({ duration: 150 });
  const [current, nestedNavComponent] = useNestedNav(nestedNav);

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full">
      {nestedNavComponent}
      <div ref={parent} className="flex flex-grow">
        {current === 0 && <CurrentAnnouncements />}
      </div>
    </div>
  );
};

export default AnnouncementsAdminView;
