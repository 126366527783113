import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OrgState = {
  defaultOrgUid: string | null;
  defaultOrg: any;
  defaultOrgUnit: any;
  worksAt: any;
  isCurrentAdmin: boolean;
};

const initialState: OrgState = {
  defaultOrgUid: null,
  defaultOrg: null,
  defaultOrgUnit: null,
  worksAt: null,
  isCurrentAdmin: false,
};

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    toggleOrg: (state, action: PayloadAction<Partial<OrgState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleOrg } = orgSlice.actions;

export default orgSlice.reducer;
