import { XIcon, CursorClickIcon } from '@heroicons/react/outline';
import { useQuery } from '@apollo/client';
import { QueryPopularSkills, QueryAllSkills } from '../../../../../../graphql/skills';
import { useAuth } from '../../../../../../lib/authContext';
import React, { useState, useEffect, Fragment, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import ClickNHoldSkill from '../../../../../../custom-prebuilt/ClickNHoldSkill.component';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import { useNavigate, useLocation } from 'react-router';
import SlideOver from '../../../../../../custom-prebuilt/SlideOver.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSlideOver } from '../../../../../../redux/slideOverSlice';
import SkillInfo from '../../skill-assessment/utils/SkillInfo.component';
import { toggleAssessment } from '../../../../../../redux/assessmentSlice';
import LoadingState from '../../../../../../custom-prebuilt/preloader/LoadingState.component';
import SimpleSection from '../../../../../shared/SimpleSection.component';
import { CloseOutlined } from '@ant-design/icons';

const allCategoriesName = [
  { name: 'AWS', icon: '/SkillTagIcons/aws_icon.svg' },
  { name: 'Azure', icon: '/SkillTagIcons/azure_icon.svg' },
  { name: 'Back End', icon: '/SkillTagIcons/backend_icon.svg' },
  { name: 'Data', icon: '/SkillTagIcons/data_icon.svg' },
  { name: 'Design', icon: '/SkillTagIcons/design_icon.svg' },
  { name: 'DevOps', icon: '/SkillTagIcons/devops_icon.svg' },
  { name: 'Front End', icon: '/SkillTagIcons/frontend_icon.svg' },
  { name: 'Google Cloud', icon: '/SkillTagIcons/googlecloud_icon.svg' },
  { name: 'Languages', icon: '/SkillTagIcons/language_icon.svg' },
  { name: 'Mobile', icon: '/SkillTagIcons/mobile_icon.svg' },
  { name: 'Techniques', icon: '/SkillTagIcons/techniques_icon.svg' },
  { name: 'Testing', icon: '/SkillTagIcons/testing_icon.svg' },
  { name: 'Web Platforms', icon: '/SkillTagIcons/webplatforms_icon.svg' },
];

const AssessmentProjectSelectSkills = () => {
  const { userEmail, cognitoID } = useAuth();
  const project = useSelector((state) => state.assessment.project);
  const globalSelectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const globalLoading = useSelector((state) => state.assessment.loading);
  const [projectSkills, setProjectSkills] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [newlyAddedSkills, setNewlyAddedSkills] = useState([]);
  const [popularSkills, setPopularSkills] = useState([]);
  const [skillSearch, setSkillSearch] = useState('');
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [parent] = useAutoAnimate({ duration: 100 });
  const navigate = useNavigate();
  const location = useLocation();
  const show = useSelector((state) => state.slideOver.show);
  const show1 = useSelector((state) => state.slideOver.show1);
  const skill = useSelector((state) => state.slideOver.skill);
  const dispatch = useDispatch();

  const queryAllSkills = useQuery(QueryAllSkills, {
    variables: {
      where: {
        node: {
          email: userEmail || '',
        },
      },
      skillsWhere2: {
        active: true,
      },
    },
  });

  const queryPopularSkills = useQuery(QueryPopularSkills, {
    variables: {
      where: {
        peopleWithSkillAggregate: {
          count_GTE: 35,
        },
      },
      peopleWithSkillConnectionWhere2: {
        node: {
          email: userEmail || '',
        },
      },
      options: {
        limit: 20,
        sort: [
          {
            totalPeopleRated: 'DESC',
          },
        ],
      },
    },
  });

  const getLastAssessment = (assessments) => {
    return assessments?.find(
      (assessment) => assessment?.personCompleted?.[0]?.cognitoID === cognitoID,
    )?.dateCompleted;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (globalLoading) return;
    setProjectSkills(globalSelectedSkills);
  }, [globalLoading]);

  useEffect(() => {
    if (!project) return;
    if (queryAllSkills.data?.skills) {
      const temp = queryAllSkills.data?.skills;
      setAllSkills(temp);
      setNewlyAddedSkills(
        [...temp].filter(
          (a) =>
            new Date(a.dateAdded) >
            new Date(getLastAssessment(project?.assessments) ?? '01/01/2022'),
        ),
      );
      setAllCategories(
        allCategoriesName.map((c) => ({
          name: c.name,
          icon: c.icon,
          value: [...temp].filter((s) => s.category.find((sc) => sc.value === c.name)),
        })),
      );
    }
  }, [queryAllSkills, project]);

  useEffect(() => {
    queryPopularSkills.data?.skills && setPopularSkills(queryPopularSkills.data?.skills);
  }, [queryPopularSkills.data]);

  useEffect(() => {
    setFilteredSkills(
      allSkills.filter((s) => s.name.toLowerCase().includes(skillSearch.toLowerCase())),
    );
  }, [skillSearch, allSkills]);

  const handleInputChange = (e) => {
    setSkillSearch(e.target.value);
  };
  const handleClearInput = () => {
    setSkillSearch('');
  };

  const handleSelectSkill = useCallback(
    (skill) => {
      if (!skill.node) {
        skill = {
          node: {
            name: skill.name,
            imageLink: skill.imageLink,
            description: skill.description,
          },
          rating: 1,
        };
      }

      const hasProjectSkill = projectSkills.map((s) => s?.node.name).includes(skill?.node.name);

      const selectedSkills = hasProjectSkill
        ? [...projectSkills].filter((s) => s?.node.name !== skill?.node.name)
        : [...projectSkills, skill];
      dispatch(toggleAssessment({ selectedSkills }));
      setProjectSkills(selectedSkills);
    },
    [projectSkills],
  );

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false, skill: null }));
  };

  const handleCloseSlideOver1 = () => {
    dispatch(toggleSlideOver({ show1: false }));
  };

  const selectedSkillComponent = useMemo(() => {
    return (
      <div ref={parent} className="w-full h-full flex flex-row flex-wrap">
        {globalLoading ? (
          <LoadingState />
        ) : projectSkills.length ? (
          [...projectSkills].map((skill, index) => (
            <Fragment key={index}>
              {skill?.node && (
                <div
                  className="w-14"
                  data-tip={skill.node.name}
                  data-for="skill"
                  onClick={() => {
                    handleSelectSkill(skill);
                  }}
                >
                  <ClickNHoldSkill skill={skill?.node} disable />
                </div>
              )}
            </Fragment>
          ))
        ) : (
          <p className="text-xs text-gray-500">You don&apos;t have any rated skills yet</p>
        )}
      </div>
    );
  }, [projectSkills, handleSelectSkill, parent]);

  return (
    <div className="w-full h-full xl:pl-4">
      <div className="hidden xl:block">
        <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="skill" />
      </div>

      <SlideOver
        show={show}
        onClickButtonClose={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        title="Details"
        content={<SkillInfo skill={skill} />}
        bgWhite
      />

      <SlideOver
        show={show1}
        onClickButtonClose={handleCloseSlideOver1}
        onClose={handleCloseSlideOver1}
        title="Selected skills"
        content={selectedSkillComponent}
        bgWhite
      />

      <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-5 h-full">
        <div className="flex flex-col space-y-5 flex-1">
          <SimpleSection>
            <div className="flex flex-row justify-between items-center mb-4 xl:mb-0">
              <h2 className="text-xs mb-0 xl:mb-3 font-semibold">Overview</h2>
              <div
                onClick={() => {
                  dispatch(toggleSlideOver({ show1: true }));
                }}
                className="xl:hidden flex flex-row px-3 py-2 bg-gray-900 rounded-md shadow text-white cursor-pointer"
              >
                <p className="text-xs mr-2">Selected skills</p>
                <CursorClickIcon className="h-4 w-4" />
              </div>
            </div>
            <p className="text-xs mb-2">Select skills that have ANY relevance to the project.</p>
            <p className="text-xs mb-2">
              The importance of the skill to the project will be rated in the next step.
            </p>
            <p className="text-xs mb-2">Tip: You can hold-click a skill to view its details.</p>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer mr-2"
              onClick={() => {
                navigate(`${getLocationPrevLevel(location)}/introduction`);
              }}
            >
              Back
            </button>
            {projectSkills.length ? (
              <button
                className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer"
                onClick={() => {
                  navigate(`${getLocationPrevLevel(location)}/rate`);
                }}
              >
                Rate skills
              </button>
            ) : null}
          </SimpleSection>

          <SimpleSection className="flex-1 overflow-y-auto overflow-x-hidden space-y-5">
            <div className="flex gap-2 items-center">
              <input
                type="text"
                name="skill-search"
                id="skill-search"
                disabled={!allSkills.length}
                className="block rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs
                    transition duration-100 w-44 disabled:bg-gray-50"
                placeholder="Search for a skill"
                onChange={handleInputChange}
                value={skillSearch}
              />
              <CloseOutlined
                className="text-gray-500 cursor-pointer hover:text-gray-900"
                onClick={handleClearInput}
              />
            </div>
            {!skillSearch ? (
              popularSkills.length && allCategories.length ? (
                <>
                  {!!newlyAddedSkills.length && (
                    <div className="w-full">
                      <p className="text-xs font-medium mb-3">Newly added skills</p>
                      <div className="flex flex-row flex-wrap">
                        {newlyAddedSkills.map((skill, index) => (
                          <div
                            key={index}
                            className="w-14"
                            data-tip={skill.name}
                            data-for="skill"
                            onClick={() => {
                              handleSelectSkill(skill);
                            }}
                          >
                            <ClickNHoldSkill preSelected={projectSkills} skill={skill} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="w-full">
                    <p className="text-xs font-medium mb-3">Popular skills</p>
                    <div className="flex flex-row flex-wrap">
                      {popularSkills.map((skill, index) => (
                        <div
                          key={index}
                          className="w-14"
                          data-tip={skill.name}
                          data-for="skill"
                          onClick={() => {
                            handleSelectSkill(skill);
                          }}
                        >
                          <ClickNHoldSkill preSelected={projectSkills} skill={skill} />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="w-full">
                    <p className="text-xs font-medium mb-3">All categories</p>
                    <div>
                      {!selectedCategory ? (
                        <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
                          {allCategories.map((c, index) => (
                            <div
                              key={index}
                              className="col-span-1 bg-white shadow rounded-md h-fit px-4 py-5 mr-3 mb-3 cursor-pointer flex flex-row
                                  space-x-2 items-center hover:bg-gray-200 transition duration-300"
                              onClick={() => {
                                setSelectedCategory(c.name);
                              }}
                            >
                              <img
                                src={require(`/src/assets${c.icon}`)}
                                alt=""
                                className="max-w-[2rem] max-h-[2rem]"
                              />
                              <div className="flex flex-col items-start space-y-1">
                                <p className="text-xs text-medium">{c.name}</p>
                                <p className="text-xs text-gray-500">{c.value.length} skills</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-full">
                          <button className="text-xs text-gray-500 font-normal shadow rounded-md px-3 py-2 mb-3 flex flex-row items-center cursor-default">
                            {selectedCategory}
                            <XIcon
                              className="h-3 w-3 ml-1 text-gray-400 cursor-pointer"
                              onClick={() => {
                                setSelectedCategory('');
                              }}
                            />
                          </button>
                          <div className="flex flex-row flex-wrap">
                            {[...allCategories]
                              .filter((c) => c.name === selectedCategory)[0]
                              .value.map((skill, index) => (
                                <div
                                  key={index}
                                  className="w-14"
                                  data-tip={skill.name}
                                  data-for="skill"
                                  onClick={() => {
                                    handleSelectSkill(skill);
                                  }}
                                >
                                  <ClickNHoldSkill preSelected={projectSkills} skill={skill} />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <LoadingState />
              )
            ) : (
              <div className="w-full">
                <p className="text-xs font-medium mb-3">Results</p>
                <div className="flex flex-row flex-wrap">
                  {filteredSkills.map((skill, index) => (
                    <div
                      key={index}
                      className="w-14"
                      data-tip={skill.name}
                      data-for="skill"
                      onClick={() => {
                        handleSelectSkill(skill);
                      }}
                    >
                      <ClickNHoldSkill preSelected={projectSkills} skill={skill} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </SimpleSection>
        </div>

        <div className="w-[30%] max-w-md hidden xl:block">
          <SimpleSection className="h-fit">
            <p className="text-xs font-medium mb-3">Selected skills</p>
            {selectedSkillComponent}
          </SimpleSection>
        </div>
      </div>
    </div>
  );
};

export default AssessmentProjectSelectSkills;
