import React from 'react';
import { classNames } from '../../lib/classNames';
import CounterText from './CounterText.component';

export interface CardInput {
  title?: string;
  titleCounter?: string | number;
  displayCounter?: boolean;

  className?: string;
  titleClassName?: string;
  titleTextClassName?: string;
  contentClassName?: string;

  hoverEnabled?: boolean;
  onClick?: () => void;

  //Class Overrides. These completely override the default classes
  _classNameOverride?: string;
  _titleClassNameOverride?: string;
  _titleTextClassNameOverride?: string;
  _contentClassNameOverride?: string;

  children?: JSX.Element;
}

/**
 * @param title - The title that goes at the top of the card in bold.
 * @param titleCounter - A number to be displayed in light text next to the title of the card
 * @param displayCounter - A boolean to toggle whether a 0 should be displayed
 *
 * @param className - String of classes to be added to the default classes for the root div of the card
 * @param titleClassName - String of classes to be added to title container <div>
 * @param titleTextClassName - String of classes to be added to title <p>
 * @param contentClassName - String of classes to be added to the card body container div
 *
 * @param _classNameOverride - String of classes to be used to override the default classes for the root div of the card
 * @param _titleClassNameOverride - String of classes to be used to override the default classes for the title container <div>
 * @param _titleTextClassNameOverride - String of classes to be used to override the default classes for the title <p>
 * @param _contentClassNameOverride - String of classes to be used to override the default classes for the card body container div
 *
 * @param children
 * @returns {JSX.Element}
 *
 */
const Card = (props: CardInput) => {
  const titleClasses =
    props._titleClassNameOverride ?? classNames('p-5 pb-2', props.titleClassName);
  const titleTextClasses =
    props._titleTextClassNameOverride ??
    classNames('text-xs font-semibold leading-6', props.titleTextClassName);

  const containerClasses =
    props._classNameOverride ??
    classNames(
      `flex flex-col bg-white shadow rounded-lg ${
        props.hoverEnabled && 'cursor-pointer hover:bg-gray-200 transition duration-300'
      }`,
      props.className,
    );

  const contentClasses =
    props._contentClassNameOverride ?? classNames('w-full px-5 pb-5', props.contentClassName);

  return (
    <div
      className={containerClasses}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      {props.title && (
        <div className={titleClasses}>
          <p className={titleTextClasses}>
            <span className="mr-2">{props.title}</span>
            {props.displayCounter && <CounterText counter={props.titleCounter}></CounterText>}
          </p>
        </div>
      )}
      <div className={contentClasses}>{props.children}</div>
    </div>
  );
};

export default Card;
