import { useCallback, useEffect, useState } from 'react';
import { Input, Button, Toggle, Select } from '../../../custom-prebuilt/common.component';
import moment from 'moment/moment';
import { QuerySkillDetails, UpdateSkillInfo, QuerySkillTags } from '../../../graphql/skills';
import { UpdatePersonDetails, QueryPersonDetails } from '../../../graphql/people';
import { UpdateCert, QueryAllCert } from '../../../graphql/certifications';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import { UpdateProjectDetails } from '../../../graphql/projects';
import { formatDate } from '../../../lib/formatDate';
import ArrayInput from '../../../custom-prebuilt/ArrayInput';

export const formatArray = (array, placeholder = 'N/A') => {
  return !array?.length ? placeholder : array.join(', ');
};

const EditInfoContent = ({ editObject }) => {
  const [inputValue, setInputValue] = useState();
  const [dataType, setDataType] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedSkillTags, setSelectedSkillTags] = useState(editObject?.oldValue || []);
  const dispatch = useDispatch();
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  const [updateSkillInfo] = useMutation(UpdateSkillInfo);
  const [updatePersonDetails] = useMutation(UpdatePersonDetails);
  const [updateCertInfo] = useMutation(UpdateCert);
  const [updateProjectDetails] = useMutation(UpdateProjectDetails);
  const querySkillTags = useQuery(QuerySkillTags);
  const allSkillTags = querySkillTags?.data?.skillTags || [];

  const getCatergoryInput = (arr) => {
    return arr?.map((s) => {
      return {
        where: {
          node: {
            value: s.value,
          },
        },
      };
    });
  };

  const getType = useCallback(
    (value) => {
      if (moment(value, 'MM/DD/YYYY', true).isValid()) {
        setDataType('date');
      } else if (typeof value === 'number') {
        setDataType('number');
      } else if (typeof value === 'boolean') {
        setDataType('boolean');
        setInputValue(editObject?.oldValue);
      } else if (typeof value === 'string') {
        setDataType('string');
      }
    },
    [editObject?.oldValue],
  );

  useEffect(() => {
    if (editObject?.type) {
      if (Array.isArray(editObject?.type)) {
        setDataType('select');
        setSelectOptions(editObject.type);
        setInputValue(editObject.type[0].value);
      } else if (editObject?.type === 'array') {
        setInputValue(editObject?.oldValue ?? []);
        setDataType('array');
      } else {
        setDataType(editObject.type);
      }
      return;
    }
    getType(editObject?.oldValue);
  }, [editObject?.oldValue, getType]);

  const handleInputChange = (e) => {
    if (dataType === 'date') {
      setInputValue(moment(e.target.value).format('MM/DD/YYYY'));
    } else if (dataType === 'boolean') {
      setInputValue(!inputValue);
    } else if (dataType === 'number') {
      setInputValue(Number(e.target.value));
    } else if (dataType === 'array') {
      setInputValue(e);
    } else {
      setInputValue(e.target.value);
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (editObject?.target === 'person') {
      updatePerson();
    } else if (editObject?.target === 'skill') {
      updateSkill();
    } else if (editObject?.target === 'person-company') {
      updatePersonCompany();
    } else if (editObject?.target === 'cert') {
      updateCert();
    } else if (editObject?.target === 'project') {
      updateProject();
    } else if (editObject?.target === 'project-user') {
      updateProjectUser();
    }
  };

  const updatePerson = () => {
    toast.promise(
      updatePersonDetails({
        variables: {
          where: { email: editObject?.id },
          update: {
            [editObject?.field]: inputValue,
          },
        },
        refetchQueries: () => [
          {
            query: QueryPersonDetails,
            variables: {
              where: { email: editObject?.id },
              orgUnitsConnectionWhere2: {
                node: {
                  organization: {
                    uid: defaultOrgUid,
                  },
                },
              },
              categoryConnectionWhere2: { node: { type: 'Category' } },
            },
          },
        ],
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
      { position: 'top-center' },
    );
  };

  const updatePersonCompany = () => {
    toast.promise(
      updatePersonDetails({
        variables: {
          where: { email: editObject?.id },
          update: {
            orgUnits: [
              {
                where: {
                  node: {
                    organization: {
                      uid: defaultOrgUid,
                    },
                  },
                },
                update: {
                  edge: {
                    [editObject?.field]: inputValue,
                  },
                },
              },
            ],
          },
        },
        refetchQueries: () => [
          {
            query: QueryPersonDetails,
            variables: {
              where: { email: editObject?.id },
              categoryConnectionWhere2: { node: { type: 'Category' } },
              projectsConnectionWhere2: { node: { projectFullName_NOT_STARTS_WITH: 'JG' } },
            },
          },
        ],
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
      { position: 'top-center' },
    );
  };

  const updateProjectUser = () => {
    toast.promise(
      updateProjectDetails({
        refetchQueries: ['QueryAllProjects', 'QueryProjectDetails'],
        variables: {
          where: { id: editObject?.id?.projectId },
          update: {
            peopleWithExperience: [
              {
                where: {
                  node: { cognitoID: editObject?.id?.userId },
                },
                update: {
                  edge: {
                    [editObject?.field]: inputValue,
                  },
                },
              },
            ],
          },
        },
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
      { position: 'top-center' },
    );
  };

  const updateSkill = () => {
    toast.promise(
      updateSkillInfo({
        refetchQueries: () => [
          {
            query: QuerySkillDetails,
            variables: {
              where: {
                slug: editObject?.id,
              },
            },
          },
        ],
        variables: {
          where: {
            slug: editObject?.id,
          },
          [editObject?.field !== 'category' && 'update']: {
            [editObject?.field]: inputValue,
          },
          [editObject?.field === 'category' && 'disconnect']: {
            category: getCatergoryInput(
              editObject?.field === 'category'
                ? editObject?.oldValue?.filter(
                    (st) => !selectedSkillTags.map((e) => e.value).includes(st.value),
                  )
                : [],
            ),
          },
          [editObject?.field === 'category' && 'connect']: {
            category: getCatergoryInput(editObject?.field === 'category' ? selectedSkillTags : []),
          },
        },
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
    );
  };

  const updateCert = () => {
    toast.promise(
      updateCertInfo({
        refetchQueries: () => [
          {
            query: QueryAllCert,
          },
        ],
        variables: {
          where: {
            name: editObject?.id,
          },
          update: {
            [editObject?.field]: inputValue,
          },
        },
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
    );
  };

  const updateProject = () => {
    toast.promise(
      updateProjectDetails({
        refetchQueries: ['QueryAllProjects', 'QueryProjectDetails'],
        variables: {
          where: {
            id: editObject?.id,
          },
          update: {
            [editObject?.field]: inputValue,
          },
        },
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
    );
  };

  const handleSelectSkillTag = (st) => {
    selectedSkillTags.map((e) => e.value).includes(st.value)
      ? setSelectedSkillTags((selectedSkillTags) =>
          selectedSkillTags.filter((s) => s.value !== st.value),
        )
      : setSelectedSkillTags((selectedSkillTags) => [...selectedSkillTags, st]);
  };

  return (
    <div className="w-full h-fit flex flex-col space-y-2">
      <div className="flex flex-col bg-white shadow rounded-md p-4 space-y-2">
        <p className="text-xs">
          Current value for <span className="font-semibold">{editObject?.field}</span>:{' '}
        </p>
        {(editObject?.field !== 'category' || editObject?.target !== 'skill') && (
          <p className="text-xs text-gray-500">
            {dataType === 'date'
              ? formatDate(editObject?.oldValue, 'N/A')
              : dataType === 'array'
              ? formatArray(editObject?.oldValue)
              : editObject?.oldValue?.toString() || 'N/A'}
          </p>
        )}
        {editObject?.field === 'category' && editObject?.target === 'skill' && (
          <div className="flex flex-row flex-wrap">
            {editObject?.oldValue?.map((e, index) => (
              <p key={index} className="text-xs bg-gray-200 py-1 px-2 mr-1 mb-1">
                {e.value}
              </p>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col bg-white shadow rounded-md p-4 space-y-2">
        <p className="text-xs">
          Change <span className="font-semibold">{editObject?.field}</span> to:{' '}
        </p>
        <form
          className="flex flex-col"
          onSubmit={(e) => {
            handleSubmitForm(e);
          }}
        >
          {(dataType === 'string' ||
            (!dataType && editObject?.field !== 'category' && editObject?.target !== 'skill')) && (
            <Input
              type="text"
              className="border px-2 mb-3"
              placeholder="New value"
              required
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          )}
          {dataType === 'number' && (
            <Input
              type="number"
              className="border px-2 mb-3"
              placeholder="New value"
              required
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          )}
          {dataType === 'date' && (
            <Input
              type="date"
              className="border px-2 mb-3"
              placeholder="New value"
              required
              onChange={handleInputChange}
            />
          )}
          {dataType === 'select' && (
            <Select onChange={handleInputChange}>
              {selectOptions.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Select>
          )}
          {dataType === 'boolean' && (
            <div className="flex flex-row items-center mb-3 mt-2">
              <Toggle onChange={handleInputChange} checked={inputValue} boolean={inputValue} />
              <label className="text-xs ml-2">{inputValue ? 'true' : 'false'}</label>
            </div>
          )}
          {dataType === 'array' && (
            <ArrayInput className="mb-3" array={inputValue} updateArray={handleInputChange} />
          )}
          {editObject?.field === 'category' && editObject?.target === 'skill' && (
            <div className="flex flex-col mb-3 mt-2">
              {allSkillTags.map((st, index) => (
                <p
                  key={index}
                  className={`text-xs py-1 px-2 mb-1 w-fit cursor-pointer transition duration-300
                      ${
                        selectedSkillTags.map((e) => e.value).includes(st.value)
                          ? 'bg-gray-900 text-white'
                          : 'bg-gray-200'
                      }`}
                  onClick={() => {
                    handleSelectSkillTag(st);
                  }}
                >
                  {st.value}
                </p>
              ))}
            </div>
          )}
          <Button formAction="submit">Save</Button>
        </form>
      </div>
    </div>
  );
};

export default EditInfoContent;
