import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { classNames } from '../lib/classNames';
import { useEffect } from 'react';

const InternalSelectedComponent = ({ closeComboBox, SelectedComponent, selected }) => {
  useEffect(() => closeComboBox(), []);
  return <SelectedComponent option={selected} />;
};

/**
 * @param {*[]} options - a list of options to display. This can be any type of list
 * @param {String} title - the title of the search and select entry
 * @param {Boolean} showByDefault - if all the options should be displayed when no item is selected
 * @param {JSX.Element} DisplayComponent - a react component that where options are mapped onto. Each option is displayed in a single display component
 * @callback searchParam - the attribute name to search. Currently only attributes at the top level are supported
 * @param {*} selected - the selected option
 * @callback setSelected - updates the selected option
 * @param {JSX.Element} SelectedComponent - a react component that will be displayed when the user selects a option
 * @param {String} className - tailwind classes that are applied to a div that wraps the search and select menu
 * @param {Boolean} isSearching - how the parent can determine if the component is searching/open
 * @param setIsSearching - allows us to set whether the state of the combo box is searching/open
 *
 * !NOTES!
 * Currently the search and select menu supports initalizing to a unselected entry, but once something is selected the user
 * cannot deselect and have no item selected
 * This has not been tested for lists of strings, please reach out to John Higgins if you have any issues
 */
const SearchAndSelectMenu = ({
  options,
  title,
  showByDefault,
  DisplayComponent,
  searchParam,
  selected,
  setSelected,
  SelectedComponent,
  className,
  isSearching,
  setIsSearching,
}) => {
  // determines if there is something selected
  const hasSelected = typeof selected !== 'undefined';
  // determines if there is a selection component that we can display
  const hasSelectedComponent = typeof SelectedComponent !== 'undefined';
  // determines if there is a search param
  const hasSearchParam = typeof searchParam !== 'undefined' && searchParam !== null;
  // determines what the value of the current selected input is
  const getCurrentValue = () => {
    // if we do not have a option selected then it is the searchString
    if (!hasSelected) return searchString;
    // other wise it is the current selected options value
    if (hasSearchParam) return selected[searchParam];
    return selected;
  };
  // the search value
  const [searchString, setSearchString] = useState(getCurrentValue());

  // opens the hero combo box
  const openComboBox = () => {
    setIsSearching(true);
  };

  // closes the hero combo box
  const closeComboBox = () => {
    setIsSearching(false);
  };

  // gets the value to display within the input
  const getDisplayValue = () => {
    // get the current value to display
    let currentValue = getCurrentValue();
    // if we are searching we should always show the search string
    if (isSearching) return searchString;
    return currentValue;
  };

  // all filtered options based on the value of the search string
  const filteredOptions =
    searchString === ''
      ? options
      : options.filter((option) => {
          if (hasSearchParam)
            return option[searchParam].toLowerCase().includes(searchString.toLowerCase());
          return searchParam.toLowerCase().includes(option.toLowerCase());
        });

  // when the search box is updated
  const onInputChange = (event) => {
    if (!isSearching) openComboBox();
    // handles weirdness with untrusted events
    if (event.isTrusted) {
      setSearchString(event.target.value);
    }
  };

  // when an option from the combo box is selected
  const chooseOption = (option) => {
    setSelected(option);
    closeComboBox();
  };

  const defaultOptionDisplay = options.map((option, index) => (
    <div key={`search-and-select-menu-${index}`} className="pr-2">
      <DisplayComponent option={option} onSelect={() => chooseOption(option)} />
    </div>
  ));

  return (
    <div className={classNames('overflow-hidden', className)}>
      <Combobox value={selected}>
        {({ open }) => (
          <div className="flex flex-col h-full">
            <Combobox.Label className="block text-xs font-medium py-1 text-gray-900">
              {title}
            </Combobox.Label>
            <Combobox.Input
              className="rounded-md border-borders text-xs font-normal focus:border-signature focus:ring-signature h-min w-min mb-2"
              displayValue={getDisplayValue}
              onChange={onInputChange}
            />
            {/* work around for controlling whether the combo box is open  */}
            {isSearching && open ? (
              <div className="basis-full overflow-y-scroll flex flex-wrap content-baseline">
                {/* Whether to show the default options */}
                {showByDefault && !isSearching && !hasSelected ? defaultOptionDisplay : null}
                {/* result of the search */}
                <Combobox.Options className="basis-full overflow-y-scroll flex flex-wrap content-baseline h-full">
                  {filteredOptions.map((option, index) => (
                    <Combobox.Option className="pr-2" key={index}>
                      {({ active, selected }) => (
                        <DisplayComponent
                          active={active}
                          selected={selected}
                          option={option}
                          onSelect={() => chooseOption(option)}
                        />
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            ) : hasSelectedComponent && hasSelected ? (
              // Had to create this component to get around not being able to close the combo box's state
              // manually
              <div className="flex flex-wrap ">
                <InternalSelectedComponent
                  selected={selected}
                  SelectedComponent={SelectedComponent}
                  closeComboBox={closeComboBox}
                />
              </div>
            ) : null}
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default SearchAndSelectMenu;
