import { useEffect, useState, useMemo } from 'react';
import ProjectsTableView from '../components/projects/ProjectsTableView.component';
import { useQuery } from '@apollo/client';
import { QueryProjectsTablePagination } from '../graphql/projects';
import { useSelector } from 'react-redux';
import PageContainer from '../custom-prebuilt/PageContainer';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { projects_split } from '../split.io/sdk.config';
import RestrictedAuth from '../custom-prebuilt/RestrictedAuth.component';
import SearchLogic from '../custom-prebuilt/SearchLogic.component';

const Projects = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  // Get query string from URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [searchInput, setSearchInput] = useState('');

  // Sort Selector for People
  const sortProjectsSelector = useSelector((state) => state.sortTable.projects);
  let sortOptions = [];
  if (sortProjectsSelector.column !== '') {
    sortOptions.push({
      [sortProjectsSelector.column]: sortProjectsSelector.sortValue,
    });
  }

  // Limit items in 1 page
  const paginationSize = 15;
  const [statesData, setStatesData] = useState([]);
  const { data, loading } = useQuery(QueryProjectsTablePagination, {
    variables: {
      options: {
        sort: sortOptions,
      },
      where: {
        AND: [
          {
            active: true,
            OR: [
              {
                name_NOT_STARTS_WITH: 'JG',
              },
              {
                name_IN: ['JG - Connect Development', 'JG - Tense'],
              },
            ],
            organizationAggregate: {
              count_GT: 0,
            },
          },
        ],
      },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (data && data?.projects) {
      const page = params.page ? params.page : 1;
      const tmp = [...data.projects];
      setStatesData(
        tmp
          .filter((project) => project.name.toLowerCase().includes(searchInput.toLowerCase()))
          // .sort((a, b) => a.projectFullName < b.projectFullName ? -1 : 1)
          .slice((page - 1) * paginationSize, page * paginationSize),
      );
    }
  }, [data, searchInput, params.page]);

  const ProjectsTableComponent = useMemo(() => {
    return (
      <ProjectsTableView
        customList={statesData}
        paginationSize={paginationSize}
        actionName="View project"
        search={{
          isSearching: searchInput ? true : false,
          searchFor: 'projects',
          filteredData: data
            ? [...data.projects].filter((project) =>
                project.name.toLowerCase().includes(searchInput.toLowerCase()),
              )
            : [],
        }}
      />
    );
  }, [data, searchInput, statesData]);

  return (
    <SplitTreatments names={[projects_split]}>
      {({ treatments, isReady }) => {
        return isReady && treatments[projects_split].treatment === 'on' ? (
          <PageContainer title="Projects Directory">
            <div className="mt-1 mb-4">
              <input
                type="text"
                placeholder="Find project"
                className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs py-2"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>

            <SearchLogic
              dataCheck={statesData.length > 0}
              loading={loading}
              searchInput={searchInput}
              dataComponent={ProjectsTableComponent}
            />
          </PageContainer>
        ) : (
          <RestrictedAuth />
        );
      }}
    </SplitTreatments>
  );
};

export default Projects;
