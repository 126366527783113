import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  Button,
  H2,
  Input,
  Label,
  Legend,
} from "../../custom-prebuilt/common.component";
import LoadingState from "../../custom-prebuilt/preloader/LoadingState.component";
import { QueryPersonDetails, UpdatePersonDetails } from "../../graphql/people";
import { useAuth } from "../../lib/authContext";

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  //remove non-digits
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7)
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

/**
 * Settings section for editing a user's contact information
 * @returns form for editing a user's contact information
 */
const SettingsContact = () => {
  const { userEmail } = useAuth()

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [slack, setSlack] = useState("");
  const [website, setWebsite] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const defaultOrgUid = useSelector(state => state.org.defaultOrgUid)

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QueryPersonDetails, {
    variables: { 
      where: { email: userEmail },
      "orgUnitsConnectionWhere2": {
        "node": {
          "organization": {
            "uid": defaultOrgUid
          }
        }
      }
    },
    skip: !userEmail,
  });

  const [
    updateSettingsContact,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UpdatePersonDetails, {
    refetchQueries: ["QueryPersonDetails"],
  });

  if (error || updateError) {
    console.error(error, updateError);
  }

  const loading = queryLoading || updateLoading;

  const updateFields = (userValue) => {
    if (userValue.email !== null) setEmail(userValue.email);
    if (userValue.phone !== null) setPhone(userValue.phone);
    if (userValue.slackUsername !== null) setSlack(userValue.slackUsername);
    if (userValue.linkedIn !== null) setLinkedIn(userValue.linkedIn);
    if (userValue.personalWebsite !== null)
      setWebsite(userValue.personalWebsite);
  };

  useEffect(() => {
    const user = data?.people?.[0];
    if (user) {
      updateFields(user);
    }
  }, [data]);

  const onSave = () => {
    //validation?

    //add variable to update email
    const response = updateSettingsContact({
      variables: {
        where: {
          email: userEmail,
        },
        update: {
          phone: phone,
          slackUsername: slack,
          linkedIn: linkedIn,
          personalWebsite: website,
        },
      },
    });

    toast.promise(response, {
      success: "Contact information saved!",
      error: "An error occurred!",
    });
  };

  return (
    <>
      {!data ? (
        <LoadingState />
      ) : (
        <div className="flex flex-col p-4 w-full md:w-1/2 xl:w-1/3 h-full overflow-y-auto">
          <H2 className="mb-2">Contact Information</H2>
          <Legend>
            You can control what you want to share in your preferences settings.
          </Legend>
          <Label className="mt-2">
            Email
            <Input
              className="disabled:bg-bg-light"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={true}
            />
          </Label>
          <Label>
            Phone Number
            <Input
              type="tel"
              className="placeholder-font-light placeholder:font-normal"
              placeholder="(xxx) xxx-xxxx"
              disabled={loading}
              value={phone}
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                setPhone(formattedPhoneNumber);
              }}
            />
          </Label>
          <Label>
            Slack Username
            <Input
              type="text"
              disabled={loading}
              value={slack}
              onChange={(e) => setSlack(e.target.value)}
            />
          </Label>
          <Label>
            LinkedIn Profile URL
            <Input
              type="text"
              disabled={loading}
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
            />
          </Label>
          <Label>
            Personal Website URL
            <Input
              type="text"
              disabled={loading}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Label>
          <Button
            className="self-end mt-14"
            disabled={loading}
            onClick={(e) => {
              onSave();
              e.target.blur();
            }}
          >
            Save Changes
          </Button>
        </div>
      )}
    </>
  );
};

export default SettingsContact;
