import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from '../lib/classNames';
import { toggleBugReport } from '../redux/bugReportSlice';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { api_reportBug } from '../components/admin/people/utils/fetchResp';
import { errorToastMid } from '../lib/toast';
import { toast } from 'react-hot-toast';
import { useAuth } from '../lib/authContext';
import { XCircleIcon } from '@heroicons/react/outline';
import { RootState } from '../redux/store';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

/**
 * Type Definition
 */
type RadioGroupOptionType = {
  checked: boolean;
};

/**
 * BugReport Component
 */
const BugReport = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.bugReport.show);
  const { userEmail } = useAuth();
  const [type, setType] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [images, setImages] = useState<any[]>([]);
  const [error, setError] = useState({
    type: false,
    name: false,
    description: false,
    image: false,
  });

  useEffect(() => {
    if (type) {
      setError((prevState) => ({ ...prevState, type: false }));
    }
  }, [type]);

  const onSelectImages = (event: ChangeEvent<HTMLInputElement>) => {
    if (images.length >= 3) {
      setError({ ...error, image: true });
      return;
    }
    if (event.target?.files && event.target.files.length > 0) {
      if (!['image/png', 'image/jpeg'].includes(event.target.files[0].type)) {
        errorToastMid('You can only upload .png or .jpeg files');
        return;
      }
      setImages([...images, event.target.files[0]]);
    }
  };

  const removeImage = (index: number) => {
    const prevState: any[] = [...images];
    prevState.splice(index, 1);
    setImages(prevState);
    setError({ ...error, image: false });
  };

  const validateForm = () => {
    let validated = true;
    if (!type) {
      setError((prevState) => ({ ...prevState, type: true }));
      validated = false;
    } else {
      if (!name) {
        setError((prevState) => ({ ...prevState, name: true }));
        validated = false;
      }
      if (!description) {
        setError((prevState) => ({ ...prevState, description: true }));
        validated = false;
      }
    }
    return validated;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      try {
        Auth.currentAuthenticatedUser().then((cogUser: CognitoUser) => {
          cogUser.getSession((err: Error | null, session: null | CognitoUserSession) => {
            if (err) {
              throw new Error(err.message);
            }
            // const sessionToken = session?.getAccessToken().jwtToken;
            const sessionToken = session?.getAccessToken().getJwtToken();
            const form = new FormData();

            const ticketName = `${_.capitalize(type)}: ${name}`;
            const ticketBody = `${description
              .replace(/\n/g, '\\n')
              .replace(/\r/g, '\\r')
              .replace(/\t/g, '\\t')}\\n\\n(Submitted by ${userEmail} from ${
              window.location.hostname
            })`;
            form.append('name', ticketName);
            form.append('description', ticketBody);
            images.forEach((image) => form.append('file', image));
            const res = api_reportBug(form, sessionToken);
            toast.promise(res, {
              success: 'Ticket successfully created!',
              error: 'An error occurred!',
              loading: null,
            });
          });
        });
      } catch (e) {
        console.error(e);
        errorToastMid(e.message);
      }

      dispatch(toggleBugReport({ show: false }));
    }
  };

  return (
    <Transition.Root
      show={show}
      as={Fragment}
      afterLeave={() => {
        setType('');
        setName('');
        setDescription('');
        setImages([]);
        setError({
          type: false,
          name: false,
          description: false,
          image: false,
        });
      }}
      appear
    >
      <Dialog
        as="div"
        className="relative z-[55]"
        onClose={() => dispatch(toggleBugReport({ show: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="mx-auto max-w-xl flex flex-col transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 
              transition-all py-5 px-4"
            >
              <h2 className="w-full text-sm font-medium">Report a bug or suggest a feature!</h2>
              <div className="flex flex-col mt-6">
                <RadioGroup
                  aria-required
                  value={type}
                  onChange={setType}
                  id="typeSelect"
                  className="flex items-center"
                >
                  <RadioGroup.Label className="text-black text-xs font-normal">
                    <span className="text-xs text-red-600 mr-1">*</span>What are you reporting?
                  </RadioGroup.Label>
                  <div className="flex flex-row ml-4">
                    <RadioGroup.Option
                      value={'bug'}
                      className={({ checked }: RadioGroupOptionType) =>
                        classNames(
                          `py-1 px-3 border-y border-l rounded-sm-l border-gray-300 shadow-sm bg-white cursor-pointer hover:bg-gray-200 text-xs
                          transition duration-300`,
                          checked ? 'bg-gray-200' : '',
                        )
                      }
                    >
                      Report Bug
                    </RadioGroup.Option>
                    <RadioGroup.Option
                      value={'feature'}
                      className={({ checked }) =>
                        classNames(
                          `py-1 px-3 border rounded-sm-r border-gray-300 shadow-sm bg-white cursor-pointer hover:bg-gray-200 text-xs
                          transition duration-300`,
                          checked ? 'bg-gray-200' : '',
                        )
                      }
                    >
                      Leave Suggestion
                    </RadioGroup.Option>
                  </div>
                </RadioGroup>
                <span
                  className={`text-red-600 text-xs font-normal self-end mr-24 ${
                    error.type ? 'visible' : 'invisible'
                  }`}
                >
                  Please select an option
                </span>

                <div className="flex flex-col mt-2">
                  {!type ? (
                    <span className="text-xs text-black text-center self-center">
                      Select Bug or Suggestion to continue
                    </span>
                  ) : (
                    <>
                      <label className="flex w-full justify-end text-xs">
                        <span className="text-xs text-red-600 mr-1">*</span>
                        Bug Title:
                        <div className="flex flex-col w-2/3 ml-4 items-center">
                          <input
                            required={true}
                            className="w-full text-xs py-1 px-2 border-borders text-font-dark rounded-md hover:border-signature focus:border-signature focus:ring-signature"
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setError((prevState) => ({
                                ...prevState,
                                name: false,
                              }));
                              setName(e.target.value);
                            }}
                          />
                          <span className={`text-red-600 ${error.name ? 'visible' : 'invisible'}`}>
                            Bug Title is required!
                          </span>
                        </div>
                      </label>

                      <label className="flex w-full justify-end text-xs items-start mt-2">
                        <span className="text-xs text-red-600 mr-1">*</span>
                        Description:
                        <div className="flex flex-col w-2/3 ml-4 items-center">
                          <textarea
                            required={true}
                            className="w-full text-xs py-2 px-2 border-borders text-font-dark rounded-md resize-none hover:border-signature focus:border-signature focus:ring-signature"
                            rows={8}
                            placeholder={
                              type === 'bug'
                                ? 'Please describe the bug in as much detail as possible.'
                                : 'Please describe the feature and detail the functionality you would like to see!'
                            }
                            value={description}
                            onChange={(e) => {
                              setError((prevState) => ({
                                ...prevState,
                                description: false,
                              }));
                              setDescription(e.target.value);
                            }}
                          />
                          <span
                            className={`text-red-600 ${
                              error.description ? 'visible' : 'invisible'
                            }`}
                          >
                            Description is required!
                          </span>
                        </div>
                      </label>

                      <div className="ml-[33%] w-full flex mt-2">
                        <div className="flex flex-col items-center self-center w-1/4">
                          <input
                            hidden
                            type="file"
                            id="upload-bug-image"
                            accept=".jpeg,.png,.jpg"
                            onChange={(event) => {
                              onSelectImages(event);
                            }}
                          />
                          <label
                            htmlFor="upload-bug-image"
                            className="cursor-pointer text-center w-full rounded-md border hover:bg-gray-200 border-gray-300 bg-white px-3.5 py-2 text-font-dark text-xs font-medium shadow-sm"
                          >
                            Attach Image
                          </label>
                          <span
                            className={`text-red-600 text-xs ${
                              error.image ? 'visible' : 'invisible'
                            }`}
                          >
                            Limit of 3 images!
                          </span>
                        </div>
                        <div className="flex flex-col ml-4">
                          {images.map((image, index) => (
                            <div className="flex items-center" key={index}>
                              <span className="text-xs text-font-dark mr-1">{image.name}</span>
                              <XCircleIcon onClick={() => removeImage(index)} className="h-5 w-5" />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="flex flex-row justify-end py-3 px-4">
                <button
                  className="px-3 py-2 mr-2 rounded-md shadow-sm bg-white border border-gray-300 text-xs font-medium text-font-dark 
                    hover:bg-gray-200 transition duration-300"
                  onClick={() => dispatch(toggleBugReport({ show: false }))}
                >
                  Cancel
                </button>
                <button
                  className="px-3 py-2 rounded-md shadow-sm border border-gray-300 font-medium bg-bg-dark text-xs text-white 
                    hover:bg-gray-700 transition duration-300"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BugReport;
