import {
  ColorSwatchIcon,
  CodeIcon,
  ChartSquareBarIcon,
  BeakerIcon,
} from '@heroicons/react/outline';
import { classNames } from '../../../../../../lib/classNames';
import { useSelector, useDispatch } from 'react-redux';
import SlideOver from '../../../../../../custom-prebuilt/SlideOver.component';
import { toggleSlideOver } from '../../../../../../redux/slideOverSlice';
import AssessmentInfo from './AssessmentInfo.component';
import { useNavigate, useLocation } from 'react-router';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import { toggleAssessment } from '../../../../../../redux/assessmentSlice';
import HelpInformation from '../../../../../../custom-prebuilt/HelpInformation.component';

const roles = [
  {
    title: 'Designer',
    description:
      'Graphic designers create visual communications to convey messages in an effective and aesthetically pleasing manner. This incorporates several tasks and responsibilities. Design web pages, brochures, logos, signs, books, magazine covers, annual reports, advertisements, and other communication materials.',
    iconForeground: 'text-red-700',
    iconBackground: 'bg-red-50',
    icon: ColorSwatchIcon,
  },
  {
    title: 'Developer',
    description:
      'Researching, designing, implementing, and managing software programs. Testing and evaluating new programs. Identifying areas for modification in existing programs and subsequently developing these modifications.',
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    icon: CodeIcon,
  },
  {
    title: 'PM / BA',
    description:
      'Project management responsibilities include the coordination and completion of projects on time within budget and within scope. Oversee all aspects of projects. Set deadlines, assign responsibilities and monitor and summarize progress of project.',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    icon: ChartSquareBarIcon,
  },
  {
    title: 'QA',
    description:
      'A quality assurance personnel is responsible for ensuring that products and services meet the established standards set by the company. Duties include maintaining strong overall quality control of products made by the company adhering to reliability, performance and customer expectation. Inspection of products is part of the job with the obligation to report and document findings.',
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    icon: BeakerIcon,
  },
];

const AssessmentIntro = () => {
  const org = useSelector((state) => state.org.defaultOrg);
  const selectedRole = useSelector((state) => state.assessment.selectedRole);
  const show = useSelector((state) => state.slideOver.show);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectRole = (role) => {
    selectedRole.includes(role.title)
      ? dispatch(
          toggleAssessment({ selectedRole: [...selectedRole].filter((r) => r !== role.title) }),
        )
      : dispatch(toggleAssessment({ selectedRole: [...selectedRole, role.title] }));
  };

  const checkIfSelectedRole = (role) => {
    return selectedRole.includes(role.title);
  };

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false }));
  };

  return (
    <div className="space-y-5 xl:pl-4 w-full">
      <HelpInformation
        title={`Welcome to the ${org.name} skill assessment`}
        additionalHelpOnClick={() => {
          dispatch(toggleSlideOver({ show: true }));
        }}
      >
        <p className="text-xs mb-3">In this assessment, you will:</p>
        <ul className="flex flex-col space-y-2 text-xs mb-3">
          <li>• Select all of the skills that you have ANY knowledge in</li>
          <li>• Rate each skill according to the options given</li>
          <li>• Have the opportunity to adjust previously selected skills and ratings</li>
        </ul>
        <p className="text-xs mb-1 font-semi">
          Get started by selecting all of the job roles that fit your skill profile below!
        </p>
        {selectedRole.length ? (
          <button
            className="px-3 py-2 mt-4 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700
        transition duration-300 cursor-pointer"
            onClick={() => {
              navigate(`${getLocationPrevLevel(location)}/select`);
            }}
          >
            Select skills
          </button>
        ) : null}
      </HelpInformation>
      <section aria-labelledby="quick-links-title">
        <div
          className="divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:gap-px sm:divide-y-0
          sm:grid sm:grid-cols-2 3xl:grid-cols-4"
        >
          <h2 className="sr-only" id="quick-links-title">
            Quick links
          </h2>
          {roles.map((role, index) => (
            <div
              onClick={() => {
                handleSelectRole(role);
              }}
              key={index}
              className={classNames(
                index === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                index === 1 ? 'sm:rounded-tr-lg 3xl:rounded-tr-none' : '',
                index === roles.length - 2 ? 'sm:rounded-bl-lg lg:rounded-bl-none' : '',
                index === roles.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                `relative group bg-white p-6 cursor-pointer transition duration-300
                ${
                  checkIfSelectedRole(role)
                    ? '!bg-gray-800 !hover:bg-gray-600 text-white'
                    : 'hover:bg-slate-100'
                }`,
              )}
            >
              <div>
                <span
                  className={classNames(
                    role.iconBackground,
                    role.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white',
                  )}
                >
                  <role.icon className="h-4 w-4" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-4">
                <p className="text-xs font-medium">
                  <span>
                    {/* Extend touch target to entire panel */}
                    {/* <span className="absolute inset-0 " aria-hidden="true" /> */}
                    {role.title}
                  </span>
                </p>
                <p className="mt-2 text-xs text-gray-400 leading-normal">{role.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <SlideOver
        show={show}
        onClickButtonClose={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        title="What is this?"
        content={<AssessmentInfo />}
        bgWhite
      />
    </div>
  );
};

export default AssessmentIntro;
