/* eslint-disable */
import { Input, Button, Label } from "../../../custom-prebuilt/common.component";
import { useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { QueryPersonBasics } from "../../../graphql/people";
import AllSkillMenu from "./AllSkillMenu.component";
import useCaretPosition from 'use-caret-position'
import { useSelector, useDispatch } from "react-redux";
import { toggleExplore } from "../../../redux/exploreSlice";
import { toast } from "react-hot-toast";
import TreeNode from "./helper/TreeNode";
import { createTree } from "./helper/utils";
import KBD from "../../shared/KBD.component";

// const DEFAULT_PROMPT = "Find me all people who knows "
const DEFAULT_PROMPT = " "

const SkillExplorePrompt = () => {

  const dispatch = useDispatch()
  const inputRef = useRef()
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT)
  const [showMenu, setShowMenu] = useState(false)
  const [inputSkill, setInputSkill] = useState("")
  const selectedSkill = useSelector(state => state.explore.selectedSkill)
  const queryHistory = useSelector(state => state.explore.queryHistory)
  const refetchQuery = useSelector(state => state.explore.refetchQuery)
  const preserveCurrentTags = useSelector(state => state.explore.preserveCurrentTags)
  const canvasItems = useSelector(state => state.explore.canvasItems)
  const canvasColors = useSelector(state => state.explore.canvasColors)

  useEffect(() => {
    if (selectedSkill) {
      const promptArr = prompt.split(" ")
      setPrompt(promptArr.slice(0, -1).join(" ") + " " + promptArr.at(-1).split(">").at(0) + ">" + selectedSkill)
      setShowMenu(false)
      dispatch(toggleExplore({ selectedSkill: "" }))
    }
  }, [selectedSkill])

  const [queryExplore] = useLazyQuery(QueryPersonBasics)

  const query = prompt.toLowerCase().replace(/[^a-zA-Z ]/g, "");

  const { x, y, getPosition, getSelection } = useCaretPosition(inputRef)

  // Whenever user starts to select a skill's proficiency, disable showMenu
  useEffect(() => {
    if (inputSkill.includes("^")) {
      setShowMenu(false)
    }
  }, [inputSkill])

  const andShortcut = '&';
  const isAndShortcut = prompt.startsWith(andShortcut);
  const orShortcut = '|';
  const isOrShortcut = prompt.startsWith(orShortcut);
  const skillsShortcut = '>';
  const isSkillsQuery = prompt.startsWith(skillsShortcut);
  const usersShortcut = '@';
  const isUsersQuery = prompt.startsWith(usersShortcut);
  const levelShortcut = '^'
  const isLevelShortcut = prompt.startsWith(levelShortcut);

  const handleInputChange = (e) => {
    let input = e.target.value
    if (/\S[&|]\S|\S[&|]\s|\s[&|]\S/.test(input)) {
      input = input
        .replace(/\s*([&|])\s*/g, " $1 ")
        .replace(/\s*\|\s*/g, " | ");
    } else {
      input = e.target.value; // Return original input
    }
    if (!input.includes(DEFAULT_PROMPT)) {
      setPrompt(DEFAULT_PROMPT)
    } else {
      setPrompt(input)
    }
    if (input.slice(-1) === " "
      || input === ""
      || input === DEFAULT_PROMPT
      || input.at(-1) === ")") {
      setShowMenu(false)
    } else if (input.split(" ").at(-1).includes(">")) {
      console.log(input.split(" ").at(-1).split(">"))
      setInputSkill(input.split(" ").at(-1).split(">").at(-1))
      setShowMenu(true)
    }
  }

  useEffect(() => {
    if (refetchQuery) {
      if (!preserveCurrentTags) {
        setPrompt(refetchQuery)
      }
      handleQuery(null, refetchQuery)
      dispatch(toggleExplore({ refetchQuery: "" }))
    }
  }, [refetchQuery])

  const handleQuery = (e, refetchQuery) => {
    e?.preventDefault()
    const querystring = refetchQuery || prompt.trimStart()

    /**
     * Create a TreeNode to store the input prompt
     * Traverse the tree to create GraphQL query variables.
     */
    const root = new TreeNode();
    const tags = [];
    createTree(querystring, root, tags);
    // root.printTree();
    root.createQuery();
    const queryObject = root.queryObject;

    // const skills = refetchQuery
    //   ? refetchQuery.split(/[|]/g)
    //   : prompt.trimStart().split(/[|]/g)

    if (!preserveCurrentTags) {
      dispatch(toggleExplore({ currentTags: tags }))
    } else {
      // After the currentTags are preserved, set preserveCurrentTags back to false to resume normal behavior
      dispatch(toggleExplore({ preserveCurrentTags: false }))
    }

    toast.promise(
      queryExplore({
        variables: {
          "where": {
            active: true,
            ...queryObject
          }
        }
      }).then((res) => {
        dispatch(toggleExplore({ skillExploreRes: res.data?.people }))
        // If this query has never been queried before (!refetchQuery) and the history does not include this yet (!queryHistory.includes(prompt))
        // and the user is not toggling skillTags (!preserveCurrentTags), then record this into the session history

        // Convert multiple spaces to single space: prompt.trim().replace(/  +/g, ' ')
        !refetchQuery
          && !queryHistory.includes(prompt.trim().replace(/  +/g, ' '))
          && !preserveCurrentTags
          && dispatch(toggleExplore({ queryHistory: [...queryHistory, prompt.trim().replace(/  +/g, ' ')] }))
      }), {
      loading: !preserveCurrentTags ? "Querying..." : "Applying filter...",
      success: !preserveCurrentTags ? "Success" : "Filter applied",
      error: "Unexpected error!"
    }
    )
  }

  return (
    <form onSubmit={handleQuery} className="flex flex-col">
      <Label>Find me all people who know</Label>
      <AllSkillMenu
        show={showMenu}
        input={inputSkill}
        position={{ posX: x || 0, posY: y || 0 }} />
      <Input
        onInput={() => getPosition(inputRef)}
        ref={inputRef}
        value={prompt}
        onChange={(e) => { handleInputChange(e) }}
        name="prompt"
        id="prompt" />
      <p className="text-xs text-font-light mt-2 flex flex-row">
        Type <KBD>&gt;</KBD> for skills, <KBD>^</KBD> for skill's proficiency (1,2,3)
      </p>
      <p className="text-xs text-font-light mt-2 flex flex-row">
        <KBD>&</KBD> for AND, <KBD>|</KBD> for OR
      </p>
      <p className="text-xs text-font-light mt-2 flex flex-row">
        <KBD className={"!mr-0"}>&#40;</KBD> <KBD className={"!ml-0"}>&#41;</KBD> for parentheses
      </p>
      <p className="text-xs text-font-light mt-2">Example: (&gt;react^2 & &gt;tailwind-css) | (&gt;vue-js & &gt;mongodb)</p>
      <div>
        <Button className="mr-auto mt-2 w-full" formAction="submit">Query</Button>
      </div>
    </form>
  )
}

export default SkillExplorePrompt
