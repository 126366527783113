import {
  BookmarkIcon,
  CursorClickIcon,
  ChartBarIcon,
  CheckIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import NestedVerticalNav from '../../../../../custom-prebuilt/NestedVerticalNav.component';
import { useLocation } from 'react-router';
import { QueryUserProjects } from '../../../../../graphql/projects';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAssessment } from '../../../../../redux/assessmentSlice';
import { useEffect } from 'react';
import { useAuth } from '../../../../../lib/authContext';

const JGProjectAssessment = () => {
  const { cognitoID } = useAuth();
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const location = useLocation();
  const pathname = location.pathname;
  const projectId = pathname.split('/').at(-2);
  const dispatch = useDispatch();

  const queryProject = useQuery(QueryUserProjects, {
    variables: {
      where: {
        id: projectId,
      },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  const project = queryProject.data?.projects[0];

  const getProjectDescription = (project) => {
    return (
      project.assessments.find((a) => a?.personCompleted?.[0]?.cognitoID === cognitoID)
        ?.description ?? ''
    );
  };

  const getProjectSkills = (project) => {
    const userAssessment = project?.assessments?.find(
      (a) => a?.personCompleted?.[0]?.cognitoID === cognitoID,
    );
    return !userAssessment ? [] : userAssessment?.skillsConnection?.edges;
  };

  useEffect(() => {
    if (!project) {
      dispatch(toggleAssessment({ selectedSkills: [], loading: true }));
      return;
    }
    dispatch(
      toggleAssessment({
        project: project,
        projectDescription: getProjectDescription(project),
        selectedSkills: getProjectSkills(project),
        loading: false,
      }),
    );
  }, [project]);

  return (
    <NestedVerticalNav
      title={`Project Assessment: ${project?.name || 'Loading...'}`}
      menuTitle="Sections"
      navigation={[
        {
          name: 'Introduction',
          href: 'introduction',
          icon: BookmarkIcon,
        },
        {
          name: 'Select skills',
          href: 'select',
          icon: CursorClickIcon,
        },
        {
          name: 'Rate skills',
          href: 'rate',
          icon: ChartBarIcon,
        },
        {
          name: 'Description',
          href: 'description',
          icon: PencilAltIcon,
        },
        {
          name: 'Confirmation',
          href: 'confirmation',
          icon: CheckIcon,
        },
      ]}
    ></NestedVerticalNav>
  );
};

export default JGProjectAssessment;
