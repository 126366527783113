import { EditorContent, useEditor } from '@tiptap/react'
import { generateHTML } from "@tiptap/react"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import TextAlign from "@tiptap/extension-text-align"
import StarterKit from "@tiptap/starter-kit"
import Image from "@tiptap/extension-image"
import { useMemo } from "react"
import { lowlight } from 'lowlight'

const HtmlPreview = ({ json }) => {
  const output = useMemo(() => {
    return generateHTML(json, [
      StarterKit,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      TextAlign.configure({
        alignments: ['left', 'right', 'center'],
        types: ['heading', 'paragraph', 'image'],
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      // other extensions …
    ])
  }, [json])

  const editor = useEditor({
    editable: false,
    content: output,
    extensions: [
      StarterKit,
      CodeBlockLowlight.configure({
        lowlight,
        languageClassPrefix: 'javascript',
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      TextAlign.configure({
        alignments: ['left', 'right', 'center'],
        types: ['heading', 'paragraph', 'image'],
      })
    ],
  })

  if (!editor) {
    return null
  }


  return (
    <EditorContent editor={editor} />
  )
}

export default HtmlPreview