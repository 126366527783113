/**
 *
 * @param {*} userDetails userDetails returned from QueryPersonDetails
 * @param {*} header true to show section header
 * @returns
 */

import Card from '../../shared/Card.component';
import { classNames } from '../../../lib/classNames';

// PROPS: { userDetails, header }
const UserCert = ({ userDetails, className }) => {
  return (
    <Card
      title="Certifications"
      titleCounter={userDetails?.certsConnection?.edges.length}
      displayCounter
      className={classNames(` `, className)}
    >
      {userDetails?.certsConnection?.edges.length ? (
        <div className="grid grid-cols-1 divide-y divide-gray-200">
          {userDetails?.certsConnection?.edges.map((cert, index) => {
            return (
              <div key={index} className="flex flex-row py-5">
                <img
                  className="h-16 w-16 rounded-full"
                  referrerPolicy="no-referrer"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${cert.node.logo}`}
                  alt=""
                />
                <div className="flex flex-col ml-5">
                  <p className="text-xs font-normal leading-relaxed">{cert.node.name}</p>
                  <p className="text-xs text-gray-700 font-medium mt-2">
                    {cert.node.category ? cert.node.category : 'null'} - {cert.node.level}{' '}
                  </p>
                  <p className="text-xs text-gray-700 font-medium mt-1">
                    Acquired:{' '}
                    <span className="text-gray-500 font-normal">
                      {cert.acquiredDate ? cert.acquiredDate : 'null'}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-xs text-font-light">This person has no certification to display</p>
      )}
    </Card>
  );
};

export default UserCert;
