import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { classNames } from '../lib/classNames';

export type SlideOverProps = {
  show?: boolean;
  title?: string;
  content?: JSX.Element;
  className?: string;
  largeSize?: boolean;
  bgWhite?: boolean;
  onClose?: () => void;
  onClickButtonClose?: () => void;
};
/**
 * A slide-over that will be displayed upon all other contents
 * @param {*} show Open/close slide over
 * @param {*} title  Slide over title
 * @param {*} content Slide over content
 * @param {*} onClose Execute when user inputs ESC
 * @param {*} onClickButtonClose Execute when user clicks "X" icon
 * @param {*} largeSize Render an extended version of slide over
 * @param {*} bgWhite If provided, slide over will have a white background, otherwise light gray
 */
const SlideOver = ({
  show,
  onClose,
  title,
  content,
  onClickButtonClose,
  largeSize,
  bgWhite,
  className,
}: SlideOverProps) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(`relative z-[500]`, className)}
        onClose={() => {
          onClose && onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="pointer-events-none fixed inset-0 overflow-hidden">
          <div className="pointer-events-none absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-150 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-150 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen ${largeSize ? 'max-w-2xl' : 'max-w-md'}`}
                >
                  <div
                    className={`flex h-full flex-col overflow-y-auto overflow-x-hidden py-10 shadow-xl ${
                      !bgWhite ? 'bg-bg_lightgray' : 'bg-white'
                    }`}
                  >
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title>
                          <p className="text-sm font-medium mb-6">{title}</p>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className={`rounded-md ${
                              !bgWhite ? 'bg-bg_lightgray' : 'bg-white'
                            } text-gray-400 hover:text-gray-500`}
                            onClick={() => {
                              onClickButtonClose && onClickButtonClose();
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      {content}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
