import { useState, useEffect } from 'react';
import SnapshotProfile from '../../custom-prebuilt/snapshot/SnapshotProfile.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import defaultImg from '../../assets/default-user.svg';
import { useNavigate } from 'react-router';

const AllTeamMembers = ({ members, basicView }) => {
  const [pointer, setPointer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [pointer]);

  return (
    <div className="flex flex-col lg:flex-row space-x-4 h-full">
      <div className="space-y-4 lg:flex-1 max-h-full overflow-auto">
        {members.map((member, index) => {
          const user = member?.node || member;
          return (
            <div
              key={index}
              className={`px-6 py-4 bg-white shadow rounded-md cursor-pointer
              ${pointer === index && 'ring-inset ring-signature bg-gray-200 ring-2'}`}
              onClick={() => {
                if (window.innerWidth >= 1024) {
                  setPointer(index);
                } else {
                  console.log(member);
                  navigate(`/${member.node.email.split('.').at(0)}`);
                }
              }}
            >
              <div className="flex flex-row items-start">
                <img
                  src={user?.userIconUrl ?? defaultImg}
                  alt=""
                  className="h-8 w-8 rounded-full mr-4"
                  referrerPolicy="no-referrer"
                />
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-900 font-medium">
                      Name:{' '}
                      <span className="text-gray-500 text-xs font-medium">
                        {user?.name || member?.name}
                      </span>
                    </span>
                    <span className="text-xs text-gray-900 font-medium">
                      Email:{' '}
                      <span className="text-gray-500 text-xs font-medium">
                        {user?.email || member?.name}
                      </span>
                    </span>
                  </div>
                  {!basicView ? (
                    <div className="flex flex-col">
                      <span className="text-xs text-gray-900 font-medium">
                        Role:{' '}
                        <span className="text-gray-500 text-xs font-medium">
                          {member?.role || 'N/A'}
                        </span>
                      </span>
                      {/* <span className="text-xs text-gray-900 font-medium">
                        Alloc Type:{' '}
                        <span className="text-gray-500 text-xs font-medium">
                          {member?.allocationType || 'null'}
                        </span>
                      </span> */}
                      <span className="text-xs text-gray-900 font-medium">
                        Alloc Per Week:{' '}
                        <span className="text-gray-500 text-xs font-medium">
                          {member?.hoursPerWeek || 'N/A'}
                        </span>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="hidden lg:block flex-1 bg-white shadow rounded-lg h-full overflow-auto">
        {!isLoading ? (
          <SnapshotProfile bgWhite target={members.at(pointer)?.node || members.at(pointer)} />
        ) : (
          <LoadingState />
        )}
      </div>
    </div>
  );
};

export default AllTeamMembers;
