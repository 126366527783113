import Cropper from 'react-easy-crop';
import getCroppedImg, { createBlob, createImage } from '../lib/cropImage';
import { ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Point, Area, MediaSize } from 'react-easy-crop/types';
import Modal from './Modal.component';
const placeholder: string = require('../assets/placeholder.png');

/**
 * Type Definition
 */
export type ImageInputProps = {
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  fieldLabel?: ReactNode | undefined;
  enableCropper?: boolean | undefined;
  cropShape?: 'rect' | 'round';
  cropAspect?: number;
  cropShowGrid?: boolean;
  changeButtonText?: string;
  contentWrapperClasses?: string;
  displayImageClasses?: string;
  filename?: string;
  afterCrop?: (params: File) => Promise<void>;
  useImageAspectRatio?: boolean;
};
export type BlobType = BlobPart;
/**
 * @param image the current image. When a image has not been selected to be displayed when no image has been selected yet
 * @param setImage
 * @param id a unique identifier used to differentiate react croppers
 * @param fieldLabel (optional) a header attached to the top of the image input
 * @param enableCropper (optional) whether the cropper should be used
 * @param cropShape (optional) round | rect
 * @param cropAspect (optional) aspect ratio
 * @param cropShowGrid (optional) whether to show the grid on cropper
 * @param changeButtonText (optional) the text on the button to change the image
 * @param contentWrapperClasses (optional) classes that are put on the wrapper that wrap the image and button
 * @param displayImageClasses (optional) classes that are put on the wrapper that wrap the image and button
 * @param filename (optional) name of the returned image; defaults to a random uuid
 * @param afterCrop (optional) function to run after crop is complete; is passed the cropped image file
 * @param useImageAspectRatio (optional) whether the cropper should use the images aspect ratio by default
 */
const ImageInput = ({
  image,
  setImage,
  id,
  fieldLabel,
  enableCropper,
  cropShape,
  cropAspect,
  cropShowGrid,
  changeButtonText,
  contentWrapperClasses,
  displayImageClasses,
  filename,
  afterCrop,
  useImageAspectRatio,
}: ImageInputProps) => {
  const defaultAspect = cropAspect ?? 1;
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [fileType, setFileType] = useState<string>('image/jpeg');
  const [fileExtension, setFileExtension] = useState<string>('.jpeg');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [internalDisplay, setInternalDisplay] = useState<string>(image);
  const [aspectRatio, setAspectRatio] = useState<number>(defaultAspect);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedArea, setCroppedArea] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const reader: FileReader = new FileReader();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const isFile = (object: any) => object instanceof File;

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    // Params from previous code: (croppedAreaPercentage, croppedAreaPixels)
    setCroppedArea(croppedAreaPixels);
  };

  const onUseCropped = useCallback(
    // eslint-disable-next-line
    async (params?: any) => {
      const name = filename ?? Date.now().toString();
      try {
        const croppedImage: Blob | null | undefined = await getCroppedImg(
          selectedImage,
          croppedArea,
          fileType,
        );
        const croppedFile = new File([croppedImage as BlobPart], name + `.${fileExtension}`);
        // Argument of type 'File' is not assignable to parameter of type 'SetStateAction<string>'.ts(2345)
        setImage(croppedFile as unknown as string);
        setInternalDisplay(URL.createObjectURL(croppedFile));
        setSelectedImage('');
        setUploadModalOpen(false);
        if (afterCrop) await afterCrop(croppedFile);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedArea],
  );

  const openCropper = async (image: string, fileType: string, fileExtension: string) => {
    setSelectedImage(image);
    setFileType(fileType);
    setFileExtension(fileExtension);

    // If the image is too big, we need to resize it before we can crop it
    const img = await createImage(image);

    // This magic number is so that the canvas created during the cropping process
    // does not exceed the maximum size of a canvas on iPhones
    //
    // The maximum area of a canvas on an iPhone is (width * height <= 16,777,216 px)
    // REF: https://github.com/jhildenbiddle/canvas-size#mobile
    const MAX_SIDE_LENGTH = 2896;

    let { width, height } = img;
    const isTooBig = width > MAX_SIDE_LENGTH || height > MAX_SIDE_LENGTH;
    if (isTooBig) {
      const scale = Math.min(MAX_SIDE_LENGTH / width, MAX_SIDE_LENGTH / height);
      width *= scale;
      height *= scale;
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('Could not get canvas context');
      ctx.drawImage(img, 0, 0, width, height);
      image = canvas.toDataURL();
      setSelectedImage(image);
    }
  };

  const readerListener = async (curFileType: string) => {
    let curFileExtension = 'jpeg';
    if (curFileType === 'image/png') curFileExtension = 'png';
    if (enableCropper) await openCropper(reader.result as string, curFileType, curFileExtension);
    else {
      const blob = await createBlob(reader.result as string, curFileType);
      const uuid = Date.now().toString();
      const readFile = new File([blob as BlobPart], uuid + `.${curFileExtension}`);
      setInternalDisplay(URL.createObjectURL(readFile));
      setImage(readFile as unknown as string);
    }
  };

  // PARAMS: (event, type)
  const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileType(file.type);
      const fileTypes = ['image/png', 'image/jpeg'];
      const fileExtensions = ['.png', '.jpeg'];
      if (fileTypes.includes(file.type)) {
        reader.readAsDataURL(file);

        return new Promise<any>((resolve) => {
          reader.addEventListener('load', async () => resolve(await readerListener(file.type)));
        });
      } else {
        toast.error(`You can only upload ${fileExtensions.join(' ')} files.`);
      }
    }
  };

  const onMediaLoaded = (event: MediaSize) => {
    if (useImageAspectRatio) setAspectRatio(event.naturalWidth / event.naturalHeight);
    reader.addEventListener('load', async () => await readerListener(fileType));
  };

  return (
    <div>
      {fieldLabel}
      <div className={contentWrapperClasses}>
        <img
          className={displayImageClasses}
          src={
            isFile(internalDisplay)
              ? URL.createObjectURL(internalDisplay as unknown as Blob)
              : internalDisplay ?? placeholder
          }
          alt="input"
        />
        <label
          role="open-modal"
          onClick={() => setUploadModalOpen(true)}
          className="cursor-pointer text-center h-fit rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium shadow-sm block mt-2"
        >
          {changeButtonText}
        </label>
      </div>
      <Modal
        open={uploadModalOpen}
        title={changeButtonText}
        content={
          <>
            {selectedImage != '' && enableCropper ? (
              <div className="container cropper relative h-[300px] flex flex-col items-center">
                <div className="cropper">
                  <Cropper
                    image={selectedImage}
                    crop={crop}
                    zoom={zoom}
                    showGrid={cropShowGrid}
                    aspect={aspectRatio}
                    cropShape={cropShape}
                    zoomSpeed={0.25}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    onMediaLoaded={onMediaLoaded}
                  />
                </div>
              </div>
            ) : (
              <div>
                <img
                  className={displayImageClasses}
                  src={
                    isFile(internalDisplay)
                      ? URL.createObjectURL(internalDisplay as unknown as Blob)
                      : internalDisplay ?? placeholder
                  }
                  style={{ margin: 'auto' }}
                  alt="input"
                />
              </div>
            )}
            <input
              hidden
              type="file"
              name="uploadImage"
              data-testid={`upload-${id}-image`}
              id={`upload-${id}-image`}
              onChange={onSelectFile}
            />
            {selectedImage != '' && enableCropper ? (
              <div className="text-center italic font-thin p-2">
                Adjust the image, and click Save when satisfied.
              </div>
            ) : (
              <label
                htmlFor={`upload-${id}-image`}
                className="cursor-pointer text-center h-fit rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium shadow-sm block mt-2"
              >
                Upload new Image
              </label>
            )}
          </>
        }
        primaryButtonLabel="Save"
        dataTestidPrimary="use-cropped"
        onPrimaryButtonClick={
          selectedImage != '' && enableCropper ? () => onUseCropped() : undefined
        }
        onSecondaryButtonClick={() => {
          setSelectedImage('');
          setUploadModalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        dataTestidSecondary="cancel-crop"
      />
    </div>
  );
};

export default ImageInput;
