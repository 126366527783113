import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import LoadingState from '../../../../custom-prebuilt/preloader/LoadingState.component';
import { GetCreatedSkills, ImportTaxonomy } from '../../../../graphql/taxonomy';
import { RootState } from '../../../../redux/store';
import { TaxonomyJSON } from './ExportTaxonomy';

type ImportTaxonomyModalProps = {
  importedTaxonomy: TaxonomyJSON;
  closeModal: () => void;
};

const ImportTaxonomyModal = ({ importedTaxonomy, closeModal }: ImportTaxonomyModalProps) => {
  const defaultOrgUid = useSelector((state: RootState) => state.org.defaultOrgUid);
  const { data, loading } = useQuery(GetCreatedSkills, {
    variables: {
      skills: importedTaxonomy.skills,
    },
  });
  const [importTaxonomy] = useMutation(ImportTaxonomy, {
    refetchQueries: ['GetOrgTaxonomy', 'GetUnattachedSkills'],
  });

  const handleImportTaxonomy = () => {
    const promise = importTaxonomy({
      variables: {
        orgUid: defaultOrgUid,
        skills: importedTaxonomy.skills,
        categories: importedTaxonomy.categories,
        edges: importedTaxonomy.edges,
      },
    });
    toast.promise(promise, {
      success: 'Successfully imported!',
      loading: 'Importing...',
      error: 'An error occurred!',
    });
    closeModal();
  };

  return (
    <div className="flex flex-col w-full h-full">
      {loading ? (
        <LoadingState />
      ) : (
        <>
          <div className="flex flex-col w-full items-center mb-4">
            {!data.getCreatedSkills.length ? (
              <div className="text-center text-sm font-medium">
                All required skills already exist in the database. No additional skills will be
                created.
              </div>
            ) : (
              <div className="flex flex-col items-center text-sm font-medium">
                The following skills will be created by the import operation:
                {data.getCreatedSkills.map((skill: string, index: number) => {
                  return (
                    <div className="mt-1 font-normal" key={index}>
                      {skill}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="self-center font-medium text-xs mb-2">
            Would you like to continue the import?
          </div>
          <div className="flex w-full justify-evenly">
            <button
              className="rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-xs font-medium text-black hover:bg-gray-200 transition duration-150"
              onClick={() => handleImportTaxonomy()}
            >
              Continue
            </button>
            <button
              className="rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-bg-dark text-xs font-medium text-white hover:bg-gray-700 transition duration-150"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportTaxonomyModal;
