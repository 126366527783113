import PageErrorComponent from './PageError.component';
import React from 'react';

const RestrictedAuth = () => {
  return (
    <PageErrorComponent
      errorCode={401}
      title={'Restricted Auth'}
      text={'Please contact your admin.'}
    />
  );
};

export default RestrictedAuth;
