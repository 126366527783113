import SearchLogic from '../../../custom-prebuilt/SearchLogic.component';
import SkillCard from '../../shared/SkillCard.component';
import Card from '../../shared/Card.component';
import { useState } from 'react';
import { Input } from '../../../custom-prebuilt/common.component';
import { classNames } from '../../../lib/classNames';
import { useNavigate } from 'react-router';

/**
 *
 * @param {*} userDetails userDetails returned from QueryPersonDetails
 * @param {*} redirectSkill If true, onClick will take user to skill's page
 * @returns
 */

const UserSkillList = ({ userDetails, className, redirectSkill }) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredSkills, setFilteredSkills] = useState(userDetails?.skillsConnection?.edges);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    setFilteredSkills(
      userDetails?.skillsConnection?.edges?.filter((s) =>
        s.node.name.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  return (
    <Card
      title="Skills"
      titleCounter={userDetails?.skillsConnection?.edges.length}
      displayCounter
      className={classNames(className, ` `)}
    >
      <div>
        <Input
          name="skill-list"
          id="skill-list"
          value={searchInput}
          placeholder="Search skill"
          onChange={handleInputChange}
        />

        <SearchLogic
          loading={!userDetails?.skillsConnection?.edges?.length}
          dataCheck={filteredSkills?.length}
          searchInput={searchInput}
          dataComponent={
            <>
              {filteredSkills?.length ? (
                <div className="grid grid-cols-1 divide-y divide-gray-200">
                  {filteredSkills.map((skill, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        navigate(`/skills/${skill.node.slug}`);
                      }}
                    >
                      <SkillCard
                        skill={skill.node}
                        redirectSkill={redirectSkill}
                        rating={skill.rating}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-xs text-font-light">
                  This person has no certification to display
                </p>
              )}
            </>
          }
        />
      </div>
    </Card>
  );
};

export default UserSkillList;
