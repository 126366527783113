import QueryHistory from "./tools/QueryHistory.component"
import SlideOver from "../../../custom-prebuilt/SlideOver.component"
import { useSelector, useDispatch } from "react-redux"
import { toggleSlideOver } from "../../../redux/slideOverSlice"

const SkillExploreHistory = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.slideOver.show2)

  const closeSlideOver = () => {
    dispatch(toggleSlideOver({ show2: false }))
  }

  return (
    <SlideOver
      show={show}
      title="Query history"
      onClose={closeSlideOver}
      onClickButtonClose={closeSlideOver}
      content={<QueryHistory />} />
  )
}

export default SkillExploreHistory