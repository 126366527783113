import { useSelector } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { levelOptions, assessmentOptions } from './utils/blockOptions';
import MultipleChoiceBlock from './utils/MultipleChoiceBlock.component';
import Modal from '../../../../custom-prebuilt/Modal.component';

const Blocks = ({ stage }) => {
  const teamAnswers = useSelector((state) => state.assessment.teamAnswers);
  const [show, setShow] = useState(false);
  const [renderBody, setRenderBody] = useState(false);
  const [parent] = useAutoAnimate({ duration: 100 });

  useEffect(() => {
    teamAnswers[0]?.value >= 0 && teamAnswers[1]?.value >= 0 ? setShow(true) : setShow(false);
  }, [teamAnswers]);

  console.log(Object.entries(assessmentOptions));

  return (
    <div ref={parent} className="h-full flex-1">
      {renderBody && (
        <div className="h-full lg:flex lg:flex-row">
          <aside className="w-full lg:max-w-lg h-full overflow-y-auto pt-5 pb-4 px-5 space-y-4">
            {Object.entries(assessmentOptions).map((option) => (
              <Fragment key={option[0]}>
                <MultipleChoiceBlock
                  options={option[1]}
                  stage={stage}
                  title={`Block ${option[0]}: ${option[1].name}`}
                />
              </Fragment>
            ))}
          </aside>
        </div>
      )}
      <Modal
        open={show}
        primaryButtonLabel="OK"
        onPrimaryButtonClick={() => {
          setShow(false);
          setRenderBody(true);
        }}
        onSecondaryButtonClick={() => setShow(false)}
        title="Company level"
        content={
          <div className="px-5 py-6 bg-white w-full shadow rounded-lg">
            <label htmlFor="multiple-choice" className="block text-xs font-medium text-gray-700">
              {levelOptions[0].question} {teamAnswers.find((a) => a.name === 'size')?.value}
            </label>
            <p className="text-xs text-gray-500 mt-3 leading-relaxed">
              {levelOptions[0].description1}{' '}
              {(teamAnswers.find((a) => a.name === 'size')?.value + 1) * 4}{' '}
              {levelOptions[0].description2}
            </p>
            <div
              className={`space-y-3 mt-4 ${
                levelOptions.align === 'row' && 'grid grid-cols-3 gap-3 space-y-0'
              }`}
            ></div>
          </div>
        }
      />
    </div>
  );
};

export default Blocks;
