import { useProfileGeneratorInfo } from './state/ProfileGeneratorContext';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from './state/profileGeneratorSlice';
import { mergeClasses } from '../../lib/classNames';
import { useMemo } from 'react';
import { ChevronDownIcon, ChevronUpIcon, RefreshIcon } from '@heroicons/react/outline';
import { camelCaseToTitle, pathToArray } from './state/ProfileGenerationContextHelpers';

const LineHeightIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.21739" y="2.8125" width="1.52174" height="9.375" fill="#4B5863" />
      <rect width="1.875" height="9.13043" transform="matrix(0 -1 -1 0 14 5)" fill="#4B5863" />
      <rect width="1.875" height="9.13043" transform="matrix(0 -1 -1 0 14 8.125)" fill="#4B5863" />
      <rect
        width="1.875"
        height="9.13043"
        transform="matrix(0 -1 -1 0 14 11.5625)"
        fill="#4B5863"
      />
      <path d="M1.97826 0L3.69148 3.04688H0.265037L1.97826 0Z" fill="#4B5863" />
      <path d="M1.97826 15L3.69148 11.9531H0.265037L1.97826 15Z" fill="#4B5863" />
    </svg>
  );
};

const getPrefix = (styleName) => {
  if (styleName === 'fontSize')
    return <p className="text-xl font-[InterVariable] font-extrabold text-font-dark">T</p>;
  else if (styleName === 'lineHeight') return <LineHeightIcon className="h-5 w-5 text-font-dark" />;
};

const getStyleValue = (styleName, valueString) => {
  if (styleName === 'fontSize' || styleName === 'lineHeight') return `${valueString}px`;
  return valueString;
};

const StyleInput = ({ path, index, styleName }) => {
  const profileData = useSelector((state) => state.profileGenerator.profileData);
  const dispatch = useDispatch();
  const { getValue } = useProfileGeneratorInfo();
  const readableStyle = useMemo(() => camelCaseToTitle(styleName), [styleName]);
  const prefix = useMemo(() => getPrefix(styleName), [styleName]);

  const onChange = (event) => {
    const newStyleValue = event.target.value.toString();
    dispatch(
      updateState({ path, index, newValue: getStyleValue(styleName, newStyleValue), styleName }),
    );
  };

  const value = useMemo(() => {
    const styleString = getValue(path, index, styleName);
    if (styleString === 'px') return '';
    const val = parseInt(styleString, 10);
    return val;
  }, [path, index, profileData]);

  const valueLength = value.toString().length;

  const increment = () => {
    onChange({ target: { value: value + 1 } });
  };

  const decrement = () => {
    if (value - 1 > 0) onChange({ target: { value: value - 1 } });
  };

  return (
    <div
      data-for="profileGenHelp"
      data-tip={readableStyle}
      className="flex gap-2 items-center justify-center"
    >
      {prefix}
      <div className="flex items-center justify-center">
        <input
          type="tel"
          min={1}
          value={value}
          onChange={onChange}
          className={mergeClasses(
            `h-auto p-1 mr-1 box-content text-font-dark font-normal border-borders border focus:border-borders focus:ring-borders text-xs rounded-md disabled:opacity-75 h-[1.25rem] ${
              valueLength === 1 ? 'w-[.6rem] ' : 'w-[1rem]'
            }`,
          )}
        />
        <div>
          <ChevronUpIcon onClick={increment} className="h-4 w-4 cursor-pointer text-font-dark" />
          <ChevronDownIcon onClick={decrement} className="h-4 w-4 cursor-pointer text-font-dark" />
        </div>
      </div>
    </div>
  );
};

const StyleInputs = ({ path, index }) => {
  const { getStyle, getValue } = useProfileGeneratorInfo();
  const dispatch = useDispatch();

  const resetStyles = () => {
    const isArray = typeof index !== 'undefined' && index !== -1;
    let actualPath = path;
    if (isArray) {
      actualPath = `.${pathToArray(path).slice(0, -1).join('.')}`;
    }
    const defaultStyles = getValue(`${actualPath}DefaultStyles`);

    dispatch(updateState({ path: `${path}Styles`, index, newValue: defaultStyles }));
  };

  const styleComponents = useMemo(() => {
    if (path !== '') {
      const styles = getStyle(path, index);
      const styleList = [];
      let key = 0;
      for (const styleName of Object.keys(styles)) {
        styleList.push(<StyleInput path={path} styleName={styleName} index={index} key={key} />);
        key++;
      }
      if (styleList.length > 0) {
        styleList.push(
          <RefreshIcon
            data-for="profileGenHelp"
            data-tip={'Reset Styles'}
            onClick={resetStyles}
            className="h-5 w-5 cursor-pointer text-font-dark"
            key={key}
          />,
        );
      }
      return styleList;
    }
    return [];
  }, [path, index]);

  if (styleComponents.length === 0) return <div />;

  return (
    <>
      <div className="bg-white shadow-md rounded-md flex items-center gap-3 w-min h-min p-2">
        {styleComponents}
      </div>
    </>
  );
};

export default StyleInputs;
