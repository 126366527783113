import '@aws-amplify/ui-react/styles.css';
import { MapView } from '@aws-amplify/ui-react'
import { Marker } from 'react-map-gl'
import { useState } from 'react';

/** Standard map wrapper
 * @param {JSON} coords latitude and longitude of speci
 * @returns a standard AWS map
 */
const Map = ({ children,location,pin=false }) => {
    const [viewState,setViewState] = useState(5*90)

    return (
        <MapView
            initialViewState={{
                longitude: location.longitude,
                latitude: location.latitude,
                zoom: 5,
            }}
            style={{height:'100%', width:"100%"}}
            onZoom={(e) => {
                setViewState(Math.floor(e.viewState.zoom*90))
            }}
        >
            {children}
            {pin?(
                <Marker
                    latitude={location.latitude}
                    longitude={location.longitude}
                    scale={viewState}
                >
                    <svg version="1.0" width={viewState} height={viewState} viewBox="-20 -6 42 17">
                        <path 
                            d="M 4.1016951 -1.7372881 A 7.0847459 7.0508475 0 1 1  -10.067797,-1.7372881 A 7.0847459 7.0508475 0 1 1  4.1016951 -1.7372881 z" 
                            className='fill-gray-600 opacity-30 stroke-gray-900 stroke-1'
                        />
                    </svg>
                </Marker>
            ):(<></>)}
        </MapView>
    )
}
export default Map;