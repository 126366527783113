import {
  ClipboardCheckIcon,
  ClipboardListIcon,
  DocumentDuplicateIcon,
  ViewGridAddIcon,
  DocumentAddIcon,
  ShareIcon,
} from '@heroicons/react/outline';
import { useLazyQuery } from '@apollo/client';
import { SearchIcon } from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { QueryCurrentOrg } from '../../../graphql/people';
import { QueryAllSkillsBasics } from '../../../graphql/skills';
import { useAuth } from '../../../lib/authContext';
import { getSections } from '../../../lib/getNameSections';
import SnapshotSkill from '../../../custom-prebuilt/snapshot/SnapshotSkill.component';
import { useNestedNav } from '../../../lib/customHook';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import AddNewSkill from './AddNewSkill.component';
import CertsDirectoryAdminView from '../certs/CertsDirectoryAdminView.component';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import SearchLogic from '../../../custom-prebuilt/SearchLogic.component';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { skills_split } from '../../../split.io/sdk.config';
import RestrictedAuth from '../../../custom-prebuilt/RestrictedAuth.component';
import { useApplyFilter } from '../../../lib/customHook';
import { toggleModal } from '../../../redux/modalSlice';
import { orgCheck } from '../../../lib/orgCheck';
import AddNewCert from '../certs/AddNewCert.component';
import SkillTaxonomyAdminView from '../taxonomy/SkillTaxonomyAdminView';
import RequestedSkills from './RequestedSkills.component';

const nestedNav = [
  {
    name: 'Manage Skills',
    icon: ClipboardListIcon,
  },
  {
    name: 'Manage Certifications',
    icon: DocumentDuplicateIcon,
  },
  {
    name: 'Add New Skill',
    icon: ViewGridAddIcon,
  },
  {
    name: 'Add New Certification',
    icon: DocumentAddIcon,
  },
  {
    name: 'Skill Taxonomy',
    icon: ShareIcon,
  },
  {
    name: 'Requested Skills',
    icon: ClipboardCheckIcon,
  },
];

const SkillsDirectoryAdminView = () => {
  const { cognitoID } = useAuth();
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [skillsDirectory, setSkillsDirectory] = useState([]);
  const [initAllSkillsData, setInitAllSkillsData] = useState(null);
  const [parent] = useAutoAnimate({ duration: 150 });
  const [currentOrg] = useLazyQuery(QueryCurrentOrg);
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const dispatch = useDispatch();
  const show = useSelector((state) => state.slideOver.show);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const [searchInput, setSearchInput] = useState('');
  const [current, nestedNavComponent] = useNestedNav(nestedNav);
  const [applyFilter, setApplyFilter, activeFilter, inactiveFilter, ModalComponent] =
    useApplyFilter();
  const [allSkills] = useLazyQuery(QueryAllSkillsBasics);

  useEffect(() => {
    if (applyFilter) {
      setSkillsDirectory([]);
      setInitAllSkillsData(null);
      setSelectedSkill(null);
      currentOrg({
        variables: { where: { cognitoID: cognitoID || '' } },
      }).then((res) => {
        if (orgCheck(res, defaultOrg)) {
          allSkills({
            variables: {
              [!((!activeFilter && !inactiveFilter) || (activeFilter && inactiveFilter)) &&
              'where']: {
                [(!activeFilter || !inactiveFilter) && 'active']: activeFilter,
              },
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'standby', //prevent repetition when re-querying
          }).then((res) => {
            const sortedSkills = [
              ...getSections([...res.data.skills].sort((a, b) => a.name.localeCompare(b.name))),
            ];
            setSkillsDirectory(sortedSkills);
            setInitAllSkillsData(res);
            setSelectedSkill(sortedSkills[0]?.data[0]);
            setApplyFilter(false);
          });
        }
      });
    }
  }, [
    cognitoID,
    defaultOrg,
    allSkills.data,
    allSkills,
    currentOrg,
    activeFilter,
    applyFilter,
    inactiveFilter,
    setApplyFilter,
  ]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    initAllSkillsData.data?.skills &&
      e.target.value === '' &&
      setSkillsDirectory([
        ...getSections(
          [...initAllSkillsData.data.skills].sort((a, b) => a.name.localeCompare(b.name)),
        ),
      ]);
    initAllSkillsData.data?.skills &&
      e.target.value !== '' &&
      setSkillsDirectory([
        ...getSections(
          initAllSkillsData.data.skills.filter((skill) =>
            skill.name.toLowerCase().includes(e.target.value.toLowerCase()),
          ),
        ),
      ]);
  };

  const handleSelectSkill = (skill) => {
    setSelectedSkill(skill);
    dispatch(toggleSlideOver({ show: true, profileTarget: skill }));
  };

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false, profileTarget: null }));
  };

  return (
    <SplitTreatments names={[skills_split]}>
      {({ treatments, isReady }) => {
        return isReady && treatments[skills_split].treatment === 'on' ? (
          <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full">
            {nestedNavComponent}
            <div ref={parent} className="flex flex-grow">
              {current === 0 && (
                <>
                  <aside className="max-w-[20rem] 2xl:max-w-sm min-w-[20rem] 2xl:min-w-[24rem] flex-1 flex-grow border-r border-gray-200 xl:order-first lg:flex lg:flex-col">
                    <div className="lg:px-6 pt-6 pb-4">
                      <h2 className="text-sm font-medium text-gray-900">Skills Directory</h2>
                      <p className="mt-1 text-xs text-gray-600">
                        Select a skill to view its details
                      </p>
                      <div className="mt-2 flex space-x-4">
                        <div className="min-w-0 flex-1">
                          <label htmlFor="search" className="sr-only">
                            Search
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                              type="search"
                              name="search"
                              id="search"
                              className="block w-full rounded-md border-gray-300 pl-10 focus:border-signature focus:ring-signature
                                        text-xs"
                              placeholder="Search"
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            dispatch(toggleModal({ show: true }));
                          }}
                          className="inline-flex justify-center rounded-md border border-gray-300 bg-gray-800 px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-700
                        focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2 transition duration-300"
                        >
                          <FilterIcon className="h-4 w-4 text-white" aria-hidden="true" />
                        </button>

                        {ModalComponent}
                      </div>
                    </div>
                    {/* Directory list */}
                    <SearchLogic
                      loading={!skillsDirectory.length}
                      dataCheck={skillsDirectory.length}
                      dataComponent={
                        <nav
                          className="min-h-0 flex-1 overflow-y-auto flex-grow"
                          aria-label="Directory"
                        >
                          {skillsDirectory.map((el, index) => {
                            return (
                              <div key={index} className="relative">
                                <div className="sticky top-0 z-10 border-t border-b border-gray-200 px-6 py-1 text-sm font-medium text-gray-500 bg-gray-200">
                                  <h3>{el.letter}</h3>
                                </div>
                                <ul className="relative z-0 divide-y divide-gray-200">
                                  {skillsDirectory[index].data.map((skill, index) => {
                                    return (
                                      <li key={index}>
                                        <div
                                          className={`relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-200 transition duration-300
                                  ${
                                    selectedSkill === skill &&
                                    'ring-inset ring-signature bg-gray-200 ring-2'
                                  }`}
                                        >
                                          <div className="flex-shrink-0">
                                            <img
                                              className="h-10 w-10 rounded-full"
                                              referrerPolicy="no-referrer"
                                              src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`}
                                              alt=""
                                            />
                                          </div>
                                          <div className="min-w-0 flex-1">
                                            <div className="focus:outline-none cursor-pointer">
                                              {/* Extend touch target to entire panel */}
                                              <span
                                                className="absolute inset-0"
                                                aria-hidden="true"
                                                onClick={() => {
                                                  handleSelectSkill(skill);
                                                }}
                                              />
                                              <p className="text-xs font-medium text-gray-900">
                                                {skill.name}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </nav>
                      }
                      searchInput={searchInput}
                      reloadCondition={applyFilter}
                    />
                  </aside>
                  {selectedSkill ? (
                    <>
                      <aside className="border-r border-gray-200 xl:order-first xl:flex xl:flex-col hidden xl:w-full">
                        {selectedSkill ? (
                          <SnapshotSkill target={selectedSkill} coverPic={true} editable />
                        ) : null}
                      </aside>
                    </>
                  ) : (
                    <LoadingState />
                  )}
                </>
              )}
              {current === 1 && <CertsDirectoryAdminView />}
              {current === 2 && <AddNewSkill reload={() => setApplyFilter(true)} />}
              {current === 3 && <AddNewCert reload={() => setApplyFilter(true)} />}
              {current === 4 && <SkillTaxonomyAdminView />}
              {current === 5 && <RequestedSkills />}
            </div>

            <SlideOver
              show={show}
              onClose={handleCloseSlideOver}
              onClickButtonClose={handleCloseSlideOver}
              className="xl:hidden"
              largeSize
              content={<SnapshotSkill target={target} editable />}
            />
          </div>
        ) : (
          <RestrictedAuth />
        );
      }}
    </SplitTreatments>
  );
};

export default SkillsDirectoryAdminView;
