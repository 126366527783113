import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const OVERVIEW = 'Overview';
const SKILLS = 'Skills/Certifications';
const EXPERIENCE = 'Experience';

export { OVERVIEW, SKILLS, EXPERIENCE };

export type ProfileNavState = {
  current: 'Overview' | 'Skills/Certifications' | 'Experience';
};

const initialState: ProfileNavState = {
  current: OVERVIEW,
};

export const profilenavSlice = createSlice({
  name: 'profileNav',
  initialState,
  reducers: {
    toggleProfilenav: (state, action: PayloadAction<Partial<ProfileNavState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleProfilenav } = profilenavSlice.actions;

export default profilenavSlice.reducer;
