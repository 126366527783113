import { FolderAddIcon, FolderOpenIcon } from '@heroicons/react/outline';
import { useNestedNav } from '../../../lib/customHook';
import AddNewProject from './AddNewProject.component';
import ProjectsDirectoryAdminView from './ProjectsDirectoryAdminView.component';

const nestedNav = [
  {
    name: 'Directory',
    icon: FolderOpenIcon,
  },
  {
    name: 'Add Project',
    icon: FolderAddIcon,
  },
];

export default function ProjectsDirectoryAdminMenu() {
  const [current, nestedNavComponent] = useNestedNav(nestedNav);

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full gap-y-4">
      {nestedNavComponent}
      {current == 0 && <ProjectsDirectoryAdminView />}
      {current == 1 && <AddNewProject />}
    </div>
  );
}
