import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SearchState = {
  show: boolean;
};

const initialState: SearchState = {
  show: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    toggleSearch: (state, action: PayloadAction<Partial<SearchState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleSearch } = searchSlice.actions;

export default searchSlice.reducer;
