import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QueryPersonDetails } from '../graphql/people';
import PageContainer from '../custom-prebuilt/PageContainer';
import UserOverview from '../components/profile/overview/UserOverview.component';
import UserDetails from '../components/profile/overview/UserDetails.component';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useAuth } from '../lib/authContext';

const Profile = () => {
  const [personDetails, setPersonDetails] = useState(null);
  const location = useLocation();
  const profileEmail = location.pathname.split('/').at(1);
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const { userEmail } = useAuth();
  const navigate = useNavigate();

  const queryPersonDetails = useQuery(QueryPersonDetails, {
    variables: {
      where: { email: profileEmail + '.com' || '' },
      categoryConnectionWhere2: { node: { type: 'Category' } },
      experienceConnectionWhere2: { node: { name_NOT_STARTS_WITH: 'JG' } },
      skillsConnectionWhere2: { node: { active: true } },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  useEffect(() => {
    queryPersonDetails.data?.people &&
      setTimeout(() => {
        setPersonDetails(queryPersonDetails.data?.people[0]);
      }, 0); // setTimeout for loading animation purpose
  }, [queryPersonDetails.data]);

  return (
    <PageContainer
      action={() => navigate('/settings/personal')}
      actionName={userEmail.includes(profileEmail) ? 'User Settings' : null}
      title="Profile"
    >
      <div className="h-fit lg:h-full-init w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-[33%_67%] lg:space-x-5 gap-y-5 lg:gap-y-0">
        <UserOverview personDetails={personDetails} />
        <UserDetails personDetails={personDetails} />
      </div>
    </PageContainer>
  );
};

export default Profile;
