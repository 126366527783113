import { useNavigate, useLocation } from 'react-router';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import MultipleChoiceBlock from './utils/MultipleChoiceBlock.component';
import { teamOptions } from './utils/blockOptions';
import { Fragment, useEffect } from 'react';
import Blocks from './7CTOsBlocks.component';
import { toggleAssessment } from '../../../../redux/assessmentSlice';
import { useDispatch } from 'react-redux';
import PageContainer from '../../../../custom-prebuilt/PageContainer';

const Assessment = ({ assessment, stage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [parent] = useAutoAnimate({ duration: 150 });
  const dispatch = useDispatch();

  const handleNavigateNav = (number) => {
    navigate(`${location.pathname}?${number}`);
  };

  useEffect(() => {
    stage !== 2 && dispatch(toggleAssessment({ teamAnswers: [] }));
  }, [stage, dispatch]);

  return (
    <PageContainer title={`Assessment: ${assessment?.data?.assessments[0]?.name}`}>
      <div className="lg:flex lg:flex-row">
        <aside className="h-fit lg:h-full border-r border-gray-200">
          <div className="flex flex-col pt-5 pb-4 space-y-1 px-2">
            <p
              className={`text-gray-600 hover:bg-gray-200 hover:text-gray-900 group flex items-center px-2 py-2 text-xs font-medium
          rounded-md transition duration-300 cursor-pointer pr-12 ${stage == 1 && 'bg-gray-200'}`}
              onClick={() => {
                handleNavigateNav(1);
              }}
            >
              General info
            </p>
            <p
              className={`text-gray-600 hover:bg-gray-200 hover:text-gray-900 group flex items-center px-2 py-2 text-xs font-medium
          rounded-md transition duration-300 cursor-pointer pr-12 ${stage == 2 && 'bg-gray-200'}`}
              onClick={() => {
                handleNavigateNav(2);
              }}
            >
              Details
            </p>
          </div>
        </aside>

        {/* CONTENT */}
        <aside
          ref={parent}
          className="w-full lg:max-w-lg h-fit lg:h-full overflow-y-auto pt-5 pb-4 px-5 space-y-4 border-r border-gray-200"
        >
          {stage == 1 && (
            <>
              <div className="px-5 py-6 bg-white w-full shadow rounded-lg">
                <label htmlFor="company-name" className="block text-xs font-medium text-gray-700">
                  What is your company name?
                </label>
                <span className="text-xs text-gray-500">
                  If you wish to keep this anonymous, feel free to use a pseudonym.
                </span>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company-name"
                    id="company-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs
                max-w-sm"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="px-5 py-6 bg-white w-full shadow rounded-lg">
                <label
                  htmlFor="company-industry"
                  className="block text-xs font-medium text-gray-700"
                >
                  What industry is your company operating in?
                </label>
                <span className="text-xs text-gray-500">eg. Fintech, Healthtech, etc.</span>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company-industry"
                    id="company-industry"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs
                max-w-sm"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="px-5 py-6 bg-white w-full shadow rounded-lg">
                <label htmlFor="Company-name" className="block text-xs font-medium text-gray-700">
                  What is your role at your company?
                </label>
                <span className="text-xs text-gray-500">eg. CTO, VPE, CEO, etc.</span>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company-name"
                    id="company-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs
                max-w-sm"
                    autoComplete="off"
                  />
                </div>
              </div>
            </>
          )}

          {teamOptions.map((option, index) => (
            <Fragment key={index}>
              <MultipleChoiceBlock options={option} stage={stage} />
            </Fragment>
          ))}
        </aside>
        {stage == 2 && <Blocks stage={stage} />}
      </div>
    </PageContainer>
  );
};

export default Assessment;
