import SimpleSection from '../../../../../shared/SimpleSection.component';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import { useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAssessment } from '../../../../../../redux/assessmentSlice';
import { TextArea, Button } from '../../../../../../custom-prebuilt/common.component';
import LoadingState from '../../../../../../custom-prebuilt/preloader/LoadingState.component';
import { Fragment, useEffect, useMemo, useState } from 'react';
import ClickNHoldSkill from '../../../../../../custom-prebuilt/ClickNHoldSkill.component';

const AssessmentProjectDescription = () => {
  const loading = useSelector((state) => state.assessment.loading);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const globalSelectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const globalLoading = useSelector((state) => state.assessment.loading);
  const [personSkills, setPersonSkills] = useState([]);
  useEffect(() => {
    if (globalLoading) return;
    setPersonSkills(globalSelectedSkills);
  }, [globalLoading]);

  // const selectedSkills = useSelector(state => state.assessment.selectedSkills)
  const projectDescription = useSelector((state) => state.assessment.projectDescription);

  const handleInputChange = (e) => {
    dispatch(toggleAssessment({ projectDescription: e.target.value }));
  };

  const selectedSkillComponent = useMemo(() => {
    return (
      <>
        <div ref={parent} className="w-full h-full flex flex-row flex-wrap">
          {globalLoading ? (
            <LoadingState />
          ) : personSkills.length ? (
            [...personSkills].map((skill, index) => (
              <Fragment key={index}>
                {skill?.node && (
                  <div className="w-14" data-tip={skill.node.name} data-for="skill">
                    <ClickNHoldSkill skill={skill?.node} disable />
                  </div>
                )}
              </Fragment>
            ))
          ) : (
            <p className="text-xs text-gray-500">You don&apos;t have any rated skills yet</p>
          )}
        </div>
      </>
    );
  }, [personSkills, parent]);

  return (
    <div className="h-full w-full xl:pl-4">
      <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-5 h-full">
        <div className="flex flex-col space-y-5 flex-1">
          <SimpleSection>
            <p className="text-xs mb-4 font-semibold">Technology Description</p>
            <p className="text-xs mb-3">
              Please take a moment to write a brief description of the skills and technologies used
              on the project.
            </p>
            <div className="flex flex-row space-x-1">
              <Button
                className="w-fit"
                onClick={() => {
                  navigate(`${getLocationPrevLevel(location)}/rate`);
                }}
              >
                Back
              </Button>
              <Button
                className="w-fit"
                onClick={() => {
                  navigate(`${getLocationPrevLevel(location)}/confirmation`);
                }}
              >
                Confirmation
              </Button>
            </div>
          </SimpleSection>

          <SimpleSection>
            <h3 className="text-xs font-medium">Description</h3>
            {loading ? (
              <LoadingState />
            ) : (
              <TextArea
                className="resize h-24"
                value={projectDescription}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            )}
          </SimpleSection>
        </div>
        <div
          className="w-[30%] max-w-md hidden xl:block overflow-y-auto overflow-x-hidden"
          data-cy="selected-skills"
        >
          <SimpleSection>
            <p className="text-xs font-medium mb-3">Selected skills</p>
            {selectedSkillComponent}
          </SimpleSection>
        </div>
      </div>
    </div>
  );
};

export default AssessmentProjectDescription;
