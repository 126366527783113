import { useProfileGeneratorInfo } from '../state/ProfileGeneratorContext';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef, useEffect } from 'react';
import { updateState, selectField } from '../state/profileGeneratorSlice';
import { mergeClasses } from '../../../lib/classNames';
import { isRefEmpty } from '../../../lib/isRefEmpty';
import AutoSizeInput from '../../../custom-prebuilt/AutoSizeInput';

interface InputWrapperProps {
  title: string;
  isEmpty: boolean;
  isFocused: boolean;
  children: JSX.Element | JSX.Element[];
  hideTitle: boolean;
  selectField: () => void;
}

const InputWrapper = ({
  title,
  isEmpty = false,
  isFocused,
  children,
  hideTitle = false,
  selectField,
}: InputWrapperProps) => {
  return (
    <div className="relative w-min">
      {!hideTitle && (
        <div className="absolute top-[calc(100%_+_8px)] z-[51] bg-white shadow-md rounded-md">
          {isFocused && (
            <p className="text-base font-medium text-[#1F2938] m-2 font-['Libre_Franklin']">
              {title}
            </p>
          )}
        </div>
      )}
      {isEmpty && (
        <div
          onClick={selectField}
          style={{ clipPath: 'circle()' }}
          className="absolute right-[calc(100%_+_8px)] z-[52] w-3 h-3 bg-red-400"
        />
      )}
      {children}
    </div>
  );
};

interface InputProps {
  path: string;
  index: number;
  onChange: (newValue: string, index: number) => void;
  className: string;
  type: string;
  styleName: string;
  title: string;
  autoSize: boolean;
  hideTitle: boolean;
  relevantFields: string[];
}

export const InputClasses =
  'relative bg-transparent disabled:opacity-75 outline-none cursor-default overflow-hidden resize-none ';
export const InputFocusClasses =
  'z-50 outline rounded-sm outline-offset-[7px] outline-2 outline-[#000]';

/**
 * @param path the combination of the sectionPath and the fieldPath
 */
const Input = ({
  path,
  title,
  onChange,
  index,
  className,
  autoSize = false,
  relevantFields,
  type,
  hideTitle,
}: InputProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const profileData = useSelector((state: any) => state.profileGenerator.profileData);
  const clipBoard = useSelector((state: any) => state.profileGenerator.clipBoard);
  const selectedField = useSelector((state: any) => state.profileGenerator.selectedField);
  const isThisFocused = useMemo(() => {
    if (typeof index !== 'undefined')
      return selectedField.path === path && selectedField.index === index;
    return selectedField.path === path;
  }, [path, index, selectedField]);
  const dispatch = useDispatch();
  const { getValue, getStyle } = useProfileGeneratorInfo();

  const onChangeInternalWrapper = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeInternal(event.target.value);
  };

  const onChangeInternal = (newValue: string) => {
    if (typeof onChange !== 'undefined') onChange(newValue, index);
    else dispatch(updateState({ path, index, newValue }));
  };

  const value = useMemo(() => getValue(path, index), [profileData]);
  const styleValue = useMemo(() => getStyle(path, index), [profileData]);

  // taken from https://codesandbox.io/s/autosize-textarea-owwtu?from-embed=&file=/src/useAutosizeTextArea.ts:128-186
  useEffect(() => {
    if (autoSize && !isRefEmpty(textAreaRef)) {
      // @ts-ignore
      textAreaRef.current.style.height = '0px';
      // @ts-ignore
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  }, [value, textAreaRef, styleValue]);

  useEffect(() => {
    if (!autoSize && !isRefEmpty(textAreaRef)) {
      // @ts-ignore
      textAreaRef.current.scrollLeft = 0;
      // @ts-ignore
      textAreaRef.current.scrollTop = 0;
    }
  }, [selectedField]);

  const focus = () => {
    dispatch(selectField({ path, index, relevantFields, type }));
  };

  const onRightClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    if (clipBoard !== '') {
      event.preventDefault();
      onChangeInternal(clipBoard);
    }
  };

  return (
    <InputWrapper
      title={title}
      isFocused={isThisFocused}
      hideTitle={hideTitle}
      isEmpty={value === ''}
      selectField={focus}
    >
      <>
        {autoSize && (
          <AutoSizeInput
            ref={textAreaRef}
            onContextMenu={onRightClick}
            onFocus={focus}
            style={styleValue}
            onChange={onChangeInternalWrapper}
            className={mergeClasses(
              InputClasses,
              className,
              isThisFocused ? InputFocusClasses : '',
            )}
            dependencyArray={[value, styleValue]}
            value={value}
          />
        )}
      </>
      <>
        {!autoSize && (
          <textarea
            ref={textAreaRef}
            onContextMenu={onRightClick}
            onFocus={focus}
            value={value}
            style={styleValue}
            onChange={onChangeInternalWrapper}
            className={mergeClasses(
              InputClasses,
              className,
              isThisFocused ? InputFocusClasses : '',
            )}
          />
        )}
      </>
    </InputWrapper>
  );
};

export default Input;
