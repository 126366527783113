import { gql } from '@apollo/client';

export const QueryOrgAdmins = gql`
  query QueryOrgAdmins(
    $where: OrganizationWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    organizations(where: $where) {
      hasAdmin {
        email
        name
        userIconUrl
        orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
          edges {
            position
          }
        }
      }
    }
  }
`;

export const CheckOrgAdmin = gql`
  query CheckOrgAdmin($where: PersonWhere, $organizationsWhere2: OrganizationWhere) {
    organizations(where: $organizationsWhere2) {
      hasAdmin(where: $where) {
        email
      }
    }
  }
`;

export const QueryCompany = gql`
  query QueryCompany(
    $where: OrgUnitWhere
    $membersConnectionWhere2: OrgUnitMembersConnectionWhere
    $first: Int
    $sort: [OrgUnitMembersConnectionSort!]
  ) {
    orgUnits(where: $where) {
      memberCount
      logo
      locationCity
      locationState
      locationZip
      motto
      name
      website
      membersConnection(where: $membersConnectionWhere2, first: $first, sort: $sort) {
        edges {
          position
          node {
            userIconUrl
            name
            email
          }
        }
      }
    }
  }
`;

/*
Variables required for calling QueryCompany
{
  "where": null,
  "membersConnectionWhere2": {
    "node": {
      "active": true
    }
  },
  "first": null,
  "sort": null
}
*/

export const QueryOrg = gql`
  query QueryOrg($where: OrganizationWhere) {
    organizations(where: $where) {
      uid
      name
      photoURL
      timestamp
      motto
    }
  }
`;

export const UpdateAdmin = gql`
  mutation UpdateAdmin($where: PersonWhere, $update: PersonUpdateInput) {
    updatePeople(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

export const ConnectPersonToOrgUnit = gql`
  mutation ConnectPersonToOrgUnit($where: PersonWhere, $connect: PersonConnectInput) {
    updatePeople(where: $where, connect: $connect) {
      info {
        relationshipsCreated
      }
    }
  }
`;

export const CheckConnectionPersonToOrg = gql`
  query CheckConnectionPersonToOrg(
    $personWhere: PersonWhere
    $orgUnitsConnectionWhere: PersonOrgUnitsConnectionWhere
  ) {
    peopleConnection(where: $personWhere) {
      edges {
        node {
          orgUnitsConnection(where: $orgUnitsConnectionWhere) {
            edges {
              startDate
            }
          }
        }
      }
    }
  }
`;

export const QueryAllOrgUnits = gql`
  query QueryAllOrgUnits($where: OrganizationWhere) {
    organizations(where: $where) {
      orgUnit {
        logo
        name
        motto
        orgUnit_id
        locationZip
        locationState
        locationCity
        status
        website
        memberCount
      }
    }
  }
`;
/*
{
  "where": {
    "uid": null
  }
}
*/

export const UpdateOrgUnit = gql`
  mutation UpdateOrgUnit($where: OrgUnitWhere, $update: OrgUnitUpdateInput) {
    updateOrgUnits(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

/*
Variables required for calling UpdateOrgUnit
{
  "where": {
    "orgUnit_id": null
  },
  "update": {
    "name": null,
    "motto": null,
    "website": null,
    "locationCity": null,
    "locationState": null,
    "locationZip": null
  }
}
*/

export const CreateOrgUnit = gql`
  mutation CreateOrgUnit($where: OrganizationWhere, $create: OrganizationRelationInput) {
    updateOrganizations(where: $where, create: $create) {
      info {
        nodesCreated
      }
    }
  }
`;

/*
{
  "where": {
    "uid": null
  },
  "create": {
    "orgUnit": [
      {
        "node": {
          "orgUnit_id": null,
          "name": null,
          "motto": null,
          "locationCity": null,
          "locationState": null,
          "locationZip": null,
          "logo": null,
          "status": null,
          "website": null
        }
      }
    ]
  }
}
*/

//use this to delete OrgUnit with no members
export const DeleteEmptyOrgUnit = gql`
  mutation DeleteEmptyOrgUnit($where: OrgUnitWhere) {
    deleteOrgUnits(where: $where) {
      nodesDeleted
    }
  }
`;
/*
{
  "where": {
    "orgUnit_id": null
  }
}
*/

//use this is delete OrgUnit with members
export const RemoveOrgUnit = gql`
  mutation RemoveOrgUnit($input: [DeleteOrgUnitInput!]!, $orgId: ID!) {
    removeOrgUnit(input: $input, orgId: $orgId)
  }
`;

/*
Variables required for calling RemoveOrgUnit
{
  "input": non-empty array of the form [{"email": null, "inactivate": null, "transfer": null}],
  "orgId": null
}
*/
