import {
  Button,
  H2,
  Input,
  Label,
  Legend,
  Select,
  TextArea,
} from '../../custom-prebuilt/common.component';
import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { useAuth } from '../../lib/authContext';
import { QueryPersonDetails, UpdatePersonDetails, QueryPersonBasics } from '../../graphql/people';
import { toast } from 'react-hot-toast';
import defaultImg from '../../assets/default-user.svg';
import { Amplify, Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import ImageInput from '../../custom-prebuilt/ImageInput';

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const isFile = (object) => object instanceof File;

/**
 * Settings section for editing a user's personal information
 * @returns form for editing a user's personal information
 */
const SettingsPersonal = () => {
  const currentAuth = useAuth();

  const [profileImage, setProfileImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [bday, setBday] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const updatePersonImages = (data) => {
    setProfileImage(data?.people?.[0]?.userIconUrl ?? defaultImg);
    setBannerImage(data?.people?.[0]?.userBannerUrl ?? defaultImg);
  };

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QueryPersonDetails, {
    variables: { where: { email: currentAuth.userEmail } },
    skip: !currentAuth.userEmail,
    onCompleted: updatePersonImages,
  });

  const [updateSettingsPersonal, { loading: updateLoading, error: updateError }] = useMutation(
    UpdatePersonDetails,
    {
      refetchQueries: () => [QueryPersonBasics, 'QueryPersonDetails'],
      awaitRefetchQueries: true,
    },
  );

  // Error handling?
  if (error || updateError) {
    console.error(error, updateError);
  }

  const loading = queryLoading || updateLoading;

  const updateFields = (userValue) => {
    if (userValue.name !== null) setName(userValue.name);
    if (userValue.biography !== null) setBio(userValue.biography);
    if (userValue.birthdate !== null) setBday(userValue.birthdate);
    if (userValue.locationCity !== null) setCity(userValue.locationCity);
    if (userValue.locationState !== null) setState(userValue.locationState);
    if (userValue.locationZip !== null) setZip(userValue.locationZip);
  };

  useEffect(() => {
    const user = data?.people?.[0];
    if (user) {
      updateFields(user);
    }
  }, [data]);

  const resizeFile = async (file, maxWidth, maxHeight, quality) => {
    const compressionFormat = file.name.split('.').pop();
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file, // Is the file of the image which will resized.
          maxWidth, // Is the maxWidth of the resized new image.
          maxHeight, // Is the maxHeight of the resized new image.
          compressionFormat, // Is the compressFormat of the resized new image.
          quality, // Is the quality of the resized new image.
          0, // Is the degree of clockwise rotation to apply to uploaded image.
          (uri) => {
            // Is the callBack function of the resized new image URI.
            resolve(uri);
          },
          'file', // Is the output type of the resized new image. Can be either base64, blob or file.(Default type is base64)
          0, // Is the minWidth of the resized new image.
          0, // Is the minHeight of the resized new image.
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const onSaveOperation = async () => {
    // Save to Public Assets S3 Bucket
    // Save Resized and compressed Images
    let imageUpdateParams = {};
    if (isFile(profileImage)) {
      try {
        const resizedProfileImage = await resizeFile(profileImage, 300, 300, 100);
        await Storage.put(
          `users/${currentAuth.cognitoUser.username}/profile-${profileImage.name}`,
          resizedProfileImage,
          {
            // metadata: { key: 'value' },
            level: 'public',
            bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
            // progressCallback(progress) {
            //   console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            // },
          },
        );
        const profileImageURL = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/users/${currentAuth.cognitoUser.username}/profile-${profileImage.name}`;
        imageUpdateParams.userIconUrl = profileImageURL;
      } catch (error) {
        console.log('Error uploading file: ', error);
      }
    }
    // Save to Public Assets S3 Bucket
    // Save Resized and compressed Images
    if (isFile(bannerImage)) {
      try {
        const resizedProfileImage = await resizeFile(bannerImage, 1000, 240, 'jpeg', 100);
        await Storage.put(
          `users/${currentAuth.cognitoUser.username}/banner-${bannerImage.name}`,
          resizedProfileImage,
          {
            // metadata: { key: 'value' },
            level: 'public',
            bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
            // progressCallback(progress) {
            //   console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            // },
          },
        );
        imageUpdateParams.userBannerUrl = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public/users/${currentAuth.cognitoUser.username}/banner-${bannerImage.name}`;
      } catch (error) {
        console.log('Error uploading file: ', error);
      }
    }

    return await updateSettingsPersonal({
      variables: {
        where: { email: currentAuth.userEmail },
        update: {
          name: name,
          biography: bio,
          locationCity: city,
          locationState: state,
          locationZip: zip,
          birthdate: bday,
          ...imageUpdateParams,
        },
      },
    });
  };

  const onSave = () => {
    const onSavePromise = onSaveOperation();
    toast.promise(onSavePromise, {
      success: 'Personal settings saved!',
      error: 'An error occurred!',
    });
  };

  return (
    <>
      {!data ? (
        <LoadingState />
      ) : (
        <div className="flex flex-wrap h-full overflow-y-auto">
          {/* columns */}
          {/* column 1 */}
          <div className="grow basis-full lg:basis-1/2 flex flex-col p-4">
            <H2>Personal Information</H2>
            <Label>
              Name
              <Input
                disabled={loading}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>

            <Label>
              Biography
              <TextArea
                rows="8"
                disabled={loading}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </Label>
            <Legend>
              A brief description about who you are. This will be visible to others in the
              organization.
            </Legend>

            <Label>
              Birthday
              <Input
                type="date"
                disabled={loading}
                value={bday}
                onChange={(e) => setBday(e.target.value)}
              />
            </Label>

            <div className="flex space-x-2">
              {/* row */}
              <Label>
                City
                <Input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={loading}
                />
              </Label>

              <Label>
                State
                <Select value={state} onChange={(e) => setState(e.target.value)} disabled={loading}>
                  {states.map((s, index) => (
                    <option key={index} value={s}>
                      {s}
                    </option>
                  ))}
                </Select>
              </Label>

              <Label>
                Zip
                <Input
                  type="text"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  disabled={loading}
                />
              </Label>
            </div>
            <Legend>Location information will only be showed as an approximation on a map.</Legend>
          </div>

          {/* column 2 */}
          <div className="grow basis-full lg:basis-1/2 flex flex-col p-4 mt-8">
            <ImageInput
              image={profileImage}
              setImage={setProfileImage}
              id={'profile'}
              changeButtonText={'Change Photo'}
              fieldLabel={<Label>Profile Photo</Label>}
              enableCropper
              cropAspect={1}
              cropShape={'round'}
              contentWrapperClasses={'flex my-4 items-center gap-6'}
              displayImageClasses={'h-[84px] w-[84px] rounded-full bg-white'}
            />

            <ImageInput
              image={bannerImage}
              setImage={setBannerImage}
              id={'banner'}
              fieldLabel={<Label>Banner Image</Label>}
              enableCropper
              cropShowGrid
              cropAspect={20 / 5}
              cropShape={'rect'}
              changeButtonText={'Change Banner'}
              contentWrapperClasses={'flex flex-col my-4 gap-4'}
              displayImageClasses={'w-full object-cover bg-white'}
            />
            <Button
              className="self-end mt-14"
              onClick={(e) => {
                onSave();
                e.target.blur();
              }}
              disabled={loading}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsPersonal;
