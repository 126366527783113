import { Switch } from '@headlessui/react';

export type AvailabilityToggleProps = {
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};
/**
 *
 * @param {*} enable Toggle enabled state
 * @param {*} setEnabled setState function for enabled
 * @returns
 */
export default function AvailabilityToggle(props: AvailabilityToggleProps) {
  const { enabled, setEnabled } = props;

  return (
    <div className="flex items-center gap-3">
      <span className="text-xs text-gray-700">Availability</span>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent 
        transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 
        focus:ring-offset-2 ${enabled ? 'bg-indigo-600' : 'bg-gray-200'}`}
      >
        <span
          aria-hidden="true"
          className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition 
        duration-200 ease-in-out ${enabled ? 'translate-x-5' : 'translate-x-0'}`}
        />
      </Switch>
    </div>
  );
}
