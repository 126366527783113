/* eslint-disable */
import { mergeClasses } from '../lib/classNames';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Card from '../components/shared/Card.component';
import PersonCard from '../components/shared/PersonCard.component';
import { useRef, useEffect, useState, useMemo } from 'react';
import { OfficeBuildingIcon, LocationMarkerIcon, CalendarIcon } from '@heroicons/react/outline';
import { SlackOutlined } from '@ant-design/icons';
import { Button } from './common.component';
import { useDispatch } from 'react-redux';
import { togglePopOver } from '../redux/popOverSlice';
import { useNavigate } from 'react-router';
import { formatDate } from '../lib/formatDate';

type PopOverProps = {
  className?: string;
} & Record<string, any>;

type PersonData = {
  biography?: string | null;
  birthdate?: string | null;
  email?: string;
  google_workLocation?: string | null;
  linkedIn?: string | null;
  locationState?: string | null;
  name?: string;
  personalWebsite?: string | null;
  phone?: string | null;
  slackUsername?: string | null;
  orgUnitsConnection?: any;
  userIconUrl?: string;
};

const POPOVER_MIN_HEIGHT = '!max-h-[265px]';

/**
 * A popover component that displays information about a person when hovering over a target element.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} [props.className] - Additional CSS classes to apply to the component.
 * @return {JSX.Element} - The component's rendered elements.
 */
const PopOver = (props: PopOverProps) => {
  const dispatch = useDispatch();
  const { className, ...rest } = props;
  const { show, position, data, targetHeight } = useSelector((state: RootState) => state.popOver);
  const [parent] = useAutoAnimate<HTMLDivElement>({ duration: 0 });
  const [popOverHeight, setPopOverHeight] = useState(350);
  const [filteredData, setFilteredData] = useState({});
  const elementRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleHover = () => {
      dispatch(togglePopOver({ popOverHover: true }));
    };

    const handleLeave = () => {
      dispatch(
        togglePopOver({
          popOverHover: false,
          show: false,
          targetType: '',
          data: null,
          targetHeight: 0,
        }),
      );
    };

    const element = document.getElementById('popover');

    // @ts-ignore
    element.addEventListener('mouseenter', handleHover);
    // @ts-ignore
    element.addEventListener('mouseleave', handleLeave);

    return () => {
      // @ts-ignore
      element.removeEventListener('mouseenter', handleHover);
      // @ts-ignore
      element.removeEventListener('mouseleave', handleLeave);
    };
  }, []);

  const mapKeyToIcon = (key: String) => {
    switch (key) {
      case (key = 'google_workLocation'):
        return <OfficeBuildingIcon className="h-4 w-4" />;
      case (key = 'locationState'):
        return <LocationMarkerIcon className="h-4 w-4" />;
      case (key = 'slackUsername'):
        return <SlackOutlined className="w-4" />;
      case (key = 'startDate'):
        return <CalendarIcon className="h-4 w-4" />;
      default:
        return;
    }
  };

  // useEffect(() => {
  //   if (elementRef.current) {
  //     const { clientHeight } = elementRef.current;
  //     setPopOverHeight(clientHeight);
  //   }
  // }, [show, filteredData, elementRef]);

  const filterPersonData = (data: PersonData): PersonData => {
    // @ts-ignore
    const filtered = {
      startDate: !!data?.orgUnitsConnection?.edges?.[0]?.startDate
        ? formatDate(data?.orgUnitsConnection?.edges?.[0]?.startDate)
        : null,
      google_workLocation: data?.google_workLocation,
      locationState: data?.locationState,
      slackUsername: data?.slackUsername,
    };

    return filtered;
  };

  const shouldDisplayPopOverBottom = useMemo(() => position.y - popOverHeight < 0, [position]);
  // const shouldDisplayPopOverBottom = true

  useEffect(() => {
    if (data !== null) {
      setFilteredData(filterPersonData(data));
    } else {
      setFilteredData({});
    }
  }, [data]);

  const handleNavigateProfile = () => {
    // @ts-ignore
    navigate(`/${data.email.split('.')[0]}`);
  };

  return (
    <div
      ref={parent}
      id="popover"
      className={mergeClasses(
        `fixed h-fit z-[500] bg-transparent w-[12rem] lg:w-[16rem] xl:w-[20rem] !pb-[10px] hidden lg:block ${
          !shouldDisplayPopOverBottom && '-translate-y-[100%]'
        }`,
        className,
      )}
      style={{
        left: `calc(${position.x}px - 35px)`,
        top: `${
          shouldDisplayPopOverBottom
            ? `calc(${position.y}px + ${targetHeight}px + 8px)`
            : `calc(${position.y}px)`
        }`,
      }}
      {...rest}
    >
      <div ref={elementRef}>
        {show && (
          <Card className={`border border-bg-med !shadow-2xl text-sm`}>
            <>
              {/* @ts-ignore */}
              <PersonCard className="!shadow-none !px-0 !pb-2" personBasics={data} disableHover />
              {/* @ts-ignore */}
              {data?.orgUnitsConnection?.edges?.[0]?.position && (
                <p className="text-font-dark text-xs font-medium line-clamp-3 mt-1">
                  {data?.orgUnitsConnection?.edges?.[0]?.position}
                </p>
              )}
              {data?.orgUnitsConnection?.edges?.[0]?.node?.name && (
                <p className="text-font-dark text-xs font-normal line-clamp-3 mt-1">
                  {data?.orgUnitsConnection?.edges?.[0]?.node?.name}
                </p>
              )}
              <div className="flex flex-col space-y-1 mt-2 text-font-light font-medium">
                {Object.keys(filteredData)?.map((key) => {
                  const icon = mapKeyToIcon(key);
                  return (
                    <div key={key}>
                      {
                        // @ts-ignore
                        filteredData?.google_workLocation && (
                          <div className="text-xs flex flex-row items-center space-x-1">
                            {icon}
                            {/* @ts-ignore */}
                            <span>{filteredData?.[key] ?? 'N/A'}</span>
                          </div>
                        )
                      }
                    </div>
                  );
                })}
              </div>
              <Button onClick={handleNavigateProfile} className="w-full mt-3">
                Full Profile
              </Button>
            </>
          </Card>
        )}
      </div>
    </div>
  );
};

export default PopOver;
