import { InformationCircleIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import { mergeClasses } from '../lib/classNames';

/**
 * A modal that will be displayed upon all other contents
 * @param title help info title
 * @param children help info content
 * @param additionalHelpOnClick a callback function for additional help
 * @param content Modal content
 * @param parentRef refernce to parent element. used to accomodate useAutoAnimate
 */
export type HelpInformationProps = {
  title: ReactNode | undefined;
  children: ReactNode | undefined;
  additionalHelpOnClick?: (e: any) => void;
  parentRef?: React.LegacyRef<HTMLDivElement> | undefined;
  className?: string;
  titleClassName?: string;
};

const HelpInformation = ({
  title,
  children,
  additionalHelpOnClick,
  parentRef,
  titleClassName,
  className,
}: HelpInformationProps) => {
  const hasAdditionalHelp = typeof additionalHelpOnClick !== 'undefined';
  return (
    <div
      ref={parentRef}
      className={mergeClasses('px-4 py-5 shadow bg-white rounded-md w-full', className)}
    >
      <div className="flex flex-row justify-between items-center">
        <h2 className={mergeClasses('text-xs mb-4 font-semibold', titleClassName)}>{title}</h2>
        <InformationCircleIcon
          className={mergeClasses('h-6 w-6', hasAdditionalHelp ? 'cursor-pointer' : '')}
          onClick={additionalHelpOnClick}
        />
      </div>
      {children}
    </div>
  );
};

export default HelpInformation;
