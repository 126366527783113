import { successToastMid } from '../../../lib/toast';

// copied from https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
const copy = (content) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(content);
    successToastMid('Copied to clipboard');
  } else {
    let textArea = document.createElement('textarea');
    textArea.value = content;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(content);
    textArea.focus();
    textArea.select();
    new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
      successToastMid('Copied to clipboard');
    });
  }
};

const toTitle = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const makeCopyOfObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

const camelCaseToTitle = (str) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  const strTitle =
    result
      .replace(/([A-Z])/g, ' $1')
      .charAt(0)
      .toUpperCase() + result.slice(1);
  return strTitle;
};

/**
 * Converts a path to an array of path items
 * @param path the path to be converted
 */
export const pathToArray = (path) => {
  if (typeof path === 'undefined') return [];
  // converts path to array
  let pathArray = path.split('.');
  // removes first .
  pathArray.shift();
  return pathArray;
};

const isObject = (item) => typeof item == 'object' && item !== null;

/**
 * @param connectDataLeft the connect data that still needs to be added to the parents data
 * @param parentData a link to the parents trimmed connect data
 */
const cleanConnectDataRecursively = (connectDataLeft, parentData) => {
  let curIndex;
  // if the parents data is an array
  // why is this the length?
  if (Array.isArray(parentData)) curIndex = parentData.length;
  // iterate through the attribute names within the connectData that's left
  // we can do this because within the connect data there is no array of priminitives
  // only arrays of objects
  for (let attributeName of Object.keys(connectDataLeft)) {
    // get the current attribute data
    const attributeData = connectDataLeft[attributeName];
    // remove certain attributes added by GraphQL including __typename, edges, node
    // remove __typename
    if (attributeName === '__typename') continue;
    // if the attributeName is edges then
    // we need to remove this attribute and shift its children up
    // original
    // if (attributeName === 'edges') {
    // do we ever reach this?
    else if (attributeName === 'orgUnitsConnection') {
      const curOrgData = connectDataLeft.orgUnitsConnection.edges.at(-1);
      const position = curOrgData?.position ?? 'No Position';
      parentData['position'] = position;
    } else if (attributeName === 'edges') {
      // iterate through each edge and clean them
      attributeData.forEach((edgeItem) => {
        let currentAttributesData = {};
        cleanConnectDataRecursively(edgeItem, currentAttributesData);
        // add cleaned edge to the parent's data
        // this is an array because further down in this function
        // when we see the edges attribute we make the parentData an array
        if (Array.isArray(parentData)) parentData.push(currentAttributesData);
        else {
          for (const key in currentAttributesData) {
            parentData[key] = currentAttributesData[key];
          }
        }
      });
      // recursive branch ends here because when a attribute has the
      // edges attribute, it is the only attribute
      return;
    }
    // if the attributeName is node remove this attribute and clean its children
    else if (attributeName === 'node') {
      cleanConnectDataRecursively(attributeData, parentData);
    }
    // if we are looking at a object or an array then
    else if (isObject(attributeData)) {
      // if the attribute data is an array
      if (Array.isArray(attributeData)) {
        // then the current attributes children will be an array
        let children = [];
        // iterate through each attribute and clean them
        attributeData.forEach((item) => {
          cleanConnectDataRecursively(item, children);
        });
        // update the parent
        parentData[attributeName] = children;
      }
      // the attribute is an object
      else {
        let children;
        // if the attribute contains the edges attribute then
        // it is the only attribute within this object and we ignore it
        if (Object.keys(attributeData).includes('edges')) children = [];
        else children = {};

        // if the parents data is an array
        if (typeof curIndex !== 'undefined') {
          cleanConnectDataRecursively(attributeData, children);
          parentData[curIndex][attributeName] = children;
        }
        // the parents data is not an array
        else {
          cleanConnectDataRecursively(attributeData, children);
          parentData[attributeName] = children;
        }
      }
    }
    // the value is a priminitive
    else {
      if (Array.isArray(parentData)) {
        if (parentData.length === curIndex) {
          parentData.push({ [attributeName]: attributeData });
        } else parentData[curIndex] = { ...parentData[curIndex], [attributeName]: attributeData };
      } else parentData[attributeName] = attributeData;
    }
  }
  // end the recursive branch
  return;
};

/**
 * @param connectData
 */
const cleanConnectData = (connectData) => {
  let newConnectData = {};
  cleanConnectDataRecursively(connectData, newConnectData);
  return newConnectData;
};

/**
 * @param pathArray the array representation of a path
 * @param connectData a users connect data or a portion of it
 * depending on what recursive branch we are on
 */
const getConnectDataRecursively = (pathArray, connectData) => {
  // original
  // let currentData = connectData
  // make copy of pathArray
  let currentPath = [...pathArray];
  // iterate through path
  for (let pathItem of pathArray) {
    if (typeof connectData === 'undefined') return connectData;
    // original
    // if(Array.isArray(connectData)) {
    //   return connectData.map(item => {
    //       return {[pathItem] : getConnectDataRecursively(currentPath, item)}
    //   })
    // }
    // if the connect data is currently an array
    if (Array.isArray(connectData)) {
      // if we are trying to get an array return the array
      // if (currentPath.length === 1) return connectData
      // else we are trying to get only certain attributes from the objects in an array
      // so we must search recursively to get it
      // else {
      currentPath.shift();
      return connectData.map((item) => getConnectDataRecursively(currentPath, item[pathItem]));
      // }
    }
    // otherwise we update the connectData
    else {
      connectData = connectData[pathItem];
      currentPath.shift();
    }
    // we update the currentPath incase we need to create a recursive branch
    // from our current position
  }
  return connectData;
};

/**
 * Gets connect data via a path
 * @param path the path where each attribute is seperated via a .
 * @param connectData
 */
const getConnectData = (path, connectData) => {
  return getConnectDataRecursively(pathToArray(path), connectData);
};

const createStyleString = (styles) => {
  if (typeof styles === 'undefined') return '';
  let styleString = '';
  for (const key of Object.keys(styles)) {
    styleString += `${key}:${styles[key]};`;
  }
  return styleString;
};

/**
 * @param sectionSchema a object that explains how to create a section
 * @param parentsChildren a link to a portion of the profileData that we are currently filling
 * @param connectData the users data from connect
 */
const createProfileDataRecursively = (sectionSchema, parentsChildren, connectData) => {
  // iterate through a sections schema updating the parentsChildren
  Object.keys(sectionSchema).forEach((schemaItemName) => {
    // get the current schema item
    const currentItem = sectionSchema[schemaItemName];
    // evaluate what type the schema item is and form recursive branches off accordingly
    if (currentItem.type === 'Object' || currentItem.type === 'Array') {
      // get the objects schema
      const currentItemSchema = currentItem.schema;
      const children = {};
      // iterate through its schema recursively searching all of its children
      createProfileDataRecursively(currentItemSchema, children, connectData);
      // once all of the schemas children have been found update the current schemas
      // parent with a link to the current schemas children
      if (currentItem.type === 'Array') {
        parentsChildren[schemaItemName] = [];
        parentsChildren[`${schemaItemName}DefaultStyles`] = createStyleString(currentItem.styles);
      } else parentsChildren[schemaItemName] = children;
    } else if (currentItem.type === 'Field' || currentItem.type === 'ImageUpload') {
      if (typeof currentItem.styles !== 'undefined') {
        const styleString = createStyleString(currentItem.styles);
        parentsChildren[`${schemaItemName}Styles`] = styleString;
        parentsChildren[`${schemaItemName}DefaultStyles`] = styleString;
      }
      // if we should autofill then we put a value here
      // HASOWNPROPERTY IS NOT SUPPORTED ON AN OLD VERSION OF ANDROID OS (.15% of users)
      // eslint-disable-next-line no-prototype-builtins
      if (currentItem.hasOwnProperty('autofill')) {
        // gets the path to the connect data to autofill
        const pathToData = currentItem.autofill;
        // retrieves autofill data
        let autofillData = getConnectData(pathToData, connectData);
        // if the autofilled value is an array take the last value from the array
        // currently we do not support autofilling entire arrays, instead you must
        // select an attribute from the array
        if (Array.isArray(autofillData)) {
          if (autofillData.length === 0) autofillData = '';
          else autofillData = autofillData.at(-1);
        }
        parentsChildren[schemaItemName] = autofillData;
      }
      // otherwise we default to the empty string
      else parentsChildren[schemaItemName] = '';
    }
  });

  return parentsChildren;
};

/**
 * Creates the data object that will be used by the profile generator. It will either create
 * empty objects that represent the schema or autofill with connect data as specified by the
 * template config
 * See the templateConfigExample.js for an example of the template config
 * @param connectData
 * @param templateConfig
 */
const createProfileData = (connectData, templateConfig) => {
  // the newSection that we are creating
  const profileData = {};
  // iterates through the template and recursively fills the template
  templateConfig.forEach((section) => {
    const { schema: sectionSchema } = section;
    createProfileDataRecursively(sectionSchema, profileData, connectData);
  });

  return profileData;
};

// ignores the key completely and doesnt attempt to dril down
const ignoreCompletely = ['__typename', 'userIconUrl'];
// ignores the key but can drill down further if its an object
// const ignorePartially = ['edges', 'node', 'userAssessmentConnection'];

const attributeTitleCorrection = {
  tense_title: 'Tense Title',
  attendedConnection: 'Education',
  skillsConnection: 'Skills',
  experienceConnection: 'Experience',
};

const getTitleFromAttributeName = (attributeName) => {
  let attributeTitle = camelCaseToTitle(attributeName);
  // if we should rename the title we rename it
  if (Object.keys(attributeTitleCorrection).includes(attributeName)) {
    attributeTitle = attributeTitleCorrection[attributeName];
  }
  return attributeTitle;
};

const ignoreSearch = ['imageLink', 'rating'];

const shouldIgnoreSearch = (key) => {
  for (const ignore of ignoreSearch) {
    let shouldIgnore = false;
    if (ignore.includes('.')) {
      shouldIgnore = pathToArray(ignore).includes(key);
    } else shouldIgnore = ignore === key;
    if (shouldIgnore) return shouldIgnore;
  }
  return false;
};

/**
 * Converts a json object to a searchable string that seperates data
 * via a seperator. This removes all keys and quotes from the json object.
 * @param {*} json
 * @param {string} seperator
 * @returns a stringified JSON object that can be searched
 */
const JSONToSearch = (json, seperator = ' ') => {
  let str = '';
  // if we have a string we should return the value with a seperator
  if (typeof json === 'string') {
    return `${json.toLowerCase()}${seperator}`;
  }
  // if we have a number we stringify it and return it with a seperator
  else if (typeof json === 'number') {
    return `${json.toString()}${seperator}`;
  } else if (Array.isArray(json)) {
    // if the JSON array has no elements then we should just skip it
    // we do this by returning a seperator
    if (json.length === 0) return seperator;
    // iterate through each item
    for (let item of json) {
      // if the item is null or undefined we throw it out
      if (typeof item === 'undefined' || item === null) continue;
      // otherwise we recursively call this function on the item
      else {
        const value = JSONToSearch(item);
        // if the return should be filtered out then we ignore it
        if (value === seperator) continue;
        // otherwise we add this value to the return string
        else str += value;
      }
    }
    return str;
  } else if (typeof json === 'object') {
    // iterate through all the keys of the object
    for (let key in json) {
      // if we are supposed to ignore the key for search we skip over it
      if (shouldIgnoreSearch(key)) continue;
      // otherwise we get the json objects data
      const data = json[key];
      // if the data is null or undefined we throw it out
      if (typeof data === 'undefined' || data === null) continue;
      // otherwise we search the data
      else {
        const value = JSONToSearch(data);
        // if the return should be filtered out then we ignore it
        if (value === seperator) continue;
        // otherwise we add this value to the return string
        else str += value;
      }
    }
    return str;
  }
  // catch all for unknown types
  else return 'ERROR';
};

const ignoreTitles = [
  '.skillsConnection.rating',
  '.skillsConnection.category',
  '.skillsConnection.imageLink',
  '.usesSkillConnection.rating',
  '.usesSkillConnection.category',
  '.usesSkillConnection.imageLink',
];

const shouldIgnoreTitle = (key) => {
  if (key === '') return false;
  for (const ignore of ignoreTitles) {
    let shouldIgnore = false;
    if (ignore.includes('.')) {
      shouldIgnore = key.includes(ignore);
    } else {
      const attributeName = pathToArray(key).pop();
      shouldIgnore = attributeName === ignore;
    }
    if (shouldIgnore) return shouldIgnore;
  }
  return false;
};

const connectDataToDisplayDataRecursive = (data, pathToCurrent, useKey, keyName) => {
  let attributeObject = {};
  if (
    ignoreCompletely.includes(keyName) ||
    typeof data === 'undefined' ||
    data === null ||
    data === '' ||
    (Array.isArray(data) && data.length === 0)
  )
    return;
  if (useKey) {
    attributeObject.title = getTitleFromAttributeName(keyName);
    attributeObject.key = pathToCurrent;
  }

  if (Array.isArray(data)) {
    const newParentData = [];
    const titles = [];
    for (const dataItem of data) {
      const newChildData = connectDataToDisplayDataRecursive(dataItem, pathToCurrent, false);
      if (typeof newChildData !== 'undefined') {
        if (
          typeof newChildData.allTitles !== 'undefined' &&
          !shouldIgnoreTitle(newChildData?.key ?? '')
        )
          titles.push(newChildData.allTitles);
        newParentData.push({ ...newChildData, isArrayItem: true });
      }
    }

    attributeObject.sort = makeCopyOfObject(newParentData);
    attributeObject.data = newParentData;
    if (typeof attributeObject.title !== 'undefined')
      titles.push(attributeObject.title.toLowerCase());
    // unqiue titles only
    const allTitles = [...new Set(titles)].join(' ');
    attributeObject.dataString = `${allTitles} ${JSONToSearch(data)}`;
    attributeObject.allTitles = allTitles;
  } else if (typeof data === 'object') {
    const newParentData = [];
    const titles = [];
    for (const attributeName of Object.keys(data)) {
      const attributeData = data[attributeName];
      const newData = connectDataToDisplayDataRecursive(
        attributeData,
        `${pathToCurrent}.${attributeName}`,
        true,
        attributeName,
      );
      if (typeof newData !== 'undefined') {
        if (
          typeof newData.title !== 'undefined' &&
          !shouldIgnoreTitle(`${pathToCurrent}.${attributeName}`)
        )
          titles.push(newData.title.toLowerCase());
        newParentData.push(newData);
      }
    }
    attributeObject.sort = makeCopyOfObject(newParentData);
    attributeObject.data = newParentData;
    if (typeof attributeObject.title !== 'undefined') titles.push(attributeObject.title);
    const allTitles = titles.join(' ');
    attributeObject.dataString = `${allTitles} ${JSONToSearch(data)}`;
    attributeObject.allTitles = allTitles;
  } else if (typeof data === 'string') {
    attributeObject.dataString = data.toLowerCase();
    if (useKey) {
      attributeObject.dataString = `${attributeObject.title.toLowerCase()} ${
        attributeObject.dataString
      }`;
    }
    attributeObject.data = data;
  } else if (typeof data === 'number') {
    attributeObject.data = data;
    attributeObject.dataString = data.toString();
    if (useKey) {
      attributeObject.dataString = `${attributeObject.title.toLowerCase()} ${
        attributeObject.dataString
      }`;
    }
  } else {
    return;
  }

  return attributeObject;
};

/**
 * This function is used to convert connect data to a more friendly format
 * for searching and the display
 */
const connectDataToDisplayData = (connectData) => {
  const queryTree = connectDataToDisplayDataRecursive(connectData, '', false).data;
  return queryTree;
};

const displayDataToConnectData = (displayData, attribute) => {
  const dataType = typeof displayData;
  if (Array.isArray(displayData) && !displayData.some((item) => typeof item === 'string')) {
    const first = displayData[0];
    if (first?.isArrayItem) {
      return displayData.map((data) =>
        displayDataToConnectData(data[attribute] ?? data.data, attribute),
      );
    } else {
      const result = {};
      displayData.map((dataItem) => {
        const name = pathToArray(dataItem.key).pop();
        result[name] = displayDataToConnectData(dataItem[attribute] ?? dataItem.data, attribute);
      });
      return result;
    }
  } else if (dataType === 'object' && !Array.isArray(displayData)) {
    const result = {};
    const name = pathToArray(displayData.key).pop();
    result[name] = displayDataToConnectData(displayData[attribute] ?? displayData.data, attribute);
    return result;
  } else {
    return displayData;
  }
};

const highlightData = (data, searchString) => {
  const dataType = typeof data;
  if (dataType === 'string' || dataType === 'boolean' || dataType === 'number') {
    // since it is a priminitive we can display the contents of the data
    let value = data;
    if (dataType === 'boolean') value = toTitle(value.toString());
    else if (dataType === 'number') value = value.toString();
    var regex = new RegExp(searchString, 'gi');
    const matches = [];
    value.replace(regex, (str) => matches.push(str));
    const valueSplit = value.split(regex);
    let occurence = 0;
    return valueSplit
      .map((item, index) => {
        if (index !== valueSplit.length - 1)
          return [item, <mark key={index}>{matches[occurence++]}</mark>];
        return item;
      })
      .flat();
  }
  return data;
};

const findMatchPercentage = (string, searchString) => {
  if (typeof string === 'undefined' || string === null) return 0;
  let realString = string.toLowerCase();
  if (realString.includes(searchString)) return searchString.length / realString.length;
  else return 0;
};

const sortData = (arrayOfScores) => {
  return arrayOfScores.sort((a, b) => b.score - a.score);
};

const ignoreFilter = ['skillsConnection', 'usesSkillConnection'];
const ignoreHighlight = [
  'imageLink',
  'rating',
  '.usesSkillConnection.description',
  '.skillsConnection.description',
  '.usesSkillConnection.name',
  '.skillsConnection.name',
  '.usesSkillConnection.category.value',
  '.skillsConnection.category.value',
];

const shouldIgnoreFilter = (key) => {
  const path = pathToArray(key);
  const lastAttribute = path.at(-1);

  for (const ignore of ignoreFilter) {
    if (ignore !== lastAttribute && path.includes(ignore)) {
      return true;
    }
  }
  return false;
};

const shouldIgnoreHighlight = (key) => {
  if (key === '') return false;
  for (const ignore of ignoreHighlight) {
    let shouldIgnore = false;
    if (ignore.includes('.')) {
      shouldIgnore = key.includes(ignore);
    } else {
      const attributeName = pathToArray(key).pop();
      shouldIgnore = attributeName === ignore;
    }
    if (shouldIgnore) return shouldIgnore;
  }
  return false;
};

const reorderQueryTree = (queryTree, searchString, isArrayItem, isIgnoreFilter) => {
  let attributeTitle = isArrayItem ? queryTree.shift() : null;
  let newOrder = queryTree.map((item) => {
    const shouldIgnoreHighlightValue = shouldIgnoreHighlight(item?.key ?? '', ignoreHighlight);
    const newItem = { ...item };
    newItem.score = findMatchPercentage(item.dataString, searchString);
    if (typeof item.sort !== 'undefined') {
      const isIgnore = shouldIgnoreFilter(item?.key ?? '') || typeof isIgnoreFilter !== 'undefined';
      newItem.sort = reorderQueryTree(item.sort, searchString, item.isArrayItem ?? false, isIgnore);
    } else if (!shouldIgnoreHighlightValue)
      newItem.data = highlightData(newItem.data, searchString);
    if (typeof newItem.title !== 'undefined')
      newItem.title = highlightData(newItem.title, searchString);
    return newItem;
  });

  newOrder = sortData(newOrder);
  if (isArrayItem) {
    newOrder.unshift({
      ...attributeTitle,
      score: findMatchPercentage(attributeTitle.dataString, searchString),
    });
    const shouldIgnoreHighlightValue = newOrder.some((attribute) =>
      shouldIgnoreHighlight(attribute?.key ?? '', ignoreHighlight),
    );
    if (!shouldIgnoreHighlightValue) {
      newOrder[0].title = highlightData(attributeTitle.title, searchString);
      newOrder[0].data = highlightData(attributeTitle.data, searchString);
    }
  }

  return newOrder;
};

const findQueryTreeItemScore = (dataString, searchString) => {
  const numberOfMatches = dataString.split(searchString).length - 1;
  const lengthOfMatches = numberOfMatches * searchString.length;
  return lengthOfMatches / dataString.length;
};

const searchQueryTree = (queryTree, searchString) => {
  const newQueryTree = makeCopyOfObject(queryTree);
  const realSearchString = searchString.toLowerCase();
  // iterate through the queue
  for (const queryItem of newQueryTree) {
    // we define a search hit as the string being included in the data
    // or being included in the title
    const dataStringIncludes = queryItem.dataString.includes(realSearchString);
    const dataStringScore = findQueryTreeItemScore(queryItem.dataString, realSearchString);
    if (dataStringIncludes) {
      queryItem.title = highlightData(queryItem.title, realSearchString);
      queryItem.score = dataStringScore;
      if (Array.isArray(queryItem.data)) {
        const isIgnoreFilter = shouldIgnoreFilter(queryItem?.key ?? '');
        // queryItem.shouldIgnoreFilter = isIgnoreFilter;
        const result = reorderQueryTree(
          queryItem.sort,
          realSearchString,
          queryItem.isArrayItem ?? false,
          isIgnoreFilter,
        );
        if (result.length === 0) queryItem.sort = queryItem.data;
        else queryItem.sort = result;
      } else {
        queryItem.data = highlightData(queryItem.data, realSearchString);
      }
    } else queryItem.score = 0;
  }
  const sorted = sortData(newQueryTree);

  const filtered = sorted.filter((item) => item.score !== 0);
  return filtered;
};

export {
  copy,
  createProfileData,
  cleanConnectData,
  camelCaseToTitle,
  toTitle,
  getConnectData,
  connectDataToDisplayData,
  searchQueryTree,
  displayDataToConnectData,
  makeCopyOfObject,
};
