import { useMemo } from 'react';
import TableView from '../../custom/TableView.component';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteRequestedSkills, QueryRequestedSkills } from '../../../graphql/skills';
import { CheckIcon } from '@heroicons/react/outline';

const tableHeadItems = [
  {
    table: 'requestedSkills',
    name: 'name',
    title: 'Skill',
    customClassName:
      'cursor-pointer py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6',
  },
  {
    table: 'requestedSkills',
    name: 'description',
    title: 'Requested by',
    customClassName:
      'py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 hidden lg:table-cell',
  },
  {
    table: 'requestedSkills',
    name: 'date',
    title: 'On',
    customClassName: 'cursor-pointer px-4 py-3.5 text-left text-xs font-semibold text-gray-900',
  },
  {
    table: 'requestedSkills',
    name: 'usersRated',
    title: 'Resolve',
    customClassName:
      'cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900 min-w-[12rem]',
  },
];

export default function RequestedSkills() {
  const { data: { userRequests: requestedSkills = [] } = {}, loading } = useQuery(
    QueryRequestedSkills,
    { variables: { where: { type: 'skill' } } },
  );

  const [deleteRequestedSkills] = useMutation(DeleteRequestedSkills, {
    refetchQueries: ['QueryRequestedSkills'],
  });

  const handleResolve = (skill) => {
    deleteRequestedSkills({
      variables: {
        where: {
          AND: {
            name: skill.name,
            requestedBy: { name: skill.requestedBy?.[0]?.name },
          },
        },
      },
    });
  };

  const RenderBody = useMemo(() => {
    const BodyMemo = () => (
      <>
        {requestedSkills &&
          requestedSkills.map((skill, index) => (
            <tr key={index} className="hover:bg-gray-200 transition duration-300 cursor-pointer">
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                <div className="flex items-center">
                  <div className="font-medium text-gray-900">{skill.name}</div>
                </div>
              </td>
              <td className="px-4 py-3 text-xs text-gray-500 sm:pl-6 hidden lg:table-cell">
                <div className="text-gray-900">{skill.requestedBy?.[0]?.name}</div>
              </td>
              <td className="px-4 py-3 text-xs text-gray-500 sm:pl-6 hidden lg:table-cell">
                <div className="text-gray-900">
                  {skill.requestedByConnection?.edges?.[0]?.dateRequested}
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                <button
                  className="text-xs font-medium shadow-sm rounded-md border ml-3 p-1"
                  onClick={() => handleResolve(skill)}
                >
                  <CheckIcon className="h-4 w-4" />
                </button>
              </td>
            </tr>
          ))}
      </>
    );

    return BodyMemo;
  }, [requestedSkills]);

  return (
    <>
      <div className="relative z-0 flex flex-1 flex-col">
        <div className="lg:px-6 pt-6 pb-4">
          <h2 className="text-sm font-medium text-gray-900">Requested Skills</h2>
        </div>
        {/* Directory list */}
        <div className="px-4">
          <TableView
            data={requestedSkills}
            tableHeadItems={tableHeadItems}
            RenderBody={RenderBody}
            showPaginationNav={false}
            page="Requested Skills"
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
