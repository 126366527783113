import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SortTableStateEntry = {
  column: string;
  sortValue: '' | 'ASC' | 'DESC';
};

export type SortTableState = {
  people: SortTableStateEntry;
  skills: SortTableStateEntry;
  projects: SortTableStateEntry;
  certifications: SortTableStateEntry;
  assessments: SortTableStateEntry;
};

const initialState: SortTableState = {
  people: {
    column: 'name',
    sortValue: 'ASC',
  },
  skills: {
    column: 'usersRated',
    sortValue: 'DESC',
  },
  projects: {
    column: 'name',
    sortValue: 'ASC',
  },
  certifications: {
    column: 'name',
    sortValue: 'ASC',
  },
  assessments: {
    column: 'user',
    sortValue: 'ASC',
  },
};

const sortOptions = ['', 'ASC', 'DESC'];

type SortColumnByNamePayload = {
  table: 'people' | 'skills' | 'projects' | 'certifications';
  column: string;
  sortValue: number;
};

export const sortTableSlice = createSlice({
  name: 'sort-table',
  initialState,
  reducers: {
    sortColumnByName: (state, action: PayloadAction<SortColumnByNamePayload>) => {
      return {
        ...initialState,
        [action.payload.table]: {
          column: action.payload.sortValue === 0 ? '' : action.payload.column,
          sortValue: sortOptions[action.payload.sortValue],
        },
      };
    },
  },
});

export const { sortColumnByName } = sortTableSlice.actions;

export default sortTableSlice.reducer;
