import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ActionInputState = {
  inputValue: string | null;
};

const initialState: ActionInputState = {
  inputValue: null,
};

export const actionInputSlice = createSlice({
  name: 'action-input',
  initialState,
  reducers: {
    toggleActionInput: (state, action: PayloadAction<Partial<ActionInputState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleActionInput } = actionInputSlice.actions;

export default actionInputSlice.reducer;
