import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import Handlebars from 'handlebars/dist/handlebars.min.js';
import Input from './ProfileGeneratorProfileInput';
import ImageUpload from './ProfileGeneratorProfileImageUpload';
import ArrayInputs from './ProfileGeneratorProfileArrayInput';
import SkillInputs from './ProfileGeneratorProfileSkillInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectField, setProfileGeneratorState } from '../state/profileGeneratorSlice';
import { isRefEmpty } from '../../../lib/isRefEmpty';

const getRelevantFields = (value) => value.split(',').filter((val) => val !== '');

const Profile = ({ profileData, template, templateID }) => {
  const divRef = useRef();
  const profileEditorContentRef = useRef();
  const generateContentRef = useRef();
  const compileTemplate = useMemo(() => Handlebars.compile(template ?? ''), [template]);
  const remoteAssetDirectory = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/templates/${templateID}/assets`;
  const editorMode = useSelector((state) => state.profileGenerator.editorMode);
  const profileHTML = useMemo(
    () => compileTemplate({ ...profileData, remoteAssetDirectory }),
    [compileTemplate],
  );
  const dispatch = useDispatch();

  const resizeMethod = (profileRef) => {
    // found this equation by manually collecting data on what scales worked relative
    // to parent divs width. R2 of 1
    if (typeof divRef !== 'undefined' && !isRefEmpty(profileRef)) {
      profileRef.current.style.scale = divRef.current.clientWidth * 0.00125 + 0.006;
    }
  };

  useEffect(() => {
    if (
      typeof divRef === 'undefined' ||
      typeof profileEditorContentRef === 'undefined' ||
      typeof generateContentRef === 'undefined'
    )
      return;
    const editorObserver = new ResizeObserver(() => resizeMethod(profileEditorContentRef));
    const generateObserver = new ResizeObserver(() => resizeMethod(generateContentRef));
    editorObserver.observe(divRef.current);
    generateObserver.observe(divRef.current);
    return () => {
      editorObserver.disconnect();
      generateObserver.disconnect();
    };
  }, [divRef, profileEditorContentRef, generateContentRef, editorMode]);

  const profileGenerateContent = useMemo(() => {
    const profileGenerateHTML = compileTemplate({ ...profileData, remoteAssetDirectory });
    return (
      <iframe
        id={`${templateID}-profileGenDisplay`}
        className={'w-[794px] h-[882px] origin-top-left'}
        ref={generateContentRef}
        scrolling="no"
        title={'Test'}
        srcDoc={profileGenerateHTML}
      />
    );
  }, [compileTemplate, editorMode]);

  const ProfileEditorContent = useCallback(() => {
    const [elements, setElements] = useState();
    const onIframeLoaded = () => {
      if (editorMode === 'editor' && typeof elements === 'undefined') {
        const list = [];
        let fieldList = [];
        const fieldNodes =
          profileEditorContentRef.current.contentWindow.document.body.querySelectorAll(
            `[profilegenid="${templateID}-profileGen"]`,
          );
        for (const fieldNode of fieldNodes) {
          let shouldSkipFieldItem = false;
          const fieldItem = {};
          const profileGenPath = fieldNode.attributes.getNamedItem('id').value;
          const profileGenFieldType = fieldNode.attributes.getNamedItem('profilegentype').value;
          const profileGenFieldTitle = fieldNode.attributes.getNamedItem('profilegentitle').value;
          fieldItem.path = profileGenPath;
          fieldItem.type = profileGenFieldType;
          const fieldDomNode =
            profileEditorContentRef.current.contentWindow.document.getElementById(profileGenPath);
          let result;
          if (profileGenFieldType === 'field' || profileGenFieldType === 'image') {
            const fieldClasses = fieldNode?.children[0]?.className;
            fieldDomNode.replaceChildren();
            if (profileGenFieldType === 'field') {
              const relevantFields = getRelevantFields(
                fieldNode.attributes.getNamedItem('profilegenrelevantfields').value,
              );
              fieldItem.relevantFields = relevantFields;
              result = createPortal(
                <Input
                  path={profileGenPath}
                  className={fieldClasses}
                  title={profileGenFieldTitle}
                  relevantFields={relevantFields}
                  type={profileGenFieldType}
                />,
                fieldDomNode,
              );
            } else {
              shouldSkipFieldItem = true;
              result = createPortal(
                <ImageUpload path={profileGenPath} className={fieldClasses} />,
                fieldDomNode,
              );
            }
          } else if (profileGenFieldType === 'array') {
            const relevantFields = getRelevantFields(
              fieldNode.attributes.getNamedItem('profilegenrelevantfields').value,
            );
            fieldItem.relevantFields = relevantFields;
            const listWrapper = fieldNode.attributes.getNamedItem('listwrapper').value;
            const listItem = fieldNode.attributes.getNamedItem('listitem').value;
            fieldDomNode.replaceChildren();
            result = createPortal(
              <ArrayInputs
                path={profileGenPath}
                listItem={listItem}
                listWrapper={listWrapper}
                title={profileGenFieldTitle}
                relevantFields={relevantFields}
                type={profileGenFieldType}
              />,
              fieldDomNode,
            );
          } else if (profileGenFieldType === 'skill') {
            const listWrapper = fieldNode.attributes.getNamedItem('listwrapper').value;
            const listItem = fieldNode.attributes.getNamedItem('listitem').value;
            fieldDomNode.replaceChildren();
            result = createPortal(
              <SkillInputs
                path={profileGenPath}
                listItem={listItem}
                listWrapper={listWrapper}
                title={profileGenFieldTitle}
                type={profileGenFieldType}
              />,
              fieldDomNode,
            );
          }
          list.push(result);
          if (!shouldSkipFieldItem) fieldList.push(fieldItem);
        }
        setElements(list.filter(Boolean));
        resizeMethod(profileEditorContentRef);
        dispatch(selectField(fieldList.at(0)));
        dispatch(setProfileGeneratorState({ profileFieldList: fieldList }));
      }
    };

    return (
      <iframe
        id={`${templateID}-profileGenDisplay`}
        onLoad={onIframeLoaded}
        className={'w-[794px] h-[882px] origin-top-left'}
        ref={profileEditorContentRef}
        scrolling="no"
        title={'Test'}
        srcDoc={profileHTML}
      >
        {editorMode === 'editor' && elements}
      </iframe>
    );
  }, [profileHTML, editorMode]);

  return (
    <div ref={divRef} className="rounded-md shadow w-[calc(100%_-_16px)]">
      <div className="overflow-hidden aspect-[.89] rounded-t-md">
        {/* A4 Paper Size */}
        {editorMode === 'generate' && profileGenerateContent}
        {editorMode === 'editor' && <ProfileEditorContent />}
      </div>
    </div>
  );
};

export default Profile;
