import PageContainer from '../custom-prebuilt/PageContainer';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import { QueryAllAnnouncements } from '../graphql/announcements';
import HtmlPreview from '../components/create-announcement/text-editor/HtmlPreview';
import LoadingState from '../custom-prebuilt/preloader/LoadingState.component';
import PersonCard from '../components/shared/PersonCard.component';
import LoadingSkeleton from '../custom-prebuilt/preloader/LoadingSkeleton.component';
import SimpleSection from '../components/shared/SimpleSection.component';
import { useDispatch } from 'react-redux';
import { toggleSlideOver } from '../redux/slideOverSlice';

const Announcements = () => {
  const location = useLocation();
  const announcementId = location.pathname.split('/').at(-1);

  const queryAnnouncement = useQuery(QueryAllAnnouncements, {
    variables: {
      where: {
        announcementId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const announcement = queryAnnouncement.data?.announcements[0];
  const content = announcement?.content;

  const dispatch = useDispatch();

  const handleSelectPerson = () => {
    dispatch(
      toggleSlideOver({
        show1: true,
        profileTarget: announcement?.belongsToConnection?.edges[0]?.node,
      }),
    );
  };

  return (
    <PageContainer title="Announcement">
      <div className="w-full md:w-[580px] lg:w-[800px] h-fit mx-auto flex flex-col space-y-4">
        <SimpleSection>
          {announcement ? (
            <div>
              <p className="text-xs font-medium mb-2">
                Date posted: <span>{announcement?.belongsToConnection?.edges[0]?.datePosted}</span>
              </p>
              <PersonCard
                personBasics={announcement?.belongsToConnection?.edges[0]?.node}
                onSelect={handleSelectPerson}
              />
            </div>
          ) : (
            <div>
              <LoadingSkeleton short count={1} />
              <LoadingSkeleton count={1} />
            </div>
          )}
        </SimpleSection>

        <SimpleSection>
          {announcement ? <HtmlPreview json={JSON.parse(content)} /> : <LoadingState />}
        </SimpleSection>
      </div>
    </PageContainer>
  );
};

export default Announcements;
