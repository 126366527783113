import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QueryPeopleTablePagination } from '../graphql/people';
import { useQuery } from '@apollo/client';
import PageContainer from '../custom-prebuilt/PageContainer';
import DataTable, { Column } from '../components/shared/table/DataTable.component';
import defaultImg from '../assets/default-user.svg';
import { useNavigate } from 'react-router';
import { SortDirection } from '../components/shared/table/SortIndicator.component';
import { formatDate } from '../lib/formatDate';

const People = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  const navigate = useNavigate();

  // Sort Selector for People
  const sortPeopleSelector = useSelector((state) => state.sortTable.people);
  let sortOptions = [];
  if (sortPeopleSelector.column !== '') {
    sortOptions.push({
      [sortPeopleSelector.column]: sortPeopleSelector.sortValue,
    });
  }

  const [allData, setAllData] = useState();
  const { data } = useQuery(QueryPeopleTablePagination, {
    variables: {
      options: {
        sort: sortOptions,
      },
      where: {
        uid: defaultOrgUid,
      },
      membersWhere2: {
        active: true,
      },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (data?.organizations[0]) {
      setAllData(data.organizations[0].orgUnit.map((u) => u.members).flat());
    }
  }, [data]);

  const defaultOrg = useSelector((state) => state.org.defaultOrg);

  return (
    <>
      {
        <PageContainer title="People Directory">
          <DataTable
            tableId={'peopleTable'}
            data={allData}
            totalRecords={allData?.length ?? 0}
            topPaginator={true}
            bottomPaginator={true}
            displaySearchBar={true}
            searchBarPlaceholder="Find user"
            onRowClick={(person) => {
              navigate(`/${person?.email.split('.').at(0)}`);
            }}
            columns={[
              Column({
                title: 'Name',
                field: 'name',
                searchable: true,
                sortable: true,
                dataCellClassName: 'sm:pl-6',
                headerCellClassName: 'sm:pl-6',
                initialSortDir: SortDirection.ASC,
                RenderDataCellFn: (data) => {
                  return (
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={data.userIconUrl ?? defaultImg}
                          alt=""
                          referrerPolicy="no-referrer"
                        />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{data.name}</div>
                        <div className="text-gray-500">{data.email}</div>
                      </div>
                    </div>
                  );
                },
              }),
              Column({
                title: 'Title',
                field: 'title',
                RenderDataCellFn: (data) => {
                  return (
                    <>
                      <div className="text-gray-900">
                        {data.orgUnitsConnection?.edges?.[0]?.position}
                      </div>
                      <div className="text-gray-500">
                        {data.orgUnitsConnection?.edges?.[0]?.node?.name}
                      </div>
                    </>
                  );
                },
              }),
              Column({
                title: 'Work Location',
                field: 'google_workLocation',
                sortable: true,
              }),
              Column({
                title: 'Start Date',
                field: 'orgUnitsConnection.edges[0].startDate',
                sortable: true,
                RenderDataCellFn: (data) => {
                  return (
                    <div className="text-xs text-gray-900">
                      {formatDate(data.orgUnitsConnection?.edges?.[0]?.startDate, '')}
                    </div>
                  );
                },
              }),
              Column({
                title: 'Role',
                field: 'role',
                RenderDataCellFn: (data) => {
                  return data?.isAdmin?.[0]?.uid === defaultOrg.uid ? 'administrator' : 'user';
                },
              }),
            ]}
          ></DataTable>
        </PageContainer>
      }
    </>
  );
};

export default People;
