import PageContainer from '../custom-prebuilt/PageContainer';
import { useLocation } from 'react-router';
import { useState, useEffect } from 'react';
import { QuerySkillDetails } from '../graphql/skills';
import { useQuery } from '@apollo/client';
import NotFound from '../custom-prebuilt/NotFound.component';
import LoadingState from '../custom-prebuilt/preloader/LoadingState.component';
import SkillDetails from '../custom-prebuilt/snapshot/utils/SkillDetails.component';
import SkillProjects from '../custom-prebuilt/snapshot/utils/SkillProjects.component';
import PersonCard from '../components/shared/PersonCard.component';
import SlideOver from '../custom-prebuilt/SlideOver.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSlideOver } from '../redux/slideOverSlice';
import SnapshotProfile from '../custom-prebuilt/snapshot/SnapshotProfile.component';
import Card from '../components/shared/Card.component';
import SingleSkillView from '../components/admin/taxonomy/SingleSkillView';
import { useCursorPosition, usePopOver } from '../lib/customHook';

const SkillProfile = () => {
  const show = useSelector((state) => state.slideOver.show1);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const dispatch = useDispatch();
  const location = useLocation();
  const skillSlug = location.pathname.split('/').at(-1);
  const [skillDetails, setSkillDetails] = useState();
  const [KPeople, setKPeople] = useState([]); //Knowledgable
  const [PPeople, setPPeople] = useState([]); //Proficient
  const [LTPeople, setLTPeople] = useState([]); //Lead/Teach

  const updatePosition = useCursorPosition();
  const { handleGetPopOverData, handleClearPopOverData } = usePopOver();

  const handleSelectPerson = (personBasics) => {
    dispatch(toggleSlideOver({ show1: true, profileTarget: personBasics }));
  };

  const querySkillDetails = useQuery(QuerySkillDetails, {
    variables: {
      where: {
        slug: decodeURIComponent(skillSlug),
      },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
  });

  useEffect(() => {
    querySkillDetails.data?.skills &&
      (querySkillDetails.data?.skills?.length !== 0
        ? setSkillDetails(querySkillDetails.data?.skills[0])
        : setSkillDetails(404));
  }, [querySkillDetails.data]);

  useEffect(() => {
    if (skillDetails && skillDetails !== 404) {
      setKPeople(
        [...(skillDetails?.peopleWithSkillConnection?.edges || [])].filter((p) => p.rating === 1),
      );
      setPPeople(
        [...(skillDetails?.peopleWithSkillConnection?.edges || [])].filter((p) => p.rating === 2),
      );
      setLTPeople(
        [...(skillDetails?.peopleWithSkillConnection?.edges || [])].filter((p) => p.rating === 3),
      );
    }
  }, [skillDetails]);

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show1: false, profileTarget: null }));
  };

  return (
    <PageContainer title={skillDetails?.name}>
      <SlideOver
        show={show}
        title="User Profile"
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        largeSize={true}
        content={<SnapshotProfile target={target} coverPic={true} />}
      />
      {/*Could tear down Snapshotprofle and rebuilt using smaller components for better customization*/}
      <div className="w-full">
        {!skillDetails ? (
          <LoadingState />
        ) : skillDetails === 404 ? (
          <NotFound msg="The requested skill was not found on the server" />
        ) : (
          <div className="h-fit xl:h-full w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-[33%_67%] xl:space-x-5 gap-y-5 xl:gap-y-0">
            <div className="space-y-5">
              <div className="h-fit w-full px-4 py-8 bg-white shadow rounded-lg flex flex-row">
                <img
                  className="h-24 w-auto"
                  src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skillDetails?.imageLink}`}
                  alt=""
                />
                <div className="flex flex-col ml-5">
                  <p className="text-sm font-medium mb-2">{skillDetails?.name}</p>
                  <p className="text-xs text-gray-500 font-medium">
                    {skillDetails?.peopleWithSkillConnection?.edges?.length} people know this skill
                  </p>
                </div>
              </div>
              <SkillDetails className="!p-0 bg-white" skillDetails={skillDetails} />
              <SkillProjects skillDetails={skillDetails} />
              <SingleSkillView skill={skillDetails} />
            </div>

            <div className="h-full flex flex-col space-y-5 overflow-y-auto">
              <Card title={'Lead/Teach'} titleCounter={LTPeople.length} displayCounter>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-3">
                  {LTPeople.map((p) => (
                    <PersonCard
                      key={p.node.email}
                      personBasics={p.node}
                      className="!w-full"
                      onSelect={() => handleSelectPerson(p.node)}
                      onMouseEnter={(event) => {
                        event.stopPropagation();
                        const position = updatePosition(event);
                        handleGetPopOverData('person', p.node, position, event.target.clientHeight);
                      }}
                      onMouseLeave={(event) => {
                        event.stopPropagation();
                        handleClearPopOverData();
                      }}
                    />
                  ))}
                </div>
              </Card>
              <Card title={'Proficient'} titleCounter={PPeople.length} displayCounter>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-3">
                  {PPeople.map((p) => (
                    <PersonCard
                      key={p.node.email}
                      personBasics={p.node}
                      className="!w-full"
                      onSelect={() => handleSelectPerson(p.node)}
                      onMouseEnter={(event) => {
                        event.stopPropagation();
                        const position = updatePosition(event);
                        handleGetPopOverData('person', p.node, position, event.target.clientHeight);
                      }}
                      onMouseLeave={(event) => {
                        event.stopPropagation();
                        handleClearPopOverData();
                      }}
                    />
                  ))}
                </div>
              </Card>
              <Card title={'Knowledgeable'} titleCounter={KPeople.length} displayCounter>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-3">
                  {KPeople.map((p) => (
                    <PersonCard
                      key={p.node.email}
                      personBasics={p.node}
                      className="!w-full"
                      onSelect={() => handleSelectPerson(p.node)}
                      onMouseEnter={(event) => {
                        event.stopPropagation();
                        const position = updatePosition(event);
                        handleGetPopOverData('person', p.node, position, event.target.clientHeight);
                      }}
                      onMouseLeave={(event) => {
                        event.stopPropagation();
                        handleClearPopOverData();
                      }}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default SkillProfile;
