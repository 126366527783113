import './ProfileGeneratorDataDisplay.scss';
import {
  pathToArray,
  copy,
  toTitle,
  searchQueryTree,
  displayDataToConnectData,
  makeCopyOfObject,
} from '../state/ProfileGenerationContextHelpers';
import { SkillDisplay } from './ProfileGeneratorSkillDisplay';
import Search from './ProfileGeneratorSearch';
import { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FilterIcon as FilterEmpty,
} from '@heroicons/react/outline';
import { FilterIcon as FilterFilled } from '@heroicons/react/solid';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import PageErrorComponent from '../../../custom-prebuilt/PageError.component';
import { useHorizontalTabs } from '../../../lib/customHook';
import Toggle from './ProfileGeneratorToggle';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileGeneratorState } from '../state/profileGeneratorSlice';

const DisplayContent = ({ content, searchString, internalCopy }) => {
  const onClick = () => {
    let dataToCopy = content;
    if (Array.isArray(dataToCopy)) {
      dataToCopy = dataToCopy
        .map((item) => {
          if (typeof item === 'object') return searchString;
          else return item;
        })
        .join('');
    }
    internalCopy(dataToCopy);
  };

  return (
    <button
      className="bg-white shadow rounded-md text-xs p-2 min-h-[1.25rem] mb-4"
      onClick={onClick}
    >
      <p className="text-left">{content}</p>
    </button>
  );
};

/**
 * When we are searching the default state is open, when we are not searching the default state is closed.
 * Changing the current visibilty changes open -> not open and vice versa.
 * @param {boolean} currentVisibility
 * @param {boolean} isSearching
 * @returns {boolean} if the display should be open
 */
const isOpen = (currentVisibility, isSearching) => {
  return currentVisibility ^ isSearching;
};

const DisplayItem = ({
  title,
  titleDecortator,
  isRoot,
  children,
  content,
  isArrayItem,
  searchString,
}) => {
  const isNotRoot = typeof isRoot === 'undefined' || (typeof isRoot === 'boolean' && !isRoot);
  const isSearching = searchString !== '';
  const [visibility, setVisibility] = useState(true);
  const isChildUndefined = typeof children === 'undefined';
  const switchContent = () => setVisibility(!visibility);

  if (isArrayItem)
    return (
      <div>
        <p className="text-sm text-font-dark font-bold">{title}</p>
        <div>
          <p className="text-sm text-font-dark">{content}</p>
          {typeof children !== 'undefined' ? <ul className="directory">{children}</ul> : null}
        </div>
      </div>
    );
  return (
    <li className={`text-sm text-font-dark ${isNotRoot ? '' : 'root'}`}>
      <div className="flex gap-2 items-center">
        <div
          className="flex cursor-pointer mb-2 self-center justify-center"
          onClick={switchContent}
        >
          {!isChildUndefined ? (
            isOpen(visibility, isSearching) ? (
              <ChevronDownIcon className="text-font-dark h-[1rem] cursor-pointer" />
            ) : (
              <ChevronUpIcon className="text-font-dark h-[1rem] cursor-pointer" />
            )
          ) : null}
          <p className="text-sm text-font-dark font-bold">{title}</p>
        </div>
        {!isChildUndefined && !isOpen(visibility, isSearching) ? titleDecortator : null}
      </div>

      <div
        style={{
          display: isOpen(visibility, isSearching) && !isChildUndefined ? 'none' : 'unset',
        }}
      >
        <p className="text-sm text-font-dark">{content}</p>
        {typeof children !== 'undefined' ? <ul className="directory">{children}</ul> : null}
      </div>
    </li>
  );
};

const DisplayItemWrapper = ({
  title,
  isRoot,
  contentFiltered,
  allContent,
  isArrayItem,
  searchString,
  filteredAttributes,
}) => {
  const isSearching = searchString !== '';
  const [forceFiltered, setForceFiltered] = useState(false);
  const forceFilter = () => setForceFiltered(!forceFiltered);
  const titleDecorator = (
    <>
      {isSearching && filteredAttributes !== 0 ? (
        <Toggle
          isToggled={!forceFiltered}
          onClick={forceFilter}
          toggle1={{
            icon: <FilterFilled className="h-6 text-color-bg-signature" />,
            title: 'Show more',
            subtitle: `${filteredAttributes} more data point${filteredAttributes > 1 ? 's' : ''}`,
          }}
          toggle2={{
            icon: <FilterEmpty className="h-6 text-color-bg-signature" />,
            title: 'Show less',
            subtitle: `Data with "${searchString}"`,
          }}
        />
      ) : null}
    </>
  );

  return (
    <DisplayItem
      title={title}
      titleDecortator={titleDecorator}
      isRoot={isRoot}
      isArrayItem={isArrayItem}
      searchString={searchString}
    >
      <div>
        <ul className="directory">
          {isOpen(forceFiltered, isSearching) ? contentFiltered : allContent}
        </ul>
      </div>
    </DisplayItem>
  );
};

const skillData = ['skillsConnection'];

const generateDisplayRecursive = ({
  displayData,
  isRoot,
  isArrayItem,
  connectData,
  searchString,
  ignoreFilter,
  selectSkill,
  internalCopy,
}) => {
  const keyValue = searchString === '' ? 'data' : 'sort';

  // if the display data is an array we are either dealing with
  // an array of items or a object
  if (Array.isArray(displayData) && !displayData.some((item) => typeof item === 'string')) {
    // we get the first item from the array to determine which
    // type of array we are dealing with
    const first = displayData[0];
    let displayComponents;
    // if it is an array of items
    if (first?.isArrayItem ?? false) {
      // we convert each item to a display where the title is the first
      // item in the items data
      displayComponents = displayData.map((arrayChild, index) => {
        const arrayChildTitle = arrayChild[keyValue][0];
        let title = arrayChildTitle.data;
        //if display item is a project, use the project name for the title
        if (arrayChildTitle.key.match(/.experienceConnection.\w+$/)) {
          title = arrayChild[keyValue].find((a) => a.dataString.startsWith('name')).data;
        }
        let allContent = generateDisplayRecursive({
          displayData: arrayChild[keyValue],
          isRoot,
          isArrayItem: true,
          connectData,
          searchString,
          ignoreFilter,
          selectSkill,
          internalCopy,
        });
        let contentFiltered = allContent;
        let filteredAttributes = 0;
        if (searchString !== '' && !ignoreFilter) {
          let arrayChildDataFiltered = arrayChild.sort.filter((item) => item.score !== 0);
          if (arrayChildDataFiltered.length === 0) arrayChildDataFiltered = arrayChild.sort;
          filteredAttributes = arrayChild.data.length - arrayChildDataFiltered.length;
          contentFiltered = generateDisplayRecursive({
            displayData: arrayChildDataFiltered,
            isRoot,
            isArrayItem: true,
            connectData,
            searchString,
            ignoreFilter,
            selectSkill,
            internalCopy,
          });
        }
        return (
          <DisplayItemWrapper
            key={`display-item-${arrayChildTitle.key}-${index}`}
            title={title}
            isRoot={false}
            isArrayItem={isArrayItem}
            searchString={searchString}
            connectData={connectData}
            contentFiltered={contentFiltered}
            allContent={allContent}
            filteredAttributes={filteredAttributes}
          />
        );
      });
    } else {
      // otherwise we are dealing with an object and we can recursively call this function
      displayComponents = displayData.map((arrayChild) =>
        generateDisplayRecursive({
          displayData: arrayChild,
          isRoot: true,
          isArrayItem: false,
          connectData,
          searchString,
          ignoreFilter,
          selectSkill,
          internalCopy,
        }),
      );
    }
    // we finally return the section name with all of its appropriate children
    return displayComponents;
  }
  // if we have an object we could be dealing with either a attribute with a value
  // or attribute with its own attributes
  else if (typeof displayData === 'object') {
    const dataType = typeof displayData.data;
    const attribute = pathToArray(displayData.key).pop();
    if (skillData.includes(attribute)) {
      const isAllSkills = pathToArray(displayData.key).shift() === 'skillsConnection';
      let skillData = displayDataToConnectData(displayData, keyValue)[attribute];
      let skillDataFiltered = skillData;
      let filteredAttributes = 0;
      if (searchString !== '' && !ignoreFilter) {
        let copyOfData = makeCopyOfObject(displayData);
        copyOfData.sort = copyOfData.sort.filter((item) => item.score !== 0);
        if (copyOfData.sort.length === 0) copyOfData.sort = displayData.sort;
        skillDataFiltered = displayDataToConnectData(copyOfData, 'sort')[attribute];
        if (!Array.isArray(skillDataFiltered) && typeof skillDataFiltered === 'object')
          skillDataFiltered = skillData;
        filteredAttributes = skillData.length - skillDataFiltered.length;
      }

      if (!isAllSkills) {
        skillData = skillData.map((s) => ({ ...s, isProject: true }));
        skillDataFiltered = skillDataFiltered.map((s) => ({ ...s, isProject: true }));
      }

      const filterPosition = isAllSkills ? ['top', 'left'] : ['bottom', 'left'];
      const filterType = isAllSkills ? ['skill', 'projectValue'] : ['project'];

      return (
        <DisplayItemWrapper
          key={`display-item-${displayData.key}`}
          title={displayData.title}
          isRoot={isRoot}
          isArrayItem={isArrayItem}
          searchString={searchString}
          filteredAttributes={filteredAttributes}
          allContent={
            <SkillDisplay
              skills={skillData}
              selectSkill={selectSkill}
              className="pb-2"
              filterPosition={filterPosition}
              type={filterType}
            />
          }
          contentFiltered={
            <SkillDisplay
              skills={skillDataFiltered}
              selectSkill={selectSkill}
              className="pb-2"
              filterVisible={skillDataFiltered.length === skillData.length}
              filterPosition={['bottom', 'left']}
              type={filterType}
            />
          }
        />
      );
    }
    // determine if the objects data is a priminitive or not
    else if (
      dataType === 'string' ||
      dataType === 'boolean' ||
      dataType === 'number' ||
      (Array.isArray(displayData.data) && displayData.data.some((item) => typeof item === 'string'))
    ) {
      // since it is a priminitive we can display the contents of the data
      let value = displayData.data;
      if (dataType === 'boolean') value = toTitle(value.toString());
      else if (dataType === 'number') value = value.toString();
      return (
        <DisplayItem
          key={`display-item-${displayData.key}`}
          title={displayData?.title}
          isRoot={isRoot}
          isArrayItem={isArrayItem}
          content={
            <DisplayContent
              content={value}
              searchString={searchString}
              internalCopy={internalCopy}
            />
          }
          searchString={searchString}
        />
      );
    } else {
      // since it is not a priminitive we make more recursive calls
      let allContent = generateDisplayRecursive({
        displayData: displayData[keyValue],
        isRoot,
        isArrayItem: false,
        connectData,
        searchString,
        ignoreFilter: true,
        selectSkill,
        internalCopy,
      });
      let contentFiltered = allContent;
      let filteredAttributes = 0;
      if (searchString !== '' && !ignoreFilter) {
        let displayDataFiltered = displayData.sort.filter((item) => item.score !== 0);
        if (displayDataFiltered.length === 0) displayDataFiltered = displayData.sort;
        filteredAttributes = displayData.data.length - displayDataFiltered.length;
        contentFiltered = generateDisplayRecursive({
          displayData: displayDataFiltered,
          isRoot,
          isArrayItem: false,
          connectData,
          searchString,
          ignoreFilter,
          selectSkill,
          internalCopy,
        });
      }
      return (
        <DisplayItemWrapper
          key={`display-item-${displayData.key}`}
          title={displayData.title}
          isRoot={isRoot}
          isArrayItem={isArrayItem}
          searchString={searchString}
          filteredAttributes={filteredAttributes}
          allContent={allContent}
          contentFiltered={contentFiltered}
        />
      );
    }
  }
};

const generateDisplay = ({ displayData, connectData, searchString, selectSkill, internalCopy }) => {
  return displayData.map((attribute) => ({
    display: generateDisplayRecursive({
      displayData: attribute,
      isRoot: false,
      isArrayItem: false,
      connectData,
      searchString,
      ignoreFilter: false,
      selectSkill,
      internalCopy,
    }),
    key: attribute.key,
  }));
};

const tabs = ['Relevant Data', 'All Data'];

const ProfileGeneratorDataDisplay = ({ displayData, connectData, relevantFields }) => {
  const search = useRef();
  const selectedField = useSelector((state) => state.profileGenerator.selectedField);
  const dispatch = useDispatch();
  const internalCopy = (content) => {
    dispatch(setProfileGeneratorState({ clipBoard: content }));
    copy(content);
  };

  const selectSkill = (skill) => {
    internalCopy(skill.name);
  };

  const getDataDisplayObject = (dataDisplay) => {
    return {
      relevant: dataDisplay
        .filter((item) => {
          return relevantFields.includes(item.key);
        })
        .map((item) => item.display),
      all: dataDisplay.map((item) => item.display),
    };
  };

  const defaultDataDisplay = useMemo(
    () =>
      generateDisplay({ displayData, connectData, searchString: '', selectSkill, internalCopy }),
    [displayData, connectData],
  );
  const defaultDisplay = useMemo(
    () => getDataDisplayObject(defaultDataDisplay),
    [defaultDataDisplay, relevantFields],
  );

  const [dataDisplay, setDataDisplay] = useState(defaultDisplay);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [tabIndex, _setTabIndex, tabsComponent] = useHorizontalTabs(
    tabs,
    false,
    'bg-signature text-white shadow',
    'bg-transparent hover:text-signature',
  );

  const isRelevantTab = () => tabIndex === 0;

  const onWipeSearch = () => {
    search.current.value = '';
    setSearchTerm('');
    setIsLoading(false);
  };

  const performSearch = () => {
    if (searchTerm !== '') {
      const data = searchQueryTree(displayData, searchTerm);
      const dataDisplay = generateDisplay({
        displayData: data,
        connectData,
        searchString: searchTerm,
        internalCopy,
        selectSkill,
      });
      setDataDisplay(getDataDisplayObject(dataDisplay));
    }
    setIsLoading(false);
  };

  const defaultDisplayComponent = useMemo(() => {
    return isRelevantTab() ? defaultDisplay.relevant : defaultDisplay.all;
  }, [tabIndex, defaultDisplay]);

  const errorHandleDataDisplay = (dataDisplayObj) => {
    if (dataDisplayObj.length === 0)
      return (
        <PageErrorComponent
          errorCode=":("
          title={'No Results Found'}
          text={`We could not find any matches for "${searchTerm}".`}
        />
      );
    return dataDisplayObj;
  };

  const dataDisplayComponent = useMemo(() => {
    if (isRelevantTab()) return errorHandleDataDisplay(dataDisplay.relevant);
    return errorHandleDataDisplay(dataDisplay.all);
  }, [tabIndex, dataDisplay]);

  // adapted from https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
  useEffect(() => {
    let delaySearch;
    if (searchTerm !== '') {
      delaySearch = setTimeout(() => performSearch(), 500);
    }
    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const onChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value !== '') setIsLoading(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="px-4 pt-5 shadow bg-white rounded-md pb-4 overflow-y-scroll">
        <Search
          inputRef={search}
          value={searchTerm}
          onChange={onChange}
          wipeSearch={onWipeSearch}
        />
        <ul className="flex flex-col directory">
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              {tabsComponent}
              {/* this lets us switch back to the default display an extra render cycle early */}
              <div className={search?.current?.value === '' ? '' : 'hidden'}>
                {defaultDisplayComponent.length !== 0 && defaultDisplayComponent}
                {defaultDisplayComponent.length === 0 && (
                  <PageErrorComponent
                    errorCode={':('}
                    title={'No Relevant Fields'}
                    text={
                      selectedField.path === ''
                        ? 'Try clicking a field on the profile'
                        : 'There is no relevant data available for this field'
                    }
                  />
                )}
              </div>
              <div className={search?.current?.value === '' ? 'hidden' : ''}>
                {dataDisplay.length !== 0 && dataDisplayComponent}
                {dataDisplay.length === 0 && (
                  <PageErrorComponent
                    errorCode={':('}
                    title={'No Results Found'}
                    text={`We could not find any matches for "${searchTerm}".`}
                  />
                )}
              </div>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ProfileGeneratorDataDisplay;
