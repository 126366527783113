import React from 'react';
import ReactDOM from 'react-dom/client';
import './input.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './lib/authContext';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import { setContext } from '@apollo/client/link/context';
import store from './redux/store';
import gsap from 'gsap';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';

gsap.ticker.fps(120);

console.warn = () => {};

Amplify.configure({
  Auth: {
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    mandatorySignIn: true,
    signUpVerificationMethod: 'code',

    cookieStorage: {
      domain: ';',
      path: '/',
      expires: 365,
      sameSite: 'lax',
      secure: false,
    },

    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      clientId: '4ie8cj6o3dq99p3c3lj08cqhpg',
    },
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          StandardMap: {
            style: 'VectorEsriLightGrayCanvas',
          },
        },
        default: 'StandardMap',
      },
      search_indices: {
        items: [process.env.REACT_APP_SEARCH_INDEX],
        default: process.env.REACT_APP_LOCATION_SEARCH_INDEX,
      },
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    },
  },
});

// const defaultOptions = {
//   watchQuery: {
//     fetchPolicy: "cache-and-network",
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: "cache-and-network",
//     errorPolicy: 'all',
//   },
// }

const httpLink = createHttpLink({
  // uri: 'https://backend.jg-explore.xyz:444/',
  // uri: 'https://backend.jg-explore.xyz/',
  // uri: 'http://localhost:4000/',
  uri: process.env.REACT_APP_GQL_URI,
});

// const alphaHttpLink = createHttpLink({
//   uri: 'https://backend.jg-explore.xyz/',
// });

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await Auth.currentSession().then((res) => {
    return res.getAccessToken()['jwtToken' as keyof CognitoAccessToken];
  });

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const GQL_URI_splitted = process.env.REACT_APP_GQL_URI?.split('.');
export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getUnratedSkills: { merge: false },
        },
      },
    },
  }),
  link: authLink.concat(httpLink),
  uri: process.env.REACT_APP_GQL_URI,
  connectToDevTools: GQL_URI_splitted?.includes('dev') || GQL_URI_splitted?.includes('poc'),
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <AmplifyProvider>
            <App />
          </AmplifyProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </ApolloProvider>,
);
reportWebVitals();
