import { useNavbar } from '../lib/customHook';
import PageHeader from './PageHeader.component';
import { classNames } from '../lib/classNames';
import { ReactNode } from 'react';

export type PageContainerProps = {
  children?: ReactNode | undefined;
  title?: ReactNode | undefined;
  action?: (event?: React.MouseEvent<HTMLElement>) => void;
  actionName?: ReactNode | undefined;
  action2?: (event?: React.MouseEvent<HTMLElement>) => void;
  actionName2?: ReactNode | undefined;
  setTopDistance?: boolean;
  className?: string;
};
const PageContainer = ({
  children,
  title,
  action,
  action2,
  actionName,
  actionName2,
  setTopDistance,
  className,
}: PageContainerProps) => {
  const pageContainerRef = useNavbar();

  return (
    <div ref={pageContainerRef} className={classNames(`page-container`, className)}>
      <PageHeader
        title={title}
        action={action}
        action2={action2}
        actionName={actionName}
        actionName2={actionName2}
        setTopDistance={setTopDistance}
      />

      {/* <div ref={parent}>
        {
          animate ? children : null
        }
      </div> */}
      {children}
    </div>
  );
};

export default PageContainer;
