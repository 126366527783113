import Modal from '../custom-prebuilt/Modal.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../redux/modalSlice';
import AllPlan from '../components/Plan/AllPlan.component';
import CurrentPlan from '../components/Plan/CurrentPlan.component';
import PageContainer from '../custom-prebuilt/PageContainer';

const Plan = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modal.show);

  const handleCloseViewAllPlan = () => {
    dispatch(toggleModal({ show: false }));
  };

  return (
    <PageContainer
      title="Plan"
      actionName="View all plan"
      action={() => {
        dispatch(toggleModal({ show: true }));
      }}
    >
      <CurrentPlan />
      <Modal
        open={show}
        onSecondaryButtonClick={handleCloseViewAllPlan}
        content={<AllPlan />}
        secondaryButtonLabel="Close"
        size="large"
      />
    </PageContainer>
  );
};

export default Plan;
