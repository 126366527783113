import { classNames } from '../../lib/classNames';

export type ButtonInput = {
  className?: string;
  onClick: () => void;
  buttonType?: 'button' | 'submit' | 'reset';
  buttonText?: string | JSX.Element;
};

export const ConfirmButton = (props: ButtonInput) => {
  return Button({
    ...props,
    className: classNames(
      'rounded bg-red-400 hover:bg-red-500 hover:scale-105 transform transition-all text-white p-2 mx-auto',
      props.className,
    ),
  });
};
ConfirmButton.defaultProps = { buttonText: 'Confirm', buttonType: 'button' };

export const CancelButton = (props: ButtonInput) => {
  return Button({
    ...props,
    className: classNames(
      'rounded bg-gray-800 hover:bg-black hover:scale-105 transform transition-all mx-auto text-white p-2',
      props.className,
    ),
  });
};
CancelButton.defaultProps = { buttonText: 'Cancel', buttonType: 'button' };

export const ActionButton = (props: ButtonInput) => {
  return Button({
    buttonText: props.buttonText,
    buttonType: props.buttonType,
    onClick: props.onClick,
    className: classNames(
      'inline-flex items-center rounded-md border border-transparent bg-gray-900 px-4 py-2 font-medium',
      'text-white shadow-sm hover:bg-gray-700 transition duration-300 text-xs',
      props.className,
    ),
  });
};
ActionButton.defaultProps = { buttonType: 'button' };

export const SaveButton = (props: ButtonInput) => {
  return Button({
    ...props,
    className: classNames(
      'mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent',
      'bg-gray-800 px-4 py-2 font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none',
      'focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto text-xs',
      props.className,
    ),
  });
};
SaveButton.defaultProps = { buttonText: 'Save', buttonType: 'submit' };

const Button = (props: ButtonInput) => {
  return (
    <button onClick={props.onClick} type={props.buttonType} className={props.className}>
      {props.buttonText}
    </button>
  );
};
