import SimpleSection from '../../shared/SimpleSection.component';
import SimpleActionCard from '../../../custom-prebuilt/actions/SimpleActionCard.component';
import { useNavigate } from 'react-router';
import {
  PencilAltIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  InformationCircleIcon,
  EyeIcon,
} from '@heroicons/react/solid';
import { useQuery } from '@apollo/client';
import { QueryAllAnnouncements } from '../../../graphql/announcements';
import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import { useMemo } from 'react';
import Card from '../../shared/Card.component';
import Modal from '../../../custom-prebuilt/Modal.component';
import { toggleModal } from '../../../redux/modalSlice';
import CreateAnnouncementPrompt from './utils/CreateAnnouncementPrompt.component';
import { Button } from '../../../custom-prebuilt/common.component';
import { TrashIcon } from '@heroicons/react/outline';
import DeleteAnnouncementPrompt from './utils/DeleteAnnouncementPrompt.component';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';

const AnnouncementsActions = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const navigate = useNavigate();
  const show = useSelector((state) => state.slideOver.show);
  const modalShow = useSelector((state) => state.modal.show);
  const dispatch = useDispatch();

  const queryDrafts = useQuery(QueryAllAnnouncements, {
    variables: {
      where: {
        draft: true,
        organization_SOME: {
          uid: defaultOrgUid,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleOpenDrafts = () => {
    dispatch(toggleSlideOver({ show: true }));
  };

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false }));
  };

  const handleCloseModal = () => {
    dispatch(toggleModal({ show: false }));
  };

  const allDrafts = useMemo(() => {
    return (
      <div className="flex flex-col space-y-3">
        {queryDrafts.data?.announcements?.length ? (
          <>
            {queryDrafts.data?.announcements?.map((a, index) => {
              return (
                <Card key={index} title={a.title || 'No title'}>
                  <div className="flex flex-col mb-4 space-y-1">
                    <p className="text-xs">
                      Created by: <span>{a?.belongsToConnection?.edges[0]?.node?.name}</span>
                    </p>
                    <p className="text-xs">
                      Date created: <span>{a?.belongsToConnection?.edges[0]?.datePosted}</span>
                    </p>
                  </div>
                  <div className="flex flex-row space-x-1 justify-end">
                    <Button
                      onClick={() => {
                        dispatch(toggleModal({ show1: true, announcementId: a?.announcementId }));
                      }}
                      className="bg-red-600 hover:bg-red-700"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`announcements/${a?.announcementId}/create`);
                      }}
                    >
                      <EyeIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </Card>
              );
            })}
          </>
        ) : (
          <LoadingState />
        )}
      </div>
    );
  }, [queryDrafts.data]);

  return (
    <div>
      <SimpleSection className="shadow-none bg-transparent pt-0">
        <p className="text-xs font-semibold mb-4">Actions</p>
        <div className="mt-4 w-full grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-5">
          <SimpleActionCard
            title="New"
            action={() => {
              dispatch(toggleModal({ show: true }));
            }}
            icon={PencilAltIcon}
          />
          <SimpleActionCard title="Drafts" action={handleOpenDrafts} icon={DocumentDuplicateIcon} />
          <SimpleActionCard title="Archive" icon={FolderIcon} />
          <SimpleActionCard title="Expired" icon={InformationCircleIcon} />
        </div>
      </SimpleSection>

      <SlideOver
        show={show}
        title="Draft announcements"
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        content={allDrafts}
      />

      <Modal
        open={modalShow}
        title="Create announcement"
        content={<CreateAnnouncementPrompt />}
        onSecondaryButtonClick={handleCloseModal}
        secondaryButtonLabel="Cancel"
      />

      <DeleteAnnouncementPrompt />
    </div>
  );
};

export default AnnouncementsActions;
