import { configureStore } from '@reduxjs/toolkit';
import searchSlice from './searchSlice';
import navbarSlice from './navbarSlice';
import modalSlice from './modalSlice';
import paginationSlice from './paginationSlice';
import slideOverSlice from './slideOverSlice';
import orgSlice from './orgSlice';
import actionInputSlice from './actionInputSlice';
import sortTableSlice from './sortTableSlice';
import assessmentSlice from './assessmentSlice';
import bugReportSlice from './bugReportSlice';
import profileNavSlice from './profileNavSlice';
import profileGeneratorSlice from '../components/profile-generator/state/profileGeneratorSlice';
import exploreSlice from './exploreSlice';
import popOverSlice from './popOverSlice';
import addKnowledgeSlice from './addKnowledgeSlice';
import userKnowledgeSlice from './userKnowledgeSlice';
import layerSlice from './layerSlice';

const store = configureStore({
  reducer: {
    search: searchSlice,
    navbar: navbarSlice,
    modal: modalSlice,
    pagination: paginationSlice,
    slideOver: slideOverSlice,
    org: orgSlice,
    actionInput: actionInputSlice,
    sortTable: sortTableSlice,
    assessment: assessmentSlice,
    bugReport: bugReportSlice,
    profileNav: profileNavSlice,
    profileGenerator: profileGeneratorSlice,
    explore: exploreSlice,
    popOver: popOverSlice,
    addKnowledge: addKnowledgeSlice,
    userKnowledge: userKnowledgeSlice,
    layer: layerSlice,
  },
  // This was added by the profile generator because we store
  // image files within state
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
