import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PaginationState = {
  peopleCurrentPage: number;
  projectsCurrentPage: number;
  skillsCurrentPage: number;
  certificationsCurrentPage: number;
};

const initialState: PaginationState = {
  peopleCurrentPage: 1,
  projectsCurrentPage: 1,
  skillsCurrentPage: 1,
  certificationsCurrentPage: 1,
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    updatePagination: (state, action: PayloadAction<Partial<PaginationState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetPagination: () => {
      return initialState;
    },
  },
});

export const { updatePagination, resetPagination } = paginationSlice.actions;

export default paginationSlice.reducer;
