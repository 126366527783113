import Modal from '../../../../custom-prebuilt/Modal.component';
import { errorToastMid } from '../../../../lib/toast';
import { useCallback, useState } from 'react';
import ImportTaxonomyModal from './ImportTaxonomyModal';
import { TaxonomyJSON } from './ExportTaxonomy';

const ImportSkillTaxonomy = () => {
  const [open, setOpen] = useState(false);
  const [imported, setImported] = useState<TaxonomyJSON>(null as unknown as TaxonomyJSON);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <div className="text-sm bg-white border rounded-sm shadow-sm border-gray-200 hover:bg-gray-50">
        <label htmlFor="import-taxonomy" className="w-full flex items-center p-1 cursor-pointer">
          <input
            hidden
            id="import-taxonomy"
            type="file"
            accept=".json"
            onClick={(e) => {
              const element = e.target as HTMLInputElement;
              element.value = '';
            }}
            onChange={(e) => {
              const file = e?.target?.files?.[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  try {
                    const importedTaxonomy = JSON.parse(event?.target?.result as string);
                    setImported(importedTaxonomy);
                    setOpen(true);
                  } catch (e) {
                    errorToastMid('File must be valid JSON');
                  }
                };
                reader.readAsText(file);
              }
            }}
          />
          Import
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 ml-2 fill-none stroke-black stroke-[1.5]"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </label>
      </div>
      <Modal
        open={open}
        onSecondaryButtonClick={handleCloseModal}
        content={<ImportTaxonomyModal importedTaxonomy={imported} closeModal={handleCloseModal} />}
      />
    </>
  );
};

export default ImportSkillTaxonomy;
