import { CheckIcon } from '@heroicons/react/solid';

const AssessmentOverview = () => {
  return (
    <div className="flex flex-col items-center pb-6">
      <div className="bg-green-100 rounded-full w-fit p-2">
        <CheckIcon className="text-green-600 h-8 w-8" />
      </div>
      <p className="mt-4 text-sm font-medium">Assessment completed</p>
      <p className="mt-3 text-xs text-gray-500 px-6 leading-relaxed">
        Your skills has been recorded in the system. If you believe something has went wrong, please
        contact your organization administrator for further support
      </p>
    </div>
  );
};

export default AssessmentOverview;
