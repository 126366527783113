import moment from 'moment';

/**
 * Converts a date to MM/DD/YYYY format
 * @param {String} date date to be converted; can be in any date format recognized by moment
 * @param {String} defaultString string to return if the date is null or an empty string
 * @returns String
 */
export const formatDate = (date: string, defaultString = '') => {
  return date ? moment(date).format('MM/DD/YYYY') : defaultString;
};

/**
 * Returns only the year portion of a date
 * @param {String} date date to be converted; can be in any date format recognized by moment
 * @param {String} defaultString string to return if the date is null or an empty string
 * @returns String
 */
export const getYear = (date: string, defaultString = '') => {
  return date ? moment(date).format('YYYY') : defaultString;
};
