import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  skillExploreRes: [],
  selectedSkill: '',
  canvasItems: [['Canvas', []]],
  canvasColors: [],
  queryHistory: [],
  refetchQuery: '',
  currentTags: [],
  preserveCurrentTags: false,
};

export const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    toggleExplore: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleExplore } = exploreSlice.actions;

export default exploreSlice.reducer;
