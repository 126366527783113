import SimpleSection from '../../shared/SimpleSection.component';
import AnnouncementsActions from './AnnouncementsActions.component';
import { useQuery } from '@apollo/client';
import { QueryAllAnnouncements } from '../../../graphql/announcements';
import { useMemo } from 'react';
import Card from '../../shared/Card.component';
import { Button } from '../../../custom-prebuilt/common.component';
import { TrashIcon, EyeIcon } from '@heroicons/react/outline';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../redux/modalSlice';
import { useNavigate } from 'react-router';

const CurrentAnnouncements = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryActive = useQuery(QueryAllAnnouncements, {
    variables: {
      where: {
        draft: false,
        active: true,
        organization_SOME: {
          uid: defaultOrgUid,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const allActive = useMemo(() => {
    return (
      <div className="flex flex-col space-y-3">
        {queryActive.data?.announcements?.length ? (
          <>
            {queryActive.data?.announcements?.map((a, index) => {
              return (
                <Card key={index} title={a.title || 'No title'}>
                  <div className="flex flex-col mb-4 space-y-1">
                    <p className="text-xs">
                      Created by: <span>{a?.belongsToConnection?.edges[0]?.node?.name}</span>
                    </p>
                    <p className="text-xs">
                      Date created: <span>{a?.belongsToConnection?.edges[0]?.datePosted}</span>
                    </p>
                  </div>
                  <div className="flex flex-row space-x-1 justify-end">
                    <Button
                      onClick={() => {
                        dispatch(toggleModal({ show1: true, announcementId: a?.announcementId }));
                      }}
                      className="bg-red-600 hover:bg-red-700"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`announcements/${a?.announcementId}/create`);
                      }}
                    >
                      <EyeIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </Card>
              );
            })}
          </>
        ) : (
          <LoadingState />
        )}
      </div>
    );
  }, [queryActive.data]);

  return (
    <div className="h-full w-full flex flex-col space-y-4 mt-4 lg:mt-0">
      <h2 className="text-sm font-medium text-gray-900 ml-4 -mb-2">Announcements</h2>
      <AnnouncementsActions />

      <SimpleSection className="shadow-none bg-transparent pt-0 overflow-y-auto overflow-x-hidden">
        <p className="text-xs font-semibold mb-4">Active Announcements</p>
        {allActive}
      </SimpleSection>
    </div>
  );
};

export default CurrentAnnouncements;
