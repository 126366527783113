import React from 'react';
import { classNames } from '../../lib/classNames';

export enum HeaderType {
  smallHeader = 'smallHeader',
}

export interface HeaderTextInput {
  text: string;
  headerType?: HeaderType;
  className?: string;
  overrideClassName?: string;
}

const HeaderText = (props: HeaderTextInput) => {
  const smallHeaderClasses = classNames(
    props.overrideClassName ?? 'text-xs font-medium',
    props.className,
  );

  function getHeaderText() {
    switch (props.headerType) {
      case HeaderType.smallHeader:
        return <p className={smallHeaderClasses}>{props.text}</p>;
      default:
        return <></>;
    }
  }

  return <>{getHeaderText()}</>;
};
HeaderText.defaultProps = { headerType: HeaderType.smallHeader };

export default HeaderText;
