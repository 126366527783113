import { useState, useEffect, useMemo } from 'react';
import CertificationsTableView from '../components/skills/CertificationsTableView';
import { QueryCertificationTablePagination } from '../graphql/certifications';
import PageContainer from '../custom-prebuilt/PageContainer';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import SearchLogic from '../custom-prebuilt/SearchLogic.component';

const CertificationDirectory = () => {
  // Get query string from URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [searchInput, setSearchInput] = useState('');

  // Sort Selector for Certs
  const sortCertsSelector = useSelector((state) => state.sortTable.certifications);
  let sortOptions = [];
  if (sortCertsSelector.column !== '') {
    sortOptions.push({
      [sortCertsSelector.column]: sortCertsSelector.sortValue,
    });
  }

  // Limit items in 1 page
  const paginationSize = 15;
  const [statesData, setStatesData] = useState([]);
  const { data, loading } = useQuery(QueryCertificationTablePagination, {
    variables: {
      options: {
        sort: sortOptions,
      },
    },
  });

  useEffect(() => {
    if (data && data?.certifications) {
      const page = params.page ? params.page : 1;
      const tmp = [...data.certifications];
      setStatesData(
        tmp
          .filter((certification) =>
            certification.name.toLowerCase().includes(searchInput.toLowerCase()),
          )
          .slice((page - 1) * paginationSize, page * paginationSize),
      );
    }
  }, [data, searchInput, params.page]);

  const CertTableComponent = useMemo(() => {
    return (
      <CertificationsTableView
        customList={statesData}
        paginationSize={paginationSize}
        search={{
          isSearching: searchInput ? true : false,
          searchFor: 'certifications',
          filteredData: data
            ? [...data.certifications].filter((certification) =>
                certification.name.toLowerCase().includes(searchInput.toLowerCase()),
              )
            : [],
        }}
      />
    );
  }, [data, searchInput, statesData]);

  const navigateBack = () => {
    history.go(-3);
    return false;
  };

  return (
    <PageContainer
      title="Certifications Directory"
      actionName="Back to Skill Directory"
      action={navigateBack}
    >
      <div className="mt-1 mb-4">
        <input
          type="text"
          placeholder="Find certification"
          className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature sm:text-xs py-2"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <SearchLogic
        dataCheck={statesData.length > 0}
        loading={loading}
        searchInput={searchInput}
        dataComponent={CertTableComponent}
      />
    </PageContainer>
  );
};

export default CertificationDirectory;
