import HelpInformation from '../../../custom-prebuilt/HelpInformation.component';
import { useProfileGeneratorInfo } from '../state/ProfileGeneratorContext';
import Profile from '../profile/ProfileGeneratorProfile';
import ProfileToolBar from '../ProfileGeneratorToolBar';
import ReactTooltip from 'react-tooltip';
import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import History from '../ProfileGeneratorHistory';

const helpTextClasses = 'text-xs text-font-dark';

const ProfileGeneratorEditor = () => {
  const { dataDisplay, skillDisplay } = useProfileGeneratorInfo();
  const [showHelpText, setShowHelpText] = useState(false);
  const profileData = useSelector((state) => state.profileGenerator.profileData);
  const editorMode = useSelector((state) => state.profileGenerator.editorMode);
  const selectedTemplate = useSelector((state) => state.profileGenerator.selectedTemplate);
  const selectedField = useSelector((state) => state.profileGenerator.selectedField);

  const connectDataComponent = useMemo(() => {
    if (selectedField.type === 'skill') return skillDisplay;
    return dataDisplay;
  }, [dataDisplay, skillDisplay, selectedField]);

  const changeHelpTextVisibility = () => {
    setShowHelpText(!showHelpText);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const helpText = useMemo(() => {
    if (editorMode === 'editor') {
      return (
        <>
          <p className={helpTextClasses}>
            <em>Connect Data:</em> On the left side of this screen, you will find all the available
            connect data for the user. You can search for data based on the title or contents of the
            data. To use a piece of data on the profile, simply click it to copy it to your
            clipboard. You can then paste it into a field or right-click on a field to replace the
            existing content with the copied content.
          </p>
          <p className={helpTextClasses}>
            <em>Tool Bar:</em> When editing a field with styles, a style editor will appear on the
            left side of the page. Use the style editor to customize the font and line height of
            your text. To navigate between sections on the profile, use the navigation arrows on the
            right side of the page. You can switch between the editor and generation modes by
            clicking the button to the right of the navigation arrows.
          </p>
          <p className={helpTextClasses}>
            <em>Profile:</em> You can edit any field on the profile by clicking directly on its text
            or using the navigation arrows on the toolbar. Fields that require additional
            information will be marked with a red dot, indicating that they should be completed. You
            can also rearrange a list&apos;s items by dragging and dropping them to a new location!
          </p>
        </>
      );
    } else if (editorMode === 'generate') {
      return (
        <>
          <p className={helpTextClasses}>
            Now is your chance to review your profile before generating it. Generating your profile
            will save it so you can view and edit it later, and will also enable additional
            features. You can currently download generated profiles as PDFs and generate a shareable
            link that is valid for three days. Be mindful when sending a shareable link, as it will
            automatically update with the most recently generated content.
          </p>
        </>
      );
    }
  }, [editorMode]);

  return (
    <>
      <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="profileGenHelp" />
      <div className="grid grid-cols-2 gap-4 overflow-hidden">
        <div className="grid grid-rows-[min-content_1fr] overflow-hidden">
          <HelpInformation
            additionalHelpOnClick={changeHelpTextVisibility}
            title={'Profile Editor Help'}
            className="h-min mb-4"
            titleClassName="mb-0"
          >
            {showHelpText && helpText}
          </HelpInformation>
          {editorMode === 'editor' && (
            <div className="overflow-scroll mb-4">{connectDataComponent}</div>
          )}
          {editorMode === 'generate' && <div className="overflow-scroll mb-4">{<History />}</div>}
        </div>
        <div className="flex flex-col gap-2 overflow-scroll mb-4">
          <ProfileToolBar />
          <Profile
            profileData={profileData}
            template={selectedTemplate?.template}
            templateID={selectedTemplate?.templateID}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileGeneratorEditor;
