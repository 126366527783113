import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, H2, Input, Label, Legend } from '../../custom-prebuilt/common.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { QueryPersonDetails, UpdatePersonDetails } from '../../graphql/people';
import { useSelector } from 'react-redux';
import { useAuth } from '../../lib/authContext';

/**
 * Settings section for editing a user's company information
 * @returns form for editing a user's company information
 */
const SettingsCompany = () => {
  const { userEmail } = useAuth();

  const [position, setPosition] = useState('');
  const [department, setDepartment] = useState('');
  const [startDate, setStartDate] = useState('');
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QueryPersonDetails, {
    variables: {
      where: {
        email: userEmail,
      },
      orgUnitsConnectionWhere2: {
        node: {
          organization: {
            uid: defaultOrgUid,
          },
        },
      },
    },
    skip: !userEmail,
  });

  const [updateSettingsCompany, { loading: updateLoading, error: updateError }] = useMutation(
    UpdatePersonDetails,
    {
      refetchQueries: ['QueryPersonDetails'],
    },
  );

  //error handling?
  if (error || updateError) {
    console.error(error, updateError);
  }

  const loading = queryLoading || updateLoading;

  const updateFields = (companyInfo) => {
    if (companyInfo.department !== null) setDepartment(companyInfo.department);
    if (companyInfo.position !== null) setPosition(companyInfo.position);
    if (companyInfo.startDate !== null) setStartDate(companyInfo.startDate);
  };

  useEffect(() => {
    const companyInfo = data?.people?.[0]?.orgUnitsConnection?.edges?.[0];
    if (companyInfo) {
      updateFields(companyInfo);
    }
  }, [data]);

  const onSave = () => {
    //validation?

    const response = updateSettingsCompany({
      variables: {
        where: {
          email: userEmail,
        },
        update: {
          orgUnits: [
            {
              where: {
                node: {
                  organization: {
                    uid: defaultOrgUid,
                  },
                },
              },
              update: {
                edge: {
                  department: department,
                  position: position,
                  startDate: startDate,
                },
              },
            },
          ],
        },
      },
    });

    toast.promise(response, {
      success: 'Company settings saved!',
      error: 'An error occurred!',
    });
  };

  return (
    <>
      {!data ? (
        <LoadingState />
      ) : (
        <div className="flex flex-col p-4 w-full md:w-2/3 xl:w-1/3 h-full overflow-y-auto">
          <H2 className="mb-2">Company Information</H2>
          <Legend>To change information about your company, contact your administrator.</Legend>

          <div className="flex items-center my-6 border border-borders rounded-md bg-white w-full">
            <img
              src={
                !data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.logo
                  ? '//:0'
                  : `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.logo}`
              }
              className="rounded-full w-[84px] h-[84px] m-4"
              alt=""
            />
            <div className="flex flex-col space-y-1">
              <H2>
                {!data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.name
                  ? 'Company Name'
                  : data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.name}
              </H2>
              <span className="text-xs text-font-dark">
                {!data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.motto
                  ? 'Motto'
                  : data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.motto}
              </span>
              <span className="text-xs font-bold text-font-dark">
                {!data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.locationCity ||
                !data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.locationState
                  ? 'Location'
                  : `${data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.locationCity}, ${data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.locationState}`}
              </span>
              <span className="text-xs text-font-light">
                {!data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node?.memberCount
                  ? 'Member Count'
                  : `${data?.people?.[0]?.orgUnitsConnection?.edges?.[0].node.memberCount} members`}
              </span>
            </div>
          </div>

          <Label>
            Position/Title
            <Input
              disabled={loading}
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Label>
          <Label>
            Department
            <Input
              type="text"
              disabled={loading}
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
            />
          </Label>
          <Label>
            Start Date
            <Input
              type="date"
              disabled={loading}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Label>
          <Button
            className="self-end mt-14"
            onClick={(e) => {
              onSave();
              e.target.blur();
            }}
            disabled={loading}
          >
            Save Changes
          </Button>
        </div>
      )}
    </>
  );
};

export default SettingsCompany;
