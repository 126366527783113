import Card from "../../../shared/Card.component"
import SearchLogic from "../../../../custom-prebuilt/SearchLogic.component"
import { Button, Input } from "../../../../custom-prebuilt/common.component"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleExplore } from "../../../../redux/exploreSlice"
import { toggleSlideOver } from "../../../../redux/slideOverSlice"

const QueryHistory = () => {
  const [searchInput, setSearchInput] = useState("")
  const dispatch = useDispatch()
  const queryHistory = useSelector(state => state.explore.queryHistory)

  const [filteredQueries, setFilteredQueries] = useState(queryHistory)

  useEffect(() => {
    queryHistory && setFilteredQueries(queryHistory)
  }, [queryHistory])

  const handleInputChange = (e) => {
    setSearchInput(e.target.value)
    setFilteredQueries(queryHistory?.filter(
      q => q.toLowerCase().includes(e.target.value.toLowerCase())
    ))
  }

  const selectQuery = (query) => {
    dispatch(toggleExplore({ refetchQuery: query }))
    dispatch(toggleSlideOver({ show2: false }))
  }

  const deleteQuery = (index) => {
    const temp = [...queryHistory]
    temp.splice(index, 1)
    dispatch(toggleExplore({ queryHistory: temp }))
  }

  return (
    <div>
      <Input
        id="query-history"
        name="query-history"
        type="text"
        value={searchInput}
        onChange={handleInputChange}
        placeholder="Search query"
      />
      <SearchLogic
        loading={false}
        dataCheck={filteredQueries?.length}
        searchInput={searchInput}
        dataComponent={
          <div className="space-y-2">
            {
              filteredQueries?.map((query, index) => (
                <Card
                  key={index}
                  title={query === '' ? '<Query all users>' : query}>
                  <div className="space-x-1">
                    <Button onClick={() => { deleteQuery(index) }} className="bg-red-600 hover:bg-red-700">Delete</Button>
                    <Button onClick={() => { selectQuery(query) }}>Retrieve</Button>
                  </div>
                </Card>
              ))
            }
          </div>
        } />
    </div>
  )
}

export default QueryHistory