import PaginationNav from '../../custom-prebuilt/PaginationNav.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import SortIndicator from '../../custom-prebuilt/SortIndicator.component';
import { classNames } from '../../lib/classNames';

const TableView = (props) => {
  const {
    data,
    tableHeadItems,
    paginationSize,
    RenderBody,
    page,
    renderActionCol,
    search,
    showPaginationNav,
    loading,
  } = props;

  const sortObject = useSelector((state) => state.sortTable);

  const showLoader = loading == undefined ? !data || data.length === 0 : loading;

  return (
    <>
      {showLoader ? (
        <LoadingState />
      ) : (
        <div className="space-y-4">
          {showPaginationNav && (
            <PaginationNav
              table={page}
              limitPagination={paginationSize}
              search={search}
              totalCount={props.total}
            />
          )}

          <div className="">
            <div className="flex flex-col">
              <div className="overflow-x-auto -my-2">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table
                      className={classNames('min-w-full divide-y divide-gray-300', props.className)}
                    >
                      <thead className="bg-gray-50">
                        <tr>
                          {tableHeadItems.map((item) => {
                            return (
                              <Fragment key={item.name}>
                                {(item.name !== 'action' || renderActionCol) && (
                                  <th
                                    scope="col"
                                    className={
                                      item.customClassName && item.customClassName !== ''
                                        ? item.customClassName
                                        : 'cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900'
                                    }
                                    onClick={() => item.sortable && item.sortingHandler(item.name)}
                                  >
                                    <div className="flex flex-row items-center justify-between">
                                      {item.customComponent ? (
                                        <span>{item.customComponent}</span>
                                      ) : (
                                        <span>{item.title}</span>
                                      )}
                                      <SortIndicator item={item} sortObject={sortObject} />
                                    </div>
                                  </th>
                                )}
                              </Fragment>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white cursor-pointer">
                        <RenderBody />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showPaginationNav && (
            <PaginationNav
              table={page}
              limitPagination={paginationSize}
              search={search}
              totalCount={props.total}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TableView;
