import { classNames } from '../../lib/classNames';

export type KBDProps = {
  children: JSX.Element;
  className?: string;
};

const KBD = ({ children, className }: KBDProps) => {
  return (
    <kbd
      className={classNames(
        `mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 sm:inline-flex border-gray-400 text-gray-900`,
        className,
      )}
    >
      {children}
    </kbd>
  );
};

export default KBD;
