import Modal from '../../../../custom-prebuilt/Modal.component';
import { toggleModal } from '../../../../redux/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteAnnouncement, QueryAllAnnouncements } from '../../../../graphql/announcements';
import { useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';

const DeleteAnnouncementPrompt = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modal.show1);
  const announcementId = useSelector((state) => state.modal.announcementId);

  const [deleteAnnouncement] = useMutation(DeleteAnnouncement, {
    refetchQueries: () => [
      {
        query: QueryAllAnnouncements,
        variables: {
          where: {
            draft: true,
            organization_SOME: {
              uid: defaultOrgUid,
            },
          },
        },
      },
      {
        query: QueryAllAnnouncements,
        variables: {
          where: {
            draft: false,
            active: true,
            organization_SOME: {
              uid: defaultOrgUid,
            },
          },
        },
      },
    ],
  });

  const handleCloseModal = () => {
    dispatch(toggleModal({ show1: false }));
  };

  const handleDeleteAnnouncement = () => {
    toast.promise(
      deleteAnnouncement({
        variables: {
          where: {
            announcementId: announcementId,
          },
        },
      }).then(() => {
        dispatch(toggleModal({ show1: false }));
      }),
      {
        loading: 'Deleting...',
        success: 'Successfully deleted',
        error: 'Could not delete',
      },
    );
  };

  return (
    <Modal
      open={show}
      title="Delete announcement"
      content={<p className="text-xs mb-2">This action can&apos;t be undone. Are you sure?</p>}
      onSecondaryButtonClick={handleCloseModal}
      secondaryButtonLabel="Cancel"
      destructiveButtonLabel="Delete"
      onDestructiveButtonClick={handleDeleteAnnouncement}
    />
  );
};

export default DeleteAnnouncementPrompt;
