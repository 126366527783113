import { Switch } from '@headlessui/react';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Label } from '../../../custom-prebuilt/common.component';
import cronstrue from 'cronstrue';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Modal from '../../../custom-prebuilt/Modal.component';
import ConnectorLogs from './ConnectorLogs';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { toast } from 'react-hot-toast';
import { api_triggerConnector } from '../people/utils/fetchResp';
import { Auth } from 'aws-amplify';

const defaultPeopleWithExperience = {
  active: '',
  startDate: '',
  endDate: '',
  hoursPerWeek: '',
  isSideHustle: '',
  role: '',
  totalHours: '',
};

const ConnectorDetails = ({ connectorData, updateConnector }) => {
  const [parent] = useAutoAnimate();
  const cronExpression = useMemo(() => {
    const cronExpRegex = /\(([^)]+)\)/;
    return connectorData.run_cadence ? cronExpRegex.exec(connectorData.run_cadence)[1] : "* * * * *";
  }, [connectorData.run_cadence]);
  const [viewLogs, setViewLogs] = useState(false);
  const [editing, setEditing] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [enabled, setEnabled] = useState(connectorData.enabled);
  const [runCadence, setRunCadence] = useState(cronExpression);
  const [projectId, setProjectId] = useState(connectorData?.projectId_map ?? '');
  const [industry, setIndustry] = useState(connectorData?.project_schema?.industry ?? '');
  const [companyName, setCompanyName] = useState(connectorData?.project_schema?.company ?? '');
  const [location, setLocation] = useState(connectorData?.project_schema?.location ?? '');
  const [description, setDescription] = useState(connectorData?.project_schema?.description ?? '');
  const [projectName, setProjectName] = useState(connectorData?.project_schema?.name ?? '');
  const [startDate, setStartDate] = useState(connectorData?.project_schema?.startDate ?? '');
  const [endDate, setEndDate] = useState(connectorData?.project_schema?.endDate ?? '');
  const [formattedCron, setFormattedCron] = useState({
    text: cronstrue.toString(cronExpression),
    error: false,
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (newValue, setState) => {
    setState(newValue);
    if (!inputChanged) setInputChanged(true);
  };

  const handleCancelUpdate = () => {
    setRunCadence(cronExpression);
    setFormattedCron({
      text: cronstrue.toString(cronExpression),
      error: false,
    });
    setProjectId(connectorData?.projectId_map ?? '');
    setIndustry(connectorData?.project_schema?.industry ?? '');
    setCompanyName(connectorData?.project_schema?.company ?? '');
    setLocation(connectorData?.project_schema?.location ?? '');
    setDescription(connectorData?.project_schema?.description ?? '');
    setProjectName(connectorData?.project_schema?.name ?? '');
    setStartDate(connectorData?.project_schema?.startDate ?? '');
    setEndDate(connectorData?.project_schema?.endDate ?? '');
    setInputChanged(false);
  };

  const handleUpdateConnector = () => {
    const projectSchema = {
      active: connectorData?.project_schema?.active ?? '',
      company: companyName,
      description: description,
      startDate: startDate,
      endDate: endDate,
      hyperlinks: connectorData?.project_schema?.hyperlinks ?? [''],
      industry: industry,
      location: location,
      name: projectName,
      sector: connectorData?.project_schema?.sector ?? '',
      peopleWithExperience:
        connectorData?.project_schema?.peopleWithExperience ?? defaultPeopleWithExperience,
    };
    updateConnector({
      ...connectorData,
      run_cadence: `cron(${runCadence})`,
      projectId_map: projectId,
      project_schema: projectSchema,
    });
    setInputChanged(false);
  };

  const handleTrigger = async (connector) => {
    if (connector.enabled === false) {
      toast.error(`${connector.connector} connector is disabled.`);
    }
    else {
      try {
        await Auth.currentAuthenticatedUser().then((user) => {
          user.getSession((err, session) => {
            if (err) {
              throw new Error(err);
            }
            const sessionToken = session?.getAccessToken().getJwtToken();
            api_triggerConnector(sessionToken, connector).then(() => {
              toast.success(`${connector.connector} Connector triggered successfully.`);
            });
          });
        })
      } catch (error) {
        toast.error("Could not trigger Connector.")
      }
    }
    setLoading(false);
  }

  return (
    <>
      {
        loading
          ? <LoadingState />
          : <div ref={parent} className="relative z-0 rounded-lg shadow bg-white mt-4 w-2/3">
            <div className="p-4 w-full flex flex-row justify-between items-center">
              <div className="flex items-center">
                <div
                  className={`text-[9px] border rounded-full p-0.5 ${enabled
                    ? 'bg-green-300 text-green-800 border-green-800'
                    : 'bg-red-300 text-red-700 border-red-700'
                    }`}
                >
                  {enabled ? 'Enabled' : 'Disabled'}
                </div>
                <h3 className="ml-2 text-base text-gray-900 font-semibold">
                  {_.startCase(connectorData.connector)}
                </h3>
              </div>
              <div className="flex items-center space-x-4">
                <Switch
                  onChange={() => {
                    setEnabled(!enabled);
                    updateConnector({ ...connectorData, enabled: !enabled });
                  }}
                  checked={enabled}
                  className={`${enabled ? 'bg-green-300 border-green-800' : 'bg-red-300 border-red-700'
                    } border relative inline-flex h-6 w-10 items-center rounded-full`}
                >
                  <span
                    className={`${enabled ? 'translate-x-1 border-green-800' : 'translate-x-5 border-red-700'
                      } inline-block border h-4 w-4 rounded-full bg-white transform transition`}
                  />
                </Switch>
                <button
                  onClick={() => setViewLogs(true)}
                  className="text-gray-900 border-gray-900 border rounded-md px-3 py-1.5 text-xs font-medium bg-white hover:bg-gray-50"
                >
                  View Logs
                </button>
                <button
                  onClick={() => {
                    setLoading(true);
                    handleTrigger(connectorData)
                  }}
                  className="text-white rounded-md px-3 py-1.5 text-xs font-medium bg-green-600 hover:bg-green-500"
                >
                  Trigger
                </button>
                <button
                  onClick={() => setEditing(!editing)}
                  className="text-white rounded-md px-3 py-1.5 text-xs font-medium bg-bg-dark hover:bg-gray-700"
                >
                  Edit
                </button>
                <button className="text-white rounded-md px-3 py-1.5 text-xs font-medium bg-red-800 hover:bg-red-700">
                  Delete
                </button>
              </div>
            </div>
            {editing && (
              <div className="w-full p-4 max-h-[300px] overflow-y-auto flex flex-col border-t border-borders">
                <h3 className="text-sm font-medium text-gray-900">Edit Connector Details</h3>
                <div className="flex flex-row">
                  <Label className="flex flex-col w-1/2">
                    Connector Run Cadence:{' '}
                    <input
                      value={runCadence}
                      onChange={(e) => {
                        handleInputChange(e.target.value, setRunCadence);
                        try {
                          setFormattedCron({ text: cronstrue.toString(e.target.value), error: false });
                        } catch {
                          setFormattedCron({ text: 'Invalid cron expression', error: true });
                        }
                      }}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      placeholder="When and how often should this connector run"
                      type="text"
                    />
                    <span
                      className={`text-[10px] italic font-normal ${formattedCron.error ? 'text-red-500' : 'text-gray-500'
                        }`}
                    >
                      {formattedCron.text}
                    </span>
                  </Label>
                </div>
                <h3 className="text-sm font-medium text-gray-900 mt-4">
                  Edit Connector Mapping Fields
                </h3>
                <div className="w-full grid grid-cols-2">
                  <Label className="flex flex-col">
                    Project ID Field:{' '}
                    <input
                      value={projectId}
                      onChange={(e) => handleInputChange(e.target.value, setProjectId)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    Industry Field:{' '}
                    <input
                      value={industry}
                      onChange={(e) => handleInputChange(e.target.value, setIndustry)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    Company Name Field:{' '}
                    <input
                      value={companyName}
                      onChange={(e) => handleInputChange(e.target.value, setCompanyName)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    Location Field:{' '}
                    <input
                      value={location}
                      onChange={(e) => handleInputChange(e.target.value, setLocation)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    Description Field:{' '}
                    <input
                      value={description}
                      onChange={(e) => handleInputChange(e.target.value, setDescription)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    Project Name Field:{' '}
                    <input
                      value={projectName}
                      onChange={(e) => handleInputChange(e.target.value, setProjectName)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    Start Date Field:{' '}
                    <input
                      value={startDate}
                      onChange={(e) => handleInputChange(e.target.value, setStartDate)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                  <Label className="flex flex-col">
                    End Date Field:{' '}
                    <input
                      value={endDate}
                      onChange={(e) => handleInputChange(e.target.value, setEndDate)}
                      className="w-3/4 text-xs font-normal shadow-sm rounded-md border border-borders my-2 px-2 h-8 focus:border-signature"
                      type="text"
                    />
                  </Label>
                </div>
              </div>
            )}
            {editing && inputChanged && (
              <div className="sticky z-10 bottom-0 rounded-b-md w-full flex flex-row space justify-end items-center p-4 border-t border-borders bg-white">
                <button
                  disabled={formattedCron.error}
                  onClick={() => handleUpdateConnector()}
                  className="text-white rounded-md mr-4 px-3 py-1.5 text-xs font-medium bg-bg-dark hover:bg-gray-700 disabled:bg-gray-900"
                >
                  Save
                </button>
                <button
                  onClick={() => handleCancelUpdate()}
                  className="text-white rounded-md px-3 py-1.5 text-xs font-medium bg-red-800 hover:bg-red-700"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
      }

      <Modal
        size="large"
        open={viewLogs}
        title={`${_.startCase(connectorData.connector)} Connector Logs`}
        onSecondaryButtonClick={() => setViewLogs(false)}
        content={<ConnectorLogs connectorId={connectorData.connector} />}
      />
    </>
  );
};

export default ConnectorDetails;
