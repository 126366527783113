import ClickNHold from 'react-click-n-hold';
import '../styles/clickNHold.scss';
import { useDispatch } from 'react-redux';
import { toggleSlideOver } from '../redux/slideOverSlice';

/**
 * Type Definition
 */
type SkillType = {
  imageLink: string;
  name: string;
};
type SkillNode = {
  node: SkillType;
};
export type ClickNHoldSkillProps = {
  skill: SkillType;
  preSelected?: SkillNode[];
  disable?: boolean;
};
/**
 * A skill div that can be "click-n-hold"
 * @param {*} skill A skill object to be displayed
 * @param {*} preSelected (Optional) List of selected skills (skill must be configed as {...field: ...value})
 * @param {*} disable (Optional) If provided, click n hold effect will be disabled
 */
const ClickNHoldSkill = ({ skill, preSelected, disable }: ClickNHoldSkillProps) => {
  const dispatch = useDispatch();

  const start = () => {};

  const clickNHold = (skill: any) => {
    dispatch(toggleSlideOver({ show: true, skill: skill }));
  };

  return (
    <>
      {!disable ? (
        <ClickNHold
          time={1.5}
          onStart={() => {
            start();
          }}
          onClickNHold={() => {
            clickNHold(skill);
          }}
        >
          <div
            className={`rounded-lg shadow h-fit w-fit p-2 cursor-pointer transition duration-150 mr-3 mb-3 relative 
              ${
                preSelected && preSelected.map((s) => s.node.name).includes(skill.name)
                  ? 'bg-green-400'
                  : 'bg-white hover:bg-gray-200'
              }`}
          >
            <img
              src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`}
              alt=""
              draggable={false}
              className="rounded-full select-none pointer-events-none h-full w-full"
            />
          </div>
        </ClickNHold>
      ) : (
        <div
          className={`rounded-lg shadow h-fit w-fit p-2 cursor-pointer transition duration-150 mr-3 mb-3 relative`}
        >
          <img
            src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.imageLink}`}
            alt=""
            draggable={false}
            className="rounded-full select-none pointer-events-none h-full w-full"
          />
        </div>
      )}
    </>
  );
};

export default ClickNHoldSkill;
