/**
 * General layout for profile page.
 * @param {*} com1 Component 1 
 * @param {*} com2 Component 2
 * @param {*} com3 component 3
 */

const ProfileLayout = ({com1, com2, com3}) => {
  return (
    <>
      <div className='col-span-1 flex flex-col space-y-5'>
        <div className="row-span-1">
          {com1}
        </div>

        <div className="row-span-1">
          {com2}
        </div>
      </div>

      <div className='col-span-1'>
        {com3}
      </div>
    </>
  )
}

export default ProfileLayout