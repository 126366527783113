import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RefreshIcon,
  DownloadIcon,
  LinkIcon,
  DocumentAddIcon,
  DocumentIcon,
} from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectField, setProfileGeneratorState } from './state/profileGeneratorSlice';
import StyleInputs from './ProfileGeneratorProfileInputStyles';
import { useProfileGeneratorInfo } from './state/ProfileGeneratorContext';
import ReactTooltip from 'react-tooltip';
import { mergeClasses } from '../../lib/classNames';

const EditorIcon = ({ className }) => {
  return (
    <div className={mergeClasses('relative w-5 h-5 flex justify-center items-center', className)}>
      <DocumentIcon className="absolute w-full" />
      <PencilIcon className="absolute w-1/2 mt-[10%]" />
    </div>
  );
};

const ReGenerateIcon = ({ className }) => {
  return (
    <div className={mergeClasses('relative w-5 h-5 flex justify-center items-center', className)}>
      <DocumentIcon className="absolute w-full" />
      <RefreshIcon className="absolute w-1/2 mt-[10%] stroke-[2.5px]" />
    </div>
  );
};

const Decorator = ({ disabled, action, Icon, error, helpText, className }) => {
  const decoratorStatusClasses = useMemo(() => {
    if (disabled) return 'text-font-light';
    if (error) return 'text-red-400';
    return 'text-font-dark';
  }, [disabled, error]);

  return (
    <button
      data-for="profileGenHelp"
      data-tip={helpText}
      disabled={disabled}
      onClick={() => action(disabled)}
    >
      <Icon aria-disabled className={mergeClasses(`w-5 ${decoratorStatusClasses}`, className)} />
    </button>
  );
};

const ProfileToolBar = () => {
  const { openRegenerateModal, downloadSelectedProfile, openGenerateModal, createDownloadLink } =
    useProfileGeneratorInfo();
  const profileID = useSelector((state) => state.profileGenerator.profileID);
  const profileStatus = useSelector((state) => state.profileGenerator.profileStatus);
  const profileData = useSelector((state) => state.profileGenerator.profileData);
  const editorMode = useSelector((state) => state.profileGenerator.editorMode);
  const profileFieldList = useSelector((state) => state.profileGenerator.profileFieldList);

  const Generate = ({ disabled, action, helpText }) => (
    <Decorator
      helpText={helpText ?? 'Generate Profile'}
      disabled={disabled}
      action={action ?? openGenerateModal}
      Icon={DocumentAddIcon}
    />
  );
  const Download = ({ disabled }) => (
    <Decorator
      helpText={'Download Profile'}
      disabled={disabled}
      action={downloadSelectedProfile}
      Icon={DownloadIcon}
    />
  );
  const ReGenerate = ({ disabled, action, helpText }) => (
    <Decorator
      helpText={helpText ?? 'Re-Generate Profile'}
      disabled={disabled}
      action={action ?? openRegenerateModal}
      Icon={ReGenerateIcon}
    />
  );
  const CopyLink = ({ disabled }) => (
    <Decorator
      helpText={'Get Shareable Link'}
      disabled={disabled}
      action={createDownloadLink}
      Icon={LinkIcon}
    />
  );

  const getGenerationOption = () => {
    const generationOption = { isGenerate: true, helpText: 'Generate Profile' };
    if (profileStatus === 'Not Generated') {
      generationOption.disabled = false;
    } else if (profileStatus === 'Regeneration Available') {
      generationOption.isGenerate = false;
      generationOption.disabled = false;
    } else if (profileStatus === 'Generated') {
      generationOption.isGenerate = false;
      generationOption.disabled = true;
    } else if (profileStatus === 'Error') {
      generationOption.disabled = false;
    } else {
      generationOption.disabled = true;
      if (typeof profileID !== 'undefined') {
        generationOption.isGenerate = false;
      }
    }

    return generationOption;
  };

  const generationTools = useMemo(() => {
    const decoratorsDisabled = { copyLink: true, download: true };
    if (profileStatus === 'Generated') {
      decoratorsDisabled.copyLink = false;
      decoratorsDisabled.download = false;
    }
    const generationOption = getGenerationOption();
    let generationComponent;
    if (generationOption.isGenerate) {
      generationComponent = <Generate disabled={generationOption.disabled} />;
    } else {
      generationComponent = <ReGenerate disabled={generationOption.disabled} />;
    }

    return (
      <div className="grid grid-cols-[min-content_min-content_min-content_min-content] w-min gap-2 shadow-md bg-white rounded-md p-2">
        {generationComponent}
        <CopyLink disabled={decoratorsDisabled.copyLink} />
        <Download disabled={decoratorsDisabled.download} />
      </div>
    );
  }, [profileStatus, profileData]);

  const selectedField = useSelector((state) => state.profileGenerator.selectedField);
  const dispatch = useDispatch();
  const selectedIndex = useMemo(
    () => profileFieldList.findIndex((value) => selectedField.path.includes(value.path)),
    [selectedField],
  );

  const nextField = () => {
    const newIndex = selectedIndex + 1;
    if (newIndex < profileFieldList.length) dispatch(selectField(profileFieldList[newIndex]));
    else dispatch(selectField(profileFieldList[profileFieldList.length - 1]));
  };

  const prevField = () => {
    const newIndex = selectedIndex - 1;
    if (newIndex >= 0 && profileFieldList.length !== 0)
      dispatch(selectField(profileFieldList[newIndex]));
    else dispatch(selectField(profileFieldList[0]));
  };

  const swapEditorMode = () => {
    let newValue = 'generate';
    if (editorMode === 'generate') newValue = 'editor';
    ReactTooltip.hide();
    dispatch(setProfileGeneratorState({ editorMode: newValue }));
  };

  const styleComponents = useMemo(
    () => <StyleInputs path={selectedField.path} index={selectedField.index} />,
    [selectedField],
  );
  const editorModeComponent = useMemo(() => {
    if (editorMode === 'editor') {
      const generationOption = getGenerationOption();
      const generationIcon = generationOption.isGenerate ? DocumentAddIcon : ReGenerateIcon;
      return (
        <Decorator Icon={generationIcon} helpText={'Go to Generate'} action={swapEditorMode} />
      );
    } else if (editorMode === 'generate') {
      return <Decorator helpText={'Go to Editor'} Icon={EditorIcon} action={swapEditorMode} />;
    }
  }, [editorMode, profileStatus]);
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="flex justify-between w-full select-none pr-[16px]">
      {editorMode === 'editor' && styleComponents}
      {editorMode === 'generate' && (
        <p className="text-xs self-end text-font-dark font-bold">{`Profile Status: ${profileStatus}`}</p>
      )}
      <div className="flex gap-2">
        {editorMode === 'editor' && (
          <div className="bg-white self-end shadow-md rounded-md flex items-center p-2 gap-2 w-min h-min">
            <ArrowLeftIcon
              data-for="profileGenHelp"
              data-tip="Previous Section"
              onClick={prevField}
              className="text-font-dark font-4 w-4 cursor-pointer"
            />
            <ArrowRightIcon
              data-for="profileGenHelp"
              data-tip={'Next Section'}
              onClick={nextField}
              className="text-font-dark font-4 w-4 cursor-pointer"
            />
          </div>
        )}
        {editorMode === 'generate' && generationTools}
        <div className="bg-white self-end shadow-md rounded-md flex items-center p-2 gap-2 w-min h-min">
          {editorModeComponent}
        </div>
      </div>
    </div>
  );
};

export default ProfileToolBar;
