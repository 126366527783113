import { BookmarkIcon, CursorClickIcon, ChartBarIcon, CheckIcon } from '@heroicons/react/outline';
import NestedVerticalNav from '../../../../../custom-prebuilt/NestedVerticalNav.component';
import { useEffect } from 'react';
import { useAuth } from '../../../../../lib/authContext';
import { QueryPersonSkills } from '../../../../../graphql/people';
import { toggleAssessment } from '../../../../../redux/assessmentSlice';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

const JGSkillAssessment = ({ assessment }) => {
  const { cognitoID } = useAuth();
  const dispatch = useDispatch();
  const [queryPersonSkills] = useLazyQuery(QueryPersonSkills, {
    variables: {
      where: {
        cognitoID,
      },
    },
  });

  useEffect(() => {
    dispatch(toggleAssessment({ selectedSkills: [], loading: true }));
    queryPersonSkills().then(({ data }) => {
      dispatch(
        toggleAssessment({
          selectedSkills: data?.people[0]?.skillsConnection?.edges ?? [],
          loading: false,
        }),
      );
    });
  }, [assessment]);

  return (
    <NestedVerticalNav
      title={`Assessment: ${assessment?.data?.assessments[0]?.name}`}
      menuTitle="Sections"
      navigation={[
        {
          name: 'Introduction',
          href: 'introduction',
          icon: BookmarkIcon,
        },
        {
          name: 'Select skills',
          href: 'select',
          icon: CursorClickIcon,
        },
        {
          name: 'Rate skills',
          href: 'rate',
          icon: ChartBarIcon,
        },
        {
          name: 'Confirmation',
          href: 'confirmation',
          icon: CheckIcon,
        },
      ]}
    ></NestedVerticalNav>
  );
};

export default JGSkillAssessment;
