import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesState,
  Panel,
} from 'reactflow';
import 'reactflow/dist/base.css';
import PageContainer from '../../../custom-prebuilt/PageContainer';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { GetOrgTaxonomy, GetSkillPath, GetTaxonomySubtree } from '../../../graphql/taxonomy';
import { getEdges, getNodes } from '../../../lib/taxonomyHelpers';
import SkillCategoryNode from './nodes/SkillCategoryNode';
import SkillNode from './nodes/SkillNode';
import TaxonomyNode from './nodes/TaxonomyNode';

//readonly version of the Taxonomy view
const SkillTaxonomyView = () => {
  const nodeTypes = useMemo(
    () => ({ Taxonomy: TaxonomyNode, SkillCategory: SkillCategoryNode, Skill: SkillNode }),
    [],
  );
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const [getFullTaxonomy] = useLazyQuery(GetOrgTaxonomy, {
    fetchPolicy: 'no-cache',
  });
  const [getCategorySubtree] = useLazyQuery(GetTaxonomySubtree, {
    fetchPolicy: 'no-cache',
  });
  const [getSkillPath] = useLazyQuery(GetSkillPath, {
    fetchPolicy: 'no-cache',
  });
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const loadFullTaxonomy = () => {
    getFullTaxonomy({
      variables: {
        orgUid: defaultOrgUid,
      },
    }).then(({ data }) => {
      const nodeArray = data?.getOrgTaxonomy;
      const formattedNodes = getNodes(nodeArray);
      const formattedEdges = getEdges(nodeArray);
      setNodes(formattedNodes);
      setEdges(formattedEdges);
    });
  };

  const loadCategorySubtree = (rootId) => {
    getCategorySubtree({
      variables: {
        rootId,
      },
    }).then(({ data }) => {
      const nodeArray = data?.getTaxonomySubtree;
      const formattedNodes = getNodes(nodeArray);
      const formattedEdges = getEdges(nodeArray);
      setNodes(formattedNodes);
      setEdges(formattedEdges);
    });
  };

  const loadSkillPath = (skillId) => {
    getSkillPath({
      variables: {
        skillId,
      },
    }).then(({ data }) => {
      const nodeArray = data?.getSkillPath;
      const formattedNodes = getNodes(nodeArray);
      const formattedEdges = getEdges(nodeArray);
      setNodes(formattedNodes);
      setEdges(formattedEdges);
    });
  };

  useEffect(() => {
    loadFullTaxonomy();
  }, []);

  const navigateBack = () => {
    history.go(-3);
    return false;
  };

  return (
    <PageContainer
      title="Skill Taxonomy"
      className="h-screen flex flex-col"
      actionName="Back to Skill Directory"
      action={navigateBack}
    >
      <div className="w-full h-full">
        {!nodes.length ? (
          <LoadingState />
        ) : (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            onNodeClick={(e, node) => {
              if (node.data.type === 'SkillCategory') {
                loadCategorySubtree(node.data.id);
                return;
              }
              if (node.data.type === 'Skill') {
                loadSkillPath(node.data.id);
                return;
              }
            }}
            fitView
          >
            <MiniMap />
            <Controls showInteractive={false} />
            <Background />
            <Panel position="top-left">
              <button
                className="bg-white rounded-md p-1 px-2 text-sm font-medium border border-black hover:bg-gray-50"
                onClick={() => {
                  loadFullTaxonomy();
                }}
              >
                Reset
              </button>
            </Panel>
          </ReactFlow>
        )}
      </div>
    </PageContainer>
  );
};

export default SkillTaxonomyView;
