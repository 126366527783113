import { Auth } from 'aws-amplify';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import Modal from '../../../custom-prebuilt/Modal.component';

const ForgotPassword = ({ show, setShowModal }) => {
  /**
   * renderInputEmail
   *    - true: user enter email
   *    - false: user enter password reset code
   */
  const [renderInputEmail, setRenderInputEmail] = useState(true);

  const handleClose = () => {
    setShowModal(false);
    handleResetStates();
  };

  // Enter email
  const [email, setEmail] = useState('');

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  // Send confirmation code to user's email
  const handleSubmitEmail = useCallback(
    async (e) => {
      e.preventDefault();
      Auth.forgotPassword(email)
        .then(() => setRenderInputEmail(false))
        .catch((err) => toast.error(err.message));
    },
    [email],
  );

  const ForgotPasswordComponent = useMemo(() => {
    return (
      <form onSubmit={handleSubmitEmail}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              required
              value={email}
              onChange={handleChangeEmail}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm"
            />
          </div>
        </div>

        <div className="!mt-6">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-signature py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-signature focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2"
          >
            Confirm
          </button>
        </div>
      </form>
    );
  }, [email, handleChangeEmail, handleSubmitEmail]);

  // Enter reset code
  const [resetInfo, setResetInfo] = useState({
    code: 0,
    new_password: '',
  });

  const handleChangeResetPassword = (e) => {
    setResetInfo({
      ...resetInfo,
      [e.target.name]: e.target.value,
    });
  };

  // Collect confirmation code and new password, then redirect to main page
  const handleSubmitResetPassword = useCallback(async (e) => {
    e.preventDefault();
    Auth.forgotPasswordSubmit(email, resetInfo.code, resetInfo.new_password)
      .then(() => {
        // reset states
        handleClose();
      })
      .catch((err) => {
        toast.error(err.message);
        handleClose();
      });
  });

  const EnterResetCode = useMemo(() => {
    return (
      <form onSubmit={handleSubmitResetPassword}>
        <div>
          <label htmlFor="code" className="block text-sm font-medium text-gray-700">
            Password Reset Code
          </label>
          <div className="mt-1">
            <input
              id="code"
              name="code"
              type="number"
              required
              value={resetInfo.code}
              onChange={handleChangeResetPassword}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">
            New Password
          </label>
          <div className="mt-1">
            <input
              id="new_password"
              name="new_password"
              type="password"
              required
              value={resetInfo.new_password}
              onChange={handleChangeResetPassword}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-signature focus:outline-none focus:ring-signature sm:text-sm"
            />
          </div>
        </div>

        <div className="!mt-6">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-signature py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-signature focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2"
          >
            Confirm
          </button>
        </div>
      </form>
    );
  });

  // Reset React states
  const handleResetStates = () => {
    setEmail('');
    setResetInfo({
      code: 0,
      new_password: '',
    });
    setRenderInputEmail(true);
  };

  return (
    <div>
      <Modal
        open={show}
        title={
          renderInputEmail
            ? 'Please enter your email address to search for your account.'
            : 'Enter password reset code.'
        }
        content={renderInputEmail ? ForgotPasswordComponent : EnterResetCode}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={handleClose}
      />
    </div>
  );
};

export default ForgotPassword;
