import React from 'react';

export type SearchBarInput = {
  tableId: string;
  placeholder?: string;
  onInputChange: (value: string) => void;
};

const SearchBar = ({ tableId, placeholder = 'Search', onInputChange }: SearchBarInput) => {
  const searchName = `${tableId}searchInput`;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.target?.value);
  };

  return (
    <div className="mt-1 mb-4">
      <input
        type="text"
        name={searchName}
        id={searchName}
        className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature
                py-2 text-xs"
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchBar;
