import { HTMLAttributes, useEffect, forwardRef, RefObject } from 'react';
import { isRefEmpty } from '../lib/isRefEmpty';

interface AutoSizeInputProps extends HTMLAttributes<HTMLTextAreaElement> {
  dependencyArray: any[];
  value: any;
}

const AutoSizeInput = forwardRef<HTMLTextAreaElement, AutoSizeInputProps>(function Input(
  props,
  textAreaRef,
) {
  const { dependencyArray } = props;
  useEffect(() => {
    if (!isRefEmpty(textAreaRef as RefObject<HTMLTextAreaElement>)) {
      // @ts-ignore
      textAreaRef.current.style.height = '0px';
      // @ts-ignore
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  }, [dependencyArray, textAreaRef]);

  return <textarea {...props} ref={textAreaRef} />;
});

export default AutoSizeInput;
