export const getSections = (arr: any[]) => {
  if (arr.length === 0) {
    return [];
  }
  return Object.values(
    arr.reduce((acc, person) => {
      let firstLetter = [];
      // name for People and Skills
      // projectFullName for Projects
      if (person.name) firstLetter = person.name[0].toLocaleUpperCase();
      else if (person.projectFullName) firstLetter = person.projectFullName[0].toLocaleUpperCase();

      if (!acc[firstLetter]) {
        acc[firstLetter] = { letter: firstLetter, data: [person] };
      } else {
        acc[firstLetter].data.push(person);
      }
      return acc;
    }, {}),
  );
};
