import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  H2,
  Input,
  InputErrorCheck,
  Label,
  Select,
  TextArea,
} from '../../../custom-prebuilt/common.component';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { CreateOrgUnit, QueryAllOrgUnits, UpdateOrgUnit } from '../../../graphql/orgs';
import SimpleActionCard from '../../../custom-prebuilt/actions/SimpleActionCard.component';
import HeaderText from '../../shared/HeaderText.component';
import DeleteOrgUnit from '../tools/utils/DeleteOrgUnit';
import { useSelector } from 'react-redux';
import ImageInput from '../../../custom-prebuilt/ImageInput';
import { Amplify, Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

const isFile = (object) => object instanceof File;

const OrgUnitTools = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const { data, error } = useQuery(QueryAllOrgUnits, {
    variables: {
      where: {
        uid: defaultOrgUid,
      },
    },
  });
  const [show, setShow] = useState(false);
  const [op, setOp] = useState('');
  const [id, setId] = useState('');
  const [formError, setFormError] = useState({
    name: false,
    image: false,
  });
  const [deletePopup, setDeletePopup] = useState(false);

  const [name, setName] = useState('');
  const [motto, setMotto] = useState('');
  const [website, setWebsite] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [logo, setLogo] = useState(null);

  const [createOrgUnit] = useMutation(CreateOrgUnit, {
    refetchQueries: ['QueryAllOrgUnits'],
    ignoreResults: true,
  });
  const [updateOrgUnit] = useMutation(UpdateOrgUnit, {
    refetchQueries: ['QueryAllOrgUnits'],
    ignoreResults: true,
  });

  if (error) {
    console.error(error);
  }

  const getSlug = () => {
    var slug = name.replace(/\W+/g, '-');
    slug = slug.replaceAll('_', '-'); //Handles previous rejex with underscore problem
    slug = slug.replaceAll('+', '-plus');
    if (slug[slug.length - 1] === '-') slug = slug.slice(0, -1); //Removes extra dash if user puts space at end

    return slug.toLowerCase();
  };

  const resizeImage = (file, maxWidth, maxHeight, compressFormat, quality) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        compressFormat,
        quality,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
        0,
        0,
      );
    });

  const validateForm = () => {
    let validated = true;
    if (!name) {
      setFormError((prevState) => ({ ...prevState, name: true }));
      validated = false;
    }
    if (!logo) {
      setFormError((prevState) => ({ ...prevState, image: true }));
      validated = false;
    } else {
      setFormError((prevState) => ({ ...prevState, image: false }));
    }
    return validated;
  };

  const handleCreateOrgUnit = async () => {
    const uuid = uuidv4();
    const slug = getSlug();
    try {
      const resizedImage = await resizeImage(logo, 200, 200, 'png', 100);
      Storage.put(`OrgUnitLogos/${slug}_${resizedImage.name}`, resizedImage, {
        level: 'public',
        bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
      }).then(() => {
        createOrgUnit({
          variables: {
            where: {
              uid: defaultOrgUid,
            },
            create: {
              orgUnit: [
                {
                  node: {
                    orgUnit_id: uuid,
                    name: name,
                    motto: motto,
                    locationCity: city,
                    locationState: state,
                    locationZip: zip,
                    status: 'active',
                    website: website,
                    logo: `/OrgUnitLogos/${slug}_${resizedImage.name}`,
                  },
                },
              ],
            },
          },
        });
      });
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  };

  const handleEditOrgUnit = async () => {
    let imageParams = {};
    const slug = getSlug();
    if (isFile(logo)) {
      try {
        const resizedImage = await resizeImage(logo, 200, 200, 'png', 100);
        await Storage.put(`OrgUnitLogos/${slug}_${resizedImage.name}`, resizedImage, {
          level: 'public',
          bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
        });
        imageParams.logo = `/OrgUnitLogos/${slug}_${resizedImage.name}`;
      } catch (error) {
        console.log('Error uploading file: ', error);
      }
    }
    updateOrgUnit({
      variables: {
        where: {
          orgUnit_id: id,
        },
        update: {
          name: name,
          motto: motto,
          website: website,
          locationCity: city,
          locationState: state,
          locationZip: zip,
          ...imageParams,
        },
      },
    });
  };

  const handleOpenCreateOrgUnit = () => {
    setShow(true);
    setOp('create');
  };

  const handleOpenEditOrgUnit = (orgUnit) => {
    setShow(true);
    setOp('edit');
    setId(orgUnit.orgUnit_id);
    if (orgUnit?.name) setName(orgUnit.name);
    if (orgUnit?.motto) setMotto(orgUnit.motto);
    if (orgUnit?.website) setWebsite(orgUnit.website);
    if (orgUnit?.locationCity) setCity(orgUnit.locationCity);
    if (orgUnit?.locationState) setState(orgUnit.locationState);
    if (orgUnit?.locationZip) setZip(orgUnit.locationZip);
    if (orgUnit?.logo) setLogo(orgUnit.logo);
  };

  const handleDeleteOrgUnit = (orgUnit) => {
    setDeletePopup(true);
    setId(orgUnit?.orgUnit_id);
  };

  return (
    <div className="flex flex-col w-full lg:pl-4 ">
      <h2 className="text-sm font-medium text-gray-900 mb-2">Manage Org Units</h2>
      <div>
        <HeaderText text="Actions" />
        <div className="mt-4 w-full grid grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 gap-5">
          <SimpleActionCard
            title="Add New Organization Unit"
            description="Create a new organization unit in organization"
            btnPrompt="Create"
            action={handleOpenCreateOrgUnit}
          />
        </div>
      </div>

      <HeaderText text="Current Organization Units" className="mt-8" />
      {!data ? (
        <LoadingState />
      ) : (
        <div className="max-h-full xl:w-4/5 overflow-y-auto mt-4">
          <div className="shadow ring-1 ring-black ring-opacity-5">
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="p-4 text-xs text-font-dark text-left w-1/6">Logo</th>
                  <th className="p-4 text-xs text-font-dark text-left">Name</th>
                  <th className="p-4 text-xs text-font-dark text-left">Member Count</th>
                  <th className="p-4 text-xs text-font-dark text-left">Status</th>
                  <th className="w-1/4"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {!data?.organizations?.[0]?.orgUnit?.length ? (
                  <tr className="h-16">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  data?.organizations?.[0]?.orgUnit?.map((orgUnit, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-3.5 pl-6">
                          <img
                            className="w-[35px] h-[35px]"
                            src={
                              orgUnit?.logo
                                ? `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${orgUnit.logo}`
                                : '//:0'
                            }
                            alt=""
                          />
                        </td>
                        <td className="py-3.5 pl-6 text-sm text-font-dark">
                          {orgUnit?.name ? orgUnit.name : 'Org Name'}
                        </td>
                        <td className="py-3.5 pl-6 text-sm text-font-dark">
                          {orgUnit?.memberCount ? orgUnit.memberCount : 'No Members Found'}
                        </td>
                        <td className="py-3.5 pl-6 text-sm text-font-dark">
                          {orgUnit?.status ? orgUnit.status : 'Org Status'}
                        </td>
                        <td>
                          <div className="flex justify-evenly items-center">
                            <button
                              className="text-sm font-bold hover:text-gray-700"
                              onClick={() => handleOpenEditOrgUnit(orgUnit)}
                            >
                              Edit
                            </button>
                            <button
                              className="text-sm font-bold text-red-700 hover:text-red-600"
                              onClick={() => handleDeleteOrgUnit(orgUnit)}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/*Popup to create/edit OrgUnits*/}
      <Transition.Root
        show={show}
        as={Fragment}
        afterLeave={() => {
          setId('');
          setName('');
          setMotto('');
          setWebsite('');
          setCity('');
          setState('');
          setZip('');
          setOp('');
          setLogo(null);
          setFormError({
            name: false,
            image: false,
          });
        }}
      >
        <Dialog as="div" className="relative z-[55]" onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-3/4 md:w-1/2 lg:w-1/2 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5
            transition-all p-9 container mx-auto"
              >
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
                  <div className="flex flex-col">
                    <H2 className="mb-2">
                      {op === 'create' ? 'Create New Organization Unit' : 'Edit Organization Unit'}
                    </H2>
                    <Label>
                      <span className="text-xs text-red-600 mr-1">*</span>Name
                      <Input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setFormError((prevState) => ({ ...prevState, name: false }));
                        }}
                      />
                      <InputErrorCheck value={name} error={formError.name}>
                        <span className="font-normal">Required</span>
                      </InputErrorCheck>
                    </Label>
                    <Label>
                      Motto
                      <TextArea rows={2} value={motto} onChange={(e) => setMotto(e.target.value)} />
                    </Label>{' '}
                    <Label>
                      Website
                      <Input
                        type="text"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </Label>
                  </div>
                  <div className=" flex justify-center items-center w-full mt-9 ">
                    <ImageInput
                      image={
                        logo ? `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${logo}` : null
                      }
                      setImage={setLogo}
                      id="org-unit"
                      fieldLabel={
                        <Label className="mb-1">
                          <span className="text-red-500">* </span>Logo
                        </Label>
                      }
                      displayImageClasses="h-[84px] w-[84px] rounded-full bg-white border-2 p-1 border-gray-800"
                      contentWrapperClasses="flex space-x-4 items-center"
                      changeButtonText="Change Photo"
                      enableCropper
                      cropAspect={1}
                      cropShape="round"
                    />
                    {formError.image && (
                      <span className="text-red-500 text-xs italic self-start ml-[4.5rem] mt-1">
                        Required
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-row space-x-4">
                  <Label>
                    City
                    <Input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                  </Label>
                  <Label>
                    State
                    <Select value={state} onChange={(e) => setState(e.target.value)}>
                      <option value={''} />
                      {states.map((state, index) => {
                        return (
                          <option value={state} key={index}>
                            {state}
                          </option>
                        );
                      })}
                    </Select>
                  </Label>
                  <Label>
                    Zip
                    <Input type="text" value={zip} onChange={(e) => setZip(e.target.value)} />
                  </Label>
                </div>
                <Button
                  className="self-end mt-10"
                  onClick={() => {
                    if (!validateForm()) return;

                    if (op === 'create') {
                      handleCreateOrgUnit();
                    } else if (op === 'edit') {
                      handleEditOrgUnit();
                    }
                    setShow(false);
                  }}
                >
                  Save
                </Button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/*Popup to Delete OrgUnit*/}
      <DeleteOrgUnit
        show={deletePopup}
        setShow={(val) => setDeletePopup(val)}
        id={id}
        setId={(val) => setId(val)}
        orgList={data?.organizations?.[0]?.orgUnit}
      />
    </div>
  );
};

export default OrgUnitTools;
