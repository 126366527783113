import { ResponsivePie, DefaultRawDatum } from '@nivo/pie';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../redux/modalSlice';

// export interface DefaultRawDatum {
//     id: DatumId;
//     value: number;
// }
export type SkillPieType = DefaultRawDatum & {
  label: string;
  skills: any[];
};

export type SkillPieProps = {
  data: SkillPieType[];
};

const SkillPie = ({ data }: SkillPieProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <ResponsivePie
        colors={{ scheme: 'dark2' }}
        data={data}
        margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.2}
        padAngle={0.4}
        cornerRadius={3}
        sortByValue={true}
        activeOuterRadiusOffset={8}
        borderWidth={0}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        onClick={(node) => {
          dispatch(toggleModal({ show: true, skills: node.data.skills }));
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={1}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsDiagonalLength={10}
        arcLinkLabelsTextOffset={3}
        arcLabelsRadiusOffset={0.8}
      />
    </>
  );
};

export default SkillPie;
