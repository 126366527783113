import {
  PresentationChartLineIcon,
  ShareIcon,
  CubeTransparentIcon,
  ClipboardListIcon,
} from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { classNames } from '../lib/classNames';
import PageContainer from '../custom-prebuilt/PageContainer';
import LoadingState from '../custom-prebuilt/preloader/LoadingState.component';
import { useNavigate } from 'react-router';
import defaultImg from '../assets/default-user.svg';
import { useAuth } from '../lib/authContext';
import { useQuery } from '@apollo/client';
import { QueryPersonBasics } from '../graphql/people';
import Card from '../components/shared/Card.component';
import { QueryAllAnnouncements } from '../graphql/announcements';
import SimpleSection from '../components/shared/SimpleSection.component';
import UserUnitGroup from '../components/profile/overview/utils/UserUnitGroup.component';

const actions = [
  {
    title: 'People',
    href: '/people',
    icon: PresentationChartLineIcon,
    iconForeground: 'text-red-700',
    iconBackground: 'bg-red-50',
    description: 'View and search for other people within your organization.',
  },
  {
    title: 'Projects',
    href: '/projects',
    icon: ShareIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    description: 'View current projects within Connect and the team members who work on them.',
  },
  {
    title: 'Skills',
    href: '/skills',
    icon: CubeTransparentIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    description: 'Search for the various skills within Connect and who knows them.',
  },
  {
    title: 'Skill Assessment',
    href: `/assessment`,
    icon: ClipboardListIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    description: 'View and take Assessments assigned to your organization.',
  },
];

const Home = () => {
  const defaultOrgUnit = useSelector((state) => state.org.defaultOrgUnit);
  const { userEmail } = useAuth();
  const { data } = useQuery(QueryPersonBasics, {
    variables: {
      where: {
        email: userEmail,
      },
      orgUnitsConnectionWhere2: {
        node: {
          orgUnit_id: defaultOrgUnit?.orgUnit_id,
        },
      },
    },
  });
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const navigate = useNavigate();

  const queryActiveAnnouncements = useQuery(QueryAllAnnouncements, {
    variables: {
      where: {
        active: true,
        draft: false,
        organization_SOME: {
          uid: defaultOrgUid,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const announcements = queryActiveAnnouncements.data?.announcements;

  const handleNavigateAction = (href) => {
    navigate(href);
  };

  const Announcements = () => {
    return (
      <SimpleSection>
        <div className="flex flex-row space-x-2">
          <p className="text-xs font-semibold">Announcements</p>
          {/* <SpeakerphoneIcon className='h-4 w-4'/> */}
        </div>
        <div className="my-3 max-h-[17rem] overflow-y-auto overflow-x-hidden">
          {announcements?.length ? (
            <div className="flex flex-col space-y-2">
              {announcements?.map((a, index) => (
                <Card
                  key={index}
                  title={a?.title}
                  hoverEnabled={true}
                  onClick={() => {
                    navigate(`/announcements/${a?.announcementId}`);
                  }}
                >
                  <div className="flex flex-col space-y-1">
                    <p className="text-xs">
                      Created by: <span>{a?.belongsToConnection?.edges[0]?.node?.name}</span>
                    </p>
                    <p className="text-xs">
                      Date created:{' '}
                      <span>{a?.belongsToConnection?.edges[0]?.datePosted || 'N/A'}</span>
                    </p>
                    <p className="text-xs">
                      Description: <span>{a?.description || 'N/A'}</span>
                    </p>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <LoadingState />
          )}
        </div>
      </SimpleSection>
    );
  };

  const ActionsPanel = () => {
    return (
      <section aria-labelledby="quick-links-title">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          <h2 className="sr-only" id="quick-links-title">
            Quick links
          </h2>
          {actions.map((action, actionIdx) => (
            <div
              onClick={() => {
                handleNavigateAction(action.href);
              }}
              key={action.title}
              className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'relative group bg-white p-6 cursor-pointer hover:bg-slate-100 transition duration-300',
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white',
                  )}
                >
                  <action.icon className="h-4 w-4" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-4">
                <p className="text-sm font-medium">
                  <span>
                    {/* Extend touch target to entire panel */}
                    {/* <span className="absolute inset-0 " aria-hidden="true" /> */}
                    {action.title}
                  </span>
                </p>
                <p className="mt-2 text-xs text-gray-500">{action.description}</p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400 transition duration-300"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </section>
    );
  };

  const ProfileOverview = () => {
    return (
      <section aria-labelledby="profile-overview-title">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <h2 className="sr-only" id="profile-overview-title">
            Profile Overview
          </h2>
          <div className="bg-white p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="flex-shrink-0">
                  <img
                    className="mx-auto h-20 w-20 rounded-full"
                    src={data?.people?.[0]?.userIconUrl ?? defaultImg}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                </div>
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                    {data?.people?.[0]?.name}
                  </p>
                  <p className="text-sm font-semibold text-gray-600">
                    {data?.people?.[0]?.orgUnitsConnection?.edges?.[0]?.position || 'Unknown role'}{' '}
                    • {defaultOrgUnit?.name}
                  </p>
                </div>
              </div>
              <div className="mt-5 flex justify-center sm:mt-0 bg-">
                <p
                  onClick={() => {
                    navigate(`/${userEmail.split('.').at(0)}`);
                  }}
                  className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm
                  font-medium text-gray-700 shadow-sm hover:bg-gray-50 cursor-pointer"
                >
                  View profile
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return !data ? (
    <LoadingState />
  ) : (
    <PageContainer title="Dashboard" className="!pb-0">
      <div className="h-full">
        <h1 className="sr-only">Profile</h1>
        {/* Main 3 column grid */}
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 h-full overflow-y-auto overflow-x-hidden">
          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <ProfileOverview />
            <ActionsPanel />
          </div>
          {/* Right column */}
          <div className="grid grid-cols-1 gap-4 max-h-full">
            <Announcements />
            {/* <Events/> */}
            <UserUnitGroup email={userEmail} slim />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Home;
