import { Button } from '../../../../../../custom-prebuilt/common.component';
import { useSelector, useDispatch } from 'react-redux';
import SlideOver from '../../../../../../custom-prebuilt/SlideOver.component';
import { toggleSlideOver } from '../../../../../../redux/slideOverSlice';
import ClickNHoldSkill from '../../../../../../custom-prebuilt/ClickNHoldSkill.component';
import SimpleSection from '../../../../../shared/SimpleSection.component';
import SkillInfo from '../../skill-assessment/utils/SkillInfo.component';
import ReactTooltip from 'react-tooltip';
import { useCallback, useEffect } from 'react';
import PersonStack from '../../../../../shared/PersonStack.component';
import SnapshotProfile from '../../../../../../custom-prebuilt/snapshot/SnapshotProfile.component';
import { toggleModal } from '../../../../../../redux/modalSlice';
import AllTeamMembers from '../../../../../projects/AllTeamMembers.component';
import Modal from '../../../../../../custom-prebuilt/Modal.component';
import { useNavigate, useLocation } from 'react-router';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import { useFilteredSkills } from '../../../../../../lib/customHook';
import LoadingState from '../../../../../../custom-prebuilt/preloader/LoadingState.component';

const AssessmentProjectIntro = () => {
  const org = useSelector((state) => state.org.defaultOrg);
  const show = useSelector((state) => state.slideOver.show);
  const show2 = useSelector((state) => state.slideOver.show2);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const dispatch = useDispatch();
  const project = useSelector((state) => state.assessment.project);
  const loading = useSelector((state) => state.assessment.loading);
  // const assessmentInfo = project?.skillAssessmentConnection?.edges[0]
  const skill = useSelector((state) => state.slideOver.skill);
  const modalShow = useSelector((state) => state.modal.show1);
  const members = useSelector((state) => state.modal.members);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false }));
  };

  const filteredSkills = useFilteredSkills(project);

  const filterSkillComponent = useCallback(
    (level) => {
      return (
        <div className="flex flex-row flex-wrap">
          {filteredSkills
            ?.filter((s) => s.rating === level)
            ?.map((s, idx) => (
              <div
                key={idx}
                className="h-12 w-12 md:h-16 md:w-16"
                data-for="skill"
                data-tip={s.node.name}
              >
                <ClickNHoldSkill skill={s.node} />
              </div>
            ))}
        </div>
      );
    },
    [project],
  );

  return (
    <div className="space-y-5 xl:pl-4 w-full">
      <SimpleSection>
        <div className="flex flex-row justify-between">
          <p className="text-xs mb-4 font-semibold">
            Welcome to the {org.name} project skill assessment
          </p>
        </div>
        <p className="text-xs mb-3">In this assessment, you will:</p>
        <ul className="flex flex-col space-y-2 text-xs mb-3">
          <li>• Select all of the skills that are relevant to the project.</li>
          <li>
            • Rate each skill according to the importance to the project using the options given.
          </li>
        </ul>
        <p className="text-xs mb-3 font-semi">
          Any previous project skills will be pre-selected in Step 2 and will show each skill&apos;s
          importance rating in Step 3.
        </p>
        <Button
          onClick={() => {
            navigate(`${getLocationPrevLevel(location)}/select`);
          }}
        >
          Next
        </Button>
      </SimpleSection>

      <SimpleSection>
        <p className="text-xs mb-4 font-semibold">Members</p>
        {loading ? (
          <LoadingState />
        ) : project?.peopleWithExperienceConnection?.edges?.length ? (
          <PersonStack peopleArray={project?.peopleWithExperienceConnection?.edges} stackNum={15} />
        ) : null}
      </SimpleSection>

      <SimpleSection>
        {loading ? (
          <LoadingState />
        ) : filteredSkills?.length ? (
          <div>
            <p className="text-xs mb-4 font-semibold">Current rated skills</p>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row">
                <p className="text-xs mr-4">Primary skills:</p>
                {filterSkillComponent(1)}
              </div>
              <div className="flex flex-row">
                <p className="text-xs mr-4">Secondary skills:</p>
                {filterSkillComponent(2)}
              </div>
              <div className="flex flex-row">
                <p className="text-xs mr-4">Passive skills:</p>
                {filterSkillComponent(3)}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-xs mb-4 font-semibold">You haven&apos;t assessed this project yet!</p>
        )}
      </SimpleSection>

      <SlideOver
        show={show}
        onClickButtonClose={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        title="Details"
        content={<SkillInfo skill={skill} />}
        bgWhite
      />

      <SlideOver
        show={show2}
        title="User Profile"
        onClose={() => {
          dispatch(toggleSlideOver({ show2: false }));
          setTimeout(() => {
            dispatch(toggleSlideOver({ profileTarget: null }));
          }, 500);
        }}
        onClickButtonClose={() => {
          dispatch(toggleSlideOver({ show2: false }));
          setTimeout(() => {
            dispatch(toggleSlideOver({ profileTarget: null }));
          }, 500);
        }}
        largeSize={true}
        bgWhite={false}
        content={<SnapshotProfile target={target} coverPic={true} />}
      />

      <div className="hidden lg:block">
        <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="skill" />
      </div>

      <div className="hidden lg:block">
        <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="members" />
      </div>

      <Modal
        open={modalShow}
        secondaryButtonLabel="Back"
        onSecondaryButtonClick={() => dispatch(toggleModal({ show1: false }))}
        title="All team members"
        content={<AllTeamMembers members={members} />}
        size="large"
      />
    </div>
  );
};

export default AssessmentProjectIntro;
