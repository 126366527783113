import { CheckIcon } from "@heroicons/react/solid"
import { useAutoAnimate } from "@formkit/auto-animate/react"

const SingleChoice = ({option, selected, index, align}) => {

  const [parent] = useAutoAnimate({duration: 100})

  return (
    <div ref={parent}
    className={`relative flex text-xs items-center bg-gray-50 px-3 py-3 rounded-sm shadow border border-transparent ${align === "row" && "col-span-1"}
      cursor-pointer transition duration-300 hover:bg-gray-200 ${selected === index && "!bg-gray-200 border border-signature"}`}>
    {
      selected === index ?
        <CheckIcon className="h-4 w-4 mr-2 text-signature" /> :
        <div className="mr-2 bg-white aspect-1 h-4 flex items-center justify-center rounded-sm border border-gray-300"></div>
    }
    <span className="text-gray-500">{option}</span>
  </div>
  )
}

export default SingleChoice