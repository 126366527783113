import { useAuth } from '../lib/authContext';
import LoginForm from '../components/login/LoginForm.component';
import { Toaster } from 'react-hot-toast';

const Login = () => {
  const { authenticated } = useAuth();

  return (
    <div className={`${authenticated ? 'bg-bg_lightgray' : 'bg-white'} h-screen w-full `}>
      <div className="text-xs">
        <Toaster position="top-center" />
      </div>
      <div className="flex h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <LoginForm />
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require(`../assets/img/connect_login_bg.png`)}
            alt=""
            draggable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
