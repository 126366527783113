import { useMutation } from '@apollo/client';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button } from '../../../../custom-prebuilt/common.component';
import Modal from '../../../../custom-prebuilt/Modal.component';
import { DetachSkill } from '../../../../graphql/taxonomy';

type DetachSkillNodeProps = {
  id: string;
  closeSlideover: () => void;
};

const DetachSkillNode = ({ id, closeSlideover }: DetachSkillNodeProps) => {
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [parentRef] = useAutoAnimate<HTMLDivElement>({ duration: 150 });
  const [detachSkill] = useMutation(DetachSkill, {
    refetchQueries: ['GetOrgTaxonomy', 'GetUnattachedSkills'],
  });

  const handleDetachSkill = () => {
    const promise = detachSkill({
      variables: {
        nodeId: id,
      },
    });
    toast.promise(promise, {
      loading: 'Detaching Skill...',
      success: 'Successfully detached Skill!',
      error: 'An error occurred!',
    });
    setOpen(false);
    closeSlideover();
  };

  return (
    <>
      <div
        ref={parentRef}
        className="w-full flex flex-col items-center bg-white border border-gray-200 rounded-md shadow-sm p-2 mt-6"
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-2">
            <svg
              id="diagram-nested-duotone"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              viewBox="0 0 22.505 22.313"
            >
              <path
                id="Path_64"
                data-name="Path 64"
                d="M6.61,32a2.2,2.2,0,0,1,2.2,2.2V38.61a2.2,2.2,0,0,1-2.2,2.2H2.2A2.2,2.2,0,0,1,0,38.61V34.2A2.2,2.2,0,0,1,2.2,32ZM18.361,43.751a2.2,2.2,0,0,1,2.2,2.2v4.407a2.2,2.2,0,0,1-2.2,2.2H13.954a2.2,2.2,0,0,1-2.2-2.2V45.954a2.2,2.2,0,0,1,2.2-2.2Z"
                transform="translate(0 -32)"
              />
              <path
                id="Path_65"
                data-name="Path 65"
                d="M64,228.407V224h2.938v4.407a1.467,1.467,0,0,0,1.469,1.469h4.407v2.938H68.407A4.381,4.381,0,0,1,64,228.407Z"
                transform="translate(-61.062 -215.187)"
                opacity="0.4"
              />
              <path
                id="xmark-solid"
                d="M11.786,98.074a1.286,1.286,0,0,0,0-1.78,1.2,1.2,0,0,0-1.729,0l-4.02,4.142L2.012,96.3a1.2,1.2,0,0,0-1.729,0,1.286,1.286,0,0,0,0,1.78l4.024,4.138-4.02,4.142a1.286,1.286,0,0,0,0,1.78,1.2,1.2,0,0,0,1.729,0L6.036,104l4.024,4.138a1.2,1.2,0,0,0,1.729,0,1.286,1.286,0,0,0,0-1.78l-4.024-4.138Z"
                transform="translate(10.357 -86.194)"
                fill="#880d0d"
              />
            </svg>
            <h3 className="text-black font-medium text-sm">Detach Skill Node</h3>
          </div>
          <button
            className="rounded-full bg-bg-light p-1 shadow-md hover:bg-gray-100"
            onClick={() => setCollapse(!collapse)}
          >
            {!collapse ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </button>
        </div>
        {!collapse && (
          <div className="w-full px-4 flex flex-col">
            <div className="flex mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 flex-shrink-0 fill-bg-dark"
                viewBox="0 96 960 960"
              >
                <path d="M453 776h60V536h-60v240Zm26.982-314q14.018 0 23.518-9.2T513 430q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447 430q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.316q-54 54.316-127 86Q563 976 480.266 976Z" />
              </svg>
              <div className="text-xs ml-2 font-normal text-gray-500">
                Important: Detaching this Skill node from the taxonomy will not delete the skill
                from the Connect system (it only removes it from the taxonomy).
              </div>
            </div>
            <Button
              className="w-fit mt-4 bg-red-800 hover:bg-red-700"
              onClick={() => setOpen(true)}
            >
              Detach Skill Node
            </Button>
          </div>
        )}
      </div>
      <Modal
        title={
          <div className="flex text-base items-center justify-center w-full">
            Are You Sure You Want to Detach this Skill?
          </div>
        }
        open={open}
        onSecondaryButtonClick={() => setOpen(false)}
        content={
          <div className="flex justify-evenly w-full h-full">
            <button
              className="px-3 py-2 rounded-md shadow-sm border border-gray-300 font-medium bg-bg-dark text-xs text-white hover:bg-gray-700"
              onClick={handleDetachSkill}
            >
              Confirm
            </button>
            <button
              className="px-3 py-2 mr-2 rounded-md shadow-sm bg-white border border-gray-300 text-xs font-medium text-font-dark hover:bg-gray-200"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        }
      />
    </>
  );
};

export default DetachSkillNode;
