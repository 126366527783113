/**
 * @warning page should be of type string instead of number as in URLSearchParams
 */
import { useQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { QueryEntitiesCount } from '../graphql/common';
import { RootState } from '../redux/store';
import LoadingSkeleton from './preloader/LoadingSkeleton.component';

export type SearchTableType = {
  isSearching: boolean;
  searchFor: string;
  filteredData: any[];
};

/**
 * A Navigation entry for a NavBar
 * @typedef {Object} PaginationNavProps
 * @property {number} siblingCount - The minimum number of pages to be shown next to the current
 * @property {number} boundaryCount - The minimum number of pages to be shown at the ends of the nav
 */
export type PaginationNavProps = {
  siblingCount?: number;
  boundaryCount?: number;
  table: string;
  limitPagination: number;
  search: SearchTableType;
  totalCount: number;
};
/**
 *
 * @param {PaginationNavProps} props
 * @returns
 */

const PaginationNav = (props: PaginationNavProps) => {
  const { siblingCount = 2, boundaryCount = 1 } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsPage = Number(searchParams.get('page') || 1);
  const defaultOrg = useSelector((state: RootState) => state.org.defaultOrg);

  // Props
  const { table, limitPagination, search } = props;
  // State
  const currentPeople = useSelector((state: RootState) => state.pagination.peopleCurrentPage);
  const currentProjects = useSelector((state: RootState) => state.pagination.projectsCurrentPage);
  const currentSkills = useSelector((state: RootState) => state.pagination.skillsCurrentPage);
  const currentCertifications = useSelector(
    (state: RootState) => state.pagination.certificationsCurrentPage,
  );
  const countEntities = useQuery(QueryEntitiesCount, {
    variables: {
      where: {
        AND: [
          {
            active: true,
            OR: [
              {
                name_NOT_STARTS_WITH: 'JG',
              },
              {
                name_IN: ['JG - Connect Development', 'JG - Tense'],
              },
            ],
            organizationAggregate: {
              count_GT: 0,
            },
          },
        ],
      },
      peopleAggregateWhere2: {
        active: true,
        orgUnitsConnection_SOME: {
          node: {
            organization: {
              uid: defaultOrg?.uid,
            },
          },
        },
      },
      skillsAggregateWhere2: {
        active: true,
      },
    },
  });

  const activeClassname =
    'cursor-pointer relative z-10 inline-flex justify-center items-center border border-indigo-500 bg-indigo-50 px-4 py-3 text-xs font-medium text-indigo-600 focus:z-20 w-12';
  const ellipsesClassname =
    'cursor-pointer relative inline-flex justify-center items-center border border-gray-300 bg-white px-4 py-3 text-xs font-medium text-gray-500 w-12';
  const inactiveClassname = ellipsesClassname + ' focus:z-20 hover:bg-gray-50';

  // Page info
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  // Set people/skills/projects based on props
  useEffect(() => {
    switch (table) {
      case 'People':
        setCurrentPage(currentPeople);
        break;
      case 'Projects':
        setCurrentPage(currentProjects);
        break;
      case 'Skills':
        setCurrentPage(currentSkills);
        break;
      case 'Certifications':
        setCurrentPage(currentCertifications);
        break;
      default:
        setCurrentPage(searchParamsPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeople, currentProjects, currentSkills, currentCertifications, table, setCurrentPage]);

  useEffect(() => {
    if (table === 'People') setCurrentPage(currentPeople);
    else if (table === 'Projects') setCurrentPage(currentProjects);
    else if (table === 'Skills') setCurrentPage(currentSkills);
    else if (table === 'Certifications') setCurrentPage(currentCertifications);
    else setCurrentPage(searchParamsPage);
  }, [
    currentPeople,
    currentProjects,
    currentSkills,
    currentCertifications,
    table,
    searchParamsPage,
  ]);

  useEffect(() => {
    let cnt: number;
    if (search?.isSearching && search?.searchFor) {
      setTotalCount(search.filteredData.length);
      search.filteredData.length % limitPagination === 0
        ? setTotalPage(search.filteredData.length / limitPagination)
        : setTotalPage(Math.floor(search.filteredData.length / limitPagination) + 1);
      return;
    }
    if (table === 'People' && countEntities.data?.peopleAggregate) {
      cnt = countEntities.data.peopleAggregate.count;
    } else if (table === 'Projects' && countEntities.data?.projectsAggregate) {
      cnt = countEntities.data.projectsAggregate.count;
    } else if (table === 'Skills' && countEntities.data?.skillsAggregate) {
      cnt = countEntities.data.skillsAggregate.count;
    } else if (table === 'Certifications' && countEntities.data?.certificationsAggregate) {
      cnt = countEntities.data.certificationsAggregate.count;
    } else {
      cnt = props.totalCount;
    }
    setTotalCount(cnt);
    if (Math.floor(cnt / limitPagination) === cnt / limitPagination)
      setTotalPage(Math.floor(cnt / limitPagination));
    else setTotalPage(Math.floor(cnt / limitPagination) + 1);
  }, [countEntities, search, limitPagination, table, props.totalCount]);

  useEffect(() => {
    //Whenever search input is changed, reset pagination to page 1
    setSearchParams({ page: '1' });
  }, [search?.filteredData.length, search?.isSearching, search?.searchFor]);

  const clamp = (number: number, lower: number, upper: number) => {
    return Math.min(Math.max(number, lower), upper);
  };

  const getRange = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const ellipses = '…';

  const getPageClassname = useCallback(
    (item: number) => {
      if (item === currentPage) return activeClassname;
      else if (item.toString() === ellipses) return ellipsesClassname;
      return inactiveClassname;
    },
    [currentPage],
  );

  const RenderRows = useCallback(() => {
    if (totalCount > 0 && totalPage > 0) {
      // the following code adapted from on the interwebs:
      // https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=4215826#gistcomment-4215826
      let pagesShown = 9;

      let delta;
      pagesShown = clamp(pagesShown, 5, totalPage);
      const centerPagesShown = pagesShown - (siblingCount * 2 + 1);
      const boundaryPagesShown = pagesShown - (boundaryCount * 2 + 1);

      if (totalPage <= pagesShown) {
        delta = pagesShown;
      } else {
        delta =
          currentPage < boundaryPagesShown || currentPage > totalPage - boundaryPagesShown
            ? boundaryPagesShown
            : centerPagesShown;
      }

      const range = {
        start: Math.round(currentPage - delta / 2),
        end: Math.round(currentPage + delta / 2),
      };

      if (range.start - 1 === 1 || range.end + 1 === totalPage) {
        range.start += 1;
        range.end += 1;
      }
      let pages =
        currentPage > delta
          ? getRange(Math.min(range.start, totalPage - delta), Math.min(range.end, totalPage))
          : getRange(1, Math.min(totalPage, delta + 1));

      if (currentPage > totalPage - boundaryPagesShown && totalPage > pagesShown) {
        pages = getRange(totalPage - delta, totalPage);
      }

      const withDots = (value: number, pair: any[]) =>
        pages.length + 1 !== totalPage ? pair : [value];
      const lastPage = pages[pages.length - 1];

      if (pages[0] !== 1) {
        pages = withDots(1, [1, ellipses]).concat(pages);
      }

      if (lastPage && lastPage < totalPage) {
        pages = pages.concat(withDots(totalPage, [ellipses, totalPage]));
      }

      return (
        <div>
          {pages.map((item, i) => (
            <div
              key={item + i}
              onClick={() => {
                if (item.toString() !== ellipses) {
                  setSearchParams({ page: item.toString() });
                }
              }}
              aria-current="page"
              className={getPageClassname(item)}
            >
              {item}
            </div>
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  }, [totalCount, currentPage, siblingCount, boundaryCount, totalPage, setSearchParams]);

  const incrementPage = (curPage: number, totalPage: number) =>
    curPage < totalPage ? curPage + 1 : totalPage;
  const decrementPage = (curPage: number) => (curPage > 1 ? curPage - 1 : 1);

  return (
    <div>
      {totalCount ? (
        <p className="text-xs text-gray-700 mb-2 md:hidden">
          Showing <span className="font-medium">{(currentPage - 1) * limitPagination + 1}</span> to{' '}
          <span className="font-medium">
            {currentPage * limitPagination > totalCount
              ? totalCount
              : currentPage * limitPagination}
          </span>{' '}
          of <span className="font-medium">{totalCount}</span> results
        </p>
      ) : (
        <LoadingSkeleton className="md:hidden" />
      )}
      <div className="flex items-center justify-between lg:border-t lg:border-gray-200 lg:bg-white lg:shadow lg:rounded-md lg:pl-6">
        <div className="flex flex-1 justify-between md:hidden">
          <div
            onClick={() => setSearchParams({ page: decrementPage(currentPage).toString() })}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </div>
          <div
            onClick={() =>
              setSearchParams({ page: incrementPage(currentPage, totalPage).toString() })
            }
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </div>
        </div>
        <div
          className="hidden md:flex-col lg:flex-row md:flex md:space-y-2 lg:space-y-0
        lg:flex-1 lg:items-center lg:justify-between"
        >
          {totalCount ? (
            <p className="text-xs text-gray-700">
              Showing <span className="font-medium">{(currentPage - 1) * limitPagination + 1}</span>{' '}
              to{' '}
              <span className="font-medium">
                {currentPage * limitPagination > totalCount
                  ? totalCount
                  : currentPage * limitPagination}
              </span>{' '}
              of <span className="font-medium">{totalCount}</span> results
            </p>
          ) : (
            <LoadingSkeleton />
          )}
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
              <div
                onClick={() => setSearchParams({ page: decrementPage(currentPage).toString() })}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
              <RenderRows />
              <div
                onClick={() =>
                  setSearchParams({ page: incrementPage(currentPage, totalPage).toString() })
                }
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginationNav;
