import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import PageContainer from '../custom-prebuilt/PageContainer';
import SearchLogic from '../custom-prebuilt/SearchLogic.component';
import ProjectAssessmentsTableView from '../components/projects/ProjectAssessmentsTableView.component';
import { QueryCompletedAssessmentsWithSkillInfo } from '../graphql/assessment';
import { QueryAllProjects } from '../graphql/projects';

const ProjectDetails = () => {
  const location = useLocation();
  const projectId = location.pathname.split('/').at(-1);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [searchInput, setSearchInput] = useState('');
  const paginationSize = 15;

  const [statesData, setStatesData] = useState([]);
  const { data, loading } = useQuery(QueryCompletedAssessmentsWithSkillInfo, {
    variables: {
      where: {
        experienceAssessedConnection_SOME: {
          node: {
            id: projectId,
          },
        },
      },
      experienceConnectionWhere2: {
        node: {
          id: projectId,
        },
      },
    },
  });

  const { data: projectData } = useQuery(QueryAllProjects, {
    variables: {
      where: {
        id: projectId,
      },
    },
  });

  useEffect(() => {
    if (data && data?.assessmentCompletions?.length) {
      const page = params.page ? params.page : 1;
      const tmp = [...data.assessmentCompletions];
      setStatesData(
        tmp
          .filter((assessment) =>
            assessment.personCompleted[0].name.toLowerCase().includes(searchInput.toLowerCase()),
          )
          // .sort((a, b) => a.projectFullName < b.projectFullName ? -1 : 1)
          .slice((page - 1) * paginationSize, page * paginationSize),
      );
    }
  }, [data, searchInput, params.page]);

  const ProjectAssessmentsTableComponent = useMemo(() => {
    return (
      <ProjectAssessmentsTableView
        customList={statesData}
        paginationSize={paginationSize}
        actionName="View people"
        search={{
          isSearching: searchInput ? true : false,
          searchFor: 'assessmentCompletions',
          filteredData: data
            ? [...data.assessmentCompletions].filter((assessment) =>
                assessment.personCompleted[0].name
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()),
              )
            : [],
        }}
      />
    );
  }, [data, statesData]);

  return (
    <PageContainer title={projectData?.projects[0].name}>
      <div className="mt-1 mb-4">
        <input
          type="text"
          placeholder="Find user"
          className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs py-2"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

      <SearchLogic
        className={'h-80'}
        dataCheck={statesData.length > 0}
        loading={loading}
        searchInput={searchInput}
        dataComponent={ProjectAssessmentsTableComponent}
      />
    </PageContainer>
  );
};

export default ProjectDetails;
