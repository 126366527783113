/*eslint-disable*/

import React from 'react';
import { classNames } from '../../lib/classNames';

interface TableProps {
  items: Array<{ [key: string]: any }>;
  actionName: string;
  action: (item: { [key: string]: any }) => void;
  headers: string[];
  className?: string;
}

/**
 * Renders a table with the provided items and an optional action.
 *
 * @param {Array<{ [key: string]: any }>} items - An array of objects representing the items to display in the table.
 * @param {string} actionName - The name of the action to be displayed in the table row.
 * @param {(item: { [key: string]: any }) => void} action - The function to be called when the action is clicked.
 * @param {string[]} headers - An array of strings representing the headers to be displayed in the table.
 * @returns {JSX.Element} - The table component.
 */
const Table = ({ items, actionName, action, headers, className }: TableProps) => {

  const filteredItems = items?.map((item) => {
    return headers.reduce((acc, key) => {
      return Object.assign(acc, { [key]: item[key] });
    }, {});
  });

  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div
      className={classNames(
        ``,
        className,
      )}
    >
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className='bg-gray-50'>
                <tr className='-mx-2'>
                  {filteredItems[0] && Object.keys(filteredItems[0]).map((value: any, index: number) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 px-3 text-left text-xs font-semibold text-gray-900"
                    >
                      {
                        typeof value === 'object'
                          ? capitalizeFirstLetter(JSON.stringify(value))
                          : capitalizeFirstLetter(value)
                      }
                    </th>
                  ))}
                  <th scope="col" className="relative py-3.5 pl-3 pr-6 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredItems.map((item, index) => (
                  <tr key={index} className='-mx-2'>
                    {Object.values(item).map((value: any, index: number) => (
                      <td
                        key={index}
                        className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900"
                      >
                        {typeof value === 'object' ? JSON.stringify(value) : value}
                      </td>
                    ))}
                    <td className="relative whitespace-nowrap py-4 px-3 text-right text-xs font-medium">
                      <p onClick={() => { action(items[index]) }} className="text-gray-900 hover:text-gray-900 cursor-pointer">
                        {actionName}<span className="sr-only"></span>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
