import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { classNames } from '../../lib/classNames';

export type LoadingSkeleton = {
  className?: string;
  count?: number;
  width?: number;
  short?: number;
};

/**
 * Customizable loading skeleton for text.
 * @param {*} width width of skeleton. Default to 15rem
 * @param {*} short width of skeleton. Default to 7rem
 * @param {*} count number of lines. Default to 1
 */
const LoadingSkeleton = ({ className, count, width, short }: LoadingSkeleton) => {
  return (
    <div className={classNames(width ? `w-[${width}]` : 'w-60', `${short && '!w-28'}`, className)}>
      <Skeleton count={count || 1} />
    </div>
  );
};

export default LoadingSkeleton;
