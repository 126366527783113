import './App.scss';
import { Routes, Route, useNavigate, useLocation } from 'react-router';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import { useCallback, useEffect, useState } from 'react';
import { client } from '.';
import { useAuth } from './lib/authContext';
import { successToastMid } from './lib/toast';
import { Toaster } from 'react-hot-toast';
import Header from './components/Header';
import SearchComponent from './custom-prebuilt/Search.component';
import Home from './pages/Home';
import PowerAdmin from './pages/PowerAdmin';
import People from './pages/People';
import Projects from './pages/Projects';
import Skills from './pages/Skills';
import Assessment from './pages/Assessment';
import CertificationDirectory from './pages/CertificationDirectory';
import AssessmentIntro from './components/assessment/companies/jg/skill-assessment/utils/AssessmentIntro.component';
import AssessmentSelectSkills from './components/assessment/companies/jg/skill-assessment/utils/AssessmentSelectSkills.component';
import AssessmentRateSkills from './components/assessment/companies/jg/skill-assessment/utils/AssessmentRateSkills.component';
import AssessmentConfirmation from './components/assessment/companies/jg/skill-assessment/utils/AssessmentConfirmation.component';
import AssessmentProjectIntro from './components/assessment/companies/jg/project-assessment/utils/AssessmentProjectIntro.component';
import AssessmentProjectSelectSkills from './components/assessment/companies/jg/project-assessment/utils/AssessmentProjectSelectSkills.component';
import AssessmentProjectRateSkills from './components/assessment/companies/jg/project-assessment/utils/AssessmentProjectRateSkills.component';
import AssessmentProjectDescription from './components/assessment/companies/jg/project-assessment/utils/AssessmentProjectDescription.component';
import AssessmentProjectConfirmation from './components/assessment/companies/jg/project-assessment/utils/AssessmentProjectConfirmation.component';
import TakeAssessment from './components/assessment/TakeAssessment.component';
import Profile from './pages/Profile';
import SkillProfile from './pages/SkillProfile';
import Preferences from './pages/Preferences';
import Plan from './pages/Plan';
import HandleInvitation from './pages/HandleInvitation';
import Explore from './pages/Explore';
import { useLazyQuery } from '@apollo/client';
import { toggleOrg } from './redux/orgSlice';
import { useSelector, useDispatch } from 'react-redux';
import { QueryOrg, CheckOrgAdmin } from './graphql/orgs';
import { QueryCurrentOrg } from './graphql/people';
import { toggleSearch } from './redux/searchSlice';
import SettingsPersonal from './components/settings/SettingsPersonal.component';
import SettingsEducation from './components/settings/SettingsEducation.component';
import SettingsCompany from './components/settings/SettingsCompany.component';
import SettingsWork from './components/settings/SettingsWork.component';
import SettingsContact from './components/settings/SettingsContact.component';
import SettingsCertifications from './components/settings/SettingsCertifications.component';
import NestedVerticalNav from './custom-prebuilt/NestedVerticalNav.component';
import settingsNavigation from './pages/Settings';
import adminNavigation from './pages/Admin';
import PeopleDirectoryAdminView from './components/admin/people/PeopleDirectoryAdminView.component';
import SkillsDirectoryAdminView from './components/admin/skills/SkillsDirectoryAdminView.component';
//import ProjectsDirectoryAdminView from './components/admin/projects/ProjectsDirectoryAdminView.component';
import AnnouncementsAdminView from './components/admin/announcements/AnnouncementsAdminView.component';
import BugReport from './custom-prebuilt/BugReport.component';
import { useCleanUpLayover } from './lib/customHook';
import LoadingState from './custom-prebuilt/preloader/LoadingState.component';
import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import sdkConfig from './split.io/sdk.config';
import ProfileGenerator from './pages/ProfileGenerator';
import RoleRestrictedRoute from './components/RoleRestrictedRoute';
import PageNotFound from './custom-prebuilt/PageNotFound.component';
import CreateAnnouncement from './components/create-announcement/CreateAnnouncement.component';
import Announcements from './pages/Announcements';
import SkillExplore from './components/explore/SkillExplore.component';
import SkillTaxonomyView from './components/admin/taxonomy/SkillTaxonomyView';
import ProjectsDirectoryAdminMenuComponent from './components/admin/projects/ProjectsDirectoryAdminMenu.component';
import PopOver from './custom-prebuilt/Popover.component';
import ConnectorsAdminMenu from './components/admin/connectors/ConnectorsAdminMenu';
import AssessmentCompletions from './components/assessment/AssessmentCompletions';
import CompetencyAdminMenu from './components/admin/competency/CompetancyAdminMenu';
import ProjectDetails from './pages/ProjectDetails';

const App = () => {
  useCleanUpLayover();

  const { cognitoUser, authenticated, userEmail } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentOrg] = useLazyQuery(QueryCurrentOrg);
  const dispatch = useDispatch();
  const [queryOrg] = useLazyQuery(QueryOrg);
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const [checkOrgAdmin] = useLazyQuery(CheckOrgAdmin);
  const [defaultOrg, setDefaultOrg] = useState(null);

  const loading = !authenticated || !defaultOrg;

  const detectKeydown = useCallback(
    (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        e.preventDefault();
        dispatch(toggleSearch({ show: true }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Kbar
  useEffect(() => {
    document.addEventListener('keydown', detectKeydown, true);
  }, [detectKeydown]);

  // Query current org
  useEffect(() => {
    defaultOrgUid &&
      queryOrg({ variables: { where: { uid: defaultOrgUid } } }).then((res) => {
        setDefaultOrg(res.data?.organizations[0]);
      });
  }, [defaultOrgUid, queryOrg]);

  // Check to see if current user is organization admin
  useEffect(() => {
    userEmail &&
      defaultOrgUid &&
      checkOrgAdmin({
        variables: {
          where: { email: userEmail },
          organizationsWhere2: { uid: defaultOrgUid },
        },
      }).then((res) => {
        const isCurrentAdmin = res.data.organizations[0].hasAdmin?.length !== 0;
        dispatch(toggleOrg({ isCurrentAdmin }));
      });
  }, [userEmail, defaultOrg, checkOrgAdmin, defaultOrgUid, dispatch]);

  useEffect(() => {
    cognitoUser &&
      currentOrg({
        variables: {
          where: {
            email: userEmail,
          },
        },
      }).then((res) => {
        dispatch(
          toggleOrg({
            defaultOrgUid:
              res.data?.people[0]?.orgUnitsConnection.edges?.map((u) => u.node?.organization)
                .length === 0
                ? ''
                : res.data?.people[0]?.orgUnitsConnection.edges?.map((u) => u.node?.organization)
                    .length === 1
                ? res.data?.people[0]?.orgUnitsConnection.edges[0].node.organization.uid
                : res.data?.people[0]?.defaultOrgUid,
            defaultOrg: defaultOrg,
            defaultOrgUnit: res.data?.people[0]?.orgUnitsConnection.edges.find(
              (o) => o.node?.organization?.uid === defaultOrg?.uid,
            )?.node,
            worksAt: res.data?.people[0]?.orgUnitsConnection.edges?.map(
              (u) => u.node?.organization,
            ),
          }),
        );
      });
  }, [cognitoUser, defaultOrg, currentOrg, dispatch, userEmail]);

  useEffect(() => {
    if (authenticated) {
      const toasted = localStorage.getItem('toastedSignedIn');
      if (!toasted) {
        successToastMid('Signed in');
        localStorage.setItem('toastedSignedIn', true);
      }
      const invitationUrl = sessionStorage.getItem('invitation_url');
      if (invitationUrl) {
        // Sign in with an invitation
        navigate(`${invitationUrl}`);
        sessionStorage.removeItem('invitation_url'); // Remove invitation-url after redirecting user
      } else {
        // Does not sign-in with an invitation
        // Do something if necessary...
      }
    }
  }, [authenticated, navigate, userEmail]);

  // Reset Query store if authenticated changes
  useEffect(() => {
    client.resetStore();
  }, [authenticated]);

  return (
    <>
      <div className="text-xs">
        <Toaster position="top-center" />
      </div>
      <PopOver />
      {loading ? (
        <div className="h-screen w-screen bg-gray-800 fixed top-0 left-0 flex justify-center items-center">
          <LoadingState color="white" secondaryColor="gray" />
        </div>
      ) : (
        <SplitFactory config={sdkConfig} updateOnSdkTimedout={true}>
          {/* PROPS: { factory, isReady, isTimedout, lastUpdate } */}
          {() => {
            return (
              <SplitClient splitKey={defaultOrg?.uid}>
                <div className="bg-bg_lightgray w-full flex flex-col h-fit-min-screen">
                  <SearchComponent />
                  <BugReport />
                  {!location.pathname.startsWith('/invitation') && <Header />}
                  <Routes>
                    <Route
                      path="*"
                      element={
                        <ProtectedRoute>
                          <PageNotFound />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <Home />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <ProtectedRoute>
                          <Login />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/people"
                      element={
                        <ProtectedRoute>
                          <People />
                        </ProtectedRoute>
                      }
                    />
                    {/* The outer route enforces protection for all child routes */}
                    <Route
                      path="/profiles"
                      element={
                        <ProtectedRoute>
                          <ProfileGenerator />
                        </ProtectedRoute>
                      }
                    >
                      {/* This route is rendered via the ProfileGenerator Page */}
                      <Route path="select" />
                      {/* This route is rendered via the ProfileGenerator Page */}
                      <Route path="template" />
                      <Route path="generate" />
                    </Route>

                    <Route
                      path="/projects"
                      element={
                        <ProtectedRoute>
                          <Projects />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/projects/:projectId"
                      element={
                        <ProtectedRoute>
                          <ProjectDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/skills"
                      element={
                        <ProtectedRoute>
                          <Skills />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/skills/certifications"
                      element={
                        <ProtectedRoute>
                          <CertificationDirectory />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/skills/taxonomy"
                      element={
                        <ProtectedRoute>
                          <SkillTaxonomyView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/skills/:skill"
                      element={
                        <ProtectedRoute>
                          <SkillProfile />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assessment"
                      element={
                        <ProtectedRoute>
                          <Assessment />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assessment/completions"
                      element={
                        <ProtectedRoute>
                          <AssessmentCompletions />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assessment/:assessmentId"
                      element={
                        <ProtectedRoute>
                          <TakeAssessment />
                        </ProtectedRoute>
                      }
                    >
                      <Route
                        path="introduction"
                        element={
                          <ProtectedRoute>
                            <AssessmentIntro />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="select"
                        element={
                          <ProtectedRoute>
                            <AssessmentSelectSkills />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="rate"
                        element={
                          <ProtectedRoute>
                            <AssessmentRateSkills />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="confirmation"
                        element={
                          <ProtectedRoute>
                            <AssessmentConfirmation />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/assessment/:assessmentId/:projectId"
                      element={
                        <ProtectedRoute>
                          <TakeAssessment />
                        </ProtectedRoute>
                      }
                    >
                      <Route
                        path="introduction"
                        element={
                          <ProtectedRoute>
                            <AssessmentProjectIntro />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="select"
                        element={
                          <ProtectedRoute>
                            <AssessmentProjectSelectSkills />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="rate"
                        element={
                          <ProtectedRoute>
                            <AssessmentProjectRateSkills />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="description"
                        element={
                          <ProtectedRoute>
                            <AssessmentProjectDescription />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="confirmation"
                        element={
                          <ProtectedRoute>
                            <AssessmentProjectConfirmation />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/admin"
                      element={
                        <ProtectedRoute>
                          <RoleRestrictedRoute>
                            <NestedVerticalNav
                              navigation={adminNavigation}
                              title="Admin"
                              menuTitle="Admin panel"
                            />
                          </RoleRestrictedRoute>
                        </ProtectedRoute>
                      }
                    >
                      <Route path="people" element={<PeopleDirectoryAdminView />} />
                      <Route path="skills" element={<SkillsDirectoryAdminView />} />
                      <Route path="projects" element={<ProjectsDirectoryAdminMenuComponent />} />
                      <Route path="communication" element={<AnnouncementsAdminView />} />
                      <Route path="connectors" element={<ConnectorsAdminMenu />} />
                      <Route path="competency" element={<CompetencyAdminMenu />} />
                    </Route>
                    <Route
                      path="/admin/communication/announcements/:id/create"
                      element={<CreateAnnouncement />}
                    />
                    <Route
                      path="/power-admin"
                      element={
                        <ProtectedRoute>
                          <PowerAdmin />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/:profile"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/preferences"
                      element={
                        <ProtectedRoute>
                          <Preferences />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/plan"
                      element={
                        <ProtectedRoute>
                          <Plan />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/invitation/:invitationUid"
                      element={
                        <ProtectedRoute>
                          <HandleInvitation />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute>
                          <NestedVerticalNav
                            navigation={settingsNavigation}
                            title="User Settings"
                            menuTitle="Settings"
                          />
                        </ProtectedRoute>
                      }
                    >
                      <Route
                        path="personal"
                        element={
                          <ProtectedRoute>
                            <SettingsPersonal />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="company"
                        element={
                          <ProtectedRoute>
                            <SettingsCompany />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="contact"
                        element={
                          <ProtectedRoute>
                            <SettingsContact />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="education"
                        element={
                          <ProtectedRoute>
                            <SettingsEducation />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="work"
                        element={
                          <ProtectedRoute>
                            <SettingsWork />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="certifications"
                        element={
                          <ProtectedRoute>
                            <SettingsCertifications />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        index
                        element={
                          <ProtectedRoute>
                            <SettingsPersonal />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route path="/explore" element={<Explore />} />
                    <Route path="/explore/jg-skill-explore" element={<SkillExplore />} />
                    <Route
                      path="/announcements/:announcementId"
                      element={
                        <ProtectedRoute>
                          <Announcements />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </div>
              </SplitClient>
            );
          }}
        </SplitFactory>
      )}
    </>
  );
};

export default App;
