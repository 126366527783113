import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetPagination, updatePagination } from '../../redux/paginationSlice';
import { sortColumnByName } from '../../redux/sortTableSlice';

/**
 *
 * @param {*} entity          Person/Skill/Project/Certs
 * @param {*} graphqlQuery    GraphQL query
 * @param {*} action          Action button
 * @param {*} initialColumns  Columns allowed sorting
 * @returns
 */
const useTableViewHooks = (entity, initialColumns) => {
  const dispatch = useDispatch();
  const [sortColumns, setSortColumns] = useState(initialColumns);

  // Get query string from URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  // Handle pagination
  const handleUpdatePagination = useCallback(
    (value) => {
      switch (entity) {
        case 'people':
          dispatch(updatePagination({ peopleCurrentPage: value }));
          break;
        case 'skills':
          dispatch(updatePagination({ skillsCurrentPage: value }));
          break;
        case 'certifications':
          dispatch(updatePagination({ certificationsCurrentPage: value }));
          break;
        case 'projects':
          dispatch(updatePagination({ projectsCurrentPage: value }));
          break;
        case 'assessments':
          dispatch(updatePagination({ assessmentsCurrentPage: value }));
          break;
        default:
          break;
      }
    },
    [entity, dispatch],
  );

  const handleResetPagination = useCallback(() => {
    dispatch(resetPagination());
  }, [dispatch]);

  // Handle sorting columns
  const handleSortColumns = (column) => {
    setSortColumns({
      ...initialColumns,
      [column]: (sortColumns[column] + 1) % 3,
    });

    dispatch(
      sortColumnByName({
        table: entity,
        column: column,
        sortValue: (sortColumns[column] + 1) % 3,
      }),
    );
  };

  // update pagination when url change
  useEffect(() => {
    if (typeof parseInt(params.page) === 'number' && parseInt(params.page) > 0) {
      handleUpdatePagination(parseInt(params.page));
    } else {
      handleResetPagination();
    }
  }, [params.page, handleResetPagination, handleUpdatePagination]);

  return [handleSortColumns];
};

export default useTableViewHooks;
