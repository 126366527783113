import SearchAndSelectInput from '../../custom-prebuilt/SearchAndSelectInput';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { QueryPersonCertSimple } from '../../graphql/people';
import { AddUserWithCertConnection, QueryAllCert } from '../../graphql/certifications';
import { useMutation } from '@apollo/client';
import { Label } from '../../custom-prebuilt/common.component';

const AddPersonWitheCertification = ({ certDetails, onClose }) => {
  const [userSearch, setUserSearch] = useState(certDetails?.certHolder || '');
  const [certHolder, setCertHolder] = useState(certDetails?.certHolder || '');
  const [certification, setCertification] = useState(certDetails?.name || '');
  const [certSearch, setCertSearch] = useState(certDetails?.name || '');
  const [acquiredDate, setAcquiredDate] = useState('');
  const [inputError, setInputError] = useState(); // eslint-disable-line no-unused-vars

  const [addNewCertificationHolder] = useMutation(AddUserWithCertConnection, {
    refetchQueries: ['QueryUserEducation', 'QueryPersonCertSimple'],
    ignoreResults: true,
  });

  const { data } = useQuery(QueryPersonCertSimple, {
    variables: {
      where: {
        active: true,
      },
    },
  });

  const allPeople = data?.people;
  const { data: allCerts } = useQuery(QueryAllCert);

  const onAdd = () => {
    if (certHolder === '' || certification === '' || acquiredDate === '') {
      setInputError(true);
      return;
    }

    addNewCertificationHolder({
      variables: {
        updateCertificationsWhere2: {
          name: certification,
        },
        update: {
          peopleWithCert: [
            {
              connect: [
                {
                  edge: {
                    acquiredDate: acquiredDate,
                  },
                  where: {
                    node: {
                      name: certHolder,
                    },
                  },
                },
              ],
            },
          ],
        },
      },
    });

    setUserSearch(certDetails?.certHolder || '');
    setCertHolder(certDetails?.certHolder || '');
    setCertification(certDetails?.name || '');
    setCertSearch(certDetails?.name || '');
    setAcquiredDate('');
    setInputError(false);
    onClose?.();
  };

  return (
    <div>
      <div>
        <div className="h-fit">
          <div className="text-sm text-gray-500">
            <div className="h-full w-full flex flex-col items-center">
              <div className="w-full">
                <Label className="text-left mb-1">User</Label>
                <SearchAndSelectInput
                  disabled={!!certDetails?.certHolder}
                  classes={`text-left  ${inputError && !certHolder ? 'border-red-500' : ''}`}
                  search={userSearch}
                  setSearch={setUserSearch}
                  data={allPeople}
                  searchField="name"
                  setSelected={setCertHolder}
                />
              </div>
              <div className="w-full">
                <Label className="text-left mb-1">Certification</Label>
                <SearchAndSelectInput
                  search={certSearch}
                  classes="text-left"
                  error={inputError && !certification}
                  disabled={!!certDetails.name}
                  setSearch={setCertSearch}
                  data={allCerts?.certifications || []}
                  searchField="name"
                  setSelected={setCertification}
                />
              </div>
              <div className="w-full">
                <Label className="text-left mb-1">Acquired Date</Label>
                <input
                  type="date"
                  name="acqDate"
                  value={acquiredDate}
                  onChange={(e) => setAcquiredDate(e.target.value)}
                  className={`rounded-md border-borders w-full h-8 text-xs font-normal focus:border-signature focus:ring-signature ${
                    inputError && !acquiredDate ? 'border-red-500' : ''
                  }`}
                  required
                />
              </div>
              <button
                className="!mt-6 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-gray-900 text-xs md:text-sm font-medium text-white hover:bg-gray-700 sm:text-sm transition duration-150"
                onClick={onAdd}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPersonWitheCertification;
