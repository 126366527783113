import Modal from '../../../../../../custom-prebuilt/Modal.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../../../../../../redux/modalSlice';
import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QueryUserProjects } from '../../../../../../graphql/projects';
import { useAuth } from '../../../../../../lib/authContext';
import Card from '../../../../../shared/Card.component';
import { useNavigate } from 'react-router';
import { Input } from '../../../../../../custom-prebuilt/common.component';
import SearchLogic from '../../../../../../custom-prebuilt/SearchLogic.component';
import { useEffect } from 'react';
import { formatDate } from '../../../../../../lib/formatDate';

const AssessmentSelectWork = () => {
  const { userEmail, cognitoID } = useAuth();
  const show = useSelector((state) => state.modal.show);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');

  const { data, loading } = useQuery(QueryUserProjects, {
    variables: {
      where: {
        AND: [
          {
            peopleWithExperience_SOME: {
              email: userEmail || '',
            },
            name_NOT_STARTS_WITH: 'JG',
          },
        ],
      },
    },
  });

  const handleCloseModal = () => {
    dispatch(toggleModal({ show: false }));
  };

  const handleRedirectProjectAssessment = (p) => {
    navigate(`1ad1fccc-d279-46a0-8980-1d91afd6ba67/${p.id}/introduction`);
  };

  const getLastAssessment = (assessments) => {
    return assessments.find(
      (assessment) => assessment?.personCompleted?.[0]?.cognitoID === cognitoID,
    )?.dateCompleted;
  };
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredPastWork, setFilteredPastWork] = useState([]);

  useEffect(() => {
    if (!data) return;
    setFilteredProjects(data?.projects.filter((p) => p?.organization.length > 0));
    setFilteredPastWork(data?.projects.filter((p) => p?.organization.length === 0));
  }, [data]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    setFilteredProjects(
      data?.projects?.filter(
        (p) =>
          p?.organization.length > 0 &&
          p?.name?.toLowerCase()?.includes(searchInput?.toLowerCase()),
      ),
    );
    setFilteredPastWork(
      data?.projects?.filter(
        (p) =>
          p?.organization.length === 0 &&
          p?.name?.toLowerCase().includes(searchInput?.toLowerCase()),
      ),
    );
  }, [searchInput]);

  const SelectWorkComponent = useMemo(() => {
    return (
      <div className="space-y-2">
        <Input
          placeholder="Search project / previous work"
          value={searchInput}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
        <p className="text-xs font-semibold !mt-4">Jahnel Group Projects:</p>
        <SearchLogic
          loading={loading}
          dataCheck={filteredProjects?.length}
          searchInput={searchInput}
          dataComponent={
            <div className="space-y-2">
              {filteredProjects?.map((p, i) => (
                <div
                  key={i}
                  onClick={() => {
                    handleRedirectProjectAssessment(p);
                  }}
                >
                  <Card title={`Project: ${p?.name}`} hoverEnabled>
                    <p className="text-xs mb-1">Project Start Date: {formatDate(p?.startDate)}</p>
                    <p className="text-xs mb-1">
                      {getLastAssessment(p?.assessments)
                        ? `Last Completed Assessment: ${formatDate(
                            getLastAssessment(p?.assessments),
                          )}`
                        : 'You have not assessed this project yet'}
                    </p>
                  </Card>
                </div>
              ))}
            </div>
          }
        />
        <p className="text-xs font-semibold !mt-4">Previous work:</p>
        <SearchLogic
          loading={loading}
          dataCheck={filteredPastWork?.length}
          searchInput={searchInput}
          dataComponent={
            <div className="space-y-2">
              {filteredPastWork?.map((p, i) => (
                <Card
                  onClick={() => handleRedirectProjectAssessment(p)}
                  key={i}
                  title={`Previous Work: ${p?.name}`}
                  hoverEnabled
                >
                  <p className="text-xs mb-1">Project Start Date: {formatDate(p?.startDate)}</p>
                  <p className="text-xs mb-1">
                    {getLastAssessment(p?.assessments)
                      ? `Last Completed Assessment: ${formatDate(
                          getLastAssessment(p?.assessments),
                        )}`
                      : 'You have not assessed this project yet'}
                  </p>
                </Card>
              ))}
            </div>
          }
        />
      </div>
    );
  }, [searchInput, filteredPastWork, filteredProjects, loading]);

  return (
    <Modal
      open={show}
      title="Select project / previous work"
      content={SelectWorkComponent}
      secondaryButtonLabel="Cancel"
      onSecondaryButtonClick={handleCloseModal}
    />
  );
};

export default AssessmentSelectWork;
