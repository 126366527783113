import {
  ClipboardListIcon,
  MapIcon,
  PencilIcon,
  PlusIcon,
  PuzzleIcon,
} from '@heroicons/react/outline';
import { useNestedNav } from '../../../lib/customHook';
import CreateNewKnowledge from './CreateNewKnowledge';
import CompetencyDirectory from './CompetencyDirectory';
import CreateNewCompetency from './CreateNewCompetency';
import CompetencyComposition from './CompetencyConposition';
import TalentTree from './TalentTree';

const nestedNav = [
  {
    name: 'Create New Knowledge',
    icon: PencilIcon,
  },
  {
    name: 'View/Edit Competencies',
    icon: MapIcon,
  },
  {
    name: 'Create New Competency',
    icon: PlusIcon,
  },
  {
    name: 'Competency Composition',
    icon: PuzzleIcon,
  },
  {
    name: 'Talent Tree',
    icon: ClipboardListIcon,
  },
];

export default function CompetencyAdminMenu() {
  const [current, nestedNavComponent] = useNestedNav(nestedNav);

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full gap-y-4">
      {nestedNavComponent}
      {current === 0 && <CreateNewKnowledge />}
      {current === 1 && <CompetencyDirectory />}
      {current === 2 && <CreateNewCompetency />}
      {current === 3 && <CompetencyComposition />}
      {current === 4 && <TalentTree />}
    </div>
  );
}
