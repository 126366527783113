// import { Fragment, useRef, useState } from 'react'
// import { Dialog, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../../../redux/modalSlice';
import HtmlPreview from './HtmlPreview';
import Modal from '../../../custom-prebuilt/Modal.component';

const ModalPreview = ({ json }) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modal.show);

  const handleCloseModal = () => {
    dispatch(toggleModal({ show: false }));
  };

  return (
    <Modal
      open={show}
      title="Announcement Preview"
      content={
        json ? (
          <div className="h-[500px] w-full overflow-y-auto">
            <HtmlPreview json={json} />
          </div>
        ) : null
      }
      secondaryButtonLabel="Cancel"
      onSecondaryButtonClick={handleCloseModal}
    />
  );
};

export default ModalPreview;
