import { twMerge } from 'tailwind-merge';
import { classNames } from '../../lib/classNames';
// import defaultImg from "../../assets/default-user.svg";
const defaultImg: string = require('../../assets/default-user.svg').default;

type PersonBasicsType = {
  userIconUrl?: string;
  name?: string;
  email?: string;
} | null;

export type PersonCardProps = {
  personBasics: PersonBasicsType;
  className?: string;
  onSelect: () => void;
  disableHover: boolean;
} & Record<string, any>;

/**
 * A basic display of a person
 * @param {*} personBasics Basic info of a person. Must have at least their name, email, and userIconUrl
 * @callback onSelect The action performed when a person is clicked
 */
const PersonCard = ({
  personBasics,
  className,
  onSelect,
  disableHover,
  ...rest
}: PersonCardProps) => {
  return (
    <div
      className={twMerge(
        'bg-white rounded-md shadow p-4 flex flex-row space-x-3 items-center transition duration-150 group w-full',
        disableHover ? 'cursor-default' : 'hover:bg-gray-800 cursor-pointer',
        className,
      )}
      onClick={onSelect}
      {...rest}
    >
      <img
        className="rounded-full h-8 w-8"
        src={personBasics?.userIconUrl ?? defaultImg}
        alt=""
        referrerPolicy="no-referrer"
      />
      <div className="flex flex-col space-y-1 truncate">
        <p
          className={classNames(
            'text-xs font-semibold text-font-dark truncate transition duration-150',
            disableHover ? '' : 'group-hover:text-font-light',
          )}
        >
          {personBasics?.name}
        </p>
        <p className="text-xs font-medium text-font-light truncate">{personBasics?.email}</p>
      </div>
    </div>
  );
};

export default PersonCard;
