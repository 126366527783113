/*eslint-disable*/

import { PlusIcon, MinusIcon } from "@heroicons/react/outline"
import PersonCard from "../../shared/PersonCard.component"
import { useSelector, useDispatch } from "react-redux"
import { toggleSlideOver } from "../../../redux/slideOverSlice"
import SnapshotProfile from '../../../custom-prebuilt/snapshot/SnapshotProfile.component'
import SlideOver from '../../../custom-prebuilt/SlideOver.component'
import { useState, useEffect } from "react"
import { Button, Input } from "../../../custom-prebuilt/common.component"
import { toggleExplore } from "../../../redux/exploreSlice"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import Table from "../../shared/Table.component"
import { ReloadOutlined } from "@ant-design/icons"
import AvailabilitySlider from "./tools/AvailabilitySlider"
import AvailabilityToggle from "./tools/AvailabilityToggle"

const SkillExploreRes = () => {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState("")
  const [filteredPeople, setFilteredPeople] = useState([])
  const personShow = useSelector(state => state.slideOver.show1)
  const profileTarget = useSelector(state => state.slideOver.profileTarget)
  const res = useSelector(state => state.explore.skillExploreRes)
  const canvasItems = useSelector(state => state.explore.canvasItems)
  const [parent] = useAutoAnimate({ duration: 300 })
  const currentTags = useSelector(state => state.explore.currentTags)
  const queryHistory = useSelector(state => state.explore.queryHistory)
  const [selectedSkillTag, setSelectedSkillTag] = useState([])
  // Availability
  const [enabledAvailability, setEnabledAvailability] = useState(false);
  const [availability, setAvailability] = useState(0)

  const handleInputChange = (e) => {
    setSearchInput(e.target.value)
  }

  useEffect(() => {
    let filtered = [...res]
    filtered = filtered?.filter(
      p =>
        p?.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        p?.email.toLowerCase().includes(searchInput.toLowerCase())
    )
    // Filter by availability
    setFilteredPeople(!enabledAvailability ? filtered : filtered.filter(p => (p?.availability || 0) >= availability))
  }, [searchInput, res, availability, enabledAvailability])

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show1: false, profileTarget: null }))
  }

  const processList = (list, el) => {
    const firstListName = list[0][0]
    let firstList = list[0][1]
    if (!firstList.map(e => e.email).includes(el.email)) {
      firstList = [...firstList, el]
    }
    // else {
    //   firstList = firstList.filter(e => e.email !== el.email)
    // }
    return [[firstListName, firstList], ...[...list].slice(1)]
  }

  const handleSelectPersonCompare = (p) => {
    dispatch(toggleExplore({
      canvasItems: processList(canvasItems, p)
    }))
  }

  const checkPersonSelected = (p) => {
    return canvasItems[0][1]?.map(e => e.email).includes(p.email)
  }

  const handlePersonSlideOver = (personBasics) => {
    dispatch(toggleSlideOver({ show1: true, profileTarget: personBasics }))
  }

  /**
   * If no tags are selected, revert to the most recent query
   */
  useEffect(() => {
    if (selectedSkillTag.length === 0 && queryHistory.length > 0) {
      dispatch(toggleExplore({ preserveCurrentTags: true }))
      dispatch(toggleExplore({
        refetchQuery: queryHistory[queryHistory.length - 1]
      }))
    }
  }, [selectedSkillTag])

  const handleSelectSkillTag = (tag, index) => {
    const newTags = [...selectedSkillTag]
    if (selectedSkillTag.includes(tag)) {
      newTags.splice(selectedSkillTag.indexOf(tag), 1)
      setSelectedSkillTag(newTags);
    }
    else {
      newTags.push(tag)
      setSelectedSkillTag(newTags);
    }

    // Why 2 dispatch(toggleExplore)?
    dispatch(toggleExplore({
      preserveCurrentTags: true,
      refetchQuery: newTags.map(item => ` (${item}) `).join('&').trim()
    }))
  }

  const handleResetSelectTags = () => {
    setSelectedSkillTag([])
    dispatch(toggleExplore({
      preserveCurrentTags: true,
      refetchQuery: queryHistory[queryHistory.length - 1]
    }))
  }

  return (
    <div className="h-full flex flex-col space-y-4">
      <div className="flex flex-row flex-wrap">
        {
          currentTags?.map((tag, index) => (
            <Button
              key={index}
              onClick={() => { handleSelectSkillTag(tag, index) }}
              className={`mr-1 mb-1 ${selectedSkillTag.includes(tag) || "!bg-gray-300"}`}
            >
              {tag.trimStart()}
            </Button>
          ))
        }
      </div>
      <p className="text-xs text-gray-700">Showing <span className="font-medium">{filteredPeople?.length}</span> results
        &nbsp;<button onClick={handleResetSelectTags}><ReloadOutlined /></button>
      </p>
      <div className="flex justify-between items-center gap-5">
        <Input
          name="people"
          id="people"
          value={searchInput}
          onChange={handleInputChange}
          className="max-w-sm"
          placeholder="Search..."
        />
        <AvailabilityToggle enabled={enabledAvailability} setEnabled={setEnabledAvailability} />
      </div>
      {
        enabledAvailability && <AvailabilitySlider availability={availability} setAvailability={setAvailability} />
      }
      <Table
        items={filteredPeople}
        actionName="Add"
        action={handleSelectPersonCompare}
        headers={["name"]}
        className="overflow-y-auto"
      />
      <SlideOver
        show={personShow}
        title="User Profile"
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        largeSize={true}
        content={<SnapshotProfile target={profileTarget} coverPic={true} />} />
    </div>
  )
}

export default SkillExploreRes