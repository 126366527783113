import { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import useTableViewHooks from '../custom/useTableViewHooks';
import TableView from '../custom/TableView.component';

import SlideOver from '../../custom-prebuilt/SlideOver.component';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSlideOver } from '../../redux/slideOverSlice';
import SnapshotProfile from '../../custom-prebuilt/snapshot/SnapshotProfile.component';
import Modal from '../../custom-prebuilt/Modal.component';
import { toggleModal } from '../../redux/modalSlice';
import AllTeamMembers from './AllTeamMembers.component';
import { formatDate } from '../../lib/formatDate';
import PersonStack from '../shared/PersonStack.component';
import SnapshotProject from '../../custom-prebuilt/snapshot/SnapshotProject.component';

const ProjectsTableView = (props) => {
  const { customList, paginationSize, actionName, search } = props;

  const dispatch = useDispatch();
  const show = useSelector((state) => state.slideOver.show2);
  const [showProjectSlideover, setShowProjectSlideover] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const target = useSelector((state) => state.slideOver.profileTarget);
  const modalShow = useSelector((state) => state.modal.show1);
  const members = useSelector((state) => state.modal.members);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  // Sorting states
  const initialColumns = {
    name: 0,
    startDate: 0,
  };

  const [handleSortColumns] = useTableViewHooks('projects', initialColumns);

  const tableHeadItems = [
    {
      table: 'projects',
      name: 'name',
      title: 'Project Name',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName:
        'cursor-pointer py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6',
    },
    {
      table: 'projects',
      name: 'startDate',
      title: 'Start Date',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName: 'cursor-pointer px-3 py-3.5 text-left text-xs font-semibold text-gray-900',
    },
    {
      table: 'projects',
      name: 'members',
      title: 'Members',
    },
  ];

  const RenderBody = useMemo(() => {
    const BodyMemo = () => {
      return (
        <>
          {customList &&
            customList.map((project, index) => (
              <tr
                key={index}
                className="hover:bg-gray-200 transition duration-300 cursor-pointer"
                onClick={() => {
                  setShowProjectSlideover(true);
                  setSelectedProject(project);
                }}
              >
                <td className="py-4 pl-4 pr-3 text-xs sm:pl-6">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">{project.name}</div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                  <div className="text-gray-900">{formatDate(project?.startDate)}</div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 w-fit hidden lg:block">
                  <PersonStack
                    peopleArray={project?.peopleWithExperienceConnection.edges}
                    stackNum={5}
                  />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 w-fit table-cell lg:hidden">
                  <PersonStack
                    peopleArray={project?.peopleWithExperienceConnection.edges}
                    stackNum={0}
                  />
                </td>
              </tr>
            ))}
        </>
      );
    };

    return BodyMemo;
  }, [customList]);

  return (
    <>
      <ReactTooltip
        place="top"
        effect="solid"
        backgroundColor="#1F2937"
        offset={{ right: 10 }}
        id="members"
      />
      <TableView
        data={customList}
        tableHeadItems={tableHeadItems}
        paginationSize={paginationSize}
        showPaginationNav={true}
        RenderBody={RenderBody}
        search={search}
        page="Projects"
        renderActionCol={actionName}
      />
      <SlideOver
        show={show}
        title="User Profile"
        onClose={() => {
          dispatch(toggleSlideOver({ show2: false }));
          setTimeout(() => {
            dispatch(toggleSlideOver({ profileTarget: null }));
          }, 500);
        }}
        onClickButtonClose={() => {
          dispatch(toggleSlideOver({ show2: false }));
          setTimeout(() => {
            dispatch(toggleSlideOver({ profileTarget: null }));
          }, 500);
        }}
        largeSize={true}
        content={<SnapshotProfile target={target} coverPic={true} />}
      />
      <SlideOver
        show={showProjectSlideover}
        title="Project Profile"
        largeSize={true}
        onClose={() => setShowProjectSlideover(false)}
        onClickButtonClose={() => setShowProjectSlideover(false)}
        content={<SnapshotProject target={selectedProject} />}
      />
      <Modal
        open={modalShow}
        title="All team members"
        content={<AllTeamMembers members={members} />}
        secondaryButtonLabel="Back"
        onSecondaryButtonClick={() => dispatch(toggleModal({ show1: false }))}
        size="large"
      />
    </>
  );
};

export default ProjectsTableView;
