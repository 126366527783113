import { CursorClickIcon } from '@heroicons/react/outline';
import { useQuery } from '@apollo/client';
import { QueryAllSkills, QueryPopularSkills } from '../../../../../../graphql/skills';
import { useAuth } from '../../../../../../lib/authContext';
import { useState, useEffect, Fragment, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import ClickNHoldSkill from '../../../../../../custom-prebuilt/ClickNHoldSkill.component';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import { useNavigate, useLocation } from 'react-router';
import SlideOver from '../../../../../../custom-prebuilt/SlideOver.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSlideOver } from '../../../../../../redux/slideOverSlice';
import SkillInfo from './SkillInfo.component';
import { toggleAssessment } from '../../../../../../redux/assessmentSlice';
import LoadingState from '../../../../../../custom-prebuilt/preloader/LoadingState.component';
import Modal from '../../../../../../custom-prebuilt/Modal.component';
import SkillSelection from '../../../../../../custom-prebuilt/SkillSelection';
import SimpleSection from '../../../../../shared/SimpleSection.component';
import { GetLastAssessment } from '../../../../../../graphql/assessment';
import { CloseOutlined } from '@ant-design/icons';

const allCategoriesName = [
  { name: 'AWS', icon: '/SkillTagIcons/aws_icon.svg' },
  { name: 'Azure', icon: '/SkillTagIcons/azure_icon.svg' },
  { name: 'Back End', icon: '/SkillTagIcons/backend_icon.svg' },
  { name: 'Data', icon: '/SkillTagIcons/data_icon.svg' },
  { name: 'Design', icon: '/SkillTagIcons/design_icon.svg' },
  { name: 'DevOps', icon: '/SkillTagIcons/devops_icon.svg' },
  { name: 'Front End', icon: '/SkillTagIcons/frontend_icon.svg' },
  { name: 'Google Cloud', icon: '/SkillTagIcons/googlecloud_icon.svg' },
  { name: 'Languages', icon: '/SkillTagIcons/language_icon.svg' },
  { name: 'Mobile', icon: '/SkillTagIcons/mobile_icon.svg' },
  { name: 'Techniques', icon: '/SkillTagIcons/techniques_icon.svg' },
  { name: 'Testing', icon: '/SkillTagIcons/testing_icon.svg' },
  { name: 'Web Platforms', icon: '/SkillTagIcons/webplatforms_icon.svg' },
];

const AssessmentSelectSkills = () => {
  const { userEmail } = useAuth();
  const globalSelectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const globalLoading = useSelector((state) => state.assessment.loading);
  const selectedRole = useSelector((state) => state.assessment.selectedRole);

  const [personSkills, setPersonSkills] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [newlyAddedSkills, setNewlyAddedSkills] = useState([]);
  const [popularSkills, setPopularSkills] = useState([]);
  const [skillSearch, setSkillSearch] = useState('');
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [parent] = useAutoAnimate({ duration: 100 });
  const [parentPanel] = useAutoAnimate({ duration: 100 });
  const navigate = useNavigate();
  const location = useLocation();
  const show = useSelector((state) => state.slideOver.show);
  const show1 = useSelector((state) => state.slideOver.show1);
  const skill = useSelector((state) => state.slideOver.skill);
  const [confirmModal, setConfirmModal] = useState();

  const dispatch = useDispatch();

  const assessmentId = location.pathname.split('/')[2];
  const { data: lastAssessment, loading: prevLoading } = useQuery(GetLastAssessment, {
    variables: {
      where: {
        email: userEmail || '',
      },
      completedAssessmentsWhere2: {
        assessment_SOME: {
          assessmentId: assessmentId,
        },
      },
    },
  });

  const queryAllSkills = useQuery(QueryAllSkills, {
    variables: {
      where: {
        node: {
          email: userEmail || '',
        },
      },
      skillsWhere2: {
        active: true,
      },
    },
  });

  const queryPopularSkills = useQuery(QueryPopularSkills, {
    variables: {
      where: selectedRole.length
        ? {
            category_SOME: {
              type: 'Job Role',
              value_IN: selectedRole,
            },
          }
        : null,
      peopleWithSkillConnectionWhere2: {
        node: {
          email: userEmail || '',
        },
      },
      options: {
        limit: 20,
        sort: [
          {
            totalPeopleRated: 'DESC',
          },
        ],
      },
    },
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (globalLoading) return;
    setPersonSkills(globalSelectedSkills);
  }, [globalLoading]);

  useEffect(() => {
    if (queryAllSkills.data?.skills && !prevLoading) {
      const temp = queryAllSkills.data?.skills;
      const lastTaken = lastAssessment?.people?.[0]?.completedAssessments?.[0]?.dateCompleted;
      setAllSkills(temp);
      if (lastTaken)
        setNewlyAddedSkills([...temp].filter((a) => new Date(a.dateAdded) > new Date(lastTaken)));
      setAllCategories(() =>
        allCategoriesName.map((c) => ({
          name: c.name,
          icon: c.icon,
          value: [...temp].filter((s) => s.category[1]?.value === c.name),
        })),
      );
    }
  }, [queryAllSkills, prevLoading]);

  useEffect(() => {
    queryPopularSkills.data?.skills && setPopularSkills(queryPopularSkills.data?.skills);
  }, [queryPopularSkills.data]);

  useEffect(() => {
    setFilteredSkills(
      allSkills.filter((s) => s.name.toLowerCase().includes(skillSearch.toLowerCase())),
    );
  }, [skillSearch, allSkills]);

  const handleInputChange = (e) => {
    setSkillSearch(e.target.value);
  };

  const handleClearInput = () => {
    setSkillSearch('');
  };

  const handleSelectSkill = useCallback(
    (skill) => {
      if (!skill.node) {
        skill = {
          node: {
            name: skill.name,
            imageLink: skill.imageLink,
            description: skill.description,
          },
          rating: skill.peopleWithSkillConnection.edges.length
            ? skill.peopleWithSkillConnection.edges[0].rating
            : 1,
        };
      }

      const personHasSkill = personSkills.map((s) => s?.node.name).includes(skill?.node.name);

      const selectedSkills = personHasSkill // if person has skill, remove it
        ? personSkills.filter((s) => s.node.name !== skill.node.name) // remove skill
        : [...personSkills, skill]; // add skill

      dispatch(toggleAssessment({ selectedSkills }));
      setPersonSkills(selectedSkills);
    },
    [personSkills, dispatch],
  );

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false, skill: null }));
  };

  const handleCloseSlideOver1 = () => {
    dispatch(toggleSlideOver({ show1: false }));
  };

  const selectedSkillComponent = useMemo(() => {
    return (
      <>
        <div ref={parent} className="w-full h-full flex flex-row flex-wrap">
          {globalLoading ? (
            <LoadingState />
          ) : personSkills.length ? (
            [...personSkills].map((skill, index) => (
              <Fragment key={index}>
                {skill?.node && (
                  <div
                    className="w-14"
                    data-tip={skill.node.name}
                    data-for="skill"
                    onClick={() => {
                      new Promise((resolve, reject) => {
                        setConfirmModal({ resolve, reject });
                      })
                        .then(() => {
                          handleSelectSkill(skill);
                        })
                        .catch(() => {
                          console.log('cancelled');
                        })
                        .finally(() => {
                          setConfirmModal(null);
                        });
                    }}
                  >
                    <ClickNHoldSkill skill={skill?.node} disable />
                  </div>
                )}
              </Fragment>
            ))
          ) : (
            <p className="text-xs text-gray-500">You don&apos;t have any rated skills yet</p>
          )}
        </div>
        <Modal
          open={!!confirmModal}
          content="Are you sure you want to remove this skill?"
          destructiveButtonLabel="Yes"
          onDestructiveButtonClick={() => {
            confirmModal.resolve?.();
          }}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={() => confirmModal.reject?.()}
        />
      </>
    );
  }, [personSkills, handleSelectSkill, parent, confirmModal]);

  return (
    <div className="w-full h-full xl:pl-4">
      <div className="hidden xl:block">
        <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="skill" />
      </div>

      <SlideOver
        show={show}
        onClickButtonClose={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        title="Details"
        content={<SkillInfo skill={skill} />}
        bgWhite
      />

      <SlideOver
        show={show1}
        onClickButtonClose={handleCloseSlideOver1}
        onClose={handleCloseSlideOver1}
        title="Selected skills"
        content={selectedSkillComponent}
        bgWhite
      />

      <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-5 h-full">
        <div className="flex flex-col space-y-5 flex-1">
          <div ref={parentPanel} className="px-4 py-5 shadow bg-white rounded-md w-full">
            <div className="flex flex-row justify-between items-center mb-4 xl:mb-0">
              <h2 className="text-xs mb-0 xl:mb-3 font-semibold">Overview</h2>
              <div
                onClick={() => {
                  dispatch(toggleSlideOver({ show1: true }));
                }}
                className="xl:hidden flex flex-row px-3 py-2 bg-gray-900 rounded-md shadow text-white cursor-pointer"
              >
                <p className="text-xs mr-2">Selected skills</p>
                <CursorClickIcon className="h-4 w-4" />
              </div>
            </div>
            <p className="text-xs mb-2">Select skills that you have ANY knowledge in</p>
            <p className="text-xs mb-2">Tip: You can hold-click a skill to view its details</p>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer mr-2"
              onClick={() => {
                navigate(`${getLocationPrevLevel(location)}/introduction`);
              }}
            >
              Back
            </button>
            {personSkills.length ? (
              <button
                className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer"
                onClick={() => {
                  navigate(`${getLocationPrevLevel(location)}/rate`);
                }}
              >
                Rate skills
              </button>
            ) : null}
          </div>

          <div className="px-4 py-5 shadow bg-white rounded-md w-full flex-1 overflow-y-auto overflow-x-hidden space-y-5">
            <div className="flex gap-2 items-center">
              <input
                type="text"
                disabled={!allSkills.length}
                name="skill-search"
                id="skill-search"
                className="block rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs
                    transition duration-100 w-44 disabled:bg-gray-50"
                placeholder="Search for a skill"
                onChange={handleInputChange}
                value={skillSearch}
              />
              <CloseOutlined
                className="text-gray-500 cursor-pointer hover:text-gray-900"
                onClick={handleClearInput}
              />
            </div>
            {!skillSearch ? (
              !prevLoading && popularSkills.length && allCategories.length ? (
                <>
                  {!!newlyAddedSkills.length && (
                    <div className="w-full">
                      <p className="text-xs font-medium mb-3">Newly added skills</p>
                      <div className="flex flex-row flex-wrap">
                        {newlyAddedSkills.map((skill, index) => (
                          <div
                            key={index}
                            className="w-14"
                            data-tip={skill.name}
                            data-for="skill"
                            onClick={() => {
                              handleSelectSkill(skill);
                            }}
                          >
                            <ClickNHoldSkill preSelected={personSkills} skill={skill} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="w-full">
                    <p className="text-xs font-medium mb-3">Popular skills</p>
                    <div className="flex flex-row flex-wrap">
                      {popularSkills.map((skill, index) => (
                        <div
                          key={index}
                          className="w-14"
                          data-tip={skill.name}
                          data-for="skill"
                          onClick={() => {
                            handleSelectSkill(skill);
                          }}
                        >
                          <ClickNHoldSkill preSelected={personSkills} skill={skill} />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="w-full">
                    <p className="text-xs font-medium mb-3">All categories</p>
                    <SkillSelection
                      skills={allSkills}
                      handleSelectSkill={handleSelectSkill}
                      selectedSkills={personSkills}
                    />
                  </div>
                </>
              ) : (
                <LoadingState />
              )
            ) : (
              <div className="w-full">
                <p className="text-xs font-medium mb-3">Results</p>
                <div className="flex flex-row flex-wrap">
                  {filteredSkills.map((skill, index) => (
                    <div
                      key={index}
                      className="w-14"
                      data-tip={skill.name}
                      data-for="skill"
                      onClick={() => {
                        handleSelectSkill(skill);
                      }}
                    >
                      <ClickNHoldSkill preSelected={personSkills} skill={skill} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="w-[30%] max-w-md hidden xl:block overflow-y-auto overflow-x-hidden"
          data-cy="selected-skills"
        >
          <SimpleSection>
            <p className="text-xs font-medium mb-3">Selected skills</p>
            {selectedSkillComponent}
          </SimpleSection>
        </div>
      </div>
    </div>
  );
};

export default AssessmentSelectSkills;
