import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalState = {
  show: boolean;
  show1: boolean;
  show2: boolean;
  skills: any;
  skill: any;
  members: any;
  assessmentId: string | null;
};

const initialState: ModalState = {
  show: false,
  show1: false, //In case there are more than 2 modals in one component
  show2: false,
  skills: null,
  skill: null,
  members: null,
  assessmentId: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<Partial<ModalState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
