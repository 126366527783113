import { useState } from 'react';
import { useMutation } from '@apollo/client';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import {
  DeleteWorkExperience,
  QueryUserWorkExperience,
  UpdateWorkExperience,
} from '../../graphql/work_experience';
import { TrashIcon } from '@heroicons/react/outline';
import {
  H2,
  Input,
  InputErrorCheck,
  Select,
  TextArea,
} from '../../custom-prebuilt/common.component';
import IconButton, { IconButtons } from '../shared/IconButton.component';
import { CancelButton, ConfirmButton } from '../shared/Button.component';
import { getYear } from '../../lib/formatDate';
import { useAuth } from '../../lib/authContext';

const industries = [
  'Agriculture & Mining',
  'Business Services',
  'Computers & Electronics',
  'Consumer Services',
  'Education',
  'Energy & Utilities',
  'Financial Services',
  'Government',
  'Healthcare, Pharmaceuticals, & Biotech',
  'Manufacturing',
  'Media & Entertainment',
  'Non-Profit',
  'Real Estate & Construction',
  'Retail',
  'Software & Internet',
  'Telecommunications',
  'Transportation & Storage',
  'Travel, Recreation, and Leisure',
  'Wholesale & Distribution',
  'Other',
];

/** Component for advertising users past work experience
 * @param {JSON} attendedData initial data on a user's experience
 * @param {boolean} editable flag controlling whether this component is editable or not
 * @returns a card advertising a users education
 */
const EditableExperienceCard = ({ workExperience }) => {
  //component state variables
  const [editing, setEditing] = useState();
  const [deletionFlag, setDeletionFlag] = useState();
  const [inputError, setError] = useState();
  const { userEmail } = useAuth();

  //queries and mutations
  const [updateExperience, { loading: updateLoading, error: updateError }] =
    useMutation(UpdateWorkExperience);
  const [deleteExperience, { loading: deleteLoading, error: deleteError }] =
    useMutation(DeleteWorkExperience);

  //input state variables
  const [company, setCompany] = useState(workExperience?.node.name);
  const [location, setLocation] = useState(workExperience?.node.location);
  const [title, setTitle] = useState(workExperience?.role);
  const [description, setDescription] = useState(workExperience?.node.description);
  const [startDate, setStartDate] = useState(workExperience?.startDate);
  const [endDate, setEndDate] = useState(workExperience?.endDate);
  const [industry, setIndustry] = useState(workExperience?.node?.industry ?? '');

  const onUpdate = async () => {
    if (company === '' || location === '' || startDate === '' || endDate === '') {
      setError(true);
      return;
    }
    await updateExperience({
      variables: {
        where: {
          email: userEmail,
        },
        update: {
          experience: [
            {
              where: {
                node: {
                  id: workExperience?.node.id,
                },
              },
              update: {
                edge: {
                  startDate: startDate,
                  endDate: endDate,
                  role: title,
                },
                node: {
                  _on: {
                    Project: {
                      name: company,
                      company: company,
                      location: location,
                      industry: industry,
                      startDate: startDate,
                      endDate: endDate,
                      description: description,
                    },
                  },
                },
              },
            },
          ],
        },
      },
      refetchQueries: [QueryUserWorkExperience],
    });
    setEditing();
    setError();
  };

  const onDelete = async () => {
    await deleteExperience({
      variables: {
        where: {
          id: workExperience?.node.id,
        },
        delete: {
          assessments: [
            {
              where: {
                node: {
                  personCompleted_SOME: {
                    email: userEmail,
                  },
                },
              },
            },
          ],
        },
      },
      refetchQueries: [QueryUserWorkExperience],
    });
    setEditing();
  };

  if (updateError || deleteError) {
    console.log(updateError);
    console.log(deleteError);
  }

  return (
    <div className={`relative border border-borders rounded-md p-4 space-y-1 my-6 bg-white`}>
      {updateLoading || deleteLoading ? (
        <LoadingState />
      ) : editing ? (
        <>
          {deletionFlag ? (
            <div className="absolute w-full h-full bg-white bg-opacity-90 top-0 left-0 rounded py-2">
              <p className="text-center mt-32 mb-4">
                Are you sure you would like to delete your work experience for{' '}
                <span className="italic font-bold">{workExperience.node.name}</span>?
              </p>
              <div className="flex w-3/5 mx-auto">
                <ConfirmButton onClick={onDelete} />
                <CancelButton onClick={() => setDeletionFlag(false)} />
              </div>
            </div>
          ) : (
            <TrashIcon
              className="absolute bottom-1 right-1 w-6 stroke-bg-dark cursor-pointer"
              onClick={() => setDeletionFlag(true)}
            />
          )}
          <div className="flex flex-col space-y-1 absolute top-1 right-1">
            <IconButton icon={IconButtons.CheckCircle} onClick={onUpdate} />
            <IconButton icon={IconButtons.XCircle} onClick={() => setEditing(false)} />
          </div>
          <div className="md:w-4/5">
            <Input
              type="text"
              placeholder="company.."
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              maxLength="50"
            />
            <InputErrorCheck value={company} error={inputError}>
              This is a required field
            </InputErrorCheck>

            <Select value={industry} onChange={(e) => setIndustry(e.target.value)}>
              <option value={''} />
              {industries.map((entry, i) => {
                return (
                  <option value={entry} key={i}>
                    {entry}
                  </option>
                );
              })}
            </Select>

            <Input
              type="text"
              placeholder="location.."
              value={location}
              onChange={(event) => setLocation(event.target.value)}
            />
            <InputErrorCheck value={location} error={inputError}>
              This is a required field
            </InputErrorCheck>

            <Input
              type="text"
              placeholder="title.."
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              maxLength="50"
            />
            <InputErrorCheck value={title} error={inputError}>
              This is a required field
            </InputErrorCheck>

            <TextArea
              rows={5}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              maxLength="2000"
            />

            <div className="flex justify-between w-4/5">
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <InputErrorCheck value={startDate} error={inputError}>
              This is a required field
            </InputErrorCheck>
          </div>
        </>
      ) : (
        <>
          <IconButton
            icon={IconButtons.Pencil}
            onClick={() => {
              setEditing(true);
            }}
            className="absolute top-1 right-2"
          />
          <H2>{workExperience?.node.name}</H2>
          {workExperience?.node.industry && workExperience?.node.industry !== 'industry' && (
            <p className="text-xs text-font-dark my-1">{workExperience?.node.industry}</p>
          )}
          <p className="text-xs text-font-dark my-1">{workExperience?.role}</p>
          <p className="text-xs text-font-dark my-1">{workExperience?.node.location}</p>
          <p className="text-xs text-font-dark my-1">
            <span>
              {getYear(workExperience?.startDate, 'N/A')} -{' '}
              {getYear(workExperience?.endDate, 'N/A')}
            </span>
          </p>
          <p className="text-font-light text-xs">{workExperience?.node.description}</p>
        </>
      )}
    </div>
  );
};

export default EditableExperienceCard;
