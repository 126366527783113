import React, { useLayoutEffect, useState } from 'react';

export enum SortDirection {
  OFF,
  ASC,
  DESC,
}

export type TableSortValue = { field: string; direction: SortDirection };

export type SortIndicatorInput = {
  tableSortState: TableSortValue;
  columnField: string;
  initialSortValue?: SortDirection;
};

const SortIndicator = ({ tableSortState, columnField, initialSortValue }: SortIndicatorInput) => {
  const [sortValue, setSortValue] = useState(initialSortValue);

  useLayoutEffect(() => {
    setSortValue(
      tableSortState.field === columnField ? tableSortState.direction : SortDirection.OFF,
    );
  }, [tableSortState, sortValue]);

  return (
    <>
      {sortValue === SortDirection.OFF && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          style={{ marginLeft: '-2px', marginRight: '-2px' }}
        >
          <path d="M12 0l8 10h-16l8-10zm8 14h-16l8 10 8-10z" />
        </svg>
      )}
      {sortValue === SortDirection.DESC && (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
          <path d="M24 3l-12 18-12-18z" />
        </svg>
      )}
      {sortValue === SortDirection.ASC && (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
          <path d="M0 21l12-18 12 18z" />
        </svg>
      )}
    </>
  );
};

export default SortIndicator;
