import { useQuery } from '@apollo/client';
import { H2 } from '../../custom-prebuilt/common.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { QueryUserWorkExperience } from '../../graphql/work_experience';
import { useAuth } from '../../lib/authContext';
import AddWorkExperience from './AddWorkExperience.component';
import EditableExperienceCard from './EditableWorkExperienceCard.component';

/** Settings section for editing a user's past educational experience
 * @returns previously added educations and a section to add a new education
 */
const SettingsWork = () => {
  const { userEmail } = useAuth();
  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QueryUserWorkExperience, {
    variables: {
      where: {
        email: userEmail,
      },
      experienceConnectionWhere2: {
        node: {
          _on: {
            Project: {
              organizationAggregate: {
                count: 0,
              },
            },
          },
        },
      },
    },
    skip: !userEmail,
  });

  if (error) {
    console.error(error);
  }

  return (
    <>
      {queryLoading ? (
        <LoadingState />
      ) : (
        <div className="px-4 w-full h-full overflow-y-auto">
          <H2>Work Experience</H2>
          <div className="lg:w-5/12 space-y-4 my-6">
            {!data?.people?.[0]?.experienceConnection?.edges?.length ? (
              <span className="text-font-light">No Work Experience</span>
            ) : (
              data?.people?.[0]?.experienceConnection?.edges?.map((company, index) => {
                return (
                  <EditableExperienceCard
                    key={`${index}-${company.node.name}`}
                    workExperience={company}
                  />
                );
              })
            )}
          </div>
          <div className="lg:w-1/2">
            <AddWorkExperience />
          </div>
        </div>
      )}
    </>
  );
};
export default SettingsWork;
