import { gql } from '@apollo/client';

export const QueryUserWorkExperience = gql`
  query QueryUserWorkExperience(
    $where: PersonWhere
    $experienceConnectionWhere2: PersonExperienceConnectionWhere
  ) {
    people(where: $where) {
      experienceConnection(where: $experienceConnectionWhere2) {
        edges {
          startDate
          endDate
          totalHours
          role
          node {
            ... on Project {
              name
              company
              id
              description
              industry
              location
            }
          }
        }
      }
    }
  }
`;
/*
{
  "where": {
    "email": null
  },
  "experienceConnectionWhere2": {
    "node": {
      "_on": {
        "Project": {
          "organizationAggregate": {
            "count": 0
          }
        }
      }
    }
  }
}
*/

export const QueryUserExperience = gql`
  query QueryUserExperience(
    $where: PersonWhere
    $experienceConnectionWhere2: PersonExperienceConnectionWhere
    $assessmentsWhere2: AssessmentCompletionWhere
  ) {
    people(where: $where) {
      experienceConnection(where: $experienceConnectionWhere2) {
        edges {
          active
          startDate
          endDate
          totalHours
          hoursPerWeek
          role
          node {
            ... on Project {
              name
              id
              company
              hyperlinks
              startDate
              endDate
              sector
              industry
              location
              description
              active
              assessments(where: $assessmentsWhere2) {
                description
                skillsConnection {
                  edges {
                    rating
                    node {
                      name
                      imageLink
                      slug
                    }
                  }
                }
                description
              }
              organization {
                name
                uid
              }
            }
          }
        }
      }
    }
  }
`;
/*
{
  "where": {
    "email": null
  },
  "experienceConnectionWhere2": {
    "node": {
      "_on": {
        "Project": {
          "AND": [
            {
              "active": null,
              "name_NOT_STARTS_WITH": null
            }
          ]
        }
      }
    }
  },
  "assessmentsWhere2": {
    "personCompleted_SOME": {
      "email": null
    }
  }
}
*/

export const UpdateWorkExperience = gql`
  mutation UpdateWorkExperience($where: PersonWhere, $update: PersonUpdateInput) {
    updatePeople(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;
/*
{
  "where": {
    "email": null
  },
  "update": {
    "experience": [
      {
        "where": {
          "node": {
            "id": null
          }
        },
        "update": {
          "edge": {
            "startDate": null,
            "endDate": null,
            "role": null
          },
          "node": {
            "_on": {
              "Project": {
                "name": null,
                "company": null,
                "location": null,
                "industry": null,
                "startDate": null,
                "endDate": null,
                "description": null
              }
            }
          }
        }
      }
    ]
  }
}
*/

export const CreateWorkExperience = gql`
  mutation CreateWorkExperience($create: PersonRelationInput, $where: PersonWhere) {
    updatePeople(create: $create, where: $where) {
      info {
        bookmark
      }
    }
  }
`;
/*
{
  "create": {
    "experience": [
      {
        "edge": {
          "active": null,
          "startDate": null,
          "endDate": null,
          "role": null
        },
        "node": {
          "Project": {
            "active": null,
            "name": null,
            "company": null,
            "id": null,
            "industry": null,
            "location": null,
            "sector": null,
            "startDate": null,
            "endDate": null,
            "description": null,
            "hyperlinks": null
          }
        }
      }
    ]
  },
  "where": {
    "email": null
  }
}
*/

export const DeleteWorkExperience = gql`
  mutation DeleteWorkExperience($where: ProjectWhere, $delete: ProjectDeleteInput) {
    deleteProjects(where: $where, delete: $delete) {
      nodesDeleted
    }
  }
`;
/*
{
  "where": {
    "id": null
  },
  "delete": {
    "assessments": [
      {
        "where": {
          "node": {
            "personCompleted_SOME": {
              "email": null
            }
          }
        }
      }
    ]
  }
}
*/
