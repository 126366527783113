import { useMutation, useQuery } from '@apollo/client';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { QueryPersonDetails } from '../../graphql/people';
import { useAuth } from '../../lib/authContext';
import { H2, Label, Input, Button, InputErrorCheck } from '../../custom-prebuilt/common.component';
import Card from '../shared/Card.component';
import SearchAndSelectInput from '../../custom-prebuilt/SearchAndSelectInput';
import { useEffect, useState } from 'react';
import { AddUserWithCertConnection, QueryAllCert } from '../../graphql/certifications';

const SettingsCertifications = () => {
  const { cognitoID } = useAuth();
  const [filteredCerts, setFilteredCerts] = useState([]);
  const [certSearch, setCertSearch] = useState('');
  const [selectedCert, setSelectedCert] = useState('');
  const [acquiredDate, setAcquiredDate] = useState('');
  const [inputError, setInputError] = useState(false);

  const { data, loading } = useQuery(QueryPersonDetails, {
    variables: {
      where: {
        cognitoID: cognitoID,
      },
    },
  });
  const { data: allCerts, loading: certLoading } = useQuery(QueryAllCert);
  const [addUserCert] = useMutation(AddUserWithCertConnection, {
    refetchQueries: ['QueryUserEducation', 'QueryPersonDetails'],
    ignoreResults: true,
  });

  useEffect(() => {
    if (!allCerts || !data) return;
    setFilteredCerts(
      allCerts?.certifications?.filter(
        (cert) =>
          !data?.people?.[0]?.certsConnection?.edges?.find((c) => c.node.name === cert.name),
      ),
    );
  }, [allCerts, data]);

  const handleAddCert = async () => {
    if (!acquiredDate || !selectedCert) {
      setInputError(true);
      return;
    }
    await addUserCert({
      variables: {
        updateCertificationsWhere2: {
          name: selectedCert,
        },
        update: {
          peopleWithCert: [
            {
              connect: [
                {
                  edge: {
                    acquiredDate: acquiredDate,
                  },
                  where: {
                    node: {
                      cognitoID: cognitoID,
                    },
                  },
                },
              ],
            },
          ],
        },
      },
    });
    setSelectedCert('');
    setCertSearch('');
    setAcquiredDate('');
    setInputError(false);
  };

  const UserCertification = ({ certDetails }) => {
    return (
      <>
        <Card>
          <div className="flex flex-row py-5">
            <img
              className="h-16 w-16 rounded-full"
              referrerPolicy="no-referrer"
              src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${certDetails.node.logo}`}
              alt=""
            />
            <div className="flex flex-col ml-5">
              <p className="text-sm font-medium leading-relaxed">{certDetails.node.name}</p>
              <p className="text-xs text-gray-700 font-medium mt-1">
                {certDetails.node.category ? `${certDetails.node.category} - ` : ''}
                {certDetails.node.level}
              </p>
              <p className="text-xs text-gray-700 font-medium mt-1">
                Acquired:{' '}
                <span className="text-gray-500 font-normal">
                  {certDetails.acquiredDate ? certDetails.acquiredDate : 'N/A'}
                </span>
              </p>
            </div>
          </div>
        </Card>
      </>
    );
  };

  return (
    <>
      <div className="px-4 w-full h-full overflow-y-auto">
        <H2>Certifications</H2>
        <div className="lg:w-5/12 space-y-4 my-6">
          {loading ? (
            <LoadingState />
          ) : !data?.people?.[0]?.certsConnection?.edges?.length ? (
            <span className="text-font-light">No Certifications</span>
          ) : (
            data?.people?.[0]?.certsConnection?.edges.map((cert, i) => {
              return <UserCertification key={i} certDetails={cert} />;
            })
          )}
        </div>

        <div className="max-w-sm">
          <H2>Add New Certification</H2>
          <Label>
            Certification
            <SearchAndSelectInput
              disabled={loading || certLoading || !filteredCerts?.length}
              search={certSearch}
              setSearch={setCertSearch}
              searchField="name"
              data={filteredCerts}
              setSelected={setSelectedCert}
            />
          </Label>
          <InputErrorCheck value={selectedCert} error={inputError}>
            Required
          </InputErrorCheck>
          <Label>
            Acquired Date
            <Input
              type="date"
              value={acquiredDate}
              onChange={(e) => setAcquiredDate(e.target.value)}
            />
          </Label>
          <InputErrorCheck value={acquiredDate} error={inputError}>
            Required
          </InputErrorCheck>
          <div className="flex flex-row justify-end">
            <Button onClick={handleAddCert} className="mt-6">
              Add Certification
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettingsCertifications;
