import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlignCenterOutlined
} from '@ant-design/icons'
import { TrashIcon } from '@heroicons/react/outline'
import '../../../styles/textEditor.scss'
import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import MenuBar from './TextEditorMenu.component'
import { lowlight } from 'lowlight'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import BubbleMenuExtension from '@tiptap/extension-bubble-menu'
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import { useDropzone } from "react-dropzone"
import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../../../redux/modalSlice'
import ModalPreview from './ModalPreview.component'
import { successToastMid } from '../../../lib/toast'
import { useLocation } from 'react-router'
import toast from "react-hot-toast"
import { Button } from '../../../custom-prebuilt/common.component'
import { useAuth } from '../../../lib/authContext'
import { QueryAllAnnouncements, UpdateAnnouncement } from '../../../graphql/announcements'
import { useMutation, useQuery } from '@apollo/client'
import LoadingState from "../../../custom-prebuilt/preloader/LoadingState.component"

const TextEditor = () => {

  const { userEmail } = useAuth()
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const [iconFile, setIconFile] = useState([])
  const [json, setJson] = useState(null)
  const [saving, setSaving] = useState(null)
  const location = useLocation()
  const announcementId = location.pathname.split("/").at(-2)

  const [updateAnnouncement] = useMutation(UpdateAnnouncement, {
    refetchQueries: () => [{
      query: QueryAllAnnouncements,
    }]
  })
  const queryAnnouncement = useQuery(QueryAllAnnouncements, {
    variables: {
      where: {
        announcementId: announcementId
      }
    },
    fetchPolicy: "network-only"
  })

  const editorContent = queryAnnouncement.data?.announcements[0]?.content

  let timer;

  const editor = useEditor({
    extensions: [
      StarterKit,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      BubbleMenuExtension.configure({
        element: document.querySelector('.menu'),
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      TextAlign.configure({
        alignments: ['left', 'right', 'center'],
        types: ['heading', 'paragraph', 'image'],
      }),
      Placeholder.configure({
        placeholder: "Start writing here...",
        emptyEditorClass: 'is-editor-empty',
      })
      // Dropcursor
    ],
    editorProps: {
      attributes: {
        class: "border-none rounded-md focus:outline-none min-h-[500px] py-6 h-fit w-full",
      },
    },
    editable: false,
    onUpdate: () => {
      const input = document.querySelector('#editor');
      clearTimeout(timer)
      timer = setTimeout(() => {
        setSaving(true)
      }, 250)
      input?.addEventListener('keydown', () => {
        setSaving(false)
      })
    },
  })

  useEffect(() => {
    if (queryAnnouncement.data && editor) {
      if (queryAnnouncement.data.announcements.length) {
        editor?.commands.setContent(
          queryAnnouncement.data?.announcements[0]?.content
            ? JSON.parse(queryAnnouncement.data?.announcements[0]?.content)
            : ""
        )
        successToastMid("Draft retrieved")
        editor?.setEditable(true)
      }
    }
  }, [queryAnnouncement.data, editor])

  useEffect(() => {
    let timer;              // Timer identifier
    const waitTime = 250;   // Wait time in milliseconds 

    const process = async (json) => {
      if (saving == true) {
        const updateObject = {
          content: json && JSON.stringify(json),
        }
        toast.promise(
          updateAnnouncement({ variables: { where: { announcementId: announcementId }, update: updateObject } }).then(() => {
            setSaving(false)
          }).catch(err => console.log(err)),
          {
            loading: "Saving...",
            success: "Saved",
            error: "Could not save"
          }, { position: "top-center" })
      }
    };

    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      const tempOutput = editor?.getJSON()
      process(tempOutput);
    }, waitTime);

    return () => {
      // Clear timer
      clearTimeout(timer);
    }
  }, [saving])

  const addImage = useCallback((resArr) => {
    const url = resArr[0]
    const filePath = resArr[1]
    if (url) {
      editor.chain().focus().setImage({ src: url, alt: filePath }).run()
    }
  }, [editor])

  /**
   * useCallback params: (acceptedFiles)
   */
  const onDrop = useCallback(() => {
    // Do something with the files
  }, [userEmail])

  useEffect(() => {
    iconFile.length && addImage(iconFile[iconFile.length - 1])
  }, [iconFile])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    onDrop,
    maxFiles: 1
  })

  if (!editor) {
    return null
  }

  const handleOutputEditor = () => {
    dispatch(toggleModal({ show: true }))
    setJson(null)
    setTimeout(() => {
      setJson(editor.getJSON())
    }, 1)
  }

  const handlePostAnnouncement = () => {
    const updateObject = {
      draft: false
    }
    toast.promise(
      updateAnnouncement({ variables: { where: { announcementId: announcementId }, update: updateObject} }),
      {
        loading: "Posting...",
        success: "Successfully posted",
        error: "Could not post..."
      }
    )
  }

  return (
    <div className={`w-full md:w-[580px] lg:w-[800px] h-fit mx-auto p-5 ${editorContent && "bg-white shadow rounded-lg"}`}>
      {
        editorContent !== null ?
          <>
            <ModalPreview json={json ? json : null} />
            <MenuBar editor={editor} />
            <div className='max-h-fit'>
              {editor &&
                <BubbleMenu class='sticky' editor={editor} tippyOptions={{ duration: 100, interactive: true, appendTo: 'parent' }}>
                  <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={`${editor.isActive('bold') ? 'tooltip-is-active' : ''} tooltip-btn`}
                  >
                    <BoldOutlined className='h-4 w-4' />
                  </button>
                  <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={`${editor.isActive('italic') ? 'tooltip-is-active' : ''} tooltip-btn`}
                  >
                    <ItalicOutlined className='h-4 w-4' />
                  </button>
                  <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={`${editor.isActive('strike') ? 'tooltip-is-active' : ''} tooltip-btn`}
                  >
                    <StrikethroughOutlined className='h-4 w-4' />
                  </button>
                  <button
                    onClick={() => editor.chain().focus().setTextAlign('left').run()}
                    className={`${editor.isActive({ textAlign: 'left' }) ? 'tooltip-is-active' : ''} tooltip-btn`}
                  >
                    <AlignLeftOutlined className='h-4 w-4' />
                  </button>
                  <button
                    onClick={() => editor.chain().focus().setTextAlign('center').run()}
                    className={`${editor.isActive({ textAlign: 'center' }) ? 'tooltip-is-active' : ''} tooltip-btn`}
                  >
                    <AlignCenterOutlined className='h-4 w-4' />
                  </button>
                  <button
                    onClick={() => editor.chain().focus().setTextAlign('right').run()}
                    className={`${editor.isActive({ textAlign: 'right' }) ? 'tooltip-is-active' : ''} tooltip-btn`}
                  >
                    <AlignRightOutlined className='h-4 w-4' />
                  </button>
                </BubbleMenu>}
              <EditorContent id="editor" className='h-fit' editor={editor} />
              <div className='bg-transparent px-3 py-4 cursor-pointer border-2 border-dashed h-44 flex justify-center items-center' {...getRootProps()}>
                {isDragActive ?
                  <p className='text-gray-600 text-xs'>Drop the image here</p> :
                  <p className='text-gray-400 text-xs'>Drag and drop or click to insert an image</p>}
                <input {...getInputProps()} />
              </div>
              <div className='mt-6 flex flex-row justify-between'>
                <Button className='bg-red-600 hover:bg-red-700'
                  onClick={handleOutputEditor}>
                  <TrashIcon className='h-4 w-4'/>
                </Button>
                <div className="ml-auto flex flex-row space-x-1">
                  <Button onClick={handleOutputEditor}>
                    Preview
                  </Button>
                  <Button onClick={handlePostAnnouncement}>
                    Post
                  </Button>
                </div>
              </div>
            </div>
          </> :
          <LoadingState />
      }
    </div>
  )
}

export default TextEditor;