/**
 * Helper functions for Explore Algorithm
 */
import TreeNode from './TreeNode';

const isLogicalExpression = (c: string): boolean => {
  return c === '&' || c === '|';
};

const isNormalCharacter = (c: string): boolean => {
  return c !== '&' && c !== '|' && c !== '(' && c !== ')' && c !== '\n' && c !== '>' && c !== '^';
};

// Get string in the middle that is a balanced-parentheis string
const getBalancedParenthesis = (input: string): string => {
  let i = 1;
  let output = '';
  let parenthesisState = 1;
  while (i < input.length) {
    if (input[i] === '(') {
      parenthesisState++;
    } else if (input[i] === ')') {
      parenthesisState--;
    }
    if (parenthesisState === 0) {
      break;
    }
    output += input[i];
    i++;
  }

  if (i == input.length && parenthesisState !== 0) {
    throw new Error('Please check the parentheses.');
  }
  return output;
};

/**
 * Partition string into components
 * Each component either be:
 * - a skill
 * - a balanced-parenthesis string
  
  Partition into an array. Then put into TreeNode
 */
export const partitionString = (input: string) => {
  let i = 0;
  let globalLogicalExpression = '';

  const partitioned = [];

  while (i < input.length) {
    const c = input[i];
    // Read string;
    if (c === '>') {
      let skill = '';
      let j = i + 1;
      while (j < input.length) {
        if (input[j] !== ' ') {
          skill += input[j];
        } else {
          i = j;
          break;
        }
        j++;
      }
      // console.log("skill: ",skill);
      partitioned.push(skill);
    }
    // Read the balanced parenthesis string
    else if (c === '(') {
      const subInput = getBalancedParenthesis(input.substring(i, input.length));
      partitioned.push(subInput);
      i += subInput.length + 2;
    }
    // Read logical expression
    else if (isLogicalExpression(c)) {
      if (globalLogicalExpression === '') {
        globalLogicalExpression = c;
      } else if (globalLogicalExpression !== c) {
        throw new Error('Logical expression does not make sense.');
      }
    }
    i++;
  }

  // console.log(globalLogicalExpression);

  return { partitioned, logical: globalLogicalExpression };
};

/**
 * Create a TreeNode to build the GraphQL query variables
 * @param {string} input Input prompt
 * @param {TreeNode} tree Root node
 * @param {string[]} tags List of tags
 */
export const createTree = (input: string, tree: TreeNode, tags: string[]) => {
  const { partitioned, logical } = partitionString(input);
  // console.log(partitioned)
  tree.logical = logical;

  for (const item of partitioned) {
    if (isNormalCharacter(item[0])) {
      // ! Watch this step
      tags.push(`>${item}`);
      const skill = new TreeNode(item);
      tree.children.push(skill);
    } else {
      tags.push(item);
      const child = new TreeNode();
      createTree(item, child, tags);
      tree.children.push(child);
    }
  }
};
