import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { getLocationPrevLevel } from '../../../../../../lib/getLocationPrevLevel';
import ClickNHoldSkill from '../../../../../../custom-prebuilt/ClickNHoldSkill.component';
import Modal from '../../../../../../custom-prebuilt/Modal.component';
import { toggleModal } from '../../../../../../redux/modalSlice';
import AssessmentAdjustConfirmation from '../../skill-assessment/utils/AssessmentAdjustConfirmation.component';
import AssessmentOverview from '../../skill-assessment/utils/AssessmentOverview.component';
import {
  AddProjectUserSkillRatings,
  AddProjectSkillAssessment,
} from '../../../../../../graphql/assessment';
import { useMutation, useQuery } from '@apollo/client';
import { QueryUserProjects } from '../../../../../../graphql/projects';
import { useAuth } from '../../../../../../lib/authContext';
import toast from 'react-hot-toast';
import moment from 'moment';
import SimpleSection from '../../../../../shared/SimpleSection.component';
import { QueryPersonBasics } from '../../../../../../graphql/people';

const AssessmentProjectConfirmation = () => {
  const { userEmail, cognitoID } = useAuth();
  const selectedSkills = useSelector((state) => state.assessment.selectedSkills);
  const show = useSelector((state) => state.modal.show);
  const show1 = useSelector((state) => state.modal.show1);
  const skill = useSelector((state) => state.modal.skill);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectDescription = useSelector((state) => state.assessment.projectDescription);
  const project = useSelector((state) => state.assessment.project);

  const primarySkills = [...selectedSkills].filter((s) => s.rating === 1);
  const secondarySkills = [...selectedSkills].filter((s) => s.rating === 2);
  const passiveSkills = [...selectedSkills].filter((s) => s.rating === 3);

  const queryPersonBasics = useQuery(QueryPersonBasics, {
    variables: {
      where: {
        email: userEmail,
      },
    },
  });

  const [addProjectUserSkillRatings] = useMutation(AddProjectUserSkillRatings, {
    refetchQueries: () => [
      {
        query: QueryUserProjects,
        variables: {
          where: {
            id: project?.id || '',
          },
        },
      },
    ],
  });

  const [addProjectSkillAssessment] = useMutation(AddProjectSkillAssessment);

  const handleSelectSkill = (skill) => {
    dispatch(toggleModal({ show: true, skill: skill }));
  };

  const handleCloseModal = () => {
    dispatch(toggleModal({ show: false, skill: null }));
  };

  const handleCloseModalConfirmation = () => {
    dispatch(toggleModal({ show1: false }));
    navigate(`${getLocationPrevLevel(location)}/introduction`);
  };

  const handleSubmitAssessment = () => {
    const input = selectedSkills.map((s) => {
      return { skillName: s.node.name, ratingValue: s.rating };
    });
    const assessmentInput = {
      projectId: project?.id || '',
      dateCompleted: moment().format('MM/DD/YYYY'),
      completedBy: cognitoID,
      projectDescription: projectDescription ?? '',
    };

    toast.promise(
      addProjectSkillAssessment({
        variables: {
          input: assessmentInput,
        },
      })
        .then(() => {
          addProjectUserSkillRatings({
            variables: {
              id: project?.id || '',
              userId: cognitoID,
              input: input,
            },
          });
        })
        .then(() => {
          dispatch(toggleModal({ show1: true }));
        }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
    );
  };

  return (
    <div className="h-full w-full xl:pl-4">
      <Modal
        open={show}
        secondaryButtonLabel="Back"
        onSecondaryButtonClick={handleCloseModal}
        title={`${skill?.node.name || 'Skill'} level`}
        content={<AssessmentAdjustConfirmation skill={skill} type="project" />}
      />

      <Modal
        open={show1}
        primaryButtonLabel="OK"
        onPrimaryButtonClick={handleCloseModalConfirmation}
        onSecondaryButtonClick={handleCloseModalConfirmation}
        content={<AssessmentOverview />}
      />

      <div className="flex flex-col xl:flex-row w-full space-x-0 xl:space-x-5 h-full">
        <div className="flex flex-col space-y-5 flex-1">
          <SimpleSection>
            <div className="flex flex-row justify-between">
              <p className="text-xs mb-4 font-semibold">Confirm skills</p>
            </div>
            <p className="text-xs mb-2">
              Please confirm the skills below match your current skillset
            </p>
            <p className="text-xs mb-2">
              Tip: You can click on a skill to adjust its rating or remove it
            </p>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer mr-2"
              onClick={() => {
                navigate(`${getLocationPrevLevel(location)}/rate`);
              }}
            >
              Back
            </button>
            <button
              className="px-3 py-2 bg-gray-800 text-white text-xs border-none rounded-md hover:bg-gray-700 mt-4
              transition duration-300 cursor-pointer"
              onClick={() => {
                handleSubmitAssessment();
              }}
            >
              Submit
            </button>
          </SimpleSection>

          <SimpleSection>
            <p className="text-xs font-semibold mb-4">Assessment Info</p>
            <div className="flex flex-col space-y-2">
              <p className="text-xs font-medium">
                Completed by:{' '}
                <span className="text-font-dark">{queryPersonBasics.data?.people[0]?.name}</span>
              </p>
              <p className="text-xs font-medium">
                Date completed:{' '}
                <span className="text-font-dark">{moment().format('MM/DD/YYYY')}</span>
              </p>
              <p className="text-xs font-medium">
                Description: <span className="text-font-dark">{projectDescription}</span>
              </p>
            </div>
          </SimpleSection>

          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
            <SimpleSection className="col-span-1">
              <p className="text-xs font-semibold mb-4">Primary Skills</p>
              <div className="flex flex-row flex-wrap">
                {primarySkills.map((skill, index) => (
                  <div
                    key={index}
                    className="w-14"
                    onClick={() => {
                      handleSelectSkill(skill);
                    }}
                  >
                    <ClickNHoldSkill skill={skill.node} disable />
                  </div>
                ))}
              </div>
            </SimpleSection>

            <SimpleSection className="col-span-1">
              <p className="text-xs font-semibold mb-4">Secondary Skills</p>
              <div className="flex flex-row flex-wrap">
                {secondarySkills.map((skill, index) => (
                  <div
                    key={index}
                    className="w-14"
                    onClick={() => {
                      handleSelectSkill(skill);
                    }}
                  >
                    <ClickNHoldSkill skill={skill.node} disable />
                  </div>
                ))}
              </div>
            </SimpleSection>

            <SimpleSection className="col-span-1">
              <p className="text-xs font-semibold mb-4">Passive Skills</p>
              <div className="flex flex-row flex-wrap">
                {passiveSkills.map((skill, index) => (
                  <div
                    key={index}
                    className="w-14"
                    onClick={() => {
                      handleSelectSkill(skill);
                    }}
                  >
                    <ClickNHoldSkill skill={skill.node} disable />
                  </div>
                ))}
              </div>
            </SimpleSection>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentProjectConfirmation;
