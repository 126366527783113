import React, { useContext, useState, useEffect, PropsWithChildren } from 'react';
import { Auth } from 'aws-amplify';
import Login from '../pages/Login';
import { useLocation } from 'react-router';

export type AuthContextType = {
  authenticated: boolean;
  cognitoUser: any;
  loading: boolean;
  groups: string[];
  userEmail?: string;
  cognitoID?: string;
  logout: () => void;
};

// creates a react context for authorization functionality
export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

// get Cognito auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provides authorization logic and functions for the app
export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [authenticated, setAuthenticated] = useState(false); // boolean authentication state
  const [cognitoUser, setUser] = useState<any>(); // Cognito User state, null if not an authenticated user
  const [loading, setLoading] = useState(true); // track auth loading for async functions and conditional rendering state
  const [userCreds, setUserCreds] = useState<any>(null); // Get user email from auth object to make initial query
  const [groups, setGroups] = useState([]); // used by the profile generator to determine if a user is a admin
  const location = useLocation();

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((currentUser) => {
        setUser(currentUser);
        setAuthenticated(true);
        setLoading(false);
        setGroups(currentUser?.signInUserSession?.accessToken?.payload['cognito:groups']);
        setUserCreds(currentUser?.signInUserSession?.idToken?.payload);
      })
      .catch((error) => {
        setLoading(false);
        setAuthenticated(false);
        setUser(null);
        setUserCreds(null);
        setGroups([]);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    Auth.signOut()
      .then(() => {
        setAuthenticated(false);
        setUser(null);
        setUserCreds(null);
        setGroups([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // auth objects and functions to be provided across the application for authentication and authorization
  const authValue: AuthContextType = {
    authenticated,
    cognitoUser,
    loading,
    logout,
    userEmail: userCreds?.email,
    cognitoID: userCreds?.['custom:cognitoID'],
    groups,
  };

  return (
    <AuthContext.Provider value={authValue}>
      {loading ? (
        <></>
      ) : authenticated ? (
        children
      ) : location.pathname.startsWith('/invitation') ? (
        children
      ) : (
        <Login />
      )}
    </AuthContext.Provider>
  );
};
