import { TrashIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import autoAnimate from '@formkit/auto-animate';
import LoadingState from '../preloader/LoadingState.component';
import CertDetails, { CertDetailsDynamicType } from './utils/CertDetails.component';
import Modal from '../Modal.component';
import AddPersonWitheCertification from '../../components/settings/AddCertificationHolder';
import ImageInput from '../ImageInput';
import { errorToastMid } from '../../lib/toast';
import { toast } from 'react-hot-toast';
import { resizeFile } from './SnapshotSkill.component';
import { Storage } from 'aws-amplify';
import { useMutation } from '@apollo/client';
import { QueryAllCert, UpdateCert } from '../../graphql/certifications';
import { getSlug } from '../../components/admin/certs/AddNewCert.component';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../redux/modalSlice';
import { RootState } from '../../redux/store';
import { DeleteCert } from '../../graphql/skills';

export type SnapshotCertProps = {
  certDetails: CertDetailsDynamicType;
  editable: boolean;
};

const SnapshotCert = ({ certDetails, editable }: SnapshotCertProps) => {
  const parentRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    parentRef.current && autoAnimate(parentRef.current);
  }, [parentRef]);

  const handleCloseAddPersonWithCertModal = () => {
    setShowAddPersonWithCertModal(false);
  };

  const [showPersonWithCertModal, setShowAddPersonWithCertModal] = useState(false);
  const show = useSelector((state: RootState) => state.modal.show1);
  const [certImage, setCertImage] = useState(certDetails?.logo);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateCertInfo] = useMutation(UpdateCert, {
    refetchQueries: [{ query: QueryAllCert }],
  });
  const [deleteSkill] = useMutation(DeleteCert, {
    refetchQueries: [{ query: QueryAllCert }],
  });

  const newLogo = certDetails?.logo;
  useEffect(() => {
    setIsLoading(true);
    setCertImage(newLogo);
    setTimeout(() => {
      setIsLoading(false);
    });
  }, [newLogo]);

  const handleDeleteCert = () => {
    toast.promise(
      deleteSkill({
        variables: {
          where: {
            name: certDetails?.name,
          },
        },
      }).then(() => {
        dispatch(toggleModal({ show1: false, skill: null }));
      }),
      {
        loading: `Deleting ${certDetails?.name}`,
        success: `Deleted ${certDetails?.name}`,
        error: `Could not delete ${certDetails?.name}`,
      },
    );
  };

  const onUpdateImage = async (croppedImg: Blob) => {
    try {
      const resizedImage: any = await resizeFile(croppedImg, 200, 200, 'png', 100);
      const slug = getSlug(certDetails?.name);

      const uploadPromise = Storage.put(`${slug}_${resizedImage?.name}`, resizedImage, {
        level: 'public',
        bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
      }).then(() => {
        return updateCertInfo({
          variables: {
            where: { name: certDetails?.name },
            update: {
              logo: `/${slug}_${resizedImage?.name}`,
            },
          },
        });
      });
      toast.promise(uploadPromise, {
        success: 'Image has been updated!',
        error: 'An error occurred!',
        loading: 'Loading...',
      });
    } catch (e) {
      console.log('Error uploading file: ', e);
      errorToastMid('An error occurred when uploading the file');
    }
  };

  return (
    <>
      <Modal
        open={show}
        title={`Delete ${certDetails?.name}`}
        content={
          <div>
            <p className="text-xs text-medium">This action cannot be undone. Are you sure?</p>
          </div>
        }
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => dispatch(toggleModal({ show1: false, skill: null }))}
        destructiveButtonLabel="Delete"
        onDestructiveButtonClick={handleDeleteCert}
      />
      <div className="flex h-full rounded-md flex-col overflow-y-scroll bg-bg_lightgray">
        {/* Main */}
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <div className="divide-y divide-gray-200">
              <div className="pb-4 bg-bg_light px-4">
                <div className="flow-root px-4 sm:flex sm:items-end sm:px-6 bg-bg_light pb-8 rounded-lg pt-6">
                  <div>
                    <div className="-m-1 flex">
                      <div className="inline-flex overflow-hidden">
                        <ImageInput
                          image={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${certImage}`}
                          id={certImage}
                          setImage={setCertImage}
                          enableCropper
                          cropShowGrid
                          afterCrop={onUpdateImage}
                          cropShape={'rect'}
                          cropAspect={1}
                          changeButtonText="Change Photo"
                          displayImageClasses="h-20 w-20 flex-shrink-0 sm:h-24 sm:w-24"
                          contentWrapperClasses="flex flex-col items-center space-y-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sm:ml-6 sm:flex-1">
                    <div>
                      <div className="flex items-center">
                        <h3 className="text-base font-bold text-gray-900 mt-3 sm:mt-0">
                          {certDetails && certDetails.name}
                        </h3>
                      </div>
                    </div>
                    <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                      <button
                        type="button"
                        className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md 
                          border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium text-white 
                          shadow-sm hover:bg-gray-700 sm:flex-1 transition duration-150"
                        onClick={() => {
                          setShowAddPersonWithCertModal(true);
                        }}
                      >
                        Add Person with Certification
                      </button>
                      <a
                        href={
                          (certDetails?.websiteLink?.includes('//') ? '' : '//') +
                          certDetails?.websiteLink
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="inline-flex w-full flex-1 items-center justify-center rounded-md border
                          border-gray-300 bg-bg_light px-4 py-2 text-xs font-medium text-gray-700 shadow-sm 
                          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150"
                        >
                          Website
                        </button>
                      </a>
                      <button
                        onClick={() => {
                          dispatch(
                            toggleModal({
                              show1: true,
                              skill: certDetails?.name,
                            }),
                          );
                        }}
                        className="inline-flex items-center justify-center rounded-md border ml-3 sm:ml-0
                          border-red-300 bg-red-600 px-4 py-2 text-xs font-medium text-white shadow-sm 
                          hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-150"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {!certDetails ? (
                <LoadingState />
              ) : (
                <>
                  <CertDetails certDetails={certDetails} editable={editable} />
                </>
              )}
            </div>
            <Modal
              open={showPersonWithCertModal}
              content={
                <AddPersonWitheCertification
                  certDetails={certDetails}
                  onClose={handleCloseAddPersonWithCertModal}
                />
              }
              title="Link certification to a user"
              onSecondaryButtonClick={handleCloseAddPersonWithCertModal}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SnapshotCert;
