import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type LayerState = {
  layers: any[];
  compId: string;
};

const initState: LayerState = {
  layers: [],
  compId: '',
};

export const layerSlice = createSlice({
  name: 'layer',
  initialState: initState,
  reducers: {
    toggleLayers: (state, action: PayloadAction<Partial<LayerState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleLayers } = layerSlice.actions;

export default layerSlice.reducer;
