import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { updateState } from '../state/profileGeneratorSlice';
import { pathToArray } from '../state/ProfileGenerationContextHelpers';
import ArrayInputs from './ProfileGeneratorProfileArrayInput';

const templateItem = {
  skillName: '',
  rating: -1,
  imageLink: '/logo108.png',
};

const SkillInputs = (props) => {
  const dispatch = useDispatch();
  const pathOfArray = useMemo(
    () => `.${pathToArray(props.path).slice(0, -1).join('.')}`,
    [props.path],
  );

  const onChange = (newValue, index) => {
    dispatch(
      updateState({
        path: pathOfArray,
        index,
        newValue: {
          skillName: newValue,
          rating: -1,
          imageLink: '/logo108.png',
        },
      }),
    );
  };

  return <ArrayInputs {...props} onChange={onChange} templateItem={templateItem} />;
};

export default SkillInputs;
