import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import PersonCard from '../../shared/PersonCard.component';
import { PlusCircleIcon } from '@heroicons/react/outline';
import SearchAndSelectMenu from '../../../custom-prebuilt/SearchAndSelectMenu.component';
import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import SnapshotProfile from '../../../custom-prebuilt/snapshot/SnapshotProfile.component';
import { QueryPersonBasics } from '../../../graphql/people';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import HelpInformation from '../../../custom-prebuilt/HelpInformation.component';
import { classNames } from '../../../lib/classNames';
import { useState } from 'react';
import UserProfile from '../ProfileGeneratorUserProfile';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../lib/authContext';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import ReactTooltip from 'react-tooltip';
import { useProfileGeneratorInfo } from '../state/ProfileGeneratorContext';
import {
  setSelectedPerson,
  setSelectedProfile,
  createNewProfile,
  setProfileGeneratorState,
} from '../state/profileGeneratorSlice';
import { toggleModal } from '../../../redux/modalSlice';
import Modal from '../../../custom-prebuilt/Modal.component';
import { makeCopyOfObject } from '../state/ProfileGenerationContextHelpers';

const ProfileGeneratorSelect = () => {
  const { userEmail, groups } = useAuth();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const isAdmin = useSelector((state) => state.org.isCurrentAdmin) && groups.includes('admin');
  const selectedPerson = useSelector((state) => state.profileGenerator.selectedPerson);
  const userProfiles = useSelector((state) => state.profileGenerator.userProfiles);
  const profileIDToDelete = useSelector((state) => state.profileGenerator.profileIDToDelete);
  const profileOwnerIDTODelete = useSelector(
    (state) => state.profileGenerator.profileOwnerIDTODelete,
  );
  const { profileComponentsLoading, deleteProfile, downloadProfile, duplicateProfile } =
    useProfileGeneratorInfo();
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const { data: peopleData } = useQuery(QueryPersonBasics, {
    variables: { where: { orgUnits: { organization: { uid: defaultOrgUid } } } },
    skip: !isAdmin,
  });
  // SlideOver
  const show = useSelector((state) => state.slideOver.show1);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const modalShow = useSelector((state) => state.modal.show);
  const dispatch = useDispatch();
  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show1: false }));
    setTimeout(() => {
      dispatch(toggleSlideOver({ profileTarget: null }));
    }, 500);
  };

  const clickSelectedPerson = (option) => {
    dispatch(toggleSlideOver({ show1: true, profileTarget: option }));
  };

  const goToCreateProfile = () => {
    dispatch(createNewProfile());
    navigate('/profiles/template');
  };

  const goToEditProfile = (profile) => {
    dispatch(setSelectedProfile(profile));
    navigate('/profiles/generate');
  };

  const handleOpenModal = () => {
    dispatch(toggleModal({ show: true }));
  };

  const handleCloseModal = () => {
    dispatch(toggleModal({ show: false }));
  };

  const handleConfirmModal = async () => {
    handleCloseModal();
    dispatch(setProfileGeneratorState({ userProfilesLength: userProfilesComponent.length - 1 }));
    await deleteProfile(profileOwnerIDTODelete, profileIDToDelete);
  };

  /**
   * Responsible for displaying all of a users profiles
   */
  const userProfilesComponent = () => {
    if (profileComponentsLoading) return <LoadingState />;
    const copyOfUserProfiles = makeCopyOfObject(userProfiles);
    copyOfUserProfiles.sort((profileA, profileB) => {
      const lastChangeA = profileA.changes.at(-1).time;
      const lastChangeB = profileB.changes.at(-1).time;
      return lastChangeB - lastChangeA;
    });

    const userProfilesComponent = copyOfUserProfiles.map((profile) => {
      const onDelete = () => {
        dispatch(
          setProfileGeneratorState({
            profileIDToDelete: profile.profileID,
            profileOwnerIDTODelete: profile.ownerID,
          }),
        );
        handleOpenModal();
      };
      const onEdit = () => goToEditProfile(profile);
      const onDownload = async () => await downloadProfile(profile.ownerID, profile.profileID);
      const onDuplicate = async () =>
        await duplicateProfile(
          profile.profileData,
          profile.templateID,
          `${profile.fileName} (Copy)`,
        );
      return (
        <UserProfile
          key={profile.profileID}
          profile={profile}
          onDelete={onDelete}
          onEdit={onEdit}
          onDownload={onDownload}
          onDuplicate={onDuplicate}
        />
      );
    });
    if (!isSearching)
      return (
        <div className="grid grid-cols-[max-content_1fr] gap-6 pt-3">
          <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="profileInfo" />
          <button
            onClick={goToCreateProfile}
            className="flex flex-col bg-white rounded-md shadow h-min w-[245px] p-4 group hover:bg-gray-200 items-stretch text-left"
          >
            <div className="flex justify-between">
              <PlusCircleIcon className="h-6 w-6 text-font-light group-hover:text-font-dark mt-3" />
              {/* No arrow up right?!? */}
              <svg
                className="h-6 w-6 text-font-light group-hover:text-font-dark"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            </div>
            <p className="text-sm text-font-dark pt-2">
              <b>Create New Profile</b>
            </p>
            <p className="text-xs text-font-dark pb-2">
              Select a template and build a new profile from scratch
            </p>
          </button>
          <div className="overflow-y-scroll flex flex-wrap content-baseline h-full gap-6">
            {userProfilesComponent}
          </div>
        </div>
      );
    return null;
  };

  const updateSelectedPerson = (newPerson) => {
    dispatch(setSelectedPerson(newPerson));
  };
  const PersonCardComponent = ({ onSelect, option }) => {
    return <PersonCard personBasics={option} onSelect={onSelect} className={personCardClasses} />;
  };
  const PersonCardComponentSelected = ({ option }) => {
    const disableHover = !isAdmin || selectedPerson.email === userEmail;
    const onSelect = () => {
      if (!disableHover) clickSelectedPerson(option);
    };
    return (
      <PersonCard
        personBasics={option}
        onSelect={onSelect}
        disableHover={!isAdmin || selectedPerson.email === userEmail}
        className={[personCardClasses, 'w-auto'].join(' ')}
      />
    );
  };

  const defaultUserHelpText = [
    'Create a PDF profile for yourself using information within your Connect account.',
    'If you or a profile admin has already created a profile it will appear below allowing you to edit or delete it.',
  ];
  const adminSpecificHelpText = [
    'Generate PDF profiles for yourself or other people based on Connect information.',
    'Select or search for a person from the display below. If profiles have already been created for that person you will have to option to edit them.',
  ];
  const personCardClasses = 'mb-2';
  const modalContet = 'Are you sure you want to delete this profile?';

  return (
    <div className="grid grid-rows-[min-content_min-content_1fr] h-full">
      <Modal
        open={modalShow}
        title="Delete Profile"
        content={modalContet}
        primaryButtonLabel="Cancel"
        onPrimaryButtonClick={handleCloseModal}
        destructiveButtonLabel="Delete"
        onDestructiveButtonClick={handleConfirmModal}
      />
      <HelpInformation title={'Profile Generator'} className="mb-2">
        {isAdmin
          ? adminSpecificHelpText.map((help, index) => (
              <p key={`admin-help-${index}`} className="text-xs">
                {help}
              </p>
            ))
          : defaultUserHelpText.map((help, index) => (
              <p key={`normal-help-${index}`} className="text-xs">
                {help}
              </p>
            ))}
      </HelpInformation>

      {isAdmin ? (
        <SearchAndSelectMenu
          options={peopleData?.people ?? []}
          title={'People'}
          DisplayComponent={PersonCardComponent}
          SelectedComponent={PersonCardComponentSelected}
          searchParam={'name'}
          selected={selectedPerson}
          setSelected={updateSelectedPerson}
          showByDefault
          isSearching={isSearching}
          setIsSearching={setIsSearching}
        />
      ) : (
        <PersonCard
          personBasics={selectedPerson}
          onSelect={clickSelectedPerson}
          disableHover={true}
          className={classNames(personCardClasses, 'h-min mt-2')}
        />
      )}
      {userProfilesComponent()}
      <SlideOver
        show={show}
        title="Person Profile"
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        largeSize={true}
        bgWhite={false}
        content={<SnapshotProfile target={target} bgWhite={true} coverPic={true} />}
      />
    </div>
  );
};
export default ProfileGeneratorSelect;
