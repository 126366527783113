import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BugReportState = {
  show: boolean;
};

const initialState: BugReportState = {
  show: false,
};

export const bugReportSlice = createSlice({
  name: 'bugReport',
  initialState,
  reducers: {
    toggleBugReport: (state, action: PayloadAction<Partial<BugReportState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { toggleBugReport } = bugReportSlice.actions;

export default bugReportSlice.reducer;
