import { useEffect, useRef, useState } from 'react';

type ExpandableTextProps = {
  text: string;
  className?: string;
};

const ExpandableText = ({ text, className }: ExpandableTextProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [expand, setExpand] = useState(false);
  const [overflowing, setOverflowing] = useState(false);

  const isOverflowing = (elem: HTMLSpanElement | null) => {
    if (elem) return elem.offsetHeight < elem.scrollHeight || elem.offsetWidth < elem.scrollWidth;
    return false;
  };

  useEffect(() => {
    setOverflowing(isOverflowing(textRef?.current));
  }, []);

  return (
    <div className={`flex w-full whitespace-normal ${className ?? ''}`}>
      <span
        ref={textRef}
        className={`text-xs text-ellipsis ${expand ? 'line-clamp-none' : 'line-clamp-1'}`}
      >
        {text}
      </span>
      {overflowing && (
        <span
          className="text-xs ml-1 text-blue-500 underline cursor-pointer hover:text-blue-400"
          onClick={() => setExpand(!expand)}
        >
          {expand ? 'less' : 'more'}
        </span>
      )}
    </div>
  );
};

export default ExpandableText;
