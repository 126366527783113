import { createSlice } from '@reduxjs/toolkit';
import { createProfileData, pathToArray } from './ProfileGenerationContextHelpers';
import { toTitle } from './ProfileGenerationContextHelpers';
import {
  defaultGenerateChangeMessage,
  defaultReGenerationMessage,
} from './ProfileGeneratorContext';

const defaultSelectedField = {
  path: '',
  index: -1,
  styleSelected: false,
  relevantFields: [],
  type: '',
};

const profileGeneratorInitState = {
  selectedPerson: undefined,
  profileIDToDelete: undefined,
  profileOwnerIDTODelete: undefined,
  userProfiles: [],
  editorMode: 'editor',
  clipBoard: '',
  relevantFields: [],
  profileFieldList: [],
  userProfilesLength: -1,
  profileStatus: 'Not Generated',
  selectedField: defaultSelectedField,
  assets: [],
  changes: [],
  fileName: '',
};

const getValueInternal = (profileData, path) => {
  let currentProfileData = { ...profileData };
  const pathArray = pathToArray(path);
  for (let pathItem of pathArray) {
    currentProfileData = currentProfileData[pathItem];
  }

  return currentProfileData;
};

const profileGenerator = createSlice({
  name: 'slice1',
  initialState: profileGeneratorInitState,
  reducers: {
    setProfileGeneratorState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    selectField: (state, action) => {
      const { path, index, styleSelected, relevantFields, type } = action.payload;
      state.selectedField = {
        path,
        index: index ?? -1,
        styleSelected: styleSelected ?? false,
        relevantFields: relevantFields ?? [],
        type,
      };
    },
    deselectField: (state) => {
      state.selectedField = {
        ...defaultSelectedField,
        relevantFields: state.relevantFields,
        type: state.type,
      };
    },
    setSelectedPerson: (state, action) => {
      state.selectedPerson = action.payload;
      state.userProfiles = [];
    },
    createNewProfile: (state) => {
      profileGenerator.caseReducers.initializeProfileData(state);
      state.fileName = '';
      state.selectedProfile = null;
      state.changes = [];
      state.profileStatus = 'Not Generated';
      state.editorMode = 'editor';
      state.changeMessage = defaultGenerateChangeMessage;
      state.selectedField = defaultSelectedField;
    },
    setSelectedProfile: (state, action) => {
      const newProfileData = JSON.parse(JSON.stringify(action.payload.profileData));
      state.fileName = action.payload.fileName;
      state.fileNameOriginal = action.payload.fileName;
      state.profileDataOriginal = JSON.parse(JSON.stringify(action.payload.profileData));
      state.profileData = newProfileData;
      state.selectedProfile = action.payload;
      state.changes = action.payload.changes ?? [];
      state.selectedTemplate = action.payload.template;
      state.profileID = action.payload.profileID;
      state.profileStatus = toTitle(action.payload.profileStatus);
      state.editorMode = 'editor';
      state.changeMessage = defaultReGenerationMessage;
      state.selectedField = defaultSelectedField;
    },
    addTemplatesToProfileData: (state) => {
      const profiles = state.userProfiles.profiles.map((profile) => {
        const template = state.templates.find(
          (template) => template.templateID === profile.templateID,
        );
        return {
          ...profile,
          template,
        };
      });
      state.userProfiles = profiles;
    },
    initializeProfileData: (state) => {
      const newProf = createProfileData(state.connectData, state.selectedTemplate.templateConfig);
      state.profileData = newProf;
      state.profileDataOriginal = JSON.parse(JSON.stringify(newProf));
    },
    // Profile Data Operations
    updateState: (state, action) => {
      const { path, newValue, index, styleName } = action.payload;
      const isArray = typeof index !== 'undefined' && index !== -1;
      const newProfileData = { ...state.profileData };
      let currentProfileData = newProfileData;
      const pathArray = pathToArray(path);
      let updateKey = pathArray.pop();
      for (let pathItem of pathArray) {
        currentProfileData = currentProfileData[pathItem];
      }
      if (typeof styleName !== 'undefined') {
        let currentData;
        updateKey = `${updateKey}Styles`;
        if (isArray) currentData = currentProfileData[index][updateKey];
        else currentData = currentProfileData[updateKey];

        const htmlStyleName = styleName.replace(/([A-Z])/g, '-$1').toLowerCase();
        const styleArray = currentData.split(';').filter((style) => style !== '');
        const styleString = styleArray.find((style) => style.includes(htmlStyleName));
        const newStyleString = `${htmlStyleName}:${newValue}`;
        const newStyleStrings = currentData.replace(styleString, newStyleString);
        if (isArray) currentProfileData[index][updateKey] = newStyleStrings;
        else currentProfileData[updateKey] = newStyleStrings;
        return;
      }
      if (pathArray[0] === 'assets') {
        const newAssets = { ...state.assets };
        newAssets[updateKey] = newValue;
        state.assets = newAssets;
        currentProfileData[updateKey] = URL.createObjectURL(newValue);
      } else if (isArray) {
        if (
          typeof currentProfileData === 'object' &&
          !Array.isArray(currentProfileData) &&
          typeof newValue === 'object'
        ) {
          const newValueWithStyle = { ...newValue };
          const curValue = currentProfileData[updateKey][index];
          for (const [key, value] of Object.entries(curValue)) {
            if (key.includes('Styles')) newValueWithStyle[key] = value;
          }
          currentProfileData[updateKey][index] = newValueWithStyle;
        } else currentProfileData[index][updateKey] = newValue;
      } else currentProfileData[updateKey] = newValue;
      state.profileData = newProfileData;
    },
    addToArray: (state, action) => {
      const { pathOfArray, templateItem } = action.payload;
      let newProfileData = { ...state.profileData };
      let currentProfileData = newProfileData;
      const pathArray = pathToArray(pathOfArray);
      for (let pathItem of pathArray) {
        currentProfileData = currentProfileData[pathItem];
      }
      currentProfileData.push(templateItem);
      state.profileData = newProfileData;
    },
    removeFromArray: (state, action) => {
      const { pathOfArray, index } = action.payload;
      const newProfileData = { ...state.profileData };
      let currentProfileData = newProfileData;
      const pathArray = pathToArray(pathOfArray);
      const updateKey = pathArray.pop();
      for (let pathItem of pathArray) {
        currentProfileData = currentProfileData[pathItem];
      }
      currentProfileData[updateKey] = currentProfileData[updateKey].filter(
        (_, curIndex) => curIndex !== index,
      );
      state.profileData = newProfileData;
    },
    rearrangeArray: (state, action) => {
      const { pathToArrayString, index, destination } = action.payload;
      const array = [...getValueInternal(state.profileData, pathToArrayString)];
      if (
        (index === 0 && destination === 0) ||
        (index === array.length - 1 && destination === array.length && array.length !== 2) ||
        index === destination
      ) {
        return;
      }
      const valueToMove = array[index];
      const newArray = [];
      array.forEach((item, curIndex) => {
        if (curIndex !== index) {
          if (curIndex === destination) {
            if (index > destination) newArray.push(valueToMove, item);
            else newArray.push(item, valueToMove);
          } else newArray.push(item);
        }
      });

      const newProfileData = { ...state.profileData };
      let currentProfileData = newProfileData;
      const pathArray = pathToArray(pathToArrayString);
      const updateKey = pathArray.pop();
      for (let pathItem of pathArray) {
        currentProfileData = currentProfileData[pathItem];
      }
      currentProfileData[updateKey] = newArray;
      state.profileData = newProfileData;
    },
    completePoll: (state, action) => {
      const profile = action.payload;
      state.profileStatus = toTitle(profile.profileStatus);
      state.fetchNumber += 1;
      state.changes = profile.changes;
      state.changeMessage = defaultReGenerationMessage;
      state.fileNameOriginal = state.fileName;
      state.profileDataOriginal = JSON.parse(JSON.stringify(state.profileData));
    },
    triggerPolling: (state, action) => {
      const profile = action.payload;
      state.profileID = profile.profileID;
      state.fetchNumber = 0;
      state.profileStatus = toTitle(profile.profileStatus);
    },
  },
});

export const {
  removeFromArray,
  addToArray,
  getValue,
  updateState,
  rearrangeArray,
  setProfileGeneratorState,
  setSelectedPerson,
  triggerPolling,
  completePoll,
  initializeProfileData,
  createNewProfile,
  setSelectedProfile,
  selectField,
  deselectField,
} = profileGenerator.actions;

const profileGeneratorSlice = profileGenerator.reducer;

export default profileGeneratorSlice;
