import { twMerge } from 'tailwind-merge';

/**
 * @deprecated use {@link mergeClasses} instead
 *
 * Removes undefined and null values from input classes
 */
export function classNames(...classes: (string | null | undefined)[]) {
  const filteredClasses = classes.filter(Boolean).join(' ');

  if (process.env.NODE_ENV === 'development') {
    const mergedClasses = twMerge(classes);
    if (mergedClasses !== filteredClasses) {
      /*console.error(
        'mergedClasses !== filteredClasses. Confirm desired classes and migrate from classNames to mergeClasses function. \n' +
          `mergedClasses = ${mergedClasses}\n` +
          `filteredClasses = ${filteredClasses}`,
      );*/
    }
  }

  return filteredClasses;
}

export function mergeClasses(...classes: (string | null | undefined)[]) {
  return twMerge(classes);
}
