import { ActionButton } from '../../components/shared/Button.component';
import Card from '../../components/shared/Card.component';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { ComponentProps, FunctionComponent } from 'react';

export type SimpleActionCardType = {
  title: string;
  description?: string;
  btnPrompt?: string;
  action?: () => void;
  icon?: FunctionComponent;
};

const SimpleActionCard = ({
  title,
  description,
  btnPrompt,
  action,
  icon,
}: SimpleActionCardType) => {
  // const item = { icon };

  const handleAction = () => {
    if (action) action();
  };

  /**
   * Render icon component from props
   * @param IconComponent
   * @param props
   * @returns
   */
  const renderIcon = (IconComponent: FunctionComponent, props: ComponentProps<'svg'>) => {
    return <IconComponent {...props} />;
  };

  return (
    <Card title={title} _titleClassNameOverride="p-5 pb-0">
      <div
        className={`w-full mt-2 sm:flex sm:items-start ${
          description ? 'sm:justify-between' : 'justify-end'
        }`}
      >
        {description && (
          <div className="max-w-xl text-xs text-gray-500">
            <p>{description}</p>
          </div>
        )}
        <div
          className={`mt-4 sm:mt-0 ${
            description && 'sm:ml-6'
          } sm:flex sm:flex-shrink-0 sm:items-center`}
        >
          <ActionButton
            onClick={handleAction}
            buttonText={
              btnPrompt ||
              (icon ? (
                renderIcon(icon, {
                  className: 'h-3 w-3',
                })
              ) : (
                <ArrowRightIcon className="h-3 w-3" />
              ))
            }
          ></ActionButton>
        </div>
      </div>
    </Card>
  );
};

export default SimpleActionCard;
