import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { Button, H2 } from "../../../../custom-prebuilt/common.component";
import LoadingState from "../../../../custom-prebuilt/preloader/LoadingState.component";
import { DeleteEmptyOrgUnit, QueryCompany, RemoveOrgUnit } from "../../../../graphql/orgs";
import { errorToastMid } from "../../../../lib/toast";
import { api_adminDisableUser, checkApiError } from "../../people/utils/fetchResp";

const DeleteOrgUnit = ({ show, setShow, id, setId, orgList }) => {
  const { data, loading, error } = useQuery(QueryCompany, {
    variables: {
      where: {
        orgUnit_id: id,
      },
      membersConnectionWhere2: {
        node: {
          active: true,
        },
      },
      sort: [
        {
          node: {
            name: "ASC",
          },
        },
      ],
    },
  });

  const [removeOrgUnit] = useMutation(RemoveOrgUnit, {
    refetchQueries: ["QueryAllOrgUnits"],
  });
  const [deleteEmptyOrgUnit] = useMutation(DeleteEmptyOrgUnit, {
    refetchQueries: ["QueryAllOrgUnits"],
  });

  if (error) {
    console.error(error);
  }

  const [inputFields, setInputFields] = useState([]);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    const memberCount = data?.orgUnits?.[0]?.memberCount;
    if (memberCount) {
      let initialInput = new Array(memberCount);
      for (let i = 0; i < memberCount; i++) {
        initialInput[i] = {email: data?.orgUnits?.[0]?.membersConnection?.edges?.[i]?.node?.email, transfer: "", inactivate: false};
      }
      setInputFields(initialInput);
    }
  }, [data]);

  let otherOrgs = [];
  if (orgList) {
    otherOrgs = orgList.filter((org) => org.orgUnit_id !== id);
  }

  const validateForm = () => {
    for (const element of inputFields) {
      if (
        (!element.transfer && !element.inactivate) ||
        (element.transfer && element.inactivate)
      ) {
        setFormError(true);
        return false;
      }
    }
    return true;
  };

  const handleOrgTransfer = (e, index) => {
    let data = [...inputFields];
    data[index].transfer = e.target.value;
    setInputFields(data);
    setFormError(false);
  };

  const handleDeletion = async () => {
    if (validateForm()) {
      let response;
      if(inputFields.length){
        response = removeOrgUnit({
          variables: {
            input: inputFields,
            orgId: id,
          },
        });
      } else {
        response = deleteEmptyOrgUnit({
          variables: {
            where: {
              orgUnit_id: id,
            },
          },
        });
      }
      toast.promise(response, {
        loading: "Deleting...",
        success: "Deleted OrgUnit!",
        error: "An error occurred!",
      });
      for (const element of inputFields) {
        if (element.inactivate) {
          //disable user in Cognito user pool
          try {
            Auth.currentAuthenticatedUser().then((user) => {
              user.getSession((err, session) => {
                if (err) {
                  throw new Error(err);
                }
                const sessionToken =
                  session.getAccessToken().jwtToken;

                api_adminDisableUser(
                  sessionToken,
                  element.email
                ).then((data) => checkApiError(data));
              });
            });
          } catch (err) {
            console.error(err);
            errorToastMid(err.message);
          }
        }
      }
      setShow(false);
    }
  }

  return (
    <Transition.Root
      show={show}
      as={Fragment}
      afterLeave={() => {
        setId("");
        setFormError(false);
      }}
    >
      <Dialog
        as="div"
        className="relative z-[55]"
        onClose={() => {
          setShow(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="w-1/3 flex flex-col overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 
            transition-all p-8"
            >
              <H2>Delete Organization Unit</H2>
              <p className="text-sm text-font-dark w-full mt-5 font-normal">
                Warning: Deleting this organization unit will affect all of the
                members that belong to it. These members will have to be moved
                to another organization unit or deactivated.
              </p>
              {!data ||
              !orgList ||
              data?.orgUnits?.[0]?.memberCount !== inputFields.length ? (
                <LoadingState />
              ) : (
                <div className="border border-borders rounded-md mt-5 p-4 flex flex-col">
                  <div className="flex flex-row w-full items-center">
                    <img
                      className="w-[84px] h-[84px]"
                      alt=""
                      src={data?.orgUnits?.[0]?.logo || "//:0"}
                    />
                    <div className="flex flex-col pl-4 space-y-1">
                      <h3 className="text-font-dark font-bold text-lg">
                        {data?.orgUnits?.[0]?.name}
                      </h3>
                      <span className="text-font-dark text-xs">
                        {data?.orgUnits?.[0]?.motto || "Motto"}
                      </span>
                      <span className="text-font-dark text-xs font-bold">
                        {!data?.orgUnits?.[0]?.locationCity ||
                        !data?.orgUnits?.[0]?.locationState
                          ? "Location"
                          : `${data?.orgUnits?.[0]?.locationCity}, ${data?.orgUnits?.[0]?.locationState}`}
                      </span>
                      <span className="text-xs font-normal text-font-dark/50">
                        {data?.orgUnits?.[0]?.website || "Website"}
                      </span>
                    </div>
                  </div>
                  <div className="max-h-[20vh] overflow-y-auto w-full mt-4">
                    <table
                      className="w-full table-fixed border-separate"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr className="text-xs text-font-dark text-center top-0 sticky bg-white">
                          <th className="text-font-dark/50 w-2/5 text-left">
                            {`${data?.orgUnits?.[0]?.memberCount} members`}
                          </th>
                          <th>Transfer to Org Unit?</th>
                          <th>Inactivate?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.orgUnits?.[0]?.membersConnection?.edges.map(
                          (member, index) => {
                            return (
                              <tr key={index}>
                                <td className="pt-4">
                                  <div className="flex items-center">
                                    <img
                                      className="w-[42px] h-[42px] rounded-full"
                                      alt=""
                                      src={member?.node?.userIconUrl || "//:0"}
                                    />
                                    <div className="flex flex-col ml-2 space-y-1">
                                      <span className="text-xs text-font-dark font-bold">
                                        {member?.node?.name || "Name"}
                                      </span>
                                      <span className="text-xs text-font-dark font-normal">
                                        {member?.position || "Position"}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="pt-4">
                                  <div className="flex justify-center items-center h-full">
                                    <select
                                      className="h-8 my-2 text-xs w-full rounded-md border-borders"
                                      value={inputFields[index].transfer}
                                      onChange={(e) => handleOrgTransfer(e, index)}
                                    >
                                      <option value="" />
                                      {otherOrgs.map((org, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={org.orgUnit_id}
                                          >
                                            {org.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </td>
                                <td className="pt-4">
                                  <div className="flex justify-center items-center h-full ">
                                    <input
                                      type="checkbox"
                                      checked={inputFields[index].inactivate}
                                      onChange={() => {
                                        let data = [...inputFields];
                                        data[index].inactivate =
                                          !data[index].inactivate;
                                        setInputFields(data);
                                        setFormError(false);
                                      }}
                                      className="rounded-md w-[20px] h-[20px] border border-borders"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {formError && (
                <span className="text-xs font-bold text-red-600">
                  All members must be either transferred or inactivated
                </span>
              )}
              <Button
                disabled={loading}
                className="mt-10 self-end"
                onClick={handleDeletion}
              >
                Save
              </Button>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteOrgUnit;
